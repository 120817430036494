import * as React from 'react';
import { useSelector } from 'react-redux';
import { getDialogData, getIsServerErrorDialogOpen } from '@selectors/ui';
import { Box } from '@material-ui/core';
import { T } from '@components/T/T';
import { GeneralDialog } from '@components/Modal/GeneralDialog';

export const ServerErrorDialog: React.FC = () => {
    const isDialogOpen = useSelector(getIsServerErrorDialogOpen);
    const dialogData = useSelector(getDialogData);
    if (isDialogOpen && dialogData) {
        const { message } = dialogData;
        return (
            <GeneralDialog isDialogOpen headerText={'Error'}>
                <Box display={'flex'} justifyContent={'center'} alignItems={'center'} flex={1} p={4.5}>
                    <T>{message}</T>
                </Box>
            </GeneralDialog>
        );
    }
    return null;
};
