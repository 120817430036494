import * as React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { OwnDropzone } from '@components';

import { IRootState } from '@reducers/index';
import { getAgreementsFileSendingError } from '@selectors/roaming';

import agreementActions from '@actions/agreements';
import { getMyPeers } from '@selectors/peers';
import { CalculationType } from '../../enums';
import { Button, Form, Input, Radio, Select } from 'antd';
import uiActions from '@actions/ui';
import transactionsActions from '@actions/transactions';
import { IDialogType } from '@reducers/ui';
import { IAgreementsReducer } from '@reducers/agreements';
import { ModalErrorMsgBox } from '@components/ErrorMsgBox/ErrorMsgBox';
import { Box } from '@material-ui/core';
import { FilterFunc } from 'rc-select/lib/interface/generator';

import { OptionData, OptionGroupData } from 'rc-select/lib/interface';
import peerActions from '@actions/peers';
import { UnsupportedPeerFeatures } from '@components/UnsupportedPeerFeatures/UnsupportedPeerFeatures';

interface INewContractValues {
    name: string;
    partner: string;
    calculationType: CalculationType;
}

interface IState {
    isLoadingPartnerFeatures: boolean;
    file?: File;
    fileAsString?: string;
    errors: string[];
}

interface IProps {
    disabled?: boolean;
    isReplaceContract?: boolean;
    fileSendingError?: IAgreementsReducer['fileSendingError'];
    peers?: ReturnType<typeof getMyPeers>;
    actions?: typeof agreementActions & typeof peerActions & typeof uiActions & typeof transactionsActions;
}

const MAX_FILE_SIZE_IN_BYTES = 100 * 1024 ** 2;

@connect(
    (state: IRootState) => ({
        fileSendingError: getAgreementsFileSendingError(state),
        peers: getMyPeers(state)
    }),
    (dispatch) => ({
        actions: bindActionCreators({ ...agreementActions, ...peerActions, ...uiActions, ...transactionsActions }, dispatch)
    })
)
export class CreateContractForm extends React.Component<IProps & Partial<INewContractValues>, IState> {
    static defaultProps = {
        calculationType: CalculationType.Yearly
    };

    state: IState = {
        isLoadingPartnerFeatures: false,
        errors: [],
        file: undefined
    };

    componentWillUnmount() {
        this.props.actions!.clearCreateContractError();
    }

    onDrop = (acceptedFiles: File[], rejectedFiles: File[]) => {
        const errors = [];
        const file = acceptedFiles[0];

        if (rejectedFiles.length > 0) {
            if (rejectedFiles[0].size > MAX_FILE_SIZE_IN_BYTES) {
                errors.push(`File size must be smaller than ${MAX_FILE_SIZE_IN_BYTES / 1024 ** 2} MB.`);
            }

            if (rejectedFiles[0].type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
                errors.push('Only Excel Spreadsheet files are accepted.');
            }
        }
        this.props.actions!.clearCreateContractError();

        this.setState({
            file,
            errors
        });
    };
    formSubmit = (values: INewContractValues): void => {
        const { actions, isReplaceContract } = this.props;
        const { file } = this.state;

        // if warning dialog needs to appear we open it, save in state the relevant data and close current modal
        if (isReplaceContract && actions) {
            actions.showDialog(IDialogType.WARNING_BEFORE_UPLOADING_NEW_CONTRACT, {
                file,
                title: values.name,
                peerId: values.partner,
                calculationType: values.calculationType
            });
            this.closeForm();
            return;
        } else {
            actions!.agreementsCreateFromFile({
                file,
                title: values.name,
                peerId: values.partner,
                calculationType: values.calculationType,
                actionToExecute: agreementActions.roamingProposeContract
            });
        }
    };

    renderDnD = (): JSX.Element => {
        const { file, errors } = this.state;
        return (
            <OwnDropzone
                onDropHandler={this.onDrop}
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                maxSize={MAX_FILE_SIZE_IN_BYTES}
                errors={[...errors]}
                file={file}
                id="agreement-scheme"
                selectedFileIcon="file-excel"
            />
        );
    };

    getListOfPartners = (): Array<{ value: string; label: string }> => {
        const { peers } = this.props;
        return peers!.map((peer) => {
            return { value: peer.id, label: peer.name };
        });
    };

    closeForm = () => {
        this.props.isReplaceContract ? this.props.actions?.toggleShowReplaceContract(false) : this.props.actions?.toggleShowCreateContract(false);
    };

    filterPartnerOptions: FilterFunc<OptionData | OptionGroupData> = (inputValue: string, option) => {
        return (
            option
                ?.label!.toString()
                .toLowerCase()
                .includes(inputValue.toLowerCase()) || false
        );
    };

    onSelectPartner = async (peerId: string) => {
        this.setState({ isLoadingPartnerFeatures: true });
        await new Promise((res) => {
            const action = this.props.actions?.getPeerFeatures(peerId);
            (action as any).resolve = res;
        });
        this.setState({ isLoadingPartnerFeatures: false });
    };

    render() {
        const { fileSendingError, peers, calculationType, name, partner, isReplaceContract } = this.props;
        const { file, isLoadingPartnerFeatures } = this.state;
        return (
            <Form layout="vertical" initialValues={{ calculationType, name, partner }} onFinish={this.formSubmit}>
                <Form.Item label="Contract Name" name="name" rules={[{ required: true, message: 'Please type a Contract name' }]}>
                    <Input data-t="name" placeholder="Type a Contract Name" disabled={isReplaceContract} />
                </Form.Item>
                <Form.Item label="Partner" required name="partner" rules={[{ required: true, message: 'Please select a Partner' }]}>
                    <Select
                        onChange={this.onSelectPartner}
                        data-t="partner"
                        showSearch
                        placeholder="Select a Partner"
                        filterOption={this.filterPartnerOptions}
                        disabled={isReplaceContract}
                        options={this.getListOfPartners()}
                    />
                </Form.Item>
                <Form.Item noStyle dependencies={['partner']}>
                    {({ getFieldValue }) => {
                        const peerId = getFieldValue('partner');
                        const peer = peers!.find((p) => p.id === peerId);
                        return peer?.features && <UnsupportedPeerFeatures target="Partner" features={peer?.features} />;
                    }}
                </Form.Item>
                <Form.Item label="Discount Calculation" required name="calculationType">
                    <Radio.Group disabled={isReplaceContract}>
                        <Radio value={CalculationType.Yearly}>{CalculationType.Yearly}</Radio>
                        <Radio value={CalculationType.Monthly}>{CalculationType.Monthly}</Radio>
                    </Radio.Group>
                </Form.Item>
                {this.renderDnD()}
                {fileSendingError && <ModalErrorMsgBox testTag="upload-error" error={fileSendingError} />}
                <Box display="flex" justifyContent="flex-end" mt={4}>
                    <Box clone mr={4}>
                        <Button onClick={this.closeForm}>Cancel</Button>
                    </Box>
                    <Button data-t="submit" disabled={!file || isLoadingPartnerFeatures} htmlType="submit" type="primary">
                        Create
                    </Button>
                </Box>
            </Form>
        );
    }
}
