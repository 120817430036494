import * as React from 'react';

import { Dropdown } from '@components/Buttons';
import { Icon } from '@components/Icon';
import { ITableColumnProps } from '@components/Table';

import { Item } from './Item';

import './styles.scss';

interface IColumnPresenterProps {
    cols: ITableColumnProps[];
    onShowColumnToggle: (key: string) => void;
    shownColumn?: { [index: string]: boolean };
}

export const ColumnPresenter: React.StatelessComponent<IColumnPresenterProps> = ({ cols, onShowColumnToggle, shownColumn }): JSX.Element => (
    <Dropdown classList="column-presenter" icon={<Icon iconName="gear" />} inline>
        {cols.map((col) => (
            <Item col={col} checked={(shownColumn || {})[col.key]} onShowColumnToggle={onShowColumnToggle} key={col.key} />
        ))}
    </Dropdown>
);
