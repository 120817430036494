import * as React from 'react';
import classNames from 'classnames';

interface IFieldProps {
    input: any;
    classes?: string;
    placeholder?: string;
    type?: string;
}

export const BaseInput = (props: IFieldProps): JSX.Element => {
    const { input, classes, placeholder, type = 'text' } = props;

    const fieldClasses = classNames(`field field-input field-${input.name}`, classes);

    return (
        <div className={fieldClasses}>
            <div className="field-control">
                <input {...input} type={type} placeholder={placeholder} />
            </div>
        </div>
    );
};
