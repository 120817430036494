import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Card, Divider, Form, message, Radio, Skeleton } from 'antd';
import { useAction } from '../../../../hooks/useAction';
import { settingsActions } from '@actions/settings';
import { selectLocalCurrency, selectSupportedUsageCurrencies } from '@selectors/settings';
import { Box } from '@material-ui/core';
import { T } from '@components/T/T';
import { FontColor } from '@components/theme';
import { Separator } from '@components/Separator/Separator';
import SuccessIcon from '@ant-design/icons/CheckCircleOutlined';

export const GeneralSettings: React.FC = () => {
    const localCurrencyCode = useSelector(selectLocalCurrency);
    const supportedUsageCurrencies = useSelector(selectSupportedUsageCurrencies);
    const getSupportedCurrencies = useAction(settingsActions.getSupportedCurrencies);
    const [isLoading, setIsLoading] = useState(true);
    const [isSaving, setIsSaving] = useState(false);
    const setLocalCurrency = useAction(settingsActions.setLocalCurrency);

    const onSaveChanges = useCallback(async ({ currency }) => {
        setIsSaving(true);
        await setLocalCurrency(currency);
        await message.info({
            content: 'Changes have been saved',
            icon: <SuccessIcon color="blue" />,
            style: {
                marginTop: '20vh'
            },
            duration: 1.5
        });
        setIsSaving(false);
    }, []);

    useEffect(() => {
        getSupportedCurrencies().then(() => {
            setIsLoading(false);
        });
    }, []);

    return (
        <Skeleton loading={isLoading}>
            <Card
                bodyStyle={{ display: 'flex', flexDirection: 'column', height: '100%', padding: 32 }}
                style={{ height: 0, minHeight: 700, width: 700, margin: '12px auto' }}
            >
                <T variant="body2">General</T>
                <Divider />
                <Box height="100%" display="flex" flexDirection="column" clone>
                    <Form layout="vertical" onFinish={onSaveChanges}>
                        <Form.Item
                            name="currency"
                            initialValue={localCurrencyCode}
                            label={
                                <T variant="caption" color={FontColor.B65} paragraph>
                                    System Currency View
                                </T>
                            }
                        >
                            <Radio.Group>
                                {supportedUsageCurrencies.map((c) => (
                                    <Box key={c.code} mb={2}>
                                        <Radio value={c.code}>
                                            <T variant="caption">{c.name}</T>
                                        </Radio>
                                    </Box>
                                ))}
                            </Radio.Group>
                        </Form.Item>
                        <Separator />
                        <Box display="flex" justifyContent="flex-end">
                            <Button htmlType="submit" type="primary" disabled={isSaving}>
                                Save Changes
                            </Button>
                        </Box>
                    </Form>
                </Box>
            </Card>
        </Skeleton>
    );
};
