import { GeneralDialog } from '@components/Modal/GeneralDialog';
import React, { useCallback, useState } from 'react';
import { Box } from '@material-ui/core';
import { T } from '@components/T/T';
import classNames from 'classnames';
import { Button, Divider, Select } from 'antd';
import { formatBalance } from '@helpers/formaters';
import uiActions from '@actions/ui';
import { useSelector } from 'react-redux';
import { getCurrentInfo, getFinalBillingStatement } from '@selectors/roaming';
import { getCurrencySymbol } from '@constants';
import { settlementOfferActions } from '@actions/settlementOffers';
import { useAction } from '../../../hooks/useAction';
import { InputNumberProps } from 'antd/lib/input-number';
import {
    DisputeResolutionMessageBox,
    NetPositionTable,
    OfferForm,
    OfferMessages,
    useSettlementOfferStyles,
    useSimulatedNetPosition
} from '../DisputeResolutionModal';
import { materialTheme } from '@components/theme';
import { Separator } from '@components/Separator/Separator';
import { CurrencyViewSelection } from '@components/DisputeResolution/CurrencyViewSelector/CurrencyViewSelection';

export const NewOffer: React.FC = () => {
    const agreement = useSelector(getCurrentInfo);
    const finalStatement = useSelector(getFinalBillingStatement);

    const contractName = agreement.contract.config.name;
    const classes = useSettlementOfferStyles();
    const createSettlementOffer = useAction(settlementOfferActions.createSettlementOffer);
    const createSettlementOfferComment = useAction(settlementOfferActions.createSettlementOfferComment);
    const hideModal = useAction(uiActions.hideModal);

    const offerDirection = finalStatement!.suggestedOfferDirection;
    const [offerAmount, setOfferAmount] = useState(finalStatement!.discrepancy.valueOf() / 2);
    const onChangeOfferAmount = useCallback(
        (amount: number) => {
            const offerAmountWithDirection = (agreement.isMyContract ? 1 : -1) * Math.abs(amount);
            setOfferAmount!(offerAmountWithDirection);
        },
        [agreement.isMyContract]
    );
    const [offerComment, setOfferComment] = useState('');
    const [isSendingOffer, setIsSendingOffer] = useState(false);
    const simulatedMyNewPosition = useSimulatedNetPosition(offerDirection, offerAmount);
    const formatOfferAmount = useCallback(
        (value) =>
            formatBalance(value, undefined, {
                currency: getCurrencySymbol(agreement.terms.currency),
                roundToInteger: true
            }),
        []
    );
    const handlePercentageChange = useCallback((value) => onChangeOfferAmount(Math.round((value / 100) * finalStatement!.discrepancy.valueOf())), []);
    const handleCommentChange = useCallback((e: React.ChangeEvent<HTMLTextAreaElement>) => setOfferComment(e.target.value), []);
    const onSendToPartnerClick = useCallback(async () => {
        setIsSendingOffer(true);
        const percentage = Math.abs(Math.round((offerAmount / finalStatement!.discrepancy.valueOf()) * 100));
        const summary = `offers to ${offerDirection.toLowerCase()} ${formatOfferAmount(
            Math.abs(offerAmount)
        )} (${percentage}%), total settlement of ${formatOfferAmount(Math.abs(simulatedMyNewPosition!))}`;
        await createSettlementOffer(agreement.contract.id, finalStatement!.id, offerAmount.toString(), summary, offerComment);
        setIsSendingOffer(false);
        hideModal();
    }, [createSettlementOffer, createSettlementOfferComment, offerComment, offerAmount]);
    const onCloseModalClick = useCallback(() => {
        hideModal();
    }, []);

    return (
        <GeneralDialog
            shouldCloseOnClickOutside={!isSendingOffer}
            onCloseClick={onCloseModalClick}
            modalClassName={'padding'}
            width={780}
            isDialogOpen={true}
            HeaderComponent={<ModalHeaderComponent subHeader={contractName} />}
            FooterComponent={
                <ModalFooterComponent onCancelClick={onCloseModalClick} onSendToPartnerClick={onSendToPartnerClick} isInProgress={isSendingOffer} />
            }
        >
            <Box className={classes.mainWrapper}>
                <Box mb={7} display={'flex'}>
                    <OfferForm
                        offerDirection={finalStatement!.suggestedOfferDirection}
                        offerAmount={offerAmount}
                        discrepancy={finalStatement!.discrepancy}
                        handlePercentageChange={handlePercentageChange}
                        setOfferAmount={onChangeOfferAmount as InputNumberProps['onChange']}
                    />
                </Box>
                <NetPositionTable offerAmount={offerAmount} offerDirection={finalStatement!.suggestedOfferDirection} />
                <OfferMessages />
                <Divider className={classes.messagesDivider} />
                <DisputeResolutionMessageBox title="Add Note (optional)" value={offerComment} onChange={handleCommentChange} />
            </Box>
        </GeneralDialog>
    );
};

const ModalHeaderComponent: React.FC<{ subHeader: string }> = ({ subHeader }) => {
    const classes = useSettlementOfferStyles();
    return (
        <Box className={classNames(classes.header)} mx={-5} px={5}>
            <Box display="flex" alignItems="center" width="100%">
                <Box mr={2}>
                    <T>New Offer</T>
                </Box>
                <Box color={materialTheme.palette.lighter}>
                    <T variant={'caption'}>{subHeader}</T>
                </Box>
                <Separator />
                <CurrencyViewSelection />
            </Box>
        </Box>
    );
};

const ModalFooterComponent: React.FC<{
    onCancelClick: () => any;
    onSendToPartnerClick: () => any;
    isInProgress: boolean;
}> = ({ onCancelClick, onSendToPartnerClick, isInProgress }) => {
    const classes = useSettlementOfferStyles();

    return (
        <Box className={classNames(classes.footer)}>
            <Box mr={3} clone>
                <Button onClick={onCancelClick} disabled={isInProgress}>
                    Cancel
                </Button>
            </Box>
            <Button onClick={onSendToPartnerClick} type="primary" loading={isInProgress}>
                Send To Partner
            </Button>
        </Box>
    );
};
