import { createAction } from '@helpers/actionCreator';

export enum alertType {
    ALERTS_GET_ALL_REQUEST = 'RSP:ALERTS:GET:ALL::REST:REQUEST',
    ALERTS_GET_ALL_SUCCESS = 'RSP:ALERTS:GET:ALL::REST:REQUEST:SUCCESS',
    ALERTS_GET_ALL_FAIL = 'RSP:ALERTS:GET:ALL::REST:REQUEST:FAIL',
    ALERTS_GET_ALL_FINISHED = 'RSP:ALERTS:GET:ALL::REST:REQUEST:FINISHED',

    ALERTS_GET_FILTERED_REQUEST = 'RSP:ALERTS:GET:FILTERED::REST:REQUEST',
    ALERTS_GET_FILTERED_SUCCESS = 'RSP:ALERTS:GET:FILTERED::REST:REQUEST:SUCCESS',
    ALERTS_GET_FILTERED_FAIL = 'RSP:ALERTS:GET:FILTERED::REST:REQUEST:FAIL',
    ALERTS_GET_FILTERED_FINISHED = 'RSP:ALERTS:GET:FILTERED::REST:REQUEST:FINISHED',

    ALERTS_UPDATE_REQUEST = 'RSP:ALERTS:UPDATE::REST:REQUEST',
    ALERTS_UPDATE_SUCCESS = 'RSP:ALERTS:UPDATE::REST:REQUEST:SUCCESS',
    ALERTS_UPDATE_FAIL = 'RSP:ALERTS:UPDATE::REST:REQUEST:FAIL',
    ALERTS_UPDATE_FINISHED = 'RSP:ALERTS:UPDATE::REST:REQUEST:FINISHED',

    ALERTS_INSERT_REQUEST = 'RSP:ALERTS:INSERT::REST:REQUEST',
    ALERTS_INSERT_SUCCESS = 'RSP:ALERTS:INSERT::REST:REQUEST:SUCCESS',
    ALERTS_INSERT_FAIL = 'RSP:ALERTS:INSERT::REST:REQUEST:FAIL',
    ALERTS_INSERT_FINISHED = 'RSP:ALERTS:INSERT::REST:REQUEST:FINISHED',

    ALERTS_DELETE_REQUEST = 'RSP:ALERTS:DELETE::REST:REQUEST',
    ALERTS_DELETE_SUCCESS = 'RSP:ALERTS:DELETE::REST:REQUEST:SUCCESS',
    ALERTS_DELETE_FAIL = 'RSP:ALERTS:DELETE::REST:REQUEST:FAIL',
    ALERTS_DELETE_FINISHED = 'RSP:ALERTS:DELETE::REST:REQUEST:FINISHED'
}

interface IAlertsBaseParams {
    contact_name: string;
    contact_info: string;
}
interface IAlertsDeleteDynamicParams extends IAlertsBaseParams {
    alert_id: string;
}

export interface IAlertsInsertDynamicParams extends IAlertsBaseParams {
    alert_type: string;
    alert_media: string;
}

export interface IAlertsUpdateDynamicParams extends IAlertsInsertDynamicParams {
    alert_id: string;
}

type IAlertsQueryDynamicParams = Partial<IAlertsDeleteDynamicParams> & {
    alert_type?: string;
};

const alertActions = {
    getAlertGeneralInfoRequest: createAction(alertType.ALERTS_GET_ALL_REQUEST, {
        payload: {
            table: 'alerts',
            operation: 'query',
            rows_in_table: 10,
            page_number: 1
        }
    }),
    getAlertFilteredInfoRequest: createAction(alertType.ALERTS_GET_FILTERED_REQUEST, {
        payload: (params?: IAlertsQueryDynamicParams) => ({
            table: 'alerts',
            operation: 'query',
            rows_in_table: 10,
            page_number: 1,
            ...params
        })
    }),
    editAlertInfoRequest: createAction(alertType.ALERTS_UPDATE_REQUEST, {
        payload: (params: IAlertsUpdateDynamicParams) => ({
            table: 'alerts',
            operation: 'update',
            ...params
        })
    }),
    addAlertInfoRequest: createAction(alertType.ALERTS_INSERT_REQUEST, {
        payload: (params: IAlertsInsertDynamicParams) => ({
            table: 'alerts',
            operation: 'insert',
            ...params
        })
    }),
    deleteAlertInfoRequest: createAction(alertType.ALERTS_DELETE_REQUEST, {
        payload: (params: IAlertsDeleteDynamicParams) => ({
            table: 'alerts',
            operation: 'delete',
            ...params
        })
    })
};

export default alertActions;
