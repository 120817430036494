import React from 'react';
import { TagList } from '@modules/Voice/components';

type comparedVal = null | string | number | string[];
interface IComparisonCellProps {
    a: comparedVal;
    b: comparedVal;
    listLimit?: number;
    formatter?: (val: any) => string;
}
const isNA = (val: any) => val === null || val === undefined || val === '';
const displayVal = (val: any, limit: number = 2, formatter?: (val: string) => string) => {
    if (isNA(val) || (Array.isArray(val) && !val.length)) {
        return 'N/A';
    }
    return Array.isArray(val) ? <TagList values={val} limit={limit} /> : formatter ? formatter(val) : val;
};
export const ComparisonCell = ({ a, b, listLimit, formatter }: IComparisonCellProps) => (
    <div className="compared">
        <span>{displayVal(a, listLimit, formatter)}</span>
        <span>{displayVal(b, listLimit, formatter)}</span>
    </div>
);
