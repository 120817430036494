import React from 'react';
import Box from '@material-ui/core/Box';
import { IStateNotification } from '@actions/notification';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { createStyles } from '@material-ui/core';

const useStyles = makeStyles(() =>
    createStyles({
        action: {
            cursor: 'pointer',
            '&:hover': {
                color: '#4a83ff'
            }
        }
    })
);
export const NotificationItem: React.FC<{ notification: IStateNotification; onMoreDetailsClick: () => any }> = ({
    notification,
    onMoreDetailsClick
}) => {
    const classes = useStyles();
    const isNotificationActionable = notification.type;
    return (
        <Box component={'li'}>
            <Box className="message">{notification.content}</Box>
            {isNotificationActionable && (
                <Box className={classes.action} onClick={onMoreDetailsClick} fontSize={14} my={0.5}>
                    For details click here
                </Box>
            )}
            <Box className="term" color={'#8593AC'} fontSize={12}>
                {notification.timeToPresent}
            </Box>
        </Box>
    );
};
