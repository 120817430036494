import * as React from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { Helmet } from 'react-helmet';

import { ForgotPasswordContainer } from './containers';
import { LoginEmailForm } from './components/Login/LoginEmailForm';

interface IProps {
    node: string;
}

export const AuthRoutes = (props: IProps): JSX.Element => (
    <>
        <Helmet title="Clear | Login" />
        <Switch>
            <Route exact strict path={'/auth/forgot-password'} component={ForgotPasswordContainer} />
            <Route exact strict path={'/auth/login'} component={LoginEmailForm} />
            <Redirect to="/auth/login" />
        </Switch>
    </>
);
