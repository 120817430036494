import { Action, combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { connectRouter, RouterState } from 'connected-react-router';
import { History } from 'history';

import { IRootState } from './state';
import { authReducer } from './auth';
import { notificationsReducer } from './notifications';
import { transactionsReducer } from './transactions';
import { appReducer } from './app';
import { balanceReducer } from './balance';
import { peersReducer } from './peers';
import { agreementsReducer } from './agreements';
import { accountReducer } from './account';
import { uiReducer } from './ui';
import { cdrReducer } from './cdr';
import { voiceReducer } from './voice';
import { queryFiltersReducer } from './queryFilters';
import { voiceDisputeDrawerReducer } from './voiceDisputeDrawer';
import { ratesSheetParserReducer } from './RatesSheetParser/index';
import { userReducer } from '@reducers/userReducer';
import { authType } from '@actions';
import { activityLogsReducer } from './activityLogs';
import { settlementOfferReducer } from '@reducers/settlementOffers';
import { settingsReducer } from '@reducers/settings';
import { currencyReducer } from '@reducers/currency';
import { settlementsReducer } from '@reducers/settlements';
import { countryReducer } from '@reducers/country';

export { IRootState };

export const getRootReducer = (history: History) => {
    const combinedReducer = combineReducers<IRootState>({
        app: appReducer,
        auth: authReducer,
        currency: currencyReducer,
        user: userReducer,
        form: formReducer,
        router: connectRouter(history),
        voice: voiceReducer,
        transactions: transactionsReducer,
        notifications: notificationsReducer,
        balance: balanceReducer,
        peers: peersReducer,
        agreements: agreementsReducer,
        settlementOffers: settlementOfferReducer,
        account: accountReducer,
        ui: uiReducer,
        cdr: cdrReducer,
        queryFilters: queryFiltersReducer,
        voiceDisputeDrawer: voiceDisputeDrawerReducer,
        rateSheetParser: ratesSheetParserReducer,
        activityLogs: activityLogsReducer,
        settings: settingsReducer,
        settlements: settlementsReducer,
        country: countryReducer
    });

    return (state: IRootState, action: Action) => {
        if (action.type === authType.SESSION_RESTORE_FAIL && state) {
            const { app, auth, form, router, ui } = state;
            // tslint:disable-next-line:no-object-literal-type-assertion
            state = { app, auth, form, router, ui } as IRootState;
        }
        return combinedReducer(state, action);
    };
};
