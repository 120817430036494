import * as React from 'react';

interface IItemProps {
    col: any;
    checked?: boolean;
    onShowColumnToggle: (key: string) => void;
}

export const Item: React.StatelessComponent<IItemProps> = ({ col: { key, title }, checked, onShowColumnToggle }): JSX.Element => (
    <label htmlFor={title}>
        <input
            type="checkbox"
            id={title}
            name={title}
            checked={checked}
            // tslint:disable-next-line jsx-no-lambda
            onChange={() => onShowColumnToggle(key)}
        />
        {title}
    </label>
);
