import { createRequestAction, ISorting } from '@helpers/actionCreator';

// NOTE - transaction keys dont have to start with transactions_ (but values do)
export enum transactionsType {
    TRANSACTIONS_GET_ALL_REQUEST = 'TRANSACTIONS:GET:ALL::REQUEST',
    TRANSACTIONS_GET_ALL_SUCCESS = 'TRANSACTIONS:GET:ALL::REQUEST:SUCCESS',
    TRANSACTIONS_GET_ALL_FAIL = 'TRANSACTIONS:GET:ALL::REQUEST:FAIL',
    TRANSACTIONS_GET_ALL_FINISHED = 'TRANSACTIONS:GET:ALL::REQUEST:FINISHED',
    TRANSACTIONS_DEPOSIT_REQUEST = 'TRANSACTIONS:DEPOSIT::REQUEST',
    TRANSACTION_UPDATE_RECEIVED = 'TRANSACTION:UPDATE:RECEIVED',
    TRANSACTIONS_GET_USD_REQUEST = 'TRANSACTIONS:GET:USD::REQUEST',
    TRANSACTIONS_GET_USD_SUCCESS = 'TRANSACTIONS:GET:USD::REQUEST:SUCCESS',
    TRANSACTIONS_INVEST_REQUEST = 'TRANSACTIONS:INVEST::REQUEST',
    TRANSACTIONS_CONVERT_USD_REQUEST = 'TRANSACTIONS:CONVERT:USD::REQUEST',
    TRANSACTIONS_CONVERT_USD_SUCCESS = 'TRANSACTIONS:CONVERT:USD::REQUEST:SUCCESS',
    TRANSACTIONS_CONVERT_CURRENCY_REQUEST = 'TRANSACTIONS:CONVERT:CURRENCY::REQUEST',
    TRANSACTIONS_CONVERT_CURRENCY_SUCCESS = 'TRANSACTIONS:CONVERT:CURRENCY::REQUEST:SUCCESS',
    TRANSACTIONS_WITHDRAW_REQUEST = 'TRANSACTIONS:WITHDRAW::REQUEST',
    TRANSACTIONS_WITHDRAW_REQUEST_SUCCESS = 'TRANSACTIONS:WITHDRAW::REQUEST:SUCCESS',
    TRANSACTIONS_WITHDRAW_REQUEST_FAIL = 'TRANSACTIONS:WITHDRAW::REQUEST:FAIL',
    TRANSACTIONS_WITHDRAW_REQUEST_FINISHED = 'TRANSACTIONS:WITHDRAW::REQUEST:FINISHED',
    TRANSACTIONS_PAYMENT_REQUEST = 'TRANSACTIONS:PAYMENT::REQUEST',
    TRANSACTIONS_PAYMENT_SUCCESS = 'TRANSACTIONS:PAYMENT::REQUEST:SUCCESS',
    TRANSACTIONS_PAYMENT_FAIL = 'TRANSACTIONS:PAYMENT::REQUEST:FAIL',
    TRANSACTIONS_PAYMENT_FINISHED = 'TRANSACTIONS:PAYMENT::REQUEST:FINISHED',
    TRANSACTIONS_WITHDRAW_APPROVE_REQUEST = 'TRANSACTIONS:WITHDRAW:APPROVE::REQUEST',
    TRANSACTIONS_WITHDRAW_APPROVE_SUCCESS = 'TRANSACTIONS:WITHDRAW:APPROVE::REQUEST:SUCCESS',
    TRANSACTIONS_WITHDRAW_APPROVE_FAIL = 'TRANSACTIONS:WITHDRAW:APPROVE::REQUEST:FAIL',
    TRANSACTIONS_WITHDRAW_APPROVE_FINISHED = 'TRANSACTIONS:WITHDRAW:APPROVE::REQUEST:FINISHED',
    TRANSACTIONS_WITHDRAWN_REQUEST = 'TRANSACTIONS:WITHDRAWN::REQUEST',
    TRANSACTIONS_WITHDRAWN_SUCCESS = 'TRANSACTIONS:WITHDRAWN::REQUEST:SUCCESS',
    TRANSACTIONS_WITHDRAWN_FAIL = 'TRANSACTIONS:WITHDRAWN::REQUEST:FAIL',
    TRANSACTIONS_WITHDRAWN_FINISHED = 'TRANSACTIONS:WITHDRAWN::REQUEST:FINISHED',
    TRANSACTIONS_WITHDRAWN_LANDED_REQUEST = 'TRANSACTIONS:WITHDRAWN:LANDED::REQUEST',
    TRANSACTIONS_WITHDRAWN_LANDED_SUCCESS = 'TRANSACTIONS:WITHDRAWN:LANDED::REQUEST:SUCCESS',
    TRANSACTIONS_WITHDRAWN_LANDED_FAIL = 'TRANSACTIONS:WITHDRAWN:LANDED::REQUEST:FAIL',
    TRANSACTIONS_WITHDRAWN_LANDED_FINISHED = 'TRANSACTIONS:WITHDRAWN:LANDED::REQUEST:FINISHED',
    QUERY_INVOICES = 'TRANSACTIONS:QUERY:INVOICES',
    UPLOAD_INVOICE = 'TRANSACTIONS:UPLOAD:INVOICE',
    INVOICE_UPDATE_RECEIVED = 'INVOICE:UPDATE:RECEIVED',
    PAY_INVOICES = 'TRANSACTIONS:PAY:INVOICES',
    ADD_TOKENS = 'TRANSACTIONS:ADD:TOKENS',
    BURN_TOKENS = 'TRANSACTIONS:BURN:TOKENS'
}

const transactionsActions = {
    transactionsGetAllRequest: createRequestAction(transactionsType.TRANSACTIONS_GET_ALL_REQUEST, 'getMyTransactions'),
    transactionsDepositRequest: createRequestAction(transactionsType.TRANSACTIONS_DEPOSIT_REQUEST, 'approveDeposit', (values: any[]) => [values]),
    transactionsInvestRequest: createRequestAction(transactionsType.TRANSACTIONS_INVEST_REQUEST, 'mmfInvested', (value: any) => [value]),
    transactionsGetUSDRequest: createRequestAction(
        transactionsType.TRANSACTIONS_GET_USD_REQUEST,
        'getUSDDataFromCurrency',
        (amount: number, currency: string) => [amount, currency]
    ),
    transactionsConvertUSDRequest: createRequestAction(
        transactionsType.TRANSACTIONS_CONVERT_USD_REQUEST,
        'getWithdrawDataFromUSD',
        (amount: number, currency: string) => [amount, currency]
    ),
    transactionsConvertCurrencyRequest: createRequestAction(
        transactionsType.TRANSACTIONS_CONVERT_CURRENCY_REQUEST,
        'getWithdrawDataFromCurrency',
        (amount: number, currency: string) => [amount, currency]
    ),
    transactionsWithdrawRequest: createRequestAction(
        transactionsType.TRANSACTIONS_WITHDRAW_REQUEST,
        'withdrawalRequest',
        (amount: number, currency: string) => [amount, currency]
    ),
    transactionsPaymentRequest: createRequestAction(
        transactionsType.TRANSACTIONS_PAYMENT_REQUEST,
        'transferEusd',
        (peerId: string, amount: number) => [peerId, amount]
    ),
    transactionsWithdrawApproveRequest: createRequestAction(
        transactionsType.TRANSACTIONS_WITHDRAW_APPROVE_REQUEST,
        'approveWithdrawal',
        (transactionId: string) => [transactionId]
    ),
    transactionsWithdrawnRequest: createRequestAction(transactionsType.TRANSACTIONS_WITHDRAWN_REQUEST, 'mmfWithdrawn', (values: any) => [values]),
    transactionsWithdrawnLandedRequest: createRequestAction(
        transactionsType.TRANSACTIONS_WITHDRAWN_LANDED_REQUEST,
        'moneyLanded',
        (transactionId: string) => [transactionId]
    ),
    queryInvoices: createRequestAction(transactionsType.QUERY_INVOICES, 'queryInvoices', (filters: any, sorting: ISorting, page: number) => [
        filters,
        sorting,
        { page, pageSize: 10 }
    ]),
    uploadInvoice: createRequestAction(transactionsType.UPLOAD_INVOICE, 'uploadInvoice', (csvContent: string) => [csvContent]),
    payInvoices: createRequestAction(transactionsType.PAY_INVOICES, 'payInvoices', (invoiceIds: string[]) => [invoiceIds]),
    addTokens: createRequestAction(transactionsType.ADD_TOKENS, 'depositRequest', (amount: number) => [amount]),
    burnTokens: createRequestAction(transactionsType.BURN_TOKENS, 'withdrawalRequest', (amount: number) => [amount, 'USD'])
};

export interface ITransactionBasket {
    rmb?: string;
    eur?: string;
    gbp?: string;
    jpy?: string;
    usd?: string;
    xdr?: string;
    net?: string;
    fee?: string;
    total?: string;
}

export interface ITransactionConvertation {
    amount: string;
    rmb: string;
    eur: string;
    gbp: string;
    jpy: string;
    usd: string;
    currency: string;
    fee: string;
    net: string;
    feePercent: string;
}

export interface ITransactionsItemReducer {
    account: string;
    action: string;
    amount: string;
    digitalAmount: string;
    bankName: string;
    currency: string;
    digitalCurrency: string;
    currentBalance: string;
    usdPurchased?: string;
    date: any;
    id: string;
    otherParty: string;
    status: string;
    statusTimeline: { [key: string]: string };
    dstCurrency: string;
    basket: ITransactionBasket;
}

export default transactionsActions;
