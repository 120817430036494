import get from 'lodash/get';
import { createSelector, Selector } from 'reselect';
import { IRootState } from '@reducers';
import { checkWithPolicy, ICheckResult, IPasswordPolicy } from '@clear/password-policy';

export const getPasswordCheckResult: Selector<IRootState, ICheckResult> = createSelector(
    (state: IRootState) => get(state.form['user-form'], ['values', 'password'], ''),
    (state: IRootState) => get(state.form['user-form'], ['values', 'username'], ''),
    (state: IRootState) => get(state, ['auth', 'passwordPolicy'], null),
    (password: string, username: string, passwordPolicy: IPasswordPolicy | null) => {
        if (!passwordPolicy) {
            return { isValid: false, errors: [], strength: 0 };
        }
        return checkWithPolicy(password, username, passwordPolicy);
    }
);
