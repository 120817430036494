import { createAction } from '@helpers/actionCreator';

export enum logsType {
    LOGS_GET_ALL_REQUEST = 'RSP:LOGS:GET:ALL::REST:REQUEST',
    LOGS_GET_ALL_SUCCESS = 'RSP:LOGS:GET:ALL::REST:REQUEST:SUCCESS',
    LOGS_GET_ALL_FAIL = 'RSP:LOGS:GET:ALL::REST:REQUEST:FAIL',
    LOGS_GET_ALL_FINISHED = 'RSP:LOGS:GET:ALL::REST:REQUEST:FINISHED',

    LOGS_GET_WITH_FILTER_REQUEST = 'RSP:LOGS:GET:WITH_FILTER::REST:REQUEST',
    LOGS_GET_WITH_FILTER_SUCCESS = 'RSP:LOGS:GET:WITH_FILTER::REST:REQUEST:SUCCESS',
    LOGS_GET_WITH_FILTER_FAIL = 'RSP:LOGS:GET:WITH_FILTER::REST:REQUEST:FAIL',
    LOGS_GET_WITH_FILTER_FINISHED = 'RSP:LOGS:GET:WITH_FILTER::REST:REQUEST:FINISHED'
}

export interface ILogsSendFilterObject {
    from_date: string;
    to_date: string;
    service_name:
        | 'UI server'
        | 'CSG Syncer'
        | 'Emails Processor'
        | 'Alerts Service'
        | 'Rates Parser'
        | 'Rates Storage'
        | 'Rates Verification'
        | 'Partners Service'
        | '';
    log_level: 'CRITICAL' | 'ERROR' | 'WARNING' | 'INFO' | 'DEBUG' | '';
    log_info: string;
    [key: string]: string;
}

const logsActions = {
    getLogsAllRequest: createAction(logsType.LOGS_GET_ALL_REQUEST, {
        payload: {
            table: 'logs',
            rows_in_table: 10,
            page_number: 1
        }
    }),
    getLogsWithFilterRequest: createAction(logsType.LOGS_GET_WITH_FILTER_REQUEST, {
        payload: (subFilterObject: ILogsSendFilterObject) => ({
            table: 'logs',
            rows_in_table: 10,
            page_number: 1,
            ...subFilterObject
        })
    })
};

export default logsActions;
