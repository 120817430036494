import * as React from 'react';
import classNames from 'classnames';
import { IMenuButtonProps, MenuButton } from '@components/Buttons/MenuButton';

import './styles.scss';
import { Dialog } from '@components/Modal/Dialog';

interface IUploadButtonPrompt {
    description: React.ReactNode | string;
    confirmText: string;
    cancelText: string;
}

interface IUploadButtonProps extends IMenuButtonProps {
    accept: string;
    maxSize: number;
    prompt?: IUploadButtonPrompt;
    isDisabled?: boolean;
    onFileSelect(file: File): void;
}

interface IUploadButtonState {
    fileUploading: any;
    uploadedSize: number;
    isPromptOpen: boolean;
}

export class UploadButton extends MenuButton<IUploadButtonProps, IUploadButtonState> {
    static defaultProps: Partial<IUploadButtonProps> = {
        accept: '.csv, .pdf',
        maxSize: 100 * 1024 * 1024
    };

    buttonRef = React.createRef<HTMLButtonElement>();

    state = {
        fileUploading: null,
        uploadedSize: 0,
        isPromptOpen: false
    };

    get id(): string {
        const { name } = this.props;
        return [name, this.randomId].join('');
    }

    onClickButton = async () => {
        const { prompt } = this.props;
        if (!prompt) {
            const inputEl = document.getElementById(this.id);
            inputEl!.click();
            return;
        }
        this.setState({
            isPromptOpen: true
        });
    };

    generateInputButton = () => {
        const { accept } = this.props;
        const buttonEl = this.buttonRef.current as HTMLButtonElement;
        const inputEl = document.createElement('input');
        inputEl.type = 'file';
        inputEl.classList.add('upload-button-input');
        inputEl.id = this.id;
        inputEl.accept = accept;
        inputEl.addEventListener('change', this.handleFileSelection as any);
        document.body.appendChild(inputEl);
        buttonEl.addEventListener('click', this.onClickButton);
    };

    componentDidMount(): void {
        this.generateInputButton();
    }

    componentWillUnmount(): void {
        const el = document.getElementById(this.id);
        if (el) {
            el.remove();
        }
    }

    handleFileSelection = (event: React.ChangeEvent<HTMLInputElement>): void => {
        if (!event.target.files) {
            return;
        }

        const fileInfo = event.target.files[0];
        (event as any).target.value = null;

        this.props.onFileSelect(fileInfo);

        if (!fileInfo) {
            return;
        }

        // Kind of request simulation
        new Promise((resolve) => {
            this.setState({
                fileUploading: fileInfo,
                uploadedSize: 100
            });

            setTimeout(() => {
                this.setState({ uploadedSize: 100 });
                return resolve(true);
            }, 500);
        }).then((res) => {
            setTimeout(() => this.setState({ uploadedSize: 100 }), 500);
            setTimeout(() => this.setState({ fileUploading: null }), 500);
        });
    };

    renderDialog = () => {
        const { prompt } = this.props;
        if (!prompt) {
            return null;
        }
        const { isPromptOpen } = this.state;
        const onCancel = () => {
            this.setState({
                isPromptOpen: false
            });
        };
        const onConfirm = (e: any) => {
            this.setState({
                isPromptOpen: false
            });
            const el = document.getElementById(this.id);
            el!.click();
        };
        return (
            <Dialog isOpen={isPromptOpen} classList="confirm-dialog" onClose={onCancel} hideCloseButton>
                <div className="message">{prompt!.description}</div>
                <div className="actions">
                    <button className="btn btn-primary btn-confirm" onClick={onConfirm}>
                        {prompt!.confirmText}
                    </button>
                    <button className="btn btn-secondary btn-cancel" onClick={onCancel}>
                        {prompt!.cancelText}
                    </button>
                </div>
            </Dialog>
        );
    };

    render() {
        const { name, classes, children, isDisabled } = this.props;
        return (
            <>
                {this.renderDialog()}
                <button disabled={isDisabled} ref={this.buttonRef} className={classNames('btn btn-primary menu-button', classes)}>
                    <span>{children || name}</span>
                </button>
            </>
        );
    }
}
