import { IAppAction } from './state';
import { CountryActionType } from '@actions/country';
import { requestSuccessType } from '@helpers/actionCreator';
import keyBy from 'lodash/keyBy';
import { STANDARD_COUNTRY_MAPPING } from '@clearblockchain/shared/country';

export interface CountryState {
    mappings: Record<string, { id: string; name: string }>;
    translations: Record<keyof typeof STANDARD_COUNTRY_MAPPING, string>;
}

const initialState: CountryState = {
    mappings: {},
    translations: ({} as unknown) as Record<keyof typeof STANDARD_COUNTRY_MAPPING, string>
};

export function countryReducer(state = initialState, { type, payload }: IAppAction): CountryState {
    switch (type) {
        case requestSuccessType(CountryActionType.GET_COUNTRY_MAPPINGS): {
            const mappings = keyBy(payload, 'id');
            return {
                ...state,
                mappings
            };
        }
        case requestSuccessType(CountryActionType.GET_COUNTRY_TRANSLATIONS): {
            const translations = payload;
            return {
                ...state,
                translations
            };
        }
        default:
            return state;
    }
}
