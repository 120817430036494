import { createAction, createRequestAction, clearType } from '@helpers/actionCreator';
import { IAccount, ISwapAgreementDetails } from '@reducers/voice';
import { PRODUCT } from '../utils/productTypes';
import { SWAP_AGREEMENT_STATUS } from '@modules/Voice/containers/AgreementView';

// TODO - enum is already voice scoped - keys don't have to strt with 'VOICE_' (but values still should);
export enum voiceType {
    // Send fileHash from upload Invoice (.pdf?) result to already created settlement period

    VOICE_SETTLEMENT_UPLOAD = 'VOICE:SETTLEMENT:UPLOAD',

    VOICE_FILEHASH_SEND_REQUEST = 'VOICE:FILEHASH:SEND::REQUEST',
    VOICE_FILEHASH_SEND_REQUEST_SUCCESS = 'VOICE:FILEHASH:SEND::REQUEST:SUCCESS',
    VOICE_FILEHASH_SEND_REQUEST_FAIL = 'VOICE:FILEHASH:SEND::REQUEST:FAIL',

    VOICE_FILE_DIALOG_CLOSE = 'VOICE:FILE:DIALOG:CLOSE',

    // VOICE_DATA_UPLOAD = 'VOICE:SETTLEMENT:DATA::UPLOAD',
    // VOICE_DATA_UPLOAD_SUCCESS = 'VOICE:SETTLEMENT:DATA::UPLOAD:SUCCESS',

    // Send fileHash from upload .cdr result to already created settlement period
    // VOICE_CDR_UPLOAD = 'VOICE:SETTLEMENT:CDR::UPLOAD',
    // VOICE_CDR_UPLOAD_SUCCESS = 'VOICE:SETTLEMENT:CDR::UPLOAD:SUCCESS',

    // VOICE_PARTNER_CDR_UPLOAD = 'VOICE:SETTLEMENT:PARTNER:CDR::UPLOAD',
    // VOICE_PARTNER_CDR_UPLOAD_SUCCESS = 'VOICE:SETTLEMENT:PARTNER:CDR::UPLOAD:SUCCESS',

    // Upload CDR and Invoice,
    // TODO need refactor, I think we can reuse one function for any file upload,
    VOICE_INVOICE_SEND = 'VOICE:INVOICE:SEND::REQUEST',
    VOICE_INVOICE_SEND_FAIL = 'VOICE:INVOICE:SEND::REQUEST:FAIL',
    VOICE_INVOICE_SEND_SUCCESS = 'VOICE:INVOICE:SEND::REQUEST:SUCCESS',
    VOICE_CDR_SEND = 'VOICE:CDR:SEND::REQUEST',
    VOICE_CDR_SEND_FAIL = 'VOICE:CDR:SEND::REQUEST:FAIL',
    VOICE_CDR_SEND_SUCCESS = 'VOICE:CDR:SEND::REQUEST:SUCCESS',

    VOICE_PARTNER_CDR_SEND = 'VOICE:PARTNER:CDR:SEND::REQUEST',
    VOICE_PARTNER_CDR_SEND_FAIL = 'VOICE:PARTNER:CDR:SEND::REQUEST:FAIL',
    VOICE_PARTNER_CDR_SEND_SUCCESS = 'VOICE:PARTNER:CDR:SEND::REQUEST:SUCCESS',

    VOICE_EXTERNAL_LINK_GENERATE = 'VOICE:EXTERNAL:LINK:GENERATE::REQUEST',
    VOICE_EXTERNAL_LINK_GENERATE_FAIL = 'VOICE:EXTERNAL:LINK:GENERATE::REQUEST:FAIL',
    VOICE_EXTERNAL_LINK_GENERATE_SUCCESS = 'VOICE:EXTERNAL:LINK:GENERATE::REQUEST:SUCCESS',
    VOICE_EXTERNAL_LINK_GENERATE_RESET = 'VOICE:EXTERNAL:LINK:GENERATE:RESET',

    // Create accounts
    VOICE_CREATE_ACCOUNT = 'VOICE:CREATE:ACCOUNT',
    VOICE_CREATE_ACCOUNT_SUCCESS = 'VOICE:CREATE:ACCOUNT::REQUEST:SUCCESS',

    // update account
    VOICE_UPDATE_ACCOUNT = 'VOICE:UPDATE:ACCOUNT',

    // Get all account, it is kinda like list of partner, like 'vodafon', 'kievstart' etc.
    VOICE_QUERY_ACCOUNTS = 'VOICE:QUERY:ACCOUNTS::REQUEST',
    VOICE_QUERY_ACCOUNTS_SUCCESS = 'VOICE:QUERY:ACCOUNTS::REQUEST:SUCCESS',

    // Get accounts by page
    VOICE_QUERY_ACCOUNTS_BY_PAGE = 'VOICE:QUERY:ACCOUNTS:BY:PAGE::REQUEST',
    VOICE_QUERY_ACCOUNTS_BY_PAGE_SUCCESS = 'VOICE:QUERY:ACCOUNTS:BY:PAGE::REQUEST:SUCCESS',

    GET_ALL_POTENTIAL_PARTNERS = 'VOICE:GET:POTENTIAL:PARTNER::REQUEST',
    GET_ALL_POTENTIAL_PARTNERS_SUCCESS = 'VOICE:GET:POTENTIAL:PARTNER::REQUEST:SUCCESS',

    // Get all created settlement period
    VOICE_QUERY_SETTLEMENT_PERIOD = 'VOICE:QUERY:SETTLEMENT:PERIOD::REQUEST',
    VOICE_QUERY_SETTLEMENT_PERIOD_SUCCESS = 'VOICE:QUERY:SETTLEMENT:PERIOD::REQUEST:SUCCESS',
    VOICE_QUERY_SETTLEMENT_PERIOD_FINISHED = 'VOICE:QUERY:SETTLEMENT:PERIOD::REQUEST:FINISHED',

    // WIP, I don't test it yet, it is for get detail info about one period
    VOICE_GET_SINGLE_SETTLEMENT_PERIOD_SUMMARY = 'VOICE:GET:SINGLE:SETTLEMENT:PERIOD:SUMMARY::REQUEST',
    VOICE_GET_SINGLE_SETTLEMENT_PERIOD_SUMMARY_SUCCESS = 'VOICE:GET:SINGLE:SETTLEMENT:PERIOD:SUMMARY::REQUEST:SUCCESS',
    VOICE_GET_SINGLE_SETTLEMENT_PERIOD_SUMMARY_FINISHED = 'VOICE:GET:SINGLE:SETTLEMENT:PERIOD:SUMMARY::REQUEST:FINISHED',

    // WIP, I don't test it yet, it is also for get detail info about one period
    VOICE_GET_SINGLE_SETTLEMENT_PERIOD = 'VOICE:GET:SINGLE:SETTLEMENT:PERIOD::REQUEST',
    VOICE_GET_SINGLE_SETTLEMENT_PERIOD_SUCCESS = 'VOICE:GET:SINGLE:SETTLEMENT:PERIOD::REQUEST:SUCCESS',
    VOICE_GET_SINGLE_SETTLEMENT_PERIOD_FINISHED = 'VOICE:GET:SINGLE:SETTLEMENT:PERIOD::REQUEST:FINISHED',
    VOICE_SINGLE_SETTLEMENT_CLEAR = 'VOICE:SINGLE:SETTLEMENT:CLEAR',

    GET_DRILLDOWN_CDRS = 'VOICE:GET:DRILLDOWN:CDRS',
    GET_DRILLDOWM_CDRS_BY_DATE_CLEAR = 'VOICE:GET:DRILLDOWN:CDRS:BYDATE:CLEAR',

    // Get drilldown CDRs
    VOICE_GET_DRILLDOWN_CDRS = 'VOICE:GET:SINGLE:SETTLEMENT:PERIOD:DRILLDOWN:CDRS::REQUEST',
    VOICE_GET_DRILLDOWN_CDRS_SUCCESS = 'VOICE:GET:SINGLE:SETTLEMENT:PERIOD:DRILLDOWN:CDRS::REQUEST:SUCCESS',
    VOICE_GET_DRILLDOWN_CDRS_FINISHED = 'VOICE:GET:SINGLE:SETTLEMENT:PERIOD:DRILLDOWN:CDRS::REQUEST:FINISHED',
    VOICE_GET_DRILLDOWN_CDRS_CLEAR = 'VOICE:SINGLE:SETTLEMENT:DRILLDOWN:CDRS:CLEAR',

    // Get values to be used in filters
    VOICE_GET_SINGLE_SETTLEMENT_PERIOD_FILTERS = 'VOICE:GET:SINGLE:SETTLEMENT:PERIOD:FILTERS',

    // WIP, I don't test it yet
    VOICE_CREATE_SETTLEMENT_PERIOD = 'VOICE:CREATE:SETTLEMENT:PERIOD',

    VOICE_UPDATE_SETTLEMENT_PERIOD = 'VOICE:UPDATE:SETTLEMENT:PERIOD::REQUEST',
    VOICE_UPDATE_SETTLEMENT_PERIOD_SUCCESS = 'VOICE:UPDATE:SETTLEMENT:PERIOD::REQUEST:SUCCESS',

    VOICE_SETTLEMENT_PERIOD_UPDATE = 'VOICE:SETTLEMENT:PERIOD:UPDATE',

    // Used for querying specific period details by partner when following external upload link
    VOICE_GET_SETTLEMENT_PERIOD = 'VOICE:SETTLEMENT:PERIOD::REQUEST',
    VOICE_GET_SETTLEMENT_PERIOD_SUCCESS = 'VOICE:SETTLEMENT:PERIOD::REQUEST:SUCCESS',
    VOICE_GET_SETTLEMENT_PERIOD_FINISHED = 'VOICE:SETTLEMENT:PERIOD::REQUEST:FINISHED',

    VOICE_CREATE_DISPUTE_OFFER = 'VOICE:CREATE:DISPUTE:OFFER',
    VOICE_GET_DISPUTE_OFFER = 'VOICE:GET:DISPUTE:OFFER',
    VOICE_GET_EXTERNAL_DISPUTE_OFFER = 'VOICE:GET:EXTERNAL:DISPUTE:OFFER',
    VOICE_UPDATE_DISPUTE_OFFER_STATUS = 'VOICE:UPDATE:DISPUTE:OFFER:STATUS',

    VOICE_SELECT_COMPARISON_TYPE = 'VOICE:SELECT:COMPARISON:TYPE',
    SELECT_MATCH_TYPE = 'VOICE:SELECT:MATCH:TYPE',

    DOWNLOAD_SINGLE_SETTLEMENT_PERIOD = 'VOICE:DOWNLOAD:SINGLE:SETTLEMENT:PERIOD',
    DOWNLOAD_DRILLSOWN_CDRS = 'VOICE:DOWNLOAD:SINGLE:SETTLEMENT:PERIOD:DRILLDOWN:CDRS',

    CLEAN_DEMO_VOICE_DATA = 'CLEAN:DEMO:VOICE:DATA',
    RESET_DEMO_VOICE_ENV = 'RESET:DEMO:VOICE:ENV',

    GET_RATESHEET_DESTINATIONS = 'VOICE:GET:RATESHEET:DESTINATIONS',
    GET_AGREEMENTS = 'VOICE:GET:AGREEMENTS',
    GET_AGREEMENT = 'VOICE:SPIN:GET:AGREEMENT',
    GET_DRAFT_AGREEMENT = 'VOICE:SPIN:GET:AGREEMENT:DRAFT',
    CREATE_AGREEMENT = 'VOICE:CREATE:AGREEMENT',
    CREATE_AGREEMENT_DRAFT = 'VOICE:SPIN:CREATE:DRAFT',
    UPDATE_AGREEMENT = 'VOICE:SPIN:UPDATE:AGREEMENT',
    PUBLISH_AGREEMENT = 'VOICE:SPIN:PUBLISH:AGREEMENT',
    UPDATE_AGREEMENT_STATUS = 'VOICE:SPIN:UPDATE:AGREEMENT:STATUS',
    DELETE_AGREEMENT = 'VOICE:DELETE:AGREEMENT'
}

export enum ComparisonType {
    CDR_PARTNER_CDR = 'cdr_partner_cdr',
    CDR_INVOICE = 'cdr_invoice',
    PARTNER_CDR_INVOICE = 'partner_cdr_invoice'
}

export enum DisputeOfferType {
    FiftyFifty = '50_50',
    NewAmount = 'NEW_AMOUNT'
}
export enum DisputeOfferStatus {
    Draft = 'DRAFT',
    Accept = 'ACCEPT',
    Reject = 'REJECT',
    OfferSent = 'OFFER_SENT'
}
export enum Direction {
    Payable = 'payable',
    Receivable = 'receivable'
}
export enum ViewBy {
    dest_period = 'destination',
    date = 'date',
    cdrs = 'CDRs'
}
export interface ISorting {
    by: string;
    order: 'desc' | 'asc';
}

const PAGE_SIZE: number = 10;

const pagination = (page: number) => ({ page, pageSize: PAGE_SIZE });

let voiceUploadDialogTimeoutId = 0;
const voiceUpload = (payload: any) => {
    const DIALOG_TIMEOUT = 7000;
    return (dispatch: any) => {
        dispatch({
            type: voiceType.VOICE_SETTLEMENT_UPLOAD,
            payload
        });
        if (voiceUploadDialogTimeoutId) {
            clearTimeout(voiceUploadDialogTimeoutId);
        }
        voiceUploadDialogTimeoutId = setTimeout(() => {
            dispatch({ type: voiceType.VOICE_FILE_DIALOG_CLOSE });
        }, DIALOG_TIMEOUT) as any;
    };
};

// TODO - object is already voice scoped - methods don't have to start with 'voice'
const voiceActions = {
    voiceGetAllPotentialPartners: createAction(voiceType.GET_ALL_POTENTIAL_PARTNERS),
    voiceSettlementUpload: voiceUpload,
    voiceFileDialogClose: createAction(voiceType.VOICE_FILE_DIALOG_CLOSE),
    voiceSelectComparisonType: createAction(voiceType.VOICE_SELECT_COMPARISON_TYPE, { payload: (comparisonType: ComparisonType) => comparisonType }),
    selectMatchType: createAction(voiceType.SELECT_MATCH_TYPE),

    voiceLinkGenerate: createRequestAction(voiceType.VOICE_EXTERNAL_LINK_GENERATE, 'createExternalSettlementPeriodHash', (periodId: number) => [
        periodId
    ]),

    voiceResetExternalHash: createAction(voiceType.VOICE_EXTERNAL_LINK_GENERATE_RESET),

    voiceQueryAccounts: createRequestAction(voiceType.VOICE_QUERY_ACCOUNTS, 'queryAccounts'),

    voiceQueryAccountsByPage: createRequestAction(
        voiceType.VOICE_QUERY_ACCOUNTS_BY_PAGE,
        'queryAccounts',
        (filterOptions?: any, sorting?: any, page?: number) => [filterOptions, sorting, page ? { page, pageSize: PAGE_SIZE } : null]
    ),

    voiceCreateAccounts: createRequestAction(voiceType.VOICE_CREATE_ACCOUNT, 'createAccount', (product: PRODUCT, accounts: IAccount[]) => [
        product,
        accounts
    ]),

    voiceUpdateAccount: createRequestAction(voiceType.VOICE_UPDATE_ACCOUNT, 'updateAccount', (accountId: string, account: IAccount) => [
        accountId,
        account
    ]),

    voiceGetPotentialPartners: createRequestAction(voiceType.GET_ALL_POTENTIAL_PARTNERS, 'getPotentialPartners'),

    voiceQuerySettlementPeriods: createRequestAction(
        voiceType.VOICE_QUERY_SETTLEMENT_PERIOD,
        'querySettlementPeriods',
        (page: number, filterOptions?: any, sortingOption: any = { by: 'updatedDate', order: 'desc' }) => [
            filterOptions,
            sortingOption,
            pagination(page)
        ]
    ),

    voiceCreateSettlementPeriod: createRequestAction(
        voiceType.VOICE_CREATE_SETTLEMENT_PERIOD,
        'createSettlementPeriod',
        (accountId: string, start?: number, end?: number) => [accountId, start, end]
    ),

    voiceUpdateSettlementPeriod: createRequestAction(
        voiceType.VOICE_UPDATE_SETTLEMENT_PERIOD,
        'updateSettlementPeriod',
        (periodId: string, obj?: any) => [periodId, obj]
    ),

    voiceGetSingleSettlementPeriodSummary: createRequestAction(
        voiceType.VOICE_GET_SINGLE_SETTLEMENT_PERIOD_SUMMARY,
        'getSingleSettlementPeriodSummary',
        (periodId: string, comparison: ComparisonType, direction: Direction) => [periodId, comparison, direction]
    ),

    voiceGetFiltersPanelData: createRequestAction(
        voiceType.VOICE_GET_SINGLE_SETTLEMENT_PERIOD_FILTERS,
        'getFiltersForSingleSettlementPeriod',
        (periodId: string, traffic: ComparisonType, direction: Direction) => [periodId, traffic, direction]
    ),

    // NOTE - the reducer counts on viewBy argument being the 3rd
    queryDrilldownCDRs: createRequestAction(
        voiceType.GET_DRILLDOWN_CDRS,
        'queryDrilldownCDRs',
        (periodId: string, direction: Direction, viewBy: ViewBy, filters: any, sorting: ISorting, page: number) => [
            periodId,
            direction,
            viewBy,
            filters,
            sorting,
            pagination(page)
        ]
    ),
    downloadDrilldownCDRs: createRequestAction(
        voiceType.DOWNLOAD_DRILLSOWN_CDRS,
        'downloadDrilldownCDRs',
        (periodId: string, direction: Direction, viewBy: ViewBy, filters: any, sorting: ISorting) => [periodId, direction, viewBy, filters, sorting]
    ),
    voiceGetSingleSettlementPeriod: createRequestAction(
        voiceType.VOICE_GET_SINGLE_SETTLEMENT_PERIOD,
        'querySingleSettlementPeriod',
        ({
            periodId,
            comparison,
            direction,
            filterOptions,
            sorting,
            page
        }: {
            periodId: number;
            comparison: ComparisonType;
            direction: string;
            filterOptions: any;
            sorting: ISorting;
            page: number;
        }) => [periodId, comparison, direction, filterOptions, sorting, pagination(page)]
    ),
    // same args as previous, minus pagination
    downloadSingleSettlementPeriod: createRequestAction(
        voiceType.DOWNLOAD_SINGLE_SETTLEMENT_PERIOD,
        'downloadSingleSettlementPeriod',
        ({
            periodId,
            comparison,
            direction,
            filterOptions,
            sorting
        }: {
            periodId: number;
            comparison: ComparisonType;
            direction: string;
            filterOptions: any;
            sorting: any;
        }) => [periodId, comparison, direction, filterOptions, sorting]
    ),

    voiceGetSettlementPeriod: createRequestAction(voiceType.VOICE_GET_SETTLEMENT_PERIOD, 'getSettlementPeriod', (periodHash: string) => [periodHash]),
    // TODO - remove
    voiceGetDrilldownCDRs: createRequestAction(
        voiceType.VOICE_GET_DRILLDOWN_CDRS,
        'queryPeriodDrilldownCDRs',
        ({ periodId, direction, destination, product, date, filters, sorting, page }: any) => [
            periodId,
            { ...filters, direction, destination, product, date },
            sorting,
            { page, pageSize: PAGE_SIZE }
        ]
    ),

    // NOTE - reducer uses this action's values
    voiceCreateDisputeOffer: createRequestAction(
        voiceType.VOICE_CREATE_DISPUTE_OFFER,
        'createDisputeOffer',
        (invoiceComparisonId: string, type: DisputeOfferType, status: DisputeOfferStatus, offerData: any, currency: string, periodId: string) => [
            invoiceComparisonId,
            type,
            status,
            offerData,
            currency,
            'dummy creator id',
            periodId
        ]
    ),

    voiceGetDisputeOffer: createRequestAction(voiceType.VOICE_GET_DISPUTE_OFFER, 'getDisputeOffer', (id: string) => [id]),

    voiceGetExternalDisputeOffer: createRequestAction(voiceType.VOICE_GET_EXTERNAL_DISPUTE_OFFER, 'getDisputeOffer', (hash: string) => [hash]),

    voiceUpdateDisputeOfferStatus: createRequestAction(
        voiceType.VOICE_UPDATE_DISPUTE_OFFER_STATUS,
        'updateInvoiceComparisonDisputeStatus',
        (disputOfferId: string, status: DisputeOfferStatus, stateDescription?: string, isExternal?: boolean) => [
            disputOfferId,
            status,
            stateDescription,
            isExternal
        ]
    ),

    voiceSingleSettlementClear: createAction(voiceType.VOICE_SINGLE_SETTLEMENT_CLEAR),
    drillDownCDRsClear: createAction(clearType(voiceType.GET_DRILLDOWN_CDRS)),
    voiceDrilldownCDRsClear: createAction(voiceType.VOICE_GET_DRILLDOWN_CDRS_CLEAR),
    voiceDisputeOfferResultClear: createAction(clearType(voiceType.VOICE_CREATE_DISPUTE_OFFER)),
    freshlyCreatedSettlementClear: createAction(clearType(voiceType.VOICE_CREATE_SETTLEMENT_PERIOD)),

    voiceCleanDemoData: createRequestAction(voiceType.CLEAN_DEMO_VOICE_DATA, 'demoVoiceClearData'),
    voiceResetDemoEnv: createRequestAction(voiceType.RESET_DEMO_VOICE_ENV, 'demoVoiceResetEnv'),

    getRatesheetDestinations: createRequestAction(
        voiceType.GET_RATESHEET_DESTINATIONS,
        'getAllRatesheetDestinations',
        // NOTE - reducer uses values
        (accountId: string, direction: Direction) => [accountId, direction]
    ),
    getAgreements: createRequestAction(
        voiceType.GET_AGREEMENTS,
        'querySwapAgreements',
        (accountId: string, page: number, filterOptions?: any, sortingOption: any = { by: 'startDate', order: 'desc' }) => [
            accountId,
            filterOptions,
            sortingOption,
            pagination(page)
        ]
    ),
    getAgreement: createRequestAction(voiceType.GET_AGREEMENT, 'getSingleSwapAgreement', (id: string) => [id]),
    // Same action with different type, to be handled differently by reducer
    getDraftAgreement: createRequestAction(voiceType.GET_DRAFT_AGREEMENT, 'getSingleSwapAgreement', (id: string) => [id]),
    clearDraftAgreement: createAction(clearType(voiceType.GET_DRAFT_AGREEMENT)),
    createAgreement: createRequestAction(
        voiceType.CREATE_AGREEMENT,
        'createSwapAgreement',
        (name: string, accountId: string, startDate: number, endDate: number, defaultInboundRate?: number, defaultOutboundRate?: number) => [
            name,
            accountId,
            startDate,
            endDate,
            defaultInboundRate,
            defaultOutboundRate
        ]
    ),
    createAgreementDraft: createRequestAction(voiceType.CREATE_AGREEMENT_DRAFT, 'createSwapAgreementFromOriginal', (id: string) => [id]),
    updateAgreement: createRequestAction(
        voiceType.UPDATE_AGREEMENT,
        'updateSwapAgreement',
        // Jan 12 2020 : temporary workaroud for BE bug - updatedDate isn't set by BE, but taken from FE
        (id: string, agreement: ISwapAgreementDetails) => {
            const swapAgreement = { ...agreement.swapAgreement };
            swapAgreement.updatedDate = Math.round(Date.now() / 1000);
            return [id, { ...agreement, swapAgreement }];
        }
    ),
    updateSwapAgreementStatus: createRequestAction(
        voiceType.UPDATE_AGREEMENT_STATUS,
        'updateSwapAgreementStatus',
        (id: string, status: SWAP_AGREEMENT_STATUS, comments?: string) => [id, status, comments || '']
    ),
    updateAndPublishAgreement: (id: string, agreement: ISwapAgreementDetails) => {
        const action = voiceActions.updateAgreement(id, agreement);
        (action as any).nextAction = voiceActions.publishAgreement(id);
        return action;
    },
    publishAgreement: createRequestAction(voiceType.PUBLISH_AGREEMENT, 'publishSwapAgreementDraft', (id: string) => [id]),
    deleteAgreement: createRequestAction(voiceType.DELETE_AGREEMENT, 'deleteSwapAgreement', (id: string) => [id])
};

export default voiceActions;
