import { put, all, take, fork, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';

import uiActions from '@actions/ui';
import voiceActions, { voiceType } from '@actions/voice';
import { fileUploader, downloadFile } from './utilities/fileUploader';
import { AnyAction } from 'redux';
import first from 'lodash/first';
import values from 'lodash/values';
import { requestSuccessType } from '@helpers/actionCreator';
import { setPathId, voicePaths } from 'utils/routePaths';

function* showSpinner() {
    yield put(uiActions.showSpinner());
}

function* hideSpinner() {
    yield put(uiActions.hideSpinner());
}

function* hideWhenAllLoad() {
    while (true) {
        yield all([take(voiceType.VOICE_GET_SINGLE_SETTLEMENT_PERIOD_SUMMARY), take(voiceType.VOICE_GET_SINGLE_SETTLEMENT_PERIOD)]);
        put(uiActions.showSpinner());

        yield all([take(voiceType.VOICE_GET_SINGLE_SETTLEMENT_PERIOD_SUMMARY_FINISHED), take(voiceType.VOICE_GET_SINGLE_SETTLEMENT_PERIOD_FINISHED)]);
        put(uiActions.hideSpinner());
    }
}

function* uploadSettlementFile(action: AnyAction) {
    const { file, backendFunctionName, rowId, magicLink, direction, successMessage, errorMessage, shouldCompress } = action.payload;
    yield put(voiceActions.voiceUpdateSettlementPeriod(rowId, { status: 'IN_PROGRESS' }));
    const response = yield fileUploader(file, shouldCompress);
    const fileHash = first(values(response));
    const fileName = Object.keys(response)[0];
    const actionValues = [rowId, fileHash, fileName, magicLink].concat(direction ? [direction] : []);

    yield put({
        payload: {
            name: backendFunctionName,
            values: actionValues
        },
        errorMessage,
        successMessage,
        type: voiceType.VOICE_FILEHASH_SEND_REQUEST
    });
}
function* gotToSettlementList({ payload }: AnyAction) {
    const accountId = payload ? payload.accountId : null;
    if (!accountId) {
        return null;
    }
    yield put(push(setPathId(voicePaths.settlementsList, accountId)));
    return;
}

function* gotoSwapAgreement({ payload }: AnyAction) {
    yield put(push(setPathId(voicePaths.agreement, payload.id)));
}
function* fetchCreatedDraft({ payload }: AnyAction) {
    yield put(voiceActions.getDraftAgreement(payload.id));
}
function* downloadReceivedHash(action: AnyAction) {
    const hash: string = action.payload;
    const fileHash: string = hash.split('#')[0];
    const fileName: string = hash.split('#')[1];
    yield downloadFile(fileHash, fileName);
}
export const sagaVoice = function*(): IterableIterator<any> {
    yield fork(hideWhenAllLoad);
    yield takeLatest(voiceType.VOICE_QUERY_SETTLEMENT_PERIOD, showSpinner);
    yield takeLatest(voiceType.VOICE_QUERY_SETTLEMENT_PERIOD_FINISHED, hideSpinner);

    yield takeLatest(voiceType.VOICE_SETTLEMENT_UPLOAD, uploadSettlementFile);

    yield takeLatest(requestSuccessType(voiceType.DOWNLOAD_SINGLE_SETTLEMENT_PERIOD), downloadReceivedHash);
    yield takeLatest(requestSuccessType(voiceType.DOWNLOAD_DRILLSOWN_CDRS), downloadReceivedHash);

    yield takeLatest(requestSuccessType(voiceType.CREATE_AGREEMENT), gotoSwapAgreement);
    yield takeLatest(requestSuccessType(voiceType.PUBLISH_AGREEMENT), gotoSwapAgreement);
    yield takeLatest(requestSuccessType(voiceType.CREATE_AGREEMENT_DRAFT), fetchCreatedDraft);

    yield takeLatest(requestSuccessType(voiceType.VOICE_CREATE_SETTLEMENT_PERIOD), gotToSettlementList);
};
