import React, { useEffect, useState } from 'react';
import { withRouter, Prompt } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import voiceActions, { Direction } from '@actions/voice';
import { getAgreement, getAgreementDraft, getRatesheetDestinations, getSwapAgreementUpdateCount } from '@selectors/voice';
import Helmet from 'react-helmet';
import { Sticky, Pagination, Table, FiltersPanel } from '@components';
import { Tabs, Tooltip, Modal, Switch, Button, Input } from 'antd';
import Back from '@ant-design/icons/BackwardOutlined';
import Check from '@ant-design/icons/CheckCircleFilled';
const { TextArea } = Input;
const { TabPane } = Tabs;
import Snackbar from '@material-ui/core/Snackbar';
import { History, Location } from 'history';
import { setPathId, voicePaths } from 'utils/routePaths';
import { IPagination, ISorting } from '@helpers/actionCreator';
import { formatDate, formatBalance, parseBalance } from '@helpers/formaters';
import { ISwapAgreementRate, ISwapAgreementDetails, ISwapAgreement } from '@reducers/voice';
import remove from 'lodash/remove';
import cloneDeep from 'lodash/cloneDeep';
import { SelectableCell, EditableDateCell, EditableCell } from '@components/EditableCells';
import { periodDateFormat } from '@constants';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import IconButton from '@material-ui/core/IconButton';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import 'antd/dist/antd.css';
import './styles.scss';
import moment from 'moment';
import { IFilterItem, FilterItemType } from '@components/FiltersPane';
import ActiveFilters from '@components/FiltersPane/ActiveFilters';
import { getFilters } from '@selectors/queryFilters';
import SvgEmptyStateSettlement from '@components/Svg/emptySettlementState';
import { getUrlQueryParam, setUrlQueryParam } from 'utils/urlFunctions';
import { ITableColumnProps } from '@components/Table';

interface INewRate extends ISwapAgreementRate {
    tempId: string; // id vs tempId is used to differentiate between new and existing rates
}
interface IEditableRate extends ISwapAgreementRate {
    changes?: Partial<ISwapAgreementRate>;
}
interface IProps {
    match: {
        params: {
            id: string;
        };
    };
    history: History;
}

export enum SWAP_AGREEMENT_STATUS {
    Draft = 'DRAFT',
    NewDraft = 'NEW_DRAFT',
    PendingPartner = 'PENDING_PARTNER_APPROVAL',
    Pending = 'PENDING_APPROVAL',
    Effective = 'EFFECTIVE',
    Replaced = 'REPLACED',
    Rejected = 'REJECTED',
    Ended = 'ENDED'
}

const ratesColumns: ITableColumnProps[] = [
    { key: 'destination', title: 'Destination' },
    { key: 'commitment', title: 'Volume' },
    { key: 'rate', title: 'Settlement Rate' },
    { key: 'incrementalRate', title: 'Incremental Rate' },
    { key: 'product', title: 'Product' },
    {
        key: 'startDateFormatted',
        sortingKey: 'startDate',
        title: 'Start Date',
        defaultSort: true
    },
    { key: 'endDateFormatted', sortingKey: 'endDate', title: 'End Date' },
    { key: 'origin', title: 'Origin' },
    { key: 'actions', title: '' }
];
const defaultSortColumn = ratesColumns.find(({ defaultSort }) => !!defaultSort) || ratesColumns[0];
const defaultSorting: ISorting = {
    by: defaultSortColumn.sortingKey || defaultSortColumn.key,
    order: 'asc'
};
const agrFields = [
    { title: 'Title', key: 'name' },
    { title: 'Start Date', key: 'startDate' },
    { title: 'End Date', key: 'endDate' },
    { title: 'Currency', key: 'currency' },
    { title: 'Default Inbound Rate', key: 'defaultInboundRate' },
    { title: 'Default Outbound Rate', key: 'defaultOutboundRate' },
    { title: 'Status', key: 'status' }
];
const PAGE_SIZE = 10;
const commitmentMask = createNumberMask({
    allowDecimal: false,
    prefix: ''
});
export const RATE_PRECISION = 4;
const rateMask = createNumberMask({
    allowDecimal: true,
    prefix: '',
    decimalLimit: RATE_PRECISION
});
let tempRateId = 0;
type SelectableKey = 'destination' | 'product' | 'origin';
const [Inbound, Outbound] = ['Inbound', 'Outbound'];
const bind = (func: (arg: any) => void, arg: any) => () => func(arg);
enum PopupType {
    delete = 'delete',
    discard = 'discard',
    pastDates = 'pastDates'
}
const popupTexts: Record<PopupType, [string, string]> = {
    [PopupType.delete]: ['Delete Draft', 'Are you sure you want to delete the draft?'],
    [PopupType.discard]: ['Discard Changes', 'You have some unsaved changes. Are you sure you want to discard them?'],
    [PopupType.pastDates]: [
        'Dates are in the past',
        'Past agreement dates will only affect settlements created from now on. Would you like to continue?'
    ]
};
const DRAFT_STORAGE_KEY = 'swap-agreement-draft';
const NEW_INBOUND_KEY = 'swap-agreement-new-inbound';
const NEW_OUTBOUND_KEY = 'swap-agreement-new-outbound';
const EDIT_QUERY_PARAM = 'edit';
const emptyItemForOptions = {};
export const AgreementView = withRouter(
    ({
        match: {
            params: { id: agreementId }
        },
        history
    }: IProps) => {
        const swapAgreementUpdateCount = useSelector(getSwapAgreementUpdateCount);

        const dispatch = useDispatch();
        useEffect(() => {
            if (agreementId) {
                dispatch(voiceActions.getAgreement(agreementId));
            }
        }, [agreementId, swapAgreementUpdateCount]);
        const isRightDraft = (value?: ISwapAgreementDetails) =>
            value && originalSA && value.swapAgreement.id === (isNewDraft ? originalSA.id : originalSA.draftId) ? value : undefined;
        // Use redux agreement and draft and state draft only fit location agreement id
        let agreement = useSelector(getAgreement);
        if (agreement && agreement!.swapAgreement.id !== agreementId) {
            agreement = undefined;
        }
        const originalSA = (agreement ? agreement!.swapAgreement : null) as ISwapAgreement;
        const isNewDraft = originalSA && originalSA.status === 'NEW_DRAFT';
        const draftFromSelector = useSelector(getAgreementDraft);
        const draft = isNewDraft ? agreement : isRightDraft(draftFromSelector);

        const [draftInStateInner, setDraftInStateInner] = useState<typeof agreement>();
        const draftInState = isRightDraft(draftInStateInner);
        const [editMode, setEditMode] = useState(false);
        // Actual edit mode 'readiness' lags behind editMode variable because draft is fetched/created in BE
        const actualEditMode = !!draftInState;

        const rateOptions = useSelector(getRatesheetDestinations);
        const filters = useSelector(getFilters);
        const [displayedPopupType, setDisplayedPopupType] = useState<PopupType>();
        const displayedPopupTexts = popupTexts[displayedPopupType!] || [];
        const [showingInbound, setShowingInbound] = useState(true);
        const [isRejecting, setIsRejecting] = useState(false);
        const [rejectReason, setRejectReason] = useState('');
        const [page, setPage] = useState(1);
        const [sorting, setSorting] = useState<ISorting>(defaultSorting);
        const [newInboundRates, setNewInboundRates] = useState<INewRate[]>([]);
        const [newOutboundRates, setNewOutboundRates] = useState<INewRate[]>([]);
        const [snackbarMessage, setSnackbarMessage] = useState<string>();
        const setDraftInState = (value: typeof agreement, persistAnyway?: boolean) => {
            setDraftInStateInner(value);
            if (value || persistAnyway) {
                sessionStorage.setItem(DRAFT_STORAGE_KEY, JSON.stringify(value));
            }
        };
        const setNewRates = (value: typeof newInboundRates, isInbound?: boolean) => {
            (isInbound ? setNewInboundRates : setNewOutboundRates)(value);
            sessionStorage.setItem(isInbound ? NEW_INBOUND_KEY : NEW_OUTBOUND_KEY, JSON.stringify(value));
        };
        useEffect(() => {
            const listener = (e: BeforeUnloadEvent) => {
                e.returnValue = 'Are you sure you want to leave?';
            };
            window.addEventListener('beforeunload', listener);
            return () => {
                // Unmounting callback
                window.removeEventListener('beforeunload', listener); // clear draft
                dispatch(voiceActions.clearDraftAgreement());
            };
        }, []);
        useEffect(() => {
            if (agreement) {
                const {
                    swapAgreement: { accountId }
                } = agreement!;
                dispatch(voiceActions.getRatesheetDestinations(accountId, Direction.Payable));
                dispatch(voiceActions.getRatesheetDestinations(accountId, Direction.Receivable));
            }
        }, [agreement]);
        // If draft was loaded - view it
        useEffect(() => {
            const draftId = draft && (isNewDraft ? originalSA.id : originalSA.draftId);
            if (draft && draft.swapAgreement.id === draftId) {
                let valueToSet = draft;
                try {
                    const restoredDraft = JSON.parse(sessionStorage.getItem(DRAFT_STORAGE_KEY) || 'null') as typeof draft;
                    if (
                        restoredDraft &&
                        restoredDraft.swapAgreement.id === draft.swapAgreement.id &&
                        restoredDraft.swapAgreement.updatedDate === draft.swapAgreement.updatedDate
                    ) {
                        valueToSet = restoredDraft;
                        if (sessionStorage.getItem(NEW_INBOUND_KEY)) {
                            setNewInboundRates(JSON.parse(sessionStorage.getItem(NEW_INBOUND_KEY) || '[]') as typeof newInboundRates);
                        }
                        if (sessionStorage.getItem(NEW_OUTBOUND_KEY)) {
                            setNewOutboundRates(JSON.parse(sessionStorage.getItem(NEW_OUTBOUND_KEY) || '[]') as typeof newInboundRates);
                        }
                    }
                } catch (err) {
                    console.log(err);
                }
                setDraftInState(cloneDeep(valueToSet));
            } else {
                setDraftInState(undefined);
            }
        }, [draft]);
        const toggleEdit = (checked: boolean) => {
            setEditMode(checked);
            if (checked) {
                if (!isNewDraft) {
                    loadDraft();
                }
            } else {
                cancelDraft();
            }
            setUrlQueryParam(window.location, EDIT_QUERY_PARAM, checked ? 'true' : '', history, true);
        };
        // Load draft to be viewed
        const loadDraft = () => {
            setPage(1);
            // The draft will then be loaded
            const draftId = originalSA.draftId;
            if (draftId) {
                dispatch(voiceActions.getDraftAgreement(draftId));
            } else {
                // Create new draft - it will then be fetched as draft by saga
                dispatch(voiceActions.createAgreementDraft(agreementId));
            }
        };
        const discardChanges = () => {
            setNewRates([], true);
            setNewRates([], false);
            setDraftInState(undefined, true);
            dispatch(voiceActions.clearDraftAgreement());
        };
        const deleteDraft = () => {
            dispatch(voiceActions.deleteAgreement(draft!.swapAgreement.id));
            discardChanges();
        };
        const cancelDraft = () => {
            if (gotUnsavedChanges || gotUnappliedInbountRates || gotUnappliedOutboundRates) {
                setDisplayedPopupType(PopupType.discard);
            } else {
                discardChanges();
            }
        };
        // Toggle edit to fit query string setting
        // New draft can only be viewed in edit mode
        if (originalSA && (isNewDraft || editMode) !== !!getUrlQueryParam(window.location, EDIT_QUERY_PARAM)) {
            toggleEdit(!editMode);
        }
        const onPopupOk = () => {
            let action;
            switch (displayedPopupType) {
                case PopupType.discard:
                    action = discardChanges;
                    break;
                case PopupType.delete:
                    action = deleteDraft;
                    break;
                case PopupType.pastDates:
                    action = save;
                    break;
            }
            (action as () => void)();
            setDisplayedPopupType(undefined);
        };
        const removeRate = (item: ISwapAgreementRate) => {
            const { id } = item;
            if (id) {
                remove(shownRates, ({ id: rateId }) => id === rateId);
                setDraftInState({ ...draftInState! });
            } else {
                const { tempId } = item as INewRate;
                setNewRates(
                    newRates.filter((rate) => rate.tempId !== tempId),
                    showingInbound
                );
            }
        };
        const addRate = () => {
            const { startDate, endDate, defaultInboundRate, defaultOutboundRate } = swapAgreement;
            const newRate = {
                startDate,
                endDate,
                tempId: 'temp' + tempRateId++ + Math.round(Math.random() * 1000)
            };
            const defaultRate = (showingInbound ? defaultInboundRate : defaultOutboundRate) as number;
            if (defaultRate !== null && !isNaN(defaultRate)) {
                (newRate as INewRate).rate = defaultRate;
            }
            setNewRates([newRate as INewRate, ...newRates], showingInbound);
        };
        const saveClick = () => {
            if (swapAgreement.startDate * 1000 < Date.now()) {
                setDisplayedPopupType(PopupType.pastDates);
            } else {
                save();
            }
        };
        const updateStatus = (status: SWAP_AGREEMENT_STATUS) => {
            dispatch(voiceActions.updateSwapAgreementStatus(agreementId, status, status === SWAP_AGREEMENT_STATUS.Rejected ? rejectReason : ''));
            setIsRejecting(false);
            setRejectReason('');
        };
        const onCommentChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
            setRejectReason(e.target.value);
        };
        const save = () => {
            dispatch(voiceActions.updateAgreement(draftInState!.swapAgreement.id, draftInState!));
        };
        const publish = () => {
            const draftId = draft!.swapAgreement.id;
            // If there are any unsaved changes, save and publish
            const action = disableSave ? voiceActions.publishAgreement(draftId) : voiceActions.updateAndPublishAgreement(draftId, draftInState!);
            dispatch(action);
            setDraftInState(undefined);
        };
        if (!agreement || agreement.swapAgreement.id !== agreementId) {
            return null;
        }

        const { swapAgreement, inboundRates, outboundRates } = draftInState || agreement;
        const gotUnsavedChanges = !!draft && !!draftInState && JSON.stringify(draft) !== JSON.stringify(draftInState);
        const gotUnappliedInbountRates =
            actualEditMode && (!!newInboundRates.length || inboundRates.some((item) => !!(item as IEditableRate).changes));
        const gotUnappliedOutboundRates =
            actualEditMode && (!!newOutboundRates.length || outboundRates.some((item) => !!(item as IEditableRate).changes));
        const gotUnappliedRates = gotUnappliedInbountRates || gotUnappliedOutboundRates;
        const cantPublish = actualEditMode && (gotUnappliedRates || !inboundRates.length || !outboundRates.length);
        const disableSave = actualEditMode && (!gotUnsavedChanges || gotUnappliedRates);
        const getPromptMessage = (location: Location) => {
            if (location.pathname === window.location.pathname || location.pathname === '/auth/login') {
                return true; // Allow query string changes, and redirect to login on session expiration
            }
            let msg;
            if (gotUnsavedChanges) {
                msg = 'There are unsaved changes';
            } else if (gotUnappliedInbountRates || gotUnappliedOutboundRates) {
                msg = 'There are unapplied destination changes';
            }
            return msg ? `${msg}.  Are you sure you want to leave?` : true;
        };
        const goBack = () => history.replace(setPathId(voicePaths.agreementsList, originalSA.accountId));
        const tabChanged = (tab: string) => {
            setPage(1);
            setShowingInbound(tab === Inbound);
        };
        const shownRates = (draftInState || agreement)[showingInbound ? 'inboundRates' : 'outboundRates'];
        const newRates = showingInbound ? newInboundRates : newOutboundRates;
        // TODO - perf - store paginated and sorted items in state and update when page/sort/filter/edit occurs
        const comparator = (a: any, b: any) => {
            const { by, order } = sorting;
            const [val1, val2] = [a[by], b[by]];
            return (order === 'asc' ? 1 : -1) * (typeof val1 === 'string' ? val1.localeCompare(val2) : val1 - val2);
        };
        const onSort = ({ key, sortingKey }: ITableColumnProps, isAsc: boolean) =>
            setSorting({
                by: sortingKey || key,
                order: isAsc ? 'asc' : 'desc'
            });
        const applyItemChanges = (item: IEditableRate) => {
            const { changes } = item;
            delete item.changes;
            Object.assign(item, changes);
            setSnackbarMessage('Rate Updated');
            setDraftInState({ ...draftInState! });
        };
        const discardItemChanges = (item: IEditableRate) => {
            delete item.changes;
            setDraftInState({ ...draftInState! });
        };
        const takeNewRate = (item: INewRate) => {
            removeRate(item); // Remove from new rates
            const { tempId } = item;
            delete item.tempId;
            item.id = tempId; // Set id so from now on changes will be handled differently
            shownRates.push(item); // Add to rates list
            setSnackbarMessage('Rate Added');
            setDraftInState({ ...draftInState! });
        };
        const getItemOptions = (item: IEditableRate, key: SelectableKey): string[] => {
            if (!item) {
                return [];
            }
            const otherKeys = (['destination', 'product', 'origin'] as SelectableKey[]).filter((k) => k !== key);
            const changes = item.changes || {};
            const otherValues = otherKeys.map((otherKey) => (changes.hasOwnProperty(otherKey) ? changes[otherKey] : item[otherKey]));
            const result: any = {};
            const keyToIndex: Record<SelectableKey, number> = {
                destination: 0,
                product: 1,
                origin: 2
            };
            rateOptions[showingInbound ? Direction.Payable : Direction.Receivable].forEach((combination) => {
                for (let i = 0; i < otherKeys.length; i++) {
                    const [otherKey, otherValue] = [otherKeys[i], otherValues[i]];
                    if (otherValue && otherValue !== combination[keyToIndex[otherKey]]) {
                        return;
                    }
                }
                const value = combination[keyToIndex[key]];
                if (value) {
                    result[value] = 1;
                }
            });
            return Object.keys(result);
        };
        const filterItems: IFilterItem[] = [
            { key: 'period', title: 'Period', type: FilterItemType.Date },
            {
                key: 'destination',
                title: 'Destination',
                type: FilterItemType.SearchSelect,
                options: getItemOptions(emptyItemForOptions as IEditableRate, 'destination') // Use the same method we use for rate editing
            }
        ];
        const itemChanged = (item: IEditableRate, key: keyof ISwapAgreementRate, value: any) => {
            switch (key) {
                case 'startDate':
                case 'endDate':
                    value = (value as number) / 1000;
                    break;
                case 'commitment':
                case 'rate':
                case 'incrementalRate':
                    value = parseBalance(value);
                    break;
            }
            if (item.id) {
                // Existing rate - update changes object
                const changes = item.changes || (item.changes = {});
                changes[key] = value;
                setDraftInState({ ...draftInState! });
            } else {
                // New rate - update in place
                (item as any)[key] = value;
                setNewRates([...newRates], showingInbound);
            }
        };
        const validateItem = (item: ISwapAgreementRate): Record<string, string> => {
            const { destination, startDate, endDate, rate } = item;
            const result: Record<string, string> = {};
            if (!destination) {
                result.destination = 'Destination is required';
            }
            if (startDate < swapAgreement.startDate) {
                result.startDate = 'Start date should be within the agreement period';
            }
            if (endDate <= startDate) {
                result.endDate = 'End date should be greater than Start date';
            } else if (endDate > swapAgreement.endDate) {
                result.endDate = 'End date should be within the agreement period';
            }
            if (isNaN(rate)) {
                result.rate = 'Rate is required';
            }
            return result;
        };
        const filterPredicate = (rate: IEditableRate) => {
            const { period, destination } = filters as any;
            const withChanges = { ...rate, ...rate.changes };
            if (destination && !(destination as string[]).includes(withChanges.destination)) {
                return false;
            }
            if (period) {
                const [startSec, endSec] = period as number[];
                const { startDate, endDate } = withChanges;
                if ((!startDate && !endDate) || (startDate && startDate > endSec) || (endDate && endDate < startSec)) {
                    return false;
                }
            }
            return true;
        };
        const getTableData = () => {
            let slice = [...shownRates]
                .filter(filterPredicate)
                .sort(comparator)
                .slice(PAGE_SIZE * (page - 1), PAGE_SIZE * page);
            if (actualEditMode) {
                // Display added rates first regardless of paging and sorting
                slice = (newRates as ISwapAgreementRate[]).concat(slice);
            }
            return slice.map((item) => {
                const { startDate, endDate } = item;
                const { tempId } = item as INewRate;
                const result: any = {
                    ...item,
                    id: item.id || tempId,
                    startDateFormatted: startDate && formatDate(startDate),
                    endDateFormatted: endDate && formatDate(endDate),
                    actions: ''
                };
                if (actualEditMode) {
                    const changes = (item as IEditableRate).changes || {};
                    const withChanges: ISwapAgreementRate = {
                        ...item,
                        ...changes
                    };
                    const rejects = validateItem(withChanges);
                    const isValid = !Object.keys(rejects).length;
                    result.commitment = (
                        <EditableCell
                            value={formatBalance(withChanges.commitment, 0)}
                            mask={commitmentMask}
                            onChange={itemChanged.bind(null, item, 'commitment')}
                        />
                    );
                    (['rate', 'incrementalRate'] as Array<keyof ISwapAgreementRate>).forEach((key) => {
                        result[key] = (
                            <EditableCell
                                value={formatBalance(withChanges[key], RATE_PRECISION, { roundToInteger: true })}
                                mask={rateMask}
                                onChange={itemChanged.bind(null, item, key)}
                                error={rejects[key]}
                            />
                        );
                    });
                    (['destination', 'product', 'origin'] as Array<keyof ISwapAgreementRate>).forEach((key) => {
                        result[key] = (
                            <SelectableCell
                                options={getItemOptions.bind(null, item, key as SelectableKey)}
                                value={withChanges[key] as string}
                                onChange={itemChanged.bind(null, item, key)}
                                error={rejects[key]}
                            />
                        );
                    });
                    (['startDate', 'endDate'] as Array<keyof ISwapAgreementRate>).forEach((key) => {
                        const val = withChanges[key] as number;
                        result[key + 'Formatted'] = (
                            <EditableDateCell
                                value={val && val * 1000}
                                format={periodDateFormat}
                                onChange={itemChanged.bind(null, item, key)}
                                error={rejects[key]}
                            />
                        );
                    });
                    const anyChanges = !!Object.keys(changes).length;
                    result.actions = (
                        <>
                            <IconButton tabIndex={-1} className="remove-btn" onClick={removeRate.bind(null, item)}>
                                <DeleteOutlineIcon />
                            </IconButton>
                            {anyChanges && (
                                <>
                                    <button className="rate-btn" disabled={!isValid} onClick={applyItemChanges.bind(null, item)}>
                                        Apply
                                    </button>
                                    <button className="rate-btn cancel" onClick={discardItemChanges.bind(null, item)}>
                                        Cancel
                                    </button>
                                </>
                            )}
                            {!!tempId && (
                                <button className="rate-btn" disabled={!isValid} onClick={takeNewRate.bind(null, item as INewRate)}>
                                    Add
                                </button>
                            )}
                        </>
                    );
                }
                return result;
            });
        };
        const getTablePlaceholder = () => {
            if (shownRates.length || newRates.length) {
                return null;
            }
            return (
                <div className="no-rates">
                    <p className="no-rates-title">Click the "+Add" button above to add rates</p>
                    <SvgEmptyStateSettlement />
                </div>
            );
        };
        const agreementDetailChanged = (key: string, value: any) => {
            switch (key) {
                case 'startDate':
                case 'endDate':
                    if (value) {
                        value /= 1000;
                    }
                    break;
                case 'defaultInboundRate':
                case 'defaultOutboundRate':
                    value = parseBalance(value);
                    break;
            }
            setDraftInState({
                ...(draftInState as ISwapAgreementDetails),
                swapAgreement: { ...swapAgreement, [key]: value }
            });
        };
        const renderAgreementDetail = (key: string) => {
            const value = (swapAgreement as any)[key];
            if (actualEditMode) {
                switch (key) {
                    case 'name':
                        return <EditableCell value={value} onChange={agreementDetailChanged.bind(null, key)} />;
                    case 'currency':
                        return value;
                    case 'startDate':
                    case 'endDate':
                        return (
                            <EditableDateCell
                                value={value && (value as number) * 1000}
                                format={periodDateFormat}
                                onChange={agreementDetailChanged.bind(null, key)}
                            />
                        );
                    case 'defaultInboundRate':
                    case 'defaultOutboundRate':
                        return (
                            <EditableCell
                                value={formatBalance(value, RATE_PRECISION, { roundToInteger: true })}
                                mask={rateMask}
                                onChange={agreementDetailChanged.bind(null, key)}
                            />
                        );
                }
            } else {
                switch (key) {
                    case 'status':
                        return value.replace(/_/g, ' ');
                    case 'startDate':
                    case 'endDate':
                        return moment.utc(value * 1000).format(periodDateFormat);
                    case 'defaultInboundRate':
                    case 'defaultOutboundRate':
                        return value === null || isNaN(value) ? 'N/A' : value;
                }
            }
            return value;
        };
        const renderRejectModal = () => (
            <Modal
                className="reject-modal"
                title="Reject Agreement"
                visible={isRejecting}
                onCancel={bind(setIsRejecting, null)}
                onOk={bind(updateStatus, SWAP_AGREEMENT_STATUS.Rejected)}
                okText="Reject"
            >
                <TextArea autoFocus placeholder={`Please provide reason for rejecting...`} rows={5} value={rejectReason} onChange={onCommentChange} />
            </Modal>
        );
        const getAgreementDetails = () => {
            return agrFields.map(({ key, title }) => (
                <div key={key} className="agreement-detail">
                    <div className="title">{title}</div>
                    <div className="value">{renderAgreementDetail(key)}</div>
                </div>
            ));
        };
        const pagination: IPagination = {
            page,
            totalItems: shownRates.length,
            pageSize: PAGE_SIZE
        };
        const getTab = (isInbound: boolean) => {
            const rates = isInbound ? inboundRates : outboundRates;
            const gotUnapplied = isInbound ? gotUnappliedInbountRates : gotUnappliedOutboundRates;
            const txt = isInbound ? Inbound : Outbound;
            let error = '';
            if (actualEditMode) {
                error = rates.length
                    ? gotUnapplied
                        ? 'There are some unapplied changes'
                        : ''
                    : `There should be at least one ${txt.toLowerCase()} destination`;
            }
            const title = (
                <>
                    {`${txt} Traffic (${rates.length})`}
                    {!!error && (
                        <Tooltip placement="right" title={error}>
                            <span className="tab-error" />
                        </Tooltip>
                    )}
                </>
            );
            return <TabPane tab={title} key={txt} />;
        };
        return (
            <main className="voice-agreement">
                <Prompt when={true} message={getPromptMessage} />
                <Helmet title="Clear Voice - Swap Agreement" />
                <Sticky>
                    <div className="voice-title-header">
                        <button className="circled-button" onClick={goBack}>
                            <Back title="Go Back" />
                        </button>
                        <div className="title">{swapAgreement.accountPresentedName || swapAgreement.accountName}</div>
                        <div className="agreement-details">{getAgreementDetails()}</div>
                        <span className="spacer" />
                        {originalSA.isEditable && (
                            <>
                                <span className="edit-label">Edit {!actualEditMode && swapAgreement.draftId ? 'Existing Draft' : ''}</span>
                                <Switch checkedChildren={<Check />} disabled={isNewDraft} checked={actualEditMode} onClick={toggleEdit} />
                            </>
                        )}
                    </div>
                </Sticky>
                <Tabs className="rates-tabs" onChange={tabChanged}>
                    {[true, false].map(getTab)}
                </Tabs>
                <div className={'tab-body' + (actualEditMode ? ' edit-mode' : '')}>
                    <div className="actions-bar">
                        <span className="dest-title">Destinations</span>
                        {actualEditMode && (
                            <button className="rate-btn add-rate" onClick={addRate}>
                                <span>+</span> Add
                            </button>
                        )}
                        <ActiveFilters items={filterItems} />
                        <span className="spacer" />
                        <Pagination pagination={pagination} onSelectPage={setPage} />
                    </div>
                    <FiltersPanel items={filterItems} currency="" />
                    <Table
                        cols={ratesColumns}
                        data={getTableData()}
                        noHeader={true}
                        noMoreOption={true}
                        onSortingColumnSelect={onSort}
                        notFrontendSortable={true}
                        placeholderGetter={getTablePlaceholder}
                    />
                    {actualEditMode && (
                        <div className="edit-panel">
                            <span>
                                <button className="agr-btn" disabled={disableSave} onClick={saveClick}>
                                    Save Draft
                                </button>
                                {draft && draft!.swapAgreement.id !== originalSA.id && (
                                    <button className="agr-btn cancel" onClick={bind(setDisplayedPopupType, PopupType.delete)}>
                                        Delete Draft
                                    </button>
                                )}
                                <button className="agr-btn" disabled={cantPublish} onClick={publish}>
                                    {disableSave ? '' : 'Save and '}Publish Changes
                                </button>
                            </span>
                        </div>
                    )}
                    {!actualEditMode && swapAgreement.status === SWAP_AGREEMENT_STATUS.Pending && (
                        <div className="pending-panel">
                            <Button className="reject" icon="close" onClick={bind(setIsRejecting, true)}>
                                REJECT
                            </Button>
                            <Button className="accept" icon="check" onClick={bind(updateStatus, SWAP_AGREEMENT_STATUS.Effective)}>
                                ACCEPT
                            </Button>
                        </div>
                    )}
                </div>
                <Modal
                    title={displayedPopupTexts[0]}
                    visible={!!displayedPopupType}
                    onCancel={bind(setDisplayedPopupType, false)}
                    onOk={onPopupOk}
                    okText="Continue"
                >
                    {displayedPopupTexts[1]}
                </Modal>
                {renderRejectModal()}
                <Snackbar
                    className="rate-snackbar"
                    open={!!snackbarMessage}
                    onClose={setSnackbarMessage.bind(null, undefined)}
                    message={<span>{snackbarMessage}</span>}
                    autoHideDuration={1500}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                />
            </main>
        );
    }
);
