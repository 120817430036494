import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { Button } from 'antd';
import BackIcon from '@ant-design/icons/LeftOutlined';

interface IBackButtonProps {
    classes?: string;
}

const BackButtonLayout: React.FC<IBackButtonProps & RouteComponentProps> = ({ classes, history, children }): JSX.Element => {
    const backButtonClickHandler = (event: React.MouseEvent): void => {
        event.preventDefault();
        event.stopPropagation();
        history.goBack();
    };

    return <Button size="small" onClick={backButtonClickHandler} icon={<BackIcon style={{ color: '#858282' }} />} />;
};

export const BackButton = withRouter(BackButtonLayout);
