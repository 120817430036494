import React from 'react';
import Select from 'react-select';
import './selectStyles.scss';
import HubIcon from '@material-ui/icons/DeviceHub';
import SearchIcon from '@material-ui/icons/Search';
import get from 'lodash/get';

export interface INewExternalPartner {
    value: string;
    label: string;
}

export interface IOption {
    value: string;
    label: string;
    [key: string]: any;
}

export interface ISelectProps {
    isSelectOpen?: boolean;
    overrideComponents?: Record<string, () => JSX.Element>;
    classNamePrefix?: string;
    containerClassName?: string;
    placeHolderText?: string;
    newExternalPartner?: INewExternalPartner;
    options: IOption[];
    selectedOptions: any;
    isOptionDisabled?(option: any): boolean;
    handleChange(selectedOption: any): void;
}

export const ClearSelect = ({
    handleChange,
    selectedOptions,
    overrideComponents,
    classNamePrefix,
    containerClassName,
    placeHolderText,
    options,
    newExternalPartner,
    isOptionDisabled
}: ISelectProps) => {
    const handleOnChange = (selectedOptionsFromSelect: any) => {
        handleChange(selectedOptionsFromSelect);
    };

    const CustomOption = (props: any) => {
        if (newExternalPartner && props.innerProps && props.data.value === newExternalPartner.value) {
            // tslint:disable-next-line:no-empty
            props.innerProps.onClick = () => {};
            return (
                <div ref={props.innerRef} {...props.innerProps} className={'select-menu link'}>
                    <a href="mailto:noa@clearx.io">{newExternalPartner.label}</a>
                </div>
            );
        }
        if (get(props, 'data.peerId')) {
            return (
                <div ref={props.innerRef} {...props.innerProps} className={'select-menu'}>
                    <span> {props.children}</span>
                    <HubIcon style={{ fontSize: 16 }} />
                </div>
            );
        }
        return (
            <div ref={props.innerRef} {...props.innerProps} className={'select-menu'}>
                <span> {props.children}</span>
            </div>
        );
    };

    const CustomNoOptionMsg = (selectProps: any) => {
        if (newExternalPartner) {
            return (
                <div ref={selectProps.innerRef} {...selectProps.innerProps} className={'select-menu link'}>
                    <a href="mailto:noa@clearx.io">{newExternalPartner.label}</a>
                </div>
            );
        } else {
            return (
                <div ref={selectProps.innerRef} {...selectProps.innerProps} className={'no-option-message-div'}>
                    <span> {selectProps.children}</span>
                </div>
            );
        }
    };

    const CustomValue = (props: any) => {
        return (
            <div ref={props.innerRef} {...props.innerProps} className={'select-placeholder'}>
                <SearchIcon style={{ fontSize: 18, marginRight: 6 }} />
                <span> {props.children}</span>
            </div>
        );
    };

    return (
        <Select
            value={selectedOptions}
            onChange={handleOnChange}
            options={options}
            isMulti
            menuIsOpen
            isOptionDisabled={isOptionDisabled}
            isSearchable
            closeMenuOnSelect={false}
            classNamePrefix={classNamePrefix || 'clear-select'}
            components={{
                Option: CustomOption,
                Placeholder: CustomValue,
                NoOptionsMessage: CustomNoOptionMsg,
                ...overrideComponents
            }}
            className={containerClassName || 'select-container'}
            placeholder={placeHolderText || 'Search For Partners'}
        />
    );
};
