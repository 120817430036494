import React, { useMemo, useState } from 'react';
import { Box } from '@material-ui/core';
import { T } from '@components/T/T';
import { Separator } from '@components/Separator/Separator';
import { Select } from 'antd';
import { SettlementCard } from '@modules/Roaming/components/SettlementDashboard/SettlementCard';
import { Currency } from '@helpers/currency';
import { ISettlementSummary } from '@reducers/settlements';
import { makeStyles } from '@material-ui/core/styles';
import { ExtendedTheme, FontColor, FontWeight } from '@components/theme';
import orderBy from 'lodash/orderBy';

enum SortType {
    HighestDiscrepancy = 'Highest Discrepancy',
    HighestNetPosition = 'Highest Net Position',
    OldestUpdate = 'Oldest Update',
    LatestUpdate = 'Latest Update',
    OldestCreatedContract = 'Oldest Created Contract',
    LatestCreatedContract = 'Latest Created Contract'
}

interface IKanbanColumnProps {
    title: string;
    type: 'open' | 'pending' | 'closed';
    totalLabel: string;
    totalValue: Currency;
    settlements: ISettlementSummary[];
    emptyStateText?: string;
}

const useStyles = makeStyles((theme: ExtendedTheme) => ({
    col: {
        '&:not(:last-of-type)': {
            marginRight: theme.spacing(19)
        }
    }
}));

export const SettlementKanbanColumn: React.FC<IKanbanColumnProps> = ({ type, title, totalLabel, settlements, totalValue, emptyStateText }) => {
    const classes = useStyles();
    const [sortType, setSortType] = useState(SortType.LatestUpdate);
    const sortedSettlements = useSortedSettlements(settlements, sortType);
    const isEmpty = useMemo(() => sortedSettlements.length === 0, [sortedSettlements]);
    return (
        <Box flex="1" className={classes.col}>
            <Box display="flex" alignItems="baseline" mb={10}>
                <T variant="h5" weight={FontWeight.Semibold}>
                    {title}
                </T>
                <Separator />
                <T variant="subtitle2" color={FontColor.B65}>
                    {totalLabel}{' '}
                </T>
                &nbsp;
                <T variant="subtitle2" color={FontColor.B85} weight={FontWeight.Semibold}>
                    {totalValue.toLocalString(0)}
                </T>
            </Box>
            <Box mb={5} display="flex" alignItems="baseline">
                {isEmpty ? (
                    <Box height="32px" />
                ) : (
                    <>
                        <T variant="subtitle2" color={FontColor.B45}>
                            Sort by
                        </T>
                        <Select dropdownMatchSelectWidth={false} bordered={false} defaultValue={sortType} onChange={setSortType}>
                            {Object.values(SortType).map((st, i) => (
                                <Select.Option key={i} value={st} title={st}>
                                    <T display="inline" variant="subtitle2">
                                        {st}
                                    </T>
                                </Select.Option>
                            ))}
                        </Select>
                    </>
                )}
            </Box>
            <Box>
                {isEmpty ? (
                    <Box py={17.5} bgcolor="#f8f8f8" textAlign="center">
                        <T variant="subtitle2" weight={FontWeight.Semibold} color={FontColor.B45}>
                            {emptyStateText}
                        </T>
                    </Box>
                ) : (
                    sortedSettlements.map((s) => (
                        <Box key={s.contractId} mb={3}>
                            <SettlementCard settlement={s} type={type} />
                        </Box>
                    ))
                )}
            </Box>
        </Box>
    );
};

SettlementKanbanColumn.defaultProps = {
    emptyStateText: ''
};

const useSortedSettlements = (settlements: ISettlementSummary[], sortType: SortType) => {
    return useMemo(() => {
        switch (sortType) {
            case SortType.HighestDiscrepancy: {
                return orderBy(settlements, [(s) => Math.abs(Number(s.netPositions[0]) - Number(s.netPositions[1]))], ['desc']);
            }
            case SortType.HighestNetPosition: {
                return orderBy(settlements, [(s) => Math.abs(Number(s.netPositions[s.myPartyIndex]))], ['desc']);
            }
            case SortType.OldestUpdate: {
                return orderBy(settlements, ['updatedAt'], ['asc']);
            }
            case SortType.LatestUpdate: {
                return orderBy(settlements, ['updatedAt'], ['desc']);
            }
            case SortType.LatestCreatedContract: {
                return orderBy(settlements, ['createdAt'], ['desc']);
            }
            case SortType.OldestCreatedContract: {
                return orderBy(settlements, ['createdAt'], ['asc']);
            }
            default: {
                return settlements;
            }
        }
    }, [settlements, sortType]);
};
