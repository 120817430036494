import * as React from 'react';

interface IPageTitleProps {
    title: string | JSX.Element;
}

export const PageTitle: React.StatelessComponent<IPageTitleProps> = ({ title }): JSX.Element => (
    <h1>
        <span className="page-title">{title}</span>
    </h1>
);
