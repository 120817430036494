import * as React from 'react';

interface IForgotPasswordResultProps {
    emailValue: string;
}

export const ForgotPasswordResult: React.StatelessComponent<IForgotPasswordResultProps> = ({ emailValue }): JSX.Element => (
    <div className="form-result">
        <p className="finish">Please check your email.</p>
        <p className="congrats">We sent an email to {emailValue} which contains a link to reset your Clear password.</p>
    </div>
);
