import { useDispatch, useSelector } from 'react-redux';
import { getMyPeers } from '@selectors/peers';
import { Box, Theme } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import { MainWrapper } from '@components/Partneships/partnershipsCommon';
import { PageWrapper } from '@components/PageWrapper/PageWrapper';
import { MultiSelectWithSideMenu } from '@components/multiSelect';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { T } from '@components/T/T';
import { useHistory, useRouteMatch } from 'react-router';
import agreementsActions from '@actions/agreements';
import { getPartnerships } from '@selectors/roaming';
import { PendingNotice, PendingYourApprovalNotice } from '@components/noticeBars/noticeBars';
import { DefaultUsageSource, EEA_COUNTRIES, PARTNERSHIP_STATUS, UsageSourceLabels } from '@clearblockchain/shared/partnership';
import { IPartnerships } from '@reducers/agreements';
import { ApprovedChip, PendingChip } from '@components/statusChips/statusChips';
import { Separator } from '@components/Separator/Separator';
import { SubHeader } from '@components';
import { Checkbox, Select } from 'antd';
import { FontColor, FontWeight } from '@components/theme';
import './partnerStyles.scss';
import { useAction } from '../../hooks/useAction';
import isEqual from 'lodash/isEqual';

const PADDING_X = 8;

const useStyles = makeStyles((theme: Theme) => ({
    mainWrapper: {
        display: 'flex',
        justifyContent: 'center'
    },
    settingsBoxWrapper: {
        display: 'flex',
        flexDirection: 'column',
        width: 1000,
        background: 'white'
    },
    settingsBoxHeader: {
        height: 50,
        display: 'flex',
        alignItems: 'center'
    },
    editListsWrapper: {
        display: 'flex',
        flexDirection: 'column'
    },
    buttonsRow: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
    sendButton: {
        borderRadius: 4,
        background: '#4A83FF',
        height: 40,
        color: 'white',
        padding: theme.spacing(3),
        '&:disabled': {
            color: 'rgba(0, 0, 0, 0.25)',
            background: '#F5F5F5',
            cursor: 'not-allowed'
        }
    },
    productWrapper: {
        display: 'flex',
        alignItems: 'center',
        border: `1px solid #EFEFEF`,
        minHeight: 40,
        padding: theme.spacing(2)
    }
}));
const SeparatorLine: React.FC = () => <Box height={'1px'} bgcolor={'#d9d9d9'} mb={3} mx={-8} />;

export const PartnerSettings: React.FC = () => {
    const dispatchPeerSupportAction = useAction(() => agreementsActions.doesPartnerSupportUsageTypePerService(peerId));

    const { params } = useRouteMatch();
    const { id: peerId } = params;
    const peers = useSelector(getMyPeers);
    const history = useHistory();
    const classes = useStyles();
    const dispatch = useDispatch();
    const { partnership, mySupportedUsageTypes } = useSelector(getPartnerships) as IPartnerships;
    const [selectedEuCountries, setSelectedEuCountries] = useState();
    const [selectedUsageSource, setSelectedUsageSource] = useState();
    const [doesMultiUsageTypeSupportedByPartner, setDoesMultiUsageTypeSupportedByPartner] = useState();

    useEffect(() => {
        isPeerVersionSupportUsageTypePerService();
        dispatch(agreementsActions.queryPartnership({ partnerId: peerId }));
        dispatch(agreementsActions.queryMySupportedUsageTypes());
    }, [peerId]);

    const isPeerVersionSupportUsageTypePerService = useCallback(async (): Promise<void> => {
        setDoesMultiUsageTypeSupportedByPartner(await dispatchPeerSupportAction(peerId));
    }, [peerId]);

    useEffect(() => {
        setSelectedEuCountries(partnership?.proposedChanges?.eea || partnership?.eea || null);
        const usageSourceState = partnership?.proposedChanges?.usageSource || partnership?.usageSource;
        setSelectedUsageSource(usageSourceState || DefaultUsageSource);
    }, [partnership]);

    const onBackButtonClick = useCallback(() => {
        history.replace({ pathname: '/dashboard/partnerships' });
    }, []);

    const onSendToPartnerClick = () => {
        dispatch(agreementsActions.proposePartnership(peerId, { eeaList: selectedEuCountries, usageSource: selectedUsageSource }));
    };

    const peer = peers.find((p) => p.id === peerId);
    const peerName = peer?.name;

    const setSelectedUsageProduct = useCallback(
        (v: any, { product, value }: any) => {
            const peerNodeVersion = peer?.version;
            let updatedUsageSource;
            if (!peerNodeVersion) {
                return;
            }
            if (doesMultiUsageTypeSupportedByPartner) {
                updatedUsageSource = { ...selectedUsageSource, [product.toLowerCase()]: value };
            }
            // BC purpose only - before 2.13.0 all products had to be the same usage source
            else {
                updatedUsageSource = {
                    moc: value,
                    mtc: value,
                    sms: value,
                    data: value,
                    volte: value
                };
            }
            setSelectedUsageSource(updatedUsageSource);
        },
        [peer?.version, doesMultiUsageTypeSupportedByPartner, selectedUsageSource]
    );

    if (partnership === undefined) {
        return null;
    }

    const status = partnership?.status;
    const partnershipInApproval = status && PARTNERSHIP_IN_APPROVAL_STATUSES.includes(status);

    const settingsChanged = partnership?.eea !== selectedEuCountries || !isEqual(partnership?.usageSource, selectedUsageSource);
    return (
        <PageWrapper>
            {partnership && <NoticeBar status={status!} partnershipId={partnership.id} />}
            <SubHeader withBackButton title="Partner Settings" />
            <MainWrapper className={classes.mainWrapper}>
                <Box className={classes.settingsBoxWrapper} px={PADDING_X}>
                    <Box className={classes.settingsBoxHeader} px={PADDING_X} mx={-PADDING_X}>
                        <Box color={'#41537D'}>
                            <T>{`Partner ${peerName}`}</T>
                        </Box>
                        <Box marginLeft={'auto'}>{status && <ChipsByStatus status={status} />}</Box>
                    </Box>
                    <SeparatorLine />
                    <Box className={classes.editListsWrapper}>
                        <Box mb={4}>
                            <T color={FontColor.B45}>EEA List</T>
                        </Box>
                        <Box mb={2}>
                            <Checkbox
                                disabled={partnershipInApproval}
                                checked={selectedEuCountries != null}
                                onClick={(e) => setSelectedEuCountries((e.target as any).checked ? EEA_COUNTRIES : null)}
                                data-t={'eea-checkbox'}
                            />
                            <Box display="inline" ml={2}>
                                <T weight={FontWeight.Semibold} variant={'caption'}>
                                    List in use
                                </T>
                            </Box>
                        </Box>
                        <MultiSelectWithSideMenu
                            lists={{
                                eea: { title: 'EEA', allItems: EEA_COUNTRIES, defaultSelectedItems: selectedEuCountries || [] }
                            }}
                            defaultListKey={'eea'}
                            onChange={setSelectedEuCountries}
                            disabled={selectedEuCountries == null}
                        />
                    </Box>
                    <Box mt={8}>
                        <SeparatorLine />
                    </Box>
                    <Box className={classes.editListsWrapper}>
                        <Box mb={4}>
                            <T color={FontColor.B45}>Charging Logic</T>
                        </Box>
                        <Box mb={2}>
                            <T weight={FontWeight.Semibold} variant={'caption'}>
                                Service Charging
                            </T>
                        </Box>
                        {doesMultiUsageTypeSupportedByPartner === false && (
                            <Box mb={2}>
                                <T variant={'caption'} color={FontColor.Warning}>
                                    * Your partner version is currently not supporting usage type per service. A usage type change will affect all
                                    services
                                </T>
                            </Box>
                        )}
                        <Box>
                            <Box className={classes.productWrapper} bgcolor={'#F4F4F4'}>
                                <Box flex={4}>
                                    <T weight={FontWeight.Semibold}>Service</T>
                                </Box>
                                <Box width={220} pl={2}>
                                    <T weight={FontWeight.Semibold}>Usage Type</T>
                                </Box>
                            </Box>
                            {selectedUsageSource &&
                                ['MOC', 'MTC', 'SMS', 'Data', 'VoLTE'].map((product) => {
                                    return (
                                        <Box className={classes.productWrapper} key={product} style={{ borderTop: 'none' }}>
                                            <Box flex={4}>
                                                <T>{product}</T>
                                            </Box>
                                            <Select
                                                dropdownMatchSelectWidth={false}
                                                data-t={`${product.toLowerCase()}-usage-type-button`}
                                                className={'partner-settings-select'}
                                                disabled={partnershipInApproval || mySupportedUsageTypes.length < 2}
                                                value={selectedUsageSource[product.toLowerCase()]}
                                                onChange={setSelectedUsageProduct}
                                                options={Object.entries(UsageSourceLabels).map(([value, label]) => ({
                                                    'data-t': `${product.toLowerCase()}-${label.split(' ')[0].toLowerCase()}`,
                                                    label,
                                                    value,
                                                    product
                                                }))}
                                            />
                                        </Box>
                                    );
                                })}
                        </Box>
                    </Box>
                    <Separator />
                    <Box className={classes.buttonsRow} my={8}>
                        <button disabled={partnershipInApproval || !settingsChanged} onClick={onSendToPartnerClick} className={classes.sendButton}>
                            Send to Partner
                        </button>
                    </Box>
                </Box>
            </MainWrapper>
        </PageWrapper>
    );
};

const ChipsByStatus: React.FC<{ status: PARTNERSHIP_STATUS }> = ({ status }) => {
    switch (status) {
        case PARTNERSHIP_STATUS.PENDING_INITIAL_APPROVAL:
        case PARTNERSHIP_STATUS.PENDING_YOUR_INITIAL_APPROVAL:
        case PARTNERSHIP_STATUS.PENDING_YOUR_APPROVAL:
        case PARTNERSHIP_STATUS.PENDING_APPROVAL:
            return <PendingChip />;
        case PARTNERSHIP_STATUS.APPROVED:
            return <ApprovedChip />;
    }
    return null;
};

const NoticeBar: React.FC<{ status: PARTNERSHIP_STATUS; partnershipId: string }> = ({ status, partnershipId }) => {
    const dispatch = useDispatch();

    const rejectSuggestedPartnership = () => {
        dispatch(agreementsActions.respondToSuggestedPartnership(partnershipId));
    };

    const sendApproval = () => {
        dispatch(agreementsActions.respondToSuggestedPartnership(partnershipId, true));
    };

    switch (status) {
        case PARTNERSHIP_STATUS.PENDING_YOUR_INITIAL_APPROVAL:
        case PARTNERSHIP_STATUS.PENDING_YOUR_APPROVAL: {
            return (
                <PendingYourApprovalNotice
                    onApprovalClick={sendApproval}
                    onRejectClick={rejectSuggestedPartnership}
                    noticeBarText={'Partnership has been modified by partner. Please review partnership details'}
                />
            );
        }
        case PARTNERSHIP_STATUS.PENDING_INITIAL_APPROVAL:
        case PARTNERSHIP_STATUS.PENDING_APPROVAL: {
            return <PendingNotice text="Pending Partner Approval" />;
        }
        default: {
            return null;
        }
    }
};

const PARTNERSHIP_IN_APPROVAL_STATUSES = [
    PARTNERSHIP_STATUS.PENDING_APPROVAL,
    PARTNERSHIP_STATUS.PENDING_YOUR_APPROVAL,
    PARTNERSHIP_STATUS.PENDING_YOUR_INITIAL_APPROVAL,
    PARTNERSHIP_STATUS.PENDING_INITIAL_APPROVAL
];
