import { connect, useSelector } from 'react-redux';
import { IRootState } from '@reducers';
import { AnyAction, Dispatch } from 'redux';
import { PasswordForm, IUserFields } from '@components/UserManagement/PasswordForm';
import authActions from '@actions/auth';
import { getErrorType } from '@selectors/auth';
import React from 'react';
import { T } from '@components/T/T';
import { Box } from '@material-ui/core';
import { FontWeight } from '@components/theme';

const ChangePasswordForm = connect(
    (state: IRootState) => ({
        isPasswordPolicyEnforced: state.app.isPasswordPolicyEnforced,
        isMfaEnabled: state.app.isMfaEnabled,
        isSucceeded: state.user.changePassword.isSuccess,
        qrCode: state.user.changePassword.qrCode,
        isLoading: state.user.changePassword.isLoading,
        errorMessage: state.user.changePassword.errorMessage,
        formTitle: 'Reset Password',
        successMessage: 'Password has been changed',
        oldPassword: '',
        username: state.user.lastRequestToLoginUsername
    }),
    (dispatch: Dispatch) => ({
        submitFunction: (userDetails: IUserFields): AnyAction => dispatch(authActions.changePassword(userDetails))
    })
)(PasswordForm);

export const ChangePassword: React.FC = () => {
    const errorType = useSelector(getErrorType);
    return (
        <>
            <ChangePasswordForm />
            {errorType && errorType === 'PasswordExpiredError' ? (
                <Box className="user-form-container" paddingTop={0}>
                    <T weight={FontWeight.Semibold}>Password expired or has been reset. Please change your password.</T>
                </Box>
            ) : null}
        </>
    );
};
