import { select, call } from 'redux-saga/effects';
import { getBackendRestUrlSelector, getBackendDownloadUrl } from '@selectors/app';
import { fileCompressor } from './fileCompressor';

export function* fileUploader(file: File, shouldCompress: boolean = false): IterableIterator<any> {
    const backendRestUrlSelector = yield select(getBackendRestUrlSelector);
    const form = new FormData();
    if (shouldCompress) {
        const blob: Blob = (yield call(fileCompressor, file)) as any;
        form.append('gzip', blob, file.name);
    } else {
        form.append('file', file);
    }

    const result = yield fetch((backendRestUrlSelector as unknown) as string, {
        method: 'POST',
        body: form
    }).then((res) => res.json());
    return result;
}

export function* downloadFile(hash: string, name: string): IterableIterator<any> {
    const downloadUrl = yield (select(getBackendDownloadUrl) as unknown) as string;

    const response: Response = (yield fetch((downloadUrl as unknown) as string, {
        method: 'POST',
        headers: {
            Accept: 'text/csv',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ hashes: [hash] })
    })) as any;

    response.blob().then((blob: Blob) => {
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = `${name}.tsv`;
        link.click();
        window.URL.revokeObjectURL(link.href);
    });
}
