import { createAction, createRequestAction } from '@helpers/actionCreator';

export enum SettlementOfferActionType {
    CREATE_SETTLEMENT_OFFER_REQUEST = 'CREATE:SETTLEMENT_OFFER::REQUEST',
    CREATE_SETTLEMENT_OFFER_SUCCESS = 'CREATE:SETTLEMENT_OFFER::REQUEST:SUCCESS',

    GET_SETTLEMENT_OFFER_REQUEST = 'GET:SETTLEMENT_OFFER::REQUEST',
    GET_SETTLEMENT_OFFER_SUCCESS = 'GET:SETTLEMENT_OFFER::REQUEST:SUCCESS',

    SETTLEMENT_OFFER_RECEIVED = 'SETTLEMENT_OFFER:UPDATE:RECEIVED',
    SETTLEMENT_OFFER_COMMENT_RECEIVED = 'SETTLEMENT_OFFER_COMMENT:UPDATE:RECEIVED',

    ACCEPT_SETTLEMENT_OFFER_REQUEST = 'ACCEPT:SETTLEMENT_OFFER::REQUEST',
    ACCEPT_SETTLEMENT_OFFER_SUCCESS = 'ACCEPT:SETTLEMENT_OFFER::REQUEST:SUCCESS',

    CREATE_SETTLEMENT_OFFER_COMMENT_REQUEST = 'CREATE:SETTLEMENT_OFFER_COMMENT::REQUEST',
    CREATE_SETTLEMENT_OFFER_COMMENT_REQUEST_SUCCESS = 'CREATE:SETTLEMENT_OFFER_COMMENT::REQUEST:SUCCESS',

    SET_CURRENCY_VIEW = 'SET:CURRENCY_VIEW'
}

export const settlementOfferActions = {
    getSettlementOffers: createRequestAction(
        SettlementOfferActionType.GET_SETTLEMENT_OFFER_REQUEST,
        'getSettlementOffers',
        (contractId: string, periodIndex: number, includeComments: boolean = false) => [contractId, periodIndex, includeComments]
    ),

    createSettlementOffer: createRequestAction(
        SettlementOfferActionType.CREATE_SETTLEMENT_OFFER_REQUEST,
        'createSettlementOffer',
        (contractId: string, settlementId: string, offerAmount: string, summary: string, comment: string) => [
            contractId,
            settlementId,
            offerAmount,
            summary,
            comment
        ]
    ),

    createSettlementOfferComment: createRequestAction(
        SettlementOfferActionType.CREATE_SETTLEMENT_OFFER_COMMENT_REQUEST,
        'createSettlementOfferComment',
        (contractId: string, offerId: string, comment: string) => [contractId, offerId, comment]
    ),

    acceptSettlementOffer: createRequestAction(
        SettlementOfferActionType.ACCEPT_SETTLEMENT_OFFER_REQUEST,
        'acceptSettlementOffer',
        (contractId: string, settlementId: string) => [contractId, settlementId]
    ),

    setCurrencyView: createAction(SettlementOfferActionType.SET_CURRENCY_VIEW, { payload: (currencyView: 'local' | 'original') => currencyView })
};
