import React, { useCallback, useMemo } from 'react';
import { FilterDropdownProps } from 'antd/es/table/interface';
import { Button, Form } from 'antd';
import Box from '@material-ui/core/Box';
import { T } from '@components/T/T';
import { Separator } from '@components/Separator/Separator';
import { FontWeight } from '@components/theme';

export const ColumnFilterContainer: React.FC<FilterDropdownProps> = ({
    confirm,
    selectedKeys,
    clearFilters: resetFilters,
    setSelectedKeys,
    children,
    ...rest
}) => {
    const initialFilters = useMemo(() => selectedKeys, []);
    const onSubmit = useCallback(() => {
        confirm();
    }, [confirm]);
    const onCancel = useCallback(() => {
        setSelectedKeys(initialFilters);
        confirm();
    }, [confirm]);

    const onResetFilters = useCallback(() => {
        resetFilters!();
    }, [resetFilters]);
    return (
        <Form onReset={onResetFilters} style={{ padding: `12px` }} onFinish={onSubmit}>
            <Box display="flex" alignItems={'center'} mb={4} color={'rgba(0,0,0,0.85)'}>
                <T variant={'caption'} weight={FontWeight.Semibold}>
                    Filter by value
                </T>
                <Separator />
                <Box clone pr={0}>
                    <Button size="small" htmlType="reset" type="link">
                        Clear
                    </Button>
                </Box>
            </Box>
            <Box mb={4}>{children}</Box>
            <Box display="flex" justifyContent="flex-end">
                <Box mr={2}>
                    <Button size="small" onClick={onCancel}>
                        Cancel
                    </Button>
                </Box>
                <Button size="small" htmlType="submit" type="primary">
                    OK
                </Button>
            </Box>
        </Form>
    );
};
