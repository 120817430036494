import React from 'react';

const SvgEmptyStateFooterImage = () => (
    <svg width="976" height="130" viewBox="0 0 976 130">
        <path
            fill="#E2EBFF"
            fillRule="evenodd"
            d="M451.176 497.166c336.755-133.59 513.176 1.14 587.37-195.666C1112.74 104.693 879.352 87 451.176 14 23-59-62 182.066-62 347.444c0 165.379 176.421 283.312 513.176 149.722z"
        />
    </svg>
);

export default SvgEmptyStateFooterImage;
