import { createSelector } from 'reselect';
import { IRootState } from '@reducers/index';
import { IDialogType, IStateModal, IStateSpinner, IStateUi } from '@reducers/ui';

const uiSelector = (state: IRootState): IStateUi => state.ui;

export const getSpinner = createSelector(uiSelector, (ui: IStateUi): IStateSpinner => ui.spinner);

export const getModal = createSelector(uiSelector, (ui: IStateUi): IStateModal => ui.modal);

export const getAddPartnerDialogStatus = createSelector(uiSelector, (ui: IStateUi): boolean => ui.dialog.isAddPartnerDialogOpen);

export const getIsWarningBeforeUploadingContractDialogOpen = createSelector(
    uiSelector,
    (ui: IStateUi): boolean => ui.dialog.isOpen && ui.dialog.openDialogType === IDialogType.WARNING_BEFORE_UPLOADING_NEW_CONTRACT
);

export const getIsWarningBeforeApprovingContractDialogOpen = createSelector(
    uiSelector,
    (ui: IStateUi): boolean => ui.dialog.isOpen && ui.dialog.openDialogType === IDialogType.WARNING_BEFORE_APPROVING_SUGGESTED_CONTRACT
);

const getOpenModalType = createSelector(uiSelector, (ui: IStateUi): string | null => {
    return ui.modal.isOpen ? ui.modal.modalType : null;
});

export const isDisputeResolutionModalOpen = createSelector(
    getOpenModalType,
    (modalType: string | null): boolean => modalType === 'disputeResolution'
);

export const getIsServerErrorDialogOpen = createSelector(
    uiSelector,
    (ui: IStateUi): boolean => ui.dialog.isOpen && ui.dialog.openDialogType === IDialogType.GENERAL_ERROR_FROM_SERVER
);

export const getDialogData = createSelector(uiSelector, (ui: IStateUi): Record<string, any> => ui.dialog.dialogProps);

export const getSessionExpirationWarningDialogStatus = createSelector(
    uiSelector,
    (ui: IStateUi): boolean => ui.dialog.isSessionExpirationWarningDialogOpen
);
