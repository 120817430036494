import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Box, makeStyles, Theme } from '@material-ui/core';
import { GeneralDialog } from '@components/Modal/GeneralDialog';
import { Checkbox } from 'antd';
import VisibleIcon from '@ant-design/icons/EyeOutlined';
import HiddenIcon from '@ant-design/icons/EyeInvisibleOutlined';
import { Separator } from '@components/Separator/Separator';
import { T } from '@components/T/T';
import { createPortal } from 'react-dom';
import { ColumnVisibility, IColumn, TableCtx } from '@components/ClearTable/ClearTable';

const useStyles = makeStyles((theme: Theme) => ({
    columnGrid: {
        columnCount: 2,
        columnGap: theme.spacing(8),
        padding: theme.spacing(6, 4)
    },
    column: {
        display: 'inline-block',
        width: '100%',
        margin: theme.spacing(1, 0)
    },
    checkbox: {
        marginRight: theme.spacing(3)
    },
    columnTag: {
        display: 'flex',
        border: '1px solid #d9d9d9',
        alignItems: 'center',
        padding: theme.spacing(1, 3),
        flex: 1,
        cursor: 'pointer',
        borderRadius: 4
    }
}));

export interface IManageTableColumnsDialogProps {
    close(): void;
}

export const ManageTableColumnsDialog: React.FC<IManageTableColumnsDialogProps> = ({ close }) => {
    const { columns, setColumns } = useContext(TableCtx);
    const [containerEl] = useState(document.createElement('div'));

    useEffect(() => {
        return () => {
            containerEl.remove();
        };
    }, []);

    const classes = useStyles();
    const [selectedCols, toggleSelected] = useState(columns.filter((c) => c.title && c.visibility !== ColumnVisibility.None));
    const onApprove = useCallback(() => {
        setColumns(selectedCols);
        close();
    }, [selectedCols]);

    const toggleColumn = useCallback(
        (index: number) => () => {
            const modifiedColumns = selectedCols.slice();
            const modifiedColumn = {
                ...modifiedColumns[index],
                visibility: modifiedColumns[index].visibility === ColumnVisibility.Visible ? ColumnVisibility.Hidden : ColumnVisibility.Visible
            };

            modifiedColumns.splice(index, 1, modifiedColumn);
            toggleSelected(modifiedColumns);
        },
        [selectedCols]
    );
    return createPortal(
        <GeneralDialog
            isDialogOpen
            headerText={'Manage Columns'}
            onApproveClick={onApprove}
            hasButtonRow
            onCancelClick={close}
            confirmText="OK"
            cancelText="Cancel"
        >
            <Box className={classes.columnGrid}>
                {selectedCols.map((c: IColumn, i: number) => (
                    <div key={c.key || c.dataIndex?.toString() || i} className={classes.column}>
                        <Box display="flex" alignItems="center" onClick={toggleColumn(i)}>
                            <Checkbox className={classes.checkbox} checked={c.visibility === ColumnVisibility.Visible} />
                            <Box className={classes.columnTag}>
                                <T>{c.title}</T>
                                <Separator />
                                {c.visibility === ColumnVisibility.Visible ? <VisibleIcon /> : <HiddenIcon />}
                            </Box>
                        </Box>
                    </div>
                ))}
            </Box>
        </GeneralDialog>,
        containerEl
    );
};
