import React from 'react';
import { Box } from '@material-ui/core';

interface IStatusChip {
    bgColor: string;
    color: string;
    text: string;
    border: string;
}

export const PendingChip: React.FC = () => <StatusChip text={'Pending'} color={'#FA541C'} bgColor={'#FFF2E8'} border={'1px solid #FFBB96'} />;

export const ApprovedChip: React.FC = () => <StatusChip text={'Approved'} color={'#52C41A'} bgColor={'#F6FFED'} border={'1px solid #B7EB8F'} />;

export const RejectedChip: React.FC = () => <StatusChip text={'Rejected'} color={'#F5222D'} bgColor={'#FFF1F0'} border={'1px solid #FFA39E'} />;

const StatusChip: React.FC<IStatusChip> = ({ bgColor, color, text, border }) => {
    return (
        <Box
            data-t="status"
            bgcolor={bgColor}
            color={color}
            border={border}
            borderRadius={'4px'}
            py={0.5}
            px={2}
            height={'30px'}
            width={'78px'}
            component={'span'}
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
        >
            {text}
        </Box>
    );
};
