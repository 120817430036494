import React from 'react';

export const CalendarSvg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <defs>
            <style>{`.cls-1{fill:none;stroke:#000;stroke-linecap:round;stroke-linejoin:round;stroke-width:2px;}`}</style>
        </defs>
        <g>
            <rect className="cls-1" x="1" y="3" width="22" height="20" rx="3" ry="3" />
            <line className="cls-1" x1="1" y1="9" x2="23" y2="9" />
            <line className="cls-1" x1="12" y1="5" x2="12" y2="1" />
            <line className="cls-1" x1="6" y1="5" x2="6" y2="1" />
            <line className="cls-1" x1="18" y1="5" x2="18" y2="1" />
            <line className="cls-1" x1="5" y1="14" x2="7" y2="14" />
            <line className="cls-1" x1="11" y1="14" x2="13" y2="14" />
            <line className="cls-1" x1="17" y1="14" x2="19" y2="14" />
            <line className="cls-1" x1="5" y1="18" x2="7" y2="18" />
            <line className="cls-1" x1="11" y1="18" x2="13" y2="18" />
            <line className="cls-1" x1="17" y1="18" x2="19" y2="18" />
        </g>
    </svg>
);
