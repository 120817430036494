import React from 'react';
import Box, { BoxProps } from '@material-ui/core/Box';

interface IIconLabel extends BoxProps {
    icon: React.ReactNode;
    iconMR?: number;
}

export const IconLabel: React.FC<IIconLabel> = ({ children, icon, iconMR, ...props }) => {
    return (
        <Box display="flex" alignItems="center" {...props}>
            <Box mr={iconMR || 3} clone>
                {icon}
            </Box>
            {children}
        </Box>
    );
};
