import { voiceType, DisputeOfferType, DisputeOfferStatus, ComparisonType, ViewBy, Direction } from '@actions/voice';
import { IAppAction } from './state';
import get from 'lodash/get';
import uniqBy from 'lodash/uniqBy';

import { PartnerAccountType } from '@components/AddPartners/AddPartners';
import { requestSuccessType, clearType, IPagination } from '@helpers/actionCreator';
import { agreementsType } from '@actions/agreements';

export enum AccountStatus {
    PENDING_CLEAR = 'PENDING_CLEAR',
    PENDING_YOUR_APPROVAL = 'PENDING_YOUR_APPROVAL',
    PENDING_PARTNER_APPROVAL = 'PENDING_PARTNER_APPROVAL',
    APPROVED = 'APPROVED',
    DELETED = 'DELETED'
}

export interface IAccount {
    currency: string;
    dateTimeOffset: number;
    disputeThreshold: number;
    id: string;
    name: string;
    presentedName: string;
    createdAt?: number | null;
    isPeer?: string;
    updatedAt?: number;
    status?: AccountStatus | null;
}

export type IAccounts = IAccount[];

export interface IAccountByPage {
    pagination: IPagination;
    items: IAccounts;
}

export interface ISettlement {
    accountId: string;
    accountName: string;
    accountPresentedName?: string;
    accountType?: PartnerAccountType;
    accountCurrency: string;
    amountDiff: number;
    amountDiffPer: number;
    endDate: number;
    id: string;
    invoiceFileHash: string;
    payableFileHash: string;
    startDate: number;
    status: string;
    totalAmount: number;
    totalInvoiceAmount: number;
    invoiceCDRCompareFileHash: string;
    invoicePartnerCDRCompareFileHash: string;
    CDRPartnerCDRCompareFileHash: string;
    comparisonFileHash: string;
    unratedCDRs: string;
    partnerRateSheetsExist: boolean;
    receivableFileHash: string;
    partnerReceivableFileHash: string;
    originalCDRFileHash: object;
    originalPartnerCDRFileHash: object;
    updatedDate: number;
}

export interface ISwapAgreement {
    id: string;
    draftId?: string;
    originalId?: string;
    name: string;
    accountPresentedName?: string;
    accountId: string;
    accountName: string;
    startDate: number;
    endDate: number;
    defaultInboundRate?: number;
    defaultOutboundRate?: number;
    status: string;
    isEditable: boolean;
    updatedDate: number;
}
export enum RateDirection {
    Inbound = 'INBOUND',
    Outbound = 'OUTBOUND'
}
export interface ISwapAgreementRate {
    id: string;
    destination: string;
    product: string;
    direction: RateDirection;
    startDate: number;
    endDate: number;
    rate: number;
    incrementalRate: number;
    commitment: number;
    origin: string;
}
export interface ISwapAgreementDetails {
    swapAgreement: ISwapAgreement;
    inboundRates: ISwapAgreementRate[];
    outboundRates: ISwapAgreementRate[];
}
export interface ISummary {
    aCalls: number;
    aMinutes: number;
    aAmount: number;
    bCalls: number;
    bMinutes: number;
    bAmount: number;
    agreedAmount: number;
    amountDiff: number;
    amountDiffPer: number;
}

export interface ICreatedDisputeData {
    isOwner: boolean;
    destination: string;
    currency: string;
    amountDiff: number;
    peerName: string;
}

export interface IDisputeOffer extends Partial<ICreatedDisputeData> {
    id: string;
    offerType: DisputeOfferType;
    amount: number;
    currency: string;
    link: string;
    status: DisputeOfferStatus;
    partnerStatusDescription?: string;
    accountPresentedName?: string;
}

export interface IPeriodFilters {
    destination: string[];
    product: string[];
    aRates: string[];
    discrepancies: string[];
}

export interface IVoicePagination {
    page?: number;
    pageSize?: number;
    totalItems?: number;
    totalPages?: number;
}

export interface IQueryResult<T = any> {
    items: T[];
    pagination: IVoicePagination;
}

export interface IPotentialPeersPartners {
    peerId?: string;
    presentedName: string;
    demo?: boolean;
}
export type IPotentialPartners = Record<string, IPotentialPeersPartners>;

export type IRatesheetCombination = [string, string | null, string | null];
export type IOnMatchedState = 0 | 1 | 'all';

export interface IStateVoice {
    invoiceFileHash: string;
    comparisonType?: ComparisonType;
    matchType?: IOnMatchedState;
    partnerRateSheetsExist: boolean;
    unratedCDRs: string;
    externalSettlementPeriodHash: string;
    fileUploadError: string;
    linkGenerateError: string;
    accounts: IAccounts;
    accountsByPage: IAccountByPage | null;
    settlements: ISettlement[];
    freshlyCreatedSettlement?: ISettlement;
    settlementUpdateCount: number;
    swapAgreementUpdateCount: number;
    settlementsPagination: IVoicePagination;
    periodSummary: ISummary | {};

    // Multi select option values to be used in query filters
    periodFilters: IPeriodFilters | {};
    fileDialogMessage: string;
    isFileDialogOpen: boolean;
    isFileUploading: boolean;
    period: IQueryResult;
    externalPeriods: { [key: string]: ISettlement };
    externalComparisonResults: { [key: string]: object };
    drilldownCDRsByDate: IQueryResult;
    drilldownCDRs: IQueryResult;
    disputeResolutionResult?: object;
    lastCreatedDisputeDate?: number;
    disputeResolutionOffers: { [id: string]: IDisputeOffer };
    status: string;
    payableDestinations: IRatesheetCombination[];
    receivableDestinations: IRatesheetCombination[];
    agreements: IQueryResult<ISwapAgreement>;
    agreement?: ISwapAgreementDetails;
    agreementDraft?: ISwapAgreementDetails;
    potentialPartners: IPotentialPartners | null;
}

const getEmptyQueryResult = () => ({ items: [], pagination: {} });

const initialState: IStateVoice = {
    invoiceFileHash: '',
    partnerRateSheetsExist: false,
    unratedCDRs: '',
    externalSettlementPeriodHash: '',
    fileUploadError: '',
    linkGenerateError: '',
    accounts: [] as IAccounts,
    accountsByPage: null,
    settlements: [] as ISettlement[],
    settlementUpdateCount: 0,
    swapAgreementUpdateCount: 0,
    periodSummary: {},
    periodFilters: {},
    settlementsPagination: {},
    fileDialogMessage: '',
    isFileDialogOpen: false,
    isFileUploading: false,
    externalPeriods: {},
    externalComparisonResults: {},
    period: getEmptyQueryResult(),
    drilldownCDRsByDate: getEmptyQueryResult(),
    drilldownCDRs: getEmptyQueryResult(),
    disputeResolutionOffers: {},
    status: '',
    payableDestinations: [],
    receivableDestinations: [],
    agreements: getEmptyQueryResult(),
    potentialPartners: null
};

export function voiceReducer(state = initialState, action: IAppAction): IStateVoice {
    switch (action.type) {
        case voiceType.VOICE_SELECT_COMPARISON_TYPE: {
            return {
                ...state,
                comparisonType: action.payload
            };
        }
        case voiceType.SELECT_MATCH_TYPE:
            return { ...state, matchType: action.payload };
        case voiceType.VOICE_SETTLEMENT_UPLOAD:
            return {
                ...state,
                fileDialogMessage: 'This might take a while. You will be notified once the operation completes.',
                isFileDialogOpen: true,
                isFileUploading: true
            };
        case voiceType.VOICE_FILEHASH_SEND_REQUEST_SUCCESS:
            return {
                ...state,
                isFileDialogOpen: false,
                isFileUploading: false
            };
        case voiceType.VOICE_FILEHASH_SEND_REQUEST_FAIL:
            return {
                ...state,
                fileDialogMessage: action.payload || action.requestAction.errorMessage,
                isFileDialogOpen: true,
                isFileUploading: false
            };

        case voiceType.VOICE_FILE_DIALOG_CLOSE:
            return { ...state, isFileDialogOpen: false };

        case voiceType.VOICE_GET_SINGLE_SETTLEMENT_PERIOD:
            return {
                ...state,
                period: {
                    items: state.period.items,
                    pagination: state.period.pagination
                }
            };
        case voiceType.VOICE_SINGLE_SETTLEMENT_CLEAR:
            return {
                ...state,
                period: {
                    items: [],
                    pagination: state.period.pagination
                }
            };

        case voiceType.VOICE_GET_SETTLEMENT_PERIOD_SUCCESS:
            return {
                ...state,
                externalPeriods: action.payload || {}
            };

        case voiceType.VOICE_GET_SINGLE_SETTLEMENT_PERIOD_SUCCESS:
            return {
                ...state,
                period: action.payload || getEmptyQueryResult()
            };
        case clearType(voiceType.GET_DRILLDOWN_CDRS):
            return {
                ...state,
                drilldownCDRs: getEmptyQueryResult()
            };
        case voiceType.GET_DRILLDOWM_CDRS_BY_DATE_CLEAR:
            return {
                ...state,
                drilldownCDRsByDate: getEmptyQueryResult()
            };
        case requestSuccessType(voiceType.GET_DRILLDOWN_CDRS):
            const viewBy = action.requestAction.payload.values[2];
            return viewBy === ViewBy.date
                ? {
                      ...state,
                      drilldownCDRsByDate: action.payload || getEmptyQueryResult()
                  }
                : {
                      ...state,
                      drilldownCDRs: action.payload || getEmptyQueryResult()
                  };
        case voiceType.VOICE_INVOICE_SEND_FAIL:
        case voiceType.VOICE_CDR_SEND_FAIL:
        case voiceType.VOICE_PARTNER_CDR_SEND_FAIL:
            return { ...state, fileUploadError: action.payload };

        case voiceType.VOICE_EXTERNAL_LINK_GENERATE_FAIL:
        case voiceType.VOICE_EXTERNAL_LINK_GENERATE_SUCCESS:
            return { ...state, externalSettlementPeriodHash: action.payload || '' };
        case voiceType.VOICE_EXTERNAL_LINK_GENERATE_RESET:
            return { ...state, externalSettlementPeriodHash: '' };

        case voiceType.VOICE_QUERY_SETTLEMENT_PERIOD_SUCCESS:
            return {
                ...state,
                settlements: action.payload.items,
                settlementsPagination: action.payload.pagination
            };

        case voiceType.VOICE_QUERY_ACCOUNTS_SUCCESS:
            const filteredAccounts = action.payload.filter((account: IAccount) => account.status === AccountStatus.APPROVED);
            return { ...state, accounts: filteredAccounts };

        case voiceType.VOICE_QUERY_ACCOUNTS_BY_PAGE_SUCCESS:
            return { ...state, accountsByPage: action.payload };

        case agreementsType.VOICE_ACCOUNT_UPDATE_RECEIVED:
            const pagination = get(state, 'accountsByPage.pagination');
            const items = get(state, 'accountsByPage.items', []);
            const freshValueToState = get(action, 'payload.objs', []);
            const updatedItems = uniqBy(freshValueToState.concat(items), 'id') as any;
            return { ...state, accountsByPage: { pagination, items: updatedItems } };
        case voiceType.GET_ALL_POTENTIAL_PARTNERS_SUCCESS:
            return { ...state, potentialPartners: action.payload };

        case voiceType.VOICE_GET_SINGLE_SETTLEMENT_PERIOD_SUMMARY:
            return { ...state, periodSummary: {} };
        case voiceType.VOICE_GET_SINGLE_SETTLEMENT_PERIOD_SUMMARY_SUCCESS:
            return { ...state, periodSummary: action.payload };

        case voiceType.VOICE_GET_SINGLE_SETTLEMENT_PERIOD_FILTERS:
            return { ...state, periodFilters: {} };
        case requestSuccessType(voiceType.VOICE_GET_SINGLE_SETTLEMENT_PERIOD_FILTERS):
            return { ...state, periodFilters: action.payload };
        case requestSuccessType(voiceType.VOICE_CREATE_SETTLEMENT_PERIOD):
            return {
                ...state,
                freshlyCreatedSettlement: action.payload as ISettlement
            };
        case clearType(voiceType.VOICE_CREATE_SETTLEMENT_PERIOD):
            return { ...state, freshlyCreatedSettlement: undefined };
        case voiceType.VOICE_SETTLEMENT_PERIOD_UPDATE: {
            let { settlementUpdateCount } = state;
            settlementUpdateCount++;
            return { ...state, settlementUpdateCount };
        }
        case requestSuccessType(voiceType.VOICE_CREATE_DISPUTE_OFFER):
            const lastCreatedDisputeDate = Date.now();
            action.payload.status !== DisputeOfferStatus.Accept
                ? (state = {
                      ...state,
                      disputeResolutionResult: { result: action.payload.id },
                      lastCreatedDisputeDate
                  })
                : (state = { ...state, lastCreatedDisputeDate });
        // Move on to store the payload
        case requestSuccessType(voiceType.VOICE_GET_EXTERNAL_DISPUTE_OFFER):
        case requestSuccessType(voiceType.VOICE_GET_DISPUTE_OFFER):
            {
                // Update last status of displayed comparison results if necessary
                const offer = action.payload;
                let { period } = state;
                const periodIndex = period.items.findIndex(({ lastDisputeOfferId }: any) => lastDisputeOfferId === offer.id);
                if (periodIndex > -1) {
                    period = { ...period };
                    period.items[periodIndex] = {
                        ...period.items[periodIndex],
                        lastDisputeStatus: offer.status
                    };
                    state = { ...state, period };
                }
            }
            let { disputeResolutionOffers } = state;
            disputeResolutionOffers = {
                ...disputeResolutionOffers,
                [action.payload.id]: action.payload
            };
            return { ...state, disputeResolutionOffers };
        case requestSuccessType(voiceType.VOICE_UPDATE_DISPUTE_OFFER_STATUS):
            // TODO - when api starts returning the dispute offer, should converge this with get dispute offer reeducer
            let { disputeResolutionOffers: offers } = state;
            const [id, newStatus] = action.requestAction.payload.values;
            if (offers[id]) {
                offers = { ...offers, [id]: { ...offers[id], status: newStatus } };
            }
            return { ...state, disputeResolutionOffers: offers };
        case clearType(voiceType.VOICE_CREATE_DISPUTE_OFFER):
            return { ...state, disputeResolutionResult: undefined };

        case requestSuccessType(voiceType.GET_RATESHEET_DESTINATIONS):
            let { payableDestinations, receivableDestinations } = state;
            if ((action.requestAction.payload.values[1] as Direction) === Direction.Payable) {
                payableDestinations = action.payload;
            } else {
                receivableDestinations = action.payload;
            }
            return { ...state, payableDestinations, receivableDestinations };
        // SWAP AGREEMENTS
        case requestSuccessType(voiceType.GET_AGREEMENTS):
            return { ...state, agreements: action.payload };
        case requestSuccessType(voiceType.GET_AGREEMENT):
            return { ...state, agreement: action.payload };
        case requestSuccessType(voiceType.GET_DRAFT_AGREEMENT):
            return { ...state, agreementDraft: action.payload };
        case clearType(voiceType.GET_DRAFT_AGREEMENT):
            return { ...state, agreementDraft: undefined };
        case requestSuccessType(voiceType.UPDATE_AGREEMENT): {
            const updated = action.payload as ISwapAgreementDetails;
            const {
                swapAgreement: { id: updatedId }
            } = updated;
            const { agreement, agreementDraft } = state;
            // Update the draft
            if (agreementDraft && agreementDraft.swapAgreement.id === updatedId) {
                state = { ...state, agreementDraft: updated };
            }
            // In case of NEW_DRAFT - the agreement itself is also the draft
            if (agreement && agreement.swapAgreement.id === updatedId) {
                state = { ...state, agreement: updated };
            }
            return state;
        }
        // UPDATE_AGREEMENT_STATUS runs upon save draft action
        case requestSuccessType(voiceType.UPDATE_AGREEMENT_STATUS):
        // PUBLISH_AGREEMENT runs upon save and publish draft action
        case requestSuccessType(voiceType.PUBLISH_AGREEMENT): {
            const { agreement } = state;
            if (agreement && agreement.swapAgreement.id === action.payload.id) {
                return {
                    ...state,
                    agreement: { ...agreement, swapAgreement: action.payload }
                };
            }
            return state;
        }
        case requestSuccessType(voiceType.DELETE_AGREEMENT): {
            const deletedId = action.requestAction.payload.values[0];
            const { agreement } = state;
            // If deleted agreement is a draft - update parent.draftId
            if (agreement && agreement.swapAgreement.draftId === deletedId) {
                return {
                    ...state,
                    agreement: {
                        ...agreement,
                        swapAgreement: { ...agreement.swapAgreement, draftId: undefined }
                    }
                };
            }
            return state;
        }
        case requestSuccessType(voiceType.CREATE_AGREEMENT_DRAFT): {
            const { id: createdId, originalId } = action.payload as ISwapAgreement;
            const { agreement } = state;
            // Update parent.draftId
            if (agreement && agreement.swapAgreement.id === originalId) {
                return {
                    ...state,
                    agreement: {
                        ...agreement,
                        swapAgreement: { ...agreement.swapAgreement, draftId: createdId }
                    }
                };
            }
        }

        case agreementsType.VOICE_SWAP_AGREEMENT_UPDATE_RECEIVED: {
            let { swapAgreementUpdateCount } = state;
            swapAgreementUpdateCount++;
            return { ...state, swapAgreementUpdateCount };
        }
        // END OF SWAP AGREEMENTS
        default:
            return state;
    }
}
