import { createSelector } from 'reselect';
import { Currency } from '@helpers/currency';
import moment from 'moment';
import { IRootState } from '@reducers';
import { ISettlementSummary } from '@reducers/settlements';
import { BillingStatement, BillingStatementStatus } from '@clearblockchain/shared/billing-statement';
import { getRouterQuery } from '@selectors/router';
import { SettlementApprovalStatus } from '@clearblockchain/shared/period';

export const selectSettlements = createSelector(
    (state: IRootState) => state.settlements,
    (settlements) => settlements.settlements
);

export const selectPendingSettlements = createSelector(selectSettlements, (settlements) =>
    settlements.filter((s) => s.status === BillingStatementStatus.Open && !isOldContractSettled(s))
);

export const selectSettledSettlements = createSelector(selectSettlements, (settlements) =>
    settlements.filter((s) => s.status === BillingStatementStatus.Closed || isOldContractSettled(s))
);
export const selectTotalSettledValue = createSelector(selectSettledSettlements, sumSettlementValue);

export const selectTotalThisMonth = createSelector(
    selectSettledSettlements,
    () => moment().startOf('month'),
    (settlements, startOfMonth) => {
        const endOfMonth = moment().endOf('month');
        const settlementsThisMonth = settlements.filter((s) => moment(s.updatedAt * 1e3).isBetween(startOfMonth, endOfMonth));
        return sumSettlementValue(settlementsThisMonth);
    }
);

export const isOldContractInNegotiation = (settlement: ISettlementSummary | BillingStatement) => {
    return Boolean(
        settlement?.perPartyState &&
            Object.values(settlement.perPartyState).some((party) => party.settlementApprovalStatus === SettlementApprovalStatus.Approved)
    );
};
export const isOldContractSettled = (settlement: ISettlementSummary | BillingStatement) => {
    return Boolean(
        settlement?.perPartyState &&
            Object.values(settlement.perPartyState).every((party) => party.settlementApprovalStatus === SettlementApprovalStatus.Approved)
    );
};

export const selectAnalyzedAndNegotiatedSettlements = createSelector(selectPendingSettlements, (settlements) => {
    const inNegotiation: ISettlementSummary[] = [];
    const readyToAnalyze: ISettlementSummary[] = [];
    settlements.forEach((s) => {
        if (s.activeOfferAmount || isOldContractInNegotiation(s)) {
            inNegotiation.push(s);
        } else {
            readyToAnalyze.push(s);
        }
    });
    return { inNegotiation, readyToAnalyze };
});

export const selectTotalReadyToAnalyzeValue = createSelector(selectAnalyzedAndNegotiatedSettlements, ({ readyToAnalyze }) =>
    sumSettlementValue(readyToAnalyze)
);

export const selectTotalNegotiatedValue = createSelector(selectAnalyzedAndNegotiatedSettlements, ({ inNegotiation }) =>
    sumSettlementValue(inNegotiation)
);

export const selectTotalPendingValue = createSelector(selectPendingSettlements, sumSettlementValue);

function sumSettlementValue(settlements: ISettlementSummary[]): Currency {
    const c = settlements.reduce((total, { balances, currency, myPartyIndex }) => {
        const b = new Currency(Math.abs(Number(balances[myPartyIndex])), currency);
        return total + b.local;
    }, 0);
    return new Currency(c, Currency.localCurrency);
}

export const selectDashboardYear = createSelector(getRouterQuery, (query) => {
    return Number(query['year']) || moment().get('year') - 1;
});
