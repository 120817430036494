import * as React from 'react';
import { Form, reduxForm, Field, InjectedFormProps, formValueSelector } from 'redux-form';
import { Dispatch } from 'redux';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { IRootState } from '@reducers/index';
import { InputField } from '@components/Fields';
import { required, email } from '@helpers/index';

import { ForgotPasswordResult } from './ForgotPasswordResult';

interface IForgotPasswordData {
    email: string;
}

interface IForgotPasswordProps {
    emailValue: string;
    location: any;
}

type FormPropsType = IForgotPasswordProps & InjectedFormProps<IForgotPasswordData, IForgotPasswordProps>;

@connect(
    (state: IRootState & IForgotPasswordProps): any => ({
        emailValue: formValueSelector('forgotPassword')(state, 'email')
    }),
    (dispatch: Dispatch): any => ({})
)
class ForgotPassword extends React.Component<FormPropsType> {
    constructor(props: FormPropsType) {
        super(props);

        const { location, initialize } = props;
        const authEmail = location.state && location.state.authEmail;

        initialize({ email: !authEmail ? '' : authEmail });
    }

    renderForm() {
        const { handleSubmit, valid, emailValue } = this.props;

        return (
            <Form className="forgot-pswd-form" onSubmit={handleSubmit((values) => values)}>
                <p className="title">Reset Your Password</p>
                <p className="description">Enter your email address and we’ll send you a link to reset your password.</p>
                <Field name="email" component={InputField} label="Email" validate={[required, email]} />
                <div className="form-actions">
                    <Link
                        to={{
                            pathname: '/auth/login',
                            state: { authEmail: emailValue }
                        }}
                    >
                        Back to Login
                    </Link>
                    <button className="btn btn-primary" type="submit" disabled={!valid}>
                        Send Password Reset Link
                    </button>
                </div>
            </Form>
        );
    }

    render() {
        return this.props.submitSucceeded ? <ForgotPasswordResult emailValue={this.props.emailValue} /> : this.renderForm();
    }
}

export const ForgotPasswordForm = reduxForm<IForgotPasswordData, IForgotPasswordProps>({ form: 'forgotPassword' })(ForgotPassword);
