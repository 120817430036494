import React from 'react';
import { Card } from 'antd';
import { Box, useTheme } from '@material-ui/core';
import { useSelector } from 'react-redux';
import {
    selectAnalyzedAndNegotiatedSettlements,
    selectTotalReadyToAnalyzeValue,
    selectTotalNegotiatedValue,
    selectSettledSettlements,
    selectTotalSettledValue
} from '@selectors/roaming';
import { SettlementKanbanColumn } from '@modules/Roaming/components/SettlementDashboard/SettlementKanban/SettlementKanbanColumn';

export const SettlementKanban: React.FC = () => {
    const { inNegotiation: negotiatedSettlements, readyToAnalyze: analyzedSettlements } = useSelector(selectAnalyzedAndNegotiatedSettlements);
    const closedSettlements = useSelector(selectSettledSettlements);
    const totalClosedSettlements = useSelector(selectTotalSettledValue);
    const totalAnalyzedSettlements = useSelector(selectTotalReadyToAnalyzeValue);
    const totalNegotiatedSettlements = useSelector(selectTotalNegotiatedValue);
    const theme = useTheme();
    return (
        <Card bodyStyle={{ padding: theme.spacing(6) }} bordered={false}>
            <Box display="flex" justifyContent="space-between">
                <SettlementKanbanColumn
                    type="open"
                    title="Analyze"
                    totalLabel="Total to Analyze"
                    totalValue={totalAnalyzedSettlements}
                    settlements={analyzedSettlements}
                    emptyStateText="No Contracts To Analyze Yet"
                />
                <SettlementKanbanColumn
                    type="pending"
                    title="Negotiate"
                    totalLabel="Total in Negotiation"
                    totalValue={totalNegotiatedSettlements}
                    settlements={negotiatedSettlements}
                    emptyStateText="Start Making Offers to Negotiate"
                />
                <SettlementKanbanColumn
                    type="closed"
                    title="Settled"
                    totalLabel="Total Settled"
                    totalValue={totalClosedSettlements}
                    settlements={closedSettlements}
                    emptyStateText="No Settled Contracts Yet"
                />
            </Box>
        </Card>
    );
};
