import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Dialog } from '@components';
import uiActions from '@actions/ui';
import './styles.scss';
import { IDialogProps } from '@components/Modal/Dialog';

export const DialogContainer: React.FC<Partial<IDialogProps>> = (props) => {
    const {
        style,
        headerText,
        isDefaultHeader,
        classList,
        children,
        headerClassName,
        hideCloseButton,
        overlayClassName,
        isOpen,
        onClose,
        HeaderComponent,
        FooterComponent,
        shouldCloseOnClickOutside
    } = props;
    const dispatch = useDispatch();
    const closeDialog = useCallback(() => {
        if (onClose) {
            onClose();
        }
        dispatch(uiActions.hideDialog());
    }, [dispatch, onClose]);

    return (
        <Dialog
            style={style}
            classList={classList}
            isOpen={isOpen}
            onClose={closeDialog}
            headerText={headerText}
            shouldCloseOnClickOutside={shouldCloseOnClickOutside}
            isDefaultHeader={isDefaultHeader}
            headerClassName={headerClassName}
            hideCloseButton={hideCloseButton}
            overlayClassName={overlayClassName}
            HeaderComponent={HeaderComponent}
            FooterComponent={FooterComponent}
        >
            {children}
        </Dialog>
    );
};
