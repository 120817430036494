import React from 'react';
import { formatBalance } from '@helpers/formaters';

interface IDiffCellProps {
    actual: string;
    percentage: number;
    actualRaw: string;
}

const isNA = (val: any) => val === null || val === undefined;
export const DiffCell = ({ actual, percentage, actualRaw }: IDiffCellProps) =>
    isNA(percentage) ? (
        <span className="na">N/A</span>
    ) : (
        <div className={'percentage' + (percentage < -1 ? ' warn' : '')}>
            <span>({formatBalance(percentage, 2, { roundToInteger: true, actualBalance: actualRaw })}%)</span> {actual}
        </div>
    );
