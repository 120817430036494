import * as React from 'react';

import * as ReactDateRange from 'react-date-range';
const DateRangePicker = ReactDateRange.DateRange as any;

import enhanceWithClickOutside from 'react-click-outside';

import './styles.scss';

const selectionRange = {
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection'
};

export const DateRangeField = enhanceWithClickOutside(
    class extends React.Component<any, any> {
        state = {
            open: false
        };

        open = () => {
            this.setState({ open: true });
        };

        close = () => {
            this.setState({ open: false });
        };

        clear = () => {
            // TODO need implement clear.
            this.close();
        };

        handleCloseClick = ({ target, currentTarget }: React.MouseEvent) => target === currentTarget && this.close();

        render() {
            const {
                input: { value, onChange }
            } = this.props;
            return (
                <div className="field field-dates-range">
                    <button onClick={this.open} className="field-btn">
                        Dates
                    </button>
                    <div onClick={this.handleCloseClick} className={'date-range__backdrop' + (this.state.open ? ' is-open' : '')}>
                        <div className="date-range__popup">
                            <DateRangePicker ranges={value.selection ? [value.selection] : [selectionRange]} months={2} onChange={onChange} />
                            <div className="date-range__bottom">
                                <button className="btn" onClick={this.clear}>
                                    Clear
                                </button>
                                <button className="btn btn-primary" onClick={this.close}>
                                    Apply
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    }
);
