import { put, select, takeLatest } from 'redux-saga/effects';

import transactionsActions, { agreementsType } from '@actions/agreements';
import { getBackendRestUrlSelector } from '@selectors/app';
import { AnyAction } from 'redux';

import first from 'lodash/first';
import values from 'lodash/values';
import { history } from '../main';
import uiActions from '@actions/ui';
import { fileUploader } from './utilities/fileUploader';
import { requestSuccessType } from '@helpers/actionCreator';
import { IDialogType } from '@reducers/ui';
import { agreementsSelector, DocType, getLastStateOfUpdatedContract } from '@selectors/roaming';
import { SettlementStatus } from '@clearblockchain/shared/contract';

function* createFromFile(action: AnyAction) {
    yield put(uiActions.incrementSpinnerPending());
    const response = yield fileUploader(action.payload.file);
    const fileHash = first(values(response));
    const fileName = Object.keys(response)[0];
    try {
        yield put(action.payload.actionToExecute(action.payload, fileHash, fileName));
        yield put(uiActions.decrementSpinnerPending());
    } catch (e) {
        console.error({ e });
        yield put(uiActions.decrementSpinnerPending());
        yield put(
            uiActions.showDialog(IDialogType.GENERAL_ERROR_FROM_SERVER, {
                message: 'We are are sorry, something went wrong.'
            })
        );
    }
}

function isProposalInUrl(url: string) {
    const [x, dashbaord, roaming, agreementType] = url.split('/');
    return agreementType === DocType.PROPOSAL;
}

function* updateUrlToNewlyCreatedContract(action: AnyAction) {
    history.replace(`/dashboard/roaming/contracts/${action.payload}`);
}

function* updateUrlIfNeeded(action: AnyAction) {
    // in case we sent a proposalId to fetch proposal but already have contract for this proposal we should replace url to be for this of a contract
    const items = action?.payload?.items;
    if (items.length === 1 && items[0].config && isProposalInUrl(history.location.pathname)) {
        history.replace(`/dashboard/roaming/contracts/${items[0].id}`);
    }
}

function* createContractSuccess(action: AnyAction) {
    history.push(`/dashboard/roaming/proposal/${action.payload}`);
}

function* getFile(action: AnyAction) {
    const backendRestUrlSelector = yield select(getBackendRestUrlSelector);
    window.open(backendRestUrlSelector.replace('upload', '') + action.payload, '_blank');
}

function* showSpinner() {
    yield put(uiActions.showSpinner());
}

function* hideSpinner() {
    yield put(uiActions.hideSpinner());
}

function* showDialog(action: AnyAction, msg?: string) {
    yield put(
        uiActions.showDialog(IDialogType.GENERAL_ERROR_FROM_SERVER, {
            message: msg || 'We are sorry, something went wrong'
        })
    );
}

function* incrementSpinner() {
    yield put(uiActions.incrementSpinnerPending());
}

function* decrementSpinner() {
    yield put(uiActions.decrementSpinnerPending());
}

// Clear the spinner for usage data upload once relevant contract state changed
function* waitForLogSubmitted(action: AnyAction) {
    const updatingContract = yield select(getLastStateOfUpdatedContract);
    const lastStatusOfUpdatingContract = updatingContract?.settlementStatus;
    const contract = action.payload?.objs?.[0];
    // after agreement finished to update we want to query latest billing statements to get updated results
    if (lastStatusOfUpdatingContract === SettlementStatus.SETTLEMENT_IN_PROGRESS && contract?.settlementStatus === SettlementStatus.SETTLED) {
        yield put(transactionsActions.agreementsQueryBillingStatementRequest(contract?.id));
    }
    const { recentlyUploadedUsageContractId } = yield select(agreementsSelector);
    if (recentlyUploadedUsageContractId && recentlyUploadedUsageContractId === contract?.id) {
        const partyIndex = contract?.myPartyIndex;
        const isLogSubmitted = contract?.logSubmitted?.[partyIndex] || false;
        if (isLogSubmitted) {
            yield put({ type: agreementsType.AGREEMENTS_CLEAR_PENDING_USAGE_DATA_CONTRACT });
            yield put(uiActions.decrementSpinnerPending());
        }
    }
}

export const sagaAgreement = function*(): IterableIterator<any> {
    yield takeLatest(agreementsType.AGREEMENTS_CREATE_FROM_FILE, createFromFile);
    yield takeLatest(requestSuccessType(agreementsType.ROAMING_PROPOSE_CONTRACT), createContractSuccess);
    yield takeLatest(agreementsType.AGREEMENTS_APPROVE_SUCCESS, updateUrlToNewlyCreatedContract);
    yield takeLatest(agreementsType.GET_AGREEMENTS_SUCCESS, updateUrlIfNeeded);

    yield takeLatest([agreementsType.ROAMING_EXPORT_BREAKDOWN_SUCCESS], getFile);
    yield takeLatest(agreementsType.ROAMING_EXPORT_BREAKDOWN_FAIL, showDialog);

    yield takeLatest([agreementsType.GET_URL_FOR_CONTRACT_RELATED_FILE_SUCCESS], getFile);
    yield takeLatest(agreementsType.GET_URL_FOR_CONTRACT_RELATED_FILE_FAIL, showDialog);

    // HACK - sustain spinner from upload success until contract's logSubmitted change by update
    yield takeLatest(requestSuccessType(agreementsType.AGREEMENTS_SETTLEMENT_DATA_SUBMIT_HASH), incrementSpinner);
    yield takeLatest(agreementsType.AGREEMENTS_CONTRACT_UPDATE_RECEIVED, waitForLogSubmitted);

    yield takeLatest(agreementsType.AGREEMENTS_QUERY_ROAMING_USAGE_REQUEST, showSpinner);
    yield takeLatest(agreementsType.AGREEMENTS_QUERY_ROAMING_USAGE_SUCCESS, hideSpinner);
    yield takeLatest(agreementsType.AGREEMENTS_QUERY_ROAMING_USAGE_FAIL, hideSpinner);

    yield takeLatest(agreementsType.RESPONSE_TO_SUGGESTED_CONTRACT_PRICES_REQUEST, incrementSpinner);
    yield takeLatest(agreementsType.RESPONSE_TO_SUGGESTED_CONTRACT_PRICES_SUCCESS, decrementSpinner);
    yield takeLatest(agreementsType.RESPONSE_TO_SUGGESTED_CONTRACT_PRICES_FAIL, decrementSpinner);

    yield takeLatest(agreementsType.UPDATE_RUN_SETTLEMENT_APPROVAL_STATUS_REQUEST, incrementSpinner);
    yield takeLatest(agreementsType.UPDATE_RUN_SETTLEMENT_APPROVAL_STATUS_SUCCESS, decrementSpinner);
    yield takeLatest(agreementsType.UPDATE_RUN_SETTLEMENT_APPROVAL_STATUS_FAIL, decrementSpinner);

    yield takeLatest(agreementsType.GET_USAGE_TO_EXPORT_REQUEST_FAIL, (action) =>
        showDialog(action, 'Sorry, we could not export your data. Please try again')
    );
};
