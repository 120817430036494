import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router';
import { Box } from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { ExtendedTheme } from '@components/theme';
import { T } from '@components/T/T';
import { compile } from 'path-to-regexp';

export interface ITabProps {
    title: string;
    path: string;
    render: React.Component | React.ReactNode;
    params?: Record<string, string>;
    query?: Record<string, string>;
}

interface IProps {
    tabs: ITabProps[];
}

const useStyles = makeStyles((theme: ExtendedTheme) => ({
    root: {
        background: '#fafafa'
    },
    tab: {
        padding: theme.spacing(0, 0, 1),
        borderBottom: `4px solid transparent`
    },
    activeTab: {
        borderColor: '#4a83ff'
    },
    tabs: {
        padding: theme.spacing(0, 6),
        margin: theme.spacing(0, -6),
        borderBottom: `1px solid #e9ebf2`,
        background: theme.palette.common.white
    },
    tabContent: {
        padding: theme.spacing(6, 0, 0)
    }
}));

export const TabbedRouter: React.FC<IProps> = ({ tabs }) => {
    const { path, url } = useRouteMatch();
    const classes = useStyles();
    return (
        <Box px={6} pb={6} className={classes.root}>
            <Box className={classes.tabs} display="flex">
                {tabs.map((tab) => {
                    const to = tab.path ? [url, tab.path].join('/') : url;
                    const toPath = compile(to, { encode: encodeURIComponent });
                    const compiledPath = toPath(tab.params);
                    const query = new URLSearchParams(tab.query);
                    return (
                        <Box clone mr={12} key={tab.title}>
                            <NavLink
                                data-t="tab"
                                exact={!tab.path}
                                className={classes.tab}
                                activeClassName={classes.activeTab}
                                to={compiledPath.concat('?', query.toString())}
                            >
                                <T>{tab.title}</T>
                            </NavLink>
                        </Box>
                    );
                })}
            </Box>
            <Box className={classes.tabContent}>
                <Switch>
                    {tabs.map((tab) => (
                        <Route exact={!tab.path} key={tab.path} path={`${path}/${tab.path}`}>
                            {tab.render}
                        </Route>
                    ))}
                </Switch>
            </Box>
        </Box>
    );
};
