import { createAction, createRequestAction } from '@helpers/actionCreator';
import { SettlementApprovalStatus } from '@selectors/roaming';
import { UploadHashHandler } from '@saga/communication';
import { DataSubmitType, RoamingFileIdentifier } from '@clearblockchain/shared/roaming-contract';
import { PartnershipData, PartnershipQueryParams } from '@clearblockchain/shared/partnership';

export enum agreementsType {
    GET_AGREEMENTS = 'GET:AGREEMENTS::REQUEST',
    GET_AGREEMENTS_SUCCESS = 'GET:AGREEMENTS::REQUEST:SUCCESS',
    GET_AGREEMENTS_FAIL = 'GET:AGREEMENTS::REQUEST:FAIL',

    GET_AGREEMENTS_FOR_EXPORT = 'GET:AGREEMENTS:FOR:EXPORT::REQUEST',
    GET_AGREEMENTS_FOR_EXPORT_FAIL = 'GET:AGREEMENTS:FOR:EXPORT::REQUEST:FAIL',

    VOICE_ACCOUNT_UPDATE_RECEIVED = 'VOICE:ACCOUNT:UPDATE:RECEIVED',

    VOICE_SWAP_AGREEMENT_UPDATE_RECEIVED = 'VOICE:SWAP:AGREEMENT:UPDATE:RECEIVED',

    AGREEMENTS_CONTRACT_UPDATE_RECEIVED = 'AGREEMENTS:CONTRACT:UPDATE:RECEIVED',
    AGREEMENTS_PROPOSAL_UPDATE_RECEIVED = 'AGREEMENTS:PROPOSAL:UPDATE:RECEIVED',

    TOGGLE_CREATE_CONTRACT = 'ROAMING:TOGGLE:CREATE:CONTRACT',
    TOGGLE_REPLACE_CONTRACT = 'ROAMING:TOGGLE:REPLACE:CONTRACT',

    ROAMING_PROPOSE_CONTRACT = 'ROAMING:SPIN:PROPOSE:CONTRACT',
    CLEAR_CONTRACT_CREATION_ERROR = 'ROAMING:CLEAR:CONTRACT:CREATION:ERROR',

    REPLACE_PROPOSAL = 'REPLACE_PROPOSAL',

    ROAMING_EXPORT_BREAKDOWN = 'ROAMING:EXPORT:BREAKDOWN::REQUEST',
    ROAMING_EXPORT_BREAKDOWN_SUCCESS = 'ROAMING:EXPORT:BREAKDOWN::REQUEST:SUCCESS',
    ROAMING_EXPORT_BREAKDOWN_FAIL = 'ROAMING:EXPORT:BREAKDOWN::REQUEST:FAIL',

    GET_URL_FOR_CONTRACT_RELATED_FILE = 'GET_URL_FOR_CONTRACT_RELATED_FILE::REQUEST',
    GET_URL_FOR_CONTRACT_RELATED_FILE_SUCCESS = 'GET_URL_FOR_CONTRACT_RELATED_FILE::REQUEST:SUCCESS',
    GET_URL_FOR_CONTRACT_RELATED_FILE_FAIL = 'GET_URL_FOR_CONTRACT_RELATED_FILE::REQUEST:FAIL',

    AGREEMENTS_SETTLEMENT_DATA_UPLOAD = 'AGREEMENTS:SETTLEMENT:DATA::UPLOAD',

    AGREEMENTS_SETTLEMENT_DATA_SUBMIT_HASH = 'AGREEMENTS:SETTLEMENT:DATA:SUBMIT:HASH:SPIN',
    SUBMIT_CONTRACT_RELATED_FILE = 'SUBMIT_CONTRACT_RELATED_FILE:SPIN',

    UPDATE_RUN_SETTLEMENT_APPROVAL_STATUS_REQUEST = 'UPDATE_RUN_SETTLEMENT_APPROVAL_STATUS::REQUEST',
    UPDATE_RUN_SETTLEMENT_APPROVAL_STATUS_SUCCESS = 'UPDATE_RUN_SETTLEMENT_APPROVAL_STATUS::REQUEST:SUCCESS',
    UPDATE_RUN_SETTLEMENT_APPROVAL_STATUS_FAIL = 'UPDATE_RUN_SETTLEMENT_APPROVAL_STATUS::REQUEST:FAIL',

    AGREEMENTS_CLEAR_PENDING_USAGE_DATA_CONTRACT = 'AGREEMENTS:CLEAR:PENDING:CONTRACT:ID',

    AGREEMENTS_QUERY_BILLING_STATEMENT_REQUEST = 'AGREEMENTS:QUERY:BILLING_STATEMENT::REQUEST',
    AGREEMENTS_QUERY_BILLING_STATEMENT_SUCCESS = 'AGREEMENTS:QUERY:BILLING_STATEMENT::REQUEST:SUCCESS',
    AGREEMENTS_QUERY_BILLING_STATEMENT_FAIL = 'AGREEMENTS:QUERY:BILLING_STATEMENT::REQUEST:FAIL',

    AGREEMENTS_QUERY_ROAMING_USAGE_REQUEST = 'AGREEMENTS:QUERY:ROAMING:USAGE::REQUEST',
    AGREEMENTS_QUERY_ROAMING_USAGE_SUCCESS = 'AGREEMENTS:QUERY:ROAMING:USAGE::REQUEST:SUCCESS',
    AGREEMENTS_QUERY_ROAMING_USAGE_FAIL = 'AGREEMENTS:QUERY:ROAMING:USAGE::REQUEST:FAIL',

    GET_USAGE_TO_EXPORT_REQUEST = 'GET:USAGE:TO:EXPORT::REQUEST',
    GET_USAGE_TO_EXPORT_REQUEST_SUCCESS = 'GET:USAGE:TO:EXPORT::REQUEST:SUCCESS',
    GET_USAGE_TO_EXPORT_REQUEST_FAIL = 'GET:USAGE:TO:EXPORT::REQUEST:FAIL',

    AGREEMENTS_CREATE_FROM_FILE = 'AGREEMENTS:CREATE:FROM:FILE',

    AGREEMENTS_APPROVE = 'AGREEMENTS:APPROVE::REQUEST',
    AGREEMENTS_APPROVE_SUCCESS = 'AGREEMENTS:APPROVE::REQUEST:SUCCESS',
    AGREEMENTS_APPROVE_FAIL = 'AGREEMENTS:APPROVE::REQUEST:FAIL',

    REJECT_PROPOSAL = 'REJECT_PROPOSAL',

    AGREEMENTS_SET_REPORTS_APPROVAL = 'AGREEMENTS:SET:REPORTS:APPROVAL::REQUEST',
    AGREEMENTS_SET_REPORTS_APPROVAL_FAIL = 'AGREEMENTS:SET:REPORTS:APPROVAL::REQUEST:FAIL',

    AGREEMENTS_QUERY_ROAMING_DISCREPANCY_REQUEST = 'AGREEMENTS:QUERY:ROAMING:DISCREPANCY::REQUEST',
    AGREEMENTS_QUERY_ROAMING_DISCREPANCY_SUCCESS = 'AGREEMENTS:QUERY:ROAMING:DISCREPANCY::REQUEST:SUCCESS',
    AGREEMENTS_QUERY_ROAMING_DISCREPANCY_FAIL = 'AGREEMENTS:QUERY:ROAMING:DISCREPANCY::REQUEST:FAIL',

    GET_DISCREPANCIES_AND_EXPORT_REQUEST = 'GET:DISCREPANCIES:AND:EXPORT::REQUEST',
    GET_DISCREPANCIES_AND_EXPORT_SUCCESS = 'GET:DISCREPANCIES:AND:EXPORT::REQUEST:SUCCESS',
    GET_DISCREPANCIES_AND_EXPORT_FAIL = 'GET:DISCREPANCIES:AND:EXPORT::REQUEST:FAIL',

    AGREEMENTS_RESET_UPLOAD_USAGE_RESULT = 'AGREEMENTS:RESET:UPLOAD:USAGE:RESULT',

    SET_AGREEMENT_BREAKDOWN_MONTH = 'SET:AGREEMENT:BREAKDOWN:MONTH',

    SUGGEST_CONTRACT_PRICES_REQUEST = 'SUGGEST:CONTRACT:PRICES::REQUEST',
    SUGGEST_CONTRACT_PRICES_SUCCESS = 'SUGGEST:CONTRACT:PRICES::REQUEST:SUCCESS',
    SUGGEST_CONTRACT_PRICES_FAIL = 'SUGGEST:CONTRACT:PRICES::REQUEST:FAIL',

    RESPONSE_TO_SUGGESTED_CONTRACT_PRICES_REQUEST = 'RESPONSE:SUGGESTED:CONTRACT:PRICES::REQUEST',
    RESPONSE_TO_SUGGESTED_CONTRACT_PRICES_SUCCESS = 'RESPONSE:SUGGESTED:CONTRACT:PRICES::REQUEST:SUCCESS',
    RESPONSE_TO_SUGGESTED_CONTRACT_PRICES_FAIL = 'RESPONSE:SUGGESTED:CONTRACT:PRICES::REQUEST:FAIL',

    RESPOND_TO_SUGGESTED_PARTNERSHIP_REQUEST = 'RESPOND:SUGGESTED:PARTNERSHIP::REQUEST',
    RESPOND_TO_SUGGESTED_PARTNERSHIP_SUCCESS = 'RESPOND:SUGGESTED:PARTNERSHIP::REQUEST:SUCCESS',
    RESPOND_TO_SUGGESTED_PARTNERSHIP_FAIL = 'RESPOND:SUGGESTED:PARTNERSHIP::REQUEST:FAIL',

    PARTNERSHIP_UPDATE_RECEIVED = 'PARTNERSHIP:UPDATE:RECEIVED',

    PROPOSE_PARTNERSHIP = 'PROPOSE:PARTNERSHIP::REQUEST',
    PROPOSE_PARTNERSHIP_SUCCESS = 'PROPOSE:PARTNERSHIP::REQUEST:SUCCESS',
    PROPOSE_PARTNERSHIP_FAIL = 'PROPOSE:PARTNERSHIP::REQUEST:FAIL',

    QUERY_PARTNERSHIP = 'QUERY:PARTNERSHIP::REQUEST',
    QUERY_PARTNERSHIP_SUCCESS = 'QUERY:PARTNERSHIP::REQUEST:SUCCESS',
    QUERY_PARTNERSHIP_FAIL = 'QUERY:PARTNERSHIP::REQUEST:FAIL',

    QUERY_ALL_PARTNERSHIPS = 'QUERY:ALL:PARTNERSHIPS::REQUEST',
    QUERY_ALL_PARTNERSHIPS_SUCCESS = 'QUERY:ALL:PARTNERSHIPS::REQUEST:SUCCESS',
    QUERY_ALL_PARTNERSHIPS_FAIL = 'QUERY:ALL:PARTNERSHIPS::REQUEST:FAIL',

    QUERY_USAGE_TYPES = 'QUERY:USAGE_TYPES::REQUEST',
    QUERY_USAGE_TYPES_SUCCESS = 'QUERY:USAGE_TYPES::REQUEST:SUCCESS',
    QUERY_USAGE_TYPES_FAIL = 'QUERY:USAGE_TYPES::REQUEST:FAIL',

    AGREEMENTS_BILLING_STATEMENT_UPDATE_RECEIVED = 'AGREEMENTS:BILLING:STATEMENT:UPDATE:RECEIVED',

    DOES_PARTNER_SUPPORT_MULTI_USAGE_TYPE = 'CHECK:MULTI:USAGE:TYPE:SUPPORT::REQUEST'
}

export interface IGetAgreementOptions {
    query?: any;
    sorting?: { by: string; order: 'asc' | 'desc' };
    pagination?: object | null;
}

const transactionsActions = {
    getAgreements: createRequestAction(agreementsType.GET_AGREEMENTS, 'getAgreements', (options?: IGetAgreementOptions) => [options]),

    getAgreementsForExport: createRequestAction(agreementsType.GET_AGREEMENTS_FOR_EXPORT, 'getAgreements'),

    toggleShowCreateContract: (showDialog: boolean) => ({
        type: agreementsType.TOGGLE_CREATE_CONTRACT,
        payload: showDialog
    }),

    toggleShowReplaceContract: (showDialog: boolean) => ({
        type: agreementsType.TOGGLE_REPLACE_CONTRACT,
        payload: showDialog
    }),

    respondToSuggestedPartnership: createRequestAction(
        agreementsType.RESPOND_TO_SUGGESTED_PARTNERSHIP_REQUEST,
        'respondToPartnershipSuggestion',
        (partnershipId: string, isApproved?: boolean) => [partnershipId, isApproved]
    ),

    proposePartnership: createRequestAction(agreementsType.PROPOSE_PARTNERSHIP, 'proposePartnership', (partnerId: string, data: PartnershipData) => [
        partnerId,
        data
    ]),

    queryPartnership: createRequestAction(agreementsType.QUERY_PARTNERSHIP, 'queryPartnerships', (queryParams: PartnershipQueryParams) => [
        queryParams
    ]),

    doesPartnerSupportUsageTypePerService: createRequestAction(
        agreementsType.DOES_PARTNER_SUPPORT_MULTI_USAGE_TYPE,
        'doesPartnerSupportMultiUsageType',
        (partnerId: string) => [partnerId]
    ),

    queryMySupportedUsageTypes: createRequestAction(agreementsType.QUERY_USAGE_TYPES, 'getMySupportedUsageTypes', () => null),

    queryAllPartnerships: createRequestAction(agreementsType.QUERY_ALL_PARTNERSHIPS, 'queryPartnerships', () => null),

    responseToSuggestedPrices: createRequestAction(
        agreementsType.RESPONSE_TO_SUGGESTED_CONTRACT_PRICES_REQUEST,
        'respondToSuggestedPrices',
        (contractId: string, isApproved?: boolean) => [contractId, isApproved]
    ),

    clearCreateContractError: createAction(agreementsType.CLEAR_CONTRACT_CREATION_ERROR),

    replaceProposal: createRequestAction(
        agreementsType.REPLACE_PROPOSAL,
        'replaceProposal',
        (payload: any, contractFileHash: string, contractFileName: string) => {
            if (!payload || !payload.upid || !payload.peerId || !payload.calculationType) {
                throw new Error(`Missing payload or var in payload in order to execute action`);
            }
            const { upid, peerId, calculationType } = payload;
            return [upid, peerId, 'roaming', { contractFileName, contractFileHash, calculationType }];
        }
    ),
    roamingProposeContract: createRequestAction(
        agreementsType.ROAMING_PROPOSE_CONTRACT,
        'proposeContract',
        (payload: any, contractFileHash: string, contractFileName: string) => {
            if (!payload || !payload.peerId || !payload.title || !payload.calculationType) {
                throw new Error(`Missing payload or var in payload in order to execute action`);
            }
            const { title, peerId, calculationType } = payload;
            return ['roaming', peerId, title, { contractFileName, contractFileHash, calculationType }];
        }
    ),

    suggestContractPrices: createRequestAction(
        agreementsType.SUGGEST_CONTRACT_PRICES_REQUEST,
        'suggestContractPrices',
        (payload: any, contractFileHash: string, contractFileName: string) => {
            if (!payload || !payload.peerId || !payload.contractId || !payload.calculationType) {
                throw new Error(`Missing payload or var in payload in order to execute action`);
            }
            const { peerId, contractId, calculationType } = payload;
            return [contractId, peerId, { contractFileName, contractFileHash, calculationType }];
        }
    ),

    roamingExportTadig: createRequestAction(
        agreementsType.ROAMING_EXPORT_BREAKDOWN,
        'exportTadigBreakdown',
        (contractId: string, partyIndex: number, periodIndex: number) => [contractId, partyIndex, periodIndex]
    ),

    getUrlForContractRelatedfile: createRequestAction(
        agreementsType.GET_URL_FOR_CONTRACT_RELATED_FILE,
        'getUrlForContractRelatedFile',
        (contractId: string, fileIdentifier: string, partyIndex: number) => [contractId, fileIdentifier, partyIndex]
    ),

    agreementsSettlementDataUpload: (file: File, contractId: string, type: DataSubmitType, fileIdentifier: RoamingFileIdentifier) => {
        // NOTE - success reducer uses the contractId
        const createUploadHashHandlerAction: UploadHashHandler = createRequestAction(
            fileIdentifier === RoamingFileIdentifier.Usage
                ? agreementsType.AGREEMENTS_SETTLEMENT_DATA_SUBMIT_HASH
                : agreementsType.SUBMIT_CONTRACT_RELATED_FILE,
            'submitLogHash',
            (logHash: string) => [contractId, file.name, logHash, { type, fileIdentifier }]
        );
        // NOTE - pattern match in type value triggers upload behind the saga scenes.
        // Subsequently, createUploadHashHandlerAction is called with logHash and resulting action is dispatched
        // Sub-subsequently, a saga hack persists the loader until relevant contract update
        return {
            type: agreementsType.AGREEMENTS_SETTLEMENT_DATA_UPLOAD,
            payload: file,
            showSpinner: true,
            createUploadHashHandlerAction
        };
    },

    agreementsQueryBillingStatementRequest: createRequestAction(
        agreementsType.AGREEMENTS_QUERY_BILLING_STATEMENT_REQUEST,
        'queryBillingStatement',
        (contractId: string) => [{ contractId }]
    ),

    agreementsCreateFromFile: createAction(agreementsType.AGREEMENTS_CREATE_FROM_FILE),

    agreementsApprove: createRequestAction(agreementsType.AGREEMENTS_APPROVE, 'agreeProposalAndSign', (id: string) => [id]),

    rejectProposal: createRequestAction(agreementsType.REJECT_PROPOSAL, 'rejectProposal', (proposalId: string, message: string) => [
        proposalId,
        message
    ]),

    agreementsSetReportsApproval: createRequestAction<[string, number, SettlementApprovalStatus], any>(
        agreementsType.AGREEMENTS_SET_REPORTS_APPROVAL,
        'setDiscountCalculationSettlementApprovalStatus',
        (contractId: string, periodIndex: number, status: SettlementApprovalStatus) => [contractId, periodIndex, status]
    ),

    agreementsQueryDiscrepancy: createRequestAction(
        agreementsType.AGREEMENTS_QUERY_ROAMING_DISCREPANCY_REQUEST,
        'queryRoamingDiscrepancy',
        (id: string, query?: any, pagination?: any, sorting?: { by: string; order: 'asc' | 'desc' }) => [id, query, pagination, sorting]
    ),

    getDiscrepanciesAndExport: createRequestAction(agreementsType.GET_DISCREPANCIES_AND_EXPORT_REQUEST, 'queryRoamingDiscrepancy', (id: string) => [
        id
    ]),

    queryRoamingUsage: createRequestAction(
        agreementsType.AGREEMENTS_QUERY_ROAMING_USAGE_REQUEST,
        'queryRoamingUsage',
        (contractId: string, periodIndex: number, pagination: any, filters: any) => [contractId, periodIndex, pagination, filters]
    ),

    getRoamingUsageToExport: createRequestAction(agreementsType.GET_USAGE_TO_EXPORT_REQUEST, 'queryRoamingUsage', (contractId: string) => [
        contractId,
        null,
        null,
        null
    ]),

    resetUploadUsageResult: createAction(agreementsType.AGREEMENTS_RESET_UPLOAD_USAGE_RESULT),

    setAgreementBreakdownMonth: createAction(agreementsType.SET_AGREEMENT_BREAKDOWN_MONTH, { payload: (monthIndex: number) => monthIndex }),

    updateRunCalculationsApprovalStatus: createRequestAction(
        agreementsType.UPDATE_RUN_SETTLEMENT_APPROVAL_STATUS_REQUEST,
        'updateRunCalculationsApprovalStatus',
        (contractId: string, status: boolean) => [contractId, status]
    )
};

export default transactionsActions;
