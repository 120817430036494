import * as React from 'react';
import { Box } from '@material-ui/core';
import { IconLabel } from '@components/IconLabel/IconLabel';
import ErrorIcon from '@material-ui/icons/Error';
import DoneIcon from '@material-ui/icons/Done';
import Info from '@ant-design/icons/InfoCircleOutlined';
import { T } from '@components/T/T';
import { Button } from 'antd';
import RejectIcon from '@ant-design/icons/CloseOutlined';
import ApproveIcon from '@ant-design/icons/CheckOutlined';
import { FontColor } from '@components/theme';

const NOTICE_BAR_SIZE = '42px';

interface IPendingNotice {
    text: string;
}

export const PendingNotice: React.FC<IPendingNotice> = ({ text }) => {
    return (
        <Box
            display="flex"
            justifyContent="center"
            minHeight={NOTICE_BAR_SIZE}
            marginBottom={1}
            py={2}
            bgcolor="error.main"
            data-t={'pending-partner-approval'}
        >
            <IconLabel icon={<ErrorIcon />} color="white" fontSize={20}>
                <T color={FontColor.W}>{text}</T>
            </IconLabel>
        </Box>
    );
};

interface IPendingYourApprovalNotice {
    onApprovalClick: () => any;
    onRejectClick: () => any;
    approveButtonText?: string;
    rejectButtonText?: string;
    noticeBarText: string;
}

export const PendingYourApprovalNotice: React.FC<IPendingYourApprovalNotice> = ({
    onApprovalClick,
    onRejectClick,
    approveButtonText,
    rejectButtonText,
    noticeBarText
}): React.ReactElement => {
    return (
        <Box
            display="flex"
            justifyContent="center"
            alignItems={'center'}
            minHeight={NOTICE_BAR_SIZE}
            py={2}
            bgcolor="#ECF6FD"
            border={'1px solid #ADD8F7'}
            data-t={'pending-your-approval'}
        >
            <Box display={'flex'} alignItems="center">
                <IconLabel icon={<Info />} color="#108EE9" fontSize={24} iconMR={4} mr={4}>
                    <T variant={'caption'} color={FontColor.B65}>
                        {noticeBarText}
                    </T>
                </IconLabel>
                <Box mr={2} clone>
                    <Button
                        onClick={onApprovalClick}
                        data-t={'approve-suggestion-button'}
                        type="primary"
                        icon={<ApproveIcon style={{ fontSize: 10 }} />}
                    >
                        {approveButtonText}
                    </Button>
                </Box>
                <Button onClick={onRejectClick} type="primary" ghost icon={<RejectIcon style={{ fontSize: 10 }} />}>
                    {rejectButtonText}
                </Button>
            </Box>
        </Box>
    );
};

PendingYourApprovalNotice.defaultProps = {
    approveButtonText: 'Approve',
    rejectButtonText: 'Reject'
};
