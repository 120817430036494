import { createRequestAction } from '@helpers/actionCreator';
import pickBy from 'lodash/pickBy';

export enum CountryActionType {
    GET_COUNTRY_MAPPINGS = 'GET:COUNTRY_MAPPINGS',

    GET_COUNTRY_TRANSLATIONS = 'GET:COUNTRY_MAPPING_TRANSLATIONS',

    GET_TEMPORARY_COUNTRY_TRANSLATIONS = 'GET:TEMPORARY_COUNTRY_TRANSLATIONS',

    REPLACE_USER_DEFINED_TRANSLATIONS = 'REPLACE:USER_DEFINED_TRANSLATIONS'
}

export const countryActions = {
    getCountryMappings: createRequestAction(CountryActionType.GET_COUNTRY_MAPPINGS, 'getCountryMappings'),
    getCountryTranslations: createRequestAction(CountryActionType.GET_COUNTRY_TRANSLATIONS, 'getCountryTranslations', (mappingId?: string) => [
        mappingId
    ]),
    getTemporaryCountryTranslations: createRequestAction(
        CountryActionType.GET_TEMPORARY_COUNTRY_TRANSLATIONS,
        'getCountryTranslations',
        (mappingId?: string) => [mappingId]
    ),
    replaceUserDefinedTranslations: createRequestAction(
        CountryActionType.REPLACE_USER_DEFINED_TRANSLATIONS,
        'replaceUserDefinedCountryTranslations',
        (translations: Record<string, string>) => {
            const nonStandardTranslations = pickBy(translations, (v, k) => k !== v);
            return [nonStandardTranslations];
        }
    )
};
