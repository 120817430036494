import * as React from 'react';
import classNames from 'classnames';
import { notification } from 'antd';

import { Icon } from '@components/Icon';
import { NotificationsList } from './NotificationsList';
import { IStateNotifications, IStateNotification } from '@actions/notification';
import { NotificationItem } from './Notification';

import './styles.scss';

export interface INotificationProps {
    unreadNotificationsSize: number;
    notifications: IStateNotifications;
    setAllNotificationWereRead(): void;
    markNotificationAsRead(id: string): void;
    handleActionableNotificationClick(notification: IStateNotification): void;
}

interface INotificationState {
    showMessages: boolean;
    onScreenNotificationId: null | string;
}

export class Notification extends React.PureComponent<INotificationProps, INotificationState> {
    constructor(props: any) {
        super(props);
        this.state = { showMessages: false, onScreenNotificationId: null };
    }

    componentDidUpdate(prevProps: Readonly<INotificationProps>): void {
        const { notifications, unreadNotificationsSize } = this.props;
        if (unreadNotificationsSize > 0 && prevProps.unreadNotificationsSize !== unreadNotificationsSize) {
            const unread = notifications.filter((item: IStateNotification): boolean => !item.read);
            const oldestUnreadNotification = unread.slice(-1)[0] as IStateNotification;
            this.popNotificationToScreen(oldestUnreadNotification);
        }
    }

    componentWillUnmount(): void {
        this.onScreenNotificationClose();
    }

    componentDidMount(): void {
        notification.destroy();
    }

    handleNotificationButtonClick = () => this.props.notifications.length && this.closeNotificationToolTip();

    closeNotificationToolTip = () => {
        this.setState(
            (state) => ({ showMessages: !state.showMessages }),
            () => this.state.showMessages && this.props.setAllNotificationWereRead()
        );
    };

    onScreenNotificationClose = () => {
        const { onScreenNotificationId } = this.state;
        if (onScreenNotificationId) {
            notification.destroy();
            this.props.markNotificationAsRead(onScreenNotificationId);
            this.setState({ onScreenNotificationId: null });
        }
    };

    popNotificationToScreen = (notificationObject: IStateNotification) => {
        const { onScreenNotificationId } = this.state;
        if (!onScreenNotificationId) {
            notification['info']({
                message: 'Notification',
                description: (
                    <NotificationItem
                        notification={notificationObject}
                        onMoreDetailsClick={() => {
                            this.props.handleActionableNotificationClick(notificationObject);
                            this.onScreenNotificationClose();
                        }}
                    />
                ),
                duration: null,
                top: 80,
                onClose: () => this.onScreenNotificationClose()
            });
            this.setState({ onScreenNotificationId: notificationObject.id });
        }
    };

    render() {
        const { notifications, handleActionableNotificationClick, unreadNotificationsSize } = this.props;
        const { showMessages } = this.state;
        const notificationListToPresent = notifications.slice(0, 10);
        const hasUnreadNotifications = Boolean(unreadNotificationsSize !== 0);
        return (
            <>
                <button
                    data-t="notifications-btn"
                    className={classNames(`notifications-btn btn-inline`, {
                        unread: notifications.length
                    })}
                    onClick={this.handleNotificationButtonClick}
                >
                    <Icon iconName="bell" />
                    {hasUnreadNotifications && <span className="counter">{unreadNotificationsSize}</span>}
                </button>
                {notificationListToPresent.length > 0 && showMessages && (
                    <NotificationsList
                        notifications={notificationListToPresent}
                        closeNotificationToolTip={this.closeNotificationToolTip}
                        handleActionableNotificationClick={handleActionableNotificationClick}
                    />
                )}
            </>
        );
    }
}
