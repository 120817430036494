import * as React from 'react';
import Popover from 'react-tiny-popover';

const defaultLimit = 3;
interface ITagListProps {
    values: string[];
    limit?: number;
    className?: string;
}

interface ITagListState {
    isOpen: boolean;
}
import './styles.scss';

export class TagList extends React.PureComponent<ITagListProps, ITagListState> {
    constructor(props: ITagListProps) {
        super(props);
        this.state = {
            isOpen: false
        };
    }

    togglePopover = (ev: MouseEvent | React.MouseEvent): void => {
        ev.stopPropagation();
        this.setState({ isOpen: !this.state.isOpen });
    };

    getLabels = (values: string[]): JSX.Element[] =>
        values.map((value: string) => (
            <label key={value} title={value}>
                {value}
            </label>
        ));

    render() {
        const { values, className } = this.props;
        const limit = this.props.limit || defaultLimit;
        const excess: number = Math.max(0, values.length - limit);
        const classNames: string = 'tag-list ' + (className || '');
        return (
            <div className={classNames}>
                {this.getLabels(values.slice(0, limit))}
                {Boolean(excess) && (
                    <Popover
                        containerClassName="popover-outer"
                        isOpen={this.state.isOpen}
                        onClickOutside={this.togglePopover}
                        content={<div className={classNames + ' popover'}>{this.getLabels(values)}</div>}
                    >
                        <span className="more" onClick={this.togglePopover}>
                            +{excess}
                        </span>
                    </Popover>
                )}
            </div>
        );
    }
}
