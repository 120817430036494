import React from 'react';
import Helmet from 'react-helmet';
import { SubHeader } from '@components';
import './styles.scss';
import { PasswordPolicyPage } from './PasswordPolicyPage';
import { GeneralSettings } from '@modules/Auth/containers/Settings/GeneralSettings';
import { TabbedRouter } from '@components/TabbedRouter/TabbedRouter';
import { CountryMapping } from '@modules/Auth/containers/Settings/CountryMapping/CountryMapping';

export const Settings = () => {
    return (
        <>
            <Helmet key="helmet" title="Settings" />
            <SubHeader title="Settings" />
            <TabbedRouter
                tabs={[
                    { title: 'General', render: () => <GeneralSettings />, path: '' },
                    { title: 'Destination Names', render: () => <CountryMapping />, path: 'destination-names' },
                    { title: 'Security', render: () => <PasswordPolicyPage />, path: 'security' }
                ]}
            />
        </>
    );
};
