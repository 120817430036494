import queryString from 'query-string';
import { ViewBy } from '@actions/voice';
import { History } from 'history';
import get from 'lodash/get';

interface IQueryParams {
    comparisonType?: string | undefined;
    direction?: string | undefined;
    filters?: string[] | [] | undefined;
    page?: string | undefined;
    sorting?: string | undefined;
    viewBy?: ViewBy | undefined;
}

export function getUrlQueryParams(location: Location): IQueryParams {
    const parsedParams = queryString.parse(location.search) || {};

    const paramsObj: IQueryParams = {
        comparisonType: (parsedParams.comparisonType as string) || undefined,
        direction: (parsedParams.direction as string) || undefined,
        filters: (parsedParams.filters as string[]) || undefined,
        page: (parsedParams.page as string) || undefined,
        sorting: (parsedParams.sorting as string) || undefined,
        viewBy: (parsedParams.viewBy as ViewBy) || undefined
    };
    return paramsObj;
}

export function resetUrlToDirectionAndComparisonType(location: Location, history: any) {
    const queryParams = getUrlQueryParams(location);
    const newQueryParams: IQueryParams = {
        comparisonType: queryParams.comparisonType,
        direction: queryParams.direction
    };
    const stringifyParams = queryString.stringify(newQueryParams);
    history.push(`${location.pathname}?${stringifyParams}`);
}

export function updateDirectionInUrl(tabNumber: number, location: Location, history: any) {
    const queryParams = getUrlQueryParams(location);
    queryParams.direction = tabNumber === 1 ? 'receivable' : 'payable';
    const stringifyParams = queryString.stringify(queryParams);
    history.push(`${location.pathname}?${stringifyParams}`);
}

export function getTabNumberFromUrl(location: Location | undefined) {
    if (location) {
        const queryParams = getUrlQueryParams(location);
        let directionTabNumber;
        if (queryParams.direction) {
            directionTabNumber = queryParams.direction === 'receivable' ? 1 : 0;
        }
        return directionTabNumber;
    } else {
        return undefined;
    }
}

export const getUrlQueryParam = (location: Location, key: string): any => get(queryString.parse(location.search), key);
export function setUrlQueryParam(location: Location, key: string, value: any, history: History, useReplace?: boolean) {
    const params = queryString.parse(location.search);
    if (value === undefined) {
        delete params[key];
    } else {
        params[key] = value;
    }
    (useReplace ? history.replace : history.push)(`${location.pathname}?${queryString.stringify(params)}`);
}
