import { createRequestAction } from '@helpers/actionCreator';
import { ActivityLogQueryParams } from '@clearblockchain/shared/activity-log';

export enum activityLogsActionType {
    GET_ACTIVITY_LOGS = 'GET:ACTIVITY:LOGS::REQUEST',
    GET_ACTIVITY_LOGS_SUCCESS = 'GET:ACTIVITY:LOGS::REQUEST:SUCCESS',
    GET_ACTIVITY_LOGS_FAIL = 'GET:ACTIVITY:LOGS::REQUEST:FAIL'
}

const activityLogsActions = {
    getActivityLogs: createRequestAction(activityLogsActionType.GET_ACTIVITY_LOGS, 'queryActivityLogs', (queryParams: ActivityLogQueryParams) => [
        queryParams
    ])
};

export default activityLogsActions;
