import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getAccounts } from '@selectors/voice';
import { Modal, Input, Select, DatePicker } from 'antd';
import MaskedInput from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
const { Option } = Select;
import voiceActions from '@actions/voice';
import './styles.scss';
import 'antd/dist/antd.css';
import { periodDateFormat } from '@constants';
import moment from 'moment';
import { turnDateToUTC } from '@helpers/formaters';
import { RATE_PRECISION } from '@modules/Voice/containers/AgreementView';

const rateMask = createNumberMask({
    allowDecimal: true,
    prefix: '',
    decimalLimit: RATE_PRECISION
});

interface IProps {
    accountId?: string;
    isOpen: boolean;
    close: () => void;
}
const bind = (func: (arg: any) => void) => (arg: any) => func(arg);
const bindWArg = (func: (arg: any) => void, arg: any) => () => func(arg);
const ErrorDisplay = ({ error }: { error: string }) => (error ? <div className="new-agr-error">{error}</div> : null);
export const NewAgreement = (props: IProps) => {
    const { isOpen, close, accountId } = props;
    const accounts = useSelector(getAccounts);
    const [sAccountId, setAccountId] = useState<string>(accountId!);
    const [startDate, setStartDate] = useState<moment.Moment | null>();
    const [endDate, setEndDate] = useState<moment.Moment | null>();
    const [title, setTitle] = useState('');
    const [defaultInbound, setDefaultInbound] = useState<number>();
    const [defaultOutbound, setDefaultOutbound] = useState<number>();
    const dispatch = useDispatch();
    const accountIdArg = accountId || sAccountId;
    const isValid = accountIdArg && startDate && endDate && endDate.valueOf() > startDate.valueOf() && title;
    const dateError = startDate && endDate && endDate.valueOf() <= startDate.valueOf() ? 'End date should be greater than start date' : '';
    const inPast =
        startDate &&
        startDate.valueOf() <
            moment
                .utc()
                .startOf('day')
                .valueOf();
    const [showPastDatesPopup, setShowPastDatesPopup] = useState(false);
    const titleChanged = (event: any) => setTitle(event.target.value);
    const rateChanged = (isInbound: boolean, event: any) => (isInbound ? setDefaultInbound : setDefaultOutbound)(parseFloat(event.target.value));
    const createClick = () => {
        if (inPast) {
            setShowPastDatesPopup(true);
        } else {
            createAgreement();
        }
    };
    const createAgreement = () => {
        setShowPastDatesPopup(false);
        dispatch(
            voiceActions.createAgreement(
                title,
                accountIdArg as string,
                startDate!.valueOf() / 1000,
                endDate!.valueOf() / 1000,
                defaultInbound,
                defaultOutbound
            )
        );
        close();
    };
    const startDateChanged = (date: any | null) => setStartDate(turnDateToUTC(date));
    const endDateChanged = (date: any | null) => setEndDate(turnDateToUTC(date));
    return (
        <>
            <Modal className="new-agreement" centered visible={isOpen} title="Add New Swap Agreement" closable onCancel={close}>
                <div className="section-title">Account</div>
                <Select value={sAccountId} onChange={bind(setAccountId)} placeholder="Select..">
                    {accounts.map(({ id, name, presentedName }) => (
                        <Option key={id} value={id}>
                            {presentedName || name}
                        </Option>
                    ))}
                </Select>
                <div className="section-title">Title</div>
                <Input onChange={titleChanged} placeholder="Add Agreement Title" />
                <div className="section-title">Date Period</div>
                <div className={'double' + (dateError ? ' error' : '')}>
                    <DatePicker format={periodDateFormat} onChange={startDateChanged} />
                    <DatePicker format={periodDateFormat} onChange={endDateChanged} />
                </div>
                <ErrorDisplay error={dateError} />
                <div className="double">
                    <div>
                        <div className="section-title">Default Inbound Rate</div>
                        <MaskedInput mask={rateMask} onChange={rateChanged.bind(null, true)} />
                    </div>
                    <div>
                        <div className="section-title">Default Outbound Rate</div>
                        <MaskedInput mask={rateMask} onChange={rateChanged.bind(null, false)} />
                    </div>
                </div>
                <div className="actions">
                    <button className="cancel" onClick={close}>
                        Cancel
                    </button>
                    <button className="create" disabled={!isValid} onClick={createClick}>
                        Create New Agreement
                    </button>
                </div>
            </Modal>
            <Modal title="Dates in the past" visible={showPastDatesPopup} onCancel={bindWArg(setShowPastDatesPopup, false)} onOk={createAgreement}>
                Agreement dates are in the past. This will only affect settlement periods created from now on. Would you like to continue?
            </Modal>
        </>
    );
};
