import * as React from 'react';
import { AnyAction, Dispatch } from 'redux';
import { Switch, Route } from 'react-router';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { push } from 'connected-react-router';

import { isTransactionHasLoaded } from '@selectors/transactions';
import { getLocationObject } from '@selectors/router';
import { Admin, AdminAndManagers, getUserInfoLoading } from '@selectors/account';
import { IRootState } from '@reducers/index';
import uiActions from '@actions/ui';

import { ROUTES, IRouteComponentBaseInfo } from '@helpers/dynamicRoutes';
import { arrayOfArgsOrSingleArg, callbackForArrayOrSingleArg } from '@helpers/commons';
import { LoadableComponent } from '@loadable/component';
import { PrivateRoute } from '@containers';
import { ManageUsers } from '@components/UserManagement/ManageUsers';
import { PartnersManagement } from '@modules/Voice/containers/partnersManagment/partnersManagment';
import { Settings } from '@modules/Auth/containers/Settings';
import { Partnerships } from '@components/Partneships/partnerships';
import { PartnerSettings } from '@components/Partneships/PartnerSettings';
import { SettlementDashboard } from '@modules/Roaming/components/SettlementDashboard/SettlementDashboard';

const createRoute = (node: IRouteComponentBaseInfo): JSX.Element => <Route key={node.path} path={node.path} component={node.component} />;

export interface IDashboardRouteStructure {
    component: LoadableComponent<JSX.Element>;
    path: string;
}

const prefix = '/dashboard/';
export const DashboardPaths = {
    SETTLEMENTS: prefix + 'settlements',
    USER_MANAGEMENT: prefix + 'users/management',
    VOICE_PARTNER_MANAGEMENT: prefix + 'users/partner-management',
    SETTINGS: prefix + 'settings',
    PARTNERSHIPS: prefix + 'partnerships'
};

const renderRoutes = (nodes: string[]): JSX.Element => (
    <Switch>
        <PrivateRoute path={DashboardPaths.SETTLEMENTS} component={SettlementDashboard} />
        <PrivateRoute authorizedGroups={Admin} path={DashboardPaths.USER_MANAGEMENT} component={ManageUsers} />
        <PrivateRoute authorizedGroups={Admin} path={DashboardPaths.VOICE_PARTNER_MANAGEMENT} component={PartnersManagement} />
        <PrivateRoute authorizedGroups={Admin} path={DashboardPaths.SETTINGS} component={Settings} />
        <PrivateRoute authorizedGroups={AdminAndManagers} path={`${DashboardPaths.PARTNERSHIPS}/:id`} component={PartnerSettings} />
        <PrivateRoute authorizedGroups={AdminAndManagers} path={DashboardPaths.PARTNERSHIPS} component={Partnerships} />
        {nodes.map((node) => callbackForArrayOrSingleArg(ROUTES[node], createRoute))}
    </Switch>
);

interface IDashboardRouteProps {
    nodes: string[];
    isTransactionHasLoaded: boolean;
    isBalanceHasLoaded: boolean;
    location: Location;
    loading: boolean;
    showSpinner: () => void;
    hideSpinner: () => void;
    routerRedirect: () => void;
}

@connect(
    createStructuredSelector<IRootState, any>({
        isTransactionHasLoaded,
        location: getLocationObject,
        loading: getUserInfoLoading
    }),
    (dispatch: Dispatch): any => ({
        showSpinner: (): AnyAction => dispatch(uiActions.showSpinner()),
        hideSpinner: (): AnyAction => dispatch(uiActions.hideSpinner()),
        routerRedirect: (path: string): AnyAction => dispatch(push(path))
    })
)
export default class DashboardRoutes extends React.Component<any> {
    constructor(props: IDashboardRouteProps) {
        super(props);
        if (!props.location.pathname.includes('/dashboard/agreements') && !props.isTransactionHasLoaded) {
            props.showSpinner();
        }
    }

    componentDidUpdate(prevProps: IDashboardRouteProps) {
        const transactionsHasLoaded = prevProps.isTransactionHasLoaded !== this.props.isTransactionHasLoaded;

        if (location.pathname.includes('/dashboard/agreements')) {
            if (transactionsHasLoaded && this.props.isTransactionHasLoaded) {
                this.props.hideSpinner();
            }
        }
        if (prevProps.loading !== this.props.loading) {
            if (!this.props.loading) {
                this.props.hideSpinner();
                if (!this.props.nodes.length) {
                    this.props.routerRedirect('/errors/404');
                } else {
                    const firstRouteNode: IRouteComponentBaseInfo | IRouteComponentBaseInfo[] = ROUTES[this.props.nodes[0]];
                    const firstRoutePath: string = arrayOfArgsOrSingleArg(firstRouteNode).path;
                    if (location.pathname === '/dashboard' || location.pathname === '/dashboard/') {
                        this.props.routerRedirect(firstRoutePath);
                    }
                }
            }
        }
    }

    render() {
        const { nodes } = this.props;

        return <section className="dashboard-content">{renderRoutes(nodes)}</section>;
    }
}
