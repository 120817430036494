import * as React from 'react';
import { Box } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';

const { useCallback } = React;

interface IProps {
    disabled?: boolean;
    selectedItem: string;
    menuItems: Array<{ key: string; title: string }>;
    onItemClick(itemKey: string): void;
}

const useStyles = makeStyles({
    item: {
        '&:hover': {
            background: '#f3faff',
            cursor: 'pointer'
        },
        '&:disabled': {
            background: 'none',
            cursor: 'not-allowed'
        }
    }
});

export const SideMenu: React.FC<IProps> = (props) => {
    const { menuItems, onItemClick, selectedItem, disabled } = props;
    const classes = useStyles(props);
    const generateOnClick = useCallback(
        (itemKey: string) => () => {
            onItemClick(itemKey);
        },
        [onItemClick]
    );
    return (
        <Box component={'ul'} flex={2.5} borderRight={'1px solid #D9D9D9'} py={3}>
            {menuItems.map((item) => {
                return (
                    <Box
                        className={classes.item}
                        key={item.key}
                        component="button"
                        onClick={generateOnClick(item.key)}
                        height={'32px'}
                        width={'100%'}
                        px={3}
                        display={'flex'}
                        alignItems={'center'}
                        bgcolor={selectedItem === item.key ? '#E5F3FC' : 'inherit'}
                        // @ts-ignore
                        disabled={disabled as any}
                        color={disabled ? 'rgba(0, 0, 0, 0.25)' : 'black'}
                    >
                        {item.title}
                    </Box>
                );
            })}
        </Box>
    );
};
