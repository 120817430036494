import React from 'react';

import './styles.scss';

interface IRadioButtonProps {
    name: string;
    value: string | number;
    label: string | number;
    checked?: boolean;
    onChange: any;
}

export const RadioButton = (props: IRadioButtonProps): JSX.Element => {
    const { name, value, checked, onChange, label } = props;

    function handleChange(e: any) {
        onChange(e.target.value);
    }

    return (
        <div className={`radio ${checked ? 'checked' : ''}`}>
            <label>
                <span className="radio--label">{label}</span>
                <input className="radio-input--field" type="radio" name={name} value={value} checked={checked} onChange={handleChange} />
                <i className="radio-icon" />
            </label>
        </div>
    );
};
