import { createSelector } from 'reselect';
import { IRootState } from '@reducers/index';
import { IStateAuth } from '@actions/auth';
import { IPasswordPolicy } from '@clear/password-policy';

const authSelector = (state: IRootState): IStateAuth => state.auth;

export const isAuthChecked = createSelector(authSelector, (authState: IStateAuth): boolean => authState.isChecked);

export const isAuthenticated = createSelector([authSelector], (authState: IStateAuth): boolean => authState.isChecked && authState.isAuthenticated);

export const getErrorType = createSelector(authSelector, (authState: IStateAuth): string => authState.login.errorType);

export const getAuthToken = createSelector(authSelector, (authState: IStateAuth): string => authState.token);

export const getSession = createSelector(authSelector, (authState: IStateAuth): IStateAuth['session'] => authState.session);

export const getPasswordPolicy = createSelector(authSelector, (authState: IStateAuth): IPasswordPolicy | null => authState.passwordPolicy);

export const getPasswordPolicyFetchError = createSelector(authSelector, (authState: IStateAuth) => authState.passwordPolicyFetchError);

export const getMyLastPasswordPolicyUpdateTime = createSelector(authSelector, (authState: IStateAuth) => authState.myLastPolicyUpdateTime);

export const getSessionLoadingStatus = createSelector(getSession, (sessionState: IStateAuth['session']): boolean => sessionState.isLoading);

export const getSessionResponse = createSelector(getSession, (sessionState: IStateAuth['session']): boolean => sessionState.isChecked);

export const getSessionStatus = createSelector(
    getSession,
    (sessionState: IStateAuth['session']): IStateAuth['session']['lastEvent'] => sessionState.lastEvent
);
