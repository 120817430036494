import { peerType, IStatePeer, IPeer } from '@actions/peers';
import { IAppAction } from './state';
import { requestSuccessType } from '@helpers/actionCreator';

const initialState = {
    id: '',
    name: '',
    peers: [] as IPeer[]
};

export function peersReducer(state = initialState, { requestAction, type, payload }: IAppAction): IStatePeer {
    switch (type) {
        case requestSuccessType(peerType.GET_PEER_FEATURES): {
            const peerId = requestAction.payload.values[0];
            const features = payload;
            const index = state.peers.findIndex((p) => p.id === peerId);
            if (index === -1) {
                throw new Error('Could not find a matching peer');
            }
            state.peers[index] = { ...state.peers[index], features };
            return {
                ...state,
                peers: state.peers.slice()
            };
        }
        case peerType.PEERS_GET_ALL_SUCCESS:
            return { ...state, peers: payload };
        default:
            return { ...state };
    }
}
