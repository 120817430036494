import React from 'react';
import { Field, Form, InjectedFormProps, reduxForm } from 'redux-form';
import { InputField } from '@components';
import { required } from '@helpers';
import './styles.scss';

import PasswordPolicy from '@components/PasswordPolicy/PasswordPolicy';
import PasswordStrengthMeter from '@components/PasswordStrengthMeter/PasswordStrengthMeter';
import { validateLogin } from '@helpers/validation';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { AnyAction } from 'redux';
import { IPasswordPolicy } from '@clear/password-policy';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { IRootState } from '@reducers';
import { getPasswordPolicy } from '@selectors/auth';
import authActions from '@actions/auth';

export interface IUserFields {
    userId: string;
    username: string;
    oldPassword?: string;
    password: string;
    mfa: boolean;
    hash: string;
}

interface IUserState {
    validateFunction: (password: string, params: any) => void;
}

interface IUserProps extends InjectedFormProps {
    isSucceeded: boolean;
    isMfaEnabled: boolean;
    isPasswordPolicyEnforced: boolean;
    hash: string;
    username: string;
    userId: string;
    qrCode: string;
    isLoading: boolean;
    errorMessage: string;
    formTitle: string;
    successMessage: string;
    oldPassword?: string;
    passwordPolicy: IPasswordPolicy | null;
    getPasswordPolicyFromBackend: () => AnyAction;
    submitFunction(userDetails: IUserFields): void;
}

@connect(
    createStructuredSelector<IRootState, any>({
        passwordPolicy: getPasswordPolicy
    }),
    (dispatch) => ({
        getPasswordPolicyFromBackend: () => dispatch(authActions.getPasswordPolicy())
    })
)
class PasswordFormDumb extends React.Component<IUserProps, IUserState> {
    constructor(props: IUserProps) {
        super(props);
        this.state = {
            validateFunction: ((password: string, params: any) => {
                if (!this.props.passwordPolicy) {
                    return;
                }
                validateLogin(this.props.passwordPolicy, password, params);
            }).bind(this)
        };
    }
    componentDidMount(): void {
        this.props.getPasswordPolicyFromBackend();
        const { initialize, username, userId, hash, isMfaEnabled, oldPassword } = this.props;
        initialize({
            username,
            userId,
            hash,
            mfa: isMfaEnabled,
            oldPassword
        });
    }
    render() {
        const {
            username,
            isSucceeded,
            errorMessage,
            isMfaEnabled,
            isLoading,
            submitFunction,
            handleSubmit,
            valid,
            qrCode,
            formTitle,
            successMessage,
            oldPassword
        } = this.props;
        return (
            <div className="user-form-container">
                <h1>{formTitle.toUpperCase()}</h1>
                <div className="user-form-body">
                    <Helmet title={`Clear | ${formTitle}`} />
                    <Form className="user-form-form" onSubmit={handleSubmit(submitFunction as any)}>
                        <Field
                            name="username"
                            component={InputField}
                            disabled={!!username}
                            label="User name"
                            validate={[required]}
                            {...(!!username
                                ? {
                                      input: {
                                          value: username
                                      }
                                  }
                                : {})}
                        />
                        {oldPassword !== undefined && (
                            <Field name="oldPassword" component={InputField} label="Old Password" type="password" validate={[required]} />
                        )}
                        <Field
                            name="password"
                            component={InputField}
                            label="Password"
                            type="password"
                            validate={[required, ...[this.state.validateFunction]]}
                            disabled={isSucceeded}
                        />
                        <PasswordStrengthMeter />
                        {!isSucceeded && (
                            <>
                                {isMfaEnabled && <label htmlFor="create-user-mfa">Require MFA</label>}
                                <div className="form-actions">
                                    <button className="btn btn-primary w-100" type="submit" disabled={!valid || isLoading}>
                                        {formTitle.toUpperCase()}
                                    </button>
                                </div>
                            </>
                        )}
                        <div className="result">
                            {isSucceeded && !errorMessage && (
                                <div className="text-center activation-success">
                                    {successMessage}, you can now{' '}
                                    <Link replace to="/auth/login">
                                        log in
                                    </Link>
                                </div>
                            )}
                            <div className="error text-center">{errorMessage || null}</div>
                        </div>
                        {qrCode && (
                            <div className="qr">
                                <p>Scan this QR code using your authenticator app</p>
                                <img alt="QR Code" src={qrCode} />
                            </div>
                        )}
                    </Form>
                    {this.props.passwordPolicy ? <PasswordPolicy passwordPolicy={this.props.passwordPolicy} /> : null}
                </div>
            </div>
        );
    }
}

export const PasswordForm = reduxForm<IUserFields>({
    form: 'user-form'
})(PasswordFormDumb as any);
