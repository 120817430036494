import React from 'react';
import './styles.scss';

interface IProps {
    className?: string;
}
export const Pulse = ({ className }: IProps) => (
    <div className={'pulse' + (className ? ` ${className}` : '')}>
        <span className="center" />
        <span className="wave1" />
        <span className="wave2" />
    </div>
);
