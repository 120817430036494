import * as React from 'react';
import classNames from 'classnames';
import enhanceWithClickOutside from 'react-click-outside';

import { Menu } from './Menu';

interface IDropdownProps {
    title?: string;
    classList?: string;
    name?: string;
    id?: string;
    icon?: JSX.Element;
    iconRight?: boolean;
    inline?: boolean;
    label?: string;
    error?: string;
    closeOnMouseLeave?: boolean;
    closeOnOutsideClick?: boolean;
    disabled?: boolean;
    dataT?: string;
}

interface IDropdownState {
    isDropdownOpen: boolean;
}

export class DropdownComponent extends React.Component<IDropdownProps, IDropdownState> {
    DOMWrapper: HTMLElement | null = null;
    constructor(props: IDropdownProps) {
        super(props);

        this.state = { isDropdownOpen: false };

        document.addEventListener('keydown', this.handleEscapePress, false);
    }

    componentDidMount() {
        if (this.DOMWrapper && this.props.closeOnMouseLeave) {
            this.DOMWrapper.addEventListener('mouseleave', this.collapseDropdown);
        }
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.handleEscapePress, false);
        if (this.DOMWrapper && this.props.closeOnMouseLeave) {
            this.DOMWrapper.removeEventListener('mouseleave', this.collapseDropdown);
        }
    }

    expandDropdown = () => this.setState({ isDropdownOpen: true });

    collapseDropdown = () => this.setState({ isDropdownOpen: false });

    // is being called on outside click
    handleClickOutside() {
        if (this.props.closeOnOutsideClick) {
            this.collapseDropdown();
        }
    }

    handleEscapePress = (event: KeyboardEvent) => event.keyCode === 27 && this.collapseDropdown();

    handleDropdownClick = (event: React.MouseEvent): void => {
        if (this.props.disabled) {
            return;
        }
        event.preventDefault();
        event.stopPropagation();

        this.setState({ isDropdownOpen: !this.state.isDropdownOpen });
    };

    handleMenuItemClick = () => {
        this.setState({ isDropdownOpen: false });
    };

    render() {
        const { children, name, title, icon, iconRight, classList, inline, label, id, error, disabled, dataT } = this.props;

        const btnClasses = classNames(
            'btn dropdown',
            classList,
            { 'btn-inline': inline },
            { [`btn-${name}`]: !!name },
            { expanded: this.state.isDropdownOpen }
        );

        const fieldLabel = Boolean(label) && <label htmlFor={name}>{label}</label>;
        const titleSpan = <span>{title}</span>;

        return (
            <div data-t={dataT} className={btnClasses} id={id} ref={(node) => (this.DOMWrapper = node)}>
                {fieldLabel}
                <button
                    className={classNames('dropdown-title', {
                        'default-icon': !icon
                    })}
                    id={name}
                    onClick={this.handleDropdownClick}
                    disabled={disabled}
                >
                    {iconRight === true ? (
                        <>
                            {titleSpan}
                            {icon}
                        </>
                    ) : (
                        <>
                            {icon}
                            {titleSpan}
                        </>
                    )}
                </button>
                <Menu menus={children} handleItemClick={this.handleMenuItemClick} />
                <div className="field-message">{error}</div>
            </div>
        );
    }
}

export const Dropdown = enhanceWithClickOutside(DropdownComponent);
