import * as React from 'react';
import classNames from 'classnames';

import { Icon } from '@components/Icon';

export interface ISpinnerProps {
    successMessage?: string;
    isLoading?: boolean;
    message?: string;
}

export class DumbSpinnerCreating extends React.PureComponent<ISpinnerProps> {
    state = { delay: false };

    componentWillUpdate(nextProps: ISpinnerProps) {
        if (Boolean(this.props.successMessage) && nextProps.isLoading && !this.props.isLoading) {
            this.setState({ delay: true });
        }
    }

    componentDidUpdate(prevProps: ISpinnerProps) {
        if (Boolean(this.props.successMessage) && prevProps.isLoading && !this.props.isLoading) {
            window.setTimeout(() => {
                this.setState({ delay: false });
            }, 2000);
        }
    }

    render() {
        const { successMessage, message, isLoading } = this.props;
        const { delay } = this.state;

        return (
            <div
                className={classNames('back-drop', {
                    closed: Boolean(successMessage) ? !delay : !isLoading
                })}
            >
                <div
                    className={classNames('spinner spinner-creating', {
                        done: Boolean(successMessage) && !isLoading
                    })}
                >
                    <div className="loader">
                        <Icon iconName="list" />
                    </div>
                    <span className="message">{isLoading ? message : successMessage}</span>
                </div>
            </div>
        );
    }
}
