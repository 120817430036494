import map from 'lodash/map';
import moment from 'moment';

import { createSelector } from 'reselect';
import { IRootState } from '@reducers/index';
import { IStateVoice, IPeriodFilters, ISettlement } from '@reducers/voice';
import { formatBalance, formatPeriod, formatRate } from '@helpers/formaters';
import { getCurrencySymbol, partialDayFormat, shortDateFormat } from '@constants/index';
import { invert } from 'lodash';
import { Direction } from '@actions/voice';

const voiceSelector = (state: IRootState): IStateVoice => state.voice;
const voiceSelectorWithCurrency = (state: IRootState): [IStateVoice, string] => {
    const { voice } = state;
    const { settlements } = voice;
    let currency = '';
    if (settlements && settlements.length > 0) {
        currency = settlements[0].accountCurrency;
    }
    return [voice, getCurrencySymbol(currency)];
};
export const splitCSV = (str: string): string[] => (str ? str.split(',') : []);
export const processDiscrepacyTypes = (str: string) => splitCSV((str || '').toLowerCase()).map(convertDiscrepancyType);

export const getUploadFileError = createSelector(voiceSelector, (voiceState: IStateVoice): string => voiceState.fileUploadError);
export const getExternalHash = createSelector(voiceSelector, (voiceState: IStateVoice): string => voiceState.externalSettlementPeriodHash);
export const getGenerateLinkError = createSelector(voiceSelector, (voiceState: IStateVoice): string => voiceState.linkGenerateError);
export const getAccounts = createSelector(voiceSelector, (voiceState: IStateVoice) => voiceState.accounts);
export const getAccountsByPage = createSelector(voiceSelector, (voiceState: IStateVoice) => voiceState.accountsByPage);
export const getLastDisputeDate = createSelector(voiceSelector, (voiceState: IStateVoice) => voiceState.lastCreatedDisputeDate);
export const getPotentialPartners = createSelector(voiceSelector, (voiceState: IStateVoice) => voiceState.potentialPartners);
export const decorateSettlement = (item: ISettlement) => {
    const symbol = getCurrencySymbol(item.accountCurrency || '');

    return {
        ...item,
        formattedAmountDiff: `${symbol}${formatBalance(item.amountDiff)}`,
        formattedAmountDiffPer: `${formatBalance(item.amountDiffPer)}%`,
        date: `${moment(item.startDate * 1000).format(partialDayFormat)} - ${moment(item.endDate * 1000).format(shortDateFormat)}`,
        account: item.accountName,
        status: item.status.slice(0).toLowerCase(),
        key: item.id,
        totalAmount: Boolean(item.totalAmount) ? `${symbol}${formatBalance(item.totalAmount)}` : 'N/A'
    };
};
export const getSettlements = createSelector(voiceSelector, ({ settlements }: IStateVoice) => map(settlements, decorateSettlement));
export const getFreshlyCreatedSettlement = createSelector(voiceSelector, ({ freshlyCreatedSettlement }: IStateVoice) => freshlyCreatedSettlement);

export const getSingleSettlementInfo = createSelector(getSettlements, (settlements) => {
    const currentSettlement = settlements[0];
    if (!currentSettlement) {
        return {};
    }
    let selectResult = { title: '', subtitle: '' };

    if (settlements.length === 1) {
        selectResult = {
            title: currentSettlement.accountPresentedName || currentSettlement.accountName,
            subtitle: `
        Period
        ${moment(currentSettlement.startDate * 1000).format('MMM DD')}
        -
        ${moment(currentSettlement.endDate * 1000).format('MMM DD YYYY')}
        `
        };
    }

    return selectResult;
});

export const getCurrentSettlementInfo = createSelector(getSettlements, (settlements) => {
    const currentSettlement = settlements[0];
    if (!currentSettlement) {
        return {};
    }
    return currentSettlement;
});

export const getAccountName = createSelector(getSettlements, (settlements) => {
    const currentSettlement = settlements[0];
    if (!currentSettlement) {
        return '';
    }
    return currentSettlement.accountName;
});

export const getSettlementsPagination = createSelector(voiceSelector, (voiceState: IStateVoice) => voiceState.settlementsPagination);
export const getSettlementUpdateCount = createSelector(voiceSelector, (voiceState: IStateVoice) => voiceState.settlementUpdateCount);
export const getSwapAgreementUpdateCount = createSelector(voiceSelector, (voiceState: IStateVoice) => voiceState.swapAgreementUpdateCount);

export const decorateComparisonResult = (item: any, currencySymbol: string) => ({
    ...item,
    key: item.id,
    amountDiff: item.amountDiff,
    types: processDiscrepacyTypes(item.discrepancies),
    amountDiffFormatted: formatBalance(item.amountDiff, 2, {
        currency: currencySymbol,
        roundToInteger: true
    }),
    minutesDiffFormatted: formatBalance(item.minutesDiff, 2, { roundToInteger: true }),
    callsDiffFormatted: formatBalance(item.callsDiff, 2, { roundToInteger: true }),
    aPeriod: formatPeriod(item.aMinDate, item.aMaxDate),
    bPeriod: formatPeriod(item.bMinDate, item.bMaxDate),
    // PATCH: item.aMaxDate for now - should be changed in drop 2 to both sides
    aAmountFormatted: formatBalance(item.aAmount, 2, {
        currency: currencySymbol,
        roundToInteger: true
    }),
    bAmountFormatted: formatBalance(item.bAmount, 2, {
        currency: currencySymbol,
        roundToInteger: true
    }),
    bMinutes: formatBalance(item.bMinutes, 2),
    aMinutes: formatBalance(item.aMinutes, 2),
    aRates: splitCSV(item.aRates).map(formatRate.bind(null, currencySymbol)),
    bRates: splitCSV(item.bRates).map(formatRate.bind(null, currencySymbol)),
    aAmount: formatBalance(item.aAmount, 2, { roundToInteger: true }),
    bAmount: formatBalance(item.bAmount, 2, { roundToInteger: true }),
    aCalls: formatBalance(item.aCalls, 2, { roundToInteger: true }),
    bCalls: formatBalance(item.bCalls, 2, { roundToInteger: true })
});
export const getPeriods = createSelector(voiceSelectorWithCurrency, ([{ period: { items } }, currencySymbol]) =>
    map(items, (item) => decorateComparisonResult(item, currencySymbol))
);

export const getDrilldownCDRsResult = createSelector(voiceSelector, ({ drilldownCDRs }) => drilldownCDRs);
export const getDrilldownCDRsByDateResult = createSelector(voiceSelector, ({ drilldownCDRsByDate }) => drilldownCDRsByDate);

export const getFileDialogInfo = createSelector(voiceSelector, (voiceState: IStateVoice) => ({
    fileDialogMessage: voiceState.fileDialogMessage,
    isFileDialogOpen: voiceState.isFileDialogOpen
}));

export const getIsFileUploading = createSelector(voiceSelector, (voiceState: IStateVoice) => voiceState.isFileUploading);

export const getPeriodPagination = createSelector(voiceSelector, (voiceState: IStateVoice) => voiceState.period.pagination);

export const getStatus = createSelector(voiceSelector, (voiceState: IStateVoice) => voiceState.status);

export const getSummary = createSelector(voiceSelector, (voiceState: IStateVoice) => voiceState.periodSummary);

export const getComparisonType = createSelector(voiceSelector, (voiceState: IStateVoice) => voiceState.comparisonType);
export const getMatchType = createSelector(voiceSelector, ({ matchType }: IStateVoice) => matchType);

export const getExternalPeriods = createSelector(voiceSelector, ({ externalPeriods }: IStateVoice) => externalPeriods);

export const getPeriodFilters = createSelector(
    voiceSelector,
    (voiceState: IStateVoice): IPeriodFilters => voiceState.periodFilters as IPeriodFilters
);

export const getDisputeOfferResult = createSelector(voiceSelector, ({ disputeResolutionResult }: IStateVoice) => disputeResolutionResult);
export const getDisputeOffers = createSelector(voiceSelector, ({ disputeResolutionOffers }: IStateVoice) => disputeResolutionOffers);

export const getRatesheetDestinations = createSelector(voiceSelector, ({ payableDestinations, receivableDestinations }: IStateVoice) => ({
    [Direction.Payable]: payableDestinations,
    [Direction.Receivable]: receivableDestinations
}));

export const getAgreements = createSelector(voiceSelector, ({ agreements }: IStateVoice) => agreements);
export const getAgreement = createSelector(voiceSelector, ({ agreement }: IStateVoice) => agreement);
export const getAgreementDraft = createSelector(voiceSelector, ({ agreementDraft }: IStateVoice) => agreementDraft);
const discType2Display: { [key: string]: string } = {
    no_match: 'Calls'
};
const display2DiscType = invert(discType2Display);

export const convertDiscrepancyType = (type: string): string => discType2Display[type] || type;
export const unconvertDiscrepancyType = (type: string): string => display2DiscType[type] || type;
