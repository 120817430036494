import * as React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { IRootState } from '@reducers/index';
import { isAuthenticated } from '@selectors/auth';

interface IProps {
    component: any;
    path: string;
    isUserAuthenticated?: boolean;
    exact?: boolean;
    location?: any;
    computedMatch?: any;
    match?: any;
}

@connect(
    createStructuredSelector<IRootState, any>({
        isUserAuthenticated: isAuthenticated
    })
)
export class AnonymousRoute extends React.Component<IProps> {
    render() {
        const { isUserAuthenticated, ...rest } = this.props;

        if (isUserAuthenticated) {
            const pathPlusSearch = location.search && location.search.split('redirect=')[1];
            const [path, search] = (pathPlusSearch || '').split('?');
            const pathName = path && path !== '/' ? path : '/dashboard';
            return (
                <Redirect
                    to={{
                        pathname: pathName,
                        search: search || ''
                    }}
                />
            );
        }
        return <Route {...rest} />;
    }
}
