import * as React from 'react';
import classNames from 'classnames';
import makeDelay from 'lodash/delay';

const delayTimeout = 600;

export interface IReduxSpinnerProps {
    spinner: {
        successMessage: string;
        isLoading: boolean;
        message: string;
        classes: string | null;
        pendingCount: number;
    };
}

export interface IReduxSpinnerState {
    delay: boolean;
    done: boolean;
}

export class ReduxSpinner extends React.PureComponent<IReduxSpinnerProps, IReduxSpinnerState> {
    state = {
        delay: false,
        done: false
    };

    componentDidUpdate(prevProps: IReduxSpinnerProps, prevState: IReduxSpinnerState) {
        const wasLoading = prevProps.spinner.isLoading || prevProps.spinner.pendingCount > 0;
        const isLoading = this.props.spinner.isLoading || this.props.spinner.pendingCount > 0;
        if (!wasLoading && isLoading && prevState.done) {
            this.setState({
                delay: true,
                done: false
            });
        }
        if (wasLoading && !isLoading) {
            makeDelay(() => this.setState({ done: true }), delayTimeout);
        }
    }

    render() {
        const { successMessage, message, classes } = this.props.spinner;
        const { delay, done } = this.state;

        const backDropClassList = classNames('back-drop', { closed: done });

        const spinnerClassList = classNames('spinner', classes, {
            closed: done
        });
        return (
            <div className={backDropClassList}>
                <div className={spinnerClassList}>
                    <div className="loader" />
                    <span className="message">{delay ? message : successMessage}</span>
                </div>
            </div>
        );
    }
}
