import { createSelector } from 'reselect';
import get from 'lodash/get';

import { IRootState } from '@reducers/index';
import { IStatePeer, IPeer } from '@actions/peers';
import { accountSelector } from './account';
import filter from 'lodash/filter';

export const peerSelector = (state: IRootState): IStatePeer => state.peers;

export interface IPeers {
    id: string;
    name: string;
    peerId: string | undefined;
}

export const getPeers = createSelector(
    peerSelector,
    accountSelector,
    (peerState, accountState): IPeers => ({
        id: peerState.id,
        name: peerState.name,
        peerId: get(accountState, 'peerId')
    })
);

export const getMyPeers = createSelector(peerSelector, getPeers, (peerState, peers): IPeer[] =>
    filter(peerState.peers, (peer) => peer.id !== peers.peerId && !peer.isDeleted)
);
