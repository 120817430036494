import React from 'react';
import './cdrNamesStyles.scss';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';

interface ICdrNamesProps {
    cdrNames: string[];
}

const CdrNames = (props: ICdrNamesProps) => {
    const stopEventProp = (e: any) => {
        e.stopPropagation();
    };
    const { cdrNames } = props;
    return (
        <div className="cdrNamesWrapper" onClick={stopEventProp}>
            <div className="cdrNamesHeader">{`${cdrNames.length} CDR files found`}</div>
            {cdrNames.map((name: string) => (
                <div className="cdrNameRow" key={name}>
                    {name}
                </div>
            ))}
        </div>
    );
};

const CdrNamesTooltip = withStyles(() => ({
    tooltip: {
        backgroundColor: 'white',
        color: 'rgba(0, 0, 0, 0.87)',
        margin: 0,
        boxShadow: '0 3px 8px 0 rgba(0,0,0,0.08)',
        borderRadius: 4,
        boxSizing: 'border-box',
        width: 382,
        padding: 0
    }
}))(Tooltip);

export { CdrNames, CdrNamesTooltip };
