import { createAction, createRequestAction } from '@helpers/actionCreator';

export enum userActionTypes {
    DELETE_USER = 'DELETE_USER::REQUEST',
    DELETE_USER_SUCCESS = 'DELETE_USER::REQUEST:SUCCESS',
    DELETE_USER_FAIL = 'DELETE_USER::REQUEST:FAIL',

    CREATE_INVITE_LINK = 'CREATE_INVITE_LINK::REQUEST',
    CREATE_INVITE_LINK_SUCCESS = 'CREATE_INVITE_LINK::REQUEST:SUCCESS',
    CREATE_INVITE_LINK_FAIL = 'CREATE_INVITE_LINK::REQUEST:FAIL',
    CREATE_INVITE_LINK_RESET = 'CREATE_INVITE_LINK::REQUEST:RESET',

    CREATE_RESET_PASSWORD_LINK = 'CREATE_RESET_PASSWORD_LINK::REQUEST',
    CREATE_RESET_PASSWORD_LINK_SUCCESS = 'CREATE_RESET_PASSWORD_LINK::REQUEST:SUCCESS',
    CREATE_RESET_PASSWORD_LINK_FAIL = 'CREATE_RESET_PASSWORD_LINK::REQUEST:FAIL',

    EXPIRE_PASSWORD = 'EXPIRE_PASSWORD::REQUEST',
    EXPIRE_PASSWORD_SUCCESS = 'EXPIRE_PASSWORD::REQUEST:SUCCESS',
    EXPIRE_PASSWORD_FAIL = 'EXPIRE_PASSWORD::REQUEST:FAIL',

    UPDATE_USER = 'USER:SPIN:UPDATE_USER',

    GET_POLICIES = 'GET_POLICIES::REQUEST',
    GET_POLICIES_SUCCESS = 'GET_POLICIES::REQUEST:SUCCESS',
    GET_POLICIES_FAIL = 'GET_POLICIES::REQUEST:FAIL',

    GET_GROUPS = 'GET_GROUPS::REQUEST',
    GET_GROUPS_SUCCESS = 'GET_GROUPS::REQUEST:SUCCESS',
    GET_GROUPS_FAIL = 'GET_GROUPS::REQUEST:FAIL',

    GET_ALL_USERS = 'GET_ALL_USERS::REQUEST',
    GET_ALL_USERS_SUCCESS = 'GET_ALL_USERS::REQUEST:SUCCESS',
    GET_ALL_USERS_FAIL = 'GET_ALL_USERS::REQUEST:FAIL',

    TOGGLE_CREATE_USER = 'USER:TOGGLE:CREATE:USER',
    SET_USER_TO_EDIT = 'USER:SET:USER:TO:EDIT',

    GET_MAGIC_LINK_DATA = 'GET_MAGIC_LINK_DATA::REQUEST',
    GET_MAGIC_LINK_DATA_SUCCESS = 'GET_MAGIC_LINK_DATA::REQUEST:SUCCESS'
}

export interface IUserData {
    id: string;
    username: string;
    tadigCodes?: string[];
    isLocked?: boolean;
    isActive?: boolean;
    groupNames: string[];
}

export enum InviteLinkType {
    Invite = 'invite',
    ResetPassword = 'reset-password'
}

export interface IInviteLinkData {
    type: InviteLinkType;
    link: string;
    expirationDate: string;
    daysToExpire: number;
}

export interface IUpdateUserData {
    userId: string;
    newData: Partial<IUserData>;
}

export interface IGroup {
    id: string;
    name: string;
}

export enum LinkType {
    UPLOAD_CDR = 'upload_cdr',
    OFFER = 'offer',
    SWAP_AGREEMENT = 'swap_agreement'
}

export interface IMagicLink {
    uri: string;
    ttl: Date;
    linkType: LinkType;
    wasUsed: boolean;
}

export interface IManageUserState {
    lastRequestToLoginUsername: string;
    magicLinkData: {} | IMagicLink;
    createUser: {
        id: string;
        qrCode: string;
    };
    activateUser: {
        qrCode: string;
        isSuccess: boolean;
        errorMessage: string;
        isLoading: boolean;
    };
    deleteUser: {
        isSuccess: boolean;
    };
    inviteLink: IInviteLinkData;
    resetPasswordResultsByUserId: Record<string, boolean>;
    expirePasswordResultsByUserId: Record<string, boolean>;
    changePassword: {
        qrCode: string;
        isSuccess: boolean;
        errorMessage: string;
        isLoading: boolean;
    };
    users: IUserData[];
    policies: [];
    groups: IGroup[];
    isLoading: boolean;
    error?: string;
    showCreateUser: boolean;
    userToEdit?: IUserData;
}

const userActions = {
    createInviteLink: createRequestAction(userActionTypes.CREATE_INVITE_LINK, 'createUserInviteLink', (userName: string) => [userName]),
    createInviteLinkReset: createAction(userActionTypes.CREATE_INVITE_LINK_RESET),
    createResetPasswordLink: createRequestAction(userActionTypes.CREATE_RESET_PASSWORD_LINK, 'createResetPasswordLink', (userId: string) => [userId]),
    expirePassword: createRequestAction(userActionTypes.EXPIRE_PASSWORD, 'expirePassword', (userId: string) => [userId]),
    deleteUser: createRequestAction(userActionTypes.DELETE_USER, 'deleteUserByUsername', (username: string) => [username]),
    updateUser: createRequestAction(userActionTypes.UPDATE_USER, 'updateUser', ({ userId, newData }: IUpdateUserData) => [userId, newData]),
    getAllPolicies: createRequestAction(userActionTypes.GET_POLICIES, 'getAllPolicies'),
    getAllGroups: createRequestAction(userActionTypes.GET_GROUPS, 'getAllGroups'),
    getAllUsers: createRequestAction(userActionTypes.GET_ALL_USERS, 'getAllUsers'),
    toggleCreateUser: (showDialog: boolean) => ({
        type: userActionTypes.TOGGLE_CREATE_USER,
        payload: showDialog
    }),
    setUserToEdit: (userToEdit?: IUserData) => ({
        type: userActionTypes.SET_USER_TO_EDIT,
        payload: userToEdit
    }),
    getMagicLink: createRequestAction(userActionTypes.GET_MAGIC_LINK_DATA, 'getMagicLink', (relatedObjectId: string, magic: string) => [
        relatedObjectId,
        magic
    ])
};

export default userActions;
