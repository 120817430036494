import * as React from 'react';

import { TRow } from './TRow';
import { TRowExtended } from './TRowExtended';
import { ITableColumnProps } from './index';

interface ITBodyProps {
    data: any[];
    colsOrder: string[];
    selectRow: (rowKey: string | number | null) => React.ComponentState;
    handleRowWithValueClick?: any;
    withCheckboxes?: boolean;
    options?: JSX.Element | ((row: any) => void);
    cols: any;
    placeholderGetter?: () => JSX.Element | null;
}

export class TBody extends React.PureComponent<ITBodyProps> {
    render() {
        const { colsOrder, data, selectRow, handleRowWithValueClick, withCheckboxes, options, cols, placeholderGetter } = this.props;

        return (
            <div className="tbody">
                {data.map((rowData: ITableColumnProps, idx) => {
                    const key = (rowData as any).id || idx;
                    if (rowData.extended) {
                        return <TRowExtended key={key} cols={cols} row={rowData} colsOrder={colsOrder} />;
                    }
                    return (
                        <TRow
                            key={key}
                            colsOrder={colsOrder}
                            cols={cols}
                            row={rowData}
                            selectRow={selectRow}
                            handleRowWithValueClick={handleRowWithValueClick}
                            withCheckboxes={withCheckboxes}
                            options={options}
                            disabled={rowData.disabled}
                        />
                    );
                })}
                {!data.length && placeholderGetter && placeholderGetter()}
            </div>
        );
    }
}
