import * as React from 'react';
import { Box } from '@material-ui/core';
import cloneDeep from 'lodash/cloneDeep';
import { SideMenu } from './MultiSelectSideMenu';
import { MultiSelect } from './MultiSelect';

interface ISelectableItems {
    isSelected: boolean;
    isFilteredIn: boolean;
}

interface IList {
    title: string;
    allItems: string[];
    selectableItems: Record<string, ISelectableItems>;
    selectedItemsArray: string[];
}

interface IState {
    lists: Record<keyof ILists, IList>;
    selectedListKey: keyof ILists;
}

interface IListProp {
    title: string;
    defaultSelectedItems: string[];
    allItems: string[];
}

interface ILists {
    [key: string]: IListProp;
}

interface IProps {
    disabled?: boolean;
    lists: ILists;
    defaultListKey: string;
    onChange(selectedListKey: string[]): any;
}

export class MultiSelectWithSideMenu extends React.Component<IProps, IState> {
    state: IState = {
        lists: {},
        selectedListKey: ''
    };

    constructor(props: IProps) {
        super(props);
        const { lists, selectedListKey } = this.preparePropsToState();
        this.state = {
            lists,
            selectedListKey
        };
    }

    preparePropsToState = () => {
        const { lists, defaultListKey } = this.props;
        const listsToState: IState['lists'] = {};
        for (const [listKey, listData] of Object.entries(lists)) {
            const { allItems, defaultSelectedItems, title } = listData;
            const selectableItems = allItems.reduce((acc, item) => {
                const isItemSelected = defaultSelectedItems.includes(item);
                return {
                    ...acc,
                    [item]: { isSelected: isItemSelected, isFilteredIn: true }
                };
            }, {});
            listsToState[listKey] = { title, allItems, selectableItems, selectedItemsArray: defaultSelectedItems };
        }
        return {
            lists: listsToState,
            selectedListKey: defaultListKey
        };
    };

    componentDidUpdate(prevProps: IProps, prevState: IState) {
        const { selectedListKey } = this.state;
        if (this.props.lists[selectedListKey].defaultSelectedItems !== prevProps.lists[selectedListKey].defaultSelectedItems) {
            const { lists: newLists } = this.preparePropsToState();
            this.setState(() => ({
                selectedListKey,
                lists: newLists
            }));
        }
    }

    getMenuItems = () => {
        const { lists } = this.state;
        const menuItems = [];
        for (const [key, value] of Object.entries(lists)) {
            menuItems.push({ key, title: value.title });
        }
        return menuItems;
    };

    prepareSelectedItems = (lists: IState['lists'], selectedListKey: IState['selectedListKey']) => {
        const selectableItems = lists[selectedListKey].selectableItems;
        const selectedItems: string[] = [];
        for (const [itemName, { isSelected }] of Object.entries(selectableItems)) {
            if (isSelected) {
                selectedItems.push(itemName);
            }
        }
        return selectedItems;
    };

    onMenuItemClick = (listKey: IState['selectedListKey']): void => {
        const { onChange } = this.props;
        const { lists } = this.state;
        const selectedItems = this.prepareSelectedItems(lists, listKey);
        this.setState({ selectedListKey: listKey }, () => onChange(selectedItems));
    };

    onSelectItemClick = (itemName: string): void => {
        const { onChange } = this.props;
        const { lists, selectedListKey } = this.state;
        const copyOfSelectableItems = cloneDeep(lists[selectedListKey].selectableItems);
        copyOfSelectableItems[itemName].isSelected = !copyOfSelectableItems[itemName].isSelected;
        let updatedLists = { ...lists, [selectedListKey]: { ...lists[selectedListKey], selectableItems: copyOfSelectableItems } };
        const selectedItems = this.prepareSelectedItems(updatedLists, selectedListKey);
        updatedLists = { ...updatedLists, [selectedListKey]: { ...updatedLists[selectedListKey], selectedItemsArray: selectedItems } };
        this.setState(
            (prevState) => ({
                ...prevState,
                lists: updatedLists
            }),
            () => onChange(selectedItems)
        );
    };

    onSearchBarChange = (value: string) => {
        const { lists, selectedListKey } = this.state;
        const copyOfSelectableItems = cloneDeep(lists[selectedListKey].selectableItems);
        Object.keys(copyOfSelectableItems).map((itemName) => {
            const itemStartWithSearchValue = itemName.toLowerCase().startsWith(value.toLowerCase());
            copyOfSelectableItems[itemName].isFilteredIn = itemStartWithSearchValue ? true : false;
        });
        this.setState((prevState) => ({
            ...prevState,
            lists: { ...prevState.lists, [selectedListKey]: { ...prevState.lists[selectedListKey], selectableItems: copyOfSelectableItems } }
        }));
    };

    render() {
        const { lists, selectedListKey } = this.state;
        const { disabled } = this.props;
        return (
            <Box width={'700px'} height={'400px'} border={'1px solid rgba(65,83,125,0.2)'} display={'flex'} borderRadius={'4px'}>
                <SideMenu
                    menuItems={this.getMenuItems()}
                    onItemClick={this.onMenuItemClick}
                    selectedItem={selectedListKey as string}
                    disabled={disabled}
                />
                <MultiSelect
                    disabled={disabled}
                    onItemClick={this.onSelectItemClick}
                    selectableItems={lists[selectedListKey].selectableItems}
                    onSearchBarChange={this.onSearchBarChange}
                />
            </Box>
        );
    }
}
