import React from 'react';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import './menuStyles.scss';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import { PopoverOrigin } from '@material-ui/core';

const defaultAnchorOrigin: PopoverOrigin = {
    vertical: 42,
    horizontal: 'right'
};

enum MenuOnHoverColor {
    BLUE = 'blue'
}

interface IClearMenuProps extends WithStyles<typeof styles> {
    menuWrapperClassName?: string;
    buttonStyle: React.CSSProperties;
    children: React.ReactNode;
    IconComp?: React.ReactElement;
    disabled?: boolean;
    anchorOrigin?: PopoverOrigin;
    transformOrigin?: PopoverOrigin;
    onHoverMenuItemColor?: MenuOnHoverColor;
}

const styles = {
    menuList: {
        padding: 0
    },
    menuPaper: {
        boxShadow: '0 3px 8px 0 rgba(0,0,0,0.15)'
    },
    menuItemGreyBG: {
        '&:hover': {
            backgroundColor: '#F1F1F1'
        }
    },
    menuItemBlueBG: {
        '&:hover': {
            backgroundColor: '#4a83ff',
            color: '#fff'
        }
    }
};

function ClearMenu(props: IClearMenuProps) {
    const { menuWrapperClassName, buttonStyle, IconComp, children, disabled, anchorOrigin, transformOrigin, classes, onHoverMenuItemColor } = props;

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const menuItemStyle = (): string => {
        switch (onHoverMenuItemColor) {
            case MenuOnHoverColor.BLUE:
                return classes.menuItemBlueBG;
            default:
                return classes.menuItemGreyBG;
        }
    };
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div className={menuWrapperClassName}>
            <IconButton
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={handleClick}
                disabled={disabled}
                style={buttonStyle}
            >
                {IconComp ? IconComp : <MoreVertIcon />}
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                transformOrigin={transformOrigin}
                anchorOrigin={anchorOrigin || defaultAnchorOrigin}
                getContentAnchorEl={null}
                classes={{ paper: classes.menuPaper, list: classes.menuList }}
            >
                {React.Children.map(
                    children,
                    (child) =>
                        child && (
                            <MenuItem onClick={handleClose} classes={{ root: menuItemStyle() }}>
                                {child}
                            </MenuItem>
                        )
                )}
            </Menu>
        </div>
    );
}

export default withStyles(styles)(ClearMenu);
