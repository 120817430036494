import { createRequestAction } from '@helpers/actionCreator';

export enum currencyActionType {
    GET_CURRENCY_RATE = 'GET_CURRENCY_RATE',
    GET_CURRENCY_RATE_SUCCESS = 'GET_CURRENCY_RATE::REQUEST:SUCCESS'
}

export const currencyActions = {
    getCurrencyRates: createRequestAction(currencyActionType.GET_CURRENCY_RATE, 'getCurrencyRates', [])
};
