import React, { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { Table } from 'antd';
import { ColumnProps } from 'antd/es/table';
import { createStyles, Theme } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { TableOptions } from '@components/ClearTable/TableOptions';
import { ManageTableColumnsDialog } from '@components/ClearTable/ManageTableColumnsDialog';
import ToggleExpandRowIcon from '@ant-design/icons/DownOutlined';
import './ClearTable.scss';
import Box from '@material-ui/core/Box';
import { TableProps } from 'antd/lib/table';
import { useHistory, useLocation } from 'react-router';
import { useWindowHeight } from '@react-hook/window-size';
import { TableLocale } from 'antd/es/table/interface';

export const DEFAULT_PAGE_SIZE = 20;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            borderRadius: theme.shape.borderRadius,
            boxShadow: '0 3px 8px 0 rgba(0,0,0,0.18)'
        },
        row: {
            cursor: 'pointer'
        },
        toggleExpandIcon: {
            color: theme.palette.text.secondary,
            fontSize: 12,
            transition: 'transform .2s'
        }
    })
);

export interface IFetchParams<T = any> {
    query: Record<keyof T, any>;
    sorting: { by: keyof T; order: 'asc' | 'desc' };
    pagination: { page: number; pageSize: number };
    filter: Record<keyof T, any>;
    select: string[];
}

export type IFetcher<T = any> = (params: Partial<IFetchParams<T>>) => void | Promise<any>;

export type IFetchExport = () => Promise<{ items: any[]; fileName?: string }>;

export interface IClearTableProps {
    width?: number;
    entityName: string;
    fetch: IFetcher;
    rowKey?: TableProps<any>['rowKey'];
    localStorageKey?: string;
    total: number;
    items: any[];
    isLoading: boolean;
    disablePagination?: boolean;
    tableTestName?: string;
    bottomScreenOffset?: number;
    fetchExport?: IFetchExport;
    hideMenu?: boolean;
    height?: number;
    emptyStateText?: TableLocale['emptyText'];

    getColumns(): IColumn[];

    onRowClick?(row: any, index: number): void;

    renderActions?(row: any): ReactNode;
}

export enum ColumnVisibility {
    Visible = 'visible',
    Hidden = 'hidden',
    None = 'none'
}

export interface IUrlQueryBinding<V extends any = any> {
    name: string;
    encode(value: V): string;
    decode(encodedValue: string): V;
}

export interface IColumn<Record extends any = any> extends ColumnProps<Record> {
    visibility?: ColumnVisibility;
    export?: boolean;
    bindUrlQuery?: string | IUrlQueryBinding;
    exportTransform?(value: any, item: Record): any;
}

export interface ITableContext {
    entityName: string;
    fetch: IFetcher;
    columns: IColumn[];

    fetchExport?: IFetchExport;

    setColumns(cols: IColumn[]): void;

    setColumnManager(isOpen: boolean): void;
}

export const TableCtx = React.createContext<ITableContext>({
    entityName: '',
    fetch: () => undefined,
    columns: [],
    setColumns(cols: IColumn[]) {
        return;
    },
    setColumnManager(isOpen: boolean) {
        return;
    },
    async fetchExport() {
        return {
            items: []
        };
    }
});

export const ClearTable: React.FC<IClearTableProps> = ({
    disablePagination,
    localStorageKey,
    entityName,
    fetch,
    rowKey,
    getColumns,
    onRowClick,
    isLoading,
    items,
    total,
    fetchExport,
    tableTestName,
    renderActions,
    width: initialWidth,
    height,
    bottomScreenOffset,
    hideMenu,
    emptyStateText
}) => {
    const { search } = useLocation();
    const history = useHistory();
    const urlQuery = new URLSearchParams(search);
    const [pageSize, setPageSize] = useState<number>(DEFAULT_PAGE_SIZE);
    const windowHeight = useWindowHeight();

    const classes = useStyles();

    const [isColumnManagerOpen, setColumnManager] = useState(false);

    const computeColumns = useCallback(() => {
        let hiddenColumns: string[] = [];
        if (localStorageKey) {
            hiddenColumns = JSON.parse(localStorage.getItem(localStorageKey) || '[]');
        }

        return getColumns().map(
            (c): IColumn => {
                let computedVisibility: ColumnVisibility = c.visibility!;
                if (!computedVisibility) {
                    computedVisibility = ColumnVisibility.Visible;
                }
                if (computedVisibility !== ColumnVisibility.None) {
                    computedVisibility =
                        computedVisibility === ColumnVisibility.Visible && !hiddenColumns.includes(c.dataIndex as string)
                            ? ColumnVisibility.Visible
                            : ColumnVisibility.Hidden;
                }
                return { ...c, className: 'custom-header', visibility: computedVisibility };
            }
        );
    }, [getColumns]);

    const [columns, setColumns] = useState(computeColumns);
    const setColumnsExternally = useCallback(
        (cols: IColumn[]) => {
            if (localStorageKey) {
                const hiddenColumns = cols.filter((c) => c.visibility === ColumnVisibility.Hidden).map((c) => c.dataIndex);
                localStorage.setItem(localStorageKey, JSON.stringify(hiddenColumns));
            }
            setColumns(cols);
        },
        [localStorageKey, setColumns]
    );
    const selectFields = useMemo(() => columns.map((c) => c.dataIndex as string), [columns]);

    useEffect(() => {
        const defaultSortColumn = columns.find((c) => c.defaultSortOrder);
        let sorting: IFetchParams['sorting'] | undefined;
        if (defaultSortColumn) {
            sorting = {
                by: defaultSortColumn.dataIndex as string,
                order: defaultSortColumn.defaultSortOrder === 'descend' ? 'desc' : 'asc'
            };
        }

        const boundUrlQueryParams = columns
            .filter((c) => c.bindUrlQuery && c.dataIndex)
            .map((c) => ({ dataIndex: c.dataIndex!.toString(), urlQuery: c.bindUrlQuery! }));
        const query: Record<string, any> = {};
        boundUrlQueryParams.forEach((param) => {
            let queryParamValue;
            if (typeof param.urlQuery === 'string') {
                queryParamValue = urlQuery.get(param.urlQuery);
            } else {
                const { name, decode } = param.urlQuery;
                const encodedValue = urlQuery.get(name);
                if (encodedValue) {
                    queryParamValue = decode(encodedValue);
                }
            }
            if (queryParamValue) {
                query[param.dataIndex] = queryParamValue;
            }
        });

        fetch({
            query,
            pagination: { page: 1, pageSize },
            sorting,
            select: selectFields
        });
    }, []);

    const onChange = useCallback(
        (pagination, filters, sorter: any) => {
            const query: any = {};

            Object.keys(filters).forEach((field) => {
                const value = filters[field] && (filters[field].length === 1 ? filters[field][0] : filters[field]);
                const column = columns.find((c) => c.dataIndex === field);
                if (value != null) {
                    if (column?.bindUrlQuery) {
                        if (typeof column.bindUrlQuery === 'string') {
                            urlQuery.set(column.bindUrlQuery, value);
                        } else {
                            const { name, encode } = column.bindUrlQuery;
                            urlQuery.set(name, encode(value));
                        }
                        history.replace({ search: urlQuery.toString() });
                    }

                    query[field] = value;
                } else if (column?.bindUrlQuery) {
                    if (typeof column.bindUrlQuery === 'string') {
                        urlQuery.delete(column.bindUrlQuery);
                    } else {
                        const { name } = column.bindUrlQuery;
                        urlQuery.delete(name);
                    }
                    history.replace({ search: urlQuery.toString() });
                }
            });

            const sorting = sorter && {
                by: sorter.field,
                order: sorter.order === 'ascend' ? ('asc' as 'asc') : ('desc' as 'desc')
            };
            fetch({
                query,
                sorting,
                pagination: { page: pagination.current, pageSize: pagination.pageSize },
                select: selectFields
            });
        },
        [fetch, urlQuery]
    );

    useEffect(() => {
        const computedColumns = computeColumns();
        setColumns(computedColumns);
    }, [computeColumns]);

    const generateRowProps = useCallback(
        (row, i) => {
            return {
                'data-t': 'table-row',
                onClick: () => onRowClick && onRowClick(row, i)
            };
        },
        [onRowClick]
    );

    const onChangePageSize = useCallback((_: number, size: number) => {
        setPageSize(size);
    }, []);

    const calculateTableWidth = useCallback(() => {
        const width = columns.reduce(
            (w, col) => w - (col.visibility !== ColumnVisibility.Visible && col.width ? (col.width as number) : 0),
            initialWidth!
        );
        return width;
    }, [columns]);

    const closeColumnManager = useCallback(() => setColumnManager(false), [setColumnManager]);

    const visibleColumns = useMemo(() => {
        const computedColumns = columns
            .filter((col) => col.visibility === ColumnVisibility.Visible)
            .map((c) => {
                if (!(c as any).marked) {
                    const s = c.render;
                    c.render = function wrapper(value: any, record: any, index: number) {
                        const children = s ? s(value, record, index) : value;
                        return {
                            children,
                            props: {
                                'data-t': c.dataIndex
                            }
                        };
                    };
                    (c as any).marked = true;
                }
                if (c.bindUrlQuery) {
                    let queryParamValue;
                    if (typeof c.bindUrlQuery === 'string') {
                        queryParamValue = urlQuery.get(c.bindUrlQuery);
                    } else {
                        const { name, decode } = c.bindUrlQuery;
                        const encodedValue = urlQuery.get(name);
                        if (encodedValue) {
                            queryParamValue = decode(encodedValue);
                        }
                    }
                    if (queryParamValue) {
                        c.defaultFilteredValue = queryParamValue;
                    }
                }
                return c;
            });
        return hideMenu ? computedColumns : computedColumns.concat(generateMenuColumn(renderActions));
    }, [columns, fetch]);

    const ctx: ITableContext = { fetch, columns, setColumns: setColumnsExternally, setColumnManager, entityName, fetchExport };
    return (
        <TableCtx.Provider value={ctx}>
            {isColumnManagerOpen && <ManageTableColumnsDialog close={closeColumnManager} />}
            <Table
                locale={{
                    emptyText: emptyStateText
                }}
                expandable={{
                    expandRowByClick: true,
                    expandIcon: ({ expanded, record }) => {
                        if (!record.children) {
                            return null;
                        }
                        return (
                            <Box clone mr={2}>
                                <ToggleExpandRowIcon
                                    className={classes.toggleExpandIcon}
                                    style={{ transform: `rotateZ(${expanded ? '-180' : '0'}deg)` }}
                                />
                            </Box>
                        );
                    }
                }}
                className={classes.root}
                rowClassName={classes.row}
                loading={isLoading}
                pagination={
                    !disablePagination && {
                        total,
                        showTotal: (numOfItems: number, range: [number, number]) => (
                            <span data-t={'pagination-data'}>{`${range[0]}-${range[1]} of ${numOfItems} ${entityName}`}</span>
                        ),
                        pageSize,
                        showSizeChanger: true,
                        onShowSizeChange: onChangePageSize
                    }
                }
                rowKey={rowKey}
                onRow={generateRowProps}
                sortDirections={['ascend', 'descend', 'ascend']}
                onChange={onChange}
                columns={visibleColumns}
                scroll={{ x: calculateTableWidth(), y: height || windowHeight - bottomScreenOffset! }}
                dataSource={items}
                data-t={tableTestName}
            />
        </TableCtx.Provider>
    );
};

ClearTable.defaultProps = {
    rowKey: 'id',
    disablePagination: false,
    width: 1700,
    bottomScreenOffset: 300,
    hideMenu: false
};

const generateMenuColumn = (renderRow?: (row: any) => ReactNode): IColumn => ({
    title: <TableOptions />,
    fixed: 'right',
    align: 'right',
    width: 96,
    className: 'menu-button',
    export: false,
    render: (_, row) => renderRow && renderRow(row),
    visibility: ColumnVisibility.Visible
});
