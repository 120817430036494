import * as React from 'react';
import { Tag } from 'antd';

export enum BadgeType {
    Success = 'success',
    Warning = 'warning',
    Uploaded = 'uploaded'
}

const COLOR_BY_STATUS: Record<BadgeType, string> = {
    success: '#12ce8d',
    warning: '#feaf33',
    uploaded: '#6c6eff'
};

interface IClearBadgeProps {
    style?: React.CSSProperties;
    type: BadgeType;
    status: string;
}

export const ClearBadge: React.FC<IClearBadgeProps> = ({ style, type, status }) => (
    <Tag color={COLOR_BY_STATUS[type]} style={{ textTransform: 'uppercase', ...style }} data-t={'status-badge'}>
        {status}
    </Tag>
);
