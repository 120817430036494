import * as React from 'react';
import { Dialog } from '@components';
import { connect } from 'react-redux';
import { NetworkStatus } from '@reducers/app';
import './styles.scss';
import { IRootState } from '@reducers';
import { CircularProgress } from '@material-ui/core';

const GRACE_PERIOD_IN_MS = 2e3;

interface INetworkStatusDialogProps {
    networkStatus?: NetworkStatus;
}

interface INetworkStatusDialogState {
    timeoutId: number;
    shouldShowDialog: boolean;
}

const mapStateToProps = (state: IRootState) => ({
    networkStatus: state.app.networkStatus
});

@(connect(mapStateToProps) as any)
export class NetworkStatusDialog extends React.Component<INetworkStatusDialogProps, INetworkStatusDialogState> {
    static getDerivedStateFromProps(nextProps: any, prevState: any) {
        if (nextProps.networkStatus === NetworkStatus.Connected) {
            if (prevState.timeoutId) {
                clearTimeout(prevState.timeoutId);
            }

            return {
                timeoutId: 0,
                shouldShowDialog: false
            };
        }

        return null;
    }

    state = {
        timeoutId: 0,
        shouldShowDialog: false
    };

    componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any): void {
        const { networkStatus } = this.props;
        const isNetworkDisconnected = ![NetworkStatus.Uninitialized, NetworkStatus.Connected].includes(networkStatus!);
        if (isNetworkDisconnected && !prevState.timeoutId) {
            clearTimeout(prevState.timeoutId);
            const timeoutId = window.setTimeout(() => {
                this.setState({ shouldShowDialog: true, timeoutId: 0 });
            }, GRACE_PERIOD_IN_MS);

            this.setState({
                timeoutId
            });
        }
    }

    render() {
        const { networkStatus } = this.props;
        const { shouldShowDialog } = this.state;
        return (
            <Dialog classList="network-status-dialog" isOpen={shouldShowDialog} hideCloseButton>
                {renderMessage(networkStatus!)}
            </Dialog>
        );
    }
}

function renderMessage(status: NetworkStatus) {
    switch (status) {
        case NetworkStatus.BrowserOffline: {
            return (
                <div>
                    <p>It seems you're not connected to the Internet.</p>
                    <p>Once you're back online, we'll reconnect you automatically</p>
                </div>
            );
        }
        case NetworkStatus.Disconnected: {
            return (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <p style={{ marginRight: 8 }}>Reconnecting to server...</p>
                    <CircularProgress size={18} />
                </div>
            );
        }
        case NetworkStatus.Connected: {
            return <div>Connected!</div>;
        }
        default: {
            return null;
        }
    }
}
