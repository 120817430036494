import * as React from 'react';
import classNames from 'classnames';

import './styles.scss';

export interface IMenuButtonProps {
    name: string;
    classes?: string;
    children?: string;
    onClick?: (e: any) => void;
    error: string;
}

export class MenuButton<P extends IMenuButtonProps, S extends {}> extends React.Component<P, S> {
    randomId = Math.random()
        .toString()
        .replace('.', '');

    render() {
        const { name, classes, children } = this.props;

        return (
            <label onClick={this.props.onClick} className={classNames('btn btn-primary menu-button', classes)} htmlFor={name + this.randomId}>
                <span>{children || name}</span>
            </label>
        );
    }
}
