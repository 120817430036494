import { accountType } from '@actions/account';
import { IAppAction } from './state';

export enum NodeType {
    Roaming = 'roaming',
    Voice = 'voice'
}

export interface IStateAccount {
    peerId: string;
    session?: {
        id: string;
        username: string;
        attributes: {
            admin: boolean;
        };
        groupNames: string[];
        lastLogin: string;
    };
    nodeType: NodeType[];
    bankAccounts: string[];
    nodeName: string;
    peerName: string;
    presentedName: string;
    peerType: string;
    userName: string;
    loading: boolean;
}

const initialState: IStateAccount = {
    nodeType: [],
    bankAccounts: [],
    nodeName: '',
    peerName: '',
    peerType: '',
    peerId: '',
    userName: '',
    presentedName: '',
    loading: false
};

export function accountReducer(state: IStateAccount = initialState, action: IAppAction): IStateAccount {
    switch (action.type) {
        case accountType.ACCOUNT_GET_INFO_REQUEST:
            return { ...state, loading: true };
        case accountType.ACCOUNT_GET_INFO_SUCCESS:
            return {
                ...state,
                ...action.payload,
                loading: false,
                isPaymentGateway: Boolean(action.payload.isPaymentGateway)
            };
        case accountType.ACCOUNT_GET_PEERNAME_SUCCESS:
            return { ...state, peerName: action.payload };
        case accountType.ACCOUNT_GET_USERNAME_SUCCESS:
            return { ...state, userName: action.payload };
        default:
            return state;
    }
}
