import { createRSType } from '@actions/RatesSheetParser/createRS';
import { IAppAction } from '../state';

export interface IStateCreateRS {
    loading: boolean;
    uploadResult: 'success' | 'fail' | null;
}

const initialState = {
    loading: false,
    uploadResult: null as 'success' | 'fail' | null
};

export function createRSReducer(state = initialState, action: IAppAction): IStateCreateRS {
    switch (action.type) {
        case createRSType.UPLOAD_RS_REQUEST:
            return { ...state, loading: true, uploadResult: null };
        case createRSType.UPLOAD_RS_SUCCESS:
            return { ...state, loading: false, uploadResult: 'success' };
        case createRSType.UPLOAD_RS_FAIL:
            return { ...state, loading: false, uploadResult: 'fail' };
        default:
            return state;
    }
}
