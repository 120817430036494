import * as React from 'react';
import classNames from 'classnames';

import { Dropdown } from '@components/Buttons';
import { Option } from './Option';

import './styles.scss';

interface ISelectFieldData {
    input: any;
    meta: any;
    options: any[];
    placeholder?: string;
    label?: string;
    classList?: string;
    disabled?: boolean;
    closeOnOutsideClick?: boolean;
}

export const SelectField = ({
    closeOnOutsideClick,
    input,
    options,
    placeholder,
    label,
    classList,
    meta: { touched, invalid, error, active },
    disabled
}: ISelectFieldData): JSX.Element => {
    const [selectedOption] = options.filter((option) => option.value === input.value);
    const currentError = touched && invalid && error;

    const dropdownClasses = classNames(
        'field field-select',
        { 'is-active': active },
        { 'has-value': input.value && input.value.length > 0 },
        { 'has-error': currentError },
        { 'is-disabled': disabled },
        classList
    );

    const dropdownTitle = selectedOption ? selectedOption.name : placeholder;

    return (
        <Dropdown
            title={dropdownTitle}
            classList={dropdownClasses}
            label={label}
            name={input.name}
            error={currentError}
            disabled={disabled}
            closeOnOutsideClick={closeOnOutsideClick}
        >
            {options.map(({ value, name, isDisabled }) => (
                <Option
                    key={value}
                    value={value}
                    title={name}
                    isSelected={value === input.value}
                    handleOptionClick={input.onChange}
                    isDisabled={isDisabled}
                />
            ))}
        </Dropdown>
    );
};
