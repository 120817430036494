import { GeneralDialog } from '@components/Modal/GeneralDialog';
import React from 'react';
import { Box } from '@material-ui/core';
import SuccessIcon from '@ant-design/icons/CheckCircleFilled';
import { T } from '@components/T/T';
import { FontColor, FontWeight } from '@components/theme';
import { Button, Divider } from 'antd';

interface Props {
    msg: string;
    onClose(): void;
}

const RIGHT_PADDING = 8;
export const SuccessDialog: React.FC<Props> = ({ onClose, msg }) => {
    return (
        <GeneralDialog width={540} isDialogOpen headerText="File Uploaded" shouldCloseOnClickOutside onCloseClick={onClose}>
            <Box pt={8} pb={3}>
                <Box pl={14} pr={RIGHT_PADDING} display="flex">
                    <Box mr={4}>
                        <SuccessIcon style={{ fontSize: 24, color: FontColor.Success }} />
                    </Box>
                    <Box flex={1}>
                        <Box>
                            <Box mb={6}>
                                <T weight={FontWeight.Semibold}>{msg}</T>
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <Divider />
                <Box pr={RIGHT_PADDING} display="flex" justifyContent="flex-end">
                    <Box mr={3} clone>
                        <Button onClick={onClose}>Close</Button>
                    </Box>
                </Box>
            </Box>
        </GeneralDialog>
    );
};
