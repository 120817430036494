import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import userActions, { IUserData } from '@actions/users';
import {
    getExpirePasswordResultsById,
    getGroups,
    getInviteLinkData,
    getManagedUsers,
    getResetPasswordResultsById,
    getShouldShowCreateUser,
    getUserError
} from '@selectors/user';
import { InviteLinkDialog } from '@components/UserManagement/IntiveLinkDialog';
import { Input, Modal, Tooltip } from 'antd';
import ResetPasswordIcon from '@ant-design/icons/ReloadOutlined';
import CheckIcon from '@ant-design/icons/CheckCircleOutlined';
import MailIcon from '@ant-design/icons/MailOutlined';
import EditIcon from '@ant-design/icons/EditOutlined';
import DeleteIcon from '@ant-design/icons/DeleteOutlined';
import UserAddIcon from '@ant-design/icons/UserAddOutlined';
import './styles.scss';

import { getSessionUsername, isRoamingNodeSelector } from '@selectors/account';
import { NewUserDialog } from './NewUserDialog';
import { ITableColumnProps, Table } from '@components/Table';

import { ExpirePasswordIcon } from '@components/Svg/ExpirePasswordIcon';

const { Search } = Input;

const columns: ITableColumnProps[] = [
    { key: 'username', title: 'UserName' },
    { key: 'groups', title: 'Group' },
    { key: 'tadig', title: 'TADIG Codes' },
    { key: 'status', title: 'Status', sortingKey: 'statusText' },
    { key: 'actions', title: 'Actions', notSortable: true }
];

export const UserCreation: React.FC<any> = (props: any) => {
    const dispatch = useDispatch();
    const groups = useSelector(getGroups);
    const users = useSelector(getManagedUsers);
    useEffect(() => {
        dispatch(userActions.getAllUsers());
        dispatch(userActions.getAllGroups());
        dispatch(userActions.toggleCreateUser(false));
    }, []);

    const userInviteLinkData = useSelector(getInviteLinkData);
    const isRoamingNode = useSelector(isRoamingNodeSelector);
    const currentUsername = useSelector(getSessionUsername);
    const userCreationError = useSelector(getUserError);
    const resetPasswordResultsByUserId = useSelector(getResetPasswordResultsById);
    const expirePasswordResultsByUserId = useSelector(getExpirePasswordResultsById);
    const showCreateUser = useSelector(getShouldShowCreateUser);

    const [userToDelete, setUserToDelete] = useState('');
    const [searchQuery, setSearchQuery] = useState('');
    const [inviteLinkUsername, setInviteLinkUsername] = useState('');

    const groupsByName: any = {};
    groups.forEach((group) => {
        groupsByName[group.name] = group.name;
    });

    useEffect(() => {
        dispatch(userActions.getAllUsers());
    }, [userCreationError]);

    const createInviteLink = (userName: string) => {
        setInviteLinkUsername(userName);
        dispatch(userActions.createInviteLink(userName));
    };

    const onInviteLinkDialogClose = () => {
        setInviteLinkUsername('');
        dispatch(userActions.createInviteLinkReset());
    };

    const expirePassword = (userId: string) => dispatch(userActions.expirePassword(userId));
    const createResetPasswordLink = (userId: string) => dispatch(userActions.createResetPasswordLink(userId));

    const openCreateUser = () => dispatch(userActions.toggleCreateUser(true));

    const handleDeleteUserClick = (username: string) => {
        if (username === currentUsername) {
            return;
        }
        setUserToDelete(username);
    };

    const editUser = (user: IUserData) => dispatch(userActions.setUserToEdit(user));

    const onCancelDeletion = () => setUserToDelete('');

    const onConfirmDeletion = () => {
        dispatch(userActions.deleteUser(userToDelete));
        setUserToDelete('');
    };

    const getResetPasswordButton = (userId: string, isLocked?: boolean) => {
        const status = resetPasswordResultsByUserId[userId];
        let title = 'Create Reset Password Link';
        if (status === true) {
            title = 'Reset Password Link created';
        } else if (status === false) {
            title = 'Password reset failed. Try again.';
        } else if (isLocked) {
            title = 'Unlock and Reset Password';
        }

        return (
            <Tooltip title={title}>
                <ResetPasswordIcon onClick={status == null ? getHandler(createResetPasswordLink, userId) : undefined} />
            </Tooltip>
        );
    };

    const getExpirePasswordButton = (userId: string) => {
        const status = expirePasswordResultsByUserId[userId];
        const title = status ? 'Password Expired' : 'Force Change Password';
        return (
            <Tooltip title={title}>
                {status ? (
                    <CheckIcon className="action" />
                ) : (
                    <span className="action" onClick={getHandler(expirePassword, userId)}>
                        <ExpirePasswordIcon />
                    </span>
                )}
            </Tooltip>
        );
    };
    const onSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => setSearchQuery(event.target.value);
    const applySearch = ({ username, groupNames, tadigCodes }: IUserData) =>
        [username, ...groupNames, ...(tadigCodes || [])].join(' ').includes(searchQuery);

    const getHandler = <T extends any>(func: (arg: T) => void, id: T) => () => func(id);

    const getData = () =>
        users.filter(applySearch).map((user) => {
            const { id, username, groupNames, tadigCodes, isActive, isLocked } = user;
            const [dotClass, statusText] = !isActive ? ['inactive', 'Inactive'] : isLocked ? ['locked', 'Locked'] : ['active', 'Active'];
            return {
                ...user,
                groups: groupNames.join(', '),
                tadig: (tadigCodes || []).join(', '),
                statusText,
                status: (
                    <>
                        <span className={'dot ' + dotClass} />
                        {statusText}
                    </>
                ),
                actions: (
                    <span className="user-actions" data-t={username}>
                        {isActive ? (
                            <>
                                {getResetPasswordButton(id, isLocked)}
                                {getExpirePasswordButton(id)}
                            </>
                        ) : (
                            <Tooltip title="Generate Invite Link">
                                <MailIcon className="action" onClick={getHandler(createInviteLink, username)} />
                            </Tooltip>
                        )}
                        <Tooltip title="Delete User">
                            <DeleteIcon className="action" onClick={getHandler(handleDeleteUserClick, username)} />
                        </Tooltip>
                        <Tooltip title="Edit User">
                            <EditIcon className="action" onClick={getHandler(editUser, user)} />
                        </Tooltip>
                    </span>
                )
            };
        });

    const cols = columns.filter((col) => isRoamingNode || col.key !== 'tadig');

    return (
        <div className="user-creation">
            <div style={{ maxWidth: '100%' }}>
                <div className="user-table-header">
                    <Search className="user-search" placeholder="Search" onChange={onSearchChange} />
                    <button data-t="create-user-btn" className="btn btn-primary add-user" onClick={openCreateUser}>
                        <UserAddIcon /> New User
                    </button>
                </div>
                <Table cols={cols} data={getData()} noMoreOption noExport noHeader />
            </div>
            {!!userCreationError && <div className="error">{userCreationError}</div>}
            <InviteLinkDialog
                username={inviteLinkUsername}
                type={userInviteLinkData.type}
                hash={userInviteLinkData.link}
                expirationDate={userInviteLinkData.expirationDate}
                daysToExpire={userInviteLinkData.daysToExpire}
                onClose={onInviteLinkDialogClose}
            />

            {!!userToDelete && (
                <Modal title="Delete User" visible onCancel={onCancelDeletion} onOk={onConfirmDeletion}>
                    Are you sure you want to delete the user "{userToDelete}"?
                </Modal>
            )}
            {showCreateUser && <NewUserDialog />}
        </div>
    );
};
