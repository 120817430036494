import * as React from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { ConnectedRouter } from 'connected-react-router';
import { ServerErrorDialog } from '@components/Modal/ServerErrorDialog';
import { PrivateRoute } from '@containers/PrivateRoute';
import { Error404Component } from '@components/ErrorPages/error404';
import { DashboardContainer } from '@modules/Dashboard';
import { ExternalDisputeResolution, ExternalUploadContainer } from '@modules/Voice/containers';
import { ActivateUser } from '@components/UserManagement/ActivateUser';
import { DisputeDrawerContainer } from '@modules/Voice/components/DisputeDrawerContainer/DisputeDrawerContainer';
import { useSelector } from 'react-redux';
import { getDisputeId, getExternalDisputeId } from '@selectors/voiceDisputeDrawer';
import { ChangePassword } from '@components/UserManagement/ChangePassword';
import { AddPartners } from '@components/AddPartners/AddPartners';
import { SessionExpirationWarning } from '@components/SessionExpirationWarning/SessionExpirationWarning';
import { getAddPartnerDialogStatus, getSessionExpirationWarningDialogStatus } from '@selectors/ui';
import { ResetPassword } from '@components/UserManagement/ResetPassword';
import { AuthContainer } from '@modules/Auth';
import { AnonymousRoute } from '@containers/AnonymousRoute';
import { isAuthenticated } from '@selectors/auth';
import { Box } from '@material-ui/core';

interface IProps {
    history: any;
}

export const AppRoutes = (props: IProps): JSX.Element => {
    const disputeId = useSelector(getDisputeId);
    const externalDisputeId = useSelector(getExternalDisputeId);
    const isAddPartnerDialogOpen = useSelector(getAddPartnerDialogStatus);
    const isSessionExpirationWarningDialogOpen = useSelector(getSessionExpirationWarningDialogStatus);
    const isAuth = useSelector(isAuthenticated);
    return (
        <div className="page-full-height">
            <ConnectedRouter history={props.history}>
                <ServerErrorDialog />
                {disputeId || externalDisputeId ? <DisputeDrawerContainer /> : null}
                {isAddPartnerDialogOpen && <AddPartners />}
                {isSessionExpirationWarningDialogOpen && <SessionExpirationWarning />}
                <Switch>
                    <Route path="/external/invite/:link">
                        {({ match }) => {
                            const { link } = match!.params;
                            const [b64username, b64userId, hash] = link.split('|');
                            const username = atob(b64username);
                            const userId = atob(b64userId);
                            if (isAuth) {
                                return (
                                    <Box display={'flex'} justifyContent={'center'} alignItems={'center'} height={'100%'}>
                                        Another user is already logged in this browser. To activate a new user, please log out first or open the link
                                        in another browser
                                    </Box>
                                );
                            }
                            // @ts-ignore
                            return <ActivateUser username={username} userId={userId} hash={hash} />;
                        }}
                    </Route>
                    <Route path="/external/reset-password/:link">
                        {({ match }) => {
                            const { link } = match!.params;
                            const [b64username, b64userId, hash] = link.split('|');
                            const username = atob(b64username);
                            const userId = atob(b64userId);
                            return (
                                // @ts-ignore
                                <ResetPassword username={username} userId={userId} hash={hash} />
                            );
                        }}
                    </Route>
                    <AnonymousRoute path="/auth" component={AuthContainer} />
                    <PrivateRoute path="/dashboard" component={DashboardContainer} />
                    <Route path="/errors/404" component={Error404Component} />
                    <Route path="/external/upload/:hash" component={ExternalUploadContainer} />
                    <Route path="/external/offer/:hash" component={ExternalDisputeResolution} />
                    <Route path="/change-password" key={'1'}>
                        {({ match }) => {
                            return <ChangePassword />;
                        }}
                    </Route>
                    <Redirect to="/dashboard" />
                </Switch>
            </ConnectedRouter>
        </div>
    );
};
