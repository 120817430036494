export { email, fieldSize, required, requiredForArray } from './validation';

export const normaliseFilename = (str: string) => {
    if (!str) {
        return str;
    }
    return str.toLowerCase().replace(' ', '_');
};

export const stopPropagation = (e: Event) => {
    e.stopPropagation();
};

export const copyToClipboard = (str: string) => {
    const el = document.createElement('textarea');
    el.value = str;
    el.setAttribute('readonly', '');
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
};
