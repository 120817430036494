import * as React from 'react';
import classNames from 'classnames';
import { IStateNotification, IStateNotifications } from '@actions/notification';

export interface INotificationsListProps {
    closeNotificationToolTip: EventListenerOrEventListenerObject;
    notifications: IStateNotifications;
    handleActionableNotificationClick: (notification: IStateNotification) => void;
}

export class NotificationsList extends React.PureComponent<INotificationsListProps> {
    constructor(props: INotificationsListProps) {
        super(props);

        document.addEventListener('click', props.closeNotificationToolTip, false);
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.props.closeNotificationToolTip);
    }

    handleActionableClick = (notification: IStateNotification) => () => {
        this.props.handleActionableNotificationClick(notification);
    };

    render() {
        const { notifications } = this.props;
        return (
            <div data-t="notifications" className="notifications">
                <h4>Notifications</h4>
                <ul className="notifications-list">
                    {notifications.map(
                        (notification: IStateNotification): JSX.Element => {
                            const isNotificationActionable = notification.type;
                            return (
                                <li
                                    className={classNames(`notification`, {
                                        unread: !notification.read
                                    })}
                                    key={notification.id}
                                >
                                    <div className="message">{notification.content}</div>
                                    {isNotificationActionable && (
                                        <div className="action" onClick={this.handleActionableClick(notification)}>
                                            For details click here
                                        </div>
                                    )}
                                    <div className="term">{notification.timeToPresent}</div>
                                </li>
                            );
                        }
                    )}
                </ul>
            </div>
        );
    }
}
