import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import voiceActions from '@actions/voice';
import { getAccountsByPage, getAccounts } from '@selectors/voice';
import Helmet from 'react-helmet';
import { Sticky, SubHeader, Table, Pagination, Icon, FiltersPane } from '@components';
import { PageTitle } from '@modules/Voice/components';
import { NewSettlement } from '@modules/Voice/components/NewSettlement';
import { RouteChildrenProps } from 'react-router';
import { FilterItemType } from '@components/FiltersPane/FiltersPanel';
import get from 'lodash/get';
import './styles.scss';
import { NewAgreement } from '@modules/Voice/components/NewAgreement';
import { voicePaths, setPathId } from 'utils/routePaths';
import { AccountStatus, IAccount, IAccounts, IAccountByPage } from '@reducers/voice';
import { getDemoVoice } from '@selectors/app';
import { ITableColumnProps } from '@components/Table';

const tableCols: ITableColumnProps[] = [
    { title: 'Account', key: 'presentedName', notSortable: true },
    { title: 'Currency', key: 'currency', notSortable: true }
];

interface IFilters {
    name?: string[];
    status?: AccountStatus;
    [key: string]: any;
}

const ACCOUNT_FILTER = 'accountFilter';

export const PartnersView: React.FC<RouteChildrenProps> = (props) => {
    const accountsByPage: IAccountByPage | null = useSelector(getAccountsByPage);
    const allAccounts: IAccounts = useSelector(getAccounts);

    const [addNewOpen, changeAddNewOpen] = useState<boolean>(false);
    const defaultFilter = { status: AccountStatus.APPROVED };
    const [filters, setFilters] = useState<IFilters>(defaultFilter);
    const [isFiltersOpen, changeFiltersOpen] = useState(false);
    const [filtersState, setFiltersState] = useState();
    const [page, setPage] = useState<number>(1);

    const accountsFiltered = get(accountsByPage, 'items');
    const accountsFilteredPagination = get(accountsByPage, 'pagination');
    const isAgreements = location.pathname.includes('agreements');
    const noun = isAgreements ? 'Agreement' : 'Settlement';
    const demoVoice: boolean = useSelector(getDemoVoice);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(voiceActions.voiceQueryAccounts());
    }, []);

    useEffect(() => {
        dispatch(voiceActions.voiceQueryAccountsByPage(filters, null, page));
    }, [filters, page]);

    const openAddNew = () => changeAddNewOpen(true);
    const closeAddNew = () => changeAddNewOpen(false);

    const onSelectPage = (newPage: number) => setPage(newPage);

    const rowClick = ({ id }: { id: string }) => {
        const path = isAgreements ? voicePaths.agreementsList : voicePaths.settlementsList;
        props.history.push(setPathId(path, id));
    };

    const openFilters = () => changeFiltersOpen(true);
    const closeFilters = () => changeFiltersOpen(false);

    const onFilterApply = (newFilters: typeof filters = {}): void => {
        // when reset all filters apply
        if (newFilters && Object.keys(newFilters).length === 0) {
            return setFilters(defaultFilter);
        }
        const filtersToServer: IFilters = {};
        const chosenAccountsPresentedName: string[] = get(newFilters, ACCOUNT_FILTER);
        if (chosenAccountsPresentedName && chosenAccountsPresentedName.length > 0) {
            filtersToServer.name = chosenAccountsPresentedName.map(
                (presentedName) => (allAccounts.find((account) => account.presentedName === presentedName) as IAccount).name
            );
        }
        setFilters({ ...filters, ...filtersToServer });
        setPage(1);
    };

    const accountsNames = allAccounts && allAccounts.map((account: IAccount) => account.presentedName || account.name);

    return (
        <main className="voice-settlements partners-view">
            {accountsFiltered && accountsFilteredPagination && (
                <>
                    <Helmet title={`Clear Voice - ${noun}s`} />
                    <Sticky>
                        <SubHeader title="">
                            <PageTitle title={noun + 's'} />
                            {isAgreements && demoVoice ? <button className="btn btn-secondary">Import Agreement</button> : ''}
                            <button className="btn btn-primary" onClick={openAddNew}>
                                Add New {noun}
                            </button>
                        </SubHeader>
                    </Sticky>
                    {isAgreements ? (
                        <NewAgreement isOpen={addNewOpen} close={closeAddNew} />
                    ) : (
                        <NewSettlement isOpen={addNewOpen} closeDrawer={closeAddNew} />
                    )}
                    <div className="action-bar">
                        <div className="filters" onClick={openFilters}>
                            <Icon iconName="filtersGrey" />
                            Add Filters
                        </div>
                        <Pagination pagination={accountsFilteredPagination} onSelectPage={onSelectPage} />
                    </div>
                    <FiltersPane
                        onApply={onFilterApply}
                        isOpen={isFiltersOpen}
                        currency="USD"
                        close={closeFilters}
                        values={filtersState}
                        onChange={setFiltersState}
                        items={[
                            {
                                key: ACCOUNT_FILTER,
                                type: FilterItemType.SearchSelect,
                                title: 'Accounts',
                                options: accountsNames
                            }
                        ]}
                    />
                    <Table
                        handleRowWithValueClick={rowClick}
                        noMoreOption={true}
                        classes="voice-settlements-list"
                        cols={tableCols}
                        data={accountsFiltered}
                        noHeader={true}
                        notFrontendSortable={true}
                        noExport
                    />
                </>
            )}
        </main>
    );
};
