import { createSelector } from 'reselect';
import { RouterState } from 'connected-react-router';
import { Location } from 'history';
import { IRootState } from '@reducers/index';

export interface ILocationObject {
    pathname: string;
    search?: string;
    hash?: string;
    state?: {
        [key: string]: string;
    };
}

export const getRouterData = (state: IRootState): RouterState => {
    return state.router;
};

export const getLocationObject = createSelector(
    getRouterData,
    (router: RouterState): Location & { query: Record<string, string> } => router.location as any
);

export const getRouterParams = createSelector(getRouterData, (router: any): any => router.params);

export const getRouterPath = createSelector(getLocationObject, (location: Location): string => location.pathname);

export const getRouterQuery = createSelector(getLocationObject, (location: any): Record<string, string> => location.query);
