import React from 'react';
import { useSelector } from 'react-redux';
import { getLastLogin } from '@selectors/account';
import { Box } from '@material-ui/core';
import { T } from '@components/T/T';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { ExtendedTheme } from '@components/theme';
import { getApp } from '@selectors/app';
import { Separator } from '@components/Separator/Separator';

const useStyles = makeStyles((theme: ExtendedTheme) => {
    return {
        root: {
            padding: theme.spacing(2, 8),
            display: 'flex',
            minHeight: 40
        }
    };
});

export const Footer: React.FC = () => {
    const lastLogin = useSelector(getLastLogin);
    const { version } = useSelector(getApp);
    const classes = useStyles();
    return (
        <Box className={classes.root} clone>
            <footer>
                <T variant="caption">v{version}</T>
                <Separator />
                {!isNaN(+lastLogin) ? <T variant="caption">Last Login at {lastLogin.toLocaleString()}</T> : null}
            </footer>
        </Box>
    );
};
