import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getDisputeId } from '@selectors/voiceDisputeDrawer';
import { getDisputeOffers, decorateComparisonResult, decorateSettlement } from '@selectors/voice';
import { DisputeResolution } from '@modules/Voice/components';
import { DisputeOfferType, DisputeOfferStatus } from '@actions';
import voiceActions from '@actions/voice';
import voiceDisputeDrawerActions from '@actions/voiceDisputeDrawer';
import { getCurrencySymbol } from '@constants';

export const DisputeDrawerContainer = (props: any) => {
    const dispatch = useDispatch();
    const [dispute, setDisputeOffer] = useState();
    const disputeId = useSelector(getDisputeId);
    const offers = useSelector(getDisputeOffers);

    useEffect(() => {
        if (disputeId) {
            dispatch(voiceActions.voiceGetDisputeOffer(disputeId));
        }
    }, [disputeId]);

    useEffect(() => {
        if (disputeId && offers) {
            setDisputeOffer(offers[disputeId]);
        }
    }, [disputeId, offers]);

    const submitDisputeResolution = (comparisonItemFromDrawer: any, isSplit: boolean, amount?: number) => {
        if (dispute) {
            dispatch(
                voiceActions.voiceCreateDisputeOffer(
                    comparisonItemFromDrawer.invoiceComparisonId,
                    isSplit ? DisputeOfferType.FiftyFifty : DisputeOfferType.NewAmount,
                    DisputeOfferStatus.OfferSent,
                    { amount },
                    comparisonItemFromDrawer.currency,
                    dispute.settlementPeriodId
                )
            );
        }
    };
    const clearDisputeResolution = () => {
        dispatch(voiceDisputeDrawerActions.closeDisputeDrawer());
    };
    const offeredData = dispute && {
        ...dispute,
        accountCurrency: dispute.currency,
        lastDisputeOfferId: disputeId
    };
    const currencySymbol = offeredData && getCurrencySymbol(offeredData.accountCurrency);
    const comparisonItem = offeredData && decorateComparisonResult(offeredData, currencySymbol);
    const settlement = offeredData && decorateSettlement(offeredData);
    return settlement && comparisonItem ? (
        <DisputeResolution
            isRecipient={!dispute.isOwner}
            isSplit={dispute.offerType === DisputeOfferType.FiftyFifty}
            settlement={settlement}
            comparisonItem={comparisonItem}
            close={clearDisputeResolution}
            submit={submitDisputeResolution}
        />
    ) : null;
};
