import { balanceType, IStateBalance } from '@actions/balance';
import { IAppAction } from './state';
import moment from 'moment';
import mapValues from 'lodash/mapValues';

const initialState: IStateBalance = {
    hasLoaded: false,
    eusdBalance: '0',
    eur: '0',
    gbp: '0',
    jpy: '0',
    margin: '0',
    percent: '0',
    purchased: '0',
    rmb: '0',
    usdBalance: '0',
    usd: '0',
    withdrawal: '0',
    withdrawn: '0',
    accounts: '0',
    totalEusdBalance: '0',
    updatedTime: '',
    payableInvoicesTotal: '0',
    receivableInvoicesTotal: '0'
};

const updateBalance = (state: IStateBalance, balanceData: any) => {
    return {
        ...state,
        ...mapValues(balanceData, (item) => item || '0'),
        updatedTime: moment().format('MM DD, YYYY - HH:mma') // TODO reducers must be pure function!
    };
};

export function balanceReducer(state = initialState, action: IAppAction): IStateBalance {
    switch (action.type) {
        case balanceType.BALANCE_GET_REQUEST:
            return { ...state, hasLoaded: false };
        case balanceType.BALANCE_GET_REQUEST_SUCCESS:
            const balanceData = action.payload && action.payload[0];
            return updateBalance(state, balanceData);
        case balanceType.BALANCE_UPDATE_RECEIVED:
            return updateBalance(state, action.payload.objs[0]);
        case balanceType.BALANCE_GET_REQUEST_FINISHED:
            return { ...state, hasLoaded: true };
        default:
            return state;
    }
}
