import * as React from 'react';
import classNames from 'classnames';
import './IconStyles.scss';

interface IIconProps {
    iconName: string;
    title?: string;
    className?: string;
}

export const Icon = ({ iconName, title, className }: IIconProps): JSX.Element => (
    <img
        src={`/assets/svg/${iconName}.svg`}
        alt={Boolean(title) ? title : iconName}
        title={Boolean(title) ? title : iconName}
        className={classNames('icon', `icon-${iconName}`, className)}
    />
);
