import { createAction } from '@helpers/actionCreator';

export enum createRSType {
    UPLOAD_RS_REQUEST = 'UPLOAD:RS::REST:POST:REQUEST',
    UPLOAD_RS_SUCCESS = 'UPLOAD:RS::REST:POST:REQUEST:SUCCESS',
    UPLOAD_RS_FAIL = 'UPLOAD:RS::REST:POST:REQUEST:FAIL',
    UPLOAD_RS_FINISHED = 'UPLOAD:RS::REST:POST:REQUEST:FINISHED'
}

interface ICreateRSParams {
    supplier: string;
    filename: string;
    file: File;
    url: string;
}

const createRSActions = {
    postNewRS: createAction(createRSType.UPLOAD_RS_REQUEST, {
        payload: (params: ICreateRSParams) => ({
            ...params
        })
    })
};

export default createRSActions;
