import { IAppAction } from './state';
import { IManageUserState, InviteLinkType, IUserData, userActionTypes } from '@actions/users';
import { authType } from '@actions/auth';
import { requestFailType, requestSuccessType } from '@helpers/actionCreator';
import get from 'lodash/get';

const initialState: IManageUserState = {
    createUser: {
        id: '',
        qrCode: ''
    },
    magicLinkData: {},
    activateUser: {
        isLoading: false,
        isSuccess: false,
        errorMessage: '',
        qrCode: ''
    },
    deleteUser: {
        isSuccess: false
    },
    inviteLink: {
        type: InviteLinkType.Invite,
        link: '',
        expirationDate: '',
        daysToExpire: 0
    },
    resetPasswordResultsByUserId: {},
    expirePasswordResultsByUserId: {},
    changePassword: {
        isLoading: false,
        isSuccess: false,
        errorMessage: '',
        qrCode: ''
    },
    users: [],
    policies: [],
    groups: [],
    isLoading: false,
    error: undefined,
    showCreateUser: false,
    lastRequestToLoginUsername: ''
};

export function userReducer(state = initialState, action: IAppAction): IManageUserState {
    const { type, payload } = action;
    switch (type) {
        case authType.RESET_PASSWORD:
        case authType.ACTIVATE_USER: {
            return {
                ...state,
                activateUser: {
                    ...state.activateUser,
                    isLoading: true
                }
            };
        }
        case authType.LOGIN_REQUEST:
            return {
                ...state,
                lastRequestToLoginUsername: get(action, 'payload.values[0]')
            };
        case authType.RESET_PASSWORD_SUCCESS:
        case authType.ACTIVATE_USER_SUCCESS: {
            return {
                ...state,
                activateUser: {
                    ...state.activateUser,
                    ...action.payload,
                    isSuccess: true,
                    errorMessage: undefined,
                    isLoading: false
                }
            };
        }

        case authType.RESET_PASSWORD_FAIL:
        case authType.ACTIVATE_USER_FAIL: {
            const { payload: errorMessage } = action;
            return {
                ...state,
                activateUser: {
                    ...state.activateUser,
                    errorMessage,
                    isLoading: false
                }
            };
        }
        case authType.CHANGE_USER_PASSWORD: {
            return {
                ...state,
                changePassword: {
                    ...state.changePassword,
                    isLoading: true
                }
            };
        }
        case authType.CHANGE_USER_PASSWORD_SUCCESS: {
            return {
                ...state,
                changePassword: {
                    ...state.changePassword,
                    ...action.payload,
                    isSuccess: true,
                    errorMessage: undefined,
                    isLoading: false
                }
            };
        }
        case authType.CHANGE_USER_PASSWORD_FAIL: {
            const { payload: errorMessage } = action;
            return {
                ...state,
                changePassword: {
                    ...state.changePassword,
                    errorMessage,
                    isLoading: false
                }
            };
        }
        case requestSuccessType(authType.CREATE_USER): {
            const { id, qrCode } = action.payload;
            const newUser = action.payload;
            const modifiedUsers = state.users.concat(newUser);
            return {
                ...state,
                createUser: { id, qrCode },
                isLoading: false,
                error: undefined,
                users: modifiedUsers
            };
        }

        case userActionTypes.DELETE_USER: {
            return { ...state, isLoading: true, error: undefined };
        }
        case userActionTypes.DELETE_USER_SUCCESS: {
            const deletedUser =
                action.requestAction.payload.values && action.requestAction.payload.values.length > 0 && action.requestAction.payload.values[0];

            return {
                ...state,
                deleteUser: { isSuccess: true },
                users: state.users.filter((user) => user.username !== deletedUser),
                isLoading: false,
                error: undefined
            };
        }
        case userActionTypes.DELETE_USER_FAIL: {
            const { payload: error } = action;
            return { ...state, isLoading: false, error };
        }
        case userActionTypes.CREATE_INVITE_LINK: {
            return {
                ...state,
                isLoading: true,
                error: undefined
            };
        }
        case userActionTypes.CREATE_RESET_PASSWORD_LINK_SUCCESS: {
            const [id] = action.requestAction.payload.values;
            const resetPasswordResultsByUserId = {
                ...state.resetPasswordResultsByUserId,
                [id]: true
            };
            const { ttl, uri } = action.payload;
            const expirationDate = new Date(ttl);
            const today = Date.now();
            const diffTime = Math.abs(+expirationDate - today);
            const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
            return {
                ...state,
                resetPasswordResultsByUserId,
                inviteLink: {
                    type: InviteLinkType.ResetPassword,
                    link: uri,
                    expirationDate: expirationDate.toDateString(),
                    daysToExpire: diffDays
                },
                isLoading: false,
                error: undefined
            };
        }

        case userActionTypes.CREATE_INVITE_LINK_SUCCESS: {
            const expirationDate = new Date(action.payload.expirationDate);
            const today = new Date();
            const diffTime = Math.abs((expirationDate as any) - (today as any));
            const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
            return {
                ...state,
                inviteLink: {
                    type: InviteLinkType.Invite,
                    link: action.payload.link,
                    expirationDate: expirationDate.toDateString(),
                    daysToExpire: diffDays
                },
                isLoading: false,
                error: undefined
            };
        }
        case userActionTypes.CREATE_RESET_PASSWORD_LINK_FAIL:
        case userActionTypes.CREATE_INVITE_LINK_FAIL: {
            const { payload: error } = action;
            return { ...state, isLoading: false, error };
        }

        case userActionTypes.EXPIRE_PASSWORD_SUCCESS: {
            const [id] = action.requestAction.payload.values;
            const expirePasswordResultsByUserId = {
                ...state.expirePasswordResultsByUserId,
                [id]: true
            };
            return {
                ...state,
                expirePasswordResultsByUserId,
                isLoading: false,
                error: undefined
            };
        }
        case requestSuccessType(userActionTypes.UPDATE_USER): {
            const updatedUser = payload as IUserData;
            return {
                ...state,
                users: state.users.map((user) => (user.id === updatedUser.id ? updatedUser : user))
            };
        }
        case requestFailType(userActionTypes.UPDATE_USER): {
            const { payload: error } = action;
            return { ...state, isLoading: false, error };
        }
        case userActionTypes.GET_POLICIES: {
            return { ...state, isLoading: true, error: undefined };
        }
        case userActionTypes.GET_POLICIES_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                error: undefined,
                policies: action.payload
            };
        }
        case userActionTypes.GET_POLICIES_FAIL: {
            const { payload: error } = action;
            return { ...state, isLoading: false, error };
        }
        case userActionTypes.GET_GROUPS_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                error: undefined,
                groups: action.payload
            };
        }

        case userActionTypes.GET_ALL_USERS: {
            return { ...state, isLoading: true, error: undefined };
        }
        case userActionTypes.GET_ALL_USERS_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                error: undefined,
                users: action.payload
            };
        }
        case userActionTypes.GET_ALL_USERS_FAIL: {
            const { payload: error } = action;
            return { ...state, isLoading: false, error };
        }
        case userActionTypes.CREATE_INVITE_LINK_RESET: {
            return {
                ...state,
                inviteLink: { link: '', expirationDate: '', type: InviteLinkType.Invite, daysToExpire: 0 },
                error: undefined
            };
        }
        case userActionTypes.TOGGLE_CREATE_USER:
            return { ...state, showCreateUser: payload as boolean };
        case userActionTypes.SET_USER_TO_EDIT:
            return {
                ...state,
                showCreateUser: !!payload,
                userToEdit: payload as IUserData | undefined
            };
        case userActionTypes.GET_MAGIC_LINK_DATA_SUCCESS:
            return { ...state, magicLinkData: payload };
        default:
            return state;
    }
}
