import * as React from 'react';

import { Icon } from '@components/Icon';

import './styles.scss';

export interface IProps {
    somevalue?: any;
}

export class Brand extends React.Component<IProps> {
    static defaultProps: Partial<IProps> = {};

    render() {
        return (
            <section className="brand">
                <Icon iconName="logo" />
                <div className="slogan">Let's Make it Clear</div>
            </section>
        );
    }
}
