import { createSelector } from 'reselect';
import { IRootState } from '@reducers';
import { IStateFilters } from '@reducers/queryFilters';

const queryFiltersSelector = (state: IRootState): IStateFilters => state.queryFilters;

export const getFilters = createSelector(queryFiltersSelector, ({ filtersForServer }: IStateFilters) => filtersForServer);

export const getPresentationFilters = createSelector(queryFiltersSelector, ({ filtersForPresentation }: IStateFilters) => filtersForPresentation);

export const getFiltersPanelStatus = createSelector(queryFiltersSelector, ({ isFiltersPanelOpen }: IStateFilters) => isFiltersPanelOpen);
