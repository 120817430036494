import React, { PropsWithChildren } from 'react';
import { ITProps, T } from '@components/T/T';

export const MultiText: React.FC<PropsWithChildren<{ separator?: string } & ITProps>> = ({ children = [], separator = '', ...props }) => {
    const childCount = React.Children.count(children);
    return (
        <>
            {React.Children.map(children as any, (child: React.ReactElement, i: number) => {
                const hasSeparator = separator && i < childCount - 1;
                return (
                    <T key={i} {...props}>
                        {child}
                        {hasSeparator && separator}
                    </T>
                );
            })}
        </>
    );
};
