import { appType, IStateApp } from '@actions/app';
import { IAppAction } from './state';

export enum NetworkStatus {
    Uninitialized = 'UNINITIALIZED',
    BrowserOffline = 'BROWSER_OFFLINE',
    Disconnected = 'DISCONNECTED',
    Connected = 'CONNECTED'
}

const initialState: IStateApp = {
    version: '',
    demoVoice: false,
    backendWsUrl: '',
    backendRestUrl: '',
    mode: 'development',
    isLocal: false,
    isMfaEnabled: false,
    isPasswordPolicyEnforced: false,
    networkStatus: NetworkStatus.Uninitialized
};

export function appReducer(state = initialState, action: IAppAction): IStateApp {
    switch (action.type) {
        case appType.APP_CONFIGURATION_STORE: {
            const { VERSION, DEMO_VOICE, WS_BACKEND_URL, REST_BACKEND_URL, NODE_ENV, IS_LOCAL, ENABLE_MFA, ENFORCE_PASSWORD_POLICY } = action.payload;
            return {
                ...state,
                version: VERSION,
                demoVoice: DEMO_VOICE,
                mode: NODE_ENV,
                backendWsUrl: WS_BACKEND_URL,
                backendRestUrl: REST_BACKEND_URL,
                isLocal: IS_LOCAL,
                isMfaEnabled: ENABLE_MFA,
                isPasswordPolicyEnforced: ENFORCE_PASSWORD_POLICY
            };
        }
        case appType.SET_NETWORK_STATUS: {
            return {
                ...state,
                networkStatus: action.payload
            };
        }
        default:
            return { ...state };
    }
}
