import { IAppAction } from '@reducers/state';
import { queryFiltersType } from '@actions';

export interface IStateFilters {
    filtersForPresentation: Record<string, object> | {};
    filtersForServer: Record<string, object> | {};
    isFiltersPanelOpen: boolean;
}

const initialState: IStateFilters = {
    filtersForPresentation: {},
    filtersForServer: {},
    isFiltersPanelOpen: false
};

export function queryFiltersReducer(state: IStateFilters = initialState, action: IAppAction): IStateFilters {
    switch (action.type) {
        case queryFiltersType.CLEAR_FILTER: {
            const { filtersForPresentation, filtersForServer } = state;
            const filterKey = action.payload;
            const filtersForPresentationCopy = { ...filtersForPresentation };
            const filtersForServerCopy = { ...filtersForServer };
            // @ts-ignore
            delete filtersForPresentationCopy[filterKey];
            // @ts-ignore
            delete filtersForServerCopy[filterKey];

            return {
                ...state,
                filtersForPresentation: filtersForPresentationCopy,
                filtersForServer: filtersForServerCopy
            };
        }
        case queryFiltersType.CLEAR_ALL_FILTERS: {
            return {
                ...state,
                filtersForPresentation: {},
                filtersForServer: {}
            };
        }
        case queryFiltersType.CLOSE_FILTERS_PANEL: {
            return {
                ...state,
                isFiltersPanelOpen: false
            };
        }
        case queryFiltersType.OPEN_FILTERS_PANEL: {
            return {
                ...state,
                isFiltersPanelOpen: true
            };
        }
        case queryFiltersType.SET_FILTERS: {
            const { filtersForPresentation, filtersForServer } = action.payload;
            const newFiltersForPresentation = {
                ...state.filtersForPresentation,
                ...filtersForPresentation
            };
            const newFiltersForServer = {
                ...state.filtersForServer,
                ...filtersForServer
            };
            const {} = action.payload;
            return {
                ...state,
                filtersForPresentation: newFiltersForPresentation,
                filtersForServer: newFiltersForServer
            };
        }
        default:
            return state;
    }
}
