import React from 'react';
import { CardProps } from 'antd/es/card';
import { Card } from 'antd';

interface Props extends CardProps {
    flat?: boolean;
}

export const ClearCard: React.FC<Props> = React.forwardRef(({ flat, style, ...props }) => {
    return <Card style={{ ...style, boxShadow: flat ? 'none' : undefined }} {...props} />;
});

ClearCard.defaultProps = {
    flat: false
};
