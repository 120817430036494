import { createSelector } from 'reselect';
import { getCurrentInfo } from '@selectors/roaming';

export const getContractPeerName = createSelector(getCurrentInfo, ({ contract, proposal }) => {
    return contract.myName || proposal.myName || '';
});

export const getContracts = createSelector(getCurrentInfo, ({ contract }) => {
    return { ...contract.config };
});
