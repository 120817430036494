import React from 'react';
import { useAction } from '../../../../hooks/useAction';
import transactionsActions from '@actions/agreements';
import { createIsOperationAllowedSelector, OperationType, ResourceType } from '@selectors/account';
import { useSelector } from 'react-redux';
import { IconLabel } from '@components/IconLabel/IconLabel';
import Plus from '@ant-design/icons/PlusOutlined';

export const CreateContractButton: React.FC<{ className?: string }> = ({ className }) => {
    const isAllowedToCreateAgreement = useSelector(
        createIsOperationAllowedSelector({
            op: OperationType.Create,
            resource: ResourceType.Contract
        })
    );
    const toggleShowCreateContract = useAction(() => transactionsActions.toggleShowCreateContract(true));
    if (!isAllowedToCreateAgreement) {
        return null;
    }
    return (
        <a data-t="create-contract" onClick={toggleShowCreateContract} className={className}>
            <IconLabel iconMR={2} icon={<Plus />}>
                New Contract
            </IconLabel>
        </a>
    );
};
