import React from 'react';

import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import MaskedInput from 'react-text-mask';
import { SelectField } from '@components';

import './styles.scss';
import { formatBalance } from '@helpers/formaters';

export enum OperationType {
    Between = 'range',
    LessThan = 'lt',
    GreaterThan = 'gt'
}

export interface INumericFilterValue {
    type: OperationType;
    value1?: number;
    value2?: number;
    typed1?: string;
    typed2?: string;
}
interface IProps {
    currencySymbol: string;
    value: INumericFilterValue;
    onChange(value: INumericFilterValue): void;
}

export const NumberInput = ({ currencySymbol, value, onChange }: IProps) => {
    const operations = [
        {
            name: (
                <span className="numeric-option">
                    Greater Than<span>&gt;&nbsp;</span>
                </span>
            ),
            value: OperationType.GreaterThan
        },
        {
            name: (
                <span className="numeric-option">
                    Less Than<span>&lt;&nbsp;</span>
                </span>
            ),
            value: OperationType.LessThan
        },
        {
            name: (
                <span className="numeric-option">
                    Between<span>&lt;&gt;</span>
                </span>
            ),
            value: OperationType.Between
        }
    ];

    const amountMask = createNumberMask({
        prefix: currencySymbol,
        allowNegative: true,
        allowDecimal: true
    });
    const onOperationChanged = (type: OperationType) => onChange({ ...value, type });
    const parseNumber = (str: string) => parseFloat(str.replace(/[^-0-9\.]/g, ''));
    const onValue1Change = (event: React.ChangeEvent<HTMLInputElement>) => {
        const inputValue = event.target.value;
        onChange({
            type: operation,
            ...value,
            typed1: inputValue,
            value1: parseNumber(inputValue)
        });
    };
    const onValue2Change = (event: React.ChangeEvent<HTMLInputElement>) => {
        const inputValue = event.target.value;
        onChange({
            ...value,
            typed2: inputValue,
            value2: parseNumber(inputValue)
        });
    };
    const operation = value.type || OperationType.GreaterThan;
    const showing2 = operation === OperationType.Between;
    const placeholder = `${currencySymbol} 0`;
    return (
        <div className={'filter-numeric-input ' + (showing2 ? 'double' : 'single')}>
            <SelectField
                classList="voice operation-select"
                input={{ onChange: onOperationChanged, value: operation }}
                options={operations}
                meta={''}
            />
            <span className="inputs">
                <MaskedInput name="value1" value={value.typed1} mask={amountMask} placeholder={placeholder} onChange={onValue1Change} />
                {showing2 && <MaskedInput name="value2" value={value.typed2} mask={amountMask} placeholder={placeholder} onChange={onValue2Change} />}
            </span>
        </div>
    );
};
NumberInput.reverseEngineerValue = (value: INumericFilterValue, currencySymbol?: string) => {
    const result = { ...value };
    const { value1, value2 } = value;
    if (value1 === undefined) {
        result.typed1 = formatBalance(value1, 2, { currency: currencySymbol, roundToInteger: true });
    }
    if (value2 === undefined) {
        result.typed2 = formatBalance(value2, 2, { currency: currencySymbol, roundToInteger: true });
    }
    return result;
};
