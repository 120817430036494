import * as React from 'react';
import * as ReactDOM from 'react-dom';

import './styles.scss';

interface IStickyProps {
    children: React.ReactNode;
}

interface IStickyState {
    scroll: number;
    top: number;
    height: number;
}

export class Sticky extends React.Component<IStickyProps, IStickyState> {
    state = {
        scroll: 0,
        top: 0,
        height: 0
    };

    handleScroll = () => {
        this.setState({ scroll: window.scrollY });
    };

    componentDidMount() {
        this.setState({
            top: (ReactDOM.findDOMNode(this) as HTMLElement).offsetTop,
            height: (ReactDOM.findDOMNode(this) as HTMLElement).offsetHeight
        });
        window.addEventListener('scroll', this.handleScroll);
    }

    componentDidUpdate() {
        this.state.scroll > this.state.top ? (document.body.style.paddingTop = `${this.state.height}px`) : (document.body.style.paddingTop = '0px');
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    render() {
        return <div className={this.state.scroll > this.state.top ? 'fixed-nav' : ''}>{this.props.children}</div>;
    }
}
