import { createSelector } from 'reselect';
import find from 'lodash/find';
import { formatBalance } from '@helpers/formaters';
import { getCurrencySymbol } from '@constants';
import { IAgreementsReducer, IError, IPartnerships, IUploadUsageDataResult } from '@reducers/agreements';
import { IRootState } from '@reducers';
import forEach from 'lodash/forEach';
import cloneDeep from 'lodash/cloneDeep';
import { peerSelector } from '../peers';
import { IPeer } from '@actions';
import { getMyPeerId } from '../account';
import { createMatchSelector } from 'connected-react-router';
import { ROAMING_AGREEMENT_PATH } from '@helpers/roamingAgreementPath';
import { ISwapAgreement } from '@reducers/voice';
import { keyBy } from 'lodash';
import { Currency } from '@helpers/currency';
import { SettlementStatus } from '@clearblockchain/shared/contract';

export enum SettlementApprovalStatus {
    Unset = 'unset',
    Approved = 'approved',
    Rejected = 'rejected'
}

export interface ISubcontract {
    name: string;
    myTadigCodes: string[];
    theirTadigCodes: string[];
}

export enum DocType {
    PROPOSAL = 'proposal',
    CONTRACT = 'contract'
}

export interface IBillingStatementBalanceForUsage {
    myName: string;
    counterName: string;
    totalOutbound: string;
    totalInbound: string;
    myNetPosition: string;
    isMyNetReceivable: boolean;
    counterNetPosition: string;
    isCounterNetReceivable: boolean;
    netDifference: string;
    postSplit: string;
    isPostSplitReceivable: boolean;
}

export interface IAgreementsReducerWithProps {
    agreements: IAgreementsReducer;
    props: any;
}

export interface ICurrentDocumentInfo {
    reports: any;
    contract: any;
    terms?: any;
    hashResponse: any;
    querySubmitted: any;
    proposal: any;
    partiesKey: { me: string; counter: string };
    partiesIndex: { me: number; counter: number };
    isMyContract: boolean;
    myName: string;
    counterName: string;
    uploadUsageResult: IUploadUsageDataResult | null;
    newEditedSuggestedCommercialTerms?: any;
    isPeerDeleted: boolean;
    peerDeletedDate: number | null;
}

export const getAgreementParamsFromUrl = createSelector(
    (state: IRootState) => createMatchSelector<IRootState, { docType: DocType; id: string }>(ROAMING_AGREEMENT_PATH)(state),
    (match) => (match ? match.params : { docType: DocType.PROPOSAL, id: '' })
);

export const agreementsSelector = (state: IRootState) => state.agreements;

export const getPartnerships = createSelector(agreementsSelector, (agreements: IAgreementsReducer): IPartnerships => agreements.partnerships);

export const getLastStateOfUpdatedContract = (state: IRootState) => state.agreements.contractStateBeforeLastUpdate;

export const agreementsSelectorWithProps = (state: IRootState, props: any): IAgreementsReducerWithProps => ({
    agreements: state.agreements,
    props
});

export const getAgreements = createSelector(agreementsSelector, (agreements: IAgreementsReducer): IAgreementsReducer['items'] => agreements.items);

const selectContractTableIds = createSelector(agreementsSelector, (agreements: IAgreementsReducer) => agreements.contractTableIds);

export const getAgreementsFileSendingError = createSelector(
    agreementsSelector,
    (agreements: IAgreementsReducer): IError | null => agreements.fileSendingError
);

export const getCurrentAgreement = createSelector(getAgreementParamsFromUrl, getAgreements, ({ id }, items) => {
    return items.find((item) => item.id === id);
});

const findOtherPeerAgreement = (peers: IPeer[], myPeerId: string, agreement: any): IPeer | null => {
    let otherPeer;
    if (agreement) {
        otherPeer = peers.find((peer) => {
            if (agreement.counterpartyPeerId) {
                return peer.id === agreement.counterpartyPeerId;
            }
            // relevant for BC for old proposals which does not have counterPartyPeerId
            const counterPeerId =
                myPeerId !== agreement?.settlementContractConfig?.initiatorPeerId
                    ? agreement?.settlementContractConfig?.initiatorPeerId
                    : agreement.id.split('-PROPOSAL')[0];
            return peer.id === counterPeerId;
        });
    }
    return otherPeer || null;
};

const getIsPeerDeleted = (agreement: ISwapAgreement, myPeerId: string, peers: IPeer[], otherPeerAgreement: IPeer | null): boolean => {
    const isContractPeerNotInNetwork = agreement && myPeerId && peers && !otherPeerAgreement;
    const isContractPeerInNetworkAndDeleted = Boolean(agreement && otherPeerAgreement && otherPeerAgreement.isDeleted);
    return isContractPeerNotInNetwork || isContractPeerInNetworkAndDeleted;
};

export const isLoadingAgreements = createSelector(agreementsSelector, ({ isLoading }) => isLoading);

export const getIsContractSettling = createSelector(agreementsSelector, ({ isContractSettling }) => isContractSettling);

export const getTotalCount = createSelector(agreementsSelector, (agreements) => agreements.total);

export const convertAgreementDto = (agreement: any, peers: IPeer[]) => {
    const config = agreement.config || agreement.settlementContractConfig;
    const isContract = !!agreement.config;
    const peerId = agreement.counterpartyPeerId === config.initiatorPeerId ? config.initiatorPeerId : agreement.counterpartyPeerId;
    const peer = peers.find((p) => p.id === peerId);
    const isActive = peer && !peer.isDeleted;
    const peerDeletedDate = isActive ? undefined : peer && peer.updatedAt;
    const partner = peer
        ? peer.name
        : agreement.counterpartyPeerId === config.initiatorPeerId
        ? config.initiatorPeerName
        : config.counterpartPeerName;
    const id = agreement.id;
    const shouldLeadToSettlementTab = isContract && agreement?.settlementStatus === SettlementStatus.SETTLED ? true : false;

    return {
        ...config,
        id,
        status: agreement.status,
        name: config.name,
        active: isActive,
        partner,
        settlementStart: config.settlementStart,
        settlementEnd: config.settlementEnd,
        net: agreement.netFeesToDate ? new Currency(Math.abs(agreement.netFeesToDate), config.currency).localString : 'N/A',
        settlementStatus: agreement.settlementStatus,
        createdAt: agreement.createdAt,
        updatedAt: agreement.updatedAt,
        link: `/dashboard/roaming/${isContract ? 'contracts' : 'proposal'}/${id}${shouldLeadToSettlementTab ? '/settlement' : ''}`,
        peerDeletedDate
    };
};

export const selectContractTableItems = createSelector(getAgreements, selectContractTableIds, peerSelector, (items, ids, { peers }) => {
    const contractsById = keyBy(items, 'id');
    return ids.map((id) => {
        const contract = contractsById[id];
        if (contract && Object.keys(contract).length > 0) {
            return convertAgreementDto(contract, peers);
        }
        return {};
    });
});

export const getLastPeriodIndex = (contract: any) => {
    const contractMonthsDuration = contract.config.commercialTerms.details.term;
    return Number(contractMonthsDuration);
};

export const getCurrentInfo = createSelector(
    getAgreementParamsFromUrl,
    agreementsSelector,
    getMyPeerId,
    peerSelector,
    (agreementParams, agreementsState, myPeerId, { peers }): ICurrentDocumentInfo => {
        const { hashResponse, querySubmitted, reports, uploadUsageResult, items } = agreementsState;
        const { id, docType } = agreementParams as any;

        const agreement = find(items, { id });

        const otherPeerAgreement = findOtherPeerAgreement(peers, myPeerId, agreement);

        const isPeerDeleted = getIsPeerDeleted(agreement, myPeerId, peers, otherPeerAgreement);

        let contract = docType === DocType.PROPOSAL ? null : agreement;

        const proposal = docType === DocType.PROPOSAL && agreement ? agreement : {};

        if (proposal?.contractId) {
            const contractOfCurrentProposal = find(items, { id: proposal.contractId });
            if (contractOfCurrentProposal) {
                contract = contractOfCurrentProposal;
            }
        }
        const originalTerms = (contract ? contract.config : proposal.settlementContractConfig) || {};

        const terms = cloneDeep(originalTerms);
        const isMyContract = myPeerId === terms.initiatorPeerId;

        const partiesKey = isMyContract ? { me: 'p1', counter: 'p2' } : { me: 'p2', counter: 'p1' };
        const partiesIndex = isMyContract ? { me: 0, counter: 1 } : { me: 1, counter: 0 };

        let myName = '';
        let counterName = '';

        if (terms && terms.commercialTerms) {
            myName = terms.commercialTerms.details[`${partiesKey.me}Name`];
            counterName = terms.commercialTerms.details[`${partiesKey.counter}Name`];
            terms.logSubmitted = contract && contract.logSubmitted;
            const editedCommercialTerms = appendDataToCommercialTerms(terms.commercialTerms, partiesKey, terms.currency);
            terms.commercialTerms = editedCommercialTerms;
            if (contract && terms.logSubmitted) {
                terms.logSubmitted.me = terms.logSubmitted[partiesIndex.me];
                terms.logSubmitted.counter = terms.logSubmitted[partiesIndex.counter];
            }
        }
        let newEditedSuggestedCommercialTerms = null;
        if (contract && contract.newSuggestedCommercialTerms) {
            newEditedSuggestedCommercialTerms = appendDataToCommercialTerms(contract.newSuggestedCommercialTerms, partiesKey, terms.currency);
        }

        return {
            reports,
            hashResponse,
            contract: contract || {},
            proposal: proposal || {},
            querySubmitted,
            terms,
            partiesKey,
            partiesIndex,
            isMyContract,
            myName,
            counterName,
            uploadUsageResult,
            newEditedSuggestedCommercialTerms,
            isPeerDeleted,
            peerDeletedDate: otherPeerAgreement && otherPeerAgreement.isDeleted ? otherPeerAgreement.updatedAt : null
        };
    }
);

export const selectCurrentSubcontracts = createSelector(getCurrentInfo, ({ contract }): ISubcontract[] => {
    const myPartyIndex = contract.myPartyIndex;
    const discountLetters = contract?.config?.commercialTerms.parties ?? [];

    const [myPartyKey, theirPartyKey] = myPartyIndex === 0 ? ['p1', 'p2'] : ['p2', 'p1'];

    return discountLetters.map(
        (discountLetter: any): ISubcontract => {
            return {
                myTadigCodes: discountLetter[myPartyKey].tadigCodes.split(' '),
                theirTadigCodes: discountLetter[theirPartyKey].tadigCodes.split(' '),
                name: discountLetter.name
            };
        }
    );
});

interface ISinglePartySubContract {
    name: string;
    services: any;
    tadigCodes: string;
    totalCommitmentAmount: string;
    hasTotalCommitment: boolean;
    hasCdlCommitment: boolean;
    alsoContractParties: boolean;
    externalDiscountedCharges: string;
    formattedTotalCommitmentAmount: string;
}

export interface ISubContractRoaming {
    counter: ISinglePartySubContract;
    me: ISinglePartySubContract;
    p1: ISinglePartySubContract;
    p2: ISinglePartySubContract;
    name: string;
    commercialLaunchDate: number | null;
}

export interface IDiscrepancy {
    date: string;
    diff: number;
    hpmn: string;
    id: string;
    monthIndex: number;
    year: number;
    vpmn: string;
    p1Charges: string;
    p2Charges: string;
    p1Usage: string;
    p2Usage: string;
    productName: string;
    subcontract: { name: string; tadigCodes: string[] };
}

const appendDataToCommercialTerms = (commercialTerms: any, partiesKey: { me: string; counter: string }, currency: string): any => {
    if (!commercialTerms.parties) {
        return commercialTerms;
    }
    const clonedCommercialTerms = cloneDeep(commercialTerms);
    for (const subContract of clonedCommercialTerms.parties) {
        subContract.me = subContract[partiesKey.me];
        subContract.counter = subContract[partiesKey.counter];
    }
    clonedCommercialTerms.details.formattedP1CdlCommitmentAmount = clonedCommercialTerms.details.p1CdlCommitmentAmount
        ? `${getCurrencySymbol(currency)} ${formatBalance(clonedCommercialTerms.details.p1CdlCommitmentAmount, 2)}`
        : null;
    clonedCommercialTerms.details.formattedP2CdlCommitmentAmount = clonedCommercialTerms.details.p2CdlCommitmentAmount
        ? `${getCurrencySymbol(currency)} ${formatBalance(clonedCommercialTerms.details.p2CdlCommitmentAmount, 2)}`
        : null;

    forEach(clonedCommercialTerms.parties, (subContract) => {
        forEach([subContract.p1, subContract.p2], (party) => {
            party.hasTotalCommitment = party.totalCommitmentAmount > 0;
            party.formattedTotalCommitmentAmount = `${getCurrencySymbol(currency)} ${formatBalance(party.totalCommitmentAmount, 2)}`;
        });
    });
    return clonedCommercialTerms;
};
