import * as React from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';
import { createStructuredSelector } from 'reselect';
import get from 'lodash/get';
import communicationActions from '@actions/communication';
import notificationActions, { IStateNotification, NOTIFICATION_TYPE, notificationType } from '@actions/notification';
import voiceDisputeDrawerActions from '@actions/voiceDisputeDrawer';
import { Notification } from '@components/Notification/index';
import { getNotifications } from '@selectors/notifications';
import { IRootState } from '@reducers/index';
import { history } from 'main';
import { setPathId, voicePaths } from 'utils/routePaths';
import { ComparisonType } from '@actions';
import uiActions from '@actions/ui';

@connect(
    createStructuredSelector<IRootState, any>({
        notifications: getNotifications
    }),
    (dispatch: Dispatch): any => ({
        dispatchNotificationSubscribe: (name: string, type: string): AnyAction => dispatch(communicationActions.subscribeUpdating(name, type)),
        dispatchAllNotificationsWereRead: (): AnyAction => dispatch(notificationActions.allNotificationsRead()),
        dispatchOpenDisputeDrawer: (disputeId: string): AnyAction => dispatch(voiceDisputeDrawerActions.openDisputeDrawer(disputeId)),
        dispatchDisputeResolutionModal: (): AnyAction => dispatch(uiActions.showDisputeResolutionModal()),
        dispatchMarkNotificationAsRead: (id: string): AnyAction => dispatch(notificationActions.markNotificationAsRead(id))
    })
)
export class NotificationContainer extends React.Component<any> {
    constructor(props: any) {
        super(props);
        props.dispatchNotificationSubscribe('notification', notificationType.NOTIFICATION_UPDATE_RECEIVED);
    }

    countUnreadNotifications = () => {
        return this.props.notifications.filter((item: any) => !item.read).length;
    };

    handleActionableNotificationClick = (notification: IStateNotification): void => {
        switch (notification.type) {
            case NOTIFICATION_TYPE.ROAMING_PARTNERSHIP: {
                const peerId = get(notification, 'data.peerId');
                history.push(`/dashboard/partnerships/${peerId}`);
                break;
            }
            case NOTIFICATION_TYPE.ROAMING_DISPUTE: {
                const id = get(notification, 'data.id');
                history.push(`/dashboard/roaming/contracts/${id}/settlement`);
                if (!get(notification, 'data.isSettled')) {
                    this.props.dispatchDisputeResolutionModal();
                }
                break;
            }
            case NOTIFICATION_TYPE.ROAMING_CONTRACT: {
                const id = get(notification, 'data.id');
                const agreementType = get(notification, 'data.isProposal') ? 'proposal' : 'contracts';
                const isSettlementReady = get(notification, 'data.settlementReady');
                history.push(`/dashboard/roaming/${agreementType}/${id}${isSettlementReady ? '/settlement' : ''}`);
                break;
            }

            case NOTIFICATION_TYPE.VOICE_DISPUTE: {
                const disputeOfferId = get(notification, 'data.id');
                this.props.dispatchOpenDisputeDrawer(disputeOfferId);
                break;
            }
            case NOTIFICATION_TYPE.VOICE_CONTRACT: {
                history.push('/dashboard/users/partner-management');
                break;
            }
            case NOTIFICATION_TYPE.VOICE_CDR: {
                const accountId = get(notification, 'data.settlement.accountId');
                const status = get(notification, 'data.settlement.status');
                const settlementId = get(notification, 'data.settlement.id');
                const isOurCdrExist = Boolean(get(notification, 'data.settlement.normalizedCDRFileHash'));
                const isPartnerCdrExist = Boolean(get(notification, 'data.settlement.normalizedPartnerCDRFileHash'));
                const isInvoiceExist = Boolean(get(notification, 'data.settlement.invoiceFileHash'));
                // if compared we redirect to comparison page if not we redirect to settlementList page
                if (status === 'COMPARED') {
                    if (isInvoiceExist && isOurCdrExist) {
                        history.push(setPathId(voicePaths.settlementsOverview, settlementId));
                    } else if (isOurCdrExist && isPartnerCdrExist) {
                        history.push(`${setPathId(voicePaths.settlementsOverview, settlementId)}?comparisonType=${ComparisonType.CDR_PARTNER_CDR}`);
                    }
                } else {
                    history.push(setPathId(voicePaths.settlementsList, accountId));
                }
                break;
            }
            case NOTIFICATION_TYPE.VOICE_AGREEMENT: {
                const swapId = get(notification, 'data.swapId');
                history.push(setPathId(voicePaths.agreement, swapId));
                break;
            }
        }
    };

    render() {
        const { dispatchAllNotificationsWereRead, notifications, dispatchMarkNotificationAsRead } = this.props;
        const unreadNotificationsSize = this.countUnreadNotifications();

        return (
            <Notification
                notifications={notifications}
                setAllNotificationWereRead={dispatchAllNotificationsWereRead}
                handleActionableNotificationClick={this.handleActionableNotificationClick}
                markNotificationAsRead={dispatchMarkNotificationAsRead}
                unreadNotificationsSize={unreadNotificationsSize}
            />
        );
    }
}
