import { call, put, select, take, takeEvery, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { eventChannel, EventChannel, Unsubscribe } from 'redux-saga';
import ReconnectingWebSocket, { Message } from 'reconnecting-websocket';
import { AnyAction } from 'redux';
import fetch from 'unfetch';
import { CloseEvent, Event } from 'reconnecting-websocket/dist/events';
import get from 'lodash/get';

import communicationActions, { communicationType } from '@actions/communication';
import appActions, { appType } from '@actions/app';
import uiActions from '@actions/ui';
import { getApp, getBackendRestUrlSelector, getBackendWSUrlSelector, getCsrfUrlSelector } from '@selectors/app';
import { getAuthToken } from '@selectors/auth';
import { sessionChecking } from '@saga/auth';
import authActions, { authType, SessionEvent } from '@actions/auth';
import { NetworkStatus } from '@reducers/app';
import { LocalStorage } from '@helpers/localstorage';

interface IMessage {
    id: number;
    func: string;
    args: any[];
    token?: string;
}

export type UploadHashHandler = (hash: string) => AnyAction;

const AUTH_ERRORS = ['UnauthorizedError', 'SessionTimeoutError', 'SessionWithNoActivityError', 'AuthError'];
const NOT_ALLOWED_ERRORS = ['NotAllowedError'];
let websocketChannel: ReconnectingWebSocket;
const socketsMessageCache = {} as any;
const socketsUpdaterCache = {} as any;
const addToMessageCache = (id: number, action: AnyAction): any => (socketsMessageCache[id] = action);
const getFromMessageCache = (id: number): any => socketsMessageCache[id];
const removeMessageFromCache = (id: number): any => delete socketsMessageCache[id];

const addToUpdaterCache = (type: string, action: AnyAction): any => (socketsUpdaterCache[type] = action);
const getFromUpdaterCache = (type: string): any => socketsUpdaterCache[type];
const removeUpdaterFromCache = (type: string): any => delete socketsUpdaterCache[type];

const shouldShowSpinner = (action: AnyAction) => !!action.showSpinner || action.type.includes(':SPIN:');

function* addReceiveInterceptor(message: any): IterableIterator<any> {
    if (!message) {
        yield put(communicationActions.requestFail());
    }

    const { value } = message;
    if (!value.success) {
        const isAuthError: boolean = AUTH_ERRORS.some((errorType) => get(value, 'errorType', '').startsWith(errorType));
        const isNotAllowedError: boolean = NOT_ALLOWED_ERRORS.some((errorType) => get(value, 'errorType', '').startsWith(errorType));

        if (isAuthError) {
            yield put(communicationActions.requestUnauthorized(message));
        }
        if (isNotAllowedError) {
            yield put(communicationActions.requestNotAllowed(message));
        }
        const isExpiredError: boolean = get(value, 'errorType', '').startsWith('PasswordExpiredError');
        if (isExpiredError) {
            yield put(authActions.loginRequestFail(message));
            yield put(push('/change-password'));
        }
        if (isExpiredError || isNotAllowedError) {
            return true;
        }
    }
}

function* sendMessage(action: AnyAction): IterableIterator<any> {
    const id = new Date().valueOf() * Math.random();
    const { payload = {} } = action;
    const func = payload.name;
    const args = payload.values || [];
    const authToken = (yield select(getAuthToken)) || LocalStorage.get('auth_token');
    const rawMessage: IMessage = { id, func, args };
    if (authToken) {
        rawMessage.token = authToken;
    }

    const message = JSON.stringify(rawMessage) as Message;

    addToMessageCache(id, action);
    websocketChannel.send(message);
    if (shouldShowSpinner(action)) {
        yield put(uiActions.incrementSpinnerPending());
    }
}

// for easy access from console
(window as any).sendMessage = (name: string, args: any[] = [], actionType: string = 'TEST') => {
    const iterator = sendMessage({ type: actionType, payload: { name, values: args } });
    let isDone = false;
    while (!isDone) {
        isDone = iterator.next().done || false;
    }
};

type WsEvent = Event | CloseEvent | MessageEvent;

function* createEventChannel(channel: ReconnectingWebSocket): IterableIterator<EventChannel<WsEvent>> {
    return eventChannel(
        (emit: (message: WsEvent) => any): Unsubscribe => {
            channel.onopen = (message: Event): Event => emit(message);
            channel.onclose = (message: CloseEvent): CloseEvent => emit(message);
            channel.onmessage = (message: MessageEvent): MessageEvent => emit(message);
            return () => channel.close();
        }
    );
}

function* handleResponseMessage(messageValue: any): IterableIterator<any> {
    const { id, success } = messageValue;
    const cached = getFromMessageCache(id);

    if (cached) {
        const actionPattern = cached.type.replace('::REQUEST', '');

        if (success) {
            yield put({
                type: `${actionPattern}::REQUEST:SUCCESS`,
                payload: messageValue.value,
                requestAction: cached
            });

            if (cached.resolve) {
                cached.resolve(messageValue.value);
            }

            if (cached.nextAction) {
                yield put({
                    ...cached.nextAction,
                    previousAction: cached
                });
            }
        } else {
            yield put({
                type: `${actionPattern}::REQUEST:FAIL`,
                payload: messageValue.exception,
                requestAction: cached,
                errorType: messageValue.errorType
            });

            if (cached.reject) {
                cached.reject(messageValue.exception);
            }
        }

        yield put({
            type: `${actionPattern}::REQUEST:FINISHED`,
            requestAction: cached
        });
        if (shouldShowSpinner(cached)) {
            yield put(uiActions.decrementSpinnerPending());
        }
        removeMessageFromCache(id);
    } else {
        yield put(communicationActions.responseUnknown(messageValue));
    }
}

function* handleUpdaterMessage(messageValue: any): IterableIterator<any> {
    const subscription = getFromUpdaterCache(messageValue.type);

    if (subscription) {
        yield put({ type: subscription, payload: messageValue });
    } else {
        const action = {
            ...messageValue,
            type: `SERVER_UPDATE::${messageValue.type.toUpperCase()}`
        };
        yield put(action);
    }
}

function* handleSessionEvent(message: any): IterableIterator<any> {
    switch (message.eventType) {
        case SessionEvent.Expired:
        case SessionEvent.Replaced:
        case SessionEvent.Warning: {
            yield put(
                authActions.receiveSessionEvent({
                    lastEvent: message.eventType,
                    expiresAt: message.expiresAt
                })
            );
            yield put(uiActions.showSessionExpirationDialog());
            break;
        }
        default:
            return;
    }
}

function* receiveMessage(message: string): IterableIterator<any> {
    const parsedData = JSON.parse(message);

    const isAuthErrorHandled = yield call(addReceiveInterceptor, parsedData);
    if (parsedData.opcode === 'response' && !isAuthErrorHandled) {
        return yield call(handleResponseMessage, parsedData.value);
    }

    if (parsedData.opcode === 'update' && !isAuthErrorHandled) {
        return yield call(handleUpdaterMessage, parsedData.value);
    }

    yield put(communicationActions.messageUnknown(parsedData));
}

function* connectToServer() {
    const wsUrl: string = (yield select(getBackendWSUrlSelector)) as any;
    const csrfUrl: string = (yield select(getCsrfUrlSelector)) as any;
    let isCsrfTokenFetched = false;
    while (!isCsrfTokenFetched) {
        try {
            yield fetch(csrfUrl, { credentials: 'include', cache: 'no-cache' });
            isCsrfTokenFetched = true;
        } catch (e) {
            yield new Promise((res) => setTimeout(res, 2000));
        }
    }

    if (!websocketChannel) {
        websocketChannel = new ReconnectingWebSocket(wsUrl);
    } else {
        websocketChannel.reconnect();
    }
    window.addEventListener('beforeunload', () => {
        websocketChannel.close(1000, 'Client exited');
    });
}

function* socketsInit(): IterableIterator<any> {
    yield put(communicationActions.communicationInit());

    yield connectToServer();

    const channel = yield call(createEventChannel, websocketChannel);

    while (true) {
        const message: MessageEvent = (yield take(channel)) as any;
        if (message.type === 'open') {
            yield call(sessionChecking);
            yield put(appActions.setNetworkStatus(NetworkStatus.Connected));
        } else if (message.type === 'close') {
            const { networkStatus } = (yield select(getApp)) as any;
            if (![NetworkStatus.Disconnected, NetworkStatus.BrowserOffline].includes(networkStatus)) {
                yield put(appActions.setNetworkStatus(NetworkStatus.Disconnected));
            }
        } else {
            yield call(receiveMessage, message.data);
        }
    }
}

function* socketsSubscribe(action: AnyAction): IterableIterator<any> {
    const { payload } = action;

    addToUpdaterCache(payload.name, payload.type);
}

function* socketsUnsubscribe(action: AnyAction): IterableIterator<any> {
    const { payload } = action;

    removeUpdaterFromCache(payload.name);
}

function* uploadFile(action: AnyAction): IterableIterator<any> {
    const showSpinner = shouldShowSpinner(action);
    if (showSpinner) {
        yield put(uiActions.incrementSpinnerPending());
    }
    yield put(authActions.sessionCheckRequest());
    yield take(authType.SESSION_CHECK_FINISHED);

    const restUrl = yield select(getBackendRestUrlSelector);

    const uploadResource = (action.payload && action.payload.url) || restUrl;

    const form = new FormData();
    form.append('file', action.payload);

    const actionPattern = action.type.replace('::REQUEST', '');

    try {
        const sendFileResult: Response = (yield fetch(uploadResource, {
            method: 'POST',
            body: form
        })) as any;

        const res = yield sendFileResult.json();
        yield put({ type: `${actionPattern}:SUCCESS`, payload: res });
        // The upload action can include an action creator for subsequent handling of upload file hash
        if (action.createUploadHashHandlerAction) {
            const actionCreator = action.createUploadHashHandlerAction as UploadHashHandler;
            const uploadHash = Object.values((res as unknown) as object)[0] as string;
            yield put(actionCreator(uploadHash));
        }
    } catch (e) {
        // TODO I AM NOT TEST THIS CODE!
        yield put({ type: `${actionPattern}:FAIL`, payload: e });
    }
    if (showSpinner) {
        yield put(uiActions.decrementSpinnerPending());
    }
}

function* restGetRequest(action: AnyAction): IterableIterator<any> {
    const backendRestUrlSelector: string = (yield select(getBackendRestUrlSelector)) as any;
    const sendRestUrl = new URL(backendRestUrlSelector);

    const { payload = {} } = action;

    if (payload != null) {
        Object.keys(payload).forEach((key: string) => sendRestUrl.searchParams.append(key, payload[key]));
    }

    const actionPattern = action.type;

    try {
        const sendResult: Response = (yield fetch(sendRestUrl.href, {
            method: 'GET'
        })) as any;

        const res = yield sendResult.json();
        yield put({ type: `${actionPattern}:SUCCESS`, payload: res });
    } catch (error) {
        yield put({ type: `${actionPattern}:FAIL`, payload: error });
    }
}

function* restPostRequest(action: AnyAction): IterableIterator<any> {
    const { payload } = action;
    const backendRestUrlSelector = yield select(getBackendRestUrlSelector);
    const backendSpecificUrlEnding = payload.url ? payload.url : '';

    const sendRestRequest = `${backendRestUrlSelector}${backendSpecificUrlEnding}`;
    const sendRestUrl = new URL(sendRestRequest);

    Object.keys(payload).forEach((key: string) => {
        if (key !== 'file' && key !== 'url') {
            sendRestUrl.searchParams.append(key, payload[key]);
        }
    });

    const form = new FormData();
    form.append('file', payload.file);

    const actionPattern = action.type;

    try {
        const sendFileResult: Response = (yield fetch(sendRestUrl.href, {
            method: 'POST',
            body: form
        })) as any;

        const res = yield sendFileResult.json();
        yield put({ type: `${actionPattern}:SUCCESS`, payload: res });
    } catch (error) {
        yield put({ type: `${actionPattern}:FAIL`, payload: error });
    }
}

function* onNetworkStatusChange(action: AnyAction): IterableIterator<any> {
    const { payload: status } = action;
    switch (status) {
        case NetworkStatus.BrowserOffline: {
            websocketChannel.close();
            break;
        }
        case NetworkStatus.Disconnected: {
            yield connectToServer();
            break;
        }
        default: {
            break;
        }
    }
}

export const sagaCommunication = function*(): IterableIterator<any> {
    yield takeLatest(appType.SAGA_INIT_FINISHED, socketsInit);
    yield takeEvery((action: AnyAction): any => /::REQUEST$/.test(action.type), sendMessage);
    yield takeEvery((action: AnyAction): any => /::UPLOAD$/.test(action.type), uploadFile);
    yield takeEvery((action: AnyAction): any => /::REST:REQUEST$/.test(action.type), restGetRequest);
    yield takeEvery((action: AnyAction): any => /::REST:POST:REQUEST$/.test(action.type), restPostRequest);
    yield takeEvery(communicationType.RESPONSE_UPDATING_SUBSCRIBE, socketsSubscribe);
    yield takeEvery(communicationType.RESPONSE_UPDATING_UNSUBSCRIBE, socketsUnsubscribe);
    yield takeEvery(appType.SET_NETWORK_STATUS, onNetworkStatusChange);
    yield takeEvery(communicationType.SERVER_UPDATE_SESSION_EVENT, handleSessionEvent);
};
