import * as React from 'react';
import classNames from 'classnames';

import { Icon } from '@components/Icon';

interface IFieldProps {
    input: any;
    meta?: any;
    label?: string;
    disabled?: boolean;
    icon?: string;
    classes?: string;
    suffix?: string | JSX.Element;
    placeholder?: string;
    type?: string;
}

export const InputField = (props: IFieldProps): JSX.Element => {
    const { input, meta, label, disabled, icon, classes, suffix, placeholder, type = 'text' } = props;
    const currentError = meta.touched && meta.invalid && meta.error;

    const fieldClasses = classNames(
        `field field-input field-${input.name}`,
        classes,
        { 'is-active': meta.active },
        { 'has-error': Boolean(currentError) },
        { 'has-icon': Boolean(icon) },
        { 'is-disabled': disabled }
    );

    const fieldLabel = Boolean(label) && <label htmlFor={input.name}>{label}</label>;

    const fieldIcon = (
        <div className={classNames(`field-icon field-icon-tail`)}>
            {currentError ? <Icon iconName="exclamationError" /> : icon ? <Icon iconName={icon} /> : null}
        </div>
    );

    const fieldSuffix = Boolean(suffix) && <div className="suffix">{suffix}</div>;

    return (
        <div className={fieldClasses}>
            {fieldLabel}
            <div className="field-control">
                <input {...input} type={type} id={input.name} disabled={disabled} placeholder={placeholder} autoComplete="off" spellCheck="false" />
                {fieldIcon}
            </div>
            {fieldSuffix}
            <div className="field-message">{currentError}</div>
        </div>
    );
};
