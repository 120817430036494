import { getUrlQueryParams } from '../../utils/urlFunctions';
import queryString from 'query-string';
import moment from 'moment';
import { formatUTCToLocal } from '@constants';

export function updateFiltersInUrl(filtersObject: object, location: Location, history: any) {
    const queryParams = getUrlQueryParams(location);
    const filtersParam = [];
    for (const [filterKey, filterValue] of Object.entries(filtersObject)) {
        if (filterKey === 'dateFilter') {
            const startDate = typeof filterValue.startDate === 'object' ? filterValue.startDate / 1000 : filterValue.startDate;
            const endDate = typeof filterValue.endDate === 'object' ? filterValue.endDate / 1000 : filterValue.endDate;
            filtersParam.push(JSON.stringify({ [filterKey]: JSON.stringify([startDate, endDate]) }));
        } else {
            filtersParam.push(JSON.stringify({ [filterKey]: JSON.stringify(filterValue) }));
        }
    }
    queryParams.filters = filtersParam;
    const stringifyParams = queryString.stringify(queryParams);
    history.push(`${location.pathname}?${stringifyParams}`);
}

export function extractFiltersFromUrl(location: Location) {
    const queryParams = getUrlQueryParams(location);
    let filters;
    if (Array.isArray(queryParams.filters)) {
        // @ts-ignore
        filters = queryParams.filters.reduce((acc, filterString: string): any => {
            const filterObj = JSON.parse(filterString);
            for (const [key, value] of Object.entries(filterObj)) {
                filterObj[key] = JSON.parse(value as string);
            }
            return { ...acc, ...filterObj };
        }, {});
    } else if (typeof queryParams.filters === 'string') {
        filters = JSON.parse(queryParams.filters);
        for (const [key, value] of Object.entries(filters)) {
            if (typeof value === 'string') {
                filters[key] = JSON.parse(value);
            }
        }
    }
    if (filters && filters.dateFilter) {
        const pickerStartFromUrl = moment(filters.dateFilter[0] * 1000).format(formatUTCToLocal);
        const pickerEndFromUrl = moment(filters.dateFilter[1] * 1000).format(formatUTCToLocal);
        filters.dateFilter = {
            pickerStartFromUrl,
            pickerEndFromUrl,
            startDate: filters.dateFilter[0],
            endDate: filters.dateFilter[1]
        };
    }
    return filters;
}
