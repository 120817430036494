import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';

import { IRootState } from '@reducers/index';

interface IProps extends RouteComponentProps<void> {
    somevalue?: any;
}

@connect(
    (state: IRootState): any => {
        return {};
    },
    (dispatch: Dispatch): any => ({})
)
export class AppContainer extends React.Component<IProps> {
    static defaultProps: Partial<IProps> = {};

    constructor(props: IProps, context?: any) {
        super(props, context);
    }

    render() {
        return (
            <div className="main-container">
                <section>
                    <Link to="/auth/login">Log In</Link>
                    <Link to="/auth/forgot-password">Forgot password?</Link>
                    <Link to="/auth/reset-password">Reset password</Link>
                    <Link to="/auth/lost-token">Reset 2-Step Verification</Link>
                    <Link to="/dashboard/contracts">Contracts</Link>
                    <Link to="/dashboard/settlements">Settlements</Link>
                </section>
            </div>
        );
    }
}
