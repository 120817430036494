import { transactionsType, ITransactionBasket, ITransactionConvertation, ITransactionsItemReducer } from '@actions/transactions';
import { IAppAction } from './state';
import { requestSuccessType, requestFailType, getEmptyQueryResult, IQueryResult } from '@helpers/actionCreator';

// Note - keys are used by display, don't change
export enum InvoiceStatus {
    Pending = 1,
    In_Progress = 2,
    Paid = 3,
    Failed = 4
}
export interface IInvoice {
    id: string;
    timestamp: number;
    statusChangedTimestamp: number;
    counterpart: string;
    receivable: boolean;
    period: number;
    status: InvoiceStatus;
    amount: number;
}
export interface ITransactionsReducer {
    basket?: ITransactionBasket;
    items: ITransactionsItemReducer[];
    hasLoaded: boolean;
    isTransactionProcessing: boolean;
    isTransactionWithdrawProcessing: boolean;
    convertationSDR?: ITransactionConvertation;
    convertationCurrency?: ITransactionConvertation;
    invoices: IQueryResult<IInvoice>;
    invoiceUpdateCount: number;
}
const initialState: ITransactionsReducer = {
    items: [],
    hasLoaded: false,
    isTransactionProcessing: false,
    isTransactionWithdrawProcessing: false,
    basket: {
        rmb: '0',
        eur: '0',
        gbp: '0',
        jpy: '0',
        usd: '0',
        total: '0',
        fee: '0',
        net: '0'
    },
    convertationSDR: {
        amount: '0',
        rmb: '0',
        eur: '0',
        gbp: '0',
        jpy: '0',
        usd: '0',
        currency: '',
        fee: '0',
        net: '0',
        feePercent: '0'
    },
    convertationCurrency: {
        amount: '0',
        rmb: '0',
        eur: '0',
        gbp: '0',
        jpy: '0',
        usd: '0',
        currency: '',
        fee: '0',
        net: '0',
        feePercent: '0'
    },
    invoices: getEmptyQueryResult(),
    invoiceUpdateCount: 0
};

const updateTransactions = (oldTransactions: ITransactionsItemReducer[], newTransactions: ITransactionsItemReducer[]): ITransactionsItemReducer[] => {
    let index = -1;
    newTransactions.forEach((newTrans: ITransactionsItemReducer): void => {
        index = oldTransactions.findIndex((oldTrans: ITransactionsItemReducer): boolean => newTrans.id === oldTrans.id);
        if (index >= 0) {
            oldTransactions[index] = newTrans;
        } else {
            oldTransactions.push(newTrans);
        }
    });
    return [...oldTransactions];
};

const parseBasket = (data: any): ITransactionBasket => {
    return {
        rmb: data.basket.rmb,
        eur: data.basket.eur,
        gbp: data.basket.gbp,
        jpy: data.basket.jpy,
        usd: data.basket.usd,
        net: data.basket.net,
        fee: data.basket.fee,
        total: data.basket.total
    };
};

const parseConvertation = (value: any): ITransactionConvertation => {
    return {
        amount: value.data && value.data.amount,
        rmb: value.data && value.data.basket.rmb,
        eur: value.data && value.data.basket.eur,
        gbp: value.data && value.data.basket.gbp,
        jpy: value.data && value.data.basket.jpy,
        usd: value.data && value.data.basket.usd,
        currency: value.fee && value.fee.currency,
        fee: value.fee && value.fee.fee,
        net: value.fee && value.fee.net,
        feePercent: value.fee && value.fee.feePercent
    };
};

export function transactionsReducer(state: ITransactionsReducer = initialState, action: IAppAction): ITransactionsReducer {
    switch (action.type) {
        case transactionsType.TRANSACTIONS_GET_ALL_REQUEST:
            return { ...state, hasLoaded: false };
        case transactionsType.TRANSACTIONS_GET_ALL_SUCCESS:
            return { ...state, items: action.payload };
        case transactionsType.TRANSACTIONS_GET_ALL_FINISHED:
            return { ...state, hasLoaded: true };
        case transactionsType.TRANSACTION_UPDATE_RECEIVED:
            return {
                ...state,
                items: updateTransactions(state.items, action.payload.objs)
            };
        case transactionsType.TRANSACTIONS_GET_USD_REQUEST:
            return { ...state, basket: initialState.basket };
        case transactionsType.TRANSACTIONS_GET_USD_SUCCESS:
            return { ...state, basket: parseBasket(action.payload) };
        case transactionsType.TRANSACTIONS_CONVERT_USD_REQUEST:
            return { ...state, convertationSDR: initialState.convertationSDR };
        case transactionsType.TRANSACTIONS_CONVERT_USD_SUCCESS:
            return { ...state, convertationSDR: parseConvertation(action.payload) };
        case transactionsType.TRANSACTIONS_CONVERT_CURRENCY_REQUEST:
            return {
                ...state,
                convertationCurrency: initialState.convertationCurrency
            };
        case transactionsType.TRANSACTIONS_CONVERT_CURRENCY_SUCCESS:
            return {
                ...state,
                convertationCurrency: parseConvertation(action.payload)
            };
        case transactionsType.TRANSACTIONS_PAYMENT_REQUEST:
            return { ...state, isTransactionProcessing: true };
        case transactionsType.TRANSACTIONS_PAYMENT_FINISHED:
            return { ...state, isTransactionProcessing: false };
        case transactionsType.TRANSACTIONS_WITHDRAW_REQUEST:
            return { ...state, isTransactionWithdrawProcessing: true };
        case transactionsType.TRANSACTIONS_WITHDRAW_REQUEST_FINISHED:
            return { ...state, isTransactionWithdrawProcessing: false };
        case requestSuccessType(transactionsType.QUERY_INVOICES):
            return { ...state, invoices: action.payload };
        case transactionsType.INVOICE_UPDATE_RECEIVED: {
            const objs: IInvoice[] = action.payload.objs;
            const { invoices } = state;
            const { items } = invoices;
            // If update relates to displayed invoice - update the modified invoices
            if (items.some(({ id }) => objs.some(({ id: objId }) => id === objId))) {
                return {
                    ...state,
                    invoices: {
                        ...invoices,
                        items: items.map((invoice) => objs.find(({ id }) => invoice.id === id) || invoice)
                    }
                };
            } else {
                // Update the invoice update count so the view could refetch the invoices
                return { ...state, invoiceUpdateCount: state.invoiceUpdateCount + 1 };
            }
        }
        case requestFailType(transactionsType.UPLOAD_INVOICE): {
            let msg = 'The upload failed. Please try again';
            if (
                action.payload &&
                action
                    .payload!.toString()
                    .toLowerCase()
                    .includes('already exists')
            ) {
                msg = action.payload;
            }
            alert(msg);
            return { ...state };
        }
        case requestFailType(transactionsType.PAY_INVOICES): {
            alert('The operation failed. Please try again');
            return { ...state };
        }
        default:
            return { ...state };
    }
}
