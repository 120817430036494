import { memoize } from 'lodash';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { selectLocalCurrency } from '@selectors/settings';
import { selectSdrRates } from '@selectors/currency';

export class Currency {
    static localCurrency: string;
    static setRates(sdrRates: Record<string, number>) {
        this.sdrRates = sdrRates;
        (Currency as any).getRate.cache.clear();
    }

    private static sdrRates: Record<string, number>;

    private static getRate = memoize(
        (from: string, to: string): number => {
            if (from === to) {
                return 1;
            }
            return Currency.sdrRates[to] / Currency.sdrRates[from];
        },
        (...args) => args.join('-')
    );

    private static format(value: number, currency: string, decimals: number = 2): string {
        return value
            .toLocaleString(undefined, {
                style: 'currency',
                currency: currency ? currency : 'EUR',
                maximumFractionDigits: decimals,
                minimumFractionDigits: decimals
            })
            .replace(/\b(\d)/, ' $1');
    }

    private readonly code: string;
    private readonly value: number;

    constructor(value: number | string, code: string) {
        this.value = typeof value === 'string' ? Number(value) : value;
        this.code = code;
    }

    get string() {
        return this.toString();
    }

    toString(decimals: number = 2): string {
        return Currency.format(this.value, this.code, decimals);
    }

    valueOf(): number {
        return this.value;
    }

    get local(): number {
        const rate = Currency.getRate(this.code, Currency.localCurrency);
        return rate * this.value;
    }

    get localString(): string {
        return this.toLocalString();
    }

    toLocalString(decimals: number = 2): string {
        const local = this.local;
        return isNaN(local) ? '...' : Currency.format(local, Currency.localCurrency, decimals);
    }

    add(other: Currency): Currency {
        if (other.code !== this.code) {
            throw new Error('Currencies must be of same code');
        }
        return new Currency(this.value + other.value, this.code);
    }
}

export const CurrencyProvider: React.FC<any> = ({ children }) => {
    const localCurrency = useSelector(selectLocalCurrency);
    const sdrRates = useSelector(selectSdrRates);
    React.useMemo(() => {
        Currency.localCurrency = localCurrency;
        Currency.setRates(sdrRates);
    }, [sdrRates, localCurrency]);

    return children;
};
