import pako from 'pako';

const fileToArrayBuffer = (file: File): Promise<ArrayBuffer> =>
    new Promise((resolve, reject) => {
        try {
            const reader = new FileReader();
            reader.onload = () => resolve(reader.result as ArrayBuffer);
            reader.readAsArrayBuffer(file);
        } catch (err) {
            reject(err);
        }
    });

const CHUNK_SIZE = 1000000;

export async function fileCompressor(file: File): Promise<Blob> {
    const buffer = await fileToArrayBuffer(file);
    const deflate = new pako.Deflate({ gzip: true });
    for (let pos = 0; pos < buffer.byteLength; ) {
        await new Promise((resolve) => window.requestAnimationFrame(resolve));
        const slice = buffer.slice(pos, (pos += CHUNK_SIZE));
        const isLast = pos >= buffer.byteLength;
        deflate.push(slice, isLast);
    }
    return new Blob([deflate.result as Uint8Array]);
}
