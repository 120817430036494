import React, { useState } from 'react';
import { copyToClipboard } from '@helpers';

import './styles.scss';

export const CopyLink = ({ link }: { link: string }) => {
    const [copied, setCopied] = useState(false);
    const copy = () => {
        copyToClipboard(link);
        setCopied(true);
    };
    if (link.startsWith('/')) {
        link = `${window.location.protocol}//${window.location.host}${link}`;
    }
    return (
        <div className={'link-box' + (copied ? ' copied' : '')}>
            <div>
                <span>{link}></span>
            </div>
            <button onClick={copy}>{copied ? 'Copied' : 'Copy Link'}</button>
        </div>
    );
};
