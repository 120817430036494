import { createSelector } from 'reselect';

import { IRootState } from '@reducers/index';
import { ISettingsState } from '@reducers/settings';

const settingsSelector = (state: IRootState): ISettingsState => state.settings;

export const selectLocalCurrency = createSelector(settingsSelector, (settings) => settings.localCurrency);
export const selectSupportedUsageCurrencies = createSelector(settingsSelector, (settings) => settings.supportedCurrencies);
export const selectCountryMappingId = createSelector(settingsSelector, (settings) => settings.countryMappingId);
