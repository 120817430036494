import { IAppAction } from './state';
import { agreementsType, settlementActionTypes } from '@actions';
import { BillingStatement, BillingStatementStatus, BillingStatementType } from '@clearblockchain/shared/billing-statement';

export interface ISettlementSummary {
    contractId: string;
    name: string;
    createdAt: number;
    updatedAt: number;
    balances: [string, string];
    netPositions: [string, string];
    myPartyIndex: number;
    currency: string;
    status: BillingStatementStatus;
    activeOfferAmount: string;
    perPartyState?: BillingStatement['perPartyState'];
}

export interface ISettlementsState {
    settlements: ISettlementSummary[];
}

const initialState = {
    settlements: []
};

export function settlementsReducer(state: ISettlementsState = initialState, { type, payload, requestAction }: IAppAction): ISettlementsState {
    switch (type) {
        case agreementsType.AGREEMENTS_CONTRACT_UPDATE_RECEIVED: {
            const { objs: contracts } = payload as { objs: any[] };
            const modifiedSettlements = state.settlements.slice();
            contracts.forEach((c) => {
                const sIndex = state.settlements.findIndex((s) => s.contractId === c.id);
                if (sIndex === -1) {
                    return;
                }

                modifiedSettlements[sIndex].updatedAt = c.updatedAt;
            });
            return {
                ...state,
                settlements: modifiedSettlements
            };
        }
        case agreementsType.AGREEMENTS_BILLING_STATEMENT_UPDATE_RECEIVED: {
            const { objs: billingStatements } = payload as { objs: BillingStatement[] };
            const modifiedSettlements = state.settlements.slice();
            billingStatements
                .filter((b) => b.type === BillingStatementType.EndOfYearDiscount)
                .forEach((b) => {
                    const sIndex = state.settlements.findIndex((s) => s.contractId === b.contractId);
                    if (sIndex === -1) {
                        return;
                    }

                    modifiedSettlements[sIndex].activeOfferAmount = b.activeOfferAmount!;
                    modifiedSettlements[sIndex].balances = b.settlementResults.result.balances;
                    modifiedSettlements[sIndex].status = b.status;
                    modifiedSettlements[sIndex].perPartyState = b.perPartyState;
                });
            return {
                ...state,
                settlements: modifiedSettlements
            };
        }

        case settlementActionTypes.GET_SUMMARY_SUCCESS: {
            return {
                ...state,
                settlements: payload
            };
        }

        default:
            return state;
    }
}
