import { createSelector } from 'reselect';
import moment from 'moment';
import take from 'lodash/take';

import { IStateNotification, IStateNotifications } from '@actions/notification';
import { IRootState } from '@reducers/index';

const notificationsSelector = (state: IRootState): IStateNotifications => state.notifications;

export const getAllNotifications = createSelector(
    notificationsSelector,
    (notificationsState: IStateNotifications): IStateNotifications => {
        return notificationsState.map((item: IStateNotification): any => ({
            ...item,
            content: item.content,
            id: item.id,
            read: item.read,
            time: item.time || moment().valueOf(),
            timeToPresent: moment((item.time as number) * 1000).fromNow()
        }));
    }
);

export const getOrderedNotifications = createSelector(
    getAllNotifications,
    (notificationsState: IStateNotifications): IStateNotifications => {
        return notificationsState.sort((item1: IStateNotification, item2: IStateNotification): number =>
            moment(item1.time).isBefore(moment(item2.time)) ? 1 : -1
        );
    }
);

export const getNotifications = createSelector(
    getOrderedNotifications,
    (notificationsState: IStateNotifications): IStateNotifications => {
        const unread = notificationsState.filter((item: IStateNotification): boolean => !item.read);
        const read = notificationsState.filter((item: IStateNotification): boolean => item.read);
        if (unread.length < 5) {
            const remainingNotifications = take(read, 5 - unread.length);
            return [...unread, ...remainingNotifications];
        }
        return unread;
    }
);
