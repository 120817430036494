import * as React from 'react';
import classNames from 'classnames';
import enhanceWithClickOutside from 'react-click-outside';

import { Icon } from '@components/Icon';
import { Checkbox } from '@components/Fields';
import { InputIndicatorSvg } from '@components/Svg/input-indicator';

import './styles.scss';

type ISimpleBooleanFunction = (arg: boolean) => void;

interface IMultiSearchSelectProps {
    menuOptions: string[];
    chosenOptions?: string[];
    selectOption: (option: string[]) => void;
}

interface IMultiSearchSelectState {
    inputValue: string;
    selectValues: string[];
    shownMenu: 'filterMenu' | 'selectMenu' | null;
}

class SelectWithMultiSearch extends React.Component<IMultiSearchSelectProps, IMultiSearchSelectState> {
    constructor(props: IMultiSearchSelectProps) {
        super(props);

        this.state = {
            inputValue: '',
            selectValues: props.chosenOptions ? props.chosenOptions : [],
            shownMenu: null
        };
    }

    handleClickOutside = () => {
        this.hideMenu();
    };

    changeInputValue = (event: React.FormEvent<HTMLInputElement>): void => {
        const inputValue = event.currentTarget.value;
        this.setState({
            shownMenu: 'filterMenu',
            inputValue
        });
    };

    showSelectMenu = (): void => {
        this.setState({
            shownMenu: 'selectMenu',
            inputValue: ''
        });
    };

    hideMenu = (): void => {
        this.setState({
            shownMenu: null
        });
    };

    chooseMenuOption = (option: string, checked: boolean): void => {
        const { selectValues } = this.state;
        const newSelectValues = !checked ? selectValues.filter((value: string) => value !== option) : [...selectValues, option];

        this.setState({
            selectValues: newSelectValues
        });
        this.props.selectOption(newSelectValues);
    };

    showFilterMenu = (): void => {
        this.setState({
            shownMenu: 'filterMenu'
        });
    };

    setMenuOption = (option: string): ISimpleBooleanFunction => (checked: boolean): void => {
        this.chooseMenuOption(option, checked);
    };

    filterMenuOptionFormatter = (option: string, searchInputString: string): JSX.Element | null => {
        const newStringStart = option.toLowerCase().indexOf(searchInputString.toLowerCase());

        if (newStringStart !== -1) {
            const newStringLength = searchInputString.length;
            const newStringEnd = newStringStart + newStringLength;

            const newString = (
                <span>
                    {option.slice(0, newStringStart)}
                    <span className="search-select-multi-menu--option_span">{option.slice(newStringStart, newStringEnd)}</span>
                    {option.slice(newStringEnd, option.length)}
                </span>
            );

            return newString;
        }
        return null;
    };

    renderSearchInput = (inputValue: string): JSX.Element => {
        return (
            <div className="search-select-field-control">
                <div className="search-select-icon">
                    <Icon iconName="search" />
                </div>
                <input
                    className="search-select-input-container--input"
                    type="text"
                    value={inputValue}
                    onChange={this.changeInputValue}
                    onFocus={this.showFilterMenu}
                    placeholder="Search"
                    name="search-select"
                    autoComplete="off"
                    spellCheck={false}
                />
                <div className="search-select-indicator" onClick={this.showSelectMenu}>
                    <InputIndicatorSvg />
                </div>
            </div>
        );
    };

    renderMenu = (menuOptions: string[], inputValue: string): JSX.Element | null => {
        const { shownMenu, selectValues } = this.state;

        const withFilterMenuOptions = menuOptions.filter((option: string) => option.toLowerCase().includes(inputValue.toLowerCase()));

        const isOptionChecked = (option: string): boolean => selectValues.includes(option);

        const multiSelectClasses = (option: string): string =>
            classNames('search-select-multi-menu--option', {
                'search-select-multi-menu--option__selected': isOptionChecked(option)
            });

        const availableFilterOption = (option: string): JSX.Element => (
            <div className={multiSelectClasses(option)} key={option}>
                <Checkbox
                    name={option}
                    label={this.filterMenuOptionFormatter(option, inputValue)}
                    isChecked={isOptionChecked(option)}
                    useCheckBoxValue={this.setMenuOption(option)}
                />
            </div>
        );
        const availableSelectOption = (option: string): JSX.Element => (
            <div className={multiSelectClasses(option)} key={option}>
                <Checkbox name={option} label={option} isChecked={isOptionChecked(option)} useCheckBoxValue={this.setMenuOption(option)} />
            </div>
        );
        const emptyOption: JSX.Element = <div className="search-select-multi-menu--option">No options</div>;

        const filterMenu: JSX.Element | null = inputValue ? (
            <div className="search-select-multi-menu">
                {withFilterMenuOptions.length ? withFilterMenuOptions.map((option: string) => availableFilterOption(option)) : emptyOption}
            </div>
        ) : null;

        const selectMenu: JSX.Element = (
            <div className="search-select-multi-menu">
                {menuOptions.length ? menuOptions.map((option: string) => availableSelectOption(option)) : emptyOption}
            </div>
        );

        switch (shownMenu) {
            case 'filterMenu':
                return filterMenu;
            case 'selectMenu':
                return selectMenu;
            default:
                return null;
        }
    };

    render() {
        const { menuOptions } = this.props;
        const { inputValue } = this.state;

        return (
            <div className="search-select">
                {this.renderSearchInput(inputValue)}
                {this.renderMenu(menuOptions, inputValue)}
            </div>
        );
    }
}

export const MultiSearchSelect = enhanceWithClickOutside(SelectWithMultiSearch);
