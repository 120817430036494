import * as React from 'react';
import isFunction from 'lodash/isFunction';
import find from 'lodash/find';
import cn from 'classnames';
import { ITableColumnProps } from '.';

interface ITRowProps {
    row: any;
    colsOrder: string[];
    selectRow: (rowKey: string | number | null) => React.ComponentState;
    handleRowWithValueClick?: any;
    withCheckboxes?: boolean;
    options?: JSX.Element | ((row: any) => void);
    disabled?: boolean;
    cols: any;
}

export class TRow extends React.PureComponent<ITRowProps> {
    handleRowClick = () =>
        Boolean(this.props.handleRowWithValueClick) ? this.props.handleRowWithValueClick(this.props.row) : this.props.selectRow(this.props.row.key);

    render() {
        const { colsOrder, handleRowWithValueClick, row, withCheckboxes, options, disabled, cols } = this.props;
        const optionsValue = isFunction(options) ? options(row) : options;

        const trClassName = cn(`tr`, { disabled, interactive: !!handleRowWithValueClick }, row.rowClass);

        return (
            <div className={trClassName} onClick={this.handleRowClick}>
                {withCheckboxes && <input type="checkbox" className="action-checkbox" />}
                {colsOrder.map((cell) => {
                    const column = find(cols, {
                        key: cell
                    }) as ITableColumnProps;
                    let classNameCell = column.classNameCell || cell;
                    if (column.alignRight) {
                        classNameCell += ' align-right';
                    }
                    if (cols.length < 5) {
                        classNameCell += ' wide-column';
                    }
                    return (
                        <div key={cell} data-t={classNameCell} className={`td ${classNameCell}`}>
                            {row[cell] && <div className="col-content">{row[cell]}</div>}
                        </div>
                    );
                })}
                {row.chipsRowComponent}
                {row.dropdownComponent}
                <div className="td options">{optionsValue || <div className="more" />}</div>
            </div>
        );
    }
}
