import Box from '@material-ui/core/Box';
import React from 'react';
import { T } from '@components/T/T';
import { SafeLink } from '@components/SafeLink/SafeLink';
import { FontWeight } from '@components/theme';

export const MagicLinkSuccessMessage = () => {
    return (
        <Box
            height={150}
            width={400}
            display="flex"
            position="absolute"
            top="30%"
            textAlign="center"
            flexDirection="column"
            justifyContent="space-evenly"
        >
            <T variant="h5" weight={FontWeight.Semibold}>
                Thank you 🙏
            </T>
            <T>Your CDR File was uploaded successfully</T>
            <T className="success-msg">
                Feel free to{' '}
                <SafeLink openTab href="http://www.clearx.io">
                    check us out
                </SafeLink>
            </T>
        </Box>
    );
};
