import { getUrlQueryParams } from '../../../../utils/urlFunctions';
import queryString from 'query-string';

export function updatePageInUrl(page: number, location: Location, history: any) {
    const queryParams = getUrlQueryParams(location);
    queryParams.page = `${page}`;
    const stringifyParams = queryString.stringify(queryParams);
    history.push(`${location.pathname}?${stringifyParams}`);
}

export function getPageFromUrl(location: Location) {
    const queryParams = getUrlQueryParams(location);
    let page;
    if (queryParams.page) {
        page = Number(queryParams.page);
    }
    return page;
}
