import { IAppAction } from './state';
import get from 'lodash/get';
import { authType, IStateAuth } from '@actions/auth';
import { requestFailType, requestSuccessType } from '@helpers/actionCreator';

const initialState: IStateAuth = {
    isChecked: false,
    isAuthenticated: false,
    passwordPolicy: null,
    token: '',
    login: {
        isFailed: false,
        isSending: false,
        isSucceeded: false,
        isTwoFactorFailed: false,
        errorType: ''
    },
    session: {
        isLoading: true,
        isChecked: false,
        lastEvent: undefined,
        expiresAt: 0
    }
};

export function authReducer(state = initialState, action: IAppAction): IStateAuth {
    const { payload } = action;
    switch (action.type) {
        case authType.ACTIVATE_USER:
        case authType.CHANGE_USER_PASSWORD:
        case authType.RESET_PASSWORD:
            return {
                ...initialState,
                isChecked: true
            };
        case authType.LOGIN_INIT:
            return { ...initialState };
        case authType.LOGIN_REQUEST:
            return {
                ...initialState,
                login: { ...initialState.login, isSending: true }
            };
        case authType.LOG_LOGOUT_EVENT:
            return {
                ...state,
                isAuthenticated: false
            };
        case authType.LOGIN_SUCCESS:
            return {
                ...state,
                isChecked: true,
                isAuthenticated: true,
                token: action.payload,
                login: {
                    ...state.login,
                    isSending: false,
                    isSucceeded: true,
                    errorType: ''
                }
            };
        case authType.LOGIN_FAIL:
            return {
                ...state,
                isChecked: true,
                isAuthenticated: false,
                login: {
                    ...state.login,
                    isSending: false,
                    isFailed: true,
                    errorType: action.errorType || get(action, 'payload.value.errorType') || 'Unknown Error'
                }
            };
        case authType.LOGIN_TWO_FACTOR_FAIL:
            return {
                ...state,
                login: {
                    ...state.login,
                    isSending: false,
                    isFailed: true,
                    isTwoFactorFailed: true
                }
            };
        case authType.LOGIN_FINISHED:
            return { ...state, login: { ...state.login, isSending: false } };
        case authType.EXTERNAL_LOGIN_REQUEST:
            return {
                ...initialState,
                login: { ...initialState.login, isSending: true }
            };
        case authType.EXTERNAL_LOGIN_SUCCESS:
            return {
                ...state,
                isChecked: true,
                isAuthenticated: true,
                login: { ...state.login, isSending: false, isSucceeded: true }
            };
        case authType.EXTERNAL_LOGIN_FAIL:
            return {
                ...state,
                login: { ...state.login, isSending: false, isFailed: true }
            };
        case authType.SESSION_STORE:
            return { ...state, token: action.payload };
        case authType.SESSION_RESTORE_SUCCESS:
            return {
                ...state,
                token: action.payload
            };
        case authType.SESSION_RESTORE_FAIL:
            return { ...state, token: '', isChecked: true, isAuthenticated: false };
        case authType.LOGOUT_FINISHED:
            return { ...initialState };
        case authType.SESSION_CHECK_REQUEST:
            return {
                ...state,
                session: { ...initialState.session, isLoading: true }
            };
        case authType.SESSION_CHECK_SUCCESS:
            return {
                ...state,
                isAuthenticated: true,
                isChecked: true,
                session: { ...state.session, isLoading: false, isChecked: true }
            };
        case authType.SESSION_CHECK_FAIL: {
            return {
                ...state,
                isAuthenticated: false,
                isChecked: true,
                session: { ...state.session, isLoading: false, isChecked: false }
            };
        }
        case authType.RECEIVE_SESSION_EVENT:
            return {
                ...state,
                session: { ...state.session, ...action.payload }
            };

        case requestSuccessType(authType.GET_PASSWORD_POLICY):
            return {
                ...state,
                passwordPolicy: payload,
                passwordPolicyFetchError: false
            };
        case requestFailType(authType.GET_PASSWORD_POLICY):
            return { ...state, passwordPolicyFetchError: payload };
        case requestSuccessType(authType.CHANGE_PASSWORD_POLICY):
            return { ...state, myLastPolicyUpdateTime: Date.now() };
        default:
            return { ...state };
    }
}
