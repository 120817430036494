import moment from 'moment';
import { periodDateFormat, partialDayFormat, utcTimeFormat, APIDateFormat, shortDateFormat, getCurrencySymbol } from '@constants/index';

const trimFloatingZeros = (value: string): string => {
    if (value.indexOf('.') === -1) {
        return value;
    }

    let cutFrom = value.length - 1;

    while (value[cutFrom] === '0') {
        cutFrom--;
    }

    if (value[cutFrom] === '.') {
        cutFrom--;
    }

    return value.substr(0, cutFrom + 1);
};

export const parseBalance = (value: string | number = ''): number => {
    const strValue = value === undefined || value === null ? '' : value.toString();
    return parseFloat(strValue.replace(/[^\d-.]/g, '')) || 0;
};

export const fixedBalance = (balance: string | number = '', fractionDigits = 2, absolute = false): string => {
    const parsedValue = parseBalance(balance);

    if (isNaN(parsedValue)) {
        return (balance || '').toString();
    }

    const maybeAbsoluteValue = absolute ? Math.abs(parsedValue) : parsedValue;
    const fixedParsedValue = maybeAbsoluteValue.toFixed(fractionDigits);
    return fixedParsedValue.toString();
};

interface IFormatBalanceOptions {
    currency?: string;
    roundToInteger?: boolean;
    actualBalance?: string | null;
    absolute?: boolean;
}

export const formatBalance = (
    balance: string | number | undefined = '',
    fractionDigits = 2,
    { currency = '', roundToInteger = false, actualBalance = null, absolute = false }: IFormatBalanceOptions = {}
): string => {
    if (actualBalance !== null && fixedBalance(actualBalance, fractionDigits) === '-0.00') {
        return '0';
    }
    const currencyPrefix = currency ? `${currency} ` : '';
    const fixedParsedValue = fixedBalance(balance, fractionDigits, absolute);
    if (fixedParsedValue === '-0.00') {
        return currencyPrefix + '0';
    }
    const replacedRE = fractionDigits ? /\d(?=(\d{3})+\.)/g : /\d(?=(\d{3})+$)/g;
    const result = currencyPrefix + fixedParsedValue.replace(replacedRE, '$&,');
    return roundToInteger ? trimFloatingZeros(result) : result;
};

export const formatPeriod = (fromSec: number, toSec: number): string => {
    if (!fromSec) {
        return '';
    }
    const mFrom = moment(fromSec * 1000);
    const mTo = moment(toSec * 1000);
    return `${mFrom.format(mFrom.year() === mTo.year() ? partialDayFormat : periodDateFormat)} - ${mTo.format(periodDateFormat)}`;
};

export const formatDate = (dateSec: number | null): string => {
    if (dateSec === null) {
        return 'N/A';
    }
    return moment.utc(dateSec * 1000).format(periodDateFormat);
};

export const formatRate = (symbol: string, str: string): string => formatBalance(str, 4, { currency: symbol });
/*
 * @param date   a db formatted date string
 */
export const formatDateString = (date: string): string => moment(date, APIDateFormat).format(periodDateFormat);

export const formatTime = (dateSec: number | null): string => {
    if (dateSec === null) {
        return 'N/A';
    }
    return moment.utc(dateSec * 1000).format(utcTimeFormat);
};

export const formatThousands = (value: string | number = ''): string => {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const capitalizeFirstLetter = (str: string) => {
    if (!str) {
        return str;
    }

    return str.charAt(0).toUpperCase() + str.slice(1);
};

const conversionDateFormat = 'YYYY-MM-DD'; // Not for display purpose - just for conversion back and forth
export const turnDateToUTC = (date: moment.Moment | null) => (date ? moment.utc(date.format(conversionDateFormat), conversionDateFormat) : date);

export const setArrayOfUniqueValues = (arr: string[]) => arr.filter((item, index) => arr && arr.indexOf(item) >= index);

export const TableViewDate = (date: number): string => moment(date * 1000).format(shortDateFormat);
