import { combineReducers } from 'redux';

import { IRateSheetParserState } from './state';
import { ratesReducer } from './rates';
import { partnersReducer } from './partners';
import { alertReducer } from './alerts';
import { logsReducer } from './logs';
import { createRSReducer } from './createRS';

export const ratesSheetParserReducer = combineReducers<IRateSheetParserState>({
    rates: ratesReducer,
    partners: partnersReducer,
    alerts: alertReducer,
    logs: logsReducer,
    createRS: createRSReducer
});
