import React, { CSSProperties } from 'react';
import ReactModal from 'react-modal';

import cn from 'classnames';

import './styles.scss';

ReactModal.setAppElement('#modalContainer');

const customStyles: ReactModal.Styles = {
    overlay: {
        zIndex: 99
    }
};

export interface IDialogProps {
    style?: Partial<CSSProperties>;
    isOpen: boolean;
    hideCloseButton?: boolean;
    classList?: string;
    headerClassName?: string;
    isDefaultHeader?: boolean;
    headerText?: React.ReactNode;
    HeaderComponent?: React.ReactElement;
    FooterComponent?: React.ReactElement;
    overlayClassName?: string;
    shouldCloseOnClickOutside?: boolean;
    onClose(): void;
}

export class Dialog extends React.Component<IDialogProps> {
    static defaultProps: Partial<IDialogProps> = {
        hideCloseButton: false,
        shouldCloseOnClickOutside: true
    };

    render() {
        const {
            hideCloseButton,
            onClose,
            isOpen,
            children,
            classList,
            isDefaultHeader,
            HeaderComponent,
            FooterComponent,
            headerText,
            headerClassName,
            overlayClassName,
            shouldCloseOnClickOutside,
            style
        } = this.props;
        const isHeaderExists = isDefaultHeader || HeaderComponent;

        let Header = null;
        if (HeaderComponent) {
            Header = HeaderComponent;
        } else if (isDefaultHeader) {
            Header = <div className={cn('modal-top-header', headerClassName)}>{headerText}</div>;
        }

        const Footer = FooterComponent || null;

        const modalStyle: ReactModal.Styles = {
            ...customStyles,
            content: {
                ...customStyles?.content,
                ...style
            }
        };
        return (
            <ReactModal
                isOpen={isOpen}
                contentLabel="modal"
                overlayClassName={cn('dialog-overlay', overlayClassName)}
                className={cn('dialog-content', classList, isHeaderExists ? 'with-header' : null)}
                style={modalStyle}
                onRequestClose={onClose}
                shouldCloseOnOverlayClick={shouldCloseOnClickOutside}
            >
                {Header}
                {hideCloseButton ? null : <button className="close btn-close" onClick={onClose} />}
                {children}
                {Footer}
            </ReactModal>
        );
    }
}
