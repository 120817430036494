import { formatBalance } from '@helpers/formaters';
import { createSelector } from 'reselect';
import get from 'lodash/get';
import { getCurrencySymbol } from '@constants';
import { agreementsSelector, getAgreementParamsFromUrl, getCurrentInfo } from '@selectors/roaming';
import { getLocationObject } from '@selectors/router';
import uniqBy from 'lodash/uniqBy';
import filter from 'lodash/filter';
import maxBy from 'lodash/maxBy';
import { BillingStatement, BillingStatementType } from '@clearblockchain/shared/billing-statement';
import { Currency } from '@helpers/currency';

function getDiscountCalculationTotal(total: any, currencyCode: string) {
    return {
        ...total,
        monthlyDiscountedCharges: new Currency(total.monthlyDiscountedCharges, currencyCode).localString,
        discount: total.discount ? new Currency(total.discount, currencyCode).localString : null,
        discountedCharges: new Currency(total.discountedCharges, currencyCode).localString,
        tapCharges: new Currency(total.tapCharges, currencyCode).localString,
        usage: '',
        contractDiscountedCharges: new Currency(total.contractDiscountedCharges, currencyCode).localString,
        externalDiscountedCharges: new Currency(total.externalDiscountedCharges, currencyCode).localString,
        preCommitmentDiscountedCharges: new Currency(total.preCommitmentDiscountedCharges, currencyCode).localString,
        postCommitmentDiscountedCharges: total.postCommitmentDiscountedCharges
            ? new Currency(total.postCommitmentDiscountedCharges, currencyCode).toLocalString(4)
            : null,
        revenueCommitment: new Currency(total.revenueCommitment, currencyCode).localString,
        shortfall: total.shortfall ? new Currency(total.shortfall, currencyCode).localString : '-',
        includedChargesInCommitment: new Currency(total.includedChargesInCommitment, currencyCode).localString
    };
}

function getDiscountCalculationProductSummaries(inboundData: any, currencyCode: string) {
    return inboundData.productSummaries.map((item: any) => {
        let extraInfo = item.extraInfo;
        let displayName = item.displayName;
        if (item.productKey === 'shortfall') {
            displayName = `Revenue Comm. (${new Currency(extraInfo, currencyCode).localString}) Shortfall`;
            extraInfo = null;
        } else if (item.productKey === 'cdlshortfall') {
            displayName = `Cross Discount Letter Comm. (${new Currency(extraInfo, currencyCode).localString}) Shortfall`;
            extraInfo = null;
        }
        return {
            ...item,
            tierName: createTierNameToDisplay(item.tierName),
            displayName,
            extraInfo,
            usage: item.usage ? formatBalance(item.usage, 2, { roundToInteger: true }) : '',
            monthlyDiscountedCharges: new Currency(item.monthlyDiscountedCharges, currencyCode).localString,
            monthlyUsage: formatBalance(item.monthlyUsage, 2),
            monthlyAvgRate: item.monthlyAvgRate ? new Currency(item.monthlyAvgRate, currencyCode).toLocalString(4) : null,
            avgTapRate: item.avgTapRate ? new Currency(item.avgTapRate, currencyCode).toLocalString(4) : null,
            tapCharges: item.tapCharges ? new Currency(item.tapCharges, currencyCode).localString : null,
            preCommitmentRate: item.preCommitmentRate ? new Currency(item.preCommitmentRate, currencyCode).toLocalString(4) : null,
            postCommitmentRate: item.postCommitmentRate ? new Currency(item.postCommitmentRate, currencyCode).toLocalString(4) : null,
            discountedCharges: item.discountedCharges ? new Currency(item.discountedCharges, currencyCode).localString : null,
            preCommitmentDiscountedCharges: item.preCommitmentDiscountedCharges
                ? new Currency(item.preCommitmentDiscountedCharges, currencyCode).localString
                : null,
            discount: item.discount ? new Currency(item.discount, currencyCode).localString : null,
            baselines: item.baselines ? formatBaselines(item.baselines, currencyCode) : null,
            includedProducts: item.includedProducts ? formatProducts(item.includedProducts, currencyCode) : null,
            includedChargesInCommitment: item.revCommIncluded ? 'Yes' : ''
        };
    });
}

const getLastPeriodIndexByStatements = (statements: BillingStatement[]): number => {
    const eoyStatement = statements.filter((s) => s.type === BillingStatementType.EndOfYearDiscount);
    return eoyStatement[0]?.index;
};

const getDiscountData = createSelector(
    agreementsSelector,
    getLocationObject,
    getAgreementParamsFromUrl,
    getCurrentInfo,
    (agreementsState, location, agreementParams, { isMyContract, terms }): any => {
        const { id: contractId } = agreementParams;
        const subContractIndex = location.query.subContractIndex || 0;
        const currency = getCurrencySymbol(terms.currency);
        const parties = get(terms, `commercialTerms.parties.${subContractIndex}`);
        const logSubmitted = get(terms, 'logSubmitted');
        const details = get(terms, 'commercialTerms.details');
        if (!details) {
            return { subContractIndex };
        }

        const contractBillingStatements = uniqBy(
            filter<BillingStatement>(agreementsState.billingStatements, { contractId }),
            'id'
        );

        let breakdownMonthIndex = agreementsState.breakdownMonthIndex;
        if (!breakdownMonthIndex && details && details.calculationType === 'Monthly') {
            breakdownMonthIndex = 0;
        } else if (!breakdownMonthIndex && details && details.calculationType === 'Yearly') {
            breakdownMonthIndex = getLastPeriodIndexByStatements(contractBillingStatements);
        }

        // check that we do not have few billing statements with the same index and if so choosing the last calculated one
        const allBillingStatementsWithRelevantMonth = contractBillingStatements.filter((statement) => statement.index === breakdownMonthIndex);
        const billingStatement =
            allBillingStatementsWithRelevantMonth.length > 1
                ? maxBy(allBillingStatementsWithRelevantMonth, 'createDate')
                : allBillingStatementsWithRelevantMonth[0];

        return {
            currency,
            parties,
            logSubmitted,
            billingStatement,
            subContractIndex
        };
    }
);

export const getDiscountCalculation = createSelector(getDiscountData, getCurrentInfo, (discountData, currentInfo): any => {
    const { isMyContract, terms } = currentInfo;
    const { currency, parties, logSubmitted, billingStatement, subContractIndex } = discountData;
    const details = get(terms, 'commercialTerms.details');
    if (!details) {
        return { subContractIndex };
    }
    const settlementResult = get(billingStatement, 'settlementResults.result');
    if (!settlementResult) {
        return { subContractIndex };
    }
    const balanceIndex = isMyContract ? { me: '0', counter: '1' } : { me: '1', counter: '0' };
    const multiplier = isMyContract ? 1 : -1;

    const out = settlementResult.balances
        ? {
              ...settlementResult,
              parties,
              currency,
              logSubmitted,
              me: settlementResult.balances[balanceIndex.me] * multiplier,
              counter: settlementResult.balances[balanceIndex.counter] * multiplier,
              calculationType: details.calculationType
          }
        : settlementResult;

    return {
        ...out,
        subContractIndex
    };
});

export const getDiscountCalculationCounter = createSelector(getDiscountData, getCurrentInfo, (discountData, currentInfo): any => {
    const { isMyContract, terms } = currentInfo;
    const { currency, parties, logSubmitted, billingStatement } = discountData;
    const details = get(terms, 'commercialTerms.details');
    if (!details) {
        return null;
    }
    const settlementResult = get(billingStatement, 'settlementResults.result');
    if (!settlementResult) {
        return { settlementResult };
    }
    const balanceIndex = !isMyContract ? { me: '0', counter: '1' } : { me: '1', counter: '0' };
    const multiplier = isMyContract ? 1 : -1;

    if (settlementResult.balances) {
        return {
            ...settlementResult,
            parties,
            currency,
            logSubmitted,
            me: settlementResult.balances[balanceIndex.me] * multiplier,
            counter: settlementResult.balances[balanceIndex.counter] * multiplier,
            calculationType: details.calculationType
        };
    }
    return settlementResult;
});

export const getDiscountSettlement = createSelector([getDiscountCalculation, getCurrentInfo], (discount, currentInfo) => {
    const dataKey = currentInfo.isMyContract ? 'p1Data' : 'p2Data';
    const subContractIndex = discount ? discount.subContractIndex : 0;
    const discountSettlement = get(discount, `${dataKey}.discountSettlements.${subContractIndex}`);
    if (!discountSettlement) {
        return null;
    }

    const outboundData = currentInfo.isMyContract ? discountSettlement.p1HPMN : discountSettlement.p2HPMN;
    const inboundData = currentInfo.isMyContract ? discountSettlement.p2HPMN : discountSettlement.p1HPMN;

    const currencyCode = currentInfo.terms.currency;
    return {
        inboundTotal: getDiscountCalculationTotal(inboundData.total, currencyCode),
        inbound: getDiscountCalculationProductSummaries(inboundData, currencyCode),
        outboundTotal: getDiscountCalculationTotal(outboundData.total, currencyCode),
        outbound: getDiscountCalculationProductSummaries(outboundData, currencyCode)
    };
});

export const getDiscountSettlementCounter = createSelector([getDiscountCalculation, getCurrentInfo], (discount, currentInfo) => {
    const dataKey = !currentInfo.isMyContract ? 'p1Data' : 'p2Data';
    const subContractIndex = discount ? discount.subContractIndex : 0;
    const discountSettlement = get(discount, `${dataKey}.discountSettlements.${subContractIndex}`);
    if (!discountSettlement) {
        return null;
    }

    const outboundData = currentInfo.isMyContract ? discountSettlement.p1HPMN : discountSettlement.p2HPMN;
    const inboundData = currentInfo.isMyContract ? discountSettlement.p2HPMN : discountSettlement.p1HPMN;

    const currencyCode = currentInfo.terms.currency;

    return {
        inboundTotal: getDiscountCalculationTotal(inboundData.total, currencyCode),
        inbound: getDiscountCalculationProductSummaries(inboundData, currencyCode),
        outboundTotal: getDiscountCalculationTotal(outboundData.total, currencyCode),
        outbound: getDiscountCalculationProductSummaries(outboundData, currencyCode)
    };
});

const formatBaselines = (baselines: [], currencyCode: string) =>
    baselines.map((item: any) => {
        const from = formatBalance(item.from, 0);
        return {
            ...item,
            discountedCharges: new Currency(item.discountedCharges, currencyCode).localString,
            preCommitmentDiscountedCharges: new Currency(item.preCommitmentDiscountedCharges, currencyCode).localString,
            from: formatBalance(item.from, 0),
            postCommitmentRate: new Currency(item.postCommitmentRate, currencyCode).toLocalString(4),
            preCommitmentRate: new Currency(item.preCommitmentRate, currencyCode).toLocalString(4),
            to: item.to === 'Infinity' ? 'Above' : formatBalance(item.to, 0),
            threshold: item.to === 'Infinity' ? `> ${from}` : `${from} - ${formatBalance(item.to, 0)}`,
            usage: formatBalance(item.usage, 2, { roundToInteger: true }),
            monthlyUsage: formatBalance(item.monthlyUsage, 2, { roundToInteger: true }),
            monthlyDiscountedCharges: new Currency(item.monthlyDiscountedCharges, currencyCode).localString,
            monthlyAvgRate: item.monthlyAvgRate ? new Currency(item.monthlyAvgRate, currencyCode).toLocalString(4) : null
        };
    });
const formatProducts = (includedProducts: [], currencyCode: string) =>
    includedProducts.map((item: any) => {
        return {
            ...item,
            avgTapRate: item.avgTapRate ? new Currency(item.avgTapRate, currencyCode).toLocalString(4) : null,
            discount: item.discount ? new Currency(item.discount, currencyCode).localString : null,
            discountedCharges: new Currency(item.discountedCharges, currencyCode).localString,
            preCommitmentDiscountedCharges: new Currency(item.preCommitmentDiscountedCharges, currencyCode).localString,
            monthlyDiscountedCharges: new Currency(item.monthlyDiscountedCharges, currencyCode).localString,
            tapCharges: item.tapCharges ? new Currency(item.tapCharges, currencyCode).localString : null,
            usage: formatBalance(item.usage, 2, { roundToInteger: true }),
            monthlyUsage: formatBalance(item.monthlyUsage, 2, { roundToInteger: true }),
            monthlyAvgRate: item.monthlyAvgRate ? new Currency(item.monthlyAvgRate, currencyCode).localString : null
        };
    });

const createTierNameToDisplay = (tierName: string): string => {
    let tierNameToUse = tierName;
    if (tierName.toLowerCase().startsWith('total')) {
        const tier = tierName.replace(/^total\s*/i, '');
        tierNameToUse = `${tier} (Total)`;
    }
    return tierNameToUse;
};
