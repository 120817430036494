import { createRequestAction } from '@helpers/actionCreator';

export enum peerType {
    PEERS_GET_ALL_REQUEST = 'PEERS:GET:ALL::REQUEST',
    PEERS_GET_ALL_SUCCESS = 'PEERS:GET:ALL::REQUEST:SUCCESS',
    PEERS_GET_ALL_FAIL = 'PEERS:GET:ALL::REQUEST:FAIL',
    GET_PEER_FEATURES = 'PEERS:GET_PEER_FEATURES'
}

export interface IPeer {
    rpcAddresses: string[];
    name: string;
    features: any;
    id: string;
    gethAddress: string;
    serializedAcct: {
        keyTypeName: string;
        address: string;
    };
    isPaymentGateway: boolean;
    isDeleted: boolean;
    updatedAt: number;
    version: string;
}

export interface IStatePeer {
    id: string;
    name: string;
    peers: IPeer[];
}

const peerActions = {
    peerGetAllRequest: createRequestAction(peerType.PEERS_GET_ALL_REQUEST, 'getAllPeers'),
    getPeerFeatures: createRequestAction(peerType.GET_PEER_FEATURES, 'getPeerFeatures', (peerId: string) => [peerId])
};

export default peerActions;
