export class LocalStorage {
    static set(key: string, value: any): void {
        const stringyfiedValue = JSON.stringify(value);
        window.localStorage.setItem(key, stringyfiedValue);
    }

    static get(key: string): any {
        const rawValue = window.localStorage.getItem(key) || '';

        try {
            return JSON.parse(rawValue);
        } catch (e) {
            return null;
        }
    }

    static remove(key: string = ''): void {
        window.localStorage.removeItem(key);
    }
}
