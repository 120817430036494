import * as React from 'react';
import classNames from 'classnames';

import { Icon } from '@components/Icon';

import MaskedInput from 'react-text-mask';

import createNumberMask from 'text-mask-addons/dist/createNumberMask';

import { formatBalance } from '@helpers/formaters';

const numberMask = createNumberMask({
    prefix: '',
    suffix: '',
    allowDecimal: true,
    allowNegative: true,
    decimalLimit: 3
});

export const BalanceField = ({
    input,
    label,
    disabled,
    icon,
    iconFont,
    classes,
    max,
    meta: { warning, error, invalid, active, touched = true },
    type = 'text',
    placeholder
}: any) => {
    const currentError = touched && invalid && error;
    const fieldClasses = classNames(`field field-${type} field-${input.name}`, classes, {
        disabled,
        'has-error': error || warning,
        'has-warning': warning
    });
    const fieldInputClasses = classNames({ 'is-active': active });

    return (
        <div className={fieldClasses}>
            <label htmlFor={input.name}>{label}</label>
            <MaskedInput
                mask={numberMask}
                {...input}
                value={input.value}
                disabled={disabled}
                type={type}
                id={input.name}
                className={fieldInputClasses}
                placeholder={Boolean(input.value) ? placeholder : 0}
            />

            {Boolean(icon) && <Icon iconName={icon} />}
            {Boolean(iconFont) && <i>{iconFont}</i>}
            {Boolean(max) && <div className="max">{formatBalance(max)}</div>}
            <div className="error-message">{currentError}</div>
        </div>
    );
};
