import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { RouteComponentProps } from 'react-router';
import get from 'lodash/get';
import CalenderIcon from '@material-ui/icons/CalendarTodayOutlined';
import FileIcon from '@material-ui/icons/InsertDriveFileOutlined';
import { getPeerPresentedName, getUserName } from '@selectors/account';
import { getMagicLink } from '@selectors/user';
import { getExternalPeriods, getFileDialogInfo, getIsFileUploading } from '@selectors/voice';
import { isAuthenticated } from '@selectors/auth';
import voiceActions from '@actions/voice';
import accountActions from '@actions/account';
import authActions from '@actions/auth';
import usersActions from '@actions/users';
import { Dialog } from '@components/Modal/Dialog';
import { formatPeriod } from '@helpers/formaters';
import queryString from 'query-string';
import { Box, createStyles, Theme } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import { MagicLinkSuccessMessage } from '@modules/Voice/containers/ExternalUploadContainer/components/MagicLinkSuccessMessage';
import { AboutUs } from '@modules/Voice/containers/ExternalUploadContainer/components/AboutUs';
import { FileUploader } from '@modules/Voice/containers/ExternalUploadContainer/components/FileUploader';
import { ExpirationDetails } from '@modules/Voice/containers/ExternalUploadContainer/components/ExpirationDetails';
import { MultiText } from '@components/Text/MultiText';
import { T } from '@components/T/T';
import { useActions } from '../../../../hooks/useAction';
import { useSelectors } from '../../../../hooks/useSelectors';
import { useDispatch } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { IconLabel } from '@components/IconLabel/IconLabel';
import { FontWeight } from '@components/theme';

interface IParams {
    hash: string;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            backgroundColor: '#fafafa',
            minHeight: '100vh',
            backgroundImage: 'url(/assets/images/earth.png)',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'bottom right',
            border: '#dfdfdf 1px solid',
            borderWidth: '0 1px'
        }
    })
);

export const ExternalUploadContainer: React.FC<RouteComponentProps<IParams>> = ({ match, location }) => {
    const magicLink = decodeURIComponent(match.params.hash);

    const classes = useStyles();

    const [
        userName,
        settlement,
        authenticated,
        { isFileDialogOpen, fileDialogMessage },
        isFileUploading,
        { wasUsed: wasMagicLinkUsed },
        peerPresentedName
    ] = useSelectors([getUserName, getExternalPeriods, isAuthenticated, getFileDialogInfo, getIsFileUploading, getMagicLink, getPeerPresentedName]);

    const [requestLogin, getPeername, getUsername, getInfoRequest, getSettlementPeriod, closeFileDialog] = useActions([
        authActions.externalLoginRequest,
        accountActions.getPeername,
        accountActions.getUsername,
        accountActions.getInfoRequest,
        voiceActions.voiceGetSettlementPeriod,
        voiceActions.voiceFileDialogClose
    ]);

    const dispatch = useDispatch();
    useEffect(() => {
        requestLogin(magicLink);
    }, []);

    useEffect(() => {
        if (authenticated && !isFileUploading) {
            getPeername();
            getUsername();
            getInfoRequest();
            getSettlementPeriod(magicLink);
        }
    }, [authenticated, isFileUploading]);

    useEffect(() => {
        if (settlement && settlement.id) {
            dispatch(usersActions.getMagicLink((settlement.id as unknown) as string, magicLink));
        }
    }, [settlement]);

    const partnerMsg = getMsgText();

    const name = get(settlement, 'accountPresentedName', userName);

    if (!authenticated) {
        return null;
    }
    return (
        <Container maxWidth="lg" className={classes.root}>
            <Helmet title="Clear Voice - Upload CDR" />
            <Box mb={10.5} textAlign="center">
                <Logo />
            </Box>
            {wasMagicLinkUsed ? (
                <MagicLinkSuccessMessage />
            ) : (
                <>
                    <Box mb={8} clone>
                        <Greeting name={name} />
                    </Box>
                    <Box mb={37}>
                        <Grid container>
                            <Grid item xs={12} md={6}>
                                <Box>
                                    <Box mb={2}>
                                        <MultiText display="inline">
                                            {'Your partner, '}
                                            <T weight={FontWeight.Semibold} display="inline">
                                                {peerPresentedName}
                                            </T>
                                            {', requested your CDR file for the following settlement period:'}
                                        </MultiText>
                                    </Box>
                                    <IconLabel icon={<CalenderIcon />}>
                                        <T>{getPeriod(settlement)}</T>
                                    </IconLabel>
                                    {partnerMsg && (
                                        <Box my={10} clone>
                                            <PersonalMsg sender={peerPresentedName} msg={partnerMsg} />
                                        </Box>
                                    )}
                                    <Box mb={4}>
                                        <Explanation />
                                    </Box>
                                    <Box mb={1} clone>
                                        <ExpirationDetails />
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xs={6}>
                                <Box textAlign="center" py={2} clone>
                                    <FileUploader magicLink={magicLink} />
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                    <Box mb={1} clone>
                        <AboutUs />
                    </Box>
                </>
            )}
            <Dialog isOpen={isFileDialogOpen} classList="voice-uploads" onClose={closeFileDialog}>
                <div className="new-rsp-module__dialog-message">{fileDialogMessage}</div>
            </Dialog>
        </Container>
    );
};

const Explanation: React.FC = () => (
    <>
        <IconLabel icon={<FileIcon />}>
            <T variant="caption">Preferable CDR structure: comma delimited with these fields:</T>
        </IconLabel>
        <Box ml={6.5}>
            <MultiText variant="caption" display="block">
                {`A Number (real, full number, only digits), B Number (real, full number, only digits),`}
                {`Call start time (YYYY-MM-DD HH:ii:ss),`}
                {`Duration(seconds), Direction (In/Out),`}
                {`Destination(country + operator, in English), Rate, Amount, Currency`}
            </MultiText>
        </Box>
    </>
);

const Greeting: React.FC<{ name: string }> = ({ name, ...props }) => <T variant={'subtitle1'} {...props}>{`Hello ${name} 👋`}</T>;

const Logo: React.FC = () => (
    <Box width={'58px !important'} clone>
        <img alt="clear" src={'/assets/svg/clearLogoBlue.svg'} />
    </Box>
);

const PersonalMsg: React.FC<{ sender: string; msg: string }> = ({ sender, msg, ...props }) => {
    return (
        <div {...props}>
            <Box pl={3} mb={1} clone>
                <T fontStyle="italic">{`Personal message from ${sender}:`}</T>
            </Box>
            <Box p={3} borderRadius={4} maxWidth={440} bgcolor="rgba(0, 0, 0, 0.05)">
                <T>{msg}</T>
            </Box>
        </div>
    );
};

const parsedParams = () => queryString.parse(location.search);
const fromBase64 = (str64: string) => {
    return Buffer.from(str64, 'base64').toString();
};
const getMsgText = () => {
    const { t: based64Text } = parsedParams();
    return based64Text ? fromBase64(based64Text as string) : '';
};

const getPeriod = ({ startDate, endDate }: any = {}) => formatPeriod(startDate, endDate);
