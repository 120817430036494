import * as React from 'react';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import './styles.scss';
import { getTabNumberFromUrl, setUrlQueryParam } from '../../utils/urlFunctions';
import get from 'lodash/get';
import { getUrlParam } from '@helpers/urlUtils';
import { history } from 'main';

interface ITabsProps {
    children: JSX.Element[];
    action?: JSX.Element;
    tabTop?: JSX.Element | null;
    common?: JSX.Element;
    onChange?: (chosenTabIndex: number) => void;
    alwaysRender?: boolean;
    className?: string;
    withLinks?: string[];
    URLParamName?: string;
    onClick?: any;
}

interface ITabsState {
    currentTab: number;
}

export interface ITabProps {
    tabLabel: React.ReactNode;
}

type ITabLabelComponent = ITabProps &
    Record<string, any> & {
        Component?: keyof React.ReactHTML;
        children: React.ReactNode;
    };

export const TabLabelComponent = ({ tabLabel, Component = 'div', children, ...rest }: ITabLabelComponent) => {
    return <Component {...rest}>{children}</Component>;
};

export class Tabs extends React.PureComponent<ITabsProps, ITabsState> {
    constructor(props: ITabsProps) {
        super(props);
        const { location } = window;
        const { URLParamName } = props;
        let currentTab = 0;
        if (location) {
            currentTab = (URLParamName ? parseInt(getUrlParam(location.search, URLParamName) || 0, 10) : getTabNumberFromUrl(location)) || 0; // TODO - remove this voice coupling abomination
        }
        this.state = { currentTab };
    }

    componentDidUpdate(prevProps: Readonly<ITabsProps>): void {
        const { location } = window;
        const { URLParamName } = this.props;
        const newTab = (URLParamName ? parseInt(getUrlParam(location!.search, URLParamName) || 0, 10) : getTabNumberFromUrl(location)) || 0;
        const currentTab = this.state.currentTab;
        if (newTab !== currentTab) {
            this.setState({ currentTab: newTab });
        }
    }

    switchTab = (tabIndex: number): React.ComponentState => {
        const { onChange, URLParamName } = this.props;
        const { location } = window;
        if (onChange) {
            onChange(tabIndex);
        }
        if (location && URLParamName) {
            setUrlQueryParam(location, URLParamName, tabIndex, history, true);
        }

        if (this.props.onClick) {
            const tabLabel = this.props.children[tabIndex] ? this.props.children[tabIndex].props.tabLabel : '';
            this.props.onClick(tabLabel.toLowerCase());
        }
    };

    getActiveTab = (): [number, JSX.Element] => {
        const { currentTab } = this.state;
        return [currentTab, this.props.children[currentTab]];
    };
    renderTabsLabels = () =>
        React.Children.map(this.props.children, (tab: JSX.Element, tabIndex: number) => {
            if (!tab) {
                return null;
            }
            const renderSingleTabLabel = (
                <li
                    key={tabIndex}
                    className={cn('tabs-label', { 'is-active': this.state.currentTab === tabIndex }, { 'is-disabled': tab.props.disabled })}
                    onClick={this.switchTab.bind(this, tabIndex)}
                >
                    {tab.props.tabLabel}
                </li>
            );

            return this.props.withLinks ? <Link to={this.props.withLinks[tabIndex]}>{renderSingleTabLabel}</Link> : renderSingleTabLabel;
        });

    renderTabContent = (): JSX.Element[] | JSX.Element => {
        if (this.props.alwaysRender) {
            return React.Children.map(
                this.props.children,
                (child: JSX.Element, index: number): JSX.Element => {
                    const isActive = index === this.state.currentTab;
                    return (
                        <div key={index} className={isActive ? 'is-active' : 'is-inactive'} style={!isActive ? { display: 'none' } : {}}>
                            {child}
                        </div>
                    );
                }
            );
        }

        return this.props.children[this.state.currentTab];
    };

    render() {
        const { currentTab } = this.state;
        const { children, className, action, common } = this.props;

        return (
            <div className={cn('tabs', className, get(children, [currentTab, 'key']))}>
                {this.props.tabTop}
                <ul className="tabs-labels">
                    {this.renderTabsLabels()}
                    {action}
                </ul>
                {common}
                <div className="tabs-content">{this.renderTabContent()}</div>
            </div>
        );
    }
}
