import { IAppAction } from './state';
import { SettingsActionType } from '@actions/settings';
import { requestSuccessType } from '@helpers/actionCreator';

// TODO: put Currency in @clearblockchain/shared and use universally
export interface ICurrency {
    name: string;
    code: string;
    symbol: string;
}

export interface ISettingsState {
    localCurrency: string;
    supportedCurrencies: ICurrency[];
    countryMappingId: string;
}

const initialState = {
    localCurrency: '',
    supportedCurrencies: [],
    countryMappingId: ''
};

export function settingsReducer(state: ISettingsState = initialState, { type, payload, requestAction }: IAppAction): ISettingsState {
    switch (type) {
        case SettingsActionType.GET_SETTINGS_SUCCESS: {
            return { ...state, ...payload };
        }
        case SettingsActionType.SET_LOCAL_CURRENCY_SUCCESS: {
            return {
                ...state,
                localCurrency: payload
            };
        }

        case requestSuccessType(SettingsActionType.SET_COUNTRY_MAPPING_ID): {
            const countryMap = requestAction.payload.values[0];
            return {
                ...state,
                countryMappingId: countryMap
            };
        }

        case SettingsActionType.GET_SUPPORTED_CURRENCIES_SUCCESS: {
            return { ...state, supportedCurrencies: payload };
        }
        default:
            return state;
    }
}
