import { createAction, createRequestAction } from '@helpers/actionCreator';
import { IUserData } from './users';
import { IPasswordPolicy } from '@clear/password-policy';

export enum authType {
    LOGIN_INIT = 'LOGIN::INIT',
    LOGIN_REQUEST = 'LOGIN::REQUEST',
    LOGIN_TWO_FACTOR_FAIL = 'LOGIN::REQUEST:TWO:FACTOR:FAIL',
    LOGIN_SUCCESS = 'LOGIN::REQUEST:SUCCESS',
    LOGIN_FAIL = 'LOGIN::REQUEST:FAIL',
    LOGIN_FINISHED = 'LOGIN::REQUEST:FINISHED',
    LOGOUT_REQUEST = 'LOGOUT::REQUEST',
    LOGOUT_FINISHED = 'LOGOUT::REQUEST:FINISHED',
    EXTERNAL_LOGIN_REQUEST = 'EXTERNAL:LOGIN::REQUEST',
    EXTERNAL_LOGIN_SUCCESS = 'EXTERNAL:LOGIN::REQUEST:SUCCESS',
    EXTERNAL_LOGIN_FAIL = 'EXTERNAL:LOGIN::REQUEST:FAIL',
    SESSION_STORE = 'SESSION::STORE',
    SESSION_RESTORE_SUCCESS = 'SESSION::RESTORE:SUCCESS',
    SESSION_RESTORE_FAIL = 'SESSION::RESTORE:FAIL',
    SESSION_CHECK_REQUEST = 'SESSION::CHECK::REQUEST',
    SESSION_CHECK_SUCCESS = 'SESSION::CHECK::REQUEST:SUCCESS',
    SESSION_CHECK_FAIL = 'SESSION::CHECK::REQUEST:FAIL',
    SESSION_CHECK_FINISHED = 'SESSION::CHECK::REQUEST:FINISHED',
    LOG_LOGOUT_EVENT = 'UI:LOG:LOGOUT:EVENT::REQUEST',
    LOG_LOGOUT_EVENT_FAIL = 'UI:LOG:LOGOUT:EVENT::REQUEST:FAIL',
    LOG_LOGOUT_EVENT_SUCCESS = 'UI:LOG:LOGOUT:EVENT::REQUEST:SUCCESS',

    CREATE_USER = 'AUTH:SPIN:CREATE_USER',

    CHANGE_PASSWORD_POLICY = 'CHANGE:SPIN:PASSWORD_POLICY',
    GET_PASSWORD_POLICY = 'GET:PASSWORD_POLICY',

    CHANGE_USER_PASSWORD = 'CHANGE_USER_PASSWORD::REQUEST',
    CHANGE_USER_PASSWORD_SUCCESS = 'CHANGE_USER_PASSWORD::REQUEST:SUCCESS',
    CHANGE_USER_PASSWORD_FAIL = 'CHANGE_USER_PASSWORD::REQUEST:FAIL',

    ACTIVATE_USER = 'ACTIVATE_USER::REQUEST',
    ACTIVATE_USER_SUCCESS = 'ACTIVATE_USER::REQUEST:SUCCESS',
    ACTIVATE_USER_FAIL = 'ACTIVATE_USER::REQUEST:FAIL',

    RESET_PASSWORD = 'RESET_PASSWORD::REQUEST',
    RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD::REQUEST:SUCCESS',
    RESET_PASSWORD_FAIL = 'RESET_PASSWORD::REQUEST:FAIL',

    RECEIVE_SESSION_EVENT = 'RECEIVE_SESSION_EVENT'
}

export type CreateUserArgs = Omit<IUserData, 'id'>;

export interface IStateAuth {
    isChecked: boolean;
    isAuthenticated: boolean;
    token: string;
    login: {
        isSending: boolean;
        isFailed: boolean;
        isSucceeded: boolean;
        isTwoFactorFailed: boolean;
        errorType: string;
    };
    passwordPolicy: IPasswordPolicy | null;
    myLastPolicyUpdateTime?: number;
    passwordPolicyFetchError?: any;
    session: {
        isLoading: boolean;
        isChecked: boolean;
        lastEvent?: SessionEvent;
        expiresAt: number;
    };
}

export const LOGIN_REDIRECT = 'redirect';
const authActions = {
    loginInit: createAction(authType.LOGIN_INIT),
    loginRequest: createRequestAction(authType.LOGIN_REQUEST, 'login', (email: string, password: string, mfa?: string) => [email, password, mfa]),
    loginRequestFail: createAction(authType.LOGIN_FAIL),
    loginRequestFinished: createAction(authType.LOGIN_FINISHED),
    loginRequestSuccess: createAction(authType.LOGIN_SUCCESS),
    loginRequestTwoFactorFail: createAction(authType.LOGIN_TWO_FACTOR_FAIL),
    externalLoginRequest: createRequestAction(authType.EXTERNAL_LOGIN_REQUEST, 'login', (link: string) => [link]),
    externalLoginRequestSuccess: createAction(authType.EXTERNAL_LOGIN_SUCCESS),
    externalLoginRequestFail: createAction(authType.EXTERNAL_LOGIN_FAIL),
    sessionStore: createAction(authType.SESSION_STORE),
    sessionRestoreSuccess: createAction(authType.SESSION_RESTORE_SUCCESS),
    sessionRestoreFail: createAction(authType.SESSION_RESTORE_FAIL),
    getPasswordPolicy: createRequestAction(authType.GET_PASSWORD_POLICY, 'getPasswordPolicy'),
    setPasswordPolicy: createRequestAction(authType.CHANGE_PASSWORD_POLICY, 'setPasswordPolicy', (passwordPolicy, hardReset: boolean) => [
        passwordPolicy,
        hardReset
    ]),
    logoutRequest: createRequestAction(authType.LOGOUT_REQUEST, 'logout', (token: string) => [token]),
    sessionCheckRequest: createRequestAction(authType.SESSION_CHECK_REQUEST, 'isAuthenticated'),
    logLogoutEvent: createRequestAction(authType.LOG_LOGOUT_EVENT, 'log', (manualLogout: boolean, lastPage: string) => [
        {
            message: 'User logged out on frontend.',
            logoutType: manualLogout ? 'manual' : 'automatic',
            lastPage
        }
    ]),
    createUser: createRequestAction(authType.CREATE_USER, 'createUser', ({ username, isActive, groupNames, tadigCodes }: CreateUserArgs) => [
        username,
        '',
        '',
        '',
        '', // Password
        groupNames ? ([] as string[]).concat(groupNames) : [],
        tadigCodes ? { tadigCodes } : {},
        false, // mfa
        isActive
    ]),
    activateUser: createRequestAction(authType.ACTIVATE_USER, 'activateUser', ({ userId, password, mfa, hash }) => [userId, password, mfa, hash]),
    resetPassword: createRequestAction(authType.RESET_PASSWORD, 'resetPassword', ({ userId, password, hash }) => [userId, password, hash]),
    changePassword: createRequestAction(authType.CHANGE_USER_PASSWORD, 'changePassword', ({ username, oldPassword, password }) => [
        username,
        oldPassword,
        password
    ]),
    receiveSessionEvent: createAction(authType.RECEIVE_SESSION_EVENT, {
        payload: (payload: { lastEvent: SessionEvent; expiresAt: number }) => payload
    })
};

export enum SessionEvent {
    Expired = 'EXPIRED',
    Replaced = 'REPLACED',
    Warning = 'WARNING'
}

export default authActions;
