import * as React from 'react';

import { Dropdown } from '@components/Buttons';
import { Icon } from '@components/Icon';

export class Header extends React.Component<{
    withActions?: boolean;
    withCheckboxes?: boolean;
}> {
    render() {
        return (
            <div className="header">
                {this.props.withCheckboxes && <input type="checkbox" className="action-checkbox" />}
                {this.props.withActions && (
                    <Dropdown title="Actions" classList="action-menu" inline>
                        <span>
                            <Icon iconName="print" />
                            <span>Remove</span>
                        </span>
                        <span>
                            <Icon iconName="clock" />
                            <span>Delete</span>
                        </span>
                        <span>
                            <Icon iconName="download2" />
                            <span>Assign to Tech Team</span>
                        </span>
                        <span>
                            <Icon iconName="forward" />
                            <span>Send to Vendor</span>
                        </span>
                    </Dropdown>
                )}
                <div className="pager">
                    <span className="all" />
                    <span className="prev" />
                    <span className="next" />
                </div>
            </div>
        );
    }
}
