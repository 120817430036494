import { takeLatest, put } from 'redux-saga/effects';

import appActions, { appType } from '@actions/app';

const INLINE_MAGIC_ENV = 'MAGIC_I_AM_AN_INLINE_ENV_MAGIC';

function* configuration(): IterableIterator<any> {
    const config = { ...process.env, NODE_ENV: process.env.NODE_ENV } as any;

    if (process.env.USE_INLINE_MAGIC_ENV) {
        Object.assign(config, JSON.parse(atob(INLINE_MAGIC_ENV)));
    }

    yield put(appActions.appConfigurationStore(config));
}

export const sagaAppConfiguration = function*(): IterableIterator<any> {
    yield takeLatest(appType.SAGA_INIT_FINISHED, configuration);
};
