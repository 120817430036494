import { createAction } from '@helpers/actionCreator';

export enum ratesType {
    RATES_GET_COMPANIES_REQUEST = 'RSP:RATES:GET:COMPANIES::REST:REQUEST',
    RATES_GET_COMPANIES_SUCCESS = 'RSP:RATES:GET:COMPANIES::REST:REQUEST:SUCCESS',
    RATES_GET_COMPANIES_FAIL = 'RSP:RATES:GET:COMPANIES::REST:REQUEST:FAIL',
    RATES_GET_COMPANIES_FINISHED = 'RSP:RATES:GET:COMPANIES::REST:REQUEST:FINISHED',

    RATES_GET_DESTINATIONS_REQUEST = 'RSP:RATES:GET:DESTINATIONS::REST:REQUEST',
    RATES_GET_DESTINATIONS_SUCCESS = 'RSP:RATES:GET:DESTINATIONS::REST:REQUEST:SUCCESS',
    RATES_GET_DESTINATIONS_FAIL = 'RSP:RATES:GET:DESTINATIONS::REST:REQUEST:FAIL',
    RATES_GET_DESTINATIONS_FINISHED = 'RSP:RATES:GET:DESTINATIONS::REST:REQUEST:FINISHED',

    RATES_GET_PRODUCTS_REQUEST = 'RSP:RATES:GET:PRODUCTS::REST:REQUEST',
    RATES_GET_PRODUCTS_SUCCESS = 'RSP:RATES:GET:PRODUCTS::REST:REQUEST:SUCCESS',
    RATES_GET_PRODUCTS_FAIL = 'RSP:RATES:GET:PRODUCTS::REST:REQUEST:FAIL',
    RATES_GET_PRODUCTS_FINISHED = 'RSP:RATES:GET:PRODUCTS::REST:REQUEST:FINISHED',

    RATES_GET_PREFIXES_REQUEST = 'RSP:RATES:GET:PREFIXES::REST:REQUEST',
    RATES_GET_PREFIXES_SUCCESS = 'RSP:RATES:GET:PREFIXES::REST:REQUEST:SUCCESS',
    RATES_GET_PREFIXES_FAIL = 'RSP:RATES:GET:PREFIXES::REST:REQUEST:FAIL',
    RATES_GET_PREFIXES_FINISHED = 'RSP:RATES:GET:PREFIXES::REST:REQUEST:FINISHED',

    RATES_GET_WITH_MAIN_FILTER_REQUEST = 'RSP:RATES:GET:WITH_MAIN_FILTER::REST:REQUEST',
    RATES_GET_WITH_MAIN_FILTER_SUCCESS = 'RSP:RATES:GET:WITH_MAIN_FILTER::REST:REQUEST:SUCCESS',
    RATES_GET_WITH_MAIN_FILTER_FAIL = 'RSP:RATES:GET:WITH_MAIN_FILTER::REST:REQUEST:FAIL',
    RATES_GET_WITH_MAIN_FILTER_FINISHED = 'RSP:RATES:GET:WITH_MAIN_FILTER::REST:REQUEST:FINISHED',

    RATES_GET_WITH_SUB_FILTER_REQUEST = 'RSP:RATES:GET:WITH_SUB_FILTER::REST:REQUEST',
    RATES_GET_WITH_SUB_FILTER_SUCCESS = 'RSP:RATES:GET:WITH_SUB_FILTER::REST:REQUEST:SUCCESS',
    RATES_GET_WITH_SUB_FILTER_FAIL = 'RSP:RATES:GET:WITH_SUB_FILTER::REST:REQUEST:FAIL',
    RATES_GET_WITH_SUB_FILTER_FINISHED = 'RSP:RATES:GET:WITH_SUB_FILTER::REST:REQUEST:FINISHED',

    RATES_CLEAR_REQUEST = 'RSP:RATES:CLEAR'
}

export interface ISubFilterObject {
    supplier: string;
    destination: string[];
    product: string[];
    prefixes: string[];
}

const ratesActions = {
    getRateCompaniesListRequest: createAction(ratesType.RATES_GET_COMPANIES_REQUEST, {
        payload: {
            table: 'rates',
            operation: 'get_list_of_suppliers'
        }
    }),
    getRateDestinationsListRequest: createAction(ratesType.RATES_GET_DESTINATIONS_REQUEST, {
        payload: (companyName: string) => ({
            table: 'rates',
            operation: 'get_list_of_destinations',
            supplier: companyName
        })
    }),
    getRateProductsListRequest: createAction(ratesType.RATES_GET_PRODUCTS_REQUEST, {
        payload: (companyName: string) => ({
            table: 'rates',
            operation: 'get_list_of_products',
            supplier: companyName
        })
    }),
    getRatePrefixesListRequest: createAction(ratesType.RATES_GET_PREFIXES_REQUEST, {
        payload: (companyName: string) => ({
            table: 'rates',
            operation: 'get_list_of_prefixes',
            supplier: companyName
        })
    }),
    getRatesWithMainFilterRequest: createAction(ratesType.RATES_GET_WITH_MAIN_FILTER_REQUEST, {
        payload: (companyName: string) => ({
            table: 'rates',
            rows_in_table: 10,
            page_number: 1,
            supplier: companyName
        })
    }),
    getRatesWithSubFilterRequest: createAction(ratesType.RATES_GET_WITH_SUB_FILTER_REQUEST, {
        payload: (subFilterObject: ISubFilterObject) => ({
            table: 'rates',
            rows_in_table: 10,
            page_number: 1,
            ...subFilterObject
        })
    }),
    clearRates: createAction(ratesType.RATES_CLEAR_REQUEST)
};

export default ratesActions;
