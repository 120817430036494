import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Box, Theme } from '@material-ui/core';
import { T } from '@components/T/T';
import makeStyles from '@material-ui/core/styles/makeStyles';
import classNames from 'classnames';
import { Alert, Input, InputNumber } from 'antd';
import { parseBalance } from '@helpers/formaters';
import { useSelector } from 'react-redux';
import { createPosition, getCurrentInfo, getFinalBillingStatement, IPosition, suggestOfferDirection } from '@selectors/roaming';
import { timeAndDay } from '@constants';
import { InputNumberProps } from 'antd/lib/input-number';
import { SettlementOfferStatus, useOfferStatus } from '@modules/Roaming/components/SettlementOffer/SettlementOffer';
import { getMyPeerId } from '@selectors/account';
import { selectCurrencyView, selectLatestOffer, selectSortedOffers } from '@selectors/roaming/settlementOffers';
import CommentIcon from '@ant-design/icons/MessageOutlined';
import OfferIcon from '@ant-design/icons/DollarOutlined';
import { NewOffer } from '@components/DisputeResolution/NewOffer/NewOffer';
import { PendingOffer } from '@components/DisputeResolution/PendingOffer/PendingOffer';
import moment from 'moment';
import { Separator } from '@components/Separator/Separator';
import TextArea from 'antd/lib/input/TextArea';
import { AcceptedOffer } from '@components/DisputeResolution/AcceptedOffer/AcceptedOffer';
import ArrowIcon from '@ant-design/icons/RightOutlined';
import { ExtendedTheme, FontWeight } from '@components/theme';
import { Currency } from '@helpers/currency';

const useStyles = makeStyles((theme: ExtendedTheme) => ({
    offerBox: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1
    },
    amountBox: {
        display: 'flex',
        flexDirection: 'column'
    },
    inputRow: {
        display: 'flex'
    },
    amountInput: {
        // css hack for lack of an API
        '& .ant-input-number-handler-wrap': {
            display: 'none'
        }
    },
    input: {
        '&:disabled, & input:disabled': {
            color: '#242424'
        }
    },
    tableBox: {
        border: '1px solid #D9D9D9',
        borderRadius: 4,
        padding: `0 ${theme.spacing(3)}px`
    },
    tableBoxRow: {
        display: 'flex',
        alignItems: 'center',
        height: 52,
        padding: `${theme.spacing(1)}px 0`
    },
    tableSeparator: {
        height: 1,
        background: '#979797',
        opacity: 0.18,
        margin: `0 -${theme.spacing(3)}px`,
        padding: `0 ${theme.spacing(3)}px`
    },
    tableBoxHeader: {
        background: '#F3F3F3',
        margin: `0 -${theme.spacing(3)}px`,
        padding: `0 ${theme.spacing(3)}px`
    },
    whoValue: {
        flex: 2.5,
        paddingRight: `${theme.spacing(2)}px`
    },
    separationArrow: {
        flex: 0.5
    },
    originalNetPositionValue: {
        flex: 2,
        color: '#989898'
    },
    messagesWrapper: {
        maxHeight: 330,
        overflowY: 'auto',
        marginBottom: theme.spacing(4)
    }
}));

export const useSettlementOfferStyles = makeStyles((theme: Theme) => ({
    header: {
        borderBottom: '1px solid #E9E9E9',
        height: 50,
        display: 'flex',
        alignItems: 'center'
    },
    footer: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        padding: theme.spacing(4, 0)
    },
    mainWrapper: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        padding: theme.spacing(6, 0, 0)
    },
    messagesDivider: {
        width: 'auto',
        margin: theme.spacing(0, -5, 4)
    }
}));

export enum OFFER_DIRECTION {
    PAY = 'Pay',
    RECEIVE = 'Receive'
}

const percentageFormatter = (value?: string | number) => `${value}%`;
const percentageParser = (value: string | undefined) => (value ? value.replace('%', '') : 0);

export const DisputeResolutionModal: React.FC = () => {
    const [shouldForceNewOffer, setForceNewOffer] = useState(false);
    const forceNewOffer = useCallback(() => setForceNewOffer(true), [setForceNewOffer]);
    const offerStatus = useOfferStatus();

    if (offerStatus === SettlementOfferStatus.Accepted) {
        return <AcceptedOffer />;
    }

    return shouldForceNewOffer || offerStatus === SettlementOfferStatus.None ? (
        <NewOffer />
    ) : (
        <PendingOffer onClickMakeCounterOffer={forceNewOffer} />
    );
};

export const OfferForm: React.FC<{
    isReadOnly?: boolean;
    discrepancy: Currency;
    offerDirection: OFFER_DIRECTION;
    offerAmount: number;
    setOfferAmount: InputNumberProps['onChange'];
    handlePercentageChange: InputNumberProps['onChange'];
}> = ({ discrepancy, offerDirection, offerAmount, setOfferAmount, handlePercentageChange, isReadOnly }) => {
    const classes = useStyles();
    const agreement = useSelector(getCurrentInfo);
    const offer = useSelector(selectLatestOffer);
    const myPeerId = useSelector(getMyPeerId);
    const currencyView = useSelector(selectCurrencyView);
    let title = 'You offer to';
    if (isReadOnly && offer?.initiatorId !== myPeerId) {
        const partnerName = agreement.counterName;
        title = `${partnerName} offer to`;
    }

    const formatter = useCallback(
        (value) => {
            const currency = new Currency(Number(value), agreement.terms.currency);
            return currencyView === 'local' ? currency.toLocalString(0) : currency.toString(0);
        },
        [currencyView, agreement.terms.currency]
    );

    return (
        <>
            <Box className={classes.offerBox}>
                <MiniHeader text={title} />
                <Box className={classes.inputRow}>
                    <Input size="large" style={{ width: 84, marginRight: 8 }} value={offerDirection} readOnly className={classes.input} disabled />
                    <InputNumber
                        size="large"
                        className={classNames(classes.amountInput, classes.input)}
                        style={{ width: 152, marginRight: 8 }}
                        value={Math.abs(isReadOnly ? offer?.offerAmount : offerAmount)}
                        readOnly={isReadOnly}
                        disabled={isReadOnly}
                        formatter={formatter}
                        parser={parseBalance}
                        onChange={setOfferAmount}
                        min={0}
                        max={discrepancy.valueOf()}
                        step={1000}
                    />
                    <InputNumber
                        size="large"
                        className={classes.input}
                        value={Math.abs(Math.round((offerAmount / discrepancy.valueOf()) * 100))}
                        min={0}
                        max={100}
                        readOnly={isReadOnly}
                        disabled={isReadOnly}
                        formatter={percentageFormatter}
                        parser={percentageParser}
                        onChange={handlePercentageChange}
                    />
                </Box>
            </Box>
            <Box className={classes.amountBox}>
                <MiniHeader text={'Discrepancy Amount'} />
                <Box color={'rgba(0,0,0,0.65)'} pt="6px" textAlign="right">
                    <T variant={'subtitle1'} weight={FontWeight.Semibold}>
                        {currencyView === 'local' ? discrepancy.localString : discrepancy.string}
                    </T>
                </Box>
            </Box>
        </>
    );
};

export const DisputeResolutionMessageBox: React.FC<{
    title: string;
    disabled?: boolean;
    value: string;
    onChange: (e: any) => void;
    SendButton?: React.ReactElement;
}> = ({ disabled, value, title, onChange, SendButton }) => {
    return (
        <Box className={'textAreaWrapper'} position={'relative'}>
            <MiniHeader text={title} />
            <TextArea
                style={{ paddingRight: '90px' }}
                disabled={disabled}
                placeholder={`Write a message to your partner`}
                rows={3}
                value={value}
                onChange={onChange}
            />
            {SendButton && <Box>{SendButton}</Box>}
        </Box>
    );
};

export const OfferMessages: React.FC = () => {
    const classes = useStyles();
    const containerRef = useRef<HTMLElement>();

    useEffect(() => {
        const containerEl = containerRef.current;
        if (!containerEl) {
            return;
        }
        containerEl.scroll(0, containerEl.scrollHeight);
    });

    const agreement = useSelector(getCurrentInfo);
    const myPeerId = useSelector(getMyPeerId);
    const allOffers = useSelector(selectSortedOffers);
    const myName = agreement.myName;
    const partnerName = agreement.counterName;

    return (
        // @ts-ignore (ref causes a prop type error, but in practice it works)
        <Box className={classes.messagesWrapper} ref={containerRef}>
            {allOffers.map((offer) => {
                const isMyOffer = offer.initiatorId === myPeerId;
                const author = isMyOffer ? myName : partnerName;
                const offerMessage = (
                    <Message type="offer" key={offer.id} author={author} message={offer.message} date={new Date(offer!.date * 1e3)} />
                );
                const commentMessages = offer?.comments.map((comment) => {
                    const isMyComment = comment.initiatorId === myPeerId;
                    const commentAuthor = isMyComment ? myName : partnerName;
                    return (
                        <Message
                            type="comment"
                            key={comment.id}
                            author={commentAuthor}
                            message={comment.message}
                            date={new Date(comment.date * 1e3)}
                        />
                    );
                });

                return [offerMessage].concat(commentMessages);
            })}
        </Box>
    );
};

const Message: React.FC<{ type: 'offer' | 'comment'; author: string; message: string; date: Date }> = ({ type, author, message, date }) => {
    return (
        <Box mb={2}>
            <Alert
                type={type === 'offer' ? 'success' : 'info'}
                icon={
                    <Box pt="1px" clone>
                        {type === 'offer' ? <OfferIcon /> : <CommentIcon />}
                    </Box>
                }
                showIcon
                message={
                    <Box display="flex" alignItems="baseline">
                        <T variant="subtitle2">({author})</T>
                        &nbsp;
                        <Box>
                            {message.split('\n').map((sentence, i) => (
                                <T key={i} variant="subtitle2">
                                    {sentence}
                                </T>
                            ))}
                        </Box>
                        <Separator />
                        <Box minWidth={70}>
                            <T variant="subtitle2">{moment(date).format(timeAndDay)}</T>
                        </Box>
                    </Box>
                }
            />
        </Box>
    );
};

export const useSimulatedNetPosition = (offerDirection: OFFER_DIRECTION, offerAmount: number) => {
    const finalStatement = useSelector(getFinalBillingStatement);
    const { isMyContract } = useSelector(getCurrentInfo);
    if (!finalStatement) {
        return null;
    }
    const counterNetPosition = finalStatement.counterNetPosition.value;
    const myNetPosition = finalStatement.myNetPosition.value;

    const absoluteNetPosition = Math.min(Math.abs(counterNetPosition), Math.abs(myNetPosition)) + Math.abs(offerAmount);
    const directionFromMyPerspective = suggestOfferDirection(isMyContract, myNetPosition, counterNetPosition);
    const sign =
        (isMyContract && directionFromMyPerspective === OFFER_DIRECTION.RECEIVE) ||
        (!isMyContract && directionFromMyPerspective === OFFER_DIRECTION.PAY)
            ? 1
            : -1;
    return sign * absoluteNetPosition;
};

export const NetPositionTable: React.FC<{ offerDirection: OFFER_DIRECTION; offerAmount: number }> = ({ offerDirection, offerAmount }) => {
    const classes = useStyles();
    const agreement = useSelector(getCurrentInfo);
    const finalStatement = useSelector(getFinalBillingStatement);
    const simulatedMyNewPosition = useSimulatedNetPosition(offerDirection, offerAmount);
    if (!finalStatement || !simulatedMyNewPosition) {
        return null;
    }
    const myName = agreement.myName;
    const partnerName = agreement.counterName;

    const simulatedNetPosition = createPosition(simulatedMyNewPosition, agreement.terms.currency, agreement.isMyContract);

    return (
        <Box className={classes.tableBox} mb={4}>
            <Box className={classNames(classes.tableBoxHeader, classes.tableBoxRow)}>
                <Box className={classes.whoValue}>Who</Box>
                <Box className={classes.originalNetPositionValue}>Original Net Position</Box>
                <Box className={classes.separationArrow} />
                <Box flex={2}>Simulated Net Position</Box>
            </Box>
            <TableRow name={myName} netPosition={finalStatement.myNetPosition} simulatedNewPosition={simulatedNetPosition} />
            <Box className={classes.tableSeparator} />
            <TableRow name={partnerName} netPosition={finalStatement.counterNetPosition} simulatedNewPosition={simulatedNetPosition} />
        </Box>
    );
};

interface ITableRow {
    name: string;
    netPosition: IPosition;
    simulatedNewPosition: IPosition;
}

const TableRow: React.FC<ITableRow> = ({ name, netPosition, simulatedNewPosition }) => {
    const currencyView = useSelector(selectCurrencyView);
    const classes = useStyles();
    return (
        <Box className={classes.tableBoxRow}>
            <Box className={classes.whoValue}>
                <T variant={'caption'}>{name}</T>
            </Box>
            <Box className={classes.originalNetPositionValue}>
                <T variant={'caption'}>
                    {' '}
                    {`${currencyView === 'local' ? netPosition.currency.localString : netPosition.currency.string} | ${netPosition.receiveOrPayable}`}
                </T>
            </Box>
            <Box className={classes.separationArrow}>
                <ArrowIcon style={{ fontSize: 12, color: '#989898' }} />
            </Box>
            <Box flex={2}>
                <T variant={'caption'}>
                    {' '}
                    {`${currencyView === 'local' ? simulatedNewPosition.currency.localString : simulatedNewPosition.currency.string} | ${
                        simulatedNewPosition.receiveOrPayable
                    }`}
                </T>
            </Box>
        </Box>
    );
};

const MiniHeader: React.FC<{ text: string }> = ({ text }) => (
    <Box color={'rgba(0,0,0,0.65)'} mb={2}>
        <T variant={'subtitle2'}>{text}</T>
    </Box>
);
