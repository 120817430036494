import React from 'react';
import { Select } from 'antd';
import { useSelector } from 'react-redux';
import { selectCurrencyView } from '@selectors/roaming/settlementOffers';
import { useAction } from '../../../hooks/useAction';
import { settlementOfferActions } from '@actions/settlementOffers';
import { getCurrentInfo } from '@selectors/roaming';
import { getCurrencySymbol } from '@constants';
import { Currency } from '@helpers/currency';

export const CurrencyViewSelection: React.FC = () => {
    const currencyView = useSelector(selectCurrencyView);
    const currentInfo = useSelector(getCurrentInfo);
    const contractCurrencyCode = currentInfo.terms.currency;
    const currencySymbol = getCurrencySymbol(contractCurrencyCode);
    const setCurrencyView = useAction(settlementOfferActions.setCurrencyView);
    if (contractCurrencyCode === Currency.localCurrency) {
        return null;
    }
    return (
        <Select dropdownMatchSelectWidth={false} value={currencyView} onChange={setCurrencyView}>
            <Select.Option value="local">View Local Currency ({getCurrencySymbol(Currency.localCurrency)})</Select.Option>
            <Select.Option value="original">View Contract Currency ({currencySymbol})</Select.Option>
        </Select>
    );
};
