import LockIcon from '@material-ui/icons/LockOutlined';
import React from 'react';
import { useSelector } from 'react-redux';
import { getMagicLink } from '@selectors/user';
import { IMagicLink } from '@actions/users';
import moment from 'moment';
import { periodDateFormat } from '@constants';
import { T } from '@components/T/T';
import { IconLabel } from '@components/IconLabel/IconLabel';

export const ExpirationDetails: React.FC = (props) => {
    const { ttl: magicLinkExpiration } = useSelector(getMagicLink) as IMagicLink;

    const expirationDateFormatted = magicLinkExpiration ? moment(magicLinkExpiration).format(periodDateFormat) : null;

    return (
        <IconLabel icon={<LockIcon fontSize="inherit" />} {...props}>
            <T variant="caption">{`This link is a secured link and active until ${expirationDateFormatted}`}</T>
        </IconLabel>
    );
};
