import React, { useState } from 'react';
import { normaliseFilename } from '@helpers';
import { capitalizeFirstLetter } from '@helpers/formaters';

interface IProps {
    peerName: string;
    peerPresentedName?: string;
}

export const Logo = ({ peerName, peerPresentedName }: IProps): JSX.Element => {
    const [isImgExist, setImageExist] = useState(true);
    const onImageNotExist = () => setImageExist(false);
    return isImgExist ? (
        <img className="logo" alt={peerName} title={peerName} onError={onImageNotExist} src={`/assets/images/${normaliseFilename(peerName)}.png`} />
    ) : (
        <div className={'my-user-name'}>{peerPresentedName || capitalizeFirstLetter(peerName)}</div>
    );
};
