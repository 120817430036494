import React from 'react';
import { Dialog } from '@components/Modal/Dialog';
import { getDisputeOffers, getDisputeOfferResult } from '@selectors/voice';
import { useSelector, useDispatch } from 'react-redux';
import voiceActions, { DisputeOfferType } from '@actions/voice';

import './styles.scss';
import { formatBalance } from '@helpers/formaters';
import { getCurrencySymbol } from '@constants';
import { CopyLink } from '../CopyLink';
import { PartnerAccountType } from '@components/AddPartners/AddPartners';
import { ISettlement } from '@reducers/voice';

interface IProps {
    settlement: ISettlement;
}
export const DisputeResolutionDialog = (props: IProps) => {
    const { result: offerId } = (useSelector(getDisputeOfferResult) || {
        result: null
    }) as { result?: string };
    const dispatch = useDispatch();
    const onClose = () => dispatch(voiceActions.voiceDisputeOfferResultClear());
    const offers = useSelector(getDisputeOffers);
    if (!offerId || !offers) {
        return null;
    }
    const offer = offers[offerId];
    const { id, offerType, amount, currency, link } = offer;
    const { accountName, accountPresentedName, accountType } = props.settlement;
    return (
        <Dialog classList="dispute-result-dialog" isOpen={true} onClose={onClose}>
            <div className="drd-inner">
                <div className="drd-header">
                    Dispute ID <span className="dispute-id">{id}</span>
                </div>
                <div className="drd-body">
                    <div className="dispute-type">{offerType === DisputeOfferType.FiftyFifty ? '50/50 Split Offer' : 'Resolution Offer'}</div>
                    <div>You offered to pay</div>
                    <div className="offer-amount">{formatBalance(amount, 2, getCurrencySymbol(currency))}</div>
                    {accountType === PartnerAccountType.CLEAR_PEER ? null : (
                        <>
                            <div className="link-title">
                                <div className="forward" />
                                Send offer link to your partner: {accountPresentedName || accountName}
                            </div>
                            <CopyLink link={`/external/offer/${encodeURIComponent(link)}`} />
                        </>
                    )}
                </div>
            </div>
        </Dialog>
    );
};
