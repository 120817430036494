import React, { useEffect } from 'react';
import { RouteComponentProps } from 'react-router';
import { Helmet } from 'react-helmet';
import { useSelector, useDispatch } from 'react-redux';
import { isAuthenticated } from '@selectors/auth';
import voiceActions, { DisputeOfferType } from '@actions/voice';
import authActions from '@actions/auth';
import { DisputeResolution } from '@modules/Voice/components';
import { getDisputeOffers, decorateComparisonResult, decorateSettlement } from '@selectors/voice';
import { getCurrencySymbol } from '@constants';
import './styles.scss';
import { getExternalDisputeId } from '@selectors/voiceDisputeDrawer';
import accountActions from '@actions/account';
import { getPeerPresentedName, getUserName } from '@selectors/account';
import { Logo } from '@modules/Voice/utils/Logo';

interface IParams {
    hash: string;
}
export const ExternalDisputeResolution = ({ match }: RouteComponentProps<IParams>) => {
    const dispatch = useDispatch();
    const disputeId = useSelector(getExternalDisputeId);
    const offers = useSelector(getDisputeOffers);
    const userName = useSelector(getUserName);
    const authenticated = useSelector(isAuthenticated);
    const magicLink = decodeURIComponent(match.params.hash);
    const peerPresentedName = useSelector(getPeerPresentedName);

    useEffect(() => {
        dispatch(authActions.externalLoginRequest(magicLink));
    }, []);

    useEffect(() => {
        if (!authenticated) {
            return;
        }
        dispatch(accountActions.getUsername());
        dispatch(accountActions.getInfoRequest());
        dispatch(voiceActions.voiceGetExternalDisputeOffer(magicLink));
    }, [authenticated]);

    const dispute = offers && disputeId && offers[disputeId];
    const offeredData = dispute && {
        ...dispute,
        accountCurrency: dispute.currency,
        lastDisputeOfferId: disputeId
    };
    const currencySymbol = offeredData && getCurrencySymbol(offeredData.accountCurrency);
    const comparisonItem = offeredData && decorateComparisonResult(offeredData, currencySymbol);

    // @ts-ignore
    const settlement = offeredData && decorateSettlement(offeredData);
    const peerName = dispute && dispute.peerName;
    return (
        <main className="voice-external external-dispute-resolution">
            <Helmet title="Clear Voice - Dispute Resolution Offer" />
            <header>{peerName && <Logo peerName={peerName} peerPresentedName={peerPresentedName} />}</header>
            <div className="content">
                <span>Welcome</span>
                <span className="username">{(dispute && dispute.accountPresentedName) || userName}</span>
                <span className="period">
                    There is {dispute && dispute.offerType === DisputeOfferType.FiftyFifty ? 'a 50/50 Split' : 'an'} Offer Waiting for You
                </span>
                <span className="separator" />
                <div className="image" />
            </div>
            <footer />
            {settlement && comparisonItem && <DisputeResolution isRecipient isExternal {...{ settlement, comparisonItem }} />}
        </main>
    );
};
