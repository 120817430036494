import { createSelector } from 'reselect';
import { IRootState } from '@reducers';
import { sortBy } from 'lodash';
import { getMyPeerId } from '@selectors/account';

const selectSettlementOfferState = createSelector(
    (root: IRootState) => root.settlementOffers,
    (settlementOffers) => settlementOffers
);

const selectAllSettlementOffers = createSelector(selectSettlementOfferState, (state) => state.offers);

export const selectSortedOffers = createSelector(selectAllSettlementOffers, (offers) => sortBy(offers, 'date'));

export const selectSettlementOfferByHash = (hash: string) =>
    createSelector(selectAllSettlementOffers, (offers) => offers.find((o) => o.hash === hash));

export const selectLatestOffer = createSelector(selectSortedOffers, (offers) => offers[offers.length - 1]);

export const selectIsLoadingOffers = createSelector(selectSettlementOfferState, (s) => s.isLoading);

export const isActiveOfferMine = createSelector(selectLatestOffer, getMyPeerId, (offer, peerId) => {
    return offer.initiatorId === peerId;
});

export const selectCurrencyView = createSelector(selectSettlementOfferState, (state) => state.currencyView);
