import * as React from 'react';
import { T } from '@components/T/T';
import { Box } from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import { IError } from '@reducers/agreements';

export const ModalErrorMsgBox: React.FC<{ error: IError; width?: string; testTag?: string }> = ({ error, width, testTag }) => {
    const errorText =
        error.errorType === 'AppError'
            ? error.errorText
            : 'We are sorry, we had a problem completing the task. Please contact us if the problem persists';
    return (
        <Box
            minHeight={'70px'}
            border={'1px solid #FABEB9'}
            borderRadius={'4px'}
            bgcolor={'#FEF0EF'}
            p={3}
            display={'flex'}
            mb={5}
            data-t={testTag}
            width={width || '100%'}
        >
            <Box color="#F04134" fontSize={24} mr={3.5} display={'flex'}>
                <ErrorIcon />
            </Box>
            <Box>
                <Box color={'rgba(0,0,0,0.65)'}>
                    <Box>
                        <T>{'We had a problem:'}</T>
                    </Box>
                </Box>
                <Box my={2}>
                    <T variant={'caption'}>{errorText}</T>
                </Box>
            </Box>
        </Box>
    );
};
