import * as React from 'react';

import './styles.scss';

export const Error404Component: React.StatelessComponent<{}> = (): JSX.Element => {
    return (
        <section className="error-layout--error404">
            <div>Oppps... maybe you're lost.</div>
            <div>404</div>
        </section>
    );
};
