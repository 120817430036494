import { IAppAction } from '@reducers/state';
import { voiceDisputeDrawerType, voiceType } from '@actions';
import { requestSuccessType } from '@helpers/actionCreator';

export interface IStateDispute {
    disputeId: string;
    externalDisputeId: string;
}

const initialState: IStateDispute = {
    disputeId: '',
    externalDisputeId: ''
};

export function voiceDisputeDrawerReducer(state: IStateDispute = initialState, action: IAppAction): IStateDispute {
    switch (action.type) {
        case voiceDisputeDrawerType.OPEN_DISPUTE_DRAWER: {
            const disputeId = action.payload;
            return {
                ...state,
                disputeId
            };
        }
        case voiceDisputeDrawerType.CLOSE_DISPUTE_DRAWER: {
            return {
                ...state,
                disputeId: ''
            };
        }
        case requestSuccessType(voiceType.VOICE_CREATE_DISPUTE_OFFER):
            return {
                ...state,
                disputeId: ''
            };
        case requestSuccessType(voiceType.VOICE_GET_EXTERNAL_DISPUTE_OFFER):
            const dispute = action.payload;
            return {
                ...state,
                externalDisputeId: dispute.id
            };

        case requestSuccessType(voiceType.VOICE_UPDATE_DISPUTE_OFFER_STATUS):
            return {
                ...state,
                externalDisputeId: ''
            };
        default:
            return state;
    }
}
