import * as React from 'react';

interface ITrafficViewerProps {
    tabLabel: string | JSX.Element;
}

export class TrafficViewer extends React.PureComponent<ITrafficViewerProps> {
    render() {
        const { tabLabel } = this.props;

        return <div className="tab-content traffic-viewer">{tabLabel}</div>;
    }
}
