import * as React from 'react';
import moment from 'moment';
import find from 'lodash/find';
import keyBy from 'lodash/keyBy';
import get from 'lodash/get';
import mapValues from 'lodash/mapValues';
import sortBy from 'lodash/sortBy';
import { ISorting } from '@actions/voice';

import classNames from 'classnames';

import { Drawer } from '@components/Drawer';
import { THead } from './THead';
import { TBody } from './TBody';
import { Header } from './Header';

import { parseBalance } from '@helpers/formaters';
import { exportTable } from '@helpers/exportToFile';

import './styles.scss';

type sortingFunction = (arg: any) => string | number;

export interface ITableColumnProps {
    extended?: object[];
    key: string;
    sortingKey?: string;
    title: string | JSX.Element;
    sortingType?: 'DATE' | 'BALANCE' | 'STRING' | 'NUMBER' | sortingFunction;
    disabled?: boolean;
    alignRight?: boolean;
    classNameCell?: string;
    notSortable?: boolean;
    defaultSort?: boolean;
}

export interface ITableProps {
    data: Array<{}>;
    cols: ITableColumnProps[];
    classes?: string;
    notSortable?: boolean;
    notFrontendSortable?: boolean;
    noMoreOption?: boolean;
    withActions?: boolean;
    withCheckboxes?: boolean;
    noHeader?: boolean;
    noTHead?: boolean;
    handleRowWithValueClick?: any;
    isLoading?: boolean;
    options?: JSX.Element | ((row: any) => void);
    onSortingColumnSelect?: (column: ITableColumnProps, isAscending: boolean) => void;
    defaultSorting?: ISorting;
    noExport?: boolean;
    exportFileName?: string;
    pagination?: JSX.Element;
    noExportRow?: boolean;
    handleExport?: () => void;
    placeholderGetter?: () => JSX.Element | null;
    stickinessScrollThreshold?: number;
    dataT?: string;
}

interface IGeneralTableProps extends ITableProps {
    drawerContent?: (drawer: any, closeDrawer: () => void) => JSX.Element;
}

interface IGeneralTableState {
    selectedRow: string | number | null;
    isStickinessOn: boolean;
}

interface IInnerTableProps extends ITableProps {
    handleRowSelection: (id: string | number | null) => void;
}

interface IInnerTableState {
    orderedColumn: string | null;
    isAscending: boolean;
    shownColumn: any;
}

const arrayToSetObject = (data: any[], key: string) => mapValues(keyBy(data, key), () => true);

const getDefaultOrderedColumn = (props: IInnerTableProps) => {
    let sortingFromUrl = get(props, 'defaultSorting.by');
    if (sortingFromUrl === 'maxRate') {
        sortingFromUrl = 'minRate';
    }
    const sortingColumn = find(props.cols, { sortingKey: sortingFromUrl });
    const preferred = sortingColumn || find(props.cols, 'defaultSort') || props.cols[0];
    return preferred.key || '';
};
const getIsAscending = (defaultSorting: ISorting | undefined): boolean => {
    const orderType = get(defaultSorting, 'order');
    return orderType === 'asc' ? true : false;
};
/* tslint:disable:max-classes-per-file */
class TableInner extends React.PureComponent<IInnerTableProps, IInnerTableState> {
    state = {
        isAscending: getIsAscending(this.props.defaultSorting),
        orderedColumn: getDefaultOrderedColumn(this.props),
        shownColumn: arrayToSetObject(this.props.cols, 'key')
    };

    componentWillReceiveProps(nextProps: Readonly<IInnerTableProps>, nextContext: any): void {
        if (this.props.cols !== nextProps.cols) {
            this.setState({
                shownColumn: arrayToSetObject(nextProps.cols, 'key')
            });
        }
    }

    onShowColumnToggle = (key: string) => {
        this.setState({
            shownColumn: {
                ...this.state.shownColumn,
                [key]: !this.state.shownColumn[key]
            }
        });
    };

    handleOrder = (columnKey: string) => {
        this.setState(
            ({ orderedColumn, isAscending }) => {
                return {
                    orderedColumn: columnKey,
                    isAscending: orderedColumn === columnKey ? !isAscending : false
                };
            },
            () => {
                const { onSortingColumnSelect, cols } = this.props;
                const { isAscending, orderedColumn } = this.state;
                if (onSortingColumnSelect) {
                    onSortingColumnSelect(find(cols, { key: orderedColumn }) as ITableColumnProps, isAscending);
                }
            }
        );
    };

    getSortedRows = (data: any[]) => {
        if (this.props.notSortable || this.props.notFrontendSortable) {
            return data;
        }
        const result = sortBy(data, [
            (row: any) => {
                const columnKey = this.state.orderedColumn;

                const sortColumn = find(this.props.cols, { key: columnKey });
                if (!sortColumn) {
                    console.warn('there is no column with key:' + columnKey);
                    return;
                }
                const value = row[sortColumn.sortingKey || sortColumn.key];
                const type = sortColumn ? sortColumn.sortingType || 'STRING' : 'STRING'; // TODO refactor to get(sortColumn, 'sortingType', 'STRING')
                const handlers = {
                    NUMBER: () => value || 0,
                    DATE: () => (value ? moment(value).valueOf() : 0),
                    STRING: () => (typeof value === 'string' ? value.toLowerCase() : value),
                    BALANCE: () => parseBalance(value)
                };
                if (typeof type === 'function') {
                    return type(value);
                }

                const resultValue = handlers[type]();
                return resultValue;
            },
            (row: any) => row.fullDate && moment(row.fullDate).valueOf() + '_' + row.id
        ]);

        return this.state.isAscending ? result : result.reverse();
    };

    exportTableToXlsx = () => {
        const { handleExport, exportFileName } = this.props;
        handleExport ? handleExport() : exportTable(exportFileName || 'Exported');
    };

    render() {
        const {
            data,
            cols,
            notSortable,
            noHeader,
            noTHead,
            handleRowWithValueClick,
            handleRowSelection,
            withActions,
            withCheckboxes,
            options,
            noExport,
            pagination,
            noExportRow,
            placeholderGetter
        } = this.props;
        const { orderedColumn, isAscending } = this.state;

        const theadCols = cols.filter((col) => this.state.shownColumn[col.key]);
        return (
            <>
                {!noExportRow && (
                    <div className="table-tools">
                        {pagination}
                        {!!pagination && !noExport && <span>|</span>}
                        {!noExport && (
                            <button className="export-button" data-t="export-btn" onClick={this.exportTableToXlsx}>
                                Export
                            </button>
                        )}
                    </div>
                )}
                {!noHeader && <Header key="header" withActions={withActions} withCheckboxes={withCheckboxes} />}
                <div className={'header-and-body-wrapper'}>
                    {!noTHead && (
                        <THead
                            key="head"
                            cols={theadCols}
                            orderedColumn={orderedColumn}
                            isAscending={isAscending}
                            orderHandler={this.handleOrder}
                            shownColumn={this.state.shownColumn}
                            onShowColumnToggle={this.onShowColumnToggle}
                            allCols={cols}
                            notSortable={notSortable}
                        />
                    )}
                    <div className={'body-wrapper'}>
                        <TBody
                            key="body"
                            data={this.getSortedRows(data)}
                            colsOrder={cols.map((col) => col.key).filter((key) => this.state.shownColumn[key])}
                            cols={cols}
                            selectRow={handleRowSelection}
                            handleRowWithValueClick={handleRowWithValueClick}
                            withCheckboxes={withCheckboxes}
                            options={options}
                            placeholderGetter={placeholderGetter}
                        />
                    </div>
                </div>
            </>
        );
    }
}

export class Table extends React.Component<IGeneralTableProps, IGeneralTableState> {
    state = { selectedRow: null, isStickinessOn: false };

    containerRef = React.createRef<HTMLDivElement>();

    componentDidMount = () => {
        const { stickinessScrollThreshold } = this.props;
        if (stickinessScrollThreshold) {
            window.addEventListener('scroll', this.onScroll);
        }
    };

    // Remove without checking if added for simplicity and in case the threshold param has changed
    componentWillUnmount = () => window.removeEventListener('scroll', this.onScroll);

    onScroll = () => {
        const isStickinessOn = document.documentElement.scrollTop > this.props.stickinessScrollThreshold!;
        if (isStickinessOn !== this.state.isStickinessOn) {
            const table = this.containerRef.current!;
            if (!table) {
                return;
            }
            const thead = table.querySelector('.thead');
            // persist width from before being 'fixed'
            if (thead) {
                const newWidth = isStickinessOn ? thead?.getBoundingClientRect().width + 'px' : '';
                (thead as HTMLDivElement).style.width = newWidth;
            }
            this.setState({ isStickinessOn });
        }
    };

    handleRowSelection = (rowKey: string | number | null) => this.setState({ selectedRow: rowKey });

    closeDrawer = () => this.setState({ selectedRow: null });

    getSelectedRowData = () => this.props.data.find((row: any) => row.key === this.state.selectedRow);

    render() {
        const { drawerContent, classes, notSortable, noMoreOption, withCheckboxes, dataT } = this.props;
        const { selectedRow, isStickinessOn } = this.state;
        const rowData = this.getSelectedRowData() || ({} as any);
        const canBeOpened = !['Transfer to', 'Transfer from'].includes(rowData.action);

        return (
            <div
                data-t={dataT}
                ref={this.containerRef}
                className={classNames('table', classes, {
                    noMoreOption,
                    withCheckboxes,
                    sortable: !notSortable,
                    sticky: isStickinessOn
                })}
            >
                <TableInner {...this.props} handleRowSelection={this.handleRowSelection} />
                {canBeOpened && drawerContent && selectedRow && (
                    <Drawer closeDrawer={this.closeDrawer}>{drawerContent(rowData, this.closeDrawer)}</Drawer>
                )}
            </div>
        );
    }
}
