import * as React from 'react';
import enhanceWithClickOutside from 'react-click-outside';
import cn from 'classnames';

interface IDrawerContentProps {
    content?: any;
    hideDrawer: () => void;
    classList?: string;
}

class DrawerContentComponent extends React.Component<IDrawerContentProps> {
    handleClickOutside = () => this.props.hideDrawer();

    render() {
        const { classList, content } = this.props;

        return (
            <aside className={cn(classList)}>
                <div className="drawer-content">{content}</div>
            </aside>
        );
    }
}

export const DrawerContent = enhanceWithClickOutside(DrawerContentComponent);
