import { getUrlQueryParams } from '../../utils/urlFunctions';
import { ISorting } from '@actions/voice';
import queryString from 'query-string';

export function updateSortingInUrl(sorting: ISorting, location: Location, history: any) {
    const queryParams = getUrlQueryParams(location);
    queryParams.sorting = JSON.stringify(sorting);
    const stringifyParams = queryString.stringify(queryParams);
    history.push(`${location.pathname}?${stringifyParams}`);
}

export function getSortingFromUrl(location: Location) {
    const queryParams = getUrlQueryParams(location);
    let sorting;
    if (queryParams.sorting) {
        sorting = JSON.parse(queryParams.sorting as string);
    }
    return sorting;
}
