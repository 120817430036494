import { logsType } from '@actions/RatesSheetParser/logs';
import { IAppAction } from '../state';

export interface ILogsServerDataByCompany {
    date: string;
    id: string;
    log_info: string;
    log_level: string;
    service_name: string;
    download_path: string | null;
}

export interface IStateLogs {
    loading: boolean;
    data: ILogsServerDataByCompany[];
}

const initialState = {
    loading: false,
    data: [] as ILogsServerDataByCompany[]
};

export function logsReducer(state = initialState, action: IAppAction): IStateLogs {
    switch (action.type) {
        case logsType.LOGS_GET_ALL_REQUEST:
        case logsType.LOGS_GET_WITH_FILTER_REQUEST:
            return { ...state, loading: true };
        case logsType.LOGS_GET_ALL_SUCCESS:
        case logsType.LOGS_GET_WITH_FILTER_SUCCESS:
            return { ...state, data: action.payload, loading: false };
        case logsType.LOGS_GET_ALL_FAIL:
        case logsType.LOGS_GET_WITH_FILTER_FAIL:
            return { ...state, loading: false };
        default:
            return state;
    }
}
