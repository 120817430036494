export const settlementPeriodsConstant = {
    monthly: 'Monthly',
    weekly: 'Weekly',
    daily: 'Daily',
    hourly: 'Hourly',
    minutely: 'Minutely',
    secondly: 'Secondly'
};

export const currenciesConstant: any = {
    USD: '$ USD', // US Dollar
    EUR: '€ EUR', // Euro
    GBP: '£ GBP', // British Pound Sterling
    HKD: '$ HK' // Hong Kong Dollar
};

export const currenciesConstantShort: any = {
    EUR: '€ EUR'
};

export const getCurrencySymbol = (currency: string) => {
    const map = {
        USD: '$',
        EUR: '€', // Euro
        GBP: '£', // British Pound Sterling
        HKD: '$' // Hong Kong Dollar
    } as any;
    return map[currency] || '';
};

export const dataFormatsConstant = {
    cdr: 'CDR',
    summary: 'Live Snapshot'
};

export const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

export const monthsShort = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

export const APIDateFormat = 'YYYY-MM-DD';
export const partialDayFormat = 'MMM D';
export const periodDateFormat = 'MMM D, YYYY';
export const shortDateFormat = 'MMM DD, YYYY';
export const longDateFormat = 'MMM, DD, YYYY - hh:mm a';
export const longDateFormatTimeFirst = 'hh:mm a MMM DD, YYYY';
export const longDateFormatWithSeconds = 'MMM, DD, YYYY - hh:mm:ss a';
export const monthAndYearFormat = 'MMMM, YYYY';
export const utcTimeFormat = 'HH:mm:ss UTC';
export const formatUTCToLocal = 'ddd MMM dd YYYY hh:mm:ss';
export const timeAndDay = 'HH:mm MMM D';
