import React from 'react';
import { DateRangePicker } from 'react-date-range';
import { CalendarSvg } from '@components/Svg';
import moment from 'moment';
import { periodDateFormat, APIDateFormat } from '@constants';
import get from 'lodash/get';
import './styles.scss';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { SelectField } from '@components';

interface IDateRangeValue {
    startDate?: number | object;
    endDate?: number | object;
    pickerStart?: object;
    pickerEnd?: object;
    initial?: Date;
    startMinutes?: string | number;
    endMinutes?: string | number;
}
interface IProps {
    dateFromUrl?: { [key: string]: any } | undefined;
    value: IDateRangeValue;
    dateOnly: boolean;
    onChange(value: any): void;
}
const timeOptions = Array(48)
    .fill(1)
    .map(Number.call, Number)
    .map((idx: any) => ({
        name: `${Math.floor(idx / 2)
            .toString()
            .padStart(2, '0')}:${idx % 2 ? 3 : 0}0`,
        value: idx * 30
    }));
export const DateRange = ({ value, dateOnly, onChange, dateFromUrl }: IProps) => {
    // pickerStart / pickerEnd are in local timezone while startDate and endDate are in UTC
    const { pickerStart, pickerEnd, startMinutes, endMinutes } = value;
    const handleTimeChange = (isFrom: boolean, minutes: string | number) =>
        processValue({
            ...value,
            [isFrom ? 'startMinutes' : 'endMinutes']: +minutes
        });
    const handleDateRangeChange = ({ selection: { startDate, endDate } }: any) =>
        processValue({ ...value, pickerStart: startDate, pickerEnd: endDate });

    const processDate = (startOfLocalDate: any, minutes: any) => {
        const d = moment.utc(moment(startOfLocalDate).format(APIDateFormat), APIDateFormat).startOf('day');
        if (minutes) {
            d.add(+minutes, 'minutes');
        }
        return d;
    };
    const processValue = (values: IDateRangeValue) => {
        if (values.pickerStart) {
            values.startDate = processDate(values.pickerStart, values.startMinutes);
        }
        if (values.pickerEnd) {
            values.endDate = processDate(values.pickerEnd, values.endMinutes);
        }
        onChange(values);
    };
    const initial = value.initial instanceof Date && !isNaN(value.initial as any) ? value.initial : new Date();
    const pickerStartFromUrl = get(dateFromUrl, 'pickerStartFromUrl');
    const pickerEndFromUrl = get(dateFromUrl, 'pickerEndFromUrl');
    const pickerStartToPickerDateSpan = pickerStart || pickerStartFromUrl;
    const pickerEndToPickerDateSpan = pickerEnd || pickerEndFromUrl;

    return (
        <div className="filter-date-range">
            {dateOnly ? (
                <div className="datepickers date-picker-row">
                    <span className="picker-label">From</span>
                    {/* tslint:disable-next-line:max-line-length */}
                    <span className="picker-date">
                        <CalendarSvg />
                        {pickerStartToPickerDateSpan ? moment(pickerStartToPickerDateSpan).format(periodDateFormat) : ''}
                    </span>
                    <span className="picker-label">To</span>
                    {/* tslint:disable-next-line:max-line-length */}
                    <span className="picker-date">
                        <CalendarSvg />
                        {pickerEndToPickerDateSpan ? moment(pickerEndToPickerDateSpan).format(periodDateFormat) : ''}
                    </span>
                </div>
            ) : (
                <div className="datepickers">
                    {[
                        ['From', pickerStart, startMinutes, true],
                        ['To', pickerEnd, endMinutes, false]
                    ].map(([label, date, time, isFrom]) => (
                        <div className="date-picker-row dp-date-time" key={label as string}>
                            <span className="picker-label">{label}</span>
                            <span className="picker-date">{date ? moment(date as string).format(periodDateFormat) : ''}</span>
                            <SelectField
                                classList="voice operation-select picker-time"
                                input={{
                                    onChange: handleTimeChange.bind(null, isFrom as boolean),
                                    value: time || 0
                                }}
                                options={timeOptions}
                                meta={''}
                            />
                        </div>
                    ))}
                </div>
            )}
            <div className="date-range-picker">
                <DateRangePicker
                    ranges={[
                        {
                            startDate: pickerStart || pickerStartFromUrl || initial,
                            endDate: pickerEnd || pickerEndFromUrl || initial,
                            key: 'selection'
                        }
                    ]}
                    onChange={handleDateRangeChange}
                />
            </div>
        </div>
    );
};
DateRange.reverseEngineerValue = (startSec: number, endSec: number, dateOnly: boolean): IDateRangeValue => {
    const startDate = startSec && moment.utc(startSec * 1000);
    const endDate = endSec && moment.utc(endSec * 1000);
    const result: IDateRangeValue = {
        startDate,
        endDate,
        pickerStart: startSec ? moment(startSec * 1000).startOf('day') : undefined,
        pickerEnd: endSec ? moment(endSec * 1000).startOf('day') : undefined
    };
    if (!dateOnly) {
        result.startMinutes = startDate && startDate.get('hours') * 60 + startDate.get('minutes');
        result.endMinutes = endDate && endDate.get('hours') * 60 + endDate.get('minutes');
    }
    return result;
};
