import React, { useState } from 'react';
import './styles.scss';
import { Dialog, Icon } from '@components';
import { InviteLinkType } from '@actions/users';

interface IInviteDialogProps {
    type: InviteLinkType;
    username: string;
    hash: string;
    expirationDate: string;
    daysToExpire: number;
    onClose(): void;
}

export const InviteLinkDialog = (props: IInviteDialogProps) => {
    const { hash, type } = props;
    const url = `${window.location.protocol}//${window.location.host}/external/${type}/${hash}`;
    const [copied, setCopied] = useState(false);

    const onClose = () => {
        setCopied(false);
        props.onClose();
    };

    const copyStringToClipboard = (str: string) => {
        const el = document.createElement('textarea');
        el.value = str;
        el.setAttribute('readonly', '');
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
    };

    const handleCopyLink = (e: React.MouseEvent) => {
        e.preventDefault();
        setCopied(true);
        copyStringToClipboard(url);
    };

    return (
        <>
            <Dialog isOpen={props.hash !== ''} onClose={onClose} classList="user-invite-link-dialog">
                <div className="content">
                    <span className="title">Personal Password Creation Link</span>
                    <div className="copy-link-wrapper">
                        <input className="input-link" value={url} disabled={true} />
                        <button className={`copy-link-btn ${copied && ' active'}`} onClick={handleCopyLink}>
                            {copied ? 'Copied' : 'Copy Link'}
                        </button>
                    </div>
                    <div className="expiration-details">
                        <Icon className="icon" iconName="clock" />
                        <span className="description">{`This link will expire in ${props.daysToExpire} days (${props.expirationDate})`}</span>
                    </div>
                </div>
            </Dialog>
        </>
    );
};
