import { createRequestAction } from '@helpers/actionCreator';

export enum notificationType {
    NOTIFICATION_UPDATE_RECEIVED = 'NOTIFICATION::UPDATE:RECEIVED',

    NOTIFICATION_GET_ALL_REQUEST = 'NOTIFICATION:GET:ALL::REQUEST',
    NOTIFICATION_GET_ALL_SUCCESS = 'NOTIFICATION:GET:ALL::REQUEST:SUCCESS',
    NOTIFICATION_GET_ALL_FAIL = 'NOTIFICATION:GET:ALL::REQUEST:FAIL',

    NOTIFICATION_ALL_READ_REQUEST = 'NOTIFICATION:ALL:READ::REQUEST',
    NOTIFICATION_ALL_READ_SUCCESS = 'NOTIFICATION:ALL:READ::REQUEST:SUCCESS',
    NOTIFICATION_ALL_READ_FAIL = 'NOTIFICATION:ALL:READ::REQUEST:FAIL',

    NOTIFICATION_MARK_AS_READ = 'NOTIFICATION:MARK:AS:READ::REQUEST'
}

const notificationActions = {
    allNotificationsRead: createRequestAction(notificationType.NOTIFICATION_ALL_READ_REQUEST, 'setAllNotificationsRead'),
    markNotificationAsRead: createRequestAction(notificationType.NOTIFICATION_MARK_AS_READ, 'markNotificationAsRead', (id: string) => [id]),
    notificationGetAll: createRequestAction(notificationType.NOTIFICATION_GET_ALL_REQUEST, 'getNotifications')
};

export enum NOTIFICATION_TYPE {
    VOICE_DISPUTE = 'VOICE_DISPUTE',
    VOICE_CONTRACT = 'VOICE_CONTRACT',
    VOICE_CDR = 'VOICE_CDR',
    VOICE_AGREEMENT = 'VOICE_AGREEMENT',
    ROAMING_CONTRACT = 'ROAMING_CONTRACT',
    ROAMING_DISPUTE = 'ROAMING_DISPUTE',
    ROAMING_PARTNERSHIP = 'ROAMING_PARTNERSHIP'
}

export interface IStateNotification {
    content: string;
    id: string;
    read: boolean;
    time: number;
    timeToPresent: string;
    type?: NOTIFICATION_TYPE;
    data?: Record<string, any>;
}

export interface IStateNotifications extends Array<IStateNotification> {}

export default notificationActions;
