import { createAction } from '@helpers/actionCreator';

export enum communicationType {
    COMMUNICATION_INIT = 'COMMUNICATION::INIT',
    REQUEST_STARTED = 'REQUEST::STARTED',
    REQUEST_UNAUTHORIZED = 'REQUEST::UNAUTHORIZED',
    REQUEST_NOT_ALLOWED = 'REQUEST::NOT:ALLOWED',
    REQUEST_SUCCESS = 'REQUEST:SUCCESS',
    REQUEST_FAIL = 'REQUEST:FAIL',
    REQUEST_FINISHED = 'REQUEST:FINISHED',
    RESPONSE_UNKNOWN = 'RESPONSE::UNKNOWN',
    MESSAGE_UNKNOWN = 'MESSAGE::UNKNOWN',
    RESPONSE_UPDATING_SUBSCRIBE = 'RESPONSE:UPDATING::SUBSCRIBE',
    RESPONSE_UPDATING_UNSUBSCRIBE = 'RESPONSE:UPDATING::UNSUBSCRIBE',
    RESPONSE_UPDATING_SUBSCRIPTION_UNKNOWN = 'RESPONSE:UPDATING::SUBSCRIPTION:UNKNOWN',
    SERVER_UPDATE_SESSION_EVENT = 'SERVER_UPDATE::SESSION_EVENT'
}

const communicationActions = {
    communicationInit: createAction(communicationType.COMMUNICATION_INIT),
    requestFail: createAction(communicationType.REQUEST_FAIL),
    requestUnauthorized: createAction(communicationType.REQUEST_UNAUTHORIZED),
    requestNotAllowed: createAction(communicationType.REQUEST_NOT_ALLOWED),
    requestFinished: createAction(communicationType.REQUEST_FINISHED),
    requestSuccess: createAction(communicationType.REQUEST_SUCCESS),
    responseUnknown: createAction(communicationType.RESPONSE_UNKNOWN),
    messageUnknown: createAction(communicationType.MESSAGE_UNKNOWN),
    subscribeUpdating: createAction(communicationType.RESPONSE_UPDATING_SUBSCRIBE, { payload: (name: string, type: string) => ({ name, type }) }),
    unsubscribeUpdating: createAction(communicationType.RESPONSE_UPDATING_UNSUBSCRIBE, { payload: (name: string) => ({ name }) }),
    subscriptionUknown: createAction(communicationType.RESPONSE_UPDATING_SUBSCRIPTION_UNKNOWN)
};

export default communicationActions;
