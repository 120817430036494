import * as React from 'react';
import { AnyAction, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import { Form, reduxForm, Field, InjectedFormProps, formValueSelector } from 'redux-form';

import { IRootState } from '@reducers/index';
import { InputField } from '@components/Fields';
import { required } from '@helpers/index';
import authActions, { LOGIN_REDIRECT } from '@actions/auth';
import uiActions from '@actions/ui';
import './styles.scss';
import { T } from '@components/T/T';

interface ILogInFormData {
    email: string;
    password: string;
    mfa?: string;
}

interface ILogInFormProps {
    isMfaEnabled: boolean;
    emailValue: string;
    loginSending: boolean;
    loginFailed: boolean;
    loginSucceeded: boolean;
    loginError?: string;
    dispatchLogin: (email: string, password: string, mfa?: string) => AnyAction;
    dispatchLogUISettings: () => AnyAction;
}

type FormPropsType = ILogInFormProps & InjectedFormProps<ILogInFormData, ILogInFormProps>;

@connect(
    (state: IRootState & ILogInFormProps) => ({
        emailValue: formValueSelector('logIn')(state, 'email'),
        loginFailed: state.auth.login.isFailed,
        loginSending: state.auth.login.isSending,
        loginSucceeded: state.auth.login.isSucceeded,
        loginError: state.auth.login.errorType,
        isMfaEnabled: state.app.isMfaEnabled
    }),
    (dispatch: Dispatch) => ({
        dispatchLogin: (mail: string, password: string, mfa?: string): AnyAction => dispatch(authActions.loginRequest(mail, password, mfa)),
        dispatchLogUISettings: (): AnyAction => dispatch(uiActions.logUiSettings())
    })
)
class LoginForm extends React.Component<FormPropsType> {
    state = { needRedirect: false };

    constructor(props: FormPropsType) {
        super(props);

        const initialValues = {
            email: '',
            password: '',
            mfa: ''
        };
        props.initialize(initialValues);
    }

    submit = (values: ILogInFormData) => {
        // TODO: Refactor to use redux state. It doesn't make any sense to just wait 3 seconds...
        let timerId: number;

        this.props.dispatchLogin(values.email, values.password, values.mfa);

        return new Promise((resolve, reject): void => {
            const checkRequest = () => {
                if (this.props.loginFailed) {
                    window.clearInterval(timerId);
                    reject();
                }

                if (this.props.loginSucceeded) {
                    this.props.dispatchLogUISettings();
                    window.clearInterval(timerId);
                    resolve();
                }
            };
            timerId = window.setInterval(checkRequest, 300);
        }).then(() => this.setState({ needRedirect: true }));
    };

    getLoginErrorElement(loginError: string, isMfaEnabled: boolean) {
        switch (loginError) {
            case 'UserLockedError':
                return <T>Too many failed login attempts. You have been blocked, please contact your admin</T>;
            default:
                return <T>{`Your user name ${isMfaEnabled ? ', password or MFA' : 'or password'} are incorrect. Please try again`}</T>;
        }
    }

    render() {
        const { handleSubmit, loginSending, isMfaEnabled, loginError } = this.props;

        if (this.state.needRedirect) {
            const currentUrl = new URL((window.location as unknown) as string);
            const redirectUrl = currentUrl.searchParams.get(LOGIN_REDIRECT) || '/dashboard';
            return <Redirect to={redirectUrl} />;
        }
        return (
            <Form className="log-in-form" onSubmit={handleSubmit(this.submit)}>
                <p className="title">Login</p>
                <Field name="email" component={InputField} label="User name" validate={[required]} disabled={loginSending} />
                <Field name="password" component={InputField} type="password" label="Password" validate={[required]} disabled={loginSending} />
                {isMfaEnabled ? <Field name="mfa" component={InputField} type="text" label="MFA" disabled={loginSending} /> : null}
                {!!loginError && <div className="login-error">{this.getLoginErrorElement(loginError, isMfaEnabled)}</div>}
                <div className="actions">
                    <button className="btn btn-primary btn-login" type="submit" disabled={loginSending}>
                        Login
                    </button>
                </div>
            </Form>
        );
    }
}

export const LoginEmailForm = reduxForm<ILogInFormData, ILogInFormProps>({
    form: 'logIn'
})(LoginForm);
