import React, { useState } from 'react';
import { Input, Tag } from 'antd';
import Plus from '@ant-design/icons/PlusOutlined';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => {
    return {
        addTagButton: {
            cursor: 'pointer'
        }
    };
});

interface IProps {
    addBtnTitle: string;
    values: string[];
    onChange: (values: string[]) => void;
    className?: string;
}
export const MultiTagInput = ({ values, onChange, addBtnTitle, className }: IProps) => {
    const classes = useStyles();
    const [inputMode, setInputMode] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const inputKeyHandler = (event: React.KeyboardEvent) => {
        const { key } = event;
        if (key === 'Enter') {
            createTag();
        } else if (key !== 'Escape') {
            return;
        }
        event.stopPropagation();
        setInputMode(false);
    };
    const createTag = () => {
        if (inputValue.trim()) {
            onChange([...values, inputValue.trim()]);
        }
        setInputMode(false);
    };
    const inputChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => setInputValue(event.target.value);
    const turnOnInputMode = () => {
        setInputValue('');
        setInputMode(true);
    };
    const getTagRemover = (tag: string) => () => onChange(values.filter((val) => val !== tag));
    return (
        <span className={className || ''}>
            {values.map((value) => (
                <Tag key={value} closable onClose={getTagRemover(value)}>
                    {value}
                </Tag>
            ))}
            {inputMode ? (
                <Input autoFocus value={inputValue} onKeyDown={inputKeyHandler} onBlur={createTag} onChange={inputChangeHandler} />
            ) : (
                <Tag className={classes.addTagButton} onClick={turnOnInputMode}>
                    <Plus /> {addBtnTitle}
                </Tag>
            )}
        </span>
    );
};
