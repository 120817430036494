import uniqBy from 'lodash/uniqBy';
import { agreementsType } from '@actions/agreements';
import { IAppAction } from './state';
import { requestFailType, requestSuccessType, requestType } from '@helpers/actionCreator';
import { BillingStatement, BillingStatementType } from '@clearblockchain/shared/billing-statement';
import get from 'lodash/get';
import { Partnership, UsageSource } from '@clearblockchain/shared/partnership';
import { SettlementStatus } from '@clearblockchain/shared/contract';

export interface IError {
    errorText: string;
    errorType: string;
}

export interface IUploadUsageDataResult {
    success: boolean;
    error?: IError;
}

export interface IPartnerships {
    isLoading: boolean;
    partnership: Partnership | undefined | null;
    allPartnerships: Partnership[] | undefined;
    mySupportedUsageTypes: UsageSource[];
}

export interface IAgreementsReducer {
    partnerships: IPartnerships;
    total: number;
    items: any[];
    contractStateBeforeLastUpdate: any;

    showCreateContractDialog: boolean;
    showReplaceContractDialog: boolean;
    fileSendingError: IError | null;

    proposals: any;
    proposalFetching: boolean;

    contracts: any;

    hashResponse: any;
    uploadUsageResult: IUploadUsageDataResult | null;

    querySubmitted: boolean;
    billingStatements: BillingStatement[] | undefined;
    reports: {
        summarySent: Record<string, boolean>;
        summaryLink: string;
    };

    gsmaData: any;
    settlementResult: any;
    roamingProposalData: any;
    roamingDiscrepancies: {
        isLoading: boolean;
        value?: any;
        pagination?: any;
    };
    breakdownMonthIndex: number;
    recentlyUploadedUsageContractId?: string;
    usageResult: {
        isLoading: boolean;
        value?: any;
        pagination?: any;
    };
    isLoading: boolean;
    isContractSettling: boolean;
    contractTableIds: string[];
}

const initialState: IAgreementsReducer = {
    partnerships: { isLoading: false, partnership: undefined, allPartnerships: undefined, mySupportedUsageTypes: [] },
    total: 0,
    items: [],
    contractStateBeforeLastUpdate: null,
    showCreateContractDialog: false,
    showReplaceContractDialog: false,
    fileSendingError: null,

    proposals: null,
    proposalFetching: false,

    contracts: null,

    hashResponse: null,
    uploadUsageResult: null,

    querySubmitted: false,

    billingStatements: undefined,
    reports: {
        summarySent: {},
        summaryLink: ''
    },

    gsmaData: {},
    settlementResult: null,
    roamingProposalData: null,
    roamingDiscrepancies: {
        isLoading: false,
        value: [],
        pagination: {}
    },
    breakdownMonthIndex: 12,
    usageResult: {
        isLoading: false,
        value: [],
        pagination: {}
    },
    isLoading: false,
    isContractSettling: false,
    contractTableIds: []
};

export function agreementsReducer(state = initialState, { type, payload, requestAction, errorType }: IAppAction): IAgreementsReducer {
    switch (type) {
        case agreementsType.QUERY_ALL_PARTNERSHIPS:
        case agreementsType.PROPOSE_PARTNERSHIP:
        case agreementsType.QUERY_PARTNERSHIP:
            return { ...state, partnerships: { ...state.partnerships, isLoading: true } };

        case agreementsType.QUERY_PARTNERSHIP_SUCCESS:
        case agreementsType.PROPOSE_PARTNERSHIP_SUCCESS:
        case agreementsType.RESPOND_TO_SUGGESTED_PARTNERSHIP_SUCCESS: {
            const isArray = payload instanceof Array;
            if (isArray && payload.length === 0) {
                return {
                    ...state,
                    partnerships: {
                        ...state.partnerships,
                        isLoading: false,
                        partnership: null
                    }
                };
            }
            const partnershipToState = isArray && payload.length === 1 ? payload[0] : payload;
            return {
                ...state,
                partnerships: {
                    ...state.partnerships,
                    isLoading: false,
                    partnership: partnershipToState?.status ? partnershipToState : null
                }
            };
        }
        case agreementsType.QUERY_PARTNERSHIP_FAIL:
        case agreementsType.QUERY_ALL_PARTNERSHIPS_FAIL:
        case agreementsType.PROPOSE_PARTNERSHIP_FAIL: {
            return { ...state, partnerships: { ...state.partnerships, isLoading: false } };
        }

        case agreementsType.QUERY_ALL_PARTNERSHIPS_SUCCESS:
            return { ...state, partnerships: { ...state.partnerships, isLoading: false, allPartnerships: payload } };

        case agreementsType.QUERY_USAGE_TYPES: {
            return { ...state, partnerships: { ...state.partnerships, isLoading: true } };
        }

        case agreementsType.QUERY_USAGE_TYPES_SUCCESS: {
            return { ...state, partnerships: { ...state.partnerships, isLoading: false, mySupportedUsageTypes: payload } };
        }

        case agreementsType.QUERY_USAGE_TYPES_FAIL: {
            return { ...state, partnerships: { ...state.partnerships, isLoading: false } };
        }

        case agreementsType.PARTNERSHIP_UPDATE_RECEIVED: {
            const partnership = payload.updatedRow?.status ? payload.updatedRow : null;
            const modifiedPartnershipList = (state.partnerships.allPartnerships ?? []).slice();
            const modifiedPartnershipIndex = modifiedPartnershipList.findIndex((p) => p.id === partnership.id);
            if (modifiedPartnershipIndex > -1) {
                modifiedPartnershipList[modifiedPartnershipIndex].status = partnership.status;
            }
            return { ...state, partnerships: { ...state.partnerships, allPartnerships: modifiedPartnershipList, isLoading: false, partnership } };
        }

        case agreementsType.GET_AGREEMENTS: {
            return { ...state, isLoading: true };
        }

        case agreementsType.GET_AGREEMENTS_FAIL: {
            return { ...state, isLoading: false };
        }

        case agreementsType.GET_AGREEMENTS_SUCCESS: {
            const { items, pagination } = payload;
            const updatedAgreements = uniqBy((Array.isArray(items) ? items : []).concat(state.items), 'id');
            return {
                ...state,
                contractTableIds: items.map((i: any) => i.id),
                items: updatedAgreements,
                total: pagination.totalItems,
                isLoading: false
            };
        }
        case agreementsType.AGREEMENTS_CONTRACT_UPDATE_RECEIVED:
            const status = payload?.objs[0].settlementStatus === SettlementStatus.SETTLEMENT_IN_PROGRESS;
            return {
                ...state,
                contractStateBeforeLastUpdate: state.items.find((contract) => contract.id === get(payload, 'objs[0].id')),
                items: uniqBy((Array.isArray(payload.objs) ? payload.objs : []).concat(state.items), 'id'),
                isContractSettling: status
            };
        case agreementsType.AGREEMENTS_PROPOSAL_UPDATE_RECEIVED:
            return {
                ...state,
                items: uniqBy((Array.isArray(payload.objs) ? payload.objs : []).concat(state.items), 'id')
            };

        case agreementsType.TOGGLE_CREATE_CONTRACT:
            return { ...state, showCreateContractDialog: payload as boolean };
        case agreementsType.TOGGLE_REPLACE_CONTRACT:
            return { ...state, showReplaceContractDialog: payload as boolean };
        case requestType(agreementsType.ROAMING_PROPOSE_CONTRACT):
        case agreementsType.CLEAR_CONTRACT_CREATION_ERROR:
            return { ...state, fileSendingError: null };
        case requestFailType(agreementsType.ROAMING_PROPOSE_CONTRACT):
            return { ...state, fileSendingError: { errorText: payload, errorType } };
        case requestSuccessType(agreementsType.ROAMING_PROPOSE_CONTRACT):
            return { ...state, showCreateContractDialog: false };

        case requestSuccessType(agreementsType.AGREEMENTS_SETTLEMENT_DATA_SUBMIT_HASH):
        case requestSuccessType(agreementsType.SUBMIT_CONTRACT_RELATED_FILE):
            return {
                ...state,
                hashResponse: payload,
                recentlyUploadedUsageContractId: requestAction?.payload?.values?.[0],
                uploadUsageResult: { success: true, error: undefined }
            };
        case agreementsType.AGREEMENTS_CLEAR_PENDING_USAGE_DATA_CONTRACT:
            return {
                ...state,
                recentlyUploadedUsageContractId: undefined
            };
        case requestFailType(agreementsType.AGREEMENTS_SETTLEMENT_DATA_SUBMIT_HASH):
        case requestFailType(agreementsType.SUBMIT_CONTRACT_RELATED_FILE):
            return {
                ...state,
                uploadUsageResult: { success: false, error: { errorType, errorText: payload } }
            };
        case agreementsType.AGREEMENTS_RESET_UPLOAD_USAGE_RESULT: {
            return { ...state, uploadUsageResult: null };
        }
        case agreementsType.AGREEMENTS_QUERY_BILLING_STATEMENT_REQUEST:
            return { ...state, querySubmitted: true, isLoading: true };

        case agreementsType.AGREEMENTS_QUERY_BILLING_STATEMENT_SUCCESS:
            return {
                ...state,
                billingStatements: payload,
                isLoading: false
            };

        case agreementsType.AGREEMENTS_BILLING_STATEMENT_UPDATE_RECEIVED: {
            const isLastPeriodUpdated = payload?.objs?.some((bill: BillingStatement) => bill.type === BillingStatementType.EndOfYearDiscount);
            if (isLastPeriodUpdated) {
                return {
                    ...state,
                    billingStatements: Array.isArray(payload.objs) ? payload.objs : []
                };
            }
            return state;
        }

        case agreementsType.AGREEMENTS_QUERY_ROAMING_USAGE_REQUEST: {
            return {
                ...state,
                usageResult: {
                    isLoading: true,
                    value: [],
                    pagination: []
                }
            };
        }

        case agreementsType.AGREEMENTS_QUERY_ROAMING_USAGE_SUCCESS: {
            const { items, pagination } = payload;
            return {
                ...state,
                usageResult: {
                    isLoading: false,
                    value: items,
                    pagination
                }
            };
        }
        case agreementsType.AGREEMENTS_QUERY_ROAMING_DISCREPANCY_SUCCESS: {
            return {
                ...state,
                roamingDiscrepancies: {
                    isLoading: false,
                    value: payload.items,
                    pagination: payload.pagination
                }
            };
        }
        case agreementsType.AGREEMENTS_QUERY_ROAMING_DISCREPANCY_FAIL: {
            return {
                ...state,
                roamingDiscrepancies: {
                    ...state.roamingDiscrepancies,
                    isLoading: false
                }
            };
        }
        case agreementsType.AGREEMENTS_QUERY_ROAMING_DISCREPANCY_REQUEST: {
            return {
                ...state,
                roamingDiscrepancies: {
                    ...state.roamingDiscrepancies,
                    isLoading: true
                }
            };
        }

        case agreementsType.SET_AGREEMENT_BREAKDOWN_MONTH: {
            return { ...state, breakdownMonthIndex: payload };
        }

        default:
            return state;
    }
}
