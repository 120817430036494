import * as React from 'react';
import { Box } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import { IconLabel } from '@components/IconLabel/IconLabel';
import { T } from '@components/T/T';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import debounce from 'lodash/debounce';
const { useCallback } = React;

interface ISelectableItems {
    isSelected: boolean;
    isFilteredIn: boolean;
}

interface IProps {
    disabled?: boolean;
    selectableItems: Record<string, ISelectableItems>;
    onItemClick(itemKey: string): void;
    onSearchBarChange(value: string): void;
}

const splitArrayToTwo = (arr: any[]): [any[], any[]] => {
    const middle = Math.ceil(arr.length / 2);
    const firstHalf = arr.slice(0, middle);
    const secondHalf = arr.slice(middle);
    return [firstHalf, secondHalf];
};

const useStyles = makeStyles({
    item: {
        cursor: 'pointer',
        height: 36,
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        '&:hover': {
            background: '#f3faff'
        },
        '&:disabled': {
            background: 'none',
            cursor: 'not-allowed'
        }
    },
    columnsWrapper: {
        flex: '1',
        display: 'grid',
        gridTemplateColumns: '1fr 1px 1fr',
        overflow: 'auto'
    },
    root: {
        flex: '10',
        display: 'flex',
        flexDirection: 'column'
    },
    inputWrapper: {
        height: 40,
        '& input': {
            borderRadius: 0,
            border: 'none',
            borderBottom: '1px solid #d9d9d9'
        }
    }
});

export const MultiSelect: React.FC<IProps> = (props) => {
    const { selectableItems, onItemClick, onSearchBarChange, disabled } = props;
    const classes = useStyles(props);
    let numOfChosenItems = 0;

    const generateOnClick = useCallback(
        (name: string) => () => {
            onItemClick(name);
        },
        [onItemClick]
    );

    const elementsToRender = Object.entries(selectableItems).map(([name, { isSelected, isFilteredIn }]) => {
        if (isSelected) {
            numOfChosenItems++;
        }
        return (
            isFilteredIn && (
                <Box
                    key={name}
                    component="button"
                    onClick={generateOnClick(name)}
                    className={classes.item}
                    px={3}
                    // @ts-ignore
                    disabled={disabled}
                    color={disabled ? 'rgba(0, 0, 0, 0.25)' : 'black'}
                >
                    {isSelected ? <IconLabel icon={<CheckIcon />} color="#108EE9" fontSize={20} width={'40px'} /> : <Box width={'40px'} />}
                    <T>{name}</T>
                </Box>
            )
        );
    });
    const debouncedSearchBarChange = useCallback(debounce(onSearchBarChange, 200), []);
    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        debouncedSearchBarChange(e.currentTarget.value);
    };

    const totalItems = elementsToRender.length;

    const splittedArrays = splitArrayToTwo(elementsToRender);
    return (
        <Box className={classes.root}>
            <Input
                disabled={disabled}
                placeholder="Search Countries"
                onChange={onChange}
                prefix={<SearchOutlined />}
                suffix={
                    <>
                        <Box component={'span'} color={'#108EE9'}>{`Selected ${numOfChosenItems}`}</Box>
                        <Box>{`\u00A0/ ${totalItems}`}</Box>
                    </>
                }
                className={classes.inputWrapper}
            />
            <Box className={classes.columnsWrapper}>
                {splittedArrays.map((elements, i) => (
                    <React.Fragment key={i}>
                        <Box component={'ul'} flex={'1'} py={3}>
                            {elements}
                        </Box>
                        {i === 0 && <Box width={'1px'} bgcolor={'#D9D9D9'} />}
                    </React.Fragment>
                ))}
            </Box>
        </Box>
    );
};
