import * as React from 'react';

import { Table } from '@components/Table';

const tableColumns = [
    { key: 'destination', title: 'DESTINATION' },
    { key: 'amtDiff', title: 'AMT DIFF' },
    { key: 'minsDiff', title: 'MINS DIFF' },
    { key: 'callsDiff', title: 'CALLS DIFF' },
    {
        key: 'amount',
        title: (
            <span className="thead-cell-items">
                <span>AMOUNT</span>
                <span>Ours | Theirs</span>
            </span>
        )
    },
    {
        key: 'minutes',
        title: (
            <span className="thead-cell-items">
                <span>MINUTES</span>
                <span>Ours | Theirs</span>
            </span>
        )
    },
    {
        key: 'calls',
        title: (
            <span className="thead-cell-items">
                <span>CALLS</span>
                <span>Ours | Theirs</span>
            </span>
        )
    }
];

const tableData: any[] = [];

export class RecordsList extends React.PureComponent<{}> {
    render() {
        return (
            <div className="records-list">
                <Table classes="record-list-table" cols={tableColumns} data={tableData} noMoreOption={true} notSortable={true} />
            </div>
        );
    }
}
