import React, { useState, useEffect } from 'react';
import { Modal, Input, Select } from 'antd';
import EditIcon from '@ant-design/icons/EditFilled';
import UserAddIcon from '@ant-design/icons/UserAddOutlined';
const { Option } = Select;
import { useDispatch, useSelector } from 'react-redux';
import userActions, { IUserData } from '@actions/users';
import { getGroups, getUserToEdit } from '@selectors/user';
import { MultiTagInput } from '@components/MultiTagInput';
import { isRoamingNodeSelector } from '@selectors/account';
import authActions, { CreateUserArgs } from '@actions/auth';

export const NewUserDialog = () => {
    const [userName, setUserName] = useState('');
    const [userGroupNames, setUserGroupNames] = useState<string[]>([]);
    const [tadigs, setTadigs] = useState<string[]>([]);
    const groups = useSelector(getGroups);
    const userToEdit = useSelector(getUserToEdit);
    const isEditMode = !!userToEdit;
    const dispatch = useDispatch();
    const isRoamingNode = useSelector(isRoamingNodeSelector);

    useEffect(() => {
        if (userToEdit) {
            const { username, groupNames, tadigCodes } = userToEdit;
            setUserName(username);
            setUserGroupNames(groupNames);
            if (tadigCodes) {
                setTadigs(tadigCodes);
            }
        }
    }, [userToEdit]);

    const setUserGroupName = (group: string) => setUserGroupNames([group]);

    const closeDialog = () => {
        const action = isEditMode
            ? userActions.setUserToEdit() // Clear user to update
            : userActions.toggleCreateUser(false);
        dispatch(action);
    };
    const onUserNameChange = (event: React.ChangeEvent<HTMLInputElement>) => setUserName(event.target.value.trim());
    const onTadigsChange = (newTadigs: string[]) => setTadigs(newTadigs);

    const save = () => {
        if (isEditMode) {
            const newData: Partial<IUserData> = {
                groupNames: userGroupNames
            };
            if (isRoamingNode) {
                newData.tadigCodes = tadigs;
            }
            dispatch(userActions.updateUser({ userId: userToEdit!.id, newData }));
        } else {
            const newUser: CreateUserArgs = {
                username: userName,
                groupNames: userGroupNames,
                isActive: false
            };
            if (isRoamingNode) {
                newUser.tadigCodes = tadigs;
            }
            dispatch(authActions.createUser(newUser));
        }
        closeDialog();
    };
    const isValid = !!userName && !!userGroupNames.length;
    return (
        <Modal
            className="new-user-dialog"
            title={
                <span className="dialog-title">
                    {isEditMode ? <EditIcon /> : <UserAddIcon />} {isEditMode ? 'Edit' : 'New'} User
                </span>
            }
            onCancel={closeDialog}
            visible
        >
            <div className="new-user-dialog-body">
                <Input placeholder="User Name" data-t="username-input" value={userName} onChange={onUserNameChange} disabled={isEditMode} />
                <Select placeholder="Select Role" data-t="role-select" onChange={setUserGroupName} className="group-select" value={userGroupNames[0]}>
                    {groups.map(({ name }) => (
                        <Option key={name} value={name}>
                            {name}
                        </Option>
                    ))}
                </Select>
                {isRoamingNode && <MultiTagInput className="tadig-input" addBtnTitle="Add Tadig" values={tadigs} onChange={onTadigsChange} />}
                <button disabled={!isValid} data-t="save-button" className="btn btn-primary" onClick={save}>
                    {isEditMode ? 'Update' : 'Create'} User
                </button>
            </div>
        </Modal>
    );
};
