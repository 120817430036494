import { createAction } from '@helpers/actionCreator';
import { NetworkStatus } from '@reducers/app';

export enum appType {
    SAGA_INIT_FINISHED = 'SAGA::INIT:FINISHED',
    APP_CONFIGURATION_STORE = 'APP::CONFIGURATION:STORE',
    SET_NETWORK_STATUS = 'APP::SET_NETWORK_STATUS'
}

export interface IStateApp {
    version: string;
    demoVoice: boolean;
    backendWsUrl: string;
    backendRestUrl: string;
    mode: string;
    isLocal: boolean;
    isMfaEnabled: boolean;
    isPasswordPolicyEnforced: boolean;
    networkStatus: NetworkStatus;
}

const appActions = {
    sagaInitFinished: createAction(appType.SAGA_INIT_FINISHED),
    appConfigurationStore: createAction(appType.APP_CONFIGURATION_STORE),
    setNetworkStatus: createAction(appType.SET_NETWORK_STATUS)
};

export default appActions;
