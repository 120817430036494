import React, { useEffect, useState } from 'react';
import { DatePicker, Select, Tooltip } from 'antd';
import { PerfSelect } from '@components/PerfSelect';
import 'antd/dist/antd.css';
import './styles.scss';
import moment from 'moment';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import MaskedInput from 'react-text-mask';
import { turnDateToUTC } from '@helpers/formaters';

const { Option } = Select;

interface IEditableCellProps<T> {
    value?: T;
    onChange: (value?: T) => void;
    error?: string;
    placeholder?: string;
}
interface IEditableDateProps extends IEditableCellProps<number> {
    format: string;
}
export const EditableDateCell = ({ value, format, onChange, error }: IEditableDateProps) => {
    const [editMode, setEditMode] = useState(false);
    const [stateValue, setStateValue] = useState<moment.Moment | null>(value ? moment.utc(value) : null);
    const startEdit = () => setEditMode(true);
    useEffect(() => {
        setStateValue(value ? moment.utc(value) : null);
    }, [value]);
    useEffect(() => {
        if (!editMode) {
            // Update onChange only after date picker is closed to avoid update loop
            const newValue = stateValue ? stateValue.valueOf() : undefined;
            if (newValue !== value) {
                onChange(newValue);
            }
        }
    }, [editMode, stateValue]);
    const openChanged = (opened: boolean) => {
        if (!opened) {
            setEditMode(false);
        }
    };
    const dateChanged = (date: any | null, _: string): void => setStateValue(date ? turnDateToUTC(date) : date);
    return editMode ? (
        <DatePicker open={true} onChange={dateChanged} onOpenChange={openChanged} format={format} value={(stateValue as any) || undefined} />
    ) : (
        <EditableDisplay error={error} onClick={startEdit}>
            {stateValue ? moment.utc(stateValue).format(format) : 'Add..'}
        </EditableDisplay>
    );
};
interface IEditableNumberProps extends IEditableCellProps<string> {
    mask?: any;
}
export const EditableCell = ({ value, mask, onChange, error, placeholder = 'Add..' }: IEditableNumberProps) => {
    const [editMode, setEditMode] = useState(false);
    const [stateValue, setStateValue] = useState(value);
    const onInputChange = (e: any) => setStateValue(e.target.value);
    const startEdit = () => setEditMode(true);
    useEffect(() => {
        setStateValue(value);
    }, [value]);
    const endEdit = () => {
        setEditMode(false);
        if (stateValue !== value) {
            onChange(stateValue);
        }
    };
    const onKeyPress = (event: any) => {
        if (event.key === 'Enter') {
            endEdit();
        }
    };
    const displayValue = [undefined, '', null].includes(stateValue) ? <span className="placeholder">{placeholder}</span> : stateValue;
    return editMode ? (
        mask ? (
            <MaskedInput
                className="editable-input"
                autoFocus
                mask={mask}
                value={stateValue}
                onChange={onInputChange}
                onBlur={endEdit}
                onKeyPress={onKeyPress}
            />
        ) : (
            <input className="editable-input" autoFocus value={stateValue} onChange={onInputChange} onBlur={endEdit} onKeyPress={onKeyPress} />
        )
    ) : (
        <EditableDisplay tabable={true} error={error} onClick={startEdit}>
            {displayValue}
        </EditableDisplay>
    );
};
type optionsGetter = () => string[];
interface ISelCellProps extends IEditableCellProps<string> {
    options: string[] | optionsGetter;
}
export const SelectableCell = ({ value, options, onChange, error, placeholder = 'Select..' }: ISelCellProps) => {
    const [editMode, setEditMode] = useState(false);
    const [stateValue, setStateValue] = useState(value);
    const startEdit = () => setEditMode(true);
    const endEdit = () => setEditMode(false);
    useEffect(() => {
        setStateValue(value);
    }, [value]);
    useEffect(() => {
        if (!editMode && stateValue !== value) {
            onChange(stateValue);
        }
    }, [editMode, stateValue]);
    const onSelectChanged = (newVal: string) => {
        setStateValue(newVal);
        endEdit();
    };
    const getOptions = () => (Array.isArray(options) ? options : (options as optionsGetter)());
    const displayValue = [undefined, '', null].includes(stateValue) ? <span className="placeholder">{placeholder}</span> : stateValue;
    // TODO - improve performance when there are many options https://github.com/ant-design/ant-design/issues/3789
    return editMode ? (
        <PerfSelect
            className="editable-select"
            allowClear
            defaultOpen
            showSearch
            placeholder={placeholder}
            value={stateValue}
            onChange={onSelectChanged}
            onBlur={endEdit}
            dropdownMatchSelectWidth={false}
        >
            {getOptions().map((option) => (
                <Option key={option} value={option}>
                    {option}
                </Option>
            ))}
        </PerfSelect>
    ) : (
        <EditableDisplay error={error} onClick={startEdit}>
            {displayValue}
        </EditableDisplay>
    );
};
interface IErrorTooltipProps {
    error?: string;
    onClick: () => void;
    tabable?: boolean;
}
const EditableDisplay = ({ children, onClick, error, tabable = false }: React.PropsWithChildren<IErrorTooltipProps>) => {
    const span = (
        <span {...(tabable && { tabIndex: 0 })} className={'editable-cell' + (error ? ' error' : '')} onFocus={onClick} onClick={onClick}>
            {children}
            <EditOutlinedIcon />
        </span>
    );
    return error ? (
        <Tooltip overlayClassName="editable-error" placement="bottomLeft" title={error}>
            {span}
        </Tooltip>
    ) : (
        span
    );
};
