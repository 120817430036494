import * as React from 'react';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';

import { IRootState } from '@reducers/index';
import { isAuthenticated } from '@selectors/auth';
import { getIsUserAdmin as isUserAdminSelector, getIsUserManagerOrAdmin, getUserGroup } from '@selectors/account';
import { LOGIN_REDIRECT } from '@actions';

interface IProps {
    component: any;
    path: string;
    authorizedGroups?: ReadonlyArray<string>;
    userAuthGroup?: string;
    isUserAuthenticated?: boolean;
    exact?: boolean;
    location?: any;
    computedMatch?: any;
}

@connect(
    createStructuredSelector<IRootState, any>({
        isUserAuthenticated: isAuthenticated,
        isUserAdmin: isUserAdminSelector,
        isUserAdminOrManager: getIsUserManagerOrAdmin,
        userAuthGroup: getUserGroup
    })
)
export class PrivateRoute extends React.Component<IProps> {
    render() {
        const { isUserAuthenticated, userAuthGroup, authorizedGroups, ...rest } = this.props;

        if (authorizedGroups && !authorizedGroups.includes(userAuthGroup as string)) {
            return <h1>You are not allowed to view this page</h1>;
        }

        if (!isUserAuthenticated) {
            return (
                <Redirect
                    to={{
                        pathname: '/auth/login',
                        search: `?${LOGIN_REDIRECT}=${this.props.location.pathname + this.props.location.search}`
                    }}
                />
            );
        }
        return <Route {...rest} />;
    }
}
