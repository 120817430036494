import { partnerType } from '@actions/RatesSheetParser/partners';
import { IAppAction } from './../state';

export interface IPartnersServerDataBySupplier {
    partner_id: string;
    supplier: string;
    short_name: string;
    sheet_name: string;
    contact: string;
    last_updated: string;
    premium_service: boolean;
    standard_service: boolean;
    currency: string;
    pop: string;
    is_active: boolean;
    rate_format: string;
}

export interface IStatePartners {
    editingOrAddingFinished: boolean;
    loading: boolean;
    suppliers: string[];
    data: IPartnersServerDataBySupplier[];
}

const initialState = {
    editingOrAddingFinished: false,
    loading: false,
    suppliers: [] as string[],
    data: [] as IPartnersServerDataBySupplier[]
};

export function partnersReducer(state = initialState, action: IAppAction): IStatePartners {
    switch (action.type) {
        case partnerType.PARTNERS_GET_SUPPLIERS_LIST_REQUEST:
            return { ...state, loading: true };
        case partnerType.PARTNERS_GET_SUPPLIER_INFO_REQUEST:
            return { ...state, loading: true };
        case partnerType.PARTNERS_EDIT_SUPPLIER_INFO_REQUEST:
        case partnerType.PARTNERS_ADD_SUPPLIER_INFO_REQUEST:
            return { ...state, editingOrAddingFinished: false };
        case partnerType.PARTNERS_GET_SUPPLIER_INFO_SUCCESS:
            return { ...state, data: action.payload, loading: false };
        case partnerType.PARTNERS_GET_SUPPLIERS_LIST_SUCCESS:
            return { ...state, suppliers: action.payload, loading: false };
        case partnerType.PARTNERS_EDIT_SUPPLIER_INFO_SUCCESS:
        case partnerType.PARTNERS_ADD_SUPPLIER_INFO_SUCCESS:
        case partnerType.PARTNERS_ADD_SUPPLIER_INFO_FAIL:
        case partnerType.PARTNERS_EDIT_SUPPLIER_INFO_FAIL:
            return { ...state, editingOrAddingFinished: true };
        case partnerType.PARTNERS_GET_SUPPLIERS_LIST_FAIL:
            return { ...state, loading: false };
        case partnerType.PARTNERS_GET_SUPPLIER_INFO_FAIL:
            return { ...state, loading: false };
        default:
            return state;
    }
}
