import { createSelector } from 'reselect';
import { getCurrencySymbol } from '@constants';
import map from 'lodash/map';
import { formatBalance } from '@helpers/formaters';
import {
    agreementsSelector,
    agreementsSelectorWithProps,
    getCurrentAgreement,
    getCurrentInfo,
    ISubcontract,
    selectCurrentSubcontracts
} from '@selectors/roaming';
import { IAgreementsReducer } from '@reducers/agreements';
import { Currency } from '@helpers/currency';
import moment from 'moment';

export const convertDiscrepancyDto = (discrepancy: any, subcontracts: ISubcontract[], contractStartMonth: number) => {
    const subcontract = subcontracts.find((s) => s.myTadigCodes.concat(s.theirTadigCodes).includes(discrepancy.vpmn));
    const OriginalMonthIndex = discrepancy.monthIndex + (contractStartMonth || 0);

    // NOTE: adding 1 hour is INTENTIONAL! the excel export screws up the date and may create a negative offset
    // so the date would end up being a day earlier than expected
    const date = new Date(Date.UTC(discrepancy.year, OriginalMonthIndex, 1, 1));
    return {
        ...discrepancy,
        date,
        subcontract
    };
};

export const getRoamingDiscrepancies = createSelector(
    agreementsSelectorWithProps,
    selectCurrentSubcontracts,
    getCurrentAgreement,
    ({ agreements }, subcontracts, contract) => {
        const { isLoading, value, pagination } = agreements.roamingDiscrepancies;
        const contractStartMonth = contract?.config?.settlementStartMonth;
        return {
            isLoading,
            pagination,
            value: value.map((item: any) => convertDiscrepancyDto(item, subcontracts, contractStartMonth))
        };
    }
);

export const getRoamingUsage = createSelector(agreementsSelector, getCurrentInfo, (agreement, currentInfo) => {
    const currencyCode = currentInfo.terms.currency;
    return {
        isLoading: agreement.usageResult.isLoading,
        pagination: agreement.usageResult.pagination,
        value: map(agreement.usageResult.value, (item: any) => {
            return {
                ...item,
                rate: new Currency(item.rate, currencyCode),
                charge: new Currency(item.charge, currencyCode),
                traffic: formatBalance(item.traffic, 0, { roundToInteger: true })
            };
        })
    };
});

export const getShouldShowCreateContractDialog = createSelector(
    agreementsSelector,
    ({ showCreateContractDialog }: IAgreementsReducer) => showCreateContractDialog
);

export const getShouldShowReplaceContractDialog = createSelector(
    agreementsSelector,
    ({ showReplaceContractDialog }: IAgreementsReducer) => showReplaceContractDialog
);
