import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { createStructuredSelector } from 'reselect';

import { IRootState } from '@reducers/index';
import { getNodeName } from '@selectors/account';
import { Brand } from './Brand';
import { AuthRoutes } from './routes';

import './styles.scss';

interface IProps extends RouteComponentProps<void> {
    appNode: string;
}

@connect(
    createStructuredSelector<IRootState, any>({
        appNode: getNodeName
    })
)
export class AuthContainer extends React.Component<IProps> {
    constructor(props: IProps, context?: any) {
        super(props, context);
    }

    render() {
        return (
            <div className="auth">
                <Brand />
                <section className="interaction">
                    <AuthRoutes node={this.props.appNode} />
                </section>
            </div>
        );
    }
}
