import { createSelector } from 'reselect';
import { formatBalance } from '@helpers/formaters';
import { IRootState } from '@reducers/index';
import { IStateBalance } from '@actions/balance';
import { getLocationObject } from '@selectors/router';
import { getCurrencySymbol } from '@constants';
import get from 'lodash/get';
import find from 'lodash/find';
import {
    agreementsSelectorWithProps,
    selectBillingStatements,
    getCurrentInfo,
    IAgreementsReducerWithProps,
    IBillingStatementBalanceForUsage,
    ICurrentDocumentInfo
} from '@selectors/roaming';
import { BillingStatement } from '@clearblockchain/shared/billing-statement';

const balanceSelector = (state: IRootState): IStateBalance => state.balance;

export interface IFormatedBalance extends IStateBalance {
    marginSign: string;
    percentSign: string;
}

export const getBalance = createSelector(
    balanceSelector,
    (balanceState: IStateBalance): IFormatedBalance => ({
        eusdBalance: formatBalance(balanceState.eusdBalance),
        accounts: formatBalance(balanceState.accounts),
        eur: formatBalance(balanceState.eur),
        gbp: formatBalance(balanceState.gbp),
        jpy: formatBalance(balanceState.jpy),
        margin: formatBalance(balanceState.margin),
        marginSign: parseFloat(balanceState.margin) >= 0 ? '+' : '-',
        percent: formatBalance(balanceState.percent),
        percentSign: parseFloat(balanceState.percent) >= 0 ? '+' : '-',
        purchased: formatBalance(balanceState.purchased),
        rmb: formatBalance(balanceState.rmb),
        usdBalance: formatBalance(balanceState.usdBalance),
        usd: formatBalance(balanceState.usd),
        withdrawal: formatBalance(balanceState.withdrawal),
        withdrawn: formatBalance(balanceState.withdrawn),
        hasLoaded: balanceState.hasLoaded,
        updatedTime: balanceState.updatedTime,
        totalEusdBalance: formatBalance(balanceState.totalEusdBalance),
        payableInvoicesTotal: formatBalance(balanceState.payableInvoicesTotal),
        receivableInvoicesTotal: formatBalance(balanceState.receivableInvoicesTotal)
    })
);

export const getBalanceForUsage = createSelector(
    [agreementsSelectorWithProps, getLocationObject, selectBillingStatements, getCurrentInfo],
    (
        { agreements, props }: IAgreementsReducerWithProps,
        location,
        billingStatements: BillingStatement[],
        currentInfo: ICurrentDocumentInfo
    ): IBillingStatementBalanceForUsage => {
        const index = props.index !== undefined ? props.index : +props.match.params.index;
        const partyIndex = props.partyIndex !== undefined ? props.partyIndex : location.query.partyIndex;
        const currency = getCurrencySymbol(currentInfo.terms.currency);
        const billingStatement = find(billingStatements, { index }) as BillingStatement;
        const { myName, counterName, isMyContract } = currentInfo;
        if (billingStatement && billingStatement.settlementFileHash) {
            const dataKey = partyIndex === '0' ? 'p1Data' : 'p2Data';

            const multiplier = isMyContract ? 1 : -1;
            const myNetPosition = get(billingStatement, `settlementResults.result.balances[${isMyContract ? '0' : '1'}]`) * multiplier;
            const counterNetPosition = get(billingStatement, `settlementResults.result.balances[${isMyContract ? '1' : '0'}]`) * multiplier;

            const myInboundChargesKey = isMyContract ? 'p1InboundCharges' : 'p1OutboundCharges';
            const myOutboundChargesKey = isMyContract ? 'p1OutboundCharges' : 'p1InboundCharges';

            const myOutbound = get(billingStatement, `settlementResults.result.${dataKey}.${myOutboundChargesKey}`);
            const myInbound = get(billingStatement, `settlementResults.result.${dataKey}.${myInboundChargesKey}`);

            const postSplit = get(billingStatement, `settlementResults.result.balancesAvg`) * multiplier;
            const netDifference = get(billingStatement, `settlementResults.result.balancesDiff`);

            return {
                myName,
                counterName,
                totalOutbound: formatBalance(myOutbound, 2, { currency, absolute: true }),
                totalInbound: formatBalance(myInbound, 2, { currency, absolute: true }),
                myNetPosition: formatBalance(myNetPosition, 2, { currency, absolute: true }),
                counterNetPosition: formatBalance(counterNetPosition, 2, { currency, absolute: true }),
                netDifference: formatBalance(netDifference, 2, { currency, absolute: true }),
                postSplit: formatBalance(postSplit, 2, { currency, absolute: true }),
                isPostSplitReceivable: Number(postSplit) >= 0,
                isMyNetReceivable: Number(myNetPosition) >= 0,
                isCounterNetReceivable: Number(counterNetPosition) >= 0
            };
        } else {
            return {
                myName,
                counterName,
                totalOutbound: '...',
                totalInbound: '...',
                myNetPosition: '...',
                counterNetPosition: '...',
                netDifference: '...',
                postSplit: '...',
                isPostSplitReceivable: false,
                isCounterNetReceivable: false,
                isMyNetReceivable: false
            };
        }
    }
);
