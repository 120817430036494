import { all, put, fork } from 'redux-saga/effects';

import appActions from '@actions/app';
import { sagaAuth } from './auth';
import { sagaCommunication } from './communication';
import { sagaAppConfiguration } from './configuration';
import { sagaAgreement } from './agreement';
import { sagaVoice } from './voice';

export const rootSaga = function*() {
    yield all([fork(sagaAppConfiguration), fork(sagaAuth), fork(sagaCommunication), fork(sagaAgreement), fork(sagaVoice)]);

    yield put(appActions.sagaInitFinished());
};
