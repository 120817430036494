import * as React from 'react';
import { BackButton } from '@components/Buttons';

import { Box, Theme } from '@material-ui/core';
import { T } from '@components/T/T';
import { ExtendedTheme, FontWeight } from '@components/theme';
import { makeStyles } from '@material-ui/core/styles';

interface Props {
    withBackButton?: boolean;
    title: string;
}

const useStyles = makeStyles((theme: ExtendedTheme) => ({
    root: {
        background: theme.palette.common.white
    }
}));

export const SubHeader: React.FC<Props> = ({ title, children, withBackButton }) => {
    const classes = useStyles();
    return (
        <Box display="flex" p={6} pb={4} alignItems="flex-start" className={classes.root}>
            {withBackButton && (
                <Box mr={4}>
                    <BackButton />
                </Box>
            )}
            <Box mr={4} style={{ lineHeight: 0 }}>
                <T weight={FontWeight.Semibold} variant="h6" lineHeight={1}>
                    {title}
                </T>
            </Box>
            {children}
        </Box>
    );
};
