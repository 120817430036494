import { Box } from '@material-ui/core';
import React from 'react';

export const PADDING_X = 6;

export const MainWrapper: React.FC<{ children: React.ReactNode; className?: string }> = ({ children, className }) => (
    <Box p={PADDING_X} flex={1} bgcolor={'#F7F7F8'} className={className}>
        {children}
    </Box>
);
