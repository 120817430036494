import React from 'react';
import { Box } from '@material-ui/core';

export const PageWrapper: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    return (
        <Box minHeight={'calc(100vh - 104px)'} display={'flex'} flexDirection={'column'}>
            {children}
        </Box>
    );
};
