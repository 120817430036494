import * as React from 'react';
import find from 'lodash/find';
import cn from 'classnames';

interface ITRowProps {
    row: any;
    cols: any;
    colsOrder: string[];
}
const Cell = (props: any) => {
    const { item, cols, cell } = props;
    const column = find(cols, { key: cell });
    const classNameCell = column.classNameCell || cell;
    return <div className={`td ${classNameCell}`}>{item[cell] && <div className="col-content">{item[cell]}</div>}</div>;
};

export class TRowExtended extends React.Component<ITRowProps> {
    renderExtended() {
        const { row, colsOrder, cols } = this.props;
        const getExtendedKeys = Array.from(row.extended.keys());

        return getExtendedKeys.map((key: any) => {
            const value = row.extended.get(key);

            return value.map((item: any, index: any) => (
                <div
                    className={cn(`tr`, key, {
                        last: index === value.length - 1
                    })}
                    key={index}
                >
                    {colsOrder.map((cell: any, i: any) => (
                        <Cell key={i} cell={cell} cols={cols} item={item} />
                    ))}
                </div>
            ));
        });
    }
    render() {
        const { row, colsOrder, cols } = this.props;
        const trClassName = cn(`tr`, row.rowClass, { extended: row.extended });
        return (
            <div className="row-extended">
                <div className={trClassName}>
                    {colsOrder.map((cell) => {
                        const column = find(cols, { key: cell });
                        const classNameCell = column.classNameCell || cell;
                        return (
                            <div key={cell} className={`td ${classNameCell}`}>
                                {row[cell] && <div className="col-content">{row[cell]}</div>}
                            </div>
                        );
                    })}
                </div>
                {this.renderExtended()}
            </div>
        );
    }
}
