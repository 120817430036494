import React from 'react';
import { ISettlement } from '@reducers/voice';
import { getCurrentSettlementInfo } from '@selectors/voice';
import { formatBalance, formatPeriod } from '@helpers/formaters';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { IRootState } from '@reducers';
import { Dispatch, bindActionCreators } from 'redux';
import { getCurrencySymbol } from '@constants';
import { getFilters, getFiltersPanelStatus, getPresentationFilters } from '@selectors/queryFilters';
import queryFiltersActions from '@actions/queryFilters';
import { Drawer, ExpansionPanel } from '@components';
import { IStringFilterValue, StringInput } from '@components/FiltersPane/StringInput';
import { MultiSelect } from '@components/FiltersPane/MultiSelect';
import { MultiSelectSection } from '@components/FiltersPane/MultiSelectSection';
import { DateRange } from '@components/FiltersPane/DateRange';
import { INumericFilterValue, NumberInput, OperationType } from '@components/FiltersPane/NumberInput';
import xor from 'lodash/xor';
import omit from 'lodash/omit';
import { updateFiltersInUrl, extractFiltersFromUrl } from './filtersUrlFunctions';
import { withRouter } from 'react-router';

export enum FilterItemType {
    Select = 'select',
    SearchSelect = 'searchSelect',
    Date = 'date',
    Number = 'number',
    String = 'string'
}
export enum FilterItemSubtype {
    Money = 'money',
    Single = 'single',
    DateTime = 'datetime'
}

const MAX_SUMMARY_LENGTH = 10;

interface IStoreToProps {
    isOpen: boolean;
    settlement?: ISettlement;
    filtersInState: Record<string, any>;
    presentationFiltersInState: any;
}

interface IPropsFromParent {
    currency: string;
    rowFilterValues?: { [key: string]: any } | null;
    items: IFilterItem[];
    useHistory?: boolean;
}

const mapStateToProps = createStructuredSelector<IRootState, any, any>({
    isOpen: getFiltersPanelStatus,
    settlement: getCurrentSettlementInfo,
    filtersInState: getFilters,
    presentationFiltersInState: getPresentationFilters
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    actions: bindActionCreators({ ...queryFiltersActions }, dispatch)
});

interface IProps extends IPropsFromParent, IStoreToProps {
    location: Location;
    history: any;
    actions: typeof queryFiltersActions;
}

export interface IFilterItem {
    type: FilterItemType;
    subType?: FilterItemSubtype;
    title: string;
    key: string;
    options?: string[];
    data?: any;
}

interface IFilterState extends Record<string, any> {
    cleared: boolean;
    canApply?: boolean;
    outputFilters: { [key: string]: any };
    summary: { [key: string]: string };
    openItemKey?: string;
}

interface IFilterPanelState {
    filtersState: IFilterState;
}

class FiltersPanel extends React.PureComponent<IProps, IFilterPanelState> {
    state = {
        filtersState: {
            cleared: true,
            outputFilters: {},
            summary: {},
            dateFilter: undefined,
            canApply: false,
            openItemKey: ''
        }
    };

    constructor(props: any) {
        super(props);
        const { location } = this.props;
        if (location) {
            const filtersFromUrl = extractFiltersFromUrl(location);
            if (filtersFromUrl) {
                this.getFiltersFromUrlAndFetchDataFromServer(filtersFromUrl);
            }
        }
    }

    componentWillUnmount(): void {
        this.clearFilters();
    }

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IFilterPanelState>, snapshot?: any): void {
        // update local state to fit global state.
        if (prevProps.filtersInState !== this.props.filtersInState) {
            const { filtersState } = this.state;
            const { canApply, cleared, openItemKey } = filtersState;
            const { filtersInState, presentationFiltersInState, location, history, useHistory } = this.props;
            const newFilterState: { [key: string]: any } = {
                canApply,
                cleared,
                openItemKey,
                outputFilters: filtersInState,
                summary: presentationFiltersInState
            };
            const filtersToUrl: any = {};
            Object.keys(filtersInState).map((filterKey: string) => {
                if (filtersInState[filterKey] && (filtersState as any)[filterKey]) {
                    newFilterState[filterKey] = filtersToUrl[filterKey] = (filtersState as any)[filterKey];
                }
            });
            this.setState({ filtersState: newFilterState as any });
            if (useHistory) {
                updateFiltersInUrl(filtersToUrl, location!, history!);
            }
        }
        const { rowFilterValues } = this.props;
        if (prevProps.rowFilterValues !== this.props.rowFilterValues) {
            if (rowFilterValues && Object.keys(rowFilterValues).length > 0) {
                this.setExternalValues(rowFilterValues, this.getCurrencySymbol());
            } else if (prevProps.rowFilterValues && rowFilterValues === null) {
                this.clearFilters();
            }
        }
    }

    getFiltersFromUrlAndFetchDataFromServer(filtersFromUrl: { [key: string]: any }) {
        const { actions } = this.props;
        const filterStateDuplicate: { [key: string]: any } = {
            ...this.state.filtersState,
            ...filtersFromUrl
        };
        [filterStateDuplicate.outputFilters, filterStateDuplicate.summary] = this.getFiltersAndSummary(filtersFromUrl, true);
        // @ts-ignore
        this.state = { filtersState: filterStateDuplicate };
        actions.setFilters({
            filtersForServer: filterStateDuplicate.outputFilters,
            filtersForPresentation: filterStateDuplicate.summary
        });
    }

    clearFilters = () => this.props.actions.clearAllFilters();

    getCurrencySymbol = () => getCurrencySymbol(this.props.settlement!.accountCurrency);

    getFiltersAndSummary = (values: any, isRenderFiltersFromUrl: boolean = false): [{ [key: string]: any }, { [key: string]: any }] => {
        const filters: { [key: string]: any } = {};
        const summary: { [key: string]: any } = {};
        const truncate = (val: string) => (val.length <= MAX_SUMMARY_LENGTH && val) || `${val.substr(0, MAX_SUMMARY_LENGTH - 1)}..`;
        this.props.items.forEach((item) => {
            const { key, type, subType, options, data } = item;
            const value = values[key];
            if (!value) {
                return;
            }
            let valueToAssign = null;
            let summaryVal = null;
            switch (type) {
                case FilterItemType.SearchSelect:
                case FilterItemType.Select:
                    if (value && value.length) {
                        valueToAssign = value;
                        const formatter = data && data.formatter;
                        if (formatter) {
                            valueToAssign = valueToAssign.map(
                                (str: string) => (options as string[]).find((option) => formatter(option) === str) || str
                            );
                        }
                        summaryVal = truncate(valueToAssign.map(truncate).join(','));
                        if (subType === FilterItemSubtype.Single) {
                            valueToAssign = valueToAssign[0];
                            summaryVal = truncate(valueToAssign);
                        }
                    }
                    break;
                case FilterItemType.Number:
                    valueToAssign = this.processNumericFilter(value);
                    if (valueToAssign) {
                        summaryVal = this.processNumericFilter(value, item);
                    }
                    break;
                case FilterItemType.Date:
                    if (isRenderFiltersFromUrl) {
                        valueToAssign = [+value.startDate, +value.endDate];
                        summaryVal = truncate(formatPeriod(...(valueToAssign as [number, number])));
                    } else {
                        const { startDate, endDate } = value;
                        if (startDate && endDate && endDate >= startDate) {
                            valueToAssign = [+startDate / 1000, +endDate / 1000];
                            summaryVal = truncate(formatPeriod(...(valueToAssign as [number, number])));
                        }
                    }
                    break;
                case FilterItemType.String:
                    const { outputValue } = value as IStringFilterValue;
                    if (outputValue) {
                        valueToAssign = outputValue;
                        summaryVal = valueToAssign.replace(/%/g, '..');
                    }
                    break;
            }
            if (valueToAssign !== null) {
                filters[key] = valueToAssign;
                summary[key] = summaryVal;
            }
        });
        return [filters, summary];
    };

    applyFilters = (shouldClear: boolean, keyToClear: string = '') => () => {
        let values: any = shouldClear ? this.getInitialValues() : this.getValues();
        if (keyToClear) {
            // @ts-ignore
            values = omit(values, keyToClear);
            delete values.outputFilters[keyToClear];
            delete values.summary[keyToClear];
        }
        const { outputFilters, summary } = values;
        const cleared = Object.keys(outputFilters).length === 0;
        this.onFiltersChange({
            ...values,
            cleared,
            canApply: !cleared,
            appliedSummary: summary
        });
        this.props.actions.setFilters({
            filtersForServer: outputFilters,
            filtersForPresentation: summary
        });
        this.closeFiltersPanel();
    };

    onSelectChange = (key: string, option: string | string[] | []) => {
        // @ts-ignore
        const chosen = this.getValues()[key] || [];
        const item = this.props.items.find(({ key: itemKey }) => itemKey === key) as IFilterItem;
        let valueToSet;
        if (typeof option === 'string') {
            valueToSet = item.subType === FilterItemSubtype.Single ? (chosen[0] === option ? [] : [option]) : xor(chosen, [option]);
        } else {
            option.length === 0 ? (valueToSet = option) : (valueToSet = Array.from(new Set([...chosen, ...option])));
        }
        this.setValue(key, valueToSet);
    };

    getInitialValues = (): IFilterState => ({
        cleared: true,
        outputFilters: {},
        summary: {}
    });
    getValues = () => this.state.filtersState || this.getInitialValues();

    setExternalValues = (values: { [key: string]: any }, currencySymbol: string) => {
        const newValues: typeof values = {};
        this.props.items.forEach(({ key, type, subType }) => {
            const val = values[key];
            if (val !== undefined) {
                switch (type) {
                    case FilterItemType.Date:
                        const [from, to] = val;
                        newValues[key] = DateRange.reverseEngineerValue(from, to, subType !== FilterItemSubtype.DateTime);
                        break;
                    case FilterItemType.SearchSelect:
                    case FilterItemType.Select:
                        newValues[key] = Array.isArray(val) ? val : [val];
                        break;
                    case FilterItemType.Number:
                        const value: INumericFilterValue = Array.isArray(val)
                            ? {
                                  type: OperationType.Between,
                                  value1: val[0],
                                  value2: val[1]
                              }
                            : { type: val.op, value1: val.value };
                        newValues[key] = NumberInput.reverseEngineerValue(value, subType === FilterItemSubtype.Money ? currencySymbol : undefined);
                        break;
                    default:
                        newValues[key] = val;
                }
            }
        });
        this.setNewValues(newValues, {}, true);
    };

    setValue = (key: string, value: any) => {
        const { filtersState } = this.state;
        const updatedFilterStatus = { ...filtersState, [key]: value };
        this.setNewValues(updatedFilterStatus);
    };
    setNewValues = (newValues: { [key: string]: any }, oldValues?: { [key: string]: any }, shouldApplyFilters: boolean = false) => {
        [newValues.outputFilters, newValues.summary] = this.getFiltersAndSummary(newValues);
        const isEmpty = Object.keys(newValues.outputFilters).length === 0;
        newValues.canApply = !isEmpty || (oldValues && !oldValues.cleared);
        this.setState({ filtersState: newValues as any }, shouldApplyFilters ? this.applyFilters(false) : undefined);
        return newValues;
    };

    // tslint:disable-next-line:max-line-length
    formatNumber = (n: number, currencyFromValue: string | null, { subType }: IFilterItem) =>
        formatBalance(n, 2, {
            currency: subType === FilterItemSubtype.Money ? currencyFromValue || getCurrencySymbol(this.props.currency) : undefined,
            roundToInteger: true
        });

    processNumericFilter = ({ type: op, value1, value2, typed1 }: INumericFilterValue, itemForSummary?: IFilterItem) => {
        if (isNaN(value1 as number)) {
            return null;
        }
        const currencyFromValue = typed1 ? typed1[0] : null;
        if (op.toString() === 'range') {
            if (isNaN(value2 as number) || (value1 as number) > (value2 as number)) {
                return null;
            }
            return itemForSummary
                ? `${this.formatNumber(value1!, currencyFromValue, itemForSummary)}..${this.formatNumber(value2!, currencyFromValue, itemForSummary)}`
                : [value1, value2];
        }
        return itemForSummary
            ? `${op === OperationType.GreaterThan ? '>' : '<'} ${this.formatNumber(value1!, currencyFromValue, itemForSummary)}`
            : { op, value: value1 };
    };

    renderItemStatus = ({ type, key }: IFilterItem) => {
        // @ts-ignore
        const value = this.getValues()[key];
        let gotValue = false;
        if (value) {
            switch (type) {
                case FilterItemType.SearchSelect:
                    const nChosen = value.length;
                    return nChosen ? <span className="chip">{nChosen}</span> : null;
                case FilterItemType.Select:
                    gotValue = !!value.length;
                    break;
                case FilterItemType.Date:
                    gotValue = !!value.startDate && !!value.endDate && value.endDate >= value.startDate;
                    break;
                case FilterItemType.Number:
                    gotValue = !!this.processNumericFilter(value);
                    break;
                case FilterItemType.String:
                    gotValue = !!(value as IStringFilterValue).outputValue;
                    break;
                default:
                    return value && value.isValid ? <span className="filter-got-value" /> : null;
            }
        }
        return gotValue ? <span className="filter-got-value" /> : null;
    };

    renderItem = (item: IFilterItem, currencySymbol: string) => {
        const { key, type, subType, options, data } = item;
        // @ts-ignore
        const value = this.getValues()[key];
        let SelectClass: typeof MultiSelect | typeof MultiSelectSection = MultiSelect;
        switch (type) {
            case FilterItemType.Date:
                return (
                    <DateRange
                        onChange={this.setValue.bind(this, key)}
                        value={value || data || {}}
                        dateFromUrl={this.state.filtersState.dateFilter}
                        dateOnly={subType !== FilterItemSubtype.DateTime}
                    />
                );
            case FilterItemType.Number:
                return (
                    <NumberInput
                        currencySymbol={subType === FilterItemSubtype.Money ? currencySymbol : ''}
                        onChange={this.setValue.bind(this, key)}
                        value={value || {}}
                    />
                );
            case FilterItemType.String:
                return <StringInput value={value || {}} onChange={this.setValue.bind(this, key)} />;
            case FilterItemType.SearchSelect:
                SelectClass = MultiSelectSection;
            case FilterItemType.Select:
                const formatter = data && data.formatter;
                return (
                    <SelectClass
                        options={formatter ? (options as string[]).map(formatter) : (options as string[])}
                        chosen={(value || []) as string[]}
                        toggleOption={this.onSelectChange.bind(this, key)}
                    />
                );
            default:
                return null;
        }
    };

    onItemToggle = (key: string) => () => {
        const KEY = 'openItemKey';
        const values = this.state.filtersState || {};
        // Set or clear the open item key
        // @ts-ignore
        this.setValue(KEY, values[KEY] === key ? null : key);
    };

    closeFiltersPanel = () => this.props.actions.closeFilterPanel();

    onFiltersChange = (filtersState: any) => this.setState({ filtersState });

    render() {
        const { isOpen, currency, items } = this.props;
        const { filtersState } = this.state;
        const currencySymbol = getCurrencySymbol(currency || '');

        if (!isOpen) {
            return null;
        }

        const { openItemKey, canApply } = (filtersState || {}) as any;

        return (
            <Drawer closeDrawer={this.closeFiltersPanel} classList="filters-drawer">
                <div className="filters-title">Add Filters</div>
                <div className={'filters-pane' + (canApply ? ' showing-actions' : '')}>
                    {items
                        .filter(({ options }) => !options || options.length)
                        .map((item: IFilterItem) => (
                            <ExpansionPanel key={item.key} isOpen={item.key === openItemKey} onToggle={this.onItemToggle(item.key)}>
                                <div className="item-header">
                                    {item.title}
                                    {this.renderItemStatus(item)}
                                </div>
                                <div className="item-body">{this.renderItem(item, currencySymbol)}</div>
                            </ExpansionPanel>
                        ))}
                </div>
                {canApply && (
                    <div className="form-actions">
                        <button className="reset" onClick={this.clearFilters}>
                            Reset Filters
                        </button>
                        <button className="apply" onClick={this.applyFilters(false, undefined)}>
                            Apply Filters
                        </button>
                    </div>
                )}
            </Drawer>
        );
    }
}

// @ts-ignore
const connectedFiltersPanel: React.FC<IPropsFromParent> = withRouter((props: any): JSX.Element => <FiltersPanel {...(props as IProps)} />);

export default connect<IStoreToProps, any>(mapStateToProps, mapDispatchToProps)(connectedFiltersPanel);
