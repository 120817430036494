import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Card, Divider, Form, message, Select, Skeleton, Upload } from 'antd';
import { T } from '@components/T/T';
import { Box } from '@material-ui/core';
import { useAction } from '../../../../../hooks/useAction';
import { countryActions } from '@actions/country';
import { useSelector } from 'react-redux';
import { IRootState } from '@reducers';
import { Separator } from '@components/Separator/Separator';
import { settingsActions } from '@actions/settings';
import SuccessIcon from '@ant-design/icons/CheckCircleOutlined';
import { exportTable } from '@helpers/exportToFile';
import XLSX from 'xlsx';
import { STANDARD_COUNTRY_MAPPING } from '@clearblockchain/shared/country';
import { selectCountryMappingId } from '@selectors/settings';
import moment from 'moment';
import { Prompt } from 'react-router';
import { ClearCard } from '@components/ClearCard/ClearCard';
import { CountryMappingTranslationTable } from '@modules/Auth/containers/Settings/CountryMapping/CountryMappingTranslationTable';
import { SuccessDialog } from '@components/Modal/SuccessDialog';

export const CountryMapping: React.FC = () => {
    const [form] = Form.useForm<{ mappingId: string; translations?: Record<string, string> }>();

    const { mappings, translations: currentlyUsedTranslations } = useSelector((state: IRootState) => state.country);
    const currentlyUsedMappingId = useSelector(selectCountryMappingId);

    const [isLoadingMappings, setIsLoadingMappings] = useState(true);
    const [isLoadingTranslations, setIsLoadingTranslations] = useState(false);

    const [isSaving, setIsSaving] = useState(false);
    const [showUploadSuccessfulDialog, setShowUploadSuccessfulDialog] = useState(false);

    const [displayedTranslations, setDisplayedTranslations] = useState(currentlyUsedTranslations);

    const getMappings = useAction(countryActions.getCountryMappings);
    const getTranslationsForDisplay = useAction(countryActions.getTemporaryCountryTranslations);
    const getTranslations = useAction(countryActions.getCountryTranslations);
    const replaceUserDefinedTranslations = useAction(countryActions.replaceUserDefinedTranslations);
    const setCountryMappingId = useAction(settingsActions.setCountryMappingId);

    const selectMapping = useCallback(
        async (id: string) => {
            form.resetFields(['translations']);
            setIsLoadingTranslations(true);
            const t = await getTranslationsForDisplay(id);
            setDisplayedTranslations(t);
            setIsLoadingTranslations(false);
        },
        [getTranslationsForDisplay]
    );

    const translationList = useMemo(() => {
        return Object.entries(displayedTranslations).map(([standard, translation]) => ({ standard, translation }));
    }, [displayedTranslations]);

    useEffect(() => {
        getMappings().then(() => {
            setIsLoadingMappings(false);
        });
    }, []);

    useEffect(() => {
        setDisplayedTranslations(currentlyUsedTranslations);
    }, [currentlyUsedTranslations]);

    const exportTranslations = useCallback(() => {
        const mappingId = form.getFieldValue('mappingId');
        const filename = `destination-names-${mappingId}_${moment(new Date()).format('YYYY-MM-DD_hh-mm')}`;
        const sheetName = `Destinations (${mappings[mappingId].name})`;
        const rows = [['Standard', 'Your Translation']].concat(translationList.map((t) => [t.standard, t.translation]));
        exportTable(filename, 'xlsx', sheetName, rows, [{ wch: 70 }, { wch: 70 }]);
    }, [translationList, mappings]);

    const importUserDefinedTranslations = useCallback(async (file: File): Promise<void> => {
        const raw = new Uint8Array(await (file as any).arrayBuffer());
        const workBook = XLSX.read(raw, { type: 'array' });
        const rows = XLSX.utils.sheet_to_json<{ standard: string; translation: string }>(workBook.Sheets[workBook.SheetNames[0]], {
            raw: false,
            header: ['standard', 'translation']
        });
        const translations = rows.slice(1).reduce<Record<string, string>>((trans, row) => {
            trans[row.standard] = row.translation || row.standard;
            return trans;
        }, {});

        form.resetFields(['translations', 'mappingId']);
        form.setFields([
            { name: 'translations', errors: [], value: translations, touched: true },
            { name: 'mappingId', value: 'user-defined', touched: true }
        ]);
        await form.validateFields(['translations']);
        setDisplayedTranslations(translations);
        setShowUploadSuccessfulDialog(true);
        return Promise.reject();
    }, []);

    const saveChanges = useCallback(
        async ({ mappingId, translations: importedTranslations }: { mappingId: string; translations?: Record<string, string> }) => {
            try {
                setIsSaving(true);
                if (mappings[mappingId].id === 'user-defined' && importedTranslations) {
                    await replaceUserDefinedTranslations(importedTranslations);
                }
                await setCountryMappingId(mappingId);
                await message.info({
                    content: 'Changes have been saved',
                    icon: <SuccessIcon color="blue" />,
                    style: {
                        marginTop: '20vh'
                    },
                    duration: 1.5
                });
                await form.resetFields();
                await getTranslations();
            } catch (e) {
                await message.error({
                    content: 'Failed to save changes, please try again or contact Clear',
                    style: {
                        marginTop: '20vh'
                    }
                });
            } finally {
                setIsSaving(false);
            }
        },
        [mappings]
    );

    const handleCountryMappingSelection = useCallback((mappingId: string) => {
        const translations = form.getFieldValue('translations');
        if (!translations) {
            return mappingId;
        }

        const shouldCompleteSelection = confirm('If you select a different set, your pending imported translations will be discarded. Continue?');
        if (shouldCompleteSelection) {
            return mappingId;
        } else {
            throw new Error('cancel select country mapping');
        }
    }, []);

    return (
        <>
            <Card bodyStyle={{ display: 'flex', flexDirection: 'column', height: '100%', padding: 32 }} style={{ width: 700, margin: '12px auto' }}>
                <Prompt message="You have unsaved changes, are you sure you want to continue?" when={form.isFieldsTouched()} />
                <Skeleton loading={isLoadingMappings}>
                    <T variant="body2">Destination Names</T>
                    <Divider />
                    <Box height="100%" display="flex" flexDirection="column" clone>
                        <Form form={form} initialValues={{ mappingId: currentlyUsedMappingId }} layout="vertical" onFinish={saveChanges}>
                            <Form.Item label="Select Destination Name Set" name="mappingId" getValueFromEvent={handleCountryMappingSelection}>
                                <Select
                                    onChange={selectMapping}
                                    data-t="country-map-selection"
                                    placeholder="Select a mapping"
                                    options={Object.values(mappings).map((set) => ({
                                        label: currentlyUsedMappingId === set.id ? [set.name, '(currently used)'].join(' ') : set.name,
                                        value: set.id
                                    }))}
                                />
                            </Form.Item>
                            <CountryMappingTranslationTable isLoading={isLoadingTranslations} translationList={translationList} />
                            <Box my={8} clone>
                                <Form.Item dependencies={['translations']} label="Create your User Defined Translations">
                                    {() => (
                                        <ClearCard flat style={{ padding: 2 }}>
                                            <ol style={{ listStyle: 'decimal', paddingLeft: 8, lineHeight: '2.5em' }}>
                                                <li>
                                                    <a onClick={exportTranslations}>Export Displayed Translations</a> and edit "Your Translation" in
                                                    Excel
                                                </li>
                                                <li>
                                                    <Form.Item
                                                        name="translations"
                                                        noStyle
                                                        valuePropName="file"
                                                        rules={[
                                                            ({ getFieldValue }) => ({
                                                                validator() {
                                                                    const translations = getFieldValue('translations');
                                                                    if (getFieldValue('mappingId') !== 'user-defined' || !translations) {
                                                                        return Promise.resolve();
                                                                    }

                                                                    const invalidStandardKeys = Object.keys(translations).filter(
                                                                        (standardKey: string) => !(standardKey in STANDARD_COUNTRY_MAPPING)
                                                                    );
                                                                    if (invalidStandardKeys.length > 0) {
                                                                        const error = `The file contains unknown Standard names: ${invalidStandardKeys
                                                                            .map((k) => `"${k}"`)
                                                                            .join(', ')}`;
                                                                        return Promise.reject(error);
                                                                    } else {
                                                                        return Promise.resolve();
                                                                    }
                                                                }
                                                            })
                                                        ]}
                                                    >
                                                        <Upload accept=".xlsx" listType="text" beforeUpload={importUserDefinedTranslations}>
                                                            <a>Import your modified Translations</a> and review your changes in the table above
                                                        </Upload>
                                                    </Form.Item>
                                                </li>
                                                <li>
                                                    <T>Don't forget to Save Changes!</T>
                                                </li>
                                            </ol>
                                        </ClearCard>
                                    )}
                                </Form.Item>
                            </Box>
                            <Separator />
                            <Box display="flex" justifyContent="flex-end">
                                <Button htmlType="submit" type="primary" disabled={isSaving}>
                                    Save Changes
                                </Button>
                            </Box>
                        </Form>
                    </Box>
                </Skeleton>
            </Card>
            {showUploadSuccessfulDialog && (
                <SuccessDialog
                    onClose={() => setShowUploadSuccessfulDialog(false)}
                    msg={'Country translation file uploaded successfully. Changes can be reviewed'}
                />
            )}
        </>
    );
};
