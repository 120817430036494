import { createSelector } from 'reselect';
import get from 'lodash/get';

import { IRootState } from '@reducers/index';
import { IStateAccount, NodeType } from '@reducers/account';

export const accountSelector = (state: IRootState): IStateAccount => state.account;

export enum OperationType {
    Create = 'create',
    Delete = 'delete',
    Read = 'read',
    Edit = 'edit',
    HidePartyServices = 'hidePartyServices'
}

export enum ResourceType {
    Contract = 'CONTRACT',
    SettlementOffer = 'SETTLEMENT-OFFER'
}
export const AdminAndManagers: ReadonlyArray<string> = ['administrators', 'managers'];

export const Admin: ReadonlyArray<string> = ['administrators'];

export const getUserEmail = createSelector(accountSelector, (account: IStateAccount): string => get(account, 'session.username', '...'));
export const getLastLogin = createSelector(accountSelector, (account: IStateAccount): Date => new Date(get(account, 'session.lastLogin')));

export const isUserAllowedToExport = createSelector(accountSelector, (account: IStateAccount): boolean =>
    AdminAndManagers.includes(account?.session?.groupNames[0] || '')
);

export const getUserNodes = createSelector(accountSelector, (account: IStateAccount): NodeType[] => get(account, 'nodeType', []));

export const getNodeType = createSelector(getUserNodes, (typeArray): NodeType => typeArray[0]);

export const isVoiceNodeSelector = createSelector(getNodeType, (nodeType: NodeType): boolean => nodeType === NodeType.Voice);

export const isRoamingNodeSelector = createSelector(getNodeType, (nodeType: NodeType): boolean => nodeType === NodeType.Roaming);

export const getNodeName = createSelector(accountSelector, (account: IStateAccount): string => get(account, 'nodeName'));

export const getPeerName = createSelector(accountSelector, (account: IStateAccount): string => get(account, 'peerName'));

export const getPeerPresentedName = createSelector(accountSelector, (account: IStateAccount): string => get(account, 'presentedName'));

export const getIsPaymentGateway = createSelector(accountSelector, (account: IStateAccount): string => get(account, 'isPaymentGateway'));

export const getMyBankAccounts = createSelector(accountSelector, ({ bankAccounts }) => bankAccounts);

export const getMyPeerId = createSelector(accountSelector, (account: IStateAccount): string => get(account, 'peerId'));

export const createIsOperationAllowedSelector = ({
    op,
    resource,
    isRestriction
}: {
    op: OperationType;
    resource: ResourceType;
    isRestriction?: boolean;
}) =>
    createSelector([getIsUserAdmin, accountSelector], (isUserAdmin: boolean, account: IStateAccount) => {
        if (isUserAdmin) {
            return isRestriction ? false : true;
        }
        const attributeName = [op, resource].join(':');
        return get(account, ['session', 'attributes', attributeName], false) === 'true';
    });

export const getIsUserAdmin = createSelector(accountSelector, (account: IStateAccount) => get(account, 'session.attributes.admin', false) === 'true');

export const getIsUserManagerOrAdmin = createSelector(accountSelector, (account: IStateAccount) => {
    const userGroupType = account?.session?.groupNames[0] || '';
    return AdminAndManagers.includes(userGroupType);
});

export const getUserGroup = createSelector(accountSelector, (account: IStateAccount) => {
    return account?.session?.groupNames[0];
});

export const getPeerType = createSelector(accountSelector, (account: IStateAccount): string => {
    if (account.peerName === 'Fidelity Investments') {
        return 'fi';
    }
    return '';
});

export const getUserName = createSelector(accountSelector, (account: IStateAccount): string => get(account, 'userName'));

export const getSessionUsername = createSelector(accountSelector, (account: IStateAccount): string => get(account, ['session', 'username']));

export const getUserInfoLoading = createSelector(accountSelector, (account: IStateAccount): boolean => get(account, 'loading'));
