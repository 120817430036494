import { createSelector } from 'reselect';
import { IRootState } from '@reducers/index';
import { IStateApp } from '@actions/app';

const appSelector = (state: IRootState): IStateApp => state.app;

export const getApp = createSelector(appSelector, (app: IStateApp): IStateApp => app);

export const getCsrfUrlSelector = createSelector(appSelector, (appState: IStateApp): string => getCsrfUrl(appState.backendWsUrl));
export const getBackendWSUrlSelector = createSelector(appSelector, (appState: IStateApp): string => appState.backendWsUrl);
export const getBackendRestUrlSelector = createSelector(appSelector, (appState: IStateApp): string => appState.backendRestUrl);
export const getBackendDownloadUrl = createSelector(getBackendRestUrlSelector, (uploadUrl: string): string =>
    uploadUrl.replace('upload', 'download')
);

export const getIsLocal = createSelector(appSelector, (appState: IStateApp) => appState.isLocal);
export const getDemoVoice = createSelector(appSelector, (appState: IStateApp) => appState.demoVoice);

function getCsrfUrl(backendWsUrl: string): string {
    const csrfUrlObj = new URL(backendWsUrl);
    csrfUrlObj.pathname = '/csrf';
    csrfUrlObj.protocol = csrfUrlObj.protocol === 'ws:' ? 'http:' : 'https:';
    return csrfUrlObj.toString();
}
