import React from 'react';
import { connect } from 'react-redux';
import { IRootState } from '@reducers';
import { Dispatch, bindActionCreators } from 'redux';
import queryFiltersActions from '@actions/queryFilters';
import { IFilterItem } from '../FiltersPanel';
import './styles.scss';

interface IStoreToProps {
    activeFilters: IFilterItem;
}

interface IPropsFromParent {
    items: IFilterItem[];
}

interface IProps extends IStoreToProps, IPropsFromParent {
    actions: typeof queryFiltersActions;
}

const mapStateToProps = (state: IRootState) => ({
    isOpen: state.queryFilters.isFiltersPanelOpen,
    activeFilters: state.queryFilters.filtersForPresentation
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    actions: bindActionCreators({ ...queryFiltersActions }, dispatch)
});

const bind = (func: (arg: any) => void, arg: any) => () => func(arg);
class ActiveFilters extends React.Component<IProps, any> {
    clearFilter = (filterKey: string) => this.props.actions.clearFilter(filterKey);

    openFiltersPanel = () => this.props.actions.openFilterPanel();

    render() {
        const { items, activeFilters } = this.props;
        const activeFiltersArray: any = Object.entries(activeFilters);
        return (
            <>
                {activeFilters
                    ? activeFiltersArray.map(([key, value]: string[]): any => {
                          const { title } = (items.find(({ key: iKey }) => key === iKey) || {}) as IFilterItem;
                          return (
                              <span className="active-filter" key={key}>
                                  {`${title}: ${value}`}
                                  <button name={key} onClick={bind(this.clearFilter, key)}>
                                      ×
                                  </button>
                              </span>
                          );
                      })
                    : null}
                <div className="add-filters" onClick={this.openFiltersPanel}>
                    + Add Filters
                </div>
            </>
        );
    }
}

const connectedActiveFilters: React.FC<IPropsFromParent> = (props: IPropsFromParent) => <ActiveFilters {...(props as IProps)} />;

export default connect<any, any>(mapStateToProps, mapDispatchToProps)(connectedActiveFilters);
