import * as React from 'react';
import { AnyAction, Dispatch } from 'redux';
import { RouteComponentProps } from 'react-router';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { getPeerName, getPeerPresentedName, getPeerType, getUserEmail, getUserNodes } from '@selectors/account';
import { getBalance } from '@selectors/balance';
import { getAuthToken } from '@selectors/auth';
import { getSpinner } from '@selectors/ui';
import communicationActions from '@actions/communication';
import { IStateBalance } from '@actions/balance';
import peerActions from '@actions/peers';
import notificationActions from '@actions/notification';
import authActions from '@actions/auth';
import uiActions from '@actions/ui';
import { HeaderComponent } from '../Header';
import DashboardRoutes from '../routes';
import { ReduxSpinner } from '@components/Spinner';
import { ModalContainer } from '@containers/ModalContainer';
import { listOfPossibleRoutingModules } from '@helpers/dynamicRoutes';

import '../styles.scss';
import transactionsActions, { agreementsType } from '@actions/agreements';
import { accountActions } from '@actions/account';

import { IRootState } from '@reducers/index';
import { IStateSpinner } from '@reducers/ui';
import voiceActions from '@actions/voice';
import { getDemoVoice } from '@selectors/app';
import { Footer } from '@modules/Dashboard/Footer/Footer';
import { SettlementOfferActionType } from '@actions/settlementOffers';
import { settingsActions } from '@actions/settings';
import { currencyActions } from '@actions/currency';
import { getShouldShowCreateContractDialog } from '@selectors/roaming';
import { CreateContractForm } from '@modules/Roaming/components/CreateContractForm/CreateContractForm';
import { Modal } from 'antd';
import { countryActions } from '@actions/country';

interface IProps extends RouteComponentProps<void> {
    balance: IStateBalance;
    appNode: string[];
    peerName: string;
    peerType: string;
    peerPresentedName: string;
    authToken: string;
    userEmail: string;
    spinner: IStateSpinner;
    loading: boolean;
    demoVoice: boolean;
    dispatchBalanceGet: () => AnyAction;
    dispatchTransactionsSubscribe: (name: string, type: string) => AnyAction;
    dispatchPeersGet: () => AnyAction;
    dispatchNotificationsGetAll: () => AnyAction;
    dispatchLogLogout: (manualLogout: boolean, lastPage: string) => AnyAction;
    dispatchLogoutRequest: (token: string) => AnyAction;
    dispatchCleanVoiceDemoData: () => AnyAction;
    dispatchResetVoiceDemoEnv: () => AnyAction;
    getInfoRequest: () => AnyAction;
    showSpinner: () => AnyAction;
    getSettings: () => AnyAction;
    getCurrencyRates: () => AnyAction;
    getCountryTranslations: () => AnyAction;
    shouldShowCreateContractDialog: boolean;
    closeCreateContractDialog(): AnyAction;
}

@connect(
    createStructuredSelector<IRootState, any>({
        appNode: getUserNodes,
        balance: getBalance,
        peerName: getPeerName,
        peerPresentedName: getPeerPresentedName,
        peerType: getPeerType,
        authToken: getAuthToken,
        userEmail: getUserEmail,
        spinner: getSpinner,
        demoVoice: getDemoVoice,
        shouldShowCreateContractDialog: getShouldShowCreateContractDialog
    }),
    (dispatch: Dispatch): any => ({
        dispatchPeersGet: (): AnyAction => dispatch(peerActions.peerGetAllRequest()),
        getInfoRequest: () => dispatch(accountActions.getInfoRequest()),
        dispatchTransactionsSubscribe: (name: string, type: string): AnyAction => dispatch(communicationActions.subscribeUpdating(name, type)),
        dispatchNotificationsGetAll: (): AnyAction => dispatch(notificationActions.notificationGetAll()),
        dispatchCleanVoiceDemoData: (): AnyAction => dispatch(voiceActions.voiceCleanDemoData()),
        dispatchResetVoiceDemoEnv: (): AnyAction => dispatch(voiceActions.voiceResetDemoEnv()),
        dispatchLogLogout: (manualLogout: boolean, lastPage: string) => dispatch(authActions.logLogoutEvent(manualLogout, lastPage)),
        dispatchLogoutRequest: (token: string): AnyAction => dispatch(authActions.logoutRequest(token)),
        showSpinner: (): AnyAction => dispatch(uiActions.showSpinner()),
        getSettings: () => dispatch(settingsActions.getSettings()),
        getCurrencyRates: () => dispatch(currencyActions.getCurrencyRates()),
        getCountryTranslations: () => dispatch(countryActions.getCountryTranslations()),
        closeCreateContractDialog: () => dispatch(transactionsActions.toggleShowCreateContract(false))
    })
)
export class DashboardContainer extends React.Component<IProps> {
    constructor(props: IProps) {
        super(props);
        props.getInfoRequest();
        props.dispatchPeersGet();
        props.dispatchTransactionsSubscribe('contract', agreementsType.AGREEMENTS_CONTRACT_UPDATE_RECEIVED);
        props.dispatchTransactionsSubscribe('proposal', agreementsType.AGREEMENTS_PROPOSAL_UPDATE_RECEIVED);
        props.dispatchTransactionsSubscribe('settlementAccount', agreementsType.VOICE_ACCOUNT_UPDATE_RECEIVED);
        props.dispatchTransactionsSubscribe('swapAgreement', agreementsType.VOICE_SWAP_AGREEMENT_UPDATE_RECEIVED);
        props.dispatchTransactionsSubscribe('partnership', agreementsType.PARTNERSHIP_UPDATE_RECEIVED);
        props.dispatchTransactionsSubscribe('settlementOffer', SettlementOfferActionType.SETTLEMENT_OFFER_RECEIVED);
        props.dispatchTransactionsSubscribe('settlementOfferComment', SettlementOfferActionType.SETTLEMENT_OFFER_COMMENT_RECEIVED);
        props.dispatchNotificationsGetAll();
        props.dispatchTransactionsSubscribe('billingStatement', agreementsType.AGREEMENTS_BILLING_STATEMENT_UPDATE_RECEIVED);
        props.getSettings();
        props.getCurrencyRates();
        props.getCountryTranslations();
        props.showSpinner();
    }

    handleLogout = (): void => {
        this.props.dispatchLogLogout(true, window.location.pathname);
        this.props.dispatchLogoutRequest(this.props.authToken);
    };

    handleCleanVoiceDemoData = (): void => {
        this.props.dispatchCleanVoiceDemoData();
    };

    handleResetVoiceDemoEnv = (): void => {
        this.props.dispatchResetVoiceDemoEnv();
    };

    render() {
        const {
            closeCreateContractDialog,
            shouldShowCreateContractDialog,
            peerName,
            peerPresentedName,
            peerType,
            appNode,
            spinner,
            demoVoice
        } = this.props;
        const userNodesDefinedByPeerType = !peerType
            ? appNode
            : appNode.map((singleNodeType: string) => {
                  const modifiedNodeType: string = `${singleNodeType}::${peerType}`;
                  if (listOfPossibleRoutingModules.includes(modifiedNodeType)) {
                      return modifiedNodeType;
                  }
                  return singleNodeType;
              });

        return (
            <div className="dashboard">
                <HeaderComponent
                    nodes={userNodesDefinedByPeerType}
                    peerName={peerName}
                    peerPresentedName={peerPresentedName}
                    userEmail={this.props.userEmail}
                    onLogout={this.handleLogout}
                    onCleanDemoVoice={this.handleCleanVoiceDemoData}
                    onResetDemoEnv={this.handleResetVoiceDemoEnv}
                    demoVoice={demoVoice}
                />
                <DashboardRoutes nodes={userNodesDefinedByPeerType} />
                <ReduxSpinner spinner={spinner} />
                <ModalContainer />
                {shouldShowCreateContractDialog && (
                    <Modal title="Create Contract" visible className="new-agreement-modal" onCancel={closeCreateContractDialog} footer={null}>
                        <CreateContractForm />
                    </Modal>
                )}
                <Footer />
            </div>
        );
    }
}
