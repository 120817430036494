import React, { useEffect, useState } from 'react';
import { InputIndicatorSvg } from '@components/Svg/input-indicator';
import classNames from 'classnames';

import './styles.scss';

interface IProps {
    key: string;
    children: JSX.Element[];
    className?: string;
    isOpen: boolean;
    onToggle(): void;
}
export const ExpansionPanel = ({ children: [header, ...content], className, isOpen, onToggle }: IProps) => {
    const [collapsable, setCollapsable] = useState<HTMLDivElement | null>();
    const receiveCollapsableRef = (ref: HTMLDivElement) => {
        if (ref) {
            const { style } = ref;
            style.display = isOpen ? 'block' : 'none';
            style.height = 'auto';
        }
        setCollapsable(ref);
    };
    const toggleCollapsableHeight = () => {
        if (collapsable) {
            const { style } = collapsable;
            const heightRemoval = () => {
                if (isOpen) {
                    style.overflow = '';
                    const { offsetParent } = collapsable;
                    if (offsetParent && collapsable.getBoundingClientRect().bottom > offsetParent.getBoundingClientRect().bottom) {
                        collapsable.scrollIntoView({
                            behavior: 'smooth',
                            block: 'end'
                        });
                    }
                } else {
                    style.display = 'none';
                }
                style.height = 'auto';
                collapsable.removeEventListener('transitionend', heightRemoval);
            };
            style.overflow = 'hidden';
            style.display = 'block';
            const height = collapsable.getBoundingClientRect().height;
            collapsable.addEventListener('transitionend', heightRemoval);
            if (isOpen) {
                style.height = '0px';
                collapsable.getBoundingClientRect(); // Redraw
                style.height = height + 'px';
            } else {
                style.height = height + 'px';
                collapsable.getBoundingClientRect(); // Redraw
                style.height = '0px';
            }
        }
    };
    useEffect(toggleCollapsableHeight, [isOpen]);
    return (
        <div className={classNames('expansion-panel', isOpen ? 'ep-expanded' : 'ep-collapsed', className)}>
            <div className="expansion-header" onClick={onToggle}>
                <span>{header}</span>
                <div className="expansion-icon">
                    <InputIndicatorSvg />
                </div>
            </div>
            <div className="expansion-content" ref={receiveCollapsableRef}>
                {...content}
            </div>
        </div>
    );
};
