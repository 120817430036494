import React, { useCallback, useRef } from 'react';
import { FilterDropdownProps } from 'antd/es/table/interface';
import { ColumnFilterContainer } from '@components/ClearTable/Filters/ColumnFilterContainer';
import { Input, Form } from 'antd';
import SearchIcon from '@ant-design/icons/SearchOutlined';

export const ColumnTextFilter: React.FC<FilterDropdownProps> = (props) => {
    const { setSelectedKeys, visible } = props;
    const input = useRef<HTMLInputElement>(null);
    const onChange = useCallback(
        (e) => {
            const text = e.target.value;
            setSelectedKeys([text.trim()]);
        },
        [setSelectedKeys]
    );
    const focus = useCallback(() => {
        if (!input.current) {
            return;
        }
        input.current!.focus();
    }, [input.current]);

    const onRef = useCallback(
        (el) => {
            // @ts-ignore
            input.current = el;
            focus();
        },
        [input.current]
    );

    return visible ? (
        <ColumnFilterContainer {...props}>
            <Form.Item name="value" rules={[{ required: true }]}>
                <Input placeholder="Filter by name" ref={onRef} onChange={onChange} suffix={<SearchIcon />} />
            </Form.Item>
        </ColumnFilterContainer>
    ) : null;
};
