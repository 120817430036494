import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Divider, Select } from 'antd';
import { Box } from '@material-ui/core';
import { useAction } from '../../../../hooks/useAction';
import { useSelector } from 'react-redux';
import { T } from '@components/T/T';
import { Currency } from '@helpers/currency';
import { makeStyles } from '@material-ui/core/styles';
import { ExtendedTheme, FontColor, FontWeight } from '@components/theme';
import SelectIcon from '@ant-design/icons/DownOutlined';
import { settlementActions } from '@actions/settlements';
import {
    selectDashboardYear,
    selectPendingSettlements,
    selectSettledSettlements,
    selectTotalPendingValue,
    selectTotalSettledValue,
    selectTotalThisMonth
} from '@selectors/roaming';
import range from 'lodash/range';

const useStyles = makeStyles((theme: ExtendedTheme) => ({
    root: {
        background: theme.palette.gradient,
        overflow: 'hidden'
    },
    header: {
        padding: theme.spacing(1, 2),
        background: 'rgba(255, 255 ,255, 0.15)'
    },
    divider: {
        background: theme.palette.common.white,
        opacity: 0.25,
        margin: 0
    },
    select: {
        color: theme.palette.text.primary,
        fontSize: theme.typography.h6.fontSize,
        fontWeight: FontWeight.Semibold
    },
    percentage: {
        borderRadius: '50%',
        border: '1px solid #fff',
        width: 120,
        height: 120
    }
}));

export const Summary: React.FC = () => {
    const classes = useStyles();

    return (
        <Box className={classes.root} borderRadius={4}>
            <SummaryHeader />
            <Divider className={classes.divider} />
            <SummaryBody />
        </Box>
    );
};

const SummaryBody: React.FC = () => {
    const totalPending = useSelector(selectTotalPendingValue);
    const totalSettled = useSelector(selectTotalSettledValue);
    const percentage = Math.round((totalSettled.local / (totalSettled.local + totalPending.local)) * 100) || 0;

    const totalThisMonth = useSelector(selectTotalThisMonth);
    return (
        <Box px={5} py={5} position="relative">
            <Box display="flex" alignItems="center" justifyContent="center">
                <SummaryLabel value={totalSettled} label="Settled" orientation="ltr" />
                <Box clone mx={15}>
                    <CircleProgress radius={62} stroke={16} progress={percentage} />
                </Box>
                <SummaryLabel value={totalPending} label="Pending" orientation="rtl" />
            </Box>
            <Box position="absolute" bottom={16}>
                <T variant="caption" color={FontColor.W}>
                    Settled this Month: {totalThisMonth.toLocalString(0)}
                </T>
            </Box>
        </Box>
    );
};

const CircleProgress: React.FC<{ radius: number; stroke: number; progress: number; className?: string }> = ({
    radius,
    progress,
    stroke,
    className
}) => {
    const circumference = useMemo(() => {
        return radius * 2 * Math.PI;
    }, [radius]);
    const strokeDashoffset = circumference - (progress / 100) * circumference;
    return (
        <Box className={className} display="flex">
            <svg height={radius * 2 + stroke} width={radius * 2 + stroke}>
                <defs>
                    <linearGradient id="linear" x1="0%" y1="0%" x2="0%" y2="100%">
                        <stop offset="0" stopColor="#5B61D1" />
                        <stop offset={progress} stopColor="#3C76F6" />
                    </linearGradient>
                </defs>
                <circle
                    stroke="white"
                    opacity="0.4"
                    fill="transparent"
                    strokeLinecap="round"
                    strokeWidth={1}
                    r={radius}
                    cx={radius + stroke / 2}
                    cy={radius + stroke / 2}
                />
                <circle
                    style={{ transform: 'rotateZ(180deg)', transition: 'stroke-dashoffset .5s', transformOrigin: 'center' }}
                    stroke="url(#linear)"
                    fill="transparent"
                    strokeLinecap="round"
                    strokeWidth={stroke}
                    strokeDasharray={[circumference, circumference].join(' ')}
                    strokeDashoffset={strokeDashoffset}
                    r={radius}
                    cx={radius + stroke / 2}
                    cy={radius + stroke / 2}
                />
                <text fill="white" fontSize={18} fontWeight={600} textAnchor="middle" x="50%" y="50%" dominantBaseline="middle">
                    {progress}%
                </text>
            </svg>
        </Box>
    );
};

const SummaryLabel: React.FC<{ label: string; value: Currency; orientation: 'ltr' | 'rtl' }> = ({ label, value, orientation }) => {
    return (
        <Box flex="1" justifyContent="flex-end" display="flex" flexDirection={orientation === 'ltr' ? 'row' : 'row-reverse'} alignItems="center">
            <T variant="body2" color={FontColor.W}>
                {label}
            </T>
            <div style={{ width: 74, height: 1, background: '#fff', margin: 30, opacity: 0.4 }} />
            <Box width={180} textAlign="center">
                <T variant="h5" color={FontColor.W} weight={FontWeight.Semibold}>
                    {value.toLocalString(0)}
                </T>
            </Box>
        </Box>
    );
};

const SummaryHeader: React.FC = () => {
    const classes = useStyles();
    const year = useSelector(selectDashboardYear);
    const setYear = useAction(settlementActions.setDashboardYear);
    const [isSelectOpen, setIsSelectOpen] = useState(false);
    const toggleSelectOpen = useCallback(() => setIsSelectOpen(!isSelectOpen), [isSelectOpen]);
    const getYearSummary = useAction(settlementActions.getYearSummary);
    const pendingSettlements = useSelector(selectPendingSettlements);
    const settledSettlements = useSelector(selectSettledSettlements);
    useEffect(() => {
        getYearSummary(year);
    }, [year]);

    const totalPending = useSelector(selectTotalPendingValue);
    const totalSettled = useSelector(selectTotalSettledValue);

    const currentYear = new Date().getFullYear();
    const availableYears = range(2018, currentYear + 1).reverse();
    return (
        <Box display="flex" alignItems="center" className={classes.header}>
            <Select
                bordered={false}
                value={year}
                onChange={setYear}
                size="large"
                open={isSelectOpen}
                onClick={toggleSelectOpen}
                className={classes.select}
                suffixIcon={<SelectIcon style={{ color: '#fff' }} onClick={toggleSelectOpen} />}
            >
                {availableYears.map((y) => (
                    <Select.Option key={y} value={y}>
                        {y}
                    </Select.Option>
                ))}
            </Select>
            <div>
                <T weight={FontWeight.Semibold} display="inline" color={FontColor.W65}>
                    Total Settlement Value for {settledSettlements.length + pendingSettlements.length} Contracts
                </T>{' '}
                <T weight={FontWeight.Semibold} display="inline" color={FontColor.W85}>
                    {new Currency(totalSettled.local + totalPending.local, Currency.localCurrency).toLocalString(0)}
                </T>
            </div>
        </Box>
    );
};
