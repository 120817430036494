import * as React from 'react';
import classNames from 'classnames';
import { Icon } from '@components/Icon';

interface ISortProps {
    isOrdered: boolean;
    isAscending?: boolean;
    columnKey: string;
}

export class Sort extends React.PureComponent<ISortProps> {
    render() {
        const { isOrdered, isAscending } = this.props;

        return <Icon iconName="sorting" className={classNames('control-sorting', { isOrdered }, { isDescending: !isAscending })} />;
    }
}
