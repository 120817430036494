import * as React from 'react';
import { DialogContainer } from '@components/Modal/DialogContainer';
import './generalDialogStyles.scss';

interface IGeneralDialog {
    width?: React.CSSProperties['width'];
    children: React.ReactNode;
    isDialogOpen: boolean;
    hasButtonRow?: boolean;
    modalClassName?: string;
    headerText?: string;
    confirmText?: string;
    HeaderComponent?: React.ReactElement;
    FooterComponent?: React.ReactElement;
    cancelText?: string;
    shouldCloseOnClickOutside?: boolean;
    onCloseClick?(): void;
    onCancelClick?(): void;
    onApproveClick?(): void;
}

export const GeneralDialog: React.FC<IGeneralDialog> = ({
    width,
    hasButtonRow,
    onApproveClick,
    onCancelClick,
    onCloseClick,
    headerText,
    children,
    isDialogOpen,
    cancelText,
    confirmText,
    HeaderComponent,
    FooterComponent,
    modalClassName,
    shouldCloseOnClickOutside
}) => {
    const modalClass = modalClassName ? `general-dialog-wrapper ${modalClassName}` : `general-dialog-wrapper`;
    return (
        <DialogContainer
            style={{ width }}
            classList={modalClass}
            HeaderComponent={HeaderComponent}
            isDefaultHeader={HeaderComponent ? false : true}
            onClose={onCloseClick}
            headerText={headerText}
            headerClassName={'general-dialog-header'}
            hideCloseButton
            overlayClassName={'general-dialog-overlay'}
            isOpen={isDialogOpen}
            shouldCloseOnClickOutside={shouldCloseOnClickOutside}
            FooterComponent={FooterComponent}
        >
            <div className={'general-dialog-body-wrapper'}>
                {children}
                {hasButtonRow && (
                    <div className={'dialog-button-row'}>
                        <button
                            className={'expiration-dialog-btn close-dialog'}
                            onClick={onCancelClick || onCloseClick}
                            data-t={'cancel-dialog-button'}
                        >
                            {cancelText}
                        </button>
                        <button className={'expiration-dialog-btn apply'} onClick={onApproveClick} data-t={'confirm-dialog-button'}>
                            {confirmText}
                        </button>
                    </div>
                )}
            </div>
        </DialogContainer>
    );
};

GeneralDialog.defaultProps = {
    confirmText: 'Approve',
    cancelText: 'Reject',
    width: 590
};
