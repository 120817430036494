import * as React from 'react';
import { updateViewByInUrl, getViewByFromUrl } from './viewByUrlFucntions';
import { Table, ITableColumnProps } from '@components/Table';
import { RecordState, ComparisonCell, DiscrepanciesCell, DiffCell, DisputeResolution, DisputeResolutionDialog } from '@modules/Voice/components';
import { updatePageInUrl, getPageFromUrl } from '@modules/Voice/components/Pagination/paginationUrlFunctions';
import { updateSortingInUrl, getSortingFromUrl } from '../../../../components/Table/sortingUrlFunctions';
import { SelectField, Dropdown, MenuButton, Pagination, FiltersPanel } from '@components';
import { connect } from 'react-redux';
import voiceActions, { DisputeOfferType, DisputeOfferStatus, ComparisonType, ViewBy, Direction, ISorting } from '@actions/voice';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import { IRootState } from '@reducers/index';
import { resetUrlToDirectionAndComparisonType, setUrlQueryParam, getUrlQueryParam } from '../../../../utils/urlFunctions';
import {
    getPeriods,
    getPeriodPagination,
    getAccountName,
    getCurrentSettlementInfo,
    getPeriodFilters,
    getComparisonType,
    getLastDisputeDate,
    getDrilldownCDRsResult,
    processDiscrepacyTypes,
    splitCSV,
    getDrilldownCDRsByDateResult,
    unconvertDiscrepancyType,
    convertDiscrepancyType,
    getMatchType
} from '@selectors/voice';
import './styles.scss';
import { getPeerPresentedName } from '@selectors/account';
import sortBy from 'lodash/sortBy';
import { IPeriodFilters, IStateVoice, IQueryResult, ISettlement, IVoicePagination, IOnMatchedState } from '@reducers/voice';
import { FilterItemType, FilterItemSubtype, IFilterItem } from '@components/FiltersPane';
import { formatRate, formatDateString, formatBalance, formatDate, formatTime } from '@helpers/formaters';
import { getCurrencySymbol, APIDateFormat } from '@constants';
import ActiveFilters from '@components/FiltersPane/ActiveFilters';
import moment from 'moment';
import { History } from 'history';
import { getFilters, getFiltersPanelStatus } from '@selectors/queryFilters';
import { voicePaths, setPathId } from 'utils/routePaths';

interface IVoiceTrafficProps {
    // For some reason plain old ref didn't work, maybe because the containing tabs handle the children
    setRef?: (me: VoiceTraffic) => void;
    accountName?: string;
    peerPresentedName?: string;
    tabLabel: string | JSX.Element | JSX.Element[];
    actions?: typeof voiceActions;
    accounts?: any[];
    settlement?: ISettlement;
    match: {
        params: {
            id: string;
        };
    };
    periods?: any[];
    periodPagination?: IVoicePagination;
    isReceivable: boolean;
    filterOptions?: IPeriodFilters;
    comparisonType?: IStateVoice['comparisonType'];
    matchType?: IStateVoice['matchType'];
    disabled?: boolean;
    drilldwonByDateResult?: IQueryResult;
    drilldownResult?: IQueryResult;
    location: Location;
    history: History;
    filtersInState?: any;
    lastDisputeDate?: number;
    areFiltersOpen?: boolean;
}

interface IDisputeResolutionParams {
    isSplit: boolean;
    comparisonItem: any;
}

interface IVoiceTrafficState {
    sorting: ISorting;
    viewBy: ViewBy;
    collapsable: any;
    disputeResolutionParams?: IDisputeResolutionParams;
    drilldownStack: IViewState[];
    rowFilterValues: { [key: string]: any } | null;
}

interface IViewState {
    viewBy: ViewBy;
}

const DisputeResolutionCompType = ComparisonType.CDR_INVOICE;

const nRatesToShow = 1;
const tableColumns: { [key in ViewBy]: ITableColumnProps[] } = {
    [ViewBy.dest_period]: [
        { key: 'destination', title: 'Destination', sortingKey: 'destination' },
        { key: 'product', title: 'Product', sortingKey: 'product' },
        {
            key: 'discrepanciesFormatted',
            title: 'Discrepancy Type',
            classNameCell: 'type fancy-labels',
            notSortable: true
        },
        {
            key: 'period',
            title: 'Period',
            sortingKey: 'aMaxDate',
            defaultSort: true
        },
        {
            key: 'amount',
            title: 'Total Amount',
            sortingKey: 'aAmount',
            alignRight: true
        },
        {
            key: 'minutesFormatted',
            title: 'Total Mins',
            sortingKey: 'aMinutes',
            classNameCell: 'minutes',
            alignRight: true
        },
        {
            key: 'callsFormatted',
            title: 'Total Calls',
            sortingKey: 'aCalls',
            classNameCell: 'calls',
            alignRight: true
        },
        {
            key: 'rates',
            title: 'Rate',
            classNameCell: 'rate',
            sortingKey: 'minRate',
            alignRight: true
        },
        {
            key: 'amountDiffFormatted',
            title: 'Amount Diff',
            sortingKey: 'amountDiff',
            classNameCell: 'amountDiff',
            alignRight: true
        },
        {
            key: 'minutesDiffFormatted',
            title: 'Minutes Diff',
            sortingKey: 'minutesDiff',
            classNameCell: 'minutesDiff',
            alignRight: true
        },
        {
            key: 'callsDiffFormatted',
            title: 'Calls Diff',
            sortingKey: 'callsDiff',
            classNameCell: 'callsDiff',
            alignRight: true
        },
        {
            key: 'actions',
            sortingKey: 'lastDisputeStatus',
            title: <span className="flag" />
        }
    ],
    [ViewBy.date]: [
        { key: 'destination', title: 'Destination', sortingKey: 'destination' },
        { key: 'product', title: 'Product', sortingKey: 'product' },
        {
            key: 'formattedDate',
            title: 'Date',
            sortingKey: 'date',
            defaultSort: true
        },
        {
            key: 'discrepanciesFormatted',
            title: 'Discrepancy Type',
            classNameCell: 'type',
            notSortable: true
        },
        {
            key: 'amountDiff',
            title: 'Amount Diff',
            sortingKey: 'amountDiff',
            alignRight: true
        },
        {
            key: 'minutesDiff',
            title: 'Minutes Diff',
            sortingKey: 'durationDiff',
            alignRight: true
        },
        {
            key: 'amount',
            title: 'Total Amount',
            sortingKey: 'aAmount',
            alignRight: true
        },
        {
            key: 'minutes',
            title: 'Total Mins',
            sortingKey: 'aDuration',
            alignRight: true
        },
        {
            key: 'calls',
            title: 'Total Calls',
            sortingKey: 'aCalls',
            alignRight: true
        },
        { key: 'rates', title: 'Rate', sortingKey: 'minRate', alignRight: true }
    ],
    [ViewBy.cdrs]: [
        { key: 'destination', title: 'Destination', sortingKey: 'destination' },
        { key: 'product', title: 'Product', sortingKey: 'product' },
        {
            key: 'discrepanciesFormatted',
            title: 'Discrepancy Type',
            classNameCell: 'type',
            notSortable: true
        },
        { key: 'date', title: 'Start Date', notSortable: true },
        {
            key: 'time',
            title: 'Start Time',
            sortingKey: 'aCallStart',
            defaultSort: true
        },
        {
            key: 'caller',
            title: 'A Number',
            sortingKey: 'caller',
            classNameCell: 'type'
        },
        {
            key: 'receiver',
            title: 'B Number',
            sortingKey: 'receiver',
            classNameCell: 'type'
        },
        {
            key: 'amount',
            title: 'Charged Amount',
            sortingKey: 'aAmount',
            alignRight: true
        },
        {
            key: 'seconds',
            title: 'Seconds',
            sortingKey: 'aDuration',
            alignRight: true
        },
        { key: 'rate', title: 'Rate', sortingKey: 'minRate', alignRight: true },
        {
            key: 'secondsDiff',
            title: 'Sec. Diff.',
            sortingKey: 'durationDiff',
            alignRight: true
        },
        {
            key: 'amountDiff',
            title: 'Amount Diff',
            sortingKey: 'amountDiff',
            alignRight: true
        }
    ]
};

const matchedOptions = [
    { value: 0, name: 'Unmatched Traffic' },
    { value: 1, name: 'Matched Traffic' },
    { value: 'all', name: 'All Traffic' }
];
const defaultMatchType = matchedOptions[2].value;
const queryStringKeys = {
    compType: 'comparisonType',
    matchType: 'isMatched' // NOTE - this value is used in filter passed to BE
};
const getDefaultSort = (viewBy: ViewBy): ISorting => {
    const columns = tableColumns[viewBy];
    const { key, sortingKey }: ITableColumnProps = columns.find(({ defaultSort }) => !!defaultSort) || columns[0];
    return {
        by: sortingKey || key,
        order: 'asc' as ISorting['order']
    };
};

const getDefaultViewBy = (location: Location): ViewBy => {
    return getViewByFromUrl(location) || ViewBy.dest_period;
};

@connect(
    createStructuredSelector<IRootState, any, any>({
        periods: getPeriods,
        drilldwonByDateResult: getDrilldownCDRsByDateResult,
        drilldownResult: getDrilldownCDRsResult,
        periodPagination: getPeriodPagination,
        accountName: getAccountName,
        peerPresentedName: getPeerPresentedName,
        settlement: getCurrentSettlementInfo,
        filterOptions: getPeriodFilters,
        comparisonType: getComparisonType,
        matchType: getMatchType,
        filtersInState: getFilters,
        lastDisputeDate: getLastDisputeDate,
        areFiltersOpen: getFiltersPanelStatus
    }),
    (dispatch: any) => ({
        actions: bindActionCreators(
            {
                ...voiceActions
            } as any,
            dispatch
        )
    })
)
export class VoiceTraffic extends React.Component<IVoiceTrafficProps, IVoiceTrafficState> {
    constructor(props: IVoiceTrafficProps) {
        super(props);
        const { location, actions } = props;
        const viewBy = getDefaultViewBy(props.location);
        this.state = {
            viewBy,
            sorting: getSortingFromUrl(props.location) || getDefaultSort(viewBy),
            collapsable: null,
            drilldownStack: [],
            rowFilterValues: viewBy === ViewBy.dest_period ? null : {}
        };
        const comparisonTypeToFetchBy = getUrlQueryParam(location, queryStringKeys.compType);
        actions!.voiceSelectComparisonType(comparisonTypeToFetchBy || this.getComparisonTypes()[0].value);
        const matchType = getUrlQueryParam(location, queryStringKeys.matchType);
        actions!.selectMatchType(matchType !== undefined ? matchType : defaultMatchType);
    }

    componentDidMount() {
        const { setRef, location } = this.props;
        if (setRef) {
            setRef(this);
        }
        const page = getPageFromUrl(location);
        // pushing this call to the next event loop is crucial, so that redux is updated beforehand
        setTimeout(() => {
            this.fetchRecords(true, page || 1, false);
        }, 0);
    }

    componentDidUpdate(prevProps: IVoiceTrafficProps, prevState: any): void {
        if (prevProps.filtersInState !== this.props.filtersInState) {
            this.fetchRecords(this.state.viewBy === ViewBy.dest_period ? true : false);
            return;
        }
        const { areFiltersOpen, actions, comparisonType, matchType } = this.props;
        if (comparisonType !== prevProps.comparisonType || matchType !== prevProps.matchType) {
            this.fetchRecords(true);
            return;
        }
        if (areFiltersOpen && !prevProps.areFiltersOpen) {
            actions!.voiceGetFiltersPanelData(this.getSettlementPeriodId(), comparisonType!, this.getDirection());
        }
        if (prevProps.lastDisputeDate !== this.props.lastDisputeDate) {
            this.fetchRecords(true);
        }
    }

    getComparisonTypes = () => {
        const { settlement, peerPresentedName } = this.props;
        const { accountPresentedName } = settlement as ISettlement;

        const { invoiceCDRCompareFileHash, invoicePartnerCDRCompareFileHash, CDRPartnerCDRCompareFileHash } = this.props.settlement!;

        const cdrCdr = {
            isDisabled: !CDRPartnerCDRCompareFileHash,
            value: ComparisonType.CDR_PARTNER_CDR,
            name: `${peerPresentedName} CDRs vs. ${accountPresentedName} CDRs`,
            type: ['peerCDR', 'accountCDR']
        };
        const arr = this.props.isReceivable
            ? [cdrCdr]
            : [
                  {
                      isDisabled: !invoiceCDRCompareFileHash,
                      value: ComparisonType.CDR_INVOICE,
                      name: `${peerPresentedName} CDRs vs. ${accountPresentedName} Invoice`,
                      type: ['peerCDR', 'accountInvoice']
                  },
                  cdrCdr,
                  {
                      isDisabled: !invoicePartnerCDRCompareFileHash,
                      value: ComparisonType.PARTNER_CDR_INVOICE,
                      name: `${accountPresentedName} CDRs vs. ${accountPresentedName} Invoice`,
                      type: ['accountCDR', 'accountInvoice']
                  }
              ];
        return sortBy(arr, 'isDisabled');
    };

    isDrillddownEnabled = () => this.props.comparisonType === ComparisonType.CDR_PARTNER_CDR;

    areDisputesEnabled() {
        return this.props.comparisonType === DisputeResolutionCompType;
    }

    createActionsCell = ({ id, lastDisputeStatus, amountDiff }: any) => {
        if (lastDisputeStatus) {
            return (
                <span className={'flag-container ' + lastDisputeStatus} onClick={this.setDisputeResolutionParams.bind(this, id, false)}>
                    <span className={'flag ' + lastDisputeStatus} />
                </span>
            );
        }
        if (amountDiff < 0) {
            return (
                <Dropdown classList="actions-dropdown" inline closeOnMouseLeave={true}>
                    <MenuButton onClick={this.setDisputeResolutionParams.bind(this, id, true)} name="Split" error="">
                        Offer 50/50 Split
                    </MenuButton>
                    <MenuButton onClick={this.setDisputeResolutionParams.bind(this, id, false)} name="Offer" error="">
                        Offer Amount
                    </MenuButton>
                    <MenuButton onClick={this.acceptOtherAmount.bind(this, id)} name="Accept" error="">
                        Accept
                    </MenuButton>
                </Dropdown>
            );
        }
        return null;
    };

    getCurrencySymbol = () => getCurrencySymbol(this.props.settlement!.accountCurrency);
    getTableData = () => {
        const { viewBy } = this.state;
        let creator: (item: any) => any;
        let records: any[] = [];
        if (viewBy === ViewBy.dest_period) {
            records = this.props.periods!;
            const disabled = !this.isDrillddownEnabled();
            const disputesEnabled = this.areDisputesEnabled();
            creator = (item: any) => ({
                disabled,
                period: <ComparisonCell a={item.aPeriod} b={item.bPeriod} />,
                discrepanciesFormatted: <DiscrepanciesCell values={item.types} />,
                amount: <ComparisonCell a={item.aAmountFormatted} b={item.bAmountFormatted} />,
                minutesFormatted: <ComparisonCell a={item.aMinutes} b={item.bMinutes} />,
                callsFormatted: <ComparisonCell a={item.aCalls} b={item.bCalls} />,
                rates: <ComparisonCell a={item.aRates} b={item.bRates} listLimit={nRatesToShow} />,
                amountDiffFormatted: <DiffCell actual={item.amountDiffFormatted} percentage={item.amountDiffPer} actualRaw={item.amountDiff} />,
                minutesDiffFormatted: <DiffCell actual={item.minutesDiffFormatted} percentage={item.minutesDiffPer} actualRaw={item.minutesDiff} />,
                callsDiffFormatted: <DiffCell actual={item.callsDiffFormatted} percentage={item.callsDiffPer} actualRaw={item.callsDiff} />,
                actions: disputesEnabled && this.createActionsCell(item)
            });
            records = this.props.periods!;
        } else {
            const currencySymbol = this.getCurrencySymbol();
            const amountFormatter = (val: string) => formatBalance(val, 2, { currency: currencySymbol, roundToInteger: true });
            const numFormatter = (val: string) => formatBalance(val, 2, { roundToInteger: true });
            const rateFormatter = (val: string) => formatRate(val, currencySymbol);
            const ratesFormatter = (rates: string) => splitCSV(rates).map(rateFormatter);
            if (viewBy === ViewBy.date) {
                records = this.props.drilldwonByDateResult!.items;
                creator = (item: any) => ({
                    formattedDate: formatDateString(item.date),
                    amount: <ComparisonCell a={amountFormatter(item.aAmount)} b={amountFormatter(item.bAmount)} />,
                    minutes: <ComparisonCell a={numFormatter(item.aMinutes)} b={numFormatter(item.bMinutes)} />,
                    calls: <ComparisonCell a={item.aCalls} b={item.bCalls} />,
                    rates: <ComparisonCell a={ratesFormatter(item.aRates)} b={ratesFormatter(item.bRates)} listLimit={1} />,
                    amountDiff: <DiffCell actual={amountFormatter(item.amountDiff)} percentage={item.amountDiffPer} actualRaw={item.amountDiff} />,
                    callsDiff: <DiffCell actual={numFormatter(item.callsDiff)} percentage={item.callsDiffPer} actualRaw={item.callsDiff} />,
                    minutesDiff: <DiffCell actual={numFormatter(item.minutesDiff)} percentage={item.minutesDiffPer} actualRaw={item.minutesDiff} />,
                    discrepanciesFormatted: <DiscrepanciesCell values={processDiscrepacyTypes(item.discrepancies)} />
                });
            } else {
                records = this.props.drilldownResult!.items;
                creator = (item: any) => ({
                    disabled: true, // Not clickable
                    date: <ComparisonCell a={item.aCallStart} b={item.bCallStart} formatter={formatDate} />,
                    time: <ComparisonCell a={item.aCallStart} b={item.bCallStart} formatter={formatTime} />,
                    caller: <ComparisonCell a={item.aCaller} b={item.bCaller} />,
                    receiver: <ComparisonCell a={item.aReceiver} b={item.bReceiver} />,
                    amount: <ComparisonCell a={item.aAmount} b={item.bAmount} formatter={amountFormatter} />,
                    seconds: <ComparisonCell a={item.aDuration} b={item.bDuration} />,
                    secondsDiff: <DiffCell actual={item.durationDiff} percentage={item.durationDiffPer} actualRaw={item.durationDiff} />,
                    rate: <ComparisonCell a={item.aRate} b={item.bRate} formatter={rateFormatter} />,
                    amountDiff: <DiffCell actual={amountFormatter(item.amountDiff)} percentage={item.amountDiffPer} actualRaw={item.amountDiff} />,
                    discrepanciesFormatted: <DiscrepanciesCell values={processDiscrepacyTypes(item.discrepancies)} />
                });
            }
        }
        return records.map((item) => ({ ...item, ...creator(item) }));
    };

    getDirection = () => (this.props.isReceivable ? Direction.Receivable : Direction.Payable);

    fetchRecords = (shouldUpdateSummary?: boolean, page: number = 1, forDownload: boolean = false) => {
        const direction = this.getDirection();
        const periodId = this.getSettlementPeriodId();
        const { sorting, viewBy } = this.state;

        if (shouldUpdateSummary) {
            const { actions: currentActions, comparisonType: currentComparisonType } = this.props;
            currentActions!.voiceGetSingleSettlementPeriodSummary(periodId, currentComparisonType!, direction);
        }

        const { actions, comparisonType, filtersInState, matchType } = this.props;
        const processedFilters = { ...filtersInState };
        // get filters process them to be ready to be sent to server to fit API expectations
        const { aRates, discrepancies } = processedFilters;
        if (aRates) {
            processedFilters.aRates = `%${aRates}%`;
        }
        if (discrepancies) {
            processedFilters.discrepancies = `%${unconvertDiscrepancyType(discrepancies)}%`;
        }
        if (matchType !== defaultMatchType) {
            processedFilters[queryStringKeys.matchType] = matchType;
        }
        switch (viewBy) {
            case ViewBy.dest_period:
                const args = {
                    periodId,
                    comparison: comparisonType!,
                    direction,
                    filterOptions: processedFilters,
                    sorting
                };
                forDownload
                    ? actions!.downloadSingleSettlementPeriod(args)
                    : actions!.voiceGetSingleSettlementPeriod({
                          ...args,
                          page
                      });
                break;
            case ViewBy.date:
            case ViewBy.cdrs:
                forDownload
                    ? actions!.downloadDrilldownCDRs(periodId, direction, viewBy, processedFilters, sorting)
                    : actions!.queryDrilldownCDRs(periodId, direction, viewBy, processedFilters, sorting, page);
                break;
        }
    };

    onSortingColumnSelect = ({ key, sortingKey }: ITableColumnProps, isAscending: boolean) => {
        if (sortingKey === 'minRate' && !isAscending) {
            sortingKey = 'maxRate';
        }
        const sorting = {
            by: sortingKey || key,
            order: (isAscending ? 'asc' : 'desc') as ISorting['order']
        };
        this.setState({ sorting }, this.fetchRecords);
        const { location, history } = this.props;
        updateSortingInUrl(sorting, location, history);
    };

    onSelectPage = (page: number): void => {
        const { location, history } = this.props;
        this.fetchRecords(false, page);
        updatePageInUrl(page, location, history);
    };

    getSettlementPeriodId = () => {
        const {
            match: {
                params: { id }
            }
        } = this.props as any;
        return id;
    };

    onSelectComparisonType = (selectedComparisonType: any) => {
        const { actions, location, history } = this.props;
        setUrlQueryParam(location, queryStringKeys.compType, selectedComparisonType, history);
        const { drilldownStack } = this.state;
        actions!.voiceSelectComparisonType(selectedComparisonType);
        // Revert drilldown (which is relevant only for cdr-cdr comparison type)
        if (drilldownStack.length) {
            this.applyViewState(drilldownStack[0] as IViewState, []);
        }
    };

    setCollapsableRef = (element: any) => {
        this.setState({ collapsable: element });
    };

    toggleCollapsableHeight = (shouldSet: boolean) => {
        const { collapsable } = this.state;
        if (collapsable) {
            if (shouldSet) {
                collapsable.style.height = '';
                collapsable.style.height = collapsable.getBoundingClientRect().height + 'px';
                collapsable.getBoundingClientRect(); // Redraw
            } else {
                const heightRemoval = () => {
                    collapsable.style.height = '';
                    collapsable.removeEventListener('animationend', heightRemoval);
                };
                collapsable.addEventListener('animationend', heightRemoval);
            }
        }
    };
    getPeriod = (periodId: string) => (this.props as any).periods.find(({ id }: { id: string }) => id === periodId);

    rowClick = ({ id }: { id: string }) => {
        let viewBy: ViewBy | null = null;
        let rowFilterValues: { [key: string]: any } = {};
        switch (this.state.viewBy) {
            case ViewBy.dest_period: {
                if (!this.isDrillddownEnabled()) {
                    return;
                }
                const { destination, product } = this.getPeriod(id);
                rowFilterValues = {
                    destination: [destination],
                    product: product ? [product] : []
                };
                viewBy = ViewBy.date;
                this.toggleCollapsableHeight(true); // For animation
                break;
            }
            case ViewBy.date: {
                const { destination, product, date } = this.props.drilldwonByDateResult!.items.find((item: { id: string }) => item.id === id);
                const dateStart = moment.utc(date, APIDateFormat);
                rowFilterValues = {
                    destination: [destination],
                    product: product ? [product] : [],
                    dateFilter: [dateStart.valueOf() / 1000, dateStart.add(1, 'day').valueOf() / 1000]
                };
                viewBy = ViewBy.cdrs;
                break;
            }
        }
        if (viewBy) {
            window.scroll(0, 0);
            const { drilldownStack: oldStack, viewBy: oldViewBy, sorting } = this.state;
            if (oldViewBy !== viewBy) {
                updateViewByInUrl(viewBy, this.props.location, this.props.history);
            }
            const { actions, filtersInState } = this.props;
            actions!.drillDownCDRsClear();
            const drilldownStack = [...oldStack, { filtersInState, sorting, viewBy: oldViewBy }];
            this.setState({
                viewBy,
                drilldownStack,
                sorting: getDefaultSort(viewBy),
                rowFilterValues
            });
        }
    };

    goBack = (): void => {
        if (this.state.viewBy === ViewBy.dest_period) {
            const { settlement, history } = this.props;
            if (settlement) {
                history.push(setPathId(voicePaths.settlementsList, settlement.accountId));
            } else {
                history.goBack();
            }
        } else {
            const destPageState = {
                viewBy: ViewBy.dest_period,
                sorting: getDefaultSort(ViewBy.dest_period),
                rowFilterValues: null
            };
            this.applyViewState(destPageState, []);
            resetUrlToDirectionAndComparisonType(this.props.location, this.props.history);
        }
    };

    applyViewState = (newState: IViewState, drilldownStack: IViewState[]) => {
        if (newState.viewBy === ViewBy.dest_period) {
            this.toggleCollapsableHeight(false); // For animation of summary
        }
        this.setState({ ...newState, drilldownStack });
    };

    setDisputeResolutionParams = (id: string, isSplit: boolean) => {
        const comparisonItem = this.getPeriod(id);
        this.setState({ disputeResolutionParams: { comparisonItem, isSplit } });
    };
    clearDisputeResolution = () => {
        this.setState({ disputeResolutionParams: undefined });
    };
    submitDisputeResolution = ({ id }: any, isSplit: boolean, amount?: number) => {
        this.createDisputOffer(id, isSplit ? DisputeOfferType.FiftyFifty : DisputeOfferType.NewAmount, DisputeOfferStatus.OfferSent, amount);
        this.clearDisputeResolution();
    };
    acceptOtherAmount = (id: string) => {
        const { amountDiff } = this.getPeriod(id);
        this.createDisputOffer(id, DisputeOfferType.NewAmount, DisputeOfferStatus.Accept, Math.abs(amountDiff));
    };
    createDisputOffer = (id: string, type: DisputeOfferType, status: DisputeOfferStatus, amount?: number) => {
        const periodId = this.getSettlementPeriodId();
        const { accountCurrency: currency } = this.props.settlement!;
        (this.props.actions as typeof voiceActions).voiceCreateDisputeOffer(id, type, status, { amount }, currency, periodId);
    };

    getPagination = (): IVoicePagination => {
        const { viewBy } = this.state;
        if (viewBy === ViewBy.dest_period) {
            return this.props.periodPagination!;
        }
        return (viewBy === ViewBy.date ? this.props.drilldwonByDateResult : this.props.drilldownResult)!.pagination;
    };
    getFilterItems = (): IFilterItem[] => {
        const { filterOptions, settlement } = this.props;
        const { accountCurrency, startDate } = settlement!;
        const currencySymbol = getCurrencySymbol(accountCurrency);
        const fOptions = filterOptions as IPeriodFilters;
        const ratesOptions = (fOptions.aRates || []).sort();
        const discrepancyOptions = (fOptions.discrepancies || []).map(convertDiscrepancyType);
        switch (this.state.viewBy) {
            case ViewBy.dest_period:
                return [
                    {
                        key: 'destination',
                        type: FilterItemType.SearchSelect,
                        title: 'Destinations',
                        options: fOptions.destination
                    },
                    {
                        key: 'discrepancies',
                        type: FilterItemType.Select,
                        subType: FilterItemSubtype.Single,
                        title: 'Discrepancy Type',
                        options: discrepancyOptions
                    },
                    {
                        key: 'product',
                        type: FilterItemType.SearchSelect,
                        title: 'Products',
                        options: fOptions.product
                    },
                    {
                        key: 'aAmount',
                        type: FilterItemType.Number,
                        subType: FilterItemSubtype.Money,
                        title: 'Total Amount'
                    },
                    {
                        key: 'dateFilter',
                        type: FilterItemType.Date,
                        title: 'Date Period',
                        data: { initial: new Date(startDate * 1000) }
                    },
                    {
                        key: 'amountDiff',
                        type: FilterItemType.Number,
                        subType: FilterItemSubtype.Money,
                        title: 'Amount Difference'
                    },
                    {
                        key: 'aMinutes',
                        type: FilterItemType.Number,
                        title: 'Total Minutes'
                    },
                    {
                        key: 'aCalls',
                        type: FilterItemType.Number,
                        title: 'Total Calls'
                    },
                    {
                        key: 'aRates',
                        type: FilterItemType.SearchSelect,
                        subType: FilterItemSubtype.Single,
                        title: 'Rates',
                        options: ratesOptions,
                        data: {
                            formatter: formatRate.bind(null, currencySymbol)
                        }
                    },
                    {
                        key: 'minutesDiff',
                        type: FilterItemType.Number,
                        title: 'Minutes Diff'
                    },
                    {
                        key: 'callDiff',
                        type: FilterItemType.Number,
                        title: 'Calls Diff'
                    }
                ];
            case ViewBy.date:
                return [
                    {
                        key: 'destination',
                        type: FilterItemType.SearchSelect,
                        title: 'Destinations',
                        options: fOptions.destination
                    },
                    {
                        key: 'dateFilter',
                        type: FilterItemType.Date,
                        title: 'Date',
                        data: { initial: new Date(startDate * 1000) }
                    },
                    {
                        key: 'discrepancies',
                        type: FilterItemType.Select,
                        subType: FilterItemSubtype.Single,
                        title: 'Discrepancy Type',
                        options: discrepancyOptions
                    },
                    {
                        key: 'product',
                        type: FilterItemType.SearchSelect,
                        title: 'Products',
                        options: fOptions.product
                    },
                    {
                        key: 'amountDiff',
                        type: FilterItemType.Number,
                        subType: FilterItemSubtype.Money,
                        title: 'Amount Difference'
                    },
                    {
                        key: 'minutesDiff',
                        type: FilterItemType.Number,
                        title: 'Minutes Diff'
                    },
                    {
                        key: 'aAmount',
                        type: FilterItemType.Number,
                        subType: FilterItemSubtype.Money,
                        title: 'Total Amount'
                    },
                    {
                        key: 'aMinutes',
                        type: FilterItemType.Number,
                        title: 'Total Minutes'
                    },
                    {
                        key: 'aCalls',
                        type: FilterItemType.Number,
                        title: 'Total Calls'
                    },
                    {
                        key: 'aRates',
                        type: FilterItemType.SearchSelect,
                        subType: FilterItemSubtype.Single,
                        title: 'Rates',
                        options: ratesOptions,
                        data: {
                            formatter: formatRate.bind(null, currencySymbol)
                        }
                    }
                ];
            case ViewBy.cdrs:
                return [
                    {
                        key: 'destination',
                        type: FilterItemType.SearchSelect,
                        title: 'Destinations',
                        options: fOptions.destination
                    },
                    {
                        key: 'dateFilter',
                        type: FilterItemType.Date,
                        title: 'Date Period & Time',
                        subType: FilterItemSubtype.DateTime,
                        data: { initial: new Date(startDate * 1000) }
                    },
                    {
                        key: 'discrepancies',
                        type: FilterItemType.Select,
                        subType: FilterItemSubtype.Single,
                        title: 'Discrepancy Type',
                        options: discrepancyOptions
                    },
                    {
                        key: 'product',
                        type: FilterItemType.SearchSelect,
                        title: 'Products',
                        options: fOptions.product
                    },
                    {
                        key: 'caller',
                        type: FilterItemType.String,
                        title: 'A Number'
                    },
                    {
                        key: 'receiver',
                        type: FilterItemType.String,
                        title: 'B Number'
                    },
                    {
                        key: 'aAmount',
                        type: FilterItemType.Number,
                        subType: FilterItemSubtype.Money,
                        title: 'Charged Amount'
                    },
                    {
                        key: 'aDuration',
                        type: FilterItemType.Number,
                        title: 'Seconds'
                    },
                    {
                        key: 'durationDiff',
                        type: FilterItemType.Number,
                        title: 'Seconds Difference'
                    },
                    {
                        key: 'aRate',
                        type: FilterItemType.SearchSelect,
                        title: 'Rates',
                        options: ratesOptions,
                        data: {
                            formatter: formatRate.bind(null, currencySymbol)
                        }
                    },
                    {
                        key: 'amountDiff',
                        type: FilterItemType.Number,
                        subType: FilterItemSubtype.Money,
                        title: 'Amount Difference'
                    }
                ];
        }
    };
    onMatchedFilterChange = (value: IOnMatchedState): void => {
        const { matchType, actions, location, history } = this.props;
        if (value !== matchType) {
            setUrlQueryParam(location, queryStringKeys.matchType, value, history);
            actions!.selectMatchType(value);
        }
    };

    render() {
        const { settlement, comparisonType, accountName, peerPresentedName, matchType } = this.props;
        const { accountCurrency } = settlement!;
        const { disputeResolutionParams, viewBy, rowFilterValues, sorting } = this.state;
        const showingDrilldown = viewBy !== ViewBy.dest_period;
        const noDisputeSupport = !this.areDisputesEnabled();
        const filterItems = this.getFilterItems();
        const { myText, partnerText } = getTextByComparisonType(comparisonType as ComparisonType);

        return (
            <div className="voice-container tab-content payable-traffic">
                <div ref={this.setCollapsableRef} className={'summary-wrapper collapsable' + (showingDrilldown ? ' collapsed' : '')}>
                    <div className="table-index">
                        <div className="index-type">{myText}</div>
                        <div className="index-type with-background">{partnerText}</div>
                    </div>
                    <RecordState currency={accountCurrency} accountName={accountName!} peerName={peerPresentedName!} />
                </div>
                <div className="top-actions">
                    <span className="filtering">
                        <SelectField
                            input={{
                                onChange: this.onSelectComparisonType,
                                value: comparisonType
                            }}
                            meta={{}}
                            options={this.getComparisonTypes()}
                            classList="filters-dropdown primary"
                            closeOnOutsideClick
                        />
                        <SelectField
                            input={{
                                onChange: this.onMatchedFilterChange,
                                value: matchType
                            }}
                            meta={{}}
                            options={matchedOptions}
                            classList="filters-dropdown"
                            closeOnOutsideClick
                        />

                        <ActiveFilters items={filterItems} />
                    </span>
                    <button className="btn btn-secondary download-button" onClick={this.fetchRecords.bind(this, false, 1, true, false)}>
                        Download
                    </button>
                </div>
                <FiltersPanel useHistory={true} currency={accountCurrency} rowFilterValues={rowFilterValues} items={filterItems} />
                <div className="table-with-drilldown">
                    <div className="pagination-header">
                        <Pagination pagination={this.getPagination()} onSelectPage={this.onSelectPage} />
                    </div>
                    <Table
                        defaultSorting={sorting}
                        key={viewBy}
                        handleRowWithValueClick={this.rowClick}
                        classes={'voice-payable-traffic-list view-by-' + viewBy + (noDisputeSupport ? ' no-actions' : '')}
                        cols={tableColumns[viewBy]}
                        data={this.getTableData()}
                        noHeader={true}
                        noMoreOption={true}
                        onSortingColumnSelect={this.onSortingColumnSelect}
                        notFrontendSortable={true}
                        noExport
                    />
                </div>
                {disputeResolutionParams && (
                    <DisputeResolution
                        {...(disputeResolutionParams as IDisputeResolutionParams)}
                        settlement={settlement}
                        close={this.clearDisputeResolution}
                        submit={this.submitDisputeResolution}
                    />
                )}
                {settlement ? <DisputeResolutionDialog settlement={settlement} /> : null}
            </div>
        );
    }
}

function getTextByComparisonType(comparisonType: ComparisonType) {
    let myText: string = '';
    let partnerText: string = '';
    switch (comparisonType) {
        case ComparisonType.CDR_PARTNER_CDR:
            myText = `My CDR's`;
            partnerText = `Partner's CDR's`;
            break;
        case ComparisonType.CDR_INVOICE:
            myText = `My CDR's`;
            partnerText = `Partner's  invoice`;
            break;
        case ComparisonType.PARTNER_CDR_INVOICE:
            myText = `Partner's CDR's`;
            partnerText = `Partner's invoice`;
            break;
    }
    return { myText, partnerText };
}
