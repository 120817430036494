import { createAction } from '@helpers/actionCreator';

export enum partnerType {
    PARTNERS_GET_SUPPLIERS_LIST_REQUEST = 'RSP:PARTNERS:GET:SUPPLIERS:LIST::REST:REQUEST',
    PARTNERS_GET_SUPPLIERS_LIST_SUCCESS = 'RSP:PARTNERS:GET:SUPPLIERS:LIST::REST:REQUEST:SUCCESS',
    PARTNERS_GET_SUPPLIERS_LIST_FAIL = 'RSP:PARTNERS:GET:SUPPLIERS:LIST::REST:REQUEST:FAIL',
    PARTNERS_GET_SUPPLIERS_LIST_FINISHED = 'RSP:PARTNERS:GET:SUPPLIERS:LIST::REST:REQUEST:FINISHED',

    PARTNERS_GET_SUPPLIER_INFO_REQUEST = 'RSP:PARTNERS:GET:SUPPLIER_INFO::REST:REQUEST',
    PARTNERS_GET_SUPPLIER_INFO_SUCCESS = 'RSP:PARTNERS:GET:SUPPLIER_INFO::REST:REQUEST:SUCCESS',
    PARTNERS_GET_SUPPLIER_INFO_FAIL = 'RSP:PARTNERS:GET:SUPPLIER_INFO::REST:REQUEST:FAIL',
    PARTNERS_GET_SUPPLIER_INFO_FINISHED = 'RSP:PARTNERS:GET:SUPPLIER_INFO::REST:REQUEST:FINISHED',

    PARTNERS_EDIT_SUPPLIER_INFO_REQUEST = 'RSP:PARTNERS:EDIT:SUPPLIER_INFO::REST:REQUEST',
    PARTNERS_EDIT_SUPPLIER_INFO_SUCCESS = 'RSP:PARTNERS:EDIT:SUPPLIER_INFO::REST:REQUEST:SUCCESS',
    PARTNERS_EDIT_SUPPLIER_INFO_FAIL = 'RSP:PARTNERS:EDIT:SUPPLIER_INFO::REST:REQUEST:FAIL',
    PARTNERS_EDIT_SUPPLIER_INFO_FINISHED = 'PARTNERS:EDIT:SUPPLIER_INFO::REST:REQUEST:FINISHED',

    PARTNERS_ADD_SUPPLIER_INFO_REQUEST = 'RSP:PARTNERS:ADD:SUPPLIER_INFO::REST:REQUEST',
    PARTNERS_ADD_SUPPLIER_INFO_SUCCESS = 'RSP:PARTNERS:ADD:SUPPLIER_INFO::REST:REQUEST:SUCCESS',
    PARTNERS_ADD_SUPPLIER_INFO_FAIL = 'RSP:PARTNERS:ADD:SUPPLIER_INFO::REST:REQUEST:FAIL',
    PARTNERS_ADD_SUPPLIER_INFO_FINISHED = 'RSP:PARTNERS:ADD:SUPPLIER_INFO::REST:REQUEST:FINISHED'
}

interface IPartnersQueryDynamicParams {
    supplier: string;
    is_active: boolean;
}

export interface IPartnersUpdateOrInsertDynamicParams extends IPartnersQueryDynamicParams {
    contact: string;
    currency: string;
    last_updated: string;
    pop: string;
    rate_format: string;
    service_level: string;
    sheet_name: string;
    short_name: string;
}

const partnerActions = {
    getPartnerListRequest: createAction(partnerType.PARTNERS_GET_SUPPLIERS_LIST_REQUEST, {
        payload: {
            table: 'partners',
            operation: 'get_list_of_suppliers'
        }
    }),
    getPartnerInfoRequest: createAction(partnerType.PARTNERS_GET_SUPPLIER_INFO_REQUEST, {
        payload: (params?: IPartnersQueryDynamicParams) => ({
            table: 'partners',
            operation: 'query',
            rows_in_table: 10,
            page_number: 1,
            ...params
        })
    }),
    editPartnerRequest: createAction(partnerType.PARTNERS_EDIT_SUPPLIER_INFO_REQUEST, {
        payload: (params: IPartnersUpdateOrInsertDynamicParams) => ({
            table: 'partners',
            operation: 'update',
            ...params
        })
    }),
    addPartnerRequest: createAction(partnerType.PARTNERS_ADD_SUPPLIER_INFO_REQUEST, {
        payload: (params: IPartnersUpdateOrInsertDynamicParams) => ({
            table: 'partners',
            operation: 'insert',
            ...params
        })
    })
};

export default partnerActions;
