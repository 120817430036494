import React from 'react';

type AnchorProps = React.DetailedHTMLProps<React.AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>;

type SafeLinkProps = Omit<AnchorProps, 'rel' | 'target'> & {
    openTab?: boolean;
};

export const SafeLink: React.FC<SafeLinkProps> = ({ openTab, ...props }) => {
    return <a {...props} target={openTab ? '_blank' : undefined} rel="noopener noreferrer" />;
};
