import { createRequestAction } from '@helpers/actionCreator';

export enum cdrType {
    CDRS_BY_DESTINATION_GET_REQUEST = 'CDRS:GET:BY:DESTINATION::REQUEST',
    CDRS_BY_DESTINATION_GET_SUCCESS = 'CDRS:GET:BY:DESTINATION::REQUEST:SUCCESS',
    CDRS_BY_DESTINATION_GET_FAIL = 'CDRS:GET:BY:DESTINATION::REQUEST:FAIL',
    CDRS_BY_DESTINATION_GET_FINISHED = 'CDRS:GET:BY:DESTINATION::REQUEST:FINISHED',

    CDRS_BY_PREFIX_GET_REQUEST = 'CDRS:GET:BY:PREFIX::REQUEST',
    CDRS_BY_PREFIX_GET_SUCCESS = 'CDRS:GET:BY:PREFIX::REQUEST:SUCCESS',
    CDRS_BY_PREFIX_GET_FAIL = 'CDRS:GET:BY:PREFIX::REQUEST:FAIL',
    CDRS_BY_PREFIX_GET_FINISHED = 'CDRS:GET:BY:PREFIX::REQUEST:FINISHED',

    CDRS_BY_PRODUCT_GET_REQUEST = 'CDRS:GET:BY:PRODUCT::REQUEST',
    CDRS_BY_PRODUCT_GET_SUCCESS = 'CDRS:GET:BY:PRODUCT::REQUEST:SUCCESS',
    CDRS_BY_PRODUCT_GET_FAIL = 'CDRS:GET:BY:PRODUCT::REQUEST:FAIL',
    CDRS_BY_PRODUCT_GET_FINISHED = 'CDRS:GET:BY:PRODUCT::REQUEST:FINISHED',

    CDRS_BY_DATE_GET_REQUEST = 'CDRS:GET:BY:DATE::REQUEST',
    CDRS_BY_DATE_GET_SUCCESS = 'CDRS:GET:BY:DATE::REQUEST:SUCCESS',
    CDRS_BY_DATE_GET_FAIL = 'CDRS:GET:BY:DATE::REQUEST:FAIL',
    CDRS_BY_DATE_GET_FINISHED = 'CDRS:GET:BY:DATE::REQUEST:FINISHED',

    CDRS_BY_CDR_GET_REQUEST = 'CDRS:GET:BY:CDR::REQUEST',
    CDRS_BY_CDR_GET_SUCCESS = 'CDRS:GET:BY:CDR::REQUEST:SUCCESS',
    CDRS_BY_CDR_GET_FAIL = 'CDRS:GET:BY:CDR::REQUEST:FAIL',
    CDRS_BY_CDR_GET_FINISHED = 'CDRS:GET:BY:CDR::REQUEST:FINISHED'
}

export interface ICdrRequestCurrentFilters {
    destination?: string;
    prefix?: string;
    date?: string;
    product?: string;
    cdrs?: string;
}

export type ICdrRequestViewBy = 'destination' | 'prefix' | 'date' | 'product' | 'CDRs';
export type ICdrRequestPayableOrReceivable = 'payable' | 'receivable';

export interface ICdrBaseRequestObject {
    onlyDiscrepancies: boolean;
    isPayable: ICdrRequestPayableOrReceivable;
    numOfResultsPerPage: number;
    page: number;
    currentFilters: ICdrRequestCurrentFilters;
    orderBy: {};
}

interface ICdrContractAndPeriod {
    contractId: string;
    periodId: string;
}

const contractParams: ICdrContractAndPeriod = {
    contractId: 'contractId6',
    periodId: 'periodId6'
};

export interface ICdrFullRequestObject extends ICdrBaseRequestObject, ICdrContractAndPeriod {
    viewBy?: ICdrRequestViewBy;
}

const createСDRrequestObj = (cdrRequestObj: ICdrBaseRequestObject, mainFilter: ICdrRequestViewBy): ICdrFullRequestObject[] => [
    { ...cdrRequestObj, viewBy: mainFilter, ...contractParams }
];

const cdrActions = {
    cdrGetByDestination: createRequestAction(
        cdrType.CDRS_BY_DESTINATION_GET_REQUEST,
        'querySettlementDetails',
        (cdrRequestObj: ICdrBaseRequestObject) => createСDRrequestObj(cdrRequestObj, 'destination')
    ),
    cdrGetByPrefix: createRequestAction(cdrType.CDRS_BY_PREFIX_GET_REQUEST, 'querySettlementDetails', (cdrRequestObj: ICdrBaseRequestObject) =>
        createСDRrequestObj(cdrRequestObj, 'prefix')
    ),
    cdrGetByDate: createRequestAction(cdrType.CDRS_BY_DATE_GET_REQUEST, 'querySettlementDetails', (cdrRequestObj: ICdrBaseRequestObject) =>
        createСDRrequestObj(cdrRequestObj, 'date')
    ),
    cdrGetByProduct: createRequestAction(cdrType.CDRS_BY_PRODUCT_GET_REQUEST, 'querySettlementDetails', (cdrRequestObj: ICdrBaseRequestObject) =>
        createСDRrequestObj(cdrRequestObj, 'product')
    ),
    cdrGetByCDR: createRequestAction(cdrType.CDRS_BY_CDR_GET_REQUEST, 'querySettlementDetails', (cdrRequestObj: ICdrBaseRequestObject) =>
        createСDRrequestObj(cdrRequestObj, 'CDRs')
    )
};

export default cdrActions;
