import { connect } from 'react-redux';
import { IRootState } from '@reducers';
import { AnyAction, Dispatch } from 'redux';
import { PasswordForm, IUserFields } from '@components/UserManagement/PasswordForm';
import authActions from '@actions/auth';

export const ActivateUser = connect(
    (state: IRootState) => ({
        isPasswordPolicyEnforced: state.app.isPasswordPolicyEnforced,
        isMfaEnabled: state.app.isMfaEnabled,
        isSucceeded: state.user.activateUser.isSuccess,
        qrCode: state.user.activateUser.qrCode,
        isLoading: state.user.activateUser.isLoading,
        errorMessage: state.user.activateUser.errorMessage,
        formTitle: 'Activate User',
        successMessage: 'Your user has been activated'
    }),
    (dispatch: Dispatch) => ({
        submitFunction: (userDetails: IUserFields): AnyAction => dispatch(authActions.activateUser(userDetails))
    })
)(PasswordForm);
