import React from 'react';
import { ISettlementSummary } from '@reducers/settlements';
import { Card, Tag } from 'antd';
import { T } from '@components/T/T';
import moment from 'moment';
import { periodDateFormat } from '@constants';
import { Separator } from '@components/Separator/Separator';
import { Box } from '@material-ui/core';
import { Currency } from '@helpers/currency';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { ExtendedTheme, FontColor, FontWeight } from '@components/theme';
import { ContractStatuses } from '@constants/contractStatuses';

const useStyles = makeStyles((theme: ExtendedTheme) => ({
    root: {
        padding: 0,
        '&:hover': {
            backgroundColor: '#f9f9f9'
        }
    }
}));

export const SettlementCard: React.FC<{ settlement: ISettlementSummary; type: 'open' | 'pending' | 'closed' }> = ({ type, settlement }) => {
    const classes = useStyles();

    const isP1BalancePositive = Number(settlement.netPositions[0]) > 0;
    const isP2BalancePositive = Number(settlement.netPositions[1]) > 0;
    const bothBalancesAreNegative = !isP1BalancePositive && !isP2BalancePositive;
    const bothBalancesArePositive = isP1BalancePositive && isP2BalancePositive;
    const isDifferentDirection = !bothBalancesAreNegative && !bothBalancesArePositive;
    const p1IsBigger = Number(settlement.netPositions[0]) > Number(settlement.netPositions[1]);

    let direction;
    let p1DeservesMoney = false;
    if (isP1BalancePositive && !isDifferentDirection) {
        p1DeservesMoney = true;
    } else if (isDifferentDirection && p1IsBigger) {
        p1DeservesMoney = true;
    }
    if ((p1DeservesMoney && settlement.myPartyIndex === 0) || (!p1DeservesMoney && settlement.myPartyIndex === 1)) {
        direction = 'receivable';
    }
    if ((p1DeservesMoney && settlement.myPartyIndex === 1) || (!p1DeservesMoney && settlement.myPartyIndex === 0)) {
        direction = 'payable';
    }
    const value = Math.abs(Number(settlement.netPositions[0]) - Number(settlement.netPositions[1]));
    const netPosition = Math.abs(Number(settlement.netPositions[settlement.myPartyIndex]));
    const percentage = ((value / netPosition) * 100).toFixed(2);

    let status: { text: string; bgColor: string; color: string };

    switch (type) {
        case 'open': {
            status = ContractStatuses.readyToAnalyze;
            break;
        }
        case 'pending': {
            status = ContractStatuses.negotiation;
            break;
        }
        case 'closed': {
            status = ContractStatuses.settled;
            break;
        }
    }

    return (
        <Link to={`/dashboard/roaming/contracts/${settlement.contractId}/settlement`}>
            <Card
                bordered={false}
                className={classes.root}
                actions={[
                    <Box px={6} display="flex" key={1}>
                        <Tag color={status.bgColor} style={{ color: status.color }}>
                            {status.text}
                        </Tag>
                    </Box>
                ]}
            >
                <Box display="flex">
                    <T variant="subtitle2">{settlement.name}</T>
                    <Separator />
                    <T color={FontColor.B45} variant="subtitle2">
                        {moment(settlement.updatedAt * 1e3).format(periodDateFormat)}
                    </T>
                </Box>
                <Box mb={5}>
                    <T color={FontColor.B45} variant="subtitle2">
                        {direction}
                    </T>
                </Box>
                <Box display="flex" justifyContent="flex-end">
                    <div>
                        <T variant="body2" align="right" weight={FontWeight.Semibold}>
                            {new Currency(value, settlement.currency).toLocalString(0)}
                        </T>
                        <T color={FontColor.B45} variant="subtitle2">
                            {percentage}% of {new Currency(netPosition, settlement.currency).toLocalString(0)}
                        </T>
                    </div>
                </Box>
            </Card>
        </Link>
    );
};
