import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import classNames from 'classnames';
import { CdrNames, CdrNamesTooltip } from '../../components/poppers/cdrNamesTooltip';
import { Icon, MenuButton, Sticky, SubHeader, Table, UploadButton, Pagination, FiltersPane } from '@components';
import queryString from 'querystring';
import { PageTitle } from '@modules/Voice/components';
import {
    getAccounts,
    getExternalHash,
    getFileDialogInfo,
    getGenerateLinkError,
    getSettlements,
    getSettlementsPagination,
    getFreshlyCreatedSettlement,
    getSettlementUpdateCount,
    getExternalPeriods
} from '@selectors/voice';

import voiceActions, { ComparisonType, Direction, voiceType } from '@actions/voice';
import { useDispatch, useSelector } from 'react-redux';
import map from 'lodash/map';
import get from 'lodash/get';
import { NewSettlement } from '@modules/Voice/components/NewSettlement';
import { Dialog } from '@components/Modal/Dialog';
import { CopyLinkModal } from '@components/Modal';

import communicationActions from '@actions/communication';

import { Dropdown, BackButton } from '@components/Buttons';

import { RouteChildrenProps } from 'react-router';
import SvgEmptyStateSettlement from '@components/Svg/emptySettlementState';
import SvgEmptyStateSettlementFooter from '@components/Svg/emptySettlementStateFooter';
import { getSpinner } from '@selectors/ui';
import './styles.scss';
import { DEFAULT_FILE_UPLOAD_ERROR_MSG } from '@constants/messages';
import { FilterItemSubtype, FilterItemType } from '@components/FiltersPane/FiltersPanel';
import { voicePaths, setPathId } from 'utils/routePaths';
import moment from 'moment';
import { periodDateFormat } from '@constants';

const tableCols = [
    { title: 'Period', key: 'date' },
    { title: 'Status', key: 'status' },
    { title: 'Total Amount', key: 'totalAmount' },
    { title: 'Amount Diff', key: 'amountDiff' },
    { title: 'Updated', key: 'updated', defaultSort: true },
    { title: 'Files', key: 'comparedTo' }
];

const statusOptions = {
    Compared: 'COMPARED',
    Uncompared: 'UNCOMPARED',
    'In Negotiation': 'IN_DISPUTE',
    Settled: 'SETTLED',
    'In Progress': 'IN_PROGRESS'
};

const OUR_CDR = 'our-cdr';
const ORIGINAL_CDR_FILE_HASH = 'originalCDRFileHash';
const ORIGINAL_PARTNER_CDR_FILE_HASH = 'originalPartnerCDRFileHash';
const cdrFileTypes = [
    {
        comparedToLabel: 'Our',
        title: 'Upload Carrier CDRs',
        propName: 'normalizedCDRFileHash' as 'normalizedCDRFileHash',
        isOfPartner: false,
        className: OUR_CDR
    },
    {
        comparedToLabel: 'CDR',
        title: 'Upload Partner CDRs',
        propName: 'normalizedPartnerCDRFileHash' as 'normalizedPartnerCDRFileHash',
        isOfPartner: true,
        className: 'partner-cdr'
    }
];

const enum DROPDOWN_ITEMS_TEXT {
    UPLOAD_CDR = 'Upload Carrier CDRs',
    UPLOAD_PARTNER_INVOICE = 'Upload Partner Invoice',
    ASK_EXTERNAL_PARTNER_INVOICE = 'Ask for Partner CDR',
    UPLOAD_PARTNER_CDR = 'Upload Partner CDRs',
    UPLOAD_UNRATED_CDR = 'Upload Carrier Unrated CDRs',
    UPLOAD_RATESHEET = 'Upload Rate Sheets',
    UPLOAD_PARTNER_RATESHEET = 'Upload Partner Rate Sheets'
}

const AcceptedRatesheetTypes = '.csv, .txt, .tsv, .xls, .xlsx';
interface ISettlementsContainerProps extends RouteChildrenProps {
    fileUploadError: string;
    linkGenerateError: string;
    actions?: typeof voiceActions;
    invoiceFileHash?: string;
    partnerRateSheetsExist?: boolean;
    unratedCDRs?: string;
    externalSettlementPeriodHash?: string;
    settlements?: any[];
    status?: string;
    fileUploadResult?: any;
}

// TODO refactor SettlementsContainer to work with FilterPanel component vs filterPane and delete filterPane
export const SettlementsContainer: React.FC<ISettlementsContainerProps> = (props) => {
    const [hasSettlements, setHasSettlements] = useState(false);
    const [isFiltersOpen, changeFiltersOpen] = useState(false);
    const [filtersState, setFiltersState] = useState();
    const [isDrawerOpen, changeDrawerOpen] = useState(false);
    const [sorting, setSorting] = useState<{
        by: string;
        order: string;
    } | null>(null);
    const [filters, setFilters] = useState<{ [key: string]: any } | null>(null);
    const [page, setPage] = useState(0);

    const { isFileDialogOpen, fileDialogMessage } = useSelector(getFileDialogInfo);
    const externalSettlementPeriodHash = useSelector(getExternalHash);
    const linkGenerateError = useSelector(getGenerateLinkError);
    const spinnerStatus = useSelector(getSpinner);
    const spinnerLoadingStatus = spinnerStatus.isLoading;
    const externalSettlement = useSelector(getExternalPeriods);
    const openDrawer = () => changeDrawerOpen(true);
    const closeDrawer = () => changeDrawerOpen(false);

    const dispatch = useDispatch();

    const closeFileDialog = () => dispatch(voiceActions.voiceFileDialogClose());
    const closeLinkDialog = () => dispatch(voiceActions.voiceResetExternalHash());
    const settlements = useSelector(getSettlements);
    const settlementUpdateCount = useSelector(getSettlementUpdateCount);
    const createdSettlement = useSelector(getFreshlyCreatedSettlement);
    const settlementsPagination = useSelector(getSettlementsPagination);
    const accounts = useSelector(getAccounts);
    const accountId = (props.match!.params as any).id;
    const account = accounts.find(({ id }) => id === accountId);
    const accountName = get(account, 'name', '');
    const accountPresentedName = get(account, 'presentedName', accountName);
    useEffect(() => {
        if (!accounts.length) {
            dispatch(voiceActions.voiceQueryAccounts());
        }
        dispatch(communicationActions.subscribeUpdating('settlementPeriod', voiceType.VOICE_SETTLEMENT_PERIOD_UPDATE));

        return () => {
            dispatch(communicationActions.unsubscribeUpdating('settlementPeriod'));
        };
    }, []);

    useEffect(() => {
        if (accounts.length) {
            dispatch(voiceActions.voiceQuerySettlementPeriods(page, { ...filters, accountName: [accountName] }, sorting ? sorting : undefined));
        }
    }, [accounts, settlementUpdateCount, page, filters, sorting]);

    // If I created a new settlement under another account - switch to it
    useEffect(() => {
        if (createdSettlement) {
            if (account && createdSettlement.accountId !== accountId) {
                props.history.replace(location.pathname.replace(accountId, createdSettlement.accountId));
            }
            dispatch(voiceActions.freshlyCreatedSettlementClear());
        }
    }, [createdSettlement]);
    useEffect(() => {
        setPage(1);
        setFilters({});
    }, [accountId]);

    useEffect(() => {
        setHasSettlements(hasSettlements || (settlements && settlements.length > 0));
    }, [settlements]);

    useEffect(() => {
        if (externalSettlementPeriodHash) {
            dispatch(voiceActions.voiceGetSettlementPeriod(externalSettlementPeriodHash));
        }
    }, [externalSettlementPeriodHash]);

    const openFilters = () => changeFiltersOpen(true);
    const closeFilters = () => changeFiltersOpen(false);

    const onFilterApply = (newFilters: typeof filters = null): void => {
        if (newFilters && newFilters.status) {
            newFilters.status = newFilters.status.map((status: string) => status.toUpperCase());
        }
        setFilters(newFilters);
        setPage(0);
    };

    const renderFileDialog = () => (
        <Dialog isOpen={isFileDialogOpen} onClose={closeFileDialog} classList="voice-uploads">
            <div className="new-rsp-module__dialog-message">{fileDialogMessage}</div>
        </Dialog>
    );

    const renderLinkDialog = () => {
        const gotError = Boolean(linkGenerateError);
        return (
            <Dialog
                isOpen={Boolean(externalSettlementPeriodHash) || gotError}
                onClose={closeLinkDialog}
                classList="copy-external-link-modal"
                headerText="Partner's CDR request"
                isDefaultHeader
                FooterComponent={<div className={'modal-footer external-link'} />}
            >
                <>
                    {gotError ? (
                        linkGenerateError
                    ) : (
                        <CopyLinkModal externalSettlement={externalSettlement} externalSettlementPeriodHash={externalSettlementPeriodHash} />
                    )}
                </>
            </Dialog>
        );
    };

    const stopEventPropagation = (e: any) => {
        e.stopPropagation();
    };

    const renderFilesColumn = (item: any) => {
        return (
            <div className="labels-wrap" onClick={stopEventPropagation}>
                <div className={`svg invoice ${item.invoiceFileHash ? '' : 'empty'}`} />
                {cdrFileTypes.map(({ propName, className, isOfPartner }) => {
                    const isOurCdr = !isOfPartner;
                    let cdrStatus = 'empty';
                    if (Object.keys(item.ratingErrors).length > 0 && isOurCdr) {
                        cdrStatus = 'error';
                    } else if (item[propName]) {
                        cdrStatus = 'rated';
                    } else if (item.unratedCDRs !== '' && item.status !== 'IN_PROGRESS' && isOurCdr) {
                        cdrStatus = 'error';
                    }

                    if (cdrStatus === 'rated') {
                        const itemNameToUse = isOurCdr ? ORIGINAL_CDR_FILE_HASH : ORIGINAL_PARTNER_CDR_FILE_HASH;
                        const cdrNamesArray: string[] =
                            typeof item[itemNameToUse] === 'string' ? [item[itemNameToUse]] : Object.values(item[itemNameToUse]);
                        return (
                            <CdrNamesTooltip
                                title={<CdrNames cdrNames={cdrNamesArray} />}
                                placement={'left'}
                                interactive
                                key={classNames(propName, className)}
                            >
                                <div className={`iconWrapper`} id={className}>
                                    <div className={classNames('svg', className, cdrStatus)} />
                                    <div className={'cdrIconNumber'}>{cdrNamesArray.length}</div>
                                </div>
                            </CdrNamesTooltip>
                        );
                    } else {
                        return (
                            <div className={`iconWrapper`} key={`${propName} ${className}`}>
                                <div className={`svg ${className} ${cdrStatus}`} />
                            </div>
                        );
                    }
                })}
            </div>
        );
    };

    const generateTableData = () => {
        return map(settlements, (item) => {
            const { status } = item;
            const itemStatusCleanName = status.replace('_', '');
            const statusDisplayName = status.toLowerCase() === 'in_dispute' ? 'In Negotiation' : status.replace('_', ' ');
            return {
                ...item,
                status: (
                    <div className={'status-wrapper ' + itemStatusCleanName}>
                        <div className={'icon spin'} />
                        <div className="name">{statusDisplayName}</div>
                    </div>
                ),
                disabled: ['uncompared', 'in_progress'].includes(status.toLowerCase()),
                statusValue: status,
                amountDiff: (
                    <div
                        className={classNames('compared', {
                            warn: Math.abs(item.amountDiffPer) > 1
                        })}
                    >
                        <span>{Boolean(item.amountDiff) ? `${item.formattedAmountDiff}` : 'N/A'}</span>
                        <br />
                        {Boolean(item.amountDiffPer) && <span>({item.formattedAmountDiffPer})</span>}
                    </div>
                ),
                updated: item.updatedDate ? moment(item.updatedDate * 1000).format(periodDateFormat) : undefined,
                comparedTo: renderFilesColumn(item)
            };
        });
    };

    const renderSettlementUploadActions = ({
        id,
        statusValue,
        normalizedCDRFileHash,
        unratedCDRs,
        normalizedPartnerCDRFileHash
    }: any): JSX.Element => {
        const uploadInvoiceCallback = (file: File) => {
            dispatch(
                voiceActions.voiceSettlementUpload({
                    file,
                    backendFunctionName: 'setInvoiceFileHash',
                    rowId: id,
                    errorMessage: DEFAULT_FILE_UPLOAD_ERROR_MSG
                })
            );
        };

        const uploadCdrCallback = (file: File, forWho: string) => {
            dispatch(
                voiceActions.voiceSettlementUpload({
                    file,
                    backendFunctionName: 'parseCdrsFile',
                    rowId: id,
                    direction: forWho,
                    shouldCompress: true,
                    errorMessage: DEFAULT_FILE_UPLOAD_ERROR_MSG
                })
            );
        };

        const uploadUnratedCdrCallback = (file: File, direction: string) => {
            dispatch(
                voiceActions.voiceSettlementUpload({
                    file,
                    backendFunctionName: 'uploadUnratedCDRs',
                    rowId: id,
                    direction,
                    shouldCompress: true,
                    errorMessage: DEFAULT_FILE_UPLOAD_ERROR_MSG
                })
            );
        };

        const uploadRateSheetsCallback = (file: File, direction: string) => {
            dispatch(
                voiceActions.voiceSettlementUpload({
                    file,
                    backendFunctionName: 'uploadRatesheets',
                    rowId: id,
                    direction,
                    shouldCompress: true,
                    errorMessage: DEFAULT_FILE_UPLOAD_ERROR_MSG
                })
            );
        };

        const generateExternalLinkCallback = (): void => {
            dispatch(voiceActions.voiceLinkGenerate(id));
        };

        const uploadCdrCallbackMy = (file: File) => uploadCdrCallback(file, 'cdr_invoice');
        const uploadCdrCallbackPartner = (file: File) => uploadCdrCallback(file, 'partner_cdr_invoice');
        const uploadUnratedCdrCallbackCarrier = (file: File) => uploadUnratedCdrCallback(file, 'cdr_invoice');
        const uploadRatesheetsCallback = (file: File) => uploadRateSheetsCallback(file, 'receivable');
        const uploadRatesheetsCallbackPartner = (file: File) => uploadRateSheetsCallback(file, 'payable');

        const getPrompt = (actionName: DROPDOWN_ITEMS_TEXT) => {
            let prompt;
            const isUnratedCdrCalculated = unratedCDRs && normalizedCDRFileHash;
            const overrideActionNameList: DROPDOWN_ITEMS_TEXT[] = [DROPDOWN_ITEMS_TEXT.UPLOAD_CDR, DROPDOWN_ITEMS_TEXT.UPLOAD_PARTNER_RATESHEET];

            if (isUnratedCdrCalculated && overrideActionNameList.includes(actionName)) {
                prompt = {
                    description: (
                        <span>
                            Uploading rated CRDs will override
                            <br />
                            the existing rating calculation.
                            <br />
                            Are you sure?
                        </span>
                    ),
                    confirmText: 'Override',
                    cancelText: 'Cancel'
                };
            } else {
                switch (statusValue) {
                    case 'in_progress': {
                        prompt = {
                            description: (
                                <span>
                                    Action is in progress.
                                    <br />
                                    Would you like to override it?
                                </span>
                            ),
                            confirmText: 'Override',
                            cancelText: 'Cancel'
                        };
                        break;
                    }
                    case 'compared': {
                        if (
                            (normalizedPartnerCDRFileHash && actionName === DROPDOWN_ITEMS_TEXT.UPLOAD_PARTNER_CDR) ||
                            actionName === DROPDOWN_ITEMS_TEXT.UPLOAD_CDR ||
                            actionName === DROPDOWN_ITEMS_TEXT.UPLOAD_PARTNER_INVOICE ||
                            (actionName === DROPDOWN_ITEMS_TEXT.UPLOAD_UNRATED_CDR && normalizedCDRFileHash)
                        ) {
                            prompt = {
                                description: (
                                    <span>
                                        Comparison already exists.
                                        <br />
                                        Would you like to replace it?
                                    </span>
                                ),
                                confirmText: 'Replace',
                                cancelText: 'Cancel'
                            };
                        }
                        break;
                    }
                    default: {
                        prompt = undefined;
                        break;
                    }
                }
            }
            return prompt;
        };

        return (
            <Dropdown classList="settlement-actions" inline closeOnMouseLeave>
                <UploadButton
                    prompt={getPrompt(DROPDOWN_ITEMS_TEXT.UPLOAD_CDR)}
                    onFileSelect={uploadCdrCallbackMy}
                    name={ORIGINAL_CDR_FILE_HASH}
                    accept={'.csv, .txt, .tsv'}
                >
                    {DROPDOWN_ITEMS_TEXT.UPLOAD_CDR}
                </UploadButton>
                <UploadButton
                    prompt={getPrompt(DROPDOWN_ITEMS_TEXT.UPLOAD_PARTNER_INVOICE)}
                    onFileSelect={uploadInvoiceCallback}
                    name="invoice"
                    accept={'.pdf, .xls, .xlsx'}
                >
                    {DROPDOWN_ITEMS_TEXT.UPLOAD_PARTNER_INVOICE}
                </UploadButton>
                <MenuButton onClick={generateExternalLinkCallback} name="askPartnerCDR" error={linkGenerateError}>
                    {DROPDOWN_ITEMS_TEXT.ASK_EXTERNAL_PARTNER_INVOICE}
                </MenuButton>
                <UploadButton
                    prompt={getPrompt(DROPDOWN_ITEMS_TEXT.UPLOAD_PARTNER_CDR)}
                    onFileSelect={uploadCdrCallbackPartner}
                    name={ORIGINAL_PARTNER_CDR_FILE_HASH}
                    accept={'.csv, .txt, .tsv'}
                >
                    {DROPDOWN_ITEMS_TEXT.UPLOAD_PARTNER_CDR}
                </UploadButton>
                <UploadButton
                    prompt={getPrompt(DROPDOWN_ITEMS_TEXT.UPLOAD_UNRATED_CDR)}
                    onFileSelect={uploadUnratedCdrCallbackCarrier}
                    name="unratedCDRs"
                    accept={'.csv, .txt, .tsv'}
                >
                    {DROPDOWN_ITEMS_TEXT.UPLOAD_UNRATED_CDR}
                </UploadButton>
                <UploadButton
                    prompt={getPrompt(DROPDOWN_ITEMS_TEXT.UPLOAD_RATESHEET)}
                    onFileSelect={uploadRatesheetsCallback}
                    accept={AcceptedRatesheetTypes}
                >
                    {DROPDOWN_ITEMS_TEXT.UPLOAD_RATESHEET}
                </UploadButton>
                <UploadButton
                    prompt={getPrompt(DROPDOWN_ITEMS_TEXT.UPLOAD_PARTNER_RATESHEET)}
                    onFileSelect={uploadRatesheetsCallbackPartner}
                    name="partnerRateSheetsExist"
                    accept={AcceptedRatesheetTypes}
                >
                    {DROPDOWN_ITEMS_TEXT.UPLOAD_PARTNER_RATESHEET}
                </UploadButton>
            </Dropdown>
        );
    };

    const onSortingColumnSelect = ({ key }: { key: string }, isAscending: boolean) => {
        const tableColumnToBackendKey = {
            account: 'accountName',
            date: 'startDate',
            totalAmount: 'totalAmount',
            amountDiff: 'amountDiff',
            status: 'status',
            updated: 'updatedDate',
            comparedTo: 'comparedTo'
        };

        const newSorting = {
            by: tableColumnToBackendKey[key as keyof typeof tableColumnToBackendKey],
            order: isAscending ? 'ascending' : 'descending'
        };

        setSorting(newSorting);
        setPage(0);
    };

    const rowClick = ({ id, disabled }: { id: string; disabled: boolean }) => {
        const clickedOnSettlement = settlements.find((settlement) => settlement.id === id);
        if (!disabled && clickedOnSettlement !== undefined) {
            const { originalCDRFileHash, originalPartnerCDRFileHash, invoiceFileHash } = clickedOnSettlement;
            let comparisonTypeToUrl: string = '';
            if (clickedOnSettlement.originalCDRFileHash && invoiceFileHash) {
                comparisonTypeToUrl = ComparisonType.CDR_INVOICE;
            } else if (originalCDRFileHash && originalPartnerCDRFileHash) {
                comparisonTypeToUrl = ComparisonType.CDR_PARTNER_CDR;
            } else if (originalPartnerCDRFileHash && invoiceFileHash) {
                comparisonTypeToUrl = ComparisonType.PARTNER_CDR_INVOICE;
            }
            const direction = clickedOnSettlement.payableFileHash ? Direction.Payable : Direction.Receivable;
            const queryParams = {
                direction,
                comparisonType: comparisonTypeToUrl
            };
            const queryParamsStringified = queryString.stringify(queryParams);
            props.history.push({
                pathname: setPathId(voicePaths.settlementsOverview, id),
                search: `?${queryParamsStringified}`
            });
        }
    };

    const goBackClick = (e: React.MouseEvent) => {
        e.stopPropagation();
        props.history.push(voicePaths.settlements);
    };

    if (!hasSettlements && !spinnerLoadingStatus) {
        return (
            <main className="voice-settlements">
                <Helmet title="Clear Voice - Settlements" />
                <div className="empty-settlement-wrapper">
                    <h1 className="title">{accountPresentedName} settlements</h1>
                    <span className="subtitle">Start by creating settlements</span>
                    <SvgEmptyStateSettlement />
                    <button className="btn btn-primary empty-settlement" onClick={openDrawer}>
                        Create New Settlement
                    </button>
                    <div className="empty-state-footer">
                        <SvgEmptyStateSettlementFooter />
                    </div>
                </div>
                <NewSettlement isOpen={isDrawerOpen} closeDrawer={closeDrawer} accountPresentedName={accountPresentedName} />
            </main>
        );
    } else {
        return (
            <main className="voice-settlements">
                <Helmet title="Clear Voice - Settlements" />
                <Sticky>
                    <SubHeader title="">
                        <span onClickCapture={goBackClick}>
                            <BackButton classes="circled-button">
                                <Icon iconName="back" title="Go Back" />
                            </BackButton>
                        </span>
                        <PageTitle title={`${accountPresentedName} settlements`} />
                        <button className="btn btn-primary" onClick={openDrawer}>
                            Add New
                        </button>
                    </SubHeader>
                </Sticky>

                <div className="action-bar">
                    <div className="filters" onClick={openFilters}>
                        <Icon iconName="filtersGrey" />
                        Add Filters
                    </div>
                    <Pagination onSelectPage={setPage} pagination={settlementsPagination} />
                </div>
                <NewSettlement isOpen={isDrawerOpen} closeDrawer={closeDrawer} accountPresentedName={accountPresentedName} />
                <FiltersPane
                    onApply={onFilterApply}
                    isOpen={isFiltersOpen}
                    currency={get(account, 'currency', 'USD')}
                    close={closeFilters}
                    values={filtersState}
                    onChange={setFiltersState}
                    items={[
                        {
                            type: FilterItemType.Date,
                            key: 'dateFilter',
                            title: 'Date Period'
                        },
                        {
                            type: FilterItemType.Number,
                            subType: FilterItemSubtype.Money,
                            key: 'totalAmount',
                            title: 'Total Amount'
                        },
                        {
                            type: FilterItemType.Number,
                            subType: FilterItemSubtype.Money,
                            key: 'amountDiff',
                            title: 'Amount Diff'
                        },
                        {
                            type: FilterItemType.Select,
                            key: 'status',
                            title: 'Status',
                            options: Object.keys(statusOptions)
                        }
                    ]}
                />
                <Table
                    handleRowWithValueClick={rowClick}
                    classes="voice-settlements-list"
                    cols={tableCols}
                    data={generateTableData()}
                    options={renderSettlementUploadActions}
                    noHeader={true}
                    notFrontendSortable={true}
                    onSortingColumnSelect={onSortingColumnSelect}
                    noExport
                />
                {renderFileDialog()}
                {renderLinkDialog()}
            </main>
        );
    }
};
