import memoize from 'lodash/memoize';
import { parseBalance } from '@helpers/formaters';
import { checkWithPolicy } from '@clear/password-policy';

export const email = (value: string) => (value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ? 'Invalid email address' : undefined);

export const fieldSize = (size: number) => (value: any) => (value && value.length < size ? `Must be ${size} digits` : undefined);

export const required = (value: any) => (value ? undefined : 'Required');

export const requiredForArray = (value: any[] = []) => (value.length === 0 ? 'Required' : undefined);

export const maxNumber = memoize((max: string) => (value: string) => {
    return value && parseBalance(value) > parseBalance(max) ? `Must be ${max} or less` : undefined;
});

export const minNumber = memoize((min: string) => (value: string): string | undefined => {
    return value && parseBalance(value) < parseBalance(min) ? `Must be ${min} or more` : undefined;
});

export const numberSimilar = memoize((desired: string, deviationPercentage: number) => (valueStr: string) => {
    if (!valueStr) {
        return undefined;
    }
    const desiredValue = parseBalance(desired);
    const value = parseBalance(valueStr);
    return Math.abs(desiredValue - value) / desiredValue > deviationPercentage / 100
        ? `Should be between ${desiredValue * (100 - deviationPercentage) * 0.01} and ${desiredValue * (100 + deviationPercentage) * 0.01}`
        : undefined;
});

export const englishLettersWithNums = (value: string) =>
    value && !/^[a-z][a-z0-9]*$/i.test(value) ? 'Must be English letters or numbers' : undefined;

export const excludeSpecialCharacters = (value: string) =>
    value && /[\'|\"|\*|\%|\_|]+$/.test(value) ? 'Characters “, ‘, *, %, _ are not allowed' : undefined;

export const dateValidation = (value: string) =>
    value && /^(0?[1-9]|[12][0-9]|3[01])[\/\-](0?[1-9]|1[012])[\/\-]\d{4}$/.test(value) ? undefined : 'Must be DD/MM/YYYY format';

export const validateLogin = (passwordPolicy: any, password: string, params: any) => {
    const result = checkWithPolicy(password, params.username, passwordPolicy);
    return result.isValid ? undefined : result.errors[0];
};
