import { GeneralDialog } from '@components/Modal/GeneralDialog';
import React, { useCallback, useState } from 'react';
import { Box } from '@material-ui/core';
import { T } from '@components/T/T';
import classNames from 'classnames';
import { Button, Divider } from 'antd';
import { formatBalance } from '@helpers/formaters';
import uiActions from '@actions/ui';
import { useSelector } from 'react-redux';
import { getCurrentInfo, getFinalBillingStatement } from '@selectors/roaming';
import { getCurrencySymbol } from '@constants';
import { settlementOfferActions } from '@actions/settlementOffers';
import { useAction } from '../../../hooks/useAction';
import { SettlementOfferStatus, useOfferStatus } from '@modules/Roaming/components/SettlementOffer/SettlementOffer';
import { Separator } from '@components/Separator/Separator';
import { getMyPeerId } from '@selectors/account';
import {
    DisputeResolutionMessageBox,
    NetPositionTable,
    OFFER_DIRECTION,
    OfferForm,
    OfferMessages,
    useSettlementOfferStyles
} from '../DisputeResolutionModal';
import { noop } from 'lodash';
import { isActiveOfferMine, selectLatestOffer } from '@selectors/roaming/settlementOffers';
import { FontColor, materialTheme } from '@components/theme';
import { getLastPartOfId } from '../../../utils/id-utils';
import { CurrencyViewSelection } from '@components/DisputeResolution/CurrencyViewSelector/CurrencyViewSelection';

export const PendingOffer: React.FC<{ onClickMakeCounterOffer: () => void }> = ({ onClickMakeCounterOffer }) => {
    const agreement = useSelector(getCurrentInfo);
    const classes = useSettlementOfferStyles();
    const hideModal = useAction(uiActions.hideModal);
    const createSettlementOfferComment = useAction(settlementOfferActions.createSettlementOfferComment);
    const finalSettlement = useSelector(getFinalBillingStatement);
    const accept = useAction(settlementOfferActions.acceptSettlementOffer);
    const [isReplying, setIsReplying] = useState(false);
    const [isAccepting, setIsAccepting] = useState(false);

    if (!finalSettlement) {
        return null;
    }
    const contractName = agreement.contract.config.name;

    const offer = useSelector(selectLatestOffer);
    const [offerComment, setOfferComment] = useState('');

    const formatOfferAmount = useCallback(
        (value) =>
            formatBalance(value, undefined, {
                currency: getCurrencySymbol(agreement.terms.currency),
                roundToInteger: true
            }),
        []
    );
    const handleCommentChange = useCallback((e: React.ChangeEvent<HTMLTextAreaElement>) => setOfferComment(e.target.value), []);
    const onCloseModalClick = useCallback(() => {
        hideModal();
    }, []);

    const onAcceptOffer = useCallback(async () => {
        setIsAccepting(true);
        await accept(finalSettlement.contractId, finalSettlement.id);
        setIsAccepting(false);
        hideModal();
    }, [finalSettlement, accept]);

    const onReply = useCallback(async () => {
        setIsReplying(true);
        await createSettlementOfferComment(finalSettlement.contractId, offer?.id, offerComment);
        setIsReplying(false);
        setOfferComment('');
    }, [offerComment, offer, finalSettlement, createSettlementOfferComment]);

    let offerDirection: OFFER_DIRECTION;
    if (useSelector(isActiveOfferMine)) {
        offerDirection = finalSettlement.suggestedOfferDirection;
    } else if (finalSettlement.suggestedOfferDirection === OFFER_DIRECTION.PAY) {
        offerDirection = OFFER_DIRECTION.RECEIVE;
    } else {
        offerDirection = OFFER_DIRECTION.PAY;
    }

    const isInProgress = isReplying || isAccepting;
    return (
        <GeneralDialog
            onCloseClick={onCloseModalClick}
            shouldCloseOnClickOutside={!isInProgress}
            modalClassName={'padding'}
            width={780}
            isDialogOpen={true}
            HeaderComponent={<ModalHeaderComponent subHeader={contractName} />}
            FooterComponent={
                <ModalFooterComponent
                    isAccepting={isAccepting}
                    onClickMakeCounterOffer={onClickMakeCounterOffer}
                    onAccept={onAcceptOffer}
                    onCancelClick={onCloseModalClick}
                    isInProgress={isInProgress}
                />
            }
        >
            <Box className={classes.mainWrapper}>
                <Box mb={7} display={'flex'}>
                    <OfferForm
                        isReadOnly
                        offerDirection={offerDirection}
                        offerAmount={offer!.offerAmount}
                        discrepancy={finalSettlement.discrepancy}
                        handlePercentageChange={noop}
                        setOfferAmount={noop}
                    />
                </Box>
                <NetPositionTable offerAmount={offer!.offerAmount} offerDirection={offerDirection} />
                <OfferMessages />
                <Divider className={classes.messagesDivider} />
                <DisputeResolutionMessageBox
                    title="Send a message to your partner"
                    disabled={isInProgress}
                    value={offerComment}
                    onChange={handleCommentChange}
                    SendButton={
                        <Box position={'absolute'} bottom={10} right={10}>
                            <Button onClick={onReply} loading={isReplying} disabled={!offerComment.trim() || isInProgress}>
                                Send
                            </Button>
                        </Box>
                    }
                />
            </Box>
        </GeneralDialog>
    );
};

const ModalHeaderComponent: React.FC<{ subHeader: string }> = ({ subHeader }) => {
    const offerStatus = useOfferStatus();
    const titleByStatus: Record<SettlementOfferStatus, string> = {
        [SettlementOfferStatus.None]: 'New Offer',
        [SettlementOfferStatus.TheirOffer]: 'Offer Received',
        [SettlementOfferStatus.TheirCounterOffer]: 'Counter offer Received',
        [SettlementOfferStatus.MyOffer]: 'My Offer',
        [SettlementOfferStatus.MyCounterOffer]: 'My Counter offer',
        [SettlementOfferStatus.Accepted]: 'Accepted Offer'
    };

    const title = titleByStatus[offerStatus];

    const classes = useSettlementOfferStyles();
    return (
        <Box className={classNames(classes.header)} mx={-5} px={5}>
            <Box display="flex" alignItems="baseline" width="100%">
                <Box mr={2}>
                    <T>{title}</T>
                </Box>
                <Box color={materialTheme.palette.lighter}>
                    <T variant={'caption'} color={FontColor.B45}>
                        {subHeader}
                    </T>
                </Box>
                <Separator />
                <CurrencyViewSelection />
            </Box>
        </Box>
    );
};

const ModalFooterComponent: React.FC<{
    onClickMakeCounterOffer: () => void;
    isAccepting: boolean;
    onAccept: () => void;
    onCancelClick: () => any;
    isInProgress: boolean;
}> = ({ onCancelClick, isAccepting, isInProgress, onClickMakeCounterOffer, onAccept }) => {
    const offer = useSelector(selectLatestOffer);
    const myPeerId = useSelector(getMyPeerId);
    const classes = useSettlementOfferStyles();

    const isMyOffer = offer?.initiatorId === myPeerId;
    if (isMyOffer) {
        return (
            <Box className={classNames(classes.footer)}>
                <T>{`ID ${getLastPartOfId(offer?.id)}`}</T>
                <Separator />
                <Button onClick={onCancelClick}>Close</Button>
            </Box>
        );
    } else {
        return (
            <Box className={classNames(classes.footer)}>
                <T>{`ID ${getLastPartOfId(offer?.id)}`}</T>
                <Separator />
                {isMyOffer ? null : (
                    <Box mr={3} clone>
                        <Button onClick={onClickMakeCounterOffer} disabled={isInProgress}>
                            Make Counter Offer
                        </Button>
                    </Box>
                )}
                <Button onClick={onAccept} type="primary" loading={isAccepting} disabled={isInProgress}>
                    Accept Offer
                </Button>
            </Box>
        );
    }
};
