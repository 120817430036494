import loadable, { LoadableComponent } from '@loadable/component';
import { voicePaths } from 'utils/routePaths';
import { AdminAndManagers } from '@selectors/account';

const Transactions: LoadableComponent<any> = loadable(() =>
    import(/* webpackChunkName: "Settlements" */ /* webpackPrefetch: true */ '@modules/Payment/index').then((res) => res.default)
);

const TransactionsOverview: LoadableComponent<any> = loadable(() =>
    import(/* webpackChunkName: "Settlements" */ /* webpackPrefetch: true */ '@modules/Payment/Overview/index').then((res) => res.default)
);

const PaymentClient: LoadableComponent<any> = loadable(() =>
    import(/* webpackChunkName: "Settlements" */ /* webpackPrefetch: true */ '@modules/Payment/containers/ClientListContainer').then(
        (res) => res.default
    )
);

const Voice: LoadableComponent<JSX.Element> = loadable(() =>
    import(/* webpackChunkName: "Rates" */ /* webpackPrefetch: true */ '@modules/Voice/index').then((res) => res.Voice)
);

const Roaming: LoadableComponent<JSX.Element> = loadable(() =>
    import(/* webpackChunkName: "Agreements" */ /* webpackPrefetch: true */ '@modules/Roaming/index').then((res) => res.Roaming)
);

export interface IRouteComponentBaseInfo {
    component: LoadableComponent<JSX.Element>;
    path: string;
    name: string;
    condition?: (a: string) => boolean;
    authorizedGroups?: ReadonlyArray<string>;
}

export type IDashboardRoutesTypes = Record<string, IRouteComponentBaseInfo[] | IRouteComponentBaseInfo>;

export const ROUTES: IDashboardRoutesTypes = {
    eusd: {
        component: Transactions,
        path: '/dashboard/transactions',
        name: 'Payment',
        condition: () => false
    },
    'eusd::fi': [
        {
            component: Transactions,
            path: '/dashboard/transactions',
            name: 'Transactions'
        },
        {
            component: TransactionsOverview,
            path: '/dashboard/transactions-overview',
            name: 'Overview'
        },
        {
            component: PaymentClient,
            path: '/dashboard/payment/client-list',
            name: 'Client'
        }
    ],
    voice: [
        {
            component: Voice,
            path: voicePaths.settlements,
            name: 'Settlements'
        },
        {
            component: Voice,
            path: voicePaths.agreements,
            name: 'Agreements'
        }
    ],
    roaming: [
        {
            component: Roaming,
            path: '/dashboard/settlements',
            name: 'Dashboard'
        },
        {
            component: Roaming,
            path: '/dashboard/roaming',
            name: 'Contracts'
        },
        {
            component: Roaming,
            path: '/dashboard/partnerships',
            name: 'Partners',
            authorizedGroups: AdminAndManagers
        }
    ]
};

export const listOfPossibleRoutingModules: string[] = Object.keys(ROUTES);
