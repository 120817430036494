import { uiType } from '@actions/ui';
import { IAppAction } from './state';
import { voiceType, agreementsType, accountType, authType, communicationType } from '@actions';
import { requestFailType, requestSuccessType } from '@helpers/actionCreator';
import get from 'lodash/get';
import { activityLogsActionType } from '@actions/activityLogs';
import { CountryActionType } from '@actions/country';

export interface IStateSpinner {
    successMessage: string;
    isLoading: boolean;
    message: string;
    classes: string | null;
    pendingCount: number;
}

export enum IDialogType {
    WARNING_BEFORE_UPLOADING_NEW_CONTRACT = 'warningBeforeUploadingNewContract',
    WARNING_BEFORE_APPROVING_SUGGESTED_CONTRACT = 'warningBeforeApprovingNewContract',
    GENERAL_ERROR_FROM_SERVER = 'generalErrorFromServer'
}

export interface IStateModalProps {
    title?: string;
    message?: string;
    closeModal?: () => void;
}

export interface IStateModal {
    modalType: string | null;
    isOpen: boolean;
    modalProps: IStateModalProps;
}

export interface IStateDialog {
    isOpen: boolean;
    isAddPartnerDialogOpen: boolean;
    isSessionExpirationWarningDialogOpen: boolean;
    dialogProps: Record<string, any>;
    openDialogType: IDialogType | null;
}

export interface IStateUi {
    spinner: IStateSpinner;
    modal: IStateModal;
    dialog: IStateDialog;
}

const initialState: IStateUi = {
    spinner: {
        successMessage: '',
        isLoading: false,
        message: 'Loading',
        classes: null,
        pendingCount: 0
    },
    modal: {
        modalType: null,
        isOpen: false,
        modalProps: ({} as any) as IStateModalProps
    },
    dialog: {
        isOpen: false,
        openDialogType: null,
        dialogProps: {},
        isAddPartnerDialogOpen: false,
        isSessionExpirationWarningDialogOpen: false
    }
};

const popErrorModal = (state: IStateUi, dialogMsg: string, action?: any): IStateUi => {
    const msg = action?.errorType === 'AppError' ? action.payload : dialogMsg;
    return {
        ...state,
        dialog: {
            ...state.dialog,
            isOpen: true,
            dialogProps: { message: msg },
            openDialogType: IDialogType.GENERAL_ERROR_FROM_SERVER
        },
        spinner: {
            ...initialState.spinner
        }
    };
};

export function uiReducer(state: IStateUi = initialState, action: IAppAction): IStateUi {
    switch (action.type) {
        case uiType.SPINNER_SHOW:
            return {
                ...state,
                spinner: {
                    ...state.spinner,
                    isLoading: true,
                    ...action.payload
                }
            };
        case uiType.SPINNER_HIDE:
            return {
                ...state,
                spinner: {
                    ...initialState.spinner
                }
            };
        case uiType.INCREMENT_PENDING_SPINNER:
        case uiType.DECREMENT_PENDING_SPINNER:
            const { spinner } = state;
            return {
                ...state,
                spinner: {
                    ...spinner,
                    // Don't go below 0
                    pendingCount: Math.max(0, spinner.pendingCount + (action.type === uiType.INCREMENT_PENDING_SPINNER ? 1 : -1))
                }
            };
        case uiType.MODAL_SHOW:
            return {
                ...state,
                modal: {
                    isOpen: true,
                    ...action.payload
                }
            };
        case uiType.DIALOG_SHOW:
            return {
                ...state,
                dialog: {
                    ...state.dialog,
                    isOpen: true,
                    dialogProps: action.payload,
                    openDialogType: action.payload.dialogType
                }
            };
        case uiType.MODAL_HIDE:
            return {
                ...state,
                modal: {
                    ...state.modal,
                    isOpen: false,
                    modalType: null
                }
            };
        case agreementsType.RESPONSE_TO_SUGGESTED_CONTRACT_PRICES_SUCCESS:
        case agreementsType.SUGGEST_CONTRACT_PRICES_SUCCESS:
        case uiType.DIALOG_HIDE:
            return {
                ...state,
                dialog: {
                    ...state.dialog,
                    isOpen: false,
                    dialogProps: {},
                    openDialogType: null
                }
            };

        case requestFailType(authType.CREATE_USER): {
            const { payload: error } = action;
            const msg = error && typeof error === 'string' ? error : 'We could not create user';
            return popErrorModal(state, msg);
        }
        case communicationType.REQUEST_NOT_ALLOWED:
            const errorType = get(action, 'payload.value.errorType');
            switch (errorType) {
                case 'NotAllowedError':
                    return {
                        ...state,
                        spinner: {
                            ...state.spinner,
                            isLoading: false
                        },
                        dialog: {
                            ...state.dialog,
                            isOpen: true,
                            dialogProps: { message: 'User is not allowed to perform this action' },
                            openDialogType: IDialogType.GENERAL_ERROR_FROM_SERVER
                        }
                    };
                default:
                    return state;
            }

        case requestFailType(agreementsType.REPLACE_PROPOSAL):
            return popErrorModal(state, 'We had a problem replacing the contract', action);

        case agreementsType.AGREEMENTS_APPROVE_FAIL:
            return popErrorModal(state, 'We had a problem Approving the contract', action);

        case accountType.ACCOUNT_GET_INFO_FAIL:
        case accountType.ACCOUNT_GET_PEERNAME_FAIL:
        case accountType.ACCOUNT_GET_USERNAME_FAIL:
            return popErrorModal(state, 'We had a problem getting your info', action);

        case agreementsType.RESPONSE_TO_SUGGESTED_CONTRACT_PRICES_FAIL:
            return popErrorModal(state, 'We are sorry something went wrong', action);

        case agreementsType.GET_AGREEMENTS_FAIL:
            return popErrorModal(state, 'We had a problem getting your agreements', action);
        case agreementsType.AGREEMENTS_QUERY_BILLING_STATEMENT_FAIL:
            return popErrorModal(state, "We had a problem getting the contract's billing statements", action);
        case agreementsType.AGREEMENTS_SET_REPORTS_APPROVAL_FAIL:
            return popErrorModal(state, 'We had a problem approving the requested period', action);
        case agreementsType.AGREEMENTS_QUERY_ROAMING_DISCREPANCY_FAIL:
            return popErrorModal(state, "We had a problem getting the contract's discrepancies", action);
        case agreementsType.GET_AGREEMENTS_FOR_EXPORT_FAIL:
        case agreementsType.GET_DISCREPANCIES_AND_EXPORT_FAIL:
            return popErrorModal(state, 'We had a problem exporting the requested data', action);
        case activityLogsActionType.GET_ACTIVITY_LOGS_FAIL:
            return popErrorModal(state, 'We had a problem getting your activity logs', action);
        case requestFailType(CountryActionType.GET_COUNTRY_MAPPINGS): {
            return popErrorModal(state, 'We had a problem getting Destination Name Sets', action);
        }
        case requestFailType(CountryActionType.GET_TEMPORARY_COUNTRY_TRANSLATIONS):
        case requestFailType(CountryActionType.GET_COUNTRY_TRANSLATIONS): {
            return popErrorModal(state, 'We had a problem getting your Destination Names', action);
        }

        case agreementsType.PROPOSE_PARTNERSHIP_FAIL: {
            const isPartnerVersionOld = action.payload.includes('Unknown function name:');
            const text = isPartnerVersionOld ? "Your partner's platform version doesn't support partnerships yet" : action.payload;
            return popErrorModal(state, text);
        }

        case agreementsType.QUERY_ALL_PARTNERSHIPS_FAIL:
        case agreementsType.QUERY_PARTNERSHIP_FAIL:
            return popErrorModal(state, 'We had a problem getting your partnerships');

        case agreementsType.SUGGEST_CONTRACT_PRICES_FAIL:
            return {
                ...state,
                dialog: {
                    ...state.dialog,
                    isOpen: true,
                    dialogProps: {
                        message:
                            // TODO: delete text after backward version compatibility is taking care of - for now every error that is unknown will provide this error regarding version
                            action.payload ||
                            'We are sorry. It seems that your partner has an older version. This functionality is not available for versions below 2.6.5'
                    },
                    openDialogType: IDialogType.GENERAL_ERROR_FROM_SERVER
                }
            };

        case uiType.SHOW_DISPUTE_RESOLUTION_MODAL:
            return {
                ...state,
                modal: {
                    ...state.modal,
                    modalType: 'disputeResolution',
                    isOpen: true
                }
            };

        case uiType.SHOW_ADD_PARTNER_DIALOG:
        case voiceType.VOICE_CREATE_ACCOUNT_SUCCESS:
        case uiType.HIDE_ADD_PARTNER_DIALOG:
            return {
                ...state,
                dialog: {
                    ...state.dialog,
                    isAddPartnerDialogOpen: action.type === uiType.SHOW_ADD_PARTNER_DIALOG
                }
            };
        case uiType.SHOW_SESSION_EXPIRATION_DIALOG:
        case uiType.HIDE_SESSION_EXPIRATION_DIALOG:
            return {
                ...state,
                dialog: {
                    ...state.dialog,
                    isSessionExpirationWarningDialogOpen: action.type === uiType.SHOW_SESSION_EXPIRATION_DIALOG
                }
            };
        case agreementsType.UPDATE_RUN_SETTLEMENT_APPROVAL_STATUS_FAIL:
            return popErrorModal(state, 'We had a problem approving market share validation file.', action);
        default:
            return state;
    }
}
