import React, { FunctionComponent } from 'react';
import { connect } from 'react-redux';
import { IRootState } from '@reducers';
import { checkWithPolicy, ICheckResult, IPasswordPolicy } from '@clear/password-policy';
import { getPasswordCheckResult } from '@selectors/form';
import { Icon } from '@components';
import './styles.scss';
import classNames from 'classnames';

export interface IPasswordPolicyProps extends Partial<ReturnType<typeof mapStateToProps>> {
    passwordPolicy: IPasswordPolicy | null;
}

const PasswordPolicy: FunctionComponent<IPasswordPolicyProps> = (props) => {
    const { result, passwordPolicy } = props;
    const allErrors: ICheckResult = passwordPolicy ? checkWithPolicy('', '', passwordPolicy, true) : { errors: [], strength: 0, isValid: false };
    return (
        <div className="password-policy">
            <h2 className="title">PASSWORD POLICY</h2>
            {allErrors.errors.map((rule) => (
                <div className="rule" key={rule}>
                    <Icon
                        className={classNames('small', {
                            invisible: result.errors.includes(rule)
                        })}
                        iconName="success"
                    />
                    {rule.replace('Password must ', '').replace(/^.{1}/, (c) => c.toUpperCase())}
                </div>
            ))}
        </div>
    );
};

const mapStateToProps = (state: IRootState): any => {
    return {
        result: getPasswordCheckResult(state)
    };
};

export default connect(mapStateToProps)(PasswordPolicy);
