import { UploadButton } from '@components';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getExternalPeriods, getIsFileUploading } from '@selectors/voice';
import voiceActions from '@actions/voice';
import { DEFAULT_FILE_UPLOAD_ERROR_MSG } from '@constants/messages';
import { Box, createStyles, Theme } from '@material-ui/core';
import { T } from '@components/T/T';
import makeStyles from '@material-ui/core/styles/makeStyles';

export interface IFileUploaderProps {
    magicLink: string;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        icon: {
            display: 'inline-block',
            mask: 'url(/assets/svg/inprogress.svg)',
            background: theme.palette.primary.dark,
            maskSize: 'contain',
            width: 50,
            height: 50,
            margin: 20,
            animation: 'rotation 1s infinite linear'
        },
        button: {
            '&:disabled': {
                border: '1px solid #bbb',
                color: theme.palette.primary.dark
            }
        }
    })
);

export const FileUploader: React.FC<IFileUploaderProps> = ({ magicLink, ...props }) => {
    const isFileUploading = useSelector(getIsFileUploading);
    const settlement = useSelector(getExternalPeriods);
    const dispatch = useDispatch();
    const classes = useStyles();

    const uploadCdrCallback = (file: File) => {
        dispatch(
            voiceActions.voiceSettlementUpload({
                file,
                backendFunctionName: 'parseCdrsFile',
                rowId: settlement && settlement.id,
                direction: 'partner_cdr_invoice',
                magicLink,
                shouldCompress: true,
                errorMessage: DEFAULT_FILE_UPLOAD_ERROR_MSG
            })
        );
    };

    return (
        <div {...props}>
            <Box mb={1}>
                <UploadButton
                    isDisabled={isFileUploading}
                    onFileSelect={uploadCdrCallback}
                    name={'originalPartnerCDRFileHash'}
                    accept={'.csv, .txt, .tsv'}
                    classes={['btn-secondary', classes.button].join(' ')}
                >
                    Upload CDR
                </UploadButton>
            </Box>
            <T variant="caption" display="block">
                * CSV or Excel file
            </T>
            {isFileUploading && <div className={classes.icon} />}
        </div>
    );
};
