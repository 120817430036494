import { Typography, TypographyProps, withTheme } from '@material-ui/core';
import styled from '@material-ui/core/styles/styled';
import React, { CSSProperties } from 'react';
import { FontColor, FontWeight } from '@components/theme';

// @ts-ignore
export interface ITProps extends TypographyProps {
    weight?: FontWeight;
    fontStyle?: CSSProperties['fontStyle'];
    color?: FontColor;
    lineHeight?: number;
}

export const T: React.FC<ITProps> = withTheme(
    styled(Typography)({
        fontWeight: (props: ITProps) => {
            return props.weight;
        },
        color: (props: ITProps): any => {
            return props.color;
        },
        fontStyle: (props: ITProps) => props.fontStyle,
        lineHeight: (props: ITProps) => props.lineHeight
    })
) as any;

T.defaultProps = {
    color: FontColor.B85,
    weight: FontWeight.Regular
};
