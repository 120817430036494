import React, { ComponentState } from 'react';

import { BaseInput } from '@components/Fields/BaseInput';

import './styles.scss';

interface IProps {
    className?: string;
    options: string[];
    chosen: string[];
    toggleOption(option: string | string[]): void;
}

interface IState {
    searchTerm: string;
    isAllSelected: boolean;
}
export class MultiSelectSection extends React.PureComponent<IProps, IState> {
    state = {
        searchTerm: '',
        isAllSelected: false
    };

    searchTermChange = (ev: React.ChangeEvent<HTMLInputElement>): ComponentState => this.setState({ searchTerm: ev.target.value });

    toggleOption = (option: string) => this.props.toggleOption(option);

    toggleAllOptions = (options: string[]) => () => {
        const { isAllSelected } = this.state;
        isAllSelected ? this.props.toggleOption([]) : this.props.toggleOption(options);
        this.setState({ isAllSelected: !isAllSelected });
    };

    render() {
        const { options, chosen } = this.props;
        const { searchTerm, isAllSelected } = this.state;

        if (!options || !options.length) {
            return null;
        }
        const filteredOptions = options
            ? searchTerm
                ? options.filter((option: string): boolean => option.toLowerCase().includes(searchTerm.toLocaleLowerCase()))
                : options
            : [];
        return (
            <div className="options-selection">
                <BaseInput
                    classes="options-search"
                    input={{
                        onChange: this.searchTermChange,
                        value: searchTerm,
                        name: 'partner'
                    }}
                    type="text"
                    placeholder="Search"
                />
                <div className="select-all-options" onClick={this.toggleAllOptions(filteredOptions)}>
                    {isAllSelected ? 'Unselect All' : 'Select All'}
                </div>
                <div className="options-list">
                    {filteredOptions.map(
                        (option: string, index: number): JSX.Element => (
                            <div
                                key={`${option}${index}`}
                                className={`checkbox${chosen.includes(option) ? ' checked' : ''}`}
                                onClick={this.toggleOption.bind(this, option)}
                            >
                                <i />
                                <span>{option}</span>
                            </div>
                        )
                    )}
                </div>
            </div>
        );
    }
}
