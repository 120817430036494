import React, { FunctionComponent } from 'react';
import { connect } from 'react-redux';
import { IRootState } from '@reducers';
import { getPasswordCheckResult } from '@selectors/form';
import './styles.scss';
import classNames from 'classnames';
import range from 'lodash/range';

export interface IPasswordStrengthMeterProps extends Partial<ReturnType<typeof mapStateToProps>> {}

const MAX_STRENGTH = 4;

const PasswordStrengthMeter: FunctionComponent<IPasswordStrengthMeterProps> = (props) => {
    const { strength } = props;
    return (
        <div className="password-strength-meter">
            <span>Password Strength</span>
            <div className="meter">
                {range(MAX_STRENGTH).map((i) => {
                    return (
                        <div
                            key={i}
                            className={classNames('step', {
                                complete: strength! - 1 >= i
                            })}
                        />
                    );
                })}
            </div>
        </div>
    );
};

const mapStateToProps = (state: IRootState) => {
    return {
        strength: getPasswordCheckResult(state).strength
    };
};

export default connect(mapStateToProps)(PasswordStrengthMeter);
