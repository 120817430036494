import React from 'react';
import './styles.scss';
import { SubHeader } from '@components';
import { UserCreation } from '@components/UserManagement/UserCreation';

export const ManageUsers: React.FC = () => {
    return (
        <>
            <SubHeader title="Manage Users" />
            <UserCreation />
        </>
    );
};
