import React from 'react';
import { Summary } from '@modules/Roaming/components/SettlementDashboard/Summary';
import Box from '@material-ui/core/Box';
import { Helmet } from 'react-helmet';
import { SettlementKanban } from '@modules/Roaming/components/SettlementDashboard/SettlementKanban/SettlementKanban';

export const SettlementDashboard: React.FC = () => {
    return (
        <>
            <Helmet title="Settlement Dashboard" />
            <Box px={6} maxWidth={1366} mt={9} mb={9} mx="auto">
                <Summary />
            </Box>
            <Box px={6} maxWidth={1366} mx="auto">
                <SettlementKanban />
            </Box>
        </>
    );
};
