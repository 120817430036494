import * as React from 'react';
import * as ReactDOM from 'react-dom';
import delay from 'lodash/delay';
import classNames from 'classnames';

import { DrawerContent } from './DrawerContent';

import './styles.scss';

interface IDrawerProps {
    closeDrawer: () => void;
    classList?: string;
}

interface IDrawerState {
    willClose: boolean;
}

export class Drawer extends React.PureComponent<IDrawerProps, IDrawerState> {
    state = {
        willClose: false
    };

    componentDidMount() {
        document.addEventListener('keydown', this.handleEscapeKeydown);
        document.body.classList.add('modal');
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.handleEscapeKeydown);
        document.body.classList.remove('modal');
    }

    closeDrawer = () => {
        this.setState({ willClose: true });
        delay(this.props.closeDrawer, 650);
    };

    handleEscapeKeydown = ({ keyCode }: KeyboardEvent) => keyCode === 27 && this.closeDrawer();

    render() {
        const { children, classList } = this.props;

        const drawerClasses = classNames('drawer ' + classList, {
            'will-close': this.state.willClose
        });

        return ReactDOM.createPortal(
            <div className={drawerClasses}>
                <div className="backdrop">
                    <button className="close" />
                    <DrawerContent content={children} hideDrawer={this.closeDrawer} classList={classList} />
                </div>
            </div>,
            document.getElementById('drawerContainer') as Element
        );
    }
}
