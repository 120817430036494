import { cdrType } from '@actions/cdr';
import { IAppAction } from './state';

export interface ICdrDataForAllFilters {
    id: string;
    ourDuration: number;
    theirDuration: number;
    ourAmount: number;
    theirAmount: number;
    amountDiff: number;
}

export interface ICdrDataFilteredByDestination extends ICdrDataForAllFilters {
    durationDiff: number;
    ourCalls: number;
    theirCalls: number;
    callsDiff: number;
    destination: string;
    minCallStart: string;
    maxCallStart: string;
}
export interface ICdrDataFilteredByDate extends ICdrDataForAllFilters {
    date: string;
    ourCalls: number;
    theirCalls: number;
    ourRate: number;
    theirRate: number;
}
export interface ICdrDataFilteredByOther extends ICdrDataForAllFilters {
    date: string;
    ourCalls: number;
    theirCalls: number;
    destination: string;
    prefix: string;
    product: string;
    paymentDirection: string;
    ourRate: number;
    theirRate: number;
    pop: string;
}

export interface ICdrDataFilteredByCdr extends ICdrDataForAllFilters {
    destination: string;
    prefix: string;
    product: string;
    paymentDirection: string;
    ourRate: number;
    theirRate: number;
    pop: string;
    ourCallStart: string;
    theirCallStart: string;
    caller: string;
    receiver: string;
    discrepancies: string[];
}

export interface ICdrDataWithFilter {
    byDestination: ICdrDataFilteredByDestination[];
    byPrefix: ICdrDataFilteredByOther[];
    byDate: ICdrDataFilteredByDate[];
    byProduct: ICdrDataFilteredByOther[];
    byCdr: ICdrDataFilteredByCdr[];
}

export interface ICdrStructure {
    filterBy: 'byDestination' | 'byPrefix' | 'byProduct' | 'byDate' | 'byCDR';
    data: ICdrDataWithFilter;
}

export interface IStateCdr {
    loading: boolean;
    cdr: ICdrStructure;
}

const initialState: IStateCdr = {
    loading: false,
    cdr: {
        filterBy: 'byDestination',
        data: {
            byDestination: [],
            byPrefix: [],
            byDate: [],
            byProduct: [],
            byCdr: []
        }
    }
};

export function cdrReducer(state: IStateCdr = initialState, action: IAppAction): IStateCdr {
    switch (action.type) {
        case cdrType.CDRS_BY_CDR_GET_REQUEST:
        case cdrType.CDRS_BY_DATE_GET_REQUEST:
        case cdrType.CDRS_BY_DESTINATION_GET_REQUEST:
        case cdrType.CDRS_BY_PREFIX_GET_REQUEST:
        case cdrType.CDRS_BY_PRODUCT_GET_REQUEST:
            return { ...state, loading: true };
        case cdrType.CDRS_BY_DESTINATION_GET_SUCCESS:
            return {
                ...state,
                cdr: {
                    filterBy: 'byDestination',
                    data: { ...initialState.cdr.data, byDestination: action.payload }
                }
            };
        case cdrType.CDRS_BY_PREFIX_GET_SUCCESS:
            return {
                ...state,
                cdr: {
                    filterBy: 'byPrefix',
                    data: { ...initialState.cdr.data, byPrefix: action.payload }
                }
            };
        case cdrType.CDRS_BY_PRODUCT_GET_SUCCESS:
            return {
                ...state,
                cdr: {
                    filterBy: 'byProduct',
                    data: { ...initialState.cdr.data, byProduct: action.payload }
                }
            };
        case cdrType.CDRS_BY_DATE_GET_SUCCESS:
            return {
                ...state,
                cdr: {
                    filterBy: 'byDate',
                    data: { ...initialState.cdr.data, byDate: action.payload }
                }
            };
        case cdrType.CDRS_BY_CDR_GET_SUCCESS:
            return {
                ...state,
                cdr: {
                    filterBy: 'byCDR',
                    data: { ...initialState.cdr.data, byCdr: action.payload }
                }
            };
        case cdrType.CDRS_BY_CDR_GET_FINISHED:
        case cdrType.CDRS_BY_DATE_GET_FINISHED:
        case cdrType.CDRS_BY_DESTINATION_GET_FINISHED:
        case cdrType.CDRS_BY_PREFIX_GET_FINISHED:
        case cdrType.CDRS_BY_PRODUCT_GET_FINISHED:
            return { ...state, loading: false };
        default:
            return state;
    }
}
