import React from 'react';

import xor from 'lodash/xor';

import { getCurrencySymbol } from '@constants/index';

import { MultiSelectSection } from './MultiSelectSection';
import { Drawer, ExpansionPanel } from '@components';
import { DateRange } from './DateRange';
import { NumberInput, INumericFilterValue, OperationType } from './NumberInput';
import { MultiSelect } from './MultiSelect';

import './styles.scss';
import { StringInput, IStringFilterValue } from './StringInput';
import omit from 'lodash/omit';
import { formatBalance, formatPeriod } from '@helpers/formaters';

export enum FilterItemType {
    Select = 'select',
    SearchSelect = 'searchSelect',
    Date = 'date',
    Number = 'number',
    String = 'string'
}
export enum FilterItemSubtype {
    Money = 'money',
    Single = 'single',
    DateTime = 'datetime'
}
export interface IFilterItem {
    type: FilterItemType;
    subType?: FilterItemSubtype;
    title: string;
    key: string;
    options?: string[];
    data?: any;
}

interface IProps {
    isOpen: boolean;
    items: IFilterItem[];
    values: { [key: string]: any };
    currency: string;
    onChange(values: any): void;
    onApply(filters: any): void;
    close(): void;
}

const MAX_SUMMARY_LENGTH = 10;
export class FiltersPane extends React.PureComponent<IProps> {
    clearFilters = () => {
        this.applyFilters(true);
    };

    getFiltersAndSummary = (values: any): [{ [key: string]: any }, { [key: string]: any }] => {
        const filters: { [key: string]: any } = {};
        const summary: { [key: string]: any } = {};
        const truncate = (val: string) => (val.length <= MAX_SUMMARY_LENGTH && val) || `${val.substr(0, MAX_SUMMARY_LENGTH - 1)}..`;
        this.props.items.forEach((item) => {
            const { key, type, subType, options, data } = item;
            const value = values[key];
            if (!value) {
                return;
            }
            let valueToAssign = null;
            let summaryVal = null;
            switch (type) {
                case FilterItemType.SearchSelect:
                case FilterItemType.Select:
                    if (value && value.length) {
                        valueToAssign = value;
                        const formatter = data && data.formatter;
                        if (formatter) {
                            valueToAssign = valueToAssign.map(
                                (str: string) => (options as string[]).find((option) => formatter(option) === str) || str
                            );
                        }
                        summaryVal = truncate(valueToAssign.map(truncate).join(','));
                        if (subType === FilterItemSubtype.Single) {
                            valueToAssign = valueToAssign[0];
                            summaryVal = truncate(valueToAssign);
                        }
                    }
                    break;
                case FilterItemType.Number:
                    valueToAssign = this.processNumericFilter(value);
                    if (valueToAssign) {
                        summaryVal = this.processNumericFilter(value, item);
                    }
                    break;
                case FilterItemType.Date:
                    const { startDate, endDate } = value;
                    if (startDate && endDate && endDate >= startDate) {
                        valueToAssign = [+startDate / 1000, +endDate / 1000];
                        summaryVal = truncate(formatPeriod(...(valueToAssign as [number, number])));
                    }
                    break;
                case FilterItemType.String:
                    const { outputValue } = value as IStringFilterValue;
                    if (outputValue) {
                        valueToAssign = outputValue;
                        summaryVal = valueToAssign.replace(/%/g, '..');
                    }
                    break;
            }
            if (valueToAssign !== null) {
                filters[key] = valueToAssign;
                summary[key] = summaryVal;
            }
        });
        return [filters, summary];
    };
    applyFilters = (shouldClear: boolean, keyToClear: string = '') => {
        const { onChange, onApply, close } = this.props;
        let values = shouldClear ? this.getInitialValues() : this.getValues();
        if (keyToClear) {
            values = omit(values, keyToClear);
            delete values.outputFilters[keyToClear];
            delete values.summary[keyToClear];
        }
        const { outputFilters, summary } = values;
        const cleared = Object.keys(outputFilters).length === 0;
        onChange({
            ...values,
            cleared,
            canApply: !cleared,
            appliedSummary: summary
        });
        onApply(outputFilters);
        close();
    };

    onSelectChange = (key: string, option: string) => {
        const chosen = this.getValues()[key] || [];
        const item = this.props.items.find(({ key: itemKey }) => itemKey === key) as IFilterItem;
        const valueToSet = item.subType === FilterItemSubtype.Single ? (chosen[0] === option ? [] : [option]) : xor(chosen, [option]);
        this.setValue(key, valueToSet);
    };

    getInitialValues = () => ({
        cleared: true,
        outputFilters: {},
        summary: {}
    });
    getValues = () => this.props.values || this.getInitialValues();
    setExternalValues = (values: { [key: string]: any }, currencySymbol: string) => {
        const newValues: typeof values = {};
        this.props.items.forEach(({ key, type, subType }) => {
            const val = values[key];
            if (val !== undefined) {
                switch (type) {
                    case FilterItemType.Date:
                        const [from, to] = val;
                        newValues[key] = DateRange.reverseEngineerValue(from, to, subType !== FilterItemSubtype.DateTime);
                        break;
                    case FilterItemType.SearchSelect:
                    case FilterItemType.Select:
                        newValues[key] = Array.isArray(val) ? val : [val];
                        break;
                    case FilterItemType.Number:
                        const value: INumericFilterValue = Array.isArray(val)
                            ? {
                                  type: OperationType.Between,
                                  value1: val[0],
                                  value2: val[1]
                              }
                            : { type: val.op, value1: val.value };
                        newValues[key] = NumberInput.reverseEngineerValue(value, subType === FilterItemSubtype.Money ? currencySymbol : undefined);
                        break;
                    default:
                        newValues[key] = val;
                }
            }
        });
        this.setNewValues(newValues);
        setTimeout(() => this.applyFilters(false), 10); // TODO - find a better way
    };
    setValue = (key: string, value: any) => {
        const values = this.getValues();
        const newValues = { ...values, [key]: value };
        this.setNewValues(newValues);
    };
    setNewValues = (newValues: { [key: string]: any }, oldValues?: { [key: string]: any }) => {
        [newValues.outputFilters, newValues.summary] = this.getFiltersAndSummary(newValues);
        const isEmpty = Object.keys(newValues.outputFilters).length === 0;
        newValues.canApply = !isEmpty || (oldValues && !oldValues.cleared);
        this.props.onChange(newValues);
        return newValues;
    };

    formatNumber = (n: number, { subType }: IFilterItem) =>
        formatBalance(n, 2, {
            currency: subType === FilterItemSubtype.Money ? getCurrencySymbol(this.props.currency) : undefined,
            roundToInteger: true
        });

    processNumericFilter = ({ type: op, value1, value2 }: INumericFilterValue, itemForSummary?: IFilterItem) => {
        if (isNaN(value1 as number)) {
            return null;
        }
        if (op.toString() === 'range') {
            if (isNaN(value2 as number) || (value1 as number) > (value2 as number)) {
                return null;
            }
            return itemForSummary ? `${this.formatNumber(value1!, itemForSummary)}..${this.formatNumber(value2!, itemForSummary)}` : [value1, value2];
        }
        return itemForSummary
            ? `${op === OperationType.GreaterThan ? '>' : '<'} ${this.formatNumber(value1!, itemForSummary)}`
            : { op, value: value1 };
    };

    onItemToggle = (key: string) => {
        const KEY = 'openItemKey';
        const values = this.props.values || {};
        // Set or clear the open item key
        this.setValue(KEY, values[KEY] === key ? null : key);
    };

    renderItem = (item: IFilterItem, currencySymbol: string) => {
        const { key, type, subType, options, data } = item;
        const value = this.getValues()[key];
        let SelectClass: typeof MultiSelect | typeof MultiSelectSection = MultiSelect;
        switch (type) {
            case FilterItemType.Date:
                return (
                    <DateRange
                        onChange={this.setValue.bind(this, key)}
                        value={value || data || {}}
                        dateOnly={subType !== FilterItemSubtype.DateTime}
                    />
                );
            case FilterItemType.Number:
                return (
                    <NumberInput
                        currencySymbol={subType === FilterItemSubtype.Money ? currencySymbol : ''}
                        onChange={this.setValue.bind(this, key)}
                        value={value || {}}
                    />
                );
            case FilterItemType.String:
                return <StringInput value={value || {}} onChange={this.setValue.bind(this, key)} />;
            case FilterItemType.SearchSelect:
                SelectClass = MultiSelectSection;
            case FilterItemType.Select:
                const formatter = data && data.formatter;
                return (
                    <SelectClass
                        options={formatter ? (options as string[]).map(formatter) : (options as string[])}
                        chosen={(value || []) as string[]}
                        toggleOption={this.onSelectChange.bind(this, key)}
                    />
                );
            default:
                return null;
        }
    };
    renderItemStatus = ({ type, key }: IFilterItem) => {
        const value = this.getValues()[key];
        let gotValue = false;
        if (value) {
            switch (type) {
                case FilterItemType.SearchSelect:
                    const nChosen = value.length;
                    return nChosen ? <span className="chip">{nChosen}</span> : null;
                case FilterItemType.Select:
                    gotValue = !!value.length;
                    break;
                case FilterItemType.Date:
                    gotValue = !!value.startDate && !!value.endDate && value.endDate >= value.startDate;
                    break;
                case FilterItemType.Number:
                    gotValue = !!this.processNumericFilter(value);
                    break;
                case FilterItemType.String:
                    gotValue = !!(value as IStringFilterValue).outputValue;
                    break;
                default:
                    return value && value.isValid ? <span className="filter-got-value" /> : null;
            }
        }
        return gotValue ? <span className="filter-got-value" /> : null;
    };

    // Consumed by ActiveFilters component
    getSummary = (values?: IProps['values']) => (values || this.getValues()).appliedSummary || {};
    clearFilter = (key: string) => this.applyFilters(false, key);
    //

    render() {
        const { isOpen, close, currency, items, values } = this.props;
        const currencySymbol = getCurrencySymbol(currency || '');

        if (!isOpen) {
            return null;
        }

        const { openItemKey, canApply } = (values || {}) as any;

        return (
            <Drawer closeDrawer={close} classList="filters-drawer">
                <div className="filters-title">Add Filters</div>
                <div className={'filters-pane' + (canApply ? ' showing-actions' : '')}>
                    {// Filter empty select items
                    items
                        .filter(({ options }) => !options || options.length)
                        .map((item) => (
                            <ExpansionPanel key={item.key} isOpen={item.key === openItemKey} onToggle={this.onItemToggle.bind(this, item.key)}>
                                <div className="item-header">
                                    {item.title}
                                    {this.renderItemStatus(item)}
                                </div>
                                <div className="item-body">{this.renderItem(item, currencySymbol)}</div>
                            </ExpansionPanel>
                        ))}
                </div>
                {canApply && (
                    <div className="form-actions">
                        <button className="reset" onClick={this.clearFilters}>
                            Reset Filters
                        </button>
                        <button className="apply" onClick={this.applyFilters.bind(this, false, undefined)}>
                            Apply Filters
                        </button>
                    </div>
                )}
            </Drawer>
        );
    }
}
