import { createRequestAction } from '@helpers/actionCreator';

export enum accountType {
    ACCOUNT_GET_INFO_REQUEST = 'ACCOUNT:GET:INFO::REQUEST',
    ACCOUNT_GET_INFO_SUCCESS = 'ACCOUNT:GET:INFO::REQUEST:SUCCESS',
    ACCOUNT_GET_INFO_FAIL = 'ACCOUNT:GET:INFO::REQUEST:FAIL',

    ACCOUNT_GET_PEERNAME_REQUEST = 'ACCOUNT:GET:PEERNAME::REQUEST',
    ACCOUNT_GET_PEERNAME_SUCCESS = 'ACCOUNT:GET:PEERNAME::REQUEST:SUCCESS',
    ACCOUNT_GET_PEERNAME_FAIL = 'ACCOUNT:GET:PEERNAME::REQUEST:FAIL',

    ACCOUNT_GET_USERNAME_REQUEST = 'ACCOUNT:GET:USERNAME::REQUEST',
    ACCOUNT_GET_USERNAME_SUCCESS = 'ACCOUNT:GET:USERNAME::REQUEST:SUCCESS',
    ACCOUNT_GET_USERNAME_FAIL = 'ACCOUNT:GET:USERNAME::REQUEST:FAIL'
}

export const accountActions = {
    getInfoRequest: createRequestAction(accountType.ACCOUNT_GET_INFO_REQUEST, 'getMyParams'),

    getPeername: createRequestAction(accountType.ACCOUNT_GET_PEERNAME_REQUEST, 'getMyPeerName'),

    getUsername: createRequestAction(accountType.ACCOUNT_GET_USERNAME_REQUEST, 'getMyUsername')
};

export default accountActions;
