import { notificationType, IStateNotifications } from '@actions/notification';
import { IAppAction } from './state';
import unionBy from 'lodash/unionBy';

const initialState: IStateNotifications = [];

export function notificationsReducer(state = initialState, action: IAppAction): IStateNotifications {
    switch (action.type) {
        case notificationType.NOTIFICATION_GET_ALL_SUCCESS:
            return [...state, ...action.payload];
        case notificationType.NOTIFICATION_UPDATE_RECEIVED:
            return [...unionBy(action.payload.objs, state, 'id')];
        default:
            return state;
    }
}
