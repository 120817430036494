import React, { useCallback, useState } from 'react';
import { Dropdown } from 'antd';
import MenuIcon from '@ant-design/icons/EllipsisOutlined';
import { TableOptionsMenu } from '@components/ClearTable/TableOptionsMenu';

export const TableOptions: React.FC = () => {
    const [isVisible, setVisible] = useState(false);
    const close = useCallback(() => setVisible(false), []);
    return (
        <Dropdown onVisibleChange={setVisible} visible={isVisible} overlay={<TableOptionsMenu close={close} />} trigger={['click']}>
            <MenuIcon style={{ fontSize: 20 }} data-t={'table-menu'} />
        </Dropdown>
    );
};
