import * as React from 'react';
import classNames from 'classnames';

import { ColumnPresenter } from './ColumnPresenter';
import { Sort } from './Sort';

import { ITableColumnProps } from './index';

import './styles.scss';

interface ITHeadProps {
    orderedColumn: string;
    isAscending: boolean;
    orderHandler: (columnKey: string) => React.ComponentState;
    cols: ITableColumnProps[];
    allCols: ITableColumnProps[];
    onShowColumnToggle: (shownColumn: any) => void;
    shownColumn?: { [index: string]: boolean };
    notSortable?: boolean;
}

export const THead: React.StatelessComponent<ITHeadProps> = ({
    cols,
    orderedColumn,
    orderHandler,
    isAscending,
    notSortable,
    allCols,
    onShowColumnToggle,
    shownColumn
}): JSX.Element => (
    <div className={classNames('thead')}>
        <div className="tr">
            {cols.map(({ key, title, classNameCell, alignRight, notSortable: notSortableColumn }) => {
                classNameCell = classNameCell || key;
                if (alignRight) {
                    classNameCell += ' align-right';
                }
                if (cols.length < 5) {
                    classNameCell += ' wide-column';
                }

                const isSortable = !notSortable && !notSortableColumn;
                return (
                    <div key={key} className={classNames('th', classNameCell)} onClick={isSortable ? () => orderHandler(key) : undefined}>
                        <div className="col-title">
                            <span className={`col-label ${isSortable && 'sortable-column'}`}>{title}</span>
                            {isSortable && (
                                <Sort isOrdered={orderedColumn === key} isAscending={orderedColumn === key && isAscending} columnKey={key} />
                            )}
                        </div>
                    </div>
                );
            })}
            <div className="th options">
                <ColumnPresenter cols={allCols} onShowColumnToggle={onShowColumnToggle} shownColumn={shownColumn} />
            </div>
        </div>
    </div>
);
