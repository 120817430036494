import { useDispatch, useSelector } from 'react-redux';
import { getMyPeers } from '@selectors/peers';
import { Box } from '@material-ui/core';
import { Badge } from 'antd';
import React, { useMemo } from 'react';
import { MainWrapper } from '@components/Partneships/partnershipsCommon';
import { PageWrapper } from '@components/PageWrapper/PageWrapper';
import { PARTNERSHIP_STATUS } from '@clearblockchain/shared/partnership';
import agreementsActions from '@actions/agreements';
import { getPartnerships } from '@selectors/roaming';
import { IPartnerships } from '@reducers/agreements';
import { PresetStatusColorType } from 'antd/lib/_util/colors';
import { ClearTable, IColumn, IFetcher } from '@components/ClearTable/ClearTable';
import { IPeer } from '@actions';
import { useHistory } from 'react-router';
import { SubHeader } from '@components';
import { T } from '@components/T/T';
import { Helmet } from 'react-helmet';
import { FontWeight } from '@components/theme';

const renderStatusComponent = (value: PARTNERSHIP_STATUS): React.ReactElement => {
    let status;
    let text;
    switch (value) {
        case PARTNERSHIP_STATUS.PENDING_YOUR_INITIAL_APPROVAL: {
            text = 'Partnership requires your approval';
            status = 'warning';
            break;
        }
        case PARTNERSHIP_STATUS.PENDING_YOUR_APPROVAL: {
            text = 'Pending changes require your approval';
            status = 'warning';
            break;
        }
        case PARTNERSHIP_STATUS.PENDING_INITIAL_APPROVAL: {
            text = "Partnership requires partner's approval";
            status = 'warning';
            break;
        }
        case PARTNERSHIP_STATUS.PENDING_APPROVAL: {
            text = "Pending changes require partner's approval";
            status = 'warning';
            break;
        }
        case PARTNERSHIP_STATUS.APPROVED: {
            status = 'success';
            text = 'Approved';
            break;
        }
        case PARTNERSHIP_STATUS.DELETED: {
            status = 'error';
            text = 'Rejected';
            break;
        }
        default: {
            status = 'error';
            text = 'Partnership has not been set up yet, click here to set it up';
            break;
        }
    }
    return <Badge data-t="peer-partnership-status" status={status as PresetStatusColorType} text={text} />;
};

const getColumns = (): Array<IColumn<any>> => [
    {
        title: 'Partner',
        dataIndex: 'name',
        width: 400,
        render(name) {
            return <T data-t="name">{name}</T>;
        }
    },
    {
        title: 'Status',
        dataIndex: 'status',
        render: renderStatusComponent
    }
];

export const Partnerships = () => {
    const history = useHistory();
    const peers = useSelector(getMyPeers);
    const dispatch = useDispatch();
    const { allPartnerships, isLoading } = useSelector(getPartnerships) as IPartnerships;

    const partners = useMemo(() => {
        if (!allPartnerships) {
            return peers;
        }
        return peers.map((peer) => {
            const peersPartnership = allPartnerships.find((partnership) => partnership.partnerId === peer.id);
            const status = peersPartnership ? peersPartnership.status : null;
            return { ...peer, status };
        });
    }, [peers, allPartnerships]);

    const onRowClick = (row: IPeer): void => {
        history.push(`${location.pathname}/${row.id}`);
    };

    const fetch: IFetcher = (): void => {
        dispatch(agreementsActions.queryAllPartnerships());
    };

    return (
        <PageWrapper>
            <Helmet title="Partners" />
            <SubHeader title="Partners" />
            <MainWrapper>
                <Box display={'flex'} justifyContent={'center'}>
                    <Box width={'1000px'}>
                        <ClearTable
                            tableTestName="partners"
                            isLoading={isLoading}
                            fetch={fetch}
                            total={partners.length}
                            localStorageKey="partnership-table"
                            onRowClick={onRowClick}
                            entityName="Partnerships"
                            getColumns={getColumns}
                            items={partners}
                            width={1000}
                        />
                    </Box>
                </Box>
            </MainWrapper>
        </PageWrapper>
    );
};
