import map from 'lodash/map';
type mappedDataObject = Record<string, any>;

export const arrayOfArgsOrSingleArg = <T1 extends {}>(arr: T1 | T1[]) => (Array.isArray(arr) ? arr[0] : arr);

export const callbackForArrayOrSingleArg = (arg: any, callback: any) => (Array.isArray(arg) ? arg.map((el) => callback(el)) : callback(arg));

export const withKeyObject = <T extends mappedDataObject>(data: T[], keyProps: string): T[] =>
    map(data, (item: T) => ({ ...item, key: item[keyProps] }));

export const getDiffValuesBetweenArrays = (arrayToExludeFrom: any[], exludedArray: any[]): any[] => {
    return arrayToExludeFrom.filter((obj) => {
        return !exludedArray.some((obj2) => {
            return obj.id === obj2.id;
        });
    });
};
