import * as React from 'react';
import classNames from 'classnames';

interface IOptionData {
    value?: string;
    title?: string;
    isSelected?: boolean;
    isDisabled?: boolean;
    handleOptionClick?: any;
}

export const Option = ({ value, title, isSelected, handleOptionClick, isDisabled }: IOptionData): JSX.Element => (
    <span
        className={classNames('item', {
            'is-selected': isSelected,
            'is-disabled': isDisabled
        })}
        // tslint:disable-next-line jsx-no-lambda
        onClick={() => handleOptionClick(value)}
    >
        {title}
    </span>
);
