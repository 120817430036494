import { createSelector } from 'reselect';
import moment from 'moment';
import { formatBalance } from '@helpers/formaters';
import { ITransactionsItemReducer, ITransactionConvertation, ITransactionBasket } from '@actions/transactions';
import { IRootState } from '@reducers/index';
import { longDateFormat, longDateFormatTimeFirst } from '@constants/index';

import get from 'lodash/get';
import { ITransactionsReducer } from '@reducers/transactions';

const transactionsSelector = (state: IRootState): ITransactionsReducer => state.transactions;
const transactionsItem = (state: IRootState): ITransactionsItemReducer[] => state.transactions.items;

export const isTransactionProcessing = createSelector(
    transactionsSelector,
    (transactions: ITransactionsReducer): boolean => transactions.isTransactionProcessing
);

export const isTransactionWithdrawProcessing = createSelector(
    transactionsSelector,
    (transactions: ITransactionsReducer): boolean => transactions.isTransactionWithdrawProcessing
);

export const isTransactionHasLoaded = createSelector(transactionsSelector, (transactions: ITransactionsReducer): boolean => transactions.hasLoaded);

export interface IItemsSelector extends ITransactionsItemReducer {
    parsedAmount: any;
    fullDate: string;
    key: string;
}

const transactionsItemsSelector = createSelector(transactionsItem, (items: ITransactionsItemReducer[]): IItemsSelector[] => {
    const notSortedItems = items.map((transaction: ITransactionsItemReducer) => {
        const { amount, digitalAmount, date, currency, digitalCurrency, basket, statusTimeline } = transaction;
        const amountStr = amount || digitalAmount;
        return {
            ...transaction,
            parsedAmount: isString(amountStr) ? formatBalance(amountStr) : 'error',
            date: formatDate(date, longDateFormatTimeFirst),
            fullDate: date,
            currency: currency || digitalCurrency,
            currentBalance: formatBalance(transaction.currentBalance || transaction.usdPurchased),
            otherParty: transaction.otherParty,
            status: transaction.status,
            id: transaction.id,
            dstCurrency: transaction.dstCurrency,
            statusTimeline: Object.keys(statusTimeline).reduce((acc: { [key: string]: string }, key) => {
                acc[key as string] = formatDate(statusTimeline[key]) as string;
                return acc;
            }, {}),
            key: transaction.id,
            basket: {
                eur: formatBalance(basket && basket.eur),
                gbp: formatBalance(basket && basket.gbp),
                jpy: formatBalance(basket && basket.jpy),
                rmb: formatBalance(basket && basket.rmb),
                usd: formatBalance(basket && basket.usd),
                xdr: formatBalance((basket && basket.xdr) || '0'),
                net: formatBalance((basket && basket.net) || '0'),
                fee: formatBalance((basket && basket.fee) || '0'),
                total: formatBalance((basket && basket.total) || '0')
            }
        };
    });

    const sortItems = notSortedItems.sort((i1, i2) => {
        if (moment(i1.fullDate).valueOf() >= moment(i2.fullDate).valueOf()) {
            return -1;
        }

        return 1;
    }); // TODO looks like it is unnecessary, because we do other sort it table component

    return sortItems;
});

const isString = (value: any): boolean => typeof value === 'string' || value instanceof String;

const formatDate = (value: string | undefined, format: string = longDateFormat): string | undefined => {
    if (!value) {
        return value;
    }

    return moment(value).format(format);
};

export const getTransactions = createSelector(
    [transactionsSelector, transactionsItemsSelector],
    (transactionsState: ITransactionsReducer, transactionsItems: any[]): ITransactionsReducer => {
        return { ...transactionsState, items: transactionsItems };
    }
);

export interface IBasketValues extends ITransactionBasket {
    formatedRmb: string;
    formatedEur: string;
    formatedGbp: string;
    formatedJpy: string;
    formatedUsd: string;
}

export const getBasketValues = createSelector(
    [transactionsSelector],
    (transactionsBasket: ITransactionsReducer): IBasketValues => {
        return {
            formatedRmb: formatBalance(transactionsBasket.basket && transactionsBasket.basket.rmb),
            formatedEur: formatBalance(transactionsBasket.basket && transactionsBasket.basket.eur),
            formatedGbp: formatBalance(transactionsBasket.basket && transactionsBasket.basket.gbp),
            formatedJpy: formatBalance(transactionsBasket.basket && transactionsBasket.basket.jpy),
            formatedUsd: formatBalance(transactionsBasket.basket && transactionsBasket.basket.usd),
            rmb: transactionsBasket.basket && transactionsBasket.basket.rmb,
            eur: transactionsBasket.basket && transactionsBasket.basket.eur,
            gbp: transactionsBasket.basket && transactionsBasket.basket.gbp,
            jpy: transactionsBasket.basket && transactionsBasket.basket.jpy,
            usd: transactionsBasket.basket && transactionsBasket.basket.usd
        };
    }
);

export interface ISDRValuesConvertation extends ITransactionConvertation {
    amountFormated: string;
    netFormated: string;
    feeFormated: string;
}

export const getConvertationSDRValues = createSelector([transactionsSelector], (transactions: ITransactionsReducer): ISDRValuesConvertation | {} => {
    const conv = transactions.convertationCurrency;
    if (conv) {
        return {
            amount: formatBalance(conv.amount, 15),
            amountFormated: formatBalance(conv.amount, 2),
            rmb: formatBalance(conv.rmb, 15),
            currency: conv.currency,
            eur: formatBalance(conv.eur, 15),
            fee: formatBalance(conv.fee, 15),
            feeFormated: formatBalance(conv.fee, 2),
            gbp: formatBalance(conv.gbp, 15),
            jpy: formatBalance(conv.jpy, 15),
            net: formatBalance(conv.net, 15),
            netFormated: formatBalance(conv.net, 2),
            usd: formatBalance(conv.usd, 15),
            feePercent: conv.feePercent,
            backConvertRate: get(transactions, 'convertationSDR.amount')
        };
    }
    return {};
});

export const getDistributorWithdrawalValues = createSelector([transactionsSelector], (transactions: ITransactionsReducer):
    | ISDRValuesConvertation
    | {} => {
    const conv = transactions.convertationSDR;
    if (conv) {
        return {
            amount: formatBalance(conv.amount, 15),
            amountFormated: formatBalance(conv.amount, 2),
            rmb: formatBalance(conv.rmb, 15),
            currency: conv.currency,
            eur: formatBalance(conv.eur, 15),
            fee: formatBalance(conv.fee, 15),
            feeFormated: formatBalance(conv.fee, 2),
            gbp: formatBalance(conv.gbp, 15),
            jpy: formatBalance(conv.jpy, 15),
            net: formatBalance(conv.net, 15),
            netFormated: formatBalance(conv.net, 2),
            usd: formatBalance(conv.usd, 15),
            feePercent: conv.feePercent,
            backConvertRate: get(transactions, 'convertationSDR.amount')
        };
    }
    return {};
});

export const getConvertationCurrencyValues = createSelector(transactionsSelector, (transactions: ITransactionsReducer):
    | ITransactionConvertation
    | {} => {
    const conv = transactions.convertationCurrency;
    if (conv) {
        return {
            amount: formatBalance(conv.amount, 15),
            rmb: formatBalance(conv.rmb, 15),
            currency: conv.currency,
            eur: formatBalance(conv.eur, 15),
            fee: formatBalance(conv.fee, 15),
            gbp: formatBalance(conv.gbp, 15),
            jpy: formatBalance(conv.amount, 15),
            net: formatBalance(conv.net, 15),
            usd: formatBalance(conv.usd, 15),
            feePercent: conv.feePercent
        };
    }
    return {};
});
export const getIncoices = createSelector(transactionsSelector, ({ invoices }: ITransactionsReducer) => invoices);
export const getInvoiceUpdateCount = createSelector(transactionsSelector, ({ invoiceUpdateCount }: ITransactionsReducer) => invoiceUpdateCount);
