const voice = '/dashboard/voice/';
const settlements = voice + 'settlements';
const agreements = voice + 'agreements';
const list = '/list/:id';
export const voicePaths = {
    settlements,
    settlementsList: settlements + list,
    settlementsOverview: settlements + '/overview/:id',
    agreements,
    agreementsList: agreements + list,
    agreement: agreements + '/:id'
};
export const setPathId = (path: string, id: string) => path.replace(':id', id);
