import * as React from 'react';
import DatePicker from 'react-datepicker';

import '!style-loader!css-loader!react-datepicker/dist/react-datepicker.css';
import './styles.scss';

interface IFieldProps {
    input: any;
    meta: any;
    label?: string;
    dateFormat?: string;
    labelSeperate: boolean;
}

export const DateField = ({ input, label, dateFormat, labelSeperate = false }: IFieldProps): JSX.Element => (
    <div className={`field field-date field-${input.name}`}>
        {labelSeperate && <label htmlFor={input.name}>{label}</label>}
        <DatePicker
            onChange={input.onChange}
            selected={input.value || null}
            dateFormat={dateFormat || 'MMM d'}
            className="date-control"
            placeholderText={labelSeperate ? undefined : label}
            popperPlacement="bottom"
            shouldCloseOnSelect={true}
        />
    </div>
);
