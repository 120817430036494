import { ratesType } from '@actions/RatesSheetParser/rates';
import { IAppAction } from '../state';

export interface IRatesServerDataByCompany {
    is_routing: boolean;
    product: string;
    destination: string;
    prefixes: string[];
    rate: string;
    currency: string;
    rounding_rules: number[];
    valid_from: string;
    valid_until: string;
    upload_time: string;
    origin: string;
    most_relevant: boolean;
}

export interface IStateRates {
    loading: boolean;
    isSubfilterUsed: boolean;
    companies: string[];
    destinations: string[];
    products: string[];
    prefixes: string[];
    ratesCommon: IRatesServerDataByCompany[];
    ratesFiltered: IRatesServerDataByCompany[];
}

const initialState = {
    loading: false,
    isSubfilterUsed: false,
    companies: [] as string[],
    destinations: [] as string[],
    products: [] as string[],
    prefixes: [] as string[],
    ratesCommon: [] as IRatesServerDataByCompany[],
    ratesFiltered: [] as IRatesServerDataByCompany[]
};

export function ratesReducer(state = initialState, action: IAppAction): IStateRates {
    switch (action.type) {
        case ratesType.RATES_GET_WITH_MAIN_FILTER_REQUEST:
        case ratesType.RATES_GET_WITH_SUB_FILTER_REQUEST:
        case ratesType.RATES_GET_COMPANIES_REQUEST:
            return { ...state, loading: true };
        case ratesType.RATES_GET_WITH_MAIN_FILTER_SUCCESS:
            return {
                ...state,
                ratesCommon: action.payload,
                isSubfilterUsed: false,
                loading: false
            };
        case ratesType.RATES_GET_WITH_SUB_FILTER_SUCCESS:
            return {
                ...state,
                ratesFiltered: action.payload,
                isSubfilterUsed: true,
                loading: false
            };
        case ratesType.RATES_GET_COMPANIES_SUCCESS:
            return { ...state, companies: action.payload, loading: false };
        case ratesType.RATES_GET_DESTINATIONS_SUCCESS:
            return { ...state, destinations: action.payload };
        case ratesType.RATES_GET_PRODUCTS_SUCCESS:
            return { ...state, products: action.payload };
        case ratesType.RATES_GET_PREFIXES_SUCCESS:
            return { ...state, prefixes: action.payload };
        case ratesType.RATES_GET_WITH_MAIN_FILTER_FAIL:
        case ratesType.RATES_GET_WITH_SUB_FILTER_FAIL:
        case ratesType.RATES_GET_COMPANIES_FAIL:
            return { ...state, loading: false };
        case ratesType.RATES_CLEAR_REQUEST:
            return { ...initialState };
        default:
            return state;
    }
}
