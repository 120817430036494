import React, { useCallback, useMemo, useState } from 'react';
import { Button, Card, Col, Row, Skeleton, Space, Tag } from 'antd';
import { T } from '@components/T/T';
import { Separator } from '@components/Separator/Separator';
import { Box, Theme } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { getCurrentInfo, getFinalBillingStatement } from '@selectors/roaming';
import { SettlementApprovalStatus } from '@clearblockchain/shared/period';
import AcceptedOfferIcon from '@components/Svg/AcceptedOfferIcon';
import { selectIsLoadingOffers, selectLatestOffer, selectSortedOffers } from '@selectors/roaming/settlementOffers';
import { useAction } from '../../../../hooks/useAction';
import uiActions from '@actions/ui';
import { DisputeResolutionModal } from '@components/DisputeResolution/DisputeResolutionModal';
import { isDisputeResolutionModalOpen } from '@selectors/ui';
import { getMyPeerId } from '@selectors/account';
import { FontColor, FontWeight, materialTheme } from '@components/theme';
import { BadgeType, ClearBadge } from '@modules/Roaming/components/ClearBadge/ClearBadge';
import transactionsActions from '@actions/agreements';
import { isFeatureSupported } from '@helpers/features';
import makeStyles from '@material-ui/core/styles/makeStyles';
import classNames from 'classnames';
import { ContractStatuses } from '@constants/contractStatuses';

export enum SettlementOfferStatus {
    MyCounterOffer = 'my-counter-offer',
    TheirCounterOffer = 'their-counter-offer',
    MyOffer = 'my-offer',
    TheirOffer = 'their-offer',
    None = 'none',
    Accepted = 'accepted'
}

const useStyles = makeStyles((theme: Theme) => ({
    cardBody: {
        borderRadius: 8,
        boxShadow: '0 3px 8px 0 rgba(0,0,0,0.13)',
        padding: theme.spacing(4),
        paddingBottom: theme.spacing(3)
    },
    successCard: {
        border: `1px solid ${FontColor.Success}`
    },
    settledIconWrapper: {
        display: 'flex',
        background: FontColor.Success,
        height: 66,
        width: 66,
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '50%'
    }
}));

export const SettlementOffer: React.FC<{ className?: string; isDisputeSettled: boolean }> = ({ className, isDisputeSettled }) => {
    const classes = useStyles();
    const offerStatus = useOfferStatus();
    const isLoadingOffers = useSelector(selectIsLoadingOffers);
    const shouldShowDisputeResolutionModal = useSelector(isDisputeResolutionModalOpen);
    const isOfferWaitingForMe = [SettlementOfferStatus.TheirOffer, SettlementOfferStatus.TheirCounterOffer].includes(offerStatus);

    return (
        <>
            <Card
                className={classNames(classes.cardBody, className, isDisputeSettled ? classes.successCard : '')}
                style={{ borderColor: isOfferWaitingForMe ? materialTheme.palette.primary.main : undefined }}
                headStyle={{ padding: `0 16px` }}
                bodyStyle={{ padding: 0, height: '100%' }}
            >
                <Box display="flex" flexDirection="column" height="inherit" alignItems="center">
                    <SettlementOfferContent isOfferWaitingForMe={isOfferWaitingForMe} />
                </Box>
            </Card>
            {shouldShowDisputeResolutionModal && !isLoadingOffers && <DisputeResolutionModal />}
        </>
    );
};

const SettlementOfferContent: React.FC<{ isOfferWaitingForMe: boolean }> = ({ isOfferWaitingForMe }) => {
    const info = useSelector(getCurrentInfo);
    const offerStatus = useOfferStatus();

    if (!isFeatureSupported(info.contract.features, 'DISPUTE_RESOLUTION')) {
        return <BcSplitOffer />;
    } else if (offerStatus === SettlementOfferStatus.Accepted) {
        return <AcceptedOfferBox />;
    } else {
        return <PendingOfferBox isOfferWaitingForMe={isOfferWaitingForMe} />;
    }
};

const SplitOfferStatus: React.FC<{ status: SettlementApprovalStatus }> = ({ status }) => {
    const style = { marginRight: 0 };
    switch (status) {
        case SettlementApprovalStatus.Approved: {
            return <ClearBadge style={style} type={BadgeType.Success} status="Accepted Split" />;
        }
        case SettlementApprovalStatus.Unset: {
            return <ClearBadge style={style} type={BadgeType.Warning} status="Pending" />;
        }
        case SettlementApprovalStatus.Rejected: {
            return <ClearBadge style={style} type={BadgeType.Warning} status="Rejected Split" />;
        }
    }
};

const BcSplitOffer: React.FC = () => {
    const info = useSelector(getCurrentInfo);
    const finalStatement = useSelector(getFinalBillingStatement);
    const [isInProgress, setIsInProgress] = useState<false | SettlementApprovalStatus>(false);
    const myStatus = finalStatement?.perPartyState[info.partiesIndex.me]?.settlementApprovalStatus;
    const theirStatus = finalStatement?.perPartyState[info.partiesIndex.counter]?.settlementApprovalStatus;

    const setApprovalStatus = useAction(transactionsActions.agreementsSetReportsApproval);
    const generateOfferStatusHandler = useCallback(
        (status: SettlementApprovalStatus) => {
            return async () => {
                setIsInProgress(status);
                await setApprovalStatus(info.contract.id, finalStatement?.index, status);
                setIsInProgress(false);
            };
        },
        [setApprovalStatus]
    );
    const acceptOffer = useCallback(generateOfferStatusHandler(SettlementApprovalStatus.Approved), [generateOfferStatusHandler]);

    return (
        <Skeleton loading={!finalStatement}>
            <Box display="flex" clone mb={4} width="100%" alignItems="center" justifyContent="center">
                <Row>
                    <T align="center" weight={FontWeight.Semibold}>
                        Discrepancy
                    </T>
                </Row>
            </Box>
            <Box display="flex" alignItems="center">
                <Box display="flex" flexDirection="column" textAlign="center">
                    <T variant="h6" weight={FontWeight.Semibold}>
                        {finalStatement?.discrepancy.localString}
                    </T>
                    <T color={FontColor.B65} style={{ alignSelf: 'center' }} variant="subtitle2">
                        ({finalStatement?.discrepancyPercentage}% of {info.myName})
                    </T>
                </Box>
            </Box>
            <Separator />
            <BcSplitOfferActions isInProgress={isInProgress} myStatus={myStatus!} theirStatus={theirStatus!} onAccept={acceptOffer} />
        </Skeleton>
    );
};

const BcSplitOfferActions: React.FC<{
    isInProgress: any;
    myStatus: SettlementApprovalStatus;
    theirStatus: SettlementApprovalStatus;
    onAccept: () => void;
}> = ({ isInProgress, myStatus, theirStatus, onAccept }) => {
    if (myStatus === SettlementApprovalStatus.Approved && theirStatus === SettlementApprovalStatus.Approved) {
        return (
            <T weight={FontWeight.Semibold} style={{ color: materialTheme.palette.success }}>
                Settled And Closed
            </T>
        );
    }
    if (myStatus === SettlementApprovalStatus.Approved) {
        return <T weight={FontWeight.Semibold}>50/50 Split Accepted By Me</T>;
    }
    return (
        <Button block size="large" type="primary" loading={isInProgress === SettlementApprovalStatus.Approved} onClick={onAccept}>
            Accept 50/50 Split
        </Button>
    );
};

const PendingOfferBox: React.FC<{ isOfferWaitingForMe: boolean }> = ({ isOfferWaitingForMe }) => {
    const info = useSelector(getCurrentInfo);
    const finalStatement = useSelector(getFinalBillingStatement);
    const isLoadingOffers = useSelector(selectIsLoadingOffers);
    const offerReceivedStatus = ContractStatuses.offerReceived;
    return (
        <Skeleton loading={isLoadingOffers || !finalStatement}>
            <Box display="flex" mb={4} width="100%" clone alignItems="center">
                <Row>
                    <Col span={8}>
                        {isOfferWaitingForMe ? (
                            <Space>
                                <Tag color={offerReceivedStatus.bgColor} style={{ color: offerReceivedStatus.color }}>
                                    {offerReceivedStatus.text}
                                </Tag>
                            </Space>
                        ) : null}
                    </Col>
                    <Col span={8}>
                        <T align="center" weight={FontWeight.Semibold}>
                            Discrepancy
                        </T>
                    </Col>
                </Row>
            </Box>
            <Box display="flex" alignItems="center">
                <Box display="flex" flexDirection="column" textAlign="center">
                    <T variant="h6" data-t={'discrepancy-amount'} weight={FontWeight.Semibold}>
                        {finalStatement?.discrepancy.localString}
                    </T>
                    <T color={FontColor.B65} style={{ alignSelf: 'center' }} variant="subtitle2">
                        ({finalStatement?.discrepancyPercentage}% of {info.myName})
                    </T>
                </Box>
            </Box>
            <Separator />
            <ActionButton />
        </Skeleton>
    );
};

const AcceptedOfferBox: React.FC = () => {
    const classes = useStyles();
    const showDisputeResolution = useAction(uiActions.showDisputeResolutionModal);
    return (
        <>
            <Box display="flex" mb={6}>
                <T weight={FontWeight.Semibold} variant={'subtitle1'} color={FontColor.Success}>
                    Settled and Closed
                </T>
            </Box>
            <Box className={classes.settledIconWrapper}>
                <AcceptedOfferIcon />
            </Box>
            <Separator />
            <Box mb={1} onClick={showDisputeResolution} style={{ cursor: 'pointer' }}>
                <T variant={'subtitle2'} color={FontColor.B65}>
                    View Offer History
                </T>
            </Box>
        </>
    );
};

const ActionButton: React.FC = () => {
    const offerStatus = useOfferStatus();
    const showDisputeResolution = useAction(uiActions.showDisputeResolutionModal);

    const captionByStatus: Record<SettlementOfferStatus, string> = {
        [SettlementOfferStatus.None]: 'Make an Offer',
        [SettlementOfferStatus.MyOffer]: 'Review My Offer',
        [SettlementOfferStatus.MyCounterOffer]: 'Review My Counter offer',
        [SettlementOfferStatus.TheirOffer]: 'Respond to Offer',
        [SettlementOfferStatus.TheirCounterOffer]: 'Respond to Counter offer',
        [SettlementOfferStatus.Accepted]: 'Accepted'
    };

    const caption = captionByStatus[offerStatus];

    return (
        <Button block size="large" type="primary" onClick={showDisputeResolution}>
            {caption}
        </Button>
    );
};

export const useOfferStatus = (): SettlementOfferStatus => {
    const finalStatement = useSelector(getFinalBillingStatement);
    const myPeerId = useSelector(getMyPeerId);
    const offer = useSelector(selectLatestOffer);
    const allOffers = useSelector(selectSortedOffers);
    return useMemo<SettlementOfferStatus>(() => {
        if (!offer) {
            return SettlementOfferStatus.None;
        }

        if (
            finalStatement &&
            [finalStatement.perPartyState[0].settlementApprovalStatus, finalStatement.perPartyState[1].settlementApprovalStatus].includes(
                SettlementApprovalStatus.Approved
            )
        ) {
            return SettlementOfferStatus.Accepted;
        }

        if (offer.initiatorId === myPeerId) {
            return allOffers.length > 1 ? SettlementOfferStatus.MyCounterOffer : SettlementOfferStatus.MyOffer;
        } else {
            return allOffers.length > 1 ? SettlementOfferStatus.TheirCounterOffer : SettlementOfferStatus.TheirOffer;
        }
    }, [offer, finalStatement]);
};
