import React from 'react';

const SvgEmptyStateSettlement = () => (
    <svg width="80" height="48" viewBox="0 0 80 48">
        <g fill="none" fillRule="evenodd">
            <path
                d="M36.986 44.236c24.27-8.696 36.985.074 42.333-12.736C84.666 18.69 57.412 15 36.986 15 16.559 15 0 23.726 0 34.49c0 10.765 12.715 18.44 36.986 9.746z"
                fill="#4A83FF"
                fillOpacity={0.159}
            />
            <path
                d="M58.019 12.864l-7.14 5.282-9.462-14.093a.62.62 0 00-.558-.27c-.506.075-.855-.044-1.108.295-.091.12-2.18 2.924-3.406 4.997-.714 1.199-2.81.965-3.831.766l1.79-6.856h.003a2.09 2.09 0 011.949-1.556l4.999-.214 6.952 3.193c.11.049.23.066.35.05l9.919-1.444a.607.607 0 00.517-.687.611.611 0 00-.695-.511l-9.741 1.419-6.919-3.18A.653.653 0 0041.352 0l-5.148.221c-2.949.125-3.218 3.153-3.305 3.303l-6.562 2.184-4.655-.53a.606.606 0 10-.136 1.204l4.761.538h.071l-.002.002c.068 0 .13-.01.194-.029l5.966-1.999-1.362 5.241c-.081.314.1.636.415.73.178.055 4.356 1.275 5.809-1.179 1.012-1.684 2.65-3.953 3.172-4.661l9.615 14.331v-.001c.045.07.107.13.178.175.018.088.053.17.107.244.239.336.334.752.262 1.156a1.504 1.504 0 01-.632 1 1.59 1.59 0 01-2.192-.366l-2.793-3.878a.623.623 0 00-.862-.147.604.604 0 00-.15.851l2.024 2.835a.88.88 0 00.071.134c.22.303.306.68.243 1.048a1.387 1.387 0 01-.577.91 1.676 1.676 0 01-2.11-.314l-2.409-3.604a.62.62 0 00-.882-.219.599.599 0 00-.13.889l1.771 2.643v.002a.202.202 0 00.03.049c.35.486.506 1.081.435 1.675a.889.889 0 01-.41.628 1.391 1.391 0 01-1.923-.304l-2.387-3.604a.622.622 0 00-.805-.11.602.602 0 00-.207.776l2.089 3.148c.01.041.022.084.036.125.138.264.162.572.067.855a1.115 1.115 0 01-.573.645c-.724.5-1.756-.026-2.303-.385h-.04c-1.68-.955-4.31-2.828-9.073-6.207l-5.06-3.614a.624.624 0 00-.864.149.606.606 0 00.15.85l5.06 3.608c4.782 3.412 7.425 5.302 9.109 6.262.672.463 1.469.72 2.287.74a2.34 2.34 0 001.376-.426c.516-.304.901-.78 1.093-1.343.852.42 1.868.346 2.647-.195.494-.32.828-.83.927-1.403v-.14c.399.18.834.274 1.275.274a2.787 2.787 0 001.577-.5 2.594 2.594 0 001.012-1.374c.338.229.722.383 1.125.45a2.8 2.8 0 002.925-1.41 2.727 2.727 0 00-.128-2.819l7.2-5.282a.598.598 0 00.125-.847.617.617 0 00-.858-.12z"
                fill="#000"
                fillRule="nonzero"
            />
        </g>
    </svg>
);

export default SvgEmptyStateSettlement;
