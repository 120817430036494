import voiceActions, { ISorting } from '@actions/voice';
import uiActions from '@actions/ui';
import { AccountStatus, IAccounts } from '@reducers/voice';
import { getAccountsByPage } from '@selectors/voice';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Pagination, SubHeader, Table } from '@components';
import ClearMenu from '@components/Menus/Menu';
import moment from 'moment';
import './partnerManagementStyles.scss';
import { periodDateFormat } from '@constants';

const tableColumns = [
    { key: 'Account', title: 'Account' },
    { key: 'Added', title: 'Added', notSortable: true },
    { key: 'Updated', title: 'Updated', notSortable: true },
    { key: 'CBN', title: 'CBN', notSortable: true }
];

const columnNameToServerName: Record<string, string> = {
    Account: 'name',
    Added: 'createdAt',
    Updated: 'updatedAt'
};

const defaultSorting = {
    by: 'Added',
    order: 'desc' as ISorting['order']
};

const textToChipsRowComponent = (status: AccountStatus) => {
    switch (status) {
        case AccountStatus.PENDING_PARTNER_APPROVAL:
            return 'Pending';
        case AccountStatus.PENDING_YOUR_APPROVAL:
            return 'Waiting your approval';
        default:
            return null;
    }
};

export const PartnersManagement = () => {
    const dispatch = useDispatch();
    const [sorting, setSorting] = useState<null | ISorting>(null);
    const [page, setPage] = useState<number>(1);
    const accountsByPage: any = useSelector(getAccountsByPage);

    const dropdownComponent = (account: any) => {
        const updateAccount = (accountToUpdate: any) => () => {
            accountToUpdate.status = AccountStatus.APPROVED;
            dispatch(voiceActions.voiceUpdateAccount(account.id, account));
        };
        return (
            <ClearMenu menuWrapperClassName={'partner-management-table-hamburger-menu'} buttonStyle={buttonStyles} anchorOrigin={anchorOrigin}>
                <div onClick={updateAccount(account)}>Accept</div>
            </ClearMenu>
        );
    };

    const adjustServerNamesToTableHeaders = (accounts: IAccounts): any => {
        const adjustedAccounts = accounts.map((account) => {
            const textToChips = account.status ? textToChipsRowComponent(account.status) : null;
            return {
                ...account,
                Account: account.presentedName,
                Added: account.createdAt ? moment(account.createdAt * 1000).format(periodDateFormat) : undefined,
                Updated: account.updatedAt ? moment(account.updatedAt * 1000).format(periodDateFormat) : undefined,
                rowClass: textToChips ? 'pending' : '',
                chipsRowComponent: textToChips ? <div className={'pending-approve-bubble'}>{textToChips}</div> : null,
                dropdownComponent: account.status === AccountStatus.PENDING_YOUR_APPROVAL ? dropdownComponent(account) : null
            };
        });
        return adjustedAccounts;
    };

    useEffect(() => {
        dispatch(voiceActions.voiceQueryAccountsByPage(null, sorting, page));
    }, [sorting, page]);

    const onSortingColumnSelect = ({ key }: any, isAscending: boolean) => {
        const dbColumnToSortBy = columnNameToServerName[key];
        setSorting({
            by: dbColumnToSortBy,
            order: isAscending ? 'asc' : 'desc'
        });
    };

    const onSelectPage = (pageNumToFetch: number): void => {
        setPage(pageNumToFetch);
    };
    let currentPartners = null;
    let pagination = null;
    if (accountsByPage) {
        pagination = accountsByPage.pagination;
        currentPartners = accountsByPage.items;
    }
    const openAddPartnerDialog = () => {
        dispatch(uiActions.showAddPartnerDialog());
    };
    return (
        <>
            <SubHeader title="Partner Management" />
            {currentPartners ? (
                <div className="table-with-drilldown with-larger-columns">
                    <div onClick={openAddPartnerDialog} className={'add-partner-opener'}>
                        Add partner
                    </div>
                    <div className="pagination-header">
                        <Pagination pagination={pagination} onSelectPage={onSelectPage} />
                    </div>
                    <Table
                        defaultSorting={defaultSorting}
                        classes={'voice-payable-traffic-list view-by-destination'}
                        cols={tableColumns}
                        data={adjustServerNamesToTableHeaders(currentPartners)}
                        noHeader={true}
                        noMoreOption={true}
                        onSortingColumnSelect={onSortingColumnSelect}
                        notFrontendSortable={true}
                        noExportRow
                    />
                </div>
            ) : null}
        </>
    );
};

const anchorOrigin = { horizontal: -50, vertical: 4 };

const buttonStyles = { padding: 10 };
