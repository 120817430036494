import { FontColor } from '@components/theme';

export const ContractStatuses = {
    readyToAnalyze: {
        bgColor: '#ebf1ff',
        color: '#4a83ff',
        text: 'Ready to Analyze'
    },
    negotiation: {
        bgColor: '#FFF2E8',
        color: '#FA541C',
        text: 'In Negotiation'
    },
    settled: {
        bgColor: '#e3fff6',
        color: FontColor.Success,
        text: 'Settled'
    },
    awaitingUsage: {
        bgColor: '#FFF2E8',
        color: '#FA541C',
        text: 'Awaiting Usage'
    },
    offerReceived: {
        bgColor: '#ebf1ff',
        color: '#4a83ff',
        text: 'Offer Received'
    },
    rejectedByUs: {
        bgColor: '#fde2e0',
        color: '#f5222d',
        text: 'Rejected by us'
    },
    rejectedByPartner: {
        bgColor: '#fde2e0',
        color: '#f5222d',
        text: 'Rejected by Partner'
    }
};
