import XLSX, { ColInfo } from 'xlsx';
import moment from 'moment';

const generateSheetFromTable = (table: Element): string[][] => {
    const wsData = [] as string[][];
    let columnWidths = [] as number[];
    let indentation = false;
    const indentations = [] as boolean[];

    const rowHTMLs = table.getElementsByClassName('tr'); // Returns all rows in a table
    const rows = Array.from(rowHTMLs);
    rows.forEach((row) => {
        const rowData = [] as string[];
        const additionalRows = [] as string[][];

        let cellHTMLs = row.getElementsByClassName('td'); // Returns all cells in a row
        if (cellHTMLs.length === 0) {
            cellHTMLs = row.getElementsByClassName('th'); // Returns all headers
            columnWidths = Array(cellHTMLs.length).fill(1); // An array to store the width of the widest grid in every column
        }
        const cells = Array.from(cellHTMLs).filter((cell: Element) => !cell.classList.contains('options'));
        cells.forEach((cell, cellIndex) => {
            const gridInsideTheCell = cell.getElementsByClassName('grid')[0];
            if (gridInsideTheCell) {
                const gridInsideTheCellTemplateColumns = getComputedStyle(gridInsideTheCell).gridTemplateColumns;
                if (gridInsideTheCellTemplateColumns) {
                    const gridColumnsNumber = gridInsideTheCellTemplateColumns.split(' ').length;
                    const columnsNumberDifference = gridColumnsNumber - columnWidths[cellIndex];
                    // Calculates the number of cells in the row until current row
                    const calculatedIndex = columnWidths.slice(0, cellIndex).reduce((a, b) => a + b, 0);
                    if (columnsNumberDifference > 0) {
                        wsData.forEach((line) => {
                            // Adding blank cells to all rows above, in order to match the grid
                            line.splice(calculatedIndex + columnWidths[cellIndex], 0, ...Array(columnsNumberDifference).fill(''));
                        });
                        columnWidths[cellIndex] = gridColumnsNumber;
                    }
                    const gridCells = Array.from(gridInsideTheCell.children);
                    gridCells.splice(0, gridColumnsNumber).forEach((innerCell) => {
                        rowData.push(innerCell.textContent || '');
                    });

                    // Adding more rows to the worksheet for the inner grid rows
                    let additionalRowNumber = 0;
                    while (gridCells.length) {
                        if (!additionalRows[additionalRowNumber]) {
                            additionalRows.push([]);
                        }
                        // Padding the row with blank cells
                        additionalRows[additionalRowNumber].push(...Array(calculatedIndex - additionalRows[additionalRowNumber].length).fill(''));
                        gridCells.splice(0, gridColumnsNumber).forEach((innerCell) => {
                            additionalRows[additionalRowNumber].push(innerCell.textContent || '');
                        });
                        additionalRowNumber += 1;
                    }
                }
            } else {
                rowData.push(cell.textContent || '');
            }
        });

        wsData.push(rowData);
        indentations.push(indentation);
        wsData.push(...additionalRows);
        indentations.push(...Array(additionalRows.length).fill(indentation));

        if (row.classList.contains('extended')) {
            indentation = false;
        }
        if (row.classList.contains('last')) {
            indentation = false;
        } else if (row.classList.contains('total')) {
            wsData.push([]);
        }
    });

    if (indentations.includes(true)) {
        wsData.forEach((row, rowIndex) => {
            row.splice(indentations[rowIndex] ? 0 : 1, 0, ''); // Adds indentation
        });
    }

    return wsData;
};

export const exportTable = (filename: string, extension: string = 'xlsx', sheetName?: string, data?: any[][], columnsWidth?: ColInfo[]) => {
    const dataToUse = data || generateSheetFromTable(document.getElementsByClassName('table')[0]);
    const wb = XLSX.utils.book_new();

    const ws = XLSX.utils.aoa_to_sheet(dataToUse);
    if (columnsWidth) {
        ws['!cols'] = columnsWidth;
    }
    XLSX.utils.book_append_sheet(wb, ws, sheetName || filename);

    XLSX.writeFile(wb, `${filename.replace(/\s/g, '-')}.${extension}`);
};

export const exportDataObject = (
    filename: string,
    sheets: Array<{ sheetName: string; data: any }>,
    extension: string = 'xlsx',
    columnsWidth?: ColInfo[]
) => {
    const wb = XLSX.utils.book_new();
    sheets.forEach((sheet) => {
        const ws = XLSX.utils.json_to_sheet(sheet.data);
        if (columnsWidth) {
            ws['!cols'] = columnsWidth;
        }
        XLSX.utils.book_append_sheet(wb, ws, sheet.sheetName);
    });

    XLSX.writeFile(wb, `${filename}.${extension}`);
};

export const getCurrentDateExportFormatted = () => moment(Date.now()).format('MMM-DD-YYYY');
