import { IAppAction } from '@reducers/state';
import { activityLogsActionType } from '@actions/activityLogs';
import { ActivityLog } from '@clearblockchain/shared/activity-log';

export interface IStateActivityLogs {
    logs: ActivityLog[] | [];
    loading: boolean;
}

const initialState: IStateActivityLogs = {
    logs: [],
    loading: false
};

export function activityLogsReducer(state = initialState, action: IAppAction): IStateActivityLogs {
    switch (action.type) {
        case activityLogsActionType.GET_ACTIVITY_LOGS:
            return { ...state, loading: true };
        case activityLogsActionType.GET_ACTIVITY_LOGS_SUCCESS:
            return { logs: action.payload, loading: false };
        case activityLogsActionType.GET_ACTIVITY_LOGS_FAIL:
            return { ...state, loading: false };
        default:
            return state;
    }
}
