import * as React from 'react';
import { useSelector } from 'react-redux';
import { getApp } from '@selectors/app';
import { ReduxSpinner } from '@components/Spinner';
import { getSpinner } from '@selectors/ui';
import { NetworkStatus } from '@reducers/app';
import { isAuthChecked } from '@selectors/auth';

export const AppLayout: React.FC = ({ children }: any) => {
    const { networkStatus } = useSelector(getApp);
    const spinner = useSelector(getSpinner);
    const userAuthChecked = useSelector(isAuthChecked);

    if (!userAuthChecked || networkStatus === NetworkStatus.Uninitialized) {
        return <ReduxSpinner spinner={spinner} />;
    }

    return children;
};
