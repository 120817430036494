import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

export function useAction(createAction: any) {
    const dispatch = useDispatch();
    return useCallback(
        (...params: Parameters<typeof createAction>): Promise<any> => {
            const action = createAction(...params);
            const promise = new Promise((res, rej) => {
                action.resolve = res;
                action.reject = rej;
            });
            dispatch(action);
            return promise;
        },
        [dispatch]
    );
}

export function useActions(actions: any[]) {
    return actions.map(useAction);
}
