import React from 'react';
import { IStateModalProps } from '@reducers/ui';

import './styles.scss';

const AlertModal = ({ closeModal, title, message }: IStateModalProps): JSX.Element => {
    return (
        <div className="modal-content">
            <div className="modal-header">
                <h5 className="modal-title">{title}</h5>
                <button type="button" className="modal-close-button" aria-label="Close" onClick={closeModal}>
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div className="modal-body">
                <p>{message}</p>
            </div>
            <div className="modal-footer">
                <button type="button" className="btn btn-secondary" onClick={closeModal}>
                    Close
                </button>
            </div>
        </div>
    );
};

export default AlertModal;
