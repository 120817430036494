import { createSelector } from 'reselect';
import { IRootState } from '@reducers/index';
import { IGroup, IInviteLinkData, IMagicLink, IManageUserState, IUserData } from '@actions/users';

const userSelector = (state: IRootState): IManageUserState => state.user;

export const getInviteLinkData = createSelector(userSelector, (userState: IManageUserState): IInviteLinkData => userState.inviteLink);

export const getManagedUsers = createSelector(userSelector, (userState: IManageUserState): IUserData[] => {
    return userState.users.map((user: any) => ({
        ...user,
        tadigCodes: user.attributes.tadigCodes
    }));
});

export const getGroups = createSelector(userSelector, (userState: IManageUserState): IGroup[] => {
    return userState.groups;
});

export const getLastRequestedToLogin = createSelector(userSelector, (userState: IManageUserState) => userState.lastRequestToLoginUsername);

export const getUserError = createSelector(userSelector, (userState: IManageUserState): string => userState.error || '');

export const getResetPasswordResultsById = createSelector(userSelector, (userState: IManageUserState) => userState.resetPasswordResultsByUserId);
export const getExpirePasswordResultsById = createSelector(userSelector, (userState: IManageUserState) => userState.expirePasswordResultsByUserId);

export const getShouldShowCreateUser = createSelector(userSelector, ({ showCreateUser }: IManageUserState) => showCreateUser);

export const getUserToEdit = createSelector(userSelector, ({ userToEdit }: IManageUserState) => userToEdit);

export const getMagicLink = createSelector(userSelector, (userState: IManageUserState): IMagicLink | {} => userState.magicLinkData);
