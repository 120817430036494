import { createAction, createRequestAction } from '@helpers/actionCreator';
import { IDialogType, IStateModalProps } from '@reducers/ui';

export enum uiType {
    SPINNER_SHOW = 'UI:SPINNER:SHOW',
    SPINNER_HIDE = 'UI:SPINNER:HIDE',
    INCREMENT_PENDING_SPINNER = 'UI:INCREMENT:PENDING:SPINNER',
    DECREMENT_PENDING_SPINNER = 'UI:DECREMENT:PENDING:SPINNER',
    MODAL_SHOW = 'UI:MODAL:SHOW',
    MODAL_HIDE = 'UI:MODAL:HIDE',
    DIALOG_SHOW = 'UI:DIALOG:SHOW',
    DIALOG_HIDE = 'UI:DIALOG:HIDE',
    SHOW_ADD_PARTNER_DIALOG = 'UI:SHOW:ADD:PARTNER:DIALOG',
    HIDE_ADD_PARTNER_DIALOG = 'UI:HIDE:ADD:PARTNER:DIALOG',
    SHOW_SESSION_EXPIRATION_DIALOG = 'UI:SHOW:EXPIRATION:SESSION:EXPIRATION:DIALOG',
    HIDE_SESSION_EXPIRATION_DIALOG = 'UI:HIDE:EXPIRATION:SESSION:EXPIRATION:DIALOG',

    SHOW_DISPUTE_RESOLUTION_MODAL = 'SHOW:DISPUTE:RESOLUTION:MODAL',

    LOG_UI_SETTINGS = 'UI:LOG:SETTINGS::REQUEST',
    LOG_UI_SETTINGS_FAIL = 'UI:LOG:SETTINGS::REQUEST:FAIL',
    LOG_UI_SETTINGS_SUCCESS = 'UI:LOG:SETTINGS::REQUEST:SUCCESS'
}

export interface IShowSpinner {
    successMessage?: string;
    message?: string;
    classes?: string;
}

function getScreenSize() {
    let screenSize = '';
    if (screen.width) {
        const width = screen.width ? screen.width : '';
        const height = screen.height ? screen.height : '';
        screenSize += `${width} x ${height}`;
    }
    return screenSize;
}

function getWindowSize() {
    let windowSize = '';
    if (window.innerWidth) {
        const width = window.innerWidth ? window.innerWidth : '';
        const height = window.innerHeight ? window.innerHeight : '';
        windowSize += `${width} x ${height}`;
    }
    return windowSize;
}

const uiActions = {
    incrementSpinnerPending: createAction(uiType.INCREMENT_PENDING_SPINNER),
    decrementSpinnerPending: createAction(uiType.DECREMENT_PENDING_SPINNER),
    showSpinner: createAction(uiType.SPINNER_SHOW, {
        payload: ({ successMessage = '', message = 'Loading', classes }: IShowSpinner = {}) => ({
            successMessage,
            message,
            classes
        })
    }),
    hideSpinner: createAction(uiType.SPINNER_HIDE),
    showWebSocketAlertModal: createAction(uiType.MODAL_SHOW, {
        payload: {
            modalType: 'webSocketAlert',
            modalProps: ({
                title: 'CONNECTION ERROR!',
                message: 'WebSocket connection is lost! Sorry for inconvenience! It will be fixed as soon as possible!'
            } as any) as IStateModalProps
        }
    }),
    showDisputeResolutionModal: createAction(uiType.SHOW_DISPUTE_RESOLUTION_MODAL),
    hideModal: createAction(uiType.MODAL_HIDE),
    showDialog: createAction(uiType.DIALOG_SHOW, { payload: (dialogType: IDialogType, dialogProps: any = {}) => ({ ...dialogProps, dialogType }) }),
    hideDialog: createAction(uiType.DIALOG_HIDE),
    showAddPartnerDialog: createAction(uiType.SHOW_ADD_PARTNER_DIALOG),
    hideAddPartnerDialog: createAction(uiType.HIDE_ADD_PARTNER_DIALOG),
    showSessionExpirationDialog: createAction(uiType.SHOW_SESSION_EXPIRATION_DIALOG),
    hideSessionExpirationDialog: createAction(uiType.HIDE_SESSION_EXPIRATION_DIALOG),
    logUiSettings: createRequestAction(uiType.LOG_UI_SETTINGS, 'log', () => [
        {
            message: 'User browser settings',
            userAgent: navigator.userAgent,
            browserVendor: navigator.vendor,
            screenSize: getScreenSize(),
            windowSize: getWindowSize()
        }
    ])
};

export default uiActions;
