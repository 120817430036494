import React from 'react';
import { Box } from '@material-ui/core';
import { T } from '@components/T/T';
import { Tooltip } from 'antd';
import MoreInfoIcon from '@ant-design/icons/QuestionCircleOutlined';
import UnsupportedIcon from '@ant-design/icons/CloseOutlined';
import { FontWeight } from '@components/theme';

const FEATURE_LIST: Array<{ label: string; type: string; name: string; info: string }> = [
    {
        type: 'settlementAlgorithm',
        name: 'COUNTRY_MAPPING',
        label: 'Destination Name Identification',
        info:
            'Destination Name Identification will not be used - destination countries will be read as they are from the usage files without translation'
    }
];

interface IProps {
    target: 'Contract' | 'Partner';
    features: any;
}

export const UnsupportedPeerFeatures: React.FC<IProps> = ({ target, features }) => {
    const unsupportedFeatures: typeof FEATURE_LIST = [];
    FEATURE_LIST.forEach((feature) => {
        if (!features[feature.type]?.[feature.name]) {
            unsupportedFeatures.push(feature);
        }
    });

    if (!unsupportedFeatures.length) {
        return null;
    }

    return (
        <Box my={4}>
            <T paragraph variant="caption" weight={FontWeight.Semibold}>
                This {target} doesn't support the following features:
            </T>
            {unsupportedFeatures.map((f) => (
                <Box key={f.name} display="flex" alignItems="center">
                    <Box mr={2}>
                        <UnsupportedIcon style={{ color: 'red' }} />
                    </Box>
                    <Box mr={2}>
                        <T variant="subtitle2">{f.label} </T>
                    </Box>
                    <Tooltip title={f.info}>
                        <MoreInfoIcon />
                    </Tooltip>
                </Box>
            ))}
        </Box>
    );
};

UnsupportedPeerFeatures.defaultProps = {
    features: {}
};
