import React, { useReducer, Reducer, useEffect } from 'react';
import { Drawer } from '@components';
import { TagList } from '@modules/Voice/components';
import MaskedInput from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import './styles.scss';
import { formatBalance } from '@helpers/formaters';
import { getCurrencySymbol } from '@constants';
import { useSelector, useDispatch } from 'react-redux';
import { getDisputeOffers, convertDiscrepancyType } from '@selectors/voice';
import voiceActions, { DisputeOfferType, DisputeOfferStatus } from '@actions/voice';
import { CopyLink } from '../CopyLink';
import get from 'lodash/get';
import { getPeerPresentedName } from '@selectors/account';

interface IProps {
    isSplit?: boolean;
    settlement: any;
    comparisonItem: any;
    isRecipient?: boolean;
    isExternal?: boolean;
    close?(): void;
    submit?(comparisonItem: any, isSplit: boolean, value?: number): void;
}

interface IState {
    editable?: boolean;
    userValue?: number;
    statusToApprove?: DisputeOfferStatus;
    rejectReason?: string;
    showRejectReason?: boolean;
    overrideExisting?: boolean;
}

export const DisputeResolution = ({ isSplit, settlement, comparisonItem, close, submit, isRecipient, isExternal }: IProps) => {
    const dispatch = useDispatch();
    const offerActionReducer = (state: IState, action: any) => {
        let editable = true;
        switch (action.type) {
            case 'reset':
                editable = false;
            case 'change':
                return { editable };
            case 'valueChange':
                return {
                    ...state,
                    userValue: action.payload
                };
            case 'accept':
                return {
                    ...state,
                    statusToApprove: DisputeOfferStatus.Accept,
                    rejectReason: undefined
                };
            case 'reject':
                return {
                    ...state,
                    statusToApprove: DisputeOfferStatus.Reject,
                    rejectReason: undefined
                };
            case 'clearStatusChange':
                return {
                    ...state,
                    statusToApprove: undefined,
                    rejectReason: undefined
                };
            case 'rejectReasonChange':
                return { ...state, rejectReason: action.payload };
            case 'performStatusChange':
                dispatch(
                    voiceActions.voiceUpdateDisputeOfferStatus(
                        lastDisputeOfferId,
                        state.statusToApprove as DisputeOfferStatus,
                        state.rejectReason,
                        isExternal
                    )
                );
                return {
                    ...state,
                    statusToApprove: undefined,
                    rejectReason: undefined
                };
            case 'setShowRejectReason':
                return { ...state, showRejectReason: action.payload };
            case 'override':
                return { ...state, overrideExisting: true };
            case 'clearOverride':
                return { ...state, overrideExisting: false };
            default:
                throw new Error('Unknown type ' + action.type);
        }
    };

    const [offerState, setOfferState] = useReducer<Reducer<IState, any>>(offerActionReducer, { editable: false });
    const { amountDiff, amountDiffFormatted, minutesDiffFormatted, types, destination, product, lastDisputeOfferId } = comparisonItem;
    const offers = useSelector(getDisputeOffers);
    const peerPresentedName = useSelector(getPeerPresentedName);
    const { date: settlementPeriod, accountCurrency: currency, accountName, invoiceReferenceId, peerName, accountPresentedName } = settlement;

    const changeAmount = () => setOfferState({ type: 'change' });
    const resetAmount = () => setOfferState({ type: 'reset' });
    const confirmAccept = () => setOfferState({ type: 'accept' });
    const confirmReject = () => setOfferState({ type: 'reject' });
    const clearStatusChange = () => setOfferState({ type: 'clearStatusChange' });
    const startCreatingNewOffer = () => setOfferState({ type: 'override' });
    const partOfDiffChange = (event: React.ChangeEvent<HTMLInputElement>) =>
        setOfferState({
            type: 'valueChange',
            payload: parseFloat(event.target.value.replace(/[^-\.0-9]/g, ''))
        });
    const setShowRejectReason = (value: boolean) => setOfferState({ type: 'setShowRejectReason', payload: value });
    const rejectReasonChange = (event: React.ChangeEvent<HTMLInputElement>) =>
        setOfferState({
            type: 'rejectReasonChange',
            payload: event.target.value.trim()
        });
    const performStatusChange = () => setOfferState({ type: 'performStatusChange' });
    const getOffersName = () => {
        if (isRecipient && !isExternal) {
            return accountPresentedName || accountName;
        }
        if (isRecipient && isExternal) {
            return peerPresentedName || (peerName || '').toUpperCase();
        }
    };
    const OffersName = getOffersName();

    useEffect(() => {
        setOfferState({ type: 'clearOverride' });
        if (lastDisputeOfferId) {
            dispatch(voiceActions.voiceGetDisputeOffer(lastDisputeOfferId));
        }
    }, [lastDisputeOfferId, get(offers, `${[lastDisputeOfferId]}.status`)]);
    const existingOffer = !offerState.overrideExisting && lastDisputeOfferId && offers[lastDisputeOfferId];
    const existingIsSplit = existingOffer && existingOffer.offerType === DisputeOfferType.FiftyFifty;
    const { status, partnerStatusDescription, link } = existingOffer || ({} as any);
    const absDiff = Math.abs(amountDiff);
    const isEditable = (!existingOffer && !isSplit) || offerState.editable;
    const currencySymbol = getCurrencySymbol(currency);

    const getResolutionTitle = () => {
        let title: any = '';
        // tslint:disable-next-line:prefer-conditional-expression
        if (existingOffer && isRecipient) {
            title = `${OffersName} ${existingIsSplit ? 'offered to split 50/50' : 'made an offer'}`;
        } else if (existingOffer && !isRecipient) {
            title = existingIsSplit ? 'Offered 50/50 split' : 'Offered Amount';
        } else {
            title = isEditable ? 'Offer Amount' : '50/50 Split';
        }
        return <div className="title">{title}</div>;
    };

    const renderAmountValue = () => {
        if (existingOffer) {
            return <div className="amount-display">{formatBalance(existingOffer.amount, 2, { currency: currencySymbol })}</div>;
        } else if (isEditable) {
            return (
                <>
                    <MaskedInput
                        autoFocus={true}
                        name="partOfDiff"
                        className="amount-display input"
                        mask={createNumberMask({
                            prefix: currencySymbol,
                            allowDecimal: true
                        })}
                        onChange={partOfDiffChange}
                    />
                    {isSplit && (
                        <button className="btn-sub" onClick={resetAmount}>
                            Reset to Split Amount
                        </button>
                    )}
                </>
            );
        } else {
            return (
                <>
                    <div className="amount-display">{formatBalance(absDiff / 2, 2, { currency: currencySymbol })}</div>
                    <button className="btn-sub" onClick={changeAmount}>
                        Change Amount
                    </button>
                </>
            );
        }
    };

    const getOfferStatusMessage = (sts: DisputeOfferStatus) => {
        switch (sts) {
            case DisputeOfferStatus.OfferSent:
                return 'Awaiting Response';
            case DisputeOfferStatus.Accept:
                return 'Accepted';
            case DisputeOfferStatus.Reject:
                return 'Rejected';
            default:
                return '';
        }
    };

    const renderStatus = (msg: string) => (
        <div className={'offer-status ' + status}>
            <span className="flag-container">
                <span className={'flag ' + status} />
            </span>
            <span>{msg}</span>
            {status === DisputeOfferStatus.Reject && partnerStatusDescription && (
                <div className="show-reason" onClick={setShowRejectReason.bind(null, true)} />
            )}
        </div>
    );

    const renderConfirmDialog = (disputeStatus: DisputeOfferStatus) => {
        // NOTE - using custom dialog implementation because the standard one's modal behavior collided with the drawer's behavior
        const isReject = disputeStatus === DisputeOfferStatus.Reject;
        const verb = isReject ? 'Reject' : 'Accept';
        return (
            <div className="confirm-dialog-overlay">
                <div className="voice-dialog confirm-dialog">
                    <button className="close" onClick={clearStatusChange} />
                    <div className="dialog-inner">
                        <div className="dialog-header">{verb} Notice</div>
                        <div className="dialog-body">
                            <div className="title">
                                You are about to {verb.toLowerCase()} {existingIsSplit ? '50/50 split' : ''} offer
                            </div>
                            <div className="dispute-amounts">
                                <span>{OffersName} offered to pay</span>
                                {renderAmountValue()}
                            </div>
                            {isReject && <input className="rejection-reason" onChange={rejectReasonChange} placeholder="Type Rejection Reason" />}
                            <div className="actions">
                                <button className="btn-cancel" onClick={clearStatusChange}>
                                    Cancel
                                </button>
                                <button className={'btn btn-primary ' + verb} onClick={performStatusChange}>
                                    {verb}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };
    const isAmountOfferedNotAllowed = get(offerState as object, 'userValue') >= Math.abs(amountDiff);
    const submitOffer = () => !isAmountOfferedNotAllowed && submit && submit(comparisonItem, !isEditable, offerState.userValue);

    // make sure renderConfirmDialog is not rendered before we close drawer
    const onClose = () => close && !offerState.statusToApprove && close();
    const onCancel = () => {
        if (offerState.overrideExisting) {
            setOfferState({ type: 'clearOverride' });
            setOfferState({
                type: 'valueChange',
                payload: 0
            });
            return;
        }
        onClose();
    };
    return (
        <>
            <Drawer closeDrawer={onClose} classList={'voice-drawer dispute-resolution' + (isRecipient ? ' unclosable' : '')}>
                <div className="dispute-resolution-title">Dispute Resolution</div>
                <div className="voice-drawer-body">
                    <div className="dispute-details">
                        <div className="details-totals">
                            {[
                                ['Amount Diff', amountDiffFormatted],
                                ['Minutes Diff', minutesDiffFormatted]
                            ].map(([a, b], idx) => (
                                <div key={idx}>
                                    <div>{a}</div>
                                    <div className="diff">{b}</div>
                                </div>
                            ))}
                        </div>
                        <div className="details-list">
                            {[
                                [
                                    <span className="field-name" key="aDiff">
                                        Amount Diff <span className="diff">{amountDiffFormatted}</span>
                                    </span>,
                                    <span className="field-name" key="mDiff">
                                        Minutes Diff <span className="diff">{minutesDiffFormatted}</span>
                                    </span>
                                ],
                                [
                                    'Discrepancy Types',
                                    <TagList key="taglist" className="fancy-labels" values={types.map(convertDiscrepancyType)} limit={types.length} />
                                ],
                                ['Destination', destination],
                                ['Period', settlementPeriod],
                                ['Product', product || 'N/A'],
                                ['Invoice', `${accountName}-${invoiceReferenceId}`]
                            ].map(([a, b], idx) => (
                                <div key={idx}>
                                    <div>{typeof a === 'string' ? <span className="field-name">{a}</span> : a}</div>
                                    <div>{b}</div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="resolution-details">
                        {getResolutionTitle()}
                        <div className="dispute-amounts">
                            <span>Amount Offered</span>
                            {renderAmountValue()}
                        </div>
                        {isAmountOfferedNotAllowed ? (
                            <div className="reject-reason">
                                <span className={'reject_text_box'}>
                                    Offered amount is higher than amount difference. Please enter a different amount
                                </span>
                            </div>
                        ) : null}
                        {existingOffer && !isRecipient && (
                            <>
                                {offerState.showRejectReason ? (
                                    <div className="reject-reason">
                                        <span>{partnerStatusDescription}</span>
                                        <span className="cross" onClick={setShowRejectReason.bind(null, false)}>
                                            ×
                                        </span>
                                    </div>
                                ) : (
                                    <>
                                        <div className="status-title">Offer Status</div>
                                        {renderStatus(getOfferStatusMessage(status))}
                                    </>
                                )}
                                {status === DisputeOfferStatus.OfferSent && <CopyLink link={`/external/offer/${encodeURIComponent(link)}`} />}
                            </>
                        )}
                    </div>
                </div>
                {!existingOffer && (
                    <div className="voice-drawer-actions creator-actions">
                        <button className="btn btn-cancel" onClick={onCancel}>
                            Cancel
                        </button>
                        <button className="btn btn-primary" onClick={submitOffer}>
                            Create {isEditable ? 'Offer' : 'Dispute'}
                        </button>
                    </div>
                )}
                {existingOffer && isRecipient && (
                    <div className="voice-drawer-actions recipient-actions">
                        {status !== DisputeOfferStatus.Reject && (
                            <button disabled={status === DisputeOfferStatus.Accept} onClick={confirmReject} className="btn btn-secondary">
                                Reject
                            </button>
                        )}
                        {(status === DisputeOfferStatus.Accept || status === DisputeOfferStatus.Reject) &&
                            renderStatus(status === DisputeOfferStatus.Accept ? 'Offer Accepted' : 'Offer Rejected')}
                        {status !== DisputeOfferStatus.Accept && (
                            <button disabled={status === DisputeOfferStatus.Reject} onClick={confirmAccept} className="btn btn-primary">
                                Accept
                            </button>
                        )}
                    </div>
                )}
                {existingOffer && !isRecipient && (
                    <div className="voice-drawer-actions creator-actions">
                        <button className="btn btn-primary" onClick={startCreatingNewOffer}>
                            Create New Offer
                        </button>
                    </div>
                )}
            </Drawer>
            {offerState.statusToApprove && renderConfirmDialog(offerState.statusToApprove)}
        </>
    );
};
