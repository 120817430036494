import * as React from 'react';
import enhanceWithClickOutside from 'react-click-outside';

import { Icon } from '@components/Icon';
import { InputIndicatorSvg } from '@components/Svg';

import './styles.scss';
import classNames from 'classnames';

type ISimpleFunction = () => void;

interface ISearchSelectProps {
    menuOptions: string[];
    chosenOption?: string;
    selectOption: (option: string) => void;
}

interface ISearchSelectState {
    inputValue: string;
    isFilterMenuShown: boolean;
    isSelectMenuShown: boolean;
}

class SelectWithSearch extends React.Component<ISearchSelectProps, ISearchSelectState> {
    constructor(props: ISearchSelectProps) {
        super(props);

        this.state = {
            inputValue: props.chosenOption ? props.chosenOption : '',
            isFilterMenuShown: false,
            isSelectMenuShown: false
        };
    }

    componentDidUpdate(prevProps: ISearchSelectProps) {
        if (prevProps.chosenOption !== this.props.chosenOption && this.props.chosenOption === '') {
            this.setState({
                inputValue: ''
            });
        }
    }

    handleClickOutside = () => {
        this.hideFilterMenu();
        this.hideSelectMenu();
    };

    changeInputValue = (event: React.FormEvent<HTMLInputElement>): void => {
        const inputValue = event.currentTarget.value;
        this.setState({
            isFilterMenuShown: true,
            isSelectMenuShown: false,
            inputValue
        });
    };

    showSelectMenu = (): void => {
        this.setState((prevState) => ({
            isSelectMenuShown: !prevState.isSelectMenuShown,
            isFilterMenuShown: false
        }));
    };

    hideSelectMenu = (): void => {
        this.setState({
            isSelectMenuShown: false
        });
    };

    chooseSelectMenuOption = (option: string): ISimpleFunction => (): void => {
        this.setState({
            inputValue: option,
            isSelectMenuShown: false,
            isFilterMenuShown: false
        });
        this.props.selectOption(option);
    };

    showFilterMenu = (): void => {
        this.setState({
            isFilterMenuShown: true,
            isSelectMenuShown: false
        });
    };

    hideFilterMenu = (): void => {
        this.setState({
            isFilterMenuShown: false
        });
    };

    filterMenuOptionFormatter = (option: string, searchInputString: string): JSX.Element | null => {
        const newStringStart = option
            .toLowerCase()
            .trim()
            .indexOf(searchInputString.toLowerCase());

        if (newStringStart === -1) {
            return null;
        }

        const newStringLength = searchInputString.length;
        const newStringEnd = newStringStart + newStringLength;

        const newString = (
            <span>
                {option.slice(0, newStringStart)}
                <span className="search-select-filter-menu--option_span">{option.slice(newStringStart, newStringEnd)}</span>
                {option.slice(newStringEnd, option.length)}
            </span>
        );

        return newString;
    };

    renderSearchInput = (inputValue: string): JSX.Element => {
        return (
            <div className="search-select-field-control">
                <div className="search-select-icon">
                    <Icon iconName="search" />
                </div>
                <input
                    className="search-select-input-container--input"
                    type="text"
                    value={inputValue}
                    onChange={this.changeInputValue}
                    placeholder="Search"
                    name="search-select"
                    autoComplete="off"
                    spellCheck={false}
                />
                <div className="search-select-indicator" onClick={this.showSelectMenu}>
                    <InputIndicatorSvg />
                </div>
            </div>
        );
    };

    renderFilterMenu = (withFilterMenuOptions: string[], inputValue: string): JSX.Element => (
        <div className="search-select-filter-menu">
            {withFilterMenuOptions.length ? (
                withFilterMenuOptions.map((option: string) => (
                    <div className="search-select-filter-menu--option" onClick={this.chooseSelectMenuOption(option)} key={option}>
                        <div className="option-wrapper">{this.filterMenuOptionFormatter(option, inputValue)}</div>
                    </div>
                ))
            ) : (
                <div className="search-select-filter-menu--option">No options</div>
            )}
        </div>
    );

    renderSelectMenu = (menuOptions: string[]): JSX.Element => {
        const availableOption = (option: string): JSX.Element => (
            <div className="search-select-select-menu--option" onClick={this.chooseSelectMenuOption(option)} key={option}>
                <div className="option-wrapper">{option}</div>
            </div>
        );
        const emptyOption: JSX.Element = <div className="search-select-select-menu--option">No options are available</div>;

        return (
            <div className="search-select-select-menu">
                {menuOptions.length ? menuOptions.map((option: string) => availableOption(option)) : emptyOption}
            </div>
        );
    };

    render() {
        const { menuOptions } = this.props;
        const { inputValue, isSelectMenuShown, isFilterMenuShown } = this.state;

        const withFilterMenuOptions = menuOptions.filter((option: string) => option.toLowerCase().includes(inputValue.toLowerCase()));

        return (
            <div className={classNames({ 'not-empty': inputValue.length > 0 }, 'search-select')}>
                {isFilterMenuShown && inputValue && this.renderFilterMenu(withFilterMenuOptions, inputValue)}
                {isSelectMenuShown && this.renderSelectMenu(menuOptions)}
                {this.renderSearchInput(inputValue)}
            </div>
        );
    }
}

export const SearchSelect = enhanceWithClickOutside(SelectWithSearch);
