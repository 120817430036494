import { GeneralDialog } from '@components/Modal/GeneralDialog';
import React, { useCallback } from 'react';
import { Box } from '@material-ui/core';
import { T } from '@components/T/T';
import classNames from 'classnames';
import { Button } from 'antd';
import { formatBalance } from '@helpers/formaters';
import uiActions from '@actions/ui';
import { useSelector } from 'react-redux';
import { getCurrentInfo, getFinalBillingStatement } from '@selectors/roaming';
import { getCurrencySymbol } from '@constants';
import { useAction } from '../../../hooks/useAction';
import { Separator } from '@components/Separator/Separator';
import { NetPositionTable, OFFER_DIRECTION, OfferForm, OfferMessages, useSettlementOfferStyles } from '../DisputeResolutionModal';
import { noop } from 'lodash';
import { isActiveOfferMine, selectLatestOffer } from '@selectors/roaming/settlementOffers';
import { materialTheme } from '@components/theme';
import { getLastPartOfId } from '../../../utils/id-utils';
import { CurrencyViewSelection } from '@components/DisputeResolution/CurrencyViewSelector/CurrencyViewSelection';

export const AcceptedOffer: React.FC = () => {
    const agreement = useSelector(getCurrentInfo);
    const classes = useSettlementOfferStyles();
    const hideModal = useAction(uiActions.hideModal);
    const finalSettlement = useSelector(getFinalBillingStatement);

    if (!finalSettlement) {
        return null;
    }
    const contractName = agreement.contract.config.name;

    const offer = useSelector(selectLatestOffer);
    const onCloseModalClick = useCallback(() => {
        hideModal();
    }, []);

    let offerDirection: OFFER_DIRECTION;
    if (useSelector(isActiveOfferMine)) {
        offerDirection = finalSettlement.suggestedOfferDirection;
    } else if (finalSettlement.suggestedOfferDirection === OFFER_DIRECTION.PAY) {
        offerDirection = OFFER_DIRECTION.RECEIVE;
    } else {
        offerDirection = OFFER_DIRECTION.PAY;
    }

    return (
        <GeneralDialog
            onCloseClick={onCloseModalClick}
            modalClassName={'padding'}
            width={780}
            isDialogOpen={true}
            HeaderComponent={<ModalHeaderComponent subHeader={contractName} />}
            FooterComponent={<ModalFooterComponent onCancelClick={onCloseModalClick} />}
        >
            <Box className={classes.mainWrapper}>
                <Box mb={7} display={'flex'}>
                    <OfferForm
                        isReadOnly
                        offerDirection={offerDirection}
                        offerAmount={offer!.offerAmount}
                        discrepancy={finalSettlement.discrepancy}
                        handlePercentageChange={noop}
                        setOfferAmount={noop}
                    />
                </Box>
                <NetPositionTable offerAmount={offer!.offerAmount} offerDirection={offerDirection} />
                <OfferMessages />
            </Box>
        </GeneralDialog>
    );
};

const ModalHeaderComponent: React.FC<{ subHeader: string }> = ({ subHeader }) => {
    const classes = useSettlementOfferStyles();
    return (
        <Box className={classNames(classes.header)} mx={-5} px={5}>
            <Box display="flex" alignItems="center" width="100%">
                <Box mr={2}>
                    <T>Accepted Offer</T>
                </Box>
                <Box color={materialTheme.palette.lighter}>
                    <T variant={'caption'}>{subHeader}</T>
                </Box>
                <Separator />
                <CurrencyViewSelection />
            </Box>
        </Box>
    );
};

const ModalFooterComponent: React.FC<{
    onCancelClick: () => any;
}> = ({ onCancelClick }) => {
    const offer = useSelector(selectLatestOffer);
    const classes = useSettlementOfferStyles();

    return (
        <Box className={classNames(classes.footer)}>
            <T>{`ID ${getLastPartOfId(offer?.id)}`}</T>
            <Separator />
            <Button onClick={onCancelClick}>Close</Button>
        </Box>
    );
};
