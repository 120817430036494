import { createRequestAction } from '@helpers/actionCreator';

export enum SettingsActionType {
    GET_SETTINGS_REQUEST = 'GET:SETTINGS::REQUEST',
    GET_SETTINGS_SUCCESS = 'GET:SETTINGS::REQUEST:SUCCESS',

    GET_SUPPORTED_CURRENCIES_REQUEST = 'GET:SUPPORTED_CURRENCIES',
    GET_SUPPORTED_CURRENCIES_SUCCESS = 'GET:SUPPORTED_CURRENCIES::REQUEST:SUCCESS',

    SET_LOCAL_CURRENCY_REQUEST = 'SET:LOCAL_CURRENCY::REQUEST',
    SET_LOCAL_CURRENCY_SUCCESS = 'SET:LOCAL_CURRENCY::REQUEST:SUCCESS',

    SET_COUNTRY_MAPPING_ID = 'SET:COUNTRY_MAPPING_ID'
}

export const settingsActions = {
    getSettings: createRequestAction(SettingsActionType.GET_SETTINGS_REQUEST, 'getSettings'),

    getSupportedCurrencies: createRequestAction(SettingsActionType.GET_SUPPORTED_CURRENCIES_REQUEST, 'getSupportedCurrencies'),

    setLocalCurrency: createRequestAction(SettingsActionType.SET_LOCAL_CURRENCY_REQUEST, 'setLocalCurrency', (code: string) => [code]),

    setCountryMappingId: createRequestAction(SettingsActionType.SET_COUNTRY_MAPPING_ID, 'setCountryMappingId', (setId: string) => [setId])
};
