import React from 'react';

import { SelectField } from '@components';

import '../NumberInput/styles.scss';

enum OperationType {
    Starts = 'starts',
    Contains = 'contains'
}

export interface IStringFilterValue {
    type: OperationType;
    inputValue: string;
    outputValue: string;
}
interface IProps {
    value: IStringFilterValue;
    onChange(value: IStringFilterValue): void;
}

export const StringInput = ({ value, onChange }: IProps) => {
    const operations = [
        {
            name: <span className="numeric-option">Starts with</span>,
            value: OperationType.Starts
        },
        {
            name: <span className="numeric-option">Contains</span>,
            value: OperationType.Contains
        }
    ];

    const onOperationChanged = (type: OperationType) => update({ ...value, type });
    const onValueChange = (event: React.ChangeEvent<HTMLInputElement>) => update({ ...value, inputValue: event.target.value });
    const update = ({ type, inputValue }: IStringFilterValue) => {
        type = type || operation;
        const outputValue = inputValue ? (type === OperationType.Starts ? inputValue + '%' : `%${inputValue}%`) : '';
        onChange({ type, inputValue, outputValue });
    };
    const operation = value.type || OperationType.Starts;
    return (
        <div className="filter-numeric-input single">
            <SelectField
                classList="voice operation-select"
                input={{ onChange: onOperationChanged, value: operation }}
                options={operations}
                meta={''}
            />
            <span className="inputs">
                <input value={value.inputValue} onChange={onValueChange} placeholder="0" />
            </span>
        </div>
    );
};
