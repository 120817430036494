import { alertType } from '@actions/RatesSheetParser/alerts';
import { IAppAction } from '../state';

export interface IAlertsServerData {
    contact_name: string;
    alert_media: string;
    contact_info: string;
    parsing_failure: boolean;
    rate_results: boolean;
    system_alert: boolean;
    alert_id: string;
    [key: string]: string | boolean;
}

export interface IStateAlerts {
    editingOrAddingOrDeletingFinished: boolean;
    loading: boolean;
    isFilterUsed: boolean;
    dataCommon: IAlertsServerData[];
    dataFiltered: IAlertsServerData[];
}

const initialState = {
    editingOrAddingOrDeletingFinished: false,
    loading: false,
    isFilterUsed: false,
    dataCommon: [] as IAlertsServerData[],
    dataFiltered: [] as IAlertsServerData[]
};

export function alertReducer(state = initialState, action: IAppAction): IStateAlerts {
    switch (action.type) {
        case alertType.ALERTS_GET_ALL_REQUEST:
        case alertType.ALERTS_GET_FILTERED_REQUEST:
            return { ...state, loading: true };
        case alertType.ALERTS_UPDATE_REQUEST:
        case alertType.ALERTS_INSERT_REQUEST:
        case alertType.ALERTS_DELETE_REQUEST:
            return { ...state, editingOrAddingOrDeletingFinished: false };
        case alertType.ALERTS_GET_ALL_SUCCESS:
            return {
                ...state,
                dataCommon: action.payload,
                isFilterUsed: false,
                loading: false
            };
        case alertType.ALERTS_GET_FILTERED_SUCCESS:
            return {
                ...state,
                dataFiltered: action.payload,
                isFilterUsed: true,
                loading: false
            };
        case alertType.ALERTS_UPDATE_SUCCESS:
        case alertType.ALERTS_INSERT_SUCCESS:
        case alertType.ALERTS_DELETE_SUCCESS:
        case alertType.ALERTS_UPDATE_FAIL:
        case alertType.ALERTS_INSERT_FAIL:
        case alertType.ALERTS_DELETE_FAIL:
            return { ...state, editingOrAddingOrDeletingFinished: true };
        case alertType.ALERTS_GET_ALL_FAIL:
        case alertType.ALERTS_GET_FILTERED_FAIL:
            return { ...state, loading: false };
        default:
            return state;
    }
}
