import React from 'react';
import classNames from 'classnames';

import './styles.scss';

interface IProps {
    options: string[];
    chosen: string[];
    toggleOption(option: string): void;
}
export const MultiSelect = ({ options, chosen, toggleOption }: IProps) => {
    const optionClick = (option: string) => toggleOption(option);
    return (
        <div className="filters-selection-buttons">
            {options.map((option, index) => (
                <button
                    key={`${option}${index}`}
                    className={classNames({
                        'is-selected': chosen.includes(option)
                    })}
                    onClick={optionClick.bind(null, option)}
                >
                    {option}
                </button>
            ))}
        </div>
    );
};
