import { createRequestAction } from '@helpers/actionCreator';
import { push } from 'connected-react-router';

export enum settlementActionTypes {
    GET_SUMMARY = 'GET_SUMMARY::REQUEST',
    GET_SUMMARY_SUCCESS = 'GET_SUMMARY::REQUEST:SUCCESS'
}

export const settlementActions = {
    getYearSummary: createRequestAction(settlementActionTypes.GET_SUMMARY, 'getYearSettlementSummary', (year: number) => [year]),

    setDashboardYear: (year: number) => push(`?year=${year}`)
};
