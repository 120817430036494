interface IPreviousFeaturesT {
    [feature: string]: boolean;
}
interface ICurrentFeaturesT {
    [featureGroup: string]: { [feature: string]: string };
}
declare type FeaturesType = IPreviousFeaturesT | ICurrentFeaturesT | undefined;

export function isFeatureSupported(features: FeaturesType, feature: string): boolean {
    if (!features) {
        return false;
    }
    if (features[feature]) {
        return true;
    } else {
        return Object.values(features).some((featureGroup) => {
            return !!featureGroup?.[feature];
        });
    }
}
