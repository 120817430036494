import { createSelector } from 'reselect';
import get from 'lodash/get';
import filter from 'lodash/filter';
import uniqBy from 'lodash/uniqBy';
import {
    agreementsSelector,
    agreementsSelectorWithProps,
    getAgreementParamsFromUrl,
    getAgreements,
    getCurrentInfo,
    IAgreementsReducerWithProps
} from '@selectors/roaming';
import { orderBy } from 'lodash';
import { BillingStatement, BillingStatementType } from '@clearblockchain/shared/billing-statement';
import { OFFER_DIRECTION } from '@components/DisputeResolution/DisputeResolutionModal';
import { Currency } from '@helpers/currency';

export interface IPosition {
    value: number;
    currency: Currency;
    receiveOrPayable: 'Receivable' | 'Payable';
}
export interface IRoamingContractBillingStatement extends BillingStatement {
    myNetPosition: IPosition;
    myFinalBalance: IPosition;
    discrepancyPercentage: string;
    discrepancy: Currency;
    suggestedOfferDirection: OFFER_DIRECTION;
    split: null | IPosition;
    counterFinalBalance: IPosition;
    counterNetPosition: IPosition;
    index: number;
    perPartyState: BillingStatement['perPartyState'];
}

export const getAllBillingStatements = createSelector(
    getCurrentInfo,
    agreementsSelectorWithProps,
    (currentInfo, { agreements }: IAgreementsReducerWithProps): BillingStatement[] => {
        const contractId = currentInfo?.contract?.id;
        const billingStatementsForContract = uniqBy(
            filter<BillingStatement>(agreements.billingStatements, { contractId }),
            'id'
        );
        return billingStatementsForContract;
    }
);

export const selectBillingStatements = createSelector(getAllBillingStatements, (billingStatementsForContract): BillingStatement[] => {
    return billingStatementsForContract.filter((billingStatement) => {
        return billingStatement.type !== BillingStatementType.EndOfYearDiscount;
    });
});

export const getFinalRawStatement = createSelector(getAllBillingStatements, (billingStatementsForContract): BillingStatement | undefined => {
    return billingStatementsForContract.find((billingStatement) => {
        return billingStatement.type === BillingStatementType.EndOfYearDiscount;
    });
});

export const getBillingStatementsForContractRoaming = createSelector(
    agreementsSelector,
    getAgreementParamsFromUrl,
    getCurrentInfo,
    (agreementsState, agreementParams, currentInfo): IRoamingContractBillingStatement[] => {
        const { id: contractId } = agreementParams;

        const notSortedContractBillingStatements = uniqBy(
            filter<BillingStatement>(agreementsState.billingStatements, { contractId }),
            'id'
        );
        const contractBillingStatements = orderBy(notSortedContractBillingStatements, ['index', 'createDate'], ['desc', 'asc']);
        const currencyCode = currentInfo.terms.currency;
        const { isMyContract } = currentInfo;
        return contractBillingStatements.map((billingStatement: BillingStatement, index: number) => {
            const myBalance = get(billingStatement, `settlementResults.result.balances[${currentInfo.isMyContract ? '0' : '1'}]`);
            const counterBalance = get(billingStatement, `settlementResults.result.balances[${currentInfo.isMyContract ? '1' : '0'}]`);
            const myNetPosition = billingStatement.settlementResults.result[currentInfo.isMyContract ? 'p1Data' : 'p2Data'].p1NetPosition;
            const counterNetPosition = billingStatement.settlementResults.result[currentInfo.isMyContract ? 'p2Data' : 'p1Data'].p1NetPosition;
            const isExist = (value: any): boolean => !Number.isNaN(value) && (value !== null || true || value !== '');

            const isBillingStatementReady = isExist(myBalance) && isExist(counterBalance);
            const discrepancy = new Currency(Math.abs(myNetPosition - counterNetPosition), currencyCode);

            return {
                ...billingStatement,
                myFinalBalance: createPosition(myBalance, currencyCode, isMyContract),
                counterFinalBalance: createPosition(counterBalance, currencyCode, isMyContract),
                myNetPosition: createPosition(myNetPosition, currencyCode, isMyContract),
                counterNetPosition: createPosition(counterNetPosition, currencyCode, isMyContract),
                suggestedOfferDirection: suggestOfferDirection(isMyContract, myNetPosition, counterNetPosition),
                discrepancyPercentage: (myNetPosition ? Math.abs(((myNetPosition - counterNetPosition) / myNetPosition) * 100) : 0).toFixed(2),
                discrepancy,
                split: isBillingStatementReady ? createPosition((myNetPosition + counterNetPosition) / 2, currencyCode, isMyContract) : null
            };
        });
    }
);

export function suggestOfferDirection(isMyContract: boolean, myNetPosition: number, counterNetPosition: number): OFFER_DIRECTION {
    // initially assuming it's my contract
    let direction: OFFER_DIRECTION;
    if (myNetPosition > 0 && counterNetPosition > 0) {
        direction = OFFER_DIRECTION.RECEIVE;
    } else if (myNetPosition < 0 && counterNetPosition < 0) {
        direction = OFFER_DIRECTION.PAY;
    } else {
        if (Math.abs(myNetPosition) > Math.abs(counterNetPosition)) {
            direction = OFFER_DIRECTION.RECEIVE;
        } else {
            direction = OFFER_DIRECTION.PAY;
        }
    }
    // if it's not my contract, just reverse the direction
    if (!isMyContract) {
        direction = direction === OFFER_DIRECTION.RECEIVE ? OFFER_DIRECTION.PAY : OFFER_DIRECTION.RECEIVE;
    }
    return direction;
}

export const getFinalBillingStatement = createSelector<any, IRoamingContractBillingStatement[], IRoamingContractBillingStatement | undefined>(
    getBillingStatementsForContractRoaming,
    (billingStatements) => {
        return billingStatements.find((s) => s.type === BillingStatementType.EndOfYearDiscount)!;
    }
);

export function createPosition(value: string | number, currencyCode: string, isMyContract: boolean): IPosition {
    return {
        value: Number(value),
        currency: new Currency(Math.abs(Number(value)), currencyCode),
        receiveOrPayable: payableOrReceivable(isMyContract, Number(value))
    };
}

export function payableOrReceivable(isMyContract: boolean, balance: number): 'Receivable' | 'Payable' {
    if (isMyContract) {
        return balance > 0 ? 'Receivable' : 'Payable';
    }
    return balance > 0 ? 'Payable' : 'Receivable';
}
