import React from 'react';
import { IPagination } from '@helpers/actionCreator';
import ArrowLeft from '@material-ui/icons/ArrowLeft';
import ArrowRight from '@material-ui/icons/ArrowRight';

import './styles.scss';

interface IProps {
    pagination: IPagination;
    onSelectPage: (a: number) => void;
}
const bind = (func: (a: any) => any, arg: any) => () => func(arg);
// Note - first page is 1
export const Pagination = ({ pagination: { page, pageSize, totalItems }, onSelectPage }: IProps) => {
    const maxPage = Math.ceil(totalItems! / pageSize!);
    return totalItems === 0 ? null : (
        <div className="c_pagination">
            <span className="pg-total" data-t="pagination-total">
                {totalItems} Result{totalItems! > 1 ? 's' : ''}
            </span>
            <button className="pg-start" disabled={page === 1} onClick={bind(onSelectPage, 0)}>
                Start
            </button>
            <button className="pg-back" disabled={page === 1} onClick={bind(onSelectPage, page! - 1)}>
                <ArrowLeft style={{ fontSize: 28 }} />
            </button>
            <span className="pg-range">
                {(page! - 1) * pageSize! + 1}-{Math.min(totalItems!, page! * pageSize!)}
            </span>
            <button className="pg-next" disabled={page === maxPage} onClick={bind(onSelectPage, page! + 1)}>
                <ArrowRight style={{ fontSize: 28 }} />
            </button>
            <button className="pg-end" disabled={page === maxPage} onClick={bind(onSelectPage, maxPage)}>
                End
            </button>
        </div>
    );
};
