import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createBrowserHistory } from 'history';
import { configureStore } from './store';
import { AppLayout } from '@components/Layout/app';
import { AppRoutes } from './routes';
import { ThemeProvider } from '@material-ui/core/styles';

import 'normalize.css';
import { materialTheme } from '@components/theme';
import '@styles/global.scss';
import '@styles/antd.scss';
import { NetworkStatusDialog } from '@components/NetworkStatus/NetworkStatusDialog';
import { CurrencyProvider } from '@helpers/currency';

import { loadXpathTool } from './xpath';

loadXpathTool();

export const history = createBrowserHistory();
const store = configureStore(history);

const render = () => {
    ReactDOM.render(
        <Provider store={store}>
            <CurrencyProvider>
                <AppLayout>
                    <NetworkStatusDialog />
                    <ThemeProvider theme={materialTheme}>
                        <AppRoutes history={history} />
                    </ThemeProvider>
                </AppLayout>
            </CurrencyProvider>
        </Provider>,
        document.getElementById('root')
    );
};

render();

if (module.hot) {
    module.hot.accept('./routes', () => {
        render();
    });
}
