import React, { useState, ReactNode, CSSProperties } from 'react';
import { Select } from 'antd';
import { SelectProps } from 'antd/lib/select';

/*
    Modify antd Select component to support performant handling of a large amount of items
    Temporary fix until antd v4
    See https://github.com/ant-design/ant-design/issues/3789
    Loosely based on the solution https://github.com/iblq/antd-virtual-select/blob/master/lib/index.js referred there
*/
interface IMenuProps {
    menuItems: Array<React.ReactElement<any>>;
    dropdownMenuStyle: CSSProperties;
}
const maxHeight = 250;
const minHeight = 80;
const itemHeights = {
    small: 24,
    large: 40,
    middle: 32
};
// TODO - make generic
export const PerfSelect: React.FC<Omit<SelectProps<string>, 'dropdownRender'>> = (props: Omit<SelectProps<string>, 'dropdownRender'>) => {
    const itemHeight = itemHeights[props.size || 'middle'];
    const [start, setStart] = useState(0);
    const [minWidth, setMinWidth] = useState(150);
    const span = Math.ceil((maxHeight * 1.5) / itemHeight);
    const onScroll = (e: any) => {
        setStart(Math.floor(e.target.scrollTop / itemHeight));
        setMinWidth(Math.max(e.target.getBoundingClientRect().width, minWidth));
    };
    const customDropdownRender = (menu: ReactNode) => {
        const menuProps = (menu as any).props as IMenuProps;
        const items = menuProps.menuItems;
        const height = items.length * itemHeight;
        const end = Math.min(start + span, items.length);
        const slice = items.slice(start, end);
        return React.cloneElement(menu as React.ReactElement<any>, {
            menuItems: slice.map((item, idx) =>
                React.cloneElement(item, {
                    style: {
                        ...(item as any).style,
                        marginTop: idx ? 0 : start * itemHeight,
                        marginBottom: idx === slice.length - 1 ? Math.max(0, items.length - end) * itemHeight : 0
                    }
                })
            ),
            dropdownMenuStyle: {
                ...menuProps.dropdownMenuStyle,
                height,
                minHeight,
                maxHeight,
                minWidth
            }
        });
    };
    return (
        <Select
            {...props}
            dropdownStyle={{ maxHeight: maxHeight + 'px', overflow: 'auto' }}
            dropdownRender={customDropdownRender}
            onPopupScroll={onScroll}
        />
    );
};
