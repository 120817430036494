import { Store, applyMiddleware, createStore, compose } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { routerMiddleware } from 'connected-react-router';
import { History } from 'history';
import { createLogger } from 'redux-logger';
import createSagaMiddleware from 'redux-saga';
import thunk from 'redux-thunk';
import { IRootState, getRootReducer } from '@reducers/index';
import { rootSaga } from '@saga/index';
import { appType } from '@actions';
import { NetworkStatus } from '@reducers/app';

export function configureStore(history: History, initialState?: IRootState): Store<IRootState> {
    const sagaMiddleware = createSagaMiddleware();
    const logger = createLogger({ collapsed: true });
    const routerReduxMiddleware = routerMiddleware(history);

    let middleware = compose(applyMiddleware(sagaMiddleware, routerReduxMiddleware, logger, thunk));

    if (process.env.NODE_ENV !== 'production') {
        middleware = composeWithDevTools(middleware);
    }

    const store = createStore(getRootReducer(history) as any, initialState as any, middleware) as Store<IRootState>;
    sagaMiddleware.run(rootSaga);

    if (module.hot) {
        module.hot.accept('@reducers/index', () => {
            const nextReducer = require('@reducers/index');
            store.replaceReducer(nextReducer);
        });
    }
    listenToOnlineStatus();
    return store;

    function listenToOnlineStatus() {
        window.addEventListener('online', () => {
            store.dispatch({
                type: appType.SET_NETWORK_STATUS,
                payload: NetworkStatus.Disconnected
            });
        });

        window.addEventListener('offline', () => {
            store.dispatch({
                type: appType.SET_NETWORK_STATUS,
                payload: NetworkStatus.BrowserOffline
            });
        });
    }
}
