import React, { useCallback, useContext } from 'react';
import { ColInfo } from 'xlsx';
import get from 'lodash/get';
import { exportTable } from '@helpers/exportToFile';
import { Menu } from 'antd';
import { TableCtx } from '@components/ClearTable/ClearTable';

export interface IMenuOptionsProps {
    close(): void;
}

export const TableOptionsMenu: React.FC<IMenuOptionsProps> = ({ close }) => {
    const { columns, setColumnManager, fetchExport, entityName } = useContext(TableCtx);
    const openColumnManager = useCallback(() => setColumnManager(true), [setColumnManager]);
    const onExport = useCallback(async () => {
        const cols = columns.filter((c) => c.export !== false);
        const columnWidths: ColInfo[] = cols.map((c) => ({ wch: c.title!.toString().length }));

        const { items, fileName } = await fetchExport!();
        const headers = [cols.map((c) => c.title!.toString())];
        const dataTable = headers.concat(
            items.map((item: any) => {
                return cols.map((c, i) => {
                    const value = get(item, c.dataIndex!);
                    columnWidths[i].wch = Math.max(columnWidths[i].wch!, get(value, 'length', 0));
                    if (c.exportTransform) {
                        return c.exportTransform(value, item);
                    }
                    return value;
                });
            })
        );
        exportTable(fileName || entityName, 'xlsx', entityName, dataTable, columnWidths);
    }, []);

    return (
        <Menu selectable={false} onClick={close} data-t="table-menu">
            {fetchExport && (
                <Menu.Item key="0" onClick={onExport} data-t="table-menu-export">
                    Export
                </Menu.Item>
            )}
            <Menu.Item key="1" onClick={openColumnManager}>
                Manage Columns
            </Menu.Item>
        </Menu>
    );
};
