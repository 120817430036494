import * as React from 'react';
import { Link, NavLink } from 'react-router-dom';
import flatMap from 'lodash/flatMap';

import { NotificationContainer } from '@containers/NotificationContainer';
import { Dropdown } from '@components/Buttons';
import { Icon } from '@components/Icon';
import { IRouteComponentBaseInfo, ROUTES } from '@helpers/dynamicRoutes';
import { connect } from 'react-redux';
import { IRootState } from '@reducers';
import { getIsUserAdmin, isVoiceNodeSelector, getUserGroup } from '@selectors/account';
import { Logo } from '@modules/Voice/utils/Logo';
import { DashboardPaths } from '@modules/Dashboard/routes';
import './HeaderComponent.scss';
import { CreateContractButton } from '@modules/Roaming/components/UploadContractButton/CreateContractButton';

interface IProps {
    nodes: string[];
    peerName: string;
    peerPresentedName: string;
    userEmail?: string;
    isUserAdmin?: boolean;
    isVoiceNode?: boolean;
    onLogout: () => void;
    onCleanDemoVoice: () => void;
    onResetDemoEnv: () => void;
    demoVoice: boolean;
    userAuthGroup?: string;
}

export interface IItemsParamsTypes {
    path: string;
    name: string;
}

@connect((state: IRootState) => ({
    isVoiceNode: isVoiceNodeSelector(state),
    isUserAdmin: getIsUserAdmin(state),
    userAuthGroup: getUserGroup(state)
}))
export class HeaderComponent extends React.Component<IProps> {
    render() {
        const { nodes, peerPresentedName, peerName, onLogout, isUserAdmin, userEmail, demoVoice, isVoiceNode, userAuthGroup } = this.props;
        if (!peerName && !nodes.includes('voice')) {
            return null;
        }
        const mergedNodes = flatMap(nodes, (node: string) => ROUTES[node]).filter((item: IRouteComponentBaseInfo): boolean | string => {
            if (item.authorizedGroups && !item.authorizedGroups.includes(userAuthGroup as string)) {
                return false;
            }
            return item.condition ? item.condition(peerName) : true;
        });
        return (
            <header className="dashboard-header">
                <nav>
                    {peerName && <Logo peerName={peerName} peerPresentedName={isVoiceNode ? peerPresentedName : undefined} />}
                    <div className="user-menu">
                        {mergedNodes.map(
                            (item: IRouteComponentBaseInfo): JSX.Element => {
                                return (
                                    <NavLink to={item.path} key={item.path} className="menu-item">
                                        {item.name}
                                    </NavLink>
                                );
                            }
                        )}
                        <CreateContractButton className={['menu-item', 'create-contract-button'].join(' ')} />
                        <NotificationContainer />
                        <Dropdown title={userEmail} icon={<Icon iconName="people" />} inline closeOnOutsideClick>
                            {isUserAdmin ? (
                                <Link to={DashboardPaths.USER_MANAGEMENT} className="item">
                                    MANAGE USERS
                                </Link>
                            ) : null}
                            {isUserAdmin ? (
                                <Link to={DashboardPaths.SETTINGS} className="item">
                                    SETTINGS
                                </Link>
                            ) : null}
                            {isUserAdmin && isVoiceNode ? (
                                <Link to={DashboardPaths.VOICE_PARTNER_MANAGEMENT} className="item">
                                    MANAGE PARTNERS
                                </Link>
                            ) : null}
                            <Link key="logout" to="/auth/login" onClick={onLogout} className="item">
                                LOG OUT
                            </Link>
                            {demoVoice ? (
                                <>
                                    <Link key="clear" to="/dashboard/voice/settlements" onClick={this.props.onCleanDemoVoice} className="item">
                                        CLEAR DATA
                                    </Link>
                                    <Link key="reset" to="/dashboard/voice/settlements" onClick={this.props.onResetDemoEnv} className="item">
                                        RESET ENV
                                    </Link>
                                </>
                            ) : null}
                        </Dropdown>
                    </div>
                </nav>
            </header>
        );
    }
}
