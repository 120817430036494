import voiceActions from '@actions/voice';
import { IPotentialPartners, AccountStatus } from '@reducers/voice';
import { getPotentialPartners } from '@selectors/voice';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import uiActions from '@actions/ui';
import { ClearSelect, IOption, INewExternalPartner } from '../Select/Select';
import { Dialog } from '@components';
import './selectDialogStyles.scss';
import { getAddPartnerDialogStatus } from '@selectors/ui';
import { PRODUCT } from '../../utils/productTypes';
import { getDemoVoice } from '@selectors/app';

export enum PartnerAccountType {
    EXTERNAL = 'EXTERNAL',
    CLEAR_PEER = 'CLEAR_PEER'
}

const newExternalPartner: INewExternalPartner = {
    label: 'I want to add a partner who is not in the list',
    value: 'newExternalPartner'
};

export const AddPartners = () => {
    const dispatch = useDispatch();
    const [selectedOptions, setSelectedOptions] = useState<any>(null);
    const potentialPartners: IPotentialPartners | null = useSelector(getPotentialPartners);
    const isAddPartnerDialogOpen = useSelector(getAddPartnerDialogStatus);
    const demoVoice: boolean = useSelector(getDemoVoice);

    useEffect(() => {
        dispatch(voiceActions.voiceGetPotentialPartners());
    }, []);

    const createAccounts = () => {
        const accountsToServer = prepareAccountsToBeSentToServer();
        dispatch(voiceActions.voiceCreateAccounts(PRODUCT.VOICE, accountsToServer));
    };

    const prepareAccountsToBeSentToServer = () => {
        const accountsToServer = selectedOptions.map((account: any) => {
            return {
                type: account.peerId ? PartnerAccountType.CLEAR_PEER : PartnerAccountType.EXTERNAL,
                status: account.peerId ? AccountStatus.PENDING_PARTNER_APPROVAL : AccountStatus.APPROVED,
                presentedName: account.presentedName,
                name: account.value,
                peerId: account.peerId
            };
        });
        return accountsToServer;
    };

    const editPotentialPartnersToFitSelect = (potentialPartnersParam: IPotentialPartners) => {
        const potentialPartnersArray = [];
        for (const [key, partner] of Object.entries(potentialPartnersParam)) {
            if (!demoVoice && partner.demo) {
                continue;
            }
            potentialPartnersArray.push({
                label: partner.presentedName,
                value: key,
                peerId: partner.peerId,
                presentedName: partner.presentedName
            });
        }
        potentialPartnersArray.push(newExternalPartner);
        return potentialPartnersArray;
    };

    const isOptionDisabled = (option: IOption) => {
        return newExternalPartner && option.value === newExternalPartner.value;
    };

    const handleChange = (selectedOptionsFromSelect: IOption[]) => {
        setSelectedOptions(selectedOptionsFromSelect);
    };

    const oncloseClick = () => {
        dispatch(uiActions.hideAddPartnerDialog());
    };

    const isApplyButtonActive = selectedOptions && selectedOptions.length > 0;

    return potentialPartners && isAddPartnerDialogOpen ? (
        <Dialog isOpen={isAddPartnerDialogOpen} onClose={oncloseClick} classList="select-dialog" hideCloseButton>
            <ClearSelect
                isOptionDisabled={isOptionDisabled}
                options={editPotentialPartnersToFitSelect(potentialPartners)}
                selectedOptions={selectedOptions}
                handleChange={handleChange}
                newExternalPartner={newExternalPartner}
            />
            <div className={'dialog-button-row'}>
                <button className={'add-partner-btn close-dialog'} onClick={oncloseClick}>
                    Close
                </button>
                <button className={`add-partner-btn apply-select ${!isApplyButtonActive && `disabled`}`} onClick={createAccounts}>
                    Apply
                </button>
            </div>
        </Dialog>
    ) : null;
};
