import * as React from 'react';

interface IVoiceTabLabelProps {
    label: string;
    totalAmount?: string;
    counter?: number;
}

export const VoiceTabLabel: React.StatelessComponent<IVoiceTabLabelProps> = ({ label, totalAmount, counter }): JSX.Element => (
    <div className="tab-label-content">
        <span className="label">
            {label}
            {counter && ` (${counter})`}
        </span>
        {totalAmount && <span className="totalAmount">{totalAmount}</span>}
    </div>
);
