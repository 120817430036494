import React from 'react';
import { connect } from 'react-redux';
import { Dispatch, AnyAction } from 'redux';
import ReactModal from 'react-modal';
import { createStructuredSelector } from 'reselect';

import modalTypes from '@components/Modal';
import { IRootState } from '@reducers/index';
import { IStateModal } from '@reducers/ui';
import { getModal } from '@selectors/ui';
import uiActions from '@actions/ui';

import './ModalContainer.scss';

ReactModal.setAppElement('#modalContainer');

export interface IModalTypes {
    [key: string]: typeof modalTypes.WebSocketAlertModal;
}

const MODAL_TYPES: IModalTypes = {
    webSocketAlert: modalTypes.WebSocketAlertModal
};

export interface IDrawerProps {
    modal: IStateModal;
    hideModal: () => void;
}

export interface IDrawerState {
    modalIsOpen: boolean;
}

export interface IDispatchModalContainer {
    hideModal: () => AnyAction;
}

@connect(
    createStructuredSelector<IRootState, any>({
        modal: getModal
    }),
    (dispatch: Dispatch): IDispatchModalContainer => ({
        hideModal: (): AnyAction => dispatch(uiActions.hideModal())
    })
)
export class ModalContainer extends React.Component<any> {
    static getDerivedStateFromProps(nextProps: any, prevState: IDrawerState) {
        if (nextProps.modal.isOpen === prevState.modalIsOpen) {
            return null;
        }
        return { modalIsOpen: nextProps.modal.isOpen };
    }

    state = {
        modalIsOpen: false
    };

    closeModal = () => {
        this.props.hideModal();
    };

    render() {
        const { modalType, modalProps } = this.props.modal;
        const { modalIsOpen } = this.state;

        if (!modalType) {
            return null;
        }

        if (modalType === 'disputeResolution') {
            return null;
        }

        const SpecifiedModal = MODAL_TYPES[modalType];

        return (
            <ReactModal
                isOpen={modalIsOpen}
                onRequestClose={this.closeModal}
                closeTimeoutMS={1000}
                contentLabel="modal"
                overlayClassName="overlay-style"
                className="modal-dialog-style"
            >
                <SpecifiedModal closeModal={this.closeModal} {...modalProps} />
            </ReactModal>
        );
    }
}
