import * as React from 'react';
import { Helmet } from 'react-helmet';
import { Tabs, Sticky } from '@components';
import { updateDirectionInUrl } from '../../../../../src/utils/urlFunctions';
import { VoiceTraffic } from '@modules/Voice/containers';
import { VoiceTabLabel } from '@modules/Voice/components';

import './styles.scss';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { bindActionCreators } from 'redux';
import { IRootState } from '@reducers/index';
import { getComparisonType, getCurrentSettlementInfo, getSingleSettlementInfo } from '@selectors/voice';
import voiceActions, { ComparisonType } from '@actions/voice';
import { Icon } from '@components/Icon';
import { BackButton } from '@components/Buttons';
import { IStateVoice, ISettlement } from '@reducers/voice';

@connect(
    createStructuredSelector<IRootState, any, any>({
        info: getSingleSettlementInfo,
        settlement: getCurrentSettlementInfo,
        comparisonType: getComparisonType
    }),
    (dispatch: any) => ({
        actions: bindActionCreators(
            {
                ...voiceActions
            } as any,
            dispatch
        )
    })
)
export class OverviewContainer extends React.Component<any> {
    voiceTraffic?: VoiceTraffic = undefined;
    tabChanged = (selectedTab: number) => {
        updateDirectionInUrl(selectedTab, this.props.location, this.props.history);
    };
    setRef = (voiceTraffic: VoiceTraffic) => (this.voiceTraffic = voiceTraffic);
    goBackClick = (e: React.MouseEvent) => {
        if (this.voiceTraffic) {
            this.voiceTraffic.goBack();
            e.stopPropagation();
        }
    };

    componentDidMount() {
        this.props.actions!.voiceQuerySettlementPeriods(0, {
            id: this.props.match.params.id
        });
    }

    isReceivableTabDisabled = (comparisonType: IStateVoice['comparisonType'], settlement: ISettlement): boolean =>
        comparisonType !== ComparisonType.CDR_PARTNER_CDR || !(settlement.receivableFileHash || settlement.partnerReceivableFileHash);

    render() {
        const { comparisonType, settlement, info, match, location, history } = this.props;
        const isReceivableTabDisabled = this.isReceivableTabDisabled(comparisonType, settlement);
        return (
            <main className="voice-overview">
                <Helmet title="Clear Voice - Settlement Overview" />
                <Sticky>
                    <div className="voice-title-header">
                        <span onClickCapture={this.goBackClick}>
                            <BackButton classes="circled-button">
                                <Icon iconName="back" title="Go Back" />
                            </BackButton>
                        </span>
                        <div className="voice-title-wrapper">
                            <div className="voice-title">{info.title}</div>
                            <div className="subtitle">{info.subtitle}</div>
                        </div>
                    </div>
                </Sticky>
                <Tabs className="voice-tabs" onChange={this.tabChanged}>
                    <VoiceTraffic
                        location={location}
                        history={history}
                        key={1}
                        setRef={this.setRef}
                        isReceivable={false}
                        match={match}
                        tabLabel={<VoiceTabLabel label={`Payable Traffic`} />}
                    />
                    <VoiceTraffic
                        location={location}
                        history={history}
                        key={2}
                        setRef={this.setRef}
                        isReceivable={true}
                        match={match}
                        tabLabel={<VoiceTabLabel label={`Receivable Traffic`} />}
                        disabled={isReceivableTabDisabled}
                    />
                </Tabs>
            </main>
        );
    }
}
