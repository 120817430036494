import * as React from 'react';

import './styles.scss';
import classNames from 'classnames';

interface IFieldProps {
    input?: any;
    disabled?: boolean;
    name: string;
    label: string | JSX.Element | null;
    isChecked: boolean;
    useCheckBoxValue?: (checked: boolean) => void;
}

export const Checkbox = ({ disabled = false, input, label, name, isChecked, useCheckBoxValue }: IFieldProps) => {
    const handleChange = () => {
        if (useCheckBoxValue) {
            useCheckBoxValue(!isChecked);
        }
    };

    const fieldName = input ? input.name : name;

    return (
        <div className={classNames('field', 'field-control', 'field-checkbox', `field-${fieldName}`, { interactive: !disabled })}>
            <label>
                <input disabled={disabled} name={fieldName} {...input} type="checkbox" checked={isChecked} onChange={handleChange} />
                {label}
            </label>
        </div>
    );
};
