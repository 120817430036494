import { IAppAction } from './state';
import { currencyActionType } from '@actions/currency';

export interface ICurrencyRate {
    from: string;
    to: string;
    rate: number;
    date: Date;
}

export interface ICurrencyState {
    sdrRates: Record<string, number>;
}

const initialState: ICurrencyState = {
    sdrRates: {}
};

export function currencyReducer(state = initialState, { type, payload }: IAppAction): ICurrencyState {
    switch (type) {
        case currencyActionType.GET_CURRENCY_RATE_SUCCESS: {
            const receivedRates = payload;
            const sdrRates: Record<string, number> = {};
            receivedRates.map((r: any) => {
                // const key = moment(r.date).format('YYYY-MM-DD');
                sdrRates[r.from] = r.rate;
            });
            return {
                ...state,
                sdrRates
            };
        }
        default:
            return state;
    }
}
