import * as React from 'react';

import './styles.scss';
import { useSelector } from 'react-redux';
import { getSummary } from '@selectors/voice';
import { ISummary } from '@reducers/voice';
import { getCurrencySymbol } from '@constants';
import { formatBalance } from '@helpers/formaters';

interface IProps {
    peerName: string;
    accountName: string;
    currency?: string;
}

interface ICube {
    myAmount: string;
    partnerAmount: string;
    amountDiff: string;
    percentageDiff: string;
    cubeName: string;
}
const HeaderSummaryCube = (props: ICube) => {
    const { myAmount, partnerAmount, amountDiff, percentageDiff, cubeName } = props;
    return (
        <div className="summary-cube-wrapper">
            <div className="summary-cube-name">{cubeName}</div>
            <div className="numbers-part-wrapper">
                <div className={'summary-cube left'}>
                    <div className={'summary-cube-amount-row comparison'}>
                        <div className={'summary-cube-amount-row-wrapper'}>
                            <span className={'partner-amount'}>{myAmount}</span>
                        </div>
                    </div>
                    <div className={'summary-cube-amount-row comparison'}>
                        <div className={'summary-cube-amount-row-wrapper with-background'}>
                            <span className={'partner-amount'}>{partnerAmount}</span>
                        </div>
                    </div>
                </div>
                <div className={'summary-cube'}>
                    <div className={'summary-cube-amount-row'}>
                        <div className={'amount-diff'}>{amountDiff}</div>
                        <div className={'diff-wrapper'}>
                            <span className={'diff'}> Difference</span>
                            <span className="percentage-diff">{percentageDiff}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export const RecordState = (props: IProps) => {
    const { aAmount, bAmount, aMinutes, bMinutes, aCalls, bCalls } = useSelector(getSummary) as ISummary;
    const { currency } = props;
    const amountSymbol = getCurrencySymbol(currency!);
    const maxAmount = Math.max(aAmount, bAmount);
    const amountDiff = aAmount - bAmount;
    const callsDiff = aCalls - bCalls;
    const minutesDiff: number = Math.round(aMinutes - bMinutes);
    const formatPercentage = (value: any, maxAmountToPercentage: number) =>
        formatBalance((value / maxAmountToPercentage) * 100, 2, { roundToInteger: true });
    const formatValue = (value: any, valueSymbol?: string | undefined) => {
        return formatBalance(value, 2, { currency: valueSymbol, roundToInteger: true });
    };
    return (
        <div className="record-state">
            {!(typeof minutesDiff === 'number' && typeof callsDiff === 'number' && typeof amountDiff === 'number') ? null : (
                <>
                    <HeaderSummaryCube
                        myAmount={formatValue(aAmount, amountSymbol)}
                        partnerAmount={formatValue(bAmount, amountSymbol)}
                        amountDiff={formatValue(amountDiff, amountSymbol)}
                        percentageDiff={`(${formatPercentage(amountDiff, maxAmount)}%)`}
                        cubeName={'Total Amounts'}
                    />
                    <HeaderSummaryCube
                        myAmount={formatValue(aCalls)}
                        partnerAmount={formatValue(bCalls)}
                        amountDiff={formatValue(callsDiff)}
                        percentageDiff={`(${formatPercentage(callsDiff, Math.max(aCalls, bCalls))}%)`}
                        cubeName={'Total Calls'}
                    />
                    <HeaderSummaryCube
                        myAmount={formatValue(aMinutes)}
                        partnerAmount={formatValue(bMinutes)}
                        amountDiff={formatValue(minutesDiff)}
                        percentageDiff={`(${formatPercentage(minutesDiff, Math.max(aMinutes, bMinutes))}%)`}
                        cubeName={'Total Minutes'}
                    />
                </>
            )}
        </div>
    );
};
