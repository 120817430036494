import { IAppAction } from './state';
import { SettlementOfferActionType } from '@actions/settlementOffers';
import { unionBy, orderBy } from 'lodash';

export interface ISettlementOfferComment {
    id: string;
    date: number;
    offerId: string;
    message: string;
    initiatorId: string;
    receiverId: string;
}

export interface ISettlementOffer {
    id: string;
    contractId: string;
    date: number;
    initiatorId: string;
    receiverId: string;
    message: string;
    offerAmount: number;
    periodIndex: number;
    hash: string;
    comments: ISettlementOfferComment[];
}

export interface ISettlementOfferState {
    isLoading: boolean;
    offers: ISettlementOffer[];
    currencyView: 'local' | 'original';
}

const initialState: ISettlementOfferState = {
    isLoading: false,
    currencyView: 'original',
    offers: []
};

export function settlementOfferReducer(state = initialState, { type, payload, requestAction }: IAppAction): ISettlementOfferState {
    switch (type) {
        case SettlementOfferActionType.GET_SETTLEMENT_OFFER_REQUEST: {
            return {
                ...state,
                isLoading: true,
                offers: []
            };
        }

        case SettlementOfferActionType.GET_SETTLEMENT_OFFER_SUCCESS: {
            const offers = payload;
            return {
                ...state,
                isLoading: false,
                offers
            };
        }

        case SettlementOfferActionType.SETTLEMENT_OFFER_RECEIVED: {
            const { offers } = payload;
            return {
                ...state,
                offers: unionBy(
                    offers.map((o: ISettlementOffer) => ({ ...o, comments: [] })),
                    state.offers,
                    'id'
                )
            };
        }

        case SettlementOfferActionType.SETTLEMENT_OFFER_COMMENT_RECEIVED: {
            const { comments } = payload;
            const offers = state.offers.slice();
            comments.forEach((comment: ISettlementOfferComment) => {
                const offer = offers.find((o) => o.id === comment.offerId);
                if (offer) {
                    const allComments = unionBy([comment], offer.comments, 'id');
                    const sortedComments = orderBy(allComments, 'date');
                    offer.comments = sortedComments;
                }
            });
            return {
                ...state,
                offers
            };
        }

        case SettlementOfferActionType.SET_CURRENCY_VIEW: {
            const currencyView = payload;
            return {
                ...state,
                currencyView
            };
        }

        default: {
            return state;
        }
    }
}
