import { call, put, takeLatest, select } from 'redux-saga/effects';
import { push } from 'connected-react-router';

import authActions, { authType, LOGIN_REDIRECT } from '@actions/auth';
import { appType } from '@actions/app';
import { communicationType } from '@actions/communication';
import { LocalStorage } from '@helpers/localstorage';
import { getRouterPath } from '@selectors/router';
import { getSessionResponse, getAuthToken } from '@selectors/auth';
import { LocationDescriptorObject } from 'history';

const authToken = 'auth_token';

function* sessionClearance(): IterableIterator<any> {
    yield put(authActions.sessionRestoreFail());
    yield clearToken();
}

function* storeToken(action: any): IterableIterator<any> {
    LocalStorage.set(authToken, action.payload);
}

function* clearToken(): IterableIterator<any> {
    LocalStorage.remove(authToken);
}

function* initTokenRestoration(): IterableIterator<any> {
    const token = LocalStorage.get(authToken);

    if (!token) {
        yield call(sessionClearance);
    } else {
        yield put(authActions.sessionRestoreSuccess(token));
    }
}

export function* sessionChecking(): IterableIterator<any> {
    const token = yield select(getAuthToken);
    if (token) {
        yield put(authActions.sessionCheckRequest());
    }
}

function* changeLocationAfterInit(): IterableIterator<any> {
    const session = yield select(getSessionResponse);
    const token = yield select(getAuthToken);
    const location: string = (yield select(getRouterPath)) as any;

    const isSessionTrue = token && session;
    const initRoutePath = !isSessionTrue ? '/auth' : '/dashboard';
    const currentUrl = window.location.pathname;

    if (location.startsWith('/external/')) {
        // Give external login a chance
        return;
    }
    const LOGIN = '/auth/login';
    if (!isSessionTrue) {
        yield call(sessionClearance);
        if (currentUrl !== LOGIN) {
            yield put(authActions.logLogoutEvent(false, currentUrl));
        } else {
            // Already in login page
            return;
        }
        const newUrl: LocationDescriptorObject = {
            pathname: LOGIN
        };
        if (!['/auth', LOGIN].includes(currentUrl)) {
            newUrl.search = `?${LOGIN_REDIRECT}=${currentUrl}`;
        }
        yield put(push(newUrl));
    } else if (location === '/') {
        yield put(push(initRoutePath));
    }
}

function* changeLocationAfterExternalLoginFail(): IterableIterator<any> {
    yield put(push('/'));
    yield call(changeLocationAfterInit);
}

function* initTokenSaving(action: any): IterableIterator<any> {
    yield put(authActions.sessionStore(action.payload));
}

export const sagaAuth = function*(): IterableIterator<any> {
    yield takeLatest(authType.LOGIN_SUCCESS, initTokenSaving);
    yield takeLatest(authType.EXTERNAL_LOGIN_SUCCESS, initTokenSaving);
    yield takeLatest(authType.SESSION_STORE, storeToken);
    yield takeLatest(authType.LOGOUT_FINISHED, sessionClearance);
    yield takeLatest(appType.SAGA_INIT_FINISHED, initTokenRestoration);
    yield takeLatest(communicationType.REQUEST_UNAUTHORIZED, sessionClearance);
    yield takeLatest(authType.SESSION_CHECK_FINISHED, changeLocationAfterInit);
    yield takeLatest(authType.EXTERNAL_LOGIN_FAIL, changeLocationAfterExternalLoginFail);
};
