import isFunction from 'lodash/isFunction';

export const createAction = <A extends any[], B>(type: string, { payload }: { payload?: ((...args: A) => B) | B } = {}) => {
    return (...params: A) => {
        return {
            type,
            payload: isFunction(payload) ? payload(...params) : payload ? payload : params[0]
        };
    };
};

const REQUEST_SUFFIX = '::REQUEST';
export const requestType = (type: string) => type + REQUEST_SUFFIX;
export const requestSuccessType = (type: string) => type + REQUEST_SUFFIX + ':SUCCESS';
export const requestFailType = (type: string) => type + REQUEST_SUFFIX + ':FAIL';
export const requestFinishType = (type: string) => type + REQUEST_SUFFIX + ':FINISHED';
export const clearType = (type: string) => type + ':CLEAR';

export const createRequestAction = <A extends any[], B>(type: string, requestName: string, values?: ((...args: A) => B) | B) => {
    if (!type.endsWith(REQUEST_SUFFIX)) {
        type = requestType(type);
    }
    return (...params: A) => ({
        type,
        payload: {
            name: requestName,
            values: isFunction(values) ? values(...params) : values
        }
    });
};
export interface ISorting {
    by: string;
    order: 'desc' | 'asc';
}
export const getEmptyQueryResult = () => ({ items: [], pagination: {} });
export interface IQueryResult<T = any> {
    items: T[];
    pagination: IPagination;
}
export interface IPagination {
    page?: number;
    pageSize?: number;
    totalItems?: number;
    totalPages?: number;
}
