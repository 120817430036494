import React from 'react';
import TextField from '@material-ui/core/TextField';
import './styles.scss';
import { formatPeriod } from '@helpers/formaters';
import get from 'lodash/get';
import { withTheme } from '@material-ui/core/styles';

interface ILinkModalProps {
    externalSettlement: any;
    externalSettlementPeriodHash: string;
}

interface ILinkModalState {
    copied: boolean;
    msgText: string;
    link: string;
    isDocumentReady: boolean;
}

class CopyLink extends React.PureComponent<ILinkModalProps, ILinkModalState> {
    constructor(props: ILinkModalProps) {
        super(props);
        this.state = {
            copied: false,
            msgText: '',
            link: `${window.location.protocol}//${window.location.host}/external/upload/${props.externalSettlementPeriodHash}`,
            isDocumentReady: false
        };
    }
    componentDidMount(): void {
        // here to make sure personal-msg-preview is not rendering before image
        const img = new Image();
        img.onload = this.setDocumentReady() as any;
        img.src = '~assets/images/preview.svg';
    }

    copyStringToClipboard = () => {
        const el = document.createElement('textarea');
        el.value = this.state.link;
        el.setAttribute('readonly', '');
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
    };

    handleCopyLink = (e: React.MouseEvent) => {
        e.preventDefault();
        const based64Text = this.msgToBase64();
        const urlWithoutTextParam = this.state.link.split('?')[0];
        this.setState(
            {
                copied: true,
                link: based64Text ? `${urlWithoutTextParam}?t=${based64Text}` : urlWithoutTextParam
            },
            () => this.copyStringToClipboard()
        );
    };

    onTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ msgText: event.target.value });
    };

    msgToBase64 = () => btoa(this.state.msgText);

    setDocumentReady = () => {
        this.setState({ isDocumentReady: true });
    };

    getPeriod = ({ startDate, endDate }: any = {}) => formatPeriod(startDate, endDate);

    render() {
        const { externalSettlement } = this.props;
        const { msgText, copied, link, isDocumentReady } = this.state;
        return (
            <div className="copylink-modal-content-wrapper">
                <div className="creating-link-side">
                    <>
                        <div className="message">
                            Send request to
                            <span className="bold">{` ${get(externalSettlement, 'accountPresentedName', '')} `}</span>
                            for their CDR file
                        </div>
                        <div className="message">
                            on the following settlement period:
                            <span className="bold">{` ${this.getPeriod(externalSettlement)}`}</span>
                        </div>
                        <TextField
                            placeholder="Type a personal message to your partner"
                            value={msgText}
                            onChange={this.onTextChange}
                            multiline
                            rows="3"
                            classes={{ root: 'partner-msg' }}
                            inputProps={{ maxLength: 250 }}
                        />
                        <div className="actions">
                            <span className="link">{link}</span>
                            <button className={`btn btn-secondary btn-copylink ${copied && ' copied'}`} onClick={this.handleCopyLink}>
                                {copied ? 'Copied' : 'Copy Link'}
                            </button>
                        </div>
                    </>
                </div>
                <div className="seperation" />
                <div className="preview-link-side">
                    {isDocumentReady && (
                        <>
                            <div className="preview-header">Preview</div>
                            <div className="image-div">
                                <div className="personal-msg-preview">{msgText}</div>
                            </div>
                        </>
                    )}
                </div>
            </div>
        );
    }
}

const CopyLinkModal = withTheme(CopyLink);
export { CopyLinkModal };
