import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import authActions from '@actions/auth';
import { getPasswordPolicy, getMyLastPasswordPolicyUpdateTime, getPasswordPolicyFetchError } from '@selectors/auth';
import { Slider, Checkbox, Radio, Button } from 'antd';
import { get } from 'lodash';
import MaskedInput from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import { RadioChangeEvent } from 'antd/lib/radio';
import { IPasswordPolicy, DefaultPasswordPolicy } from '@clear/password-policy';

const minReqTitles: Partial<Record<keyof IPasswordPolicy, string>> = {
    ['passwordLengthEnabled']: 'Minimum length',
    ['specialCharsEnabled']: 'Special chars',
    ['uppercaseCharsEnabled']: 'Upper case chars',
    ['lowercaseCharsEnabled']: 'Lower case chars',
    ['digitCharsEnabled']: 'Digit chars'
};
const minReqKeys = Object.keys(minReqTitles) as Array<keyof IPasswordPolicy>;

const enablingKeyMapping: Partial<Record<keyof IPasswordPolicy, keyof IPasswordPolicy>> = {
    ['passwordLengthEnabled']: 'passwordLength',
    ['digitCharsEnabled']: 'digitChars',
    ['passwordStrengthEnabled']: 'passwordStrength',
    ['specialCharsEnabled']: 'specialChars',
    ['uppercaseCharsEnabled']: 'uppercaseChars',
    ['lowercaseCharsEnabled']: 'lowercaseChars'
};

const integerMask = createNumberMask({
    prefix: '',
    allowNegative: false,
    allowDecimal: false,
    integerLimit: 3
});
export const PasswordPolicyPage: React.FC = () => {
    const currentPolicy = useSelector(getPasswordPolicy);
    const fetchError = useSelector(getPasswordPolicyFetchError);
    const myLastUpdateTime = useSelector(getMyLastPasswordPolicyUpdateTime);
    const [policyChanges, setPolicyChanges] = useState<Partial<IPasswordPolicy>>({});
    const [isHardReset, setIsHardReset] = useState(true);
    const dispatch = useDispatch();
    const mergedPolicy = { ...currentPolicy, ...policyChanges };

    // Reload password policy after saving takes effect
    useEffect(() => {
        dispatch(authActions.getPasswordPolicy());
    }, [myLastUpdateTime]);

    // Reset local changes once policy was loaded
    useEffect(() => {
        setPolicyChanges({});
    }, [currentPolicy]);

    const setPasswordPolicy = () => dispatch(authActions.setPasswordPolicy(mergedPolicy, isHardReset));

    const resetPolicy = () => {
        const changes = {};
        Object.entries(DefaultPasswordPolicy).forEach(([key, value]) => updateValueInChanges(changes, key as keyof IPasswordPolicy, value));
        setPolicyChanges(changes);
    };

    const updateValueInChanges = (changes: typeof policyChanges, key: keyof IPasswordPolicy, value: any) => {
        const currentValue = get(currentPolicy, key);
        if (currentValue === value) {
            delete changes[key];
        } else {
            changes[key] = value;
        }
    };
    const updateValue = (key: keyof IPasswordPolicy, value: any) => {
        const newChanges = { ...policyChanges };
        updateValueInChanges(newChanges, key, value);
        setPolicyChanges(newChanges);
    };
    const strengthChangeHandler = (value: any) => updateValue('passwordStrength', value);
    const getInputNumberUpdater = (key: keyof IPasswordPolicy) => (e: React.ChangeEvent<HTMLInputElement>) => {
        const newVal = parseInt(e.target.value, 10);
        if (!isNaN(newVal)) {
            updateValue(key, newVal);
        }
    };

    const renderPropertyCheckbox = (key: keyof IPasswordPolicy, title: React.ReactNode) => {
        const onChange = () => updateValue(key, !mergedPolicy[key]);
        return (
            <Checkbox checked={!!mergedPolicy[key]} onChange={onChange}>
                {title}
            </Checkbox>
        );
    };
    const hardResetChanged = (e: RadioChangeEvent) => setIsHardReset(e.target.value);

    // Min Required checkbox - adapt to the state of children checkboxes
    const numOfCheckedMinReq = minReqKeys.reduce((count, key) => count + (mergedPolicy[key] ? 1 : 0), 0);
    const isMinReqChecked = numOfCheckedMinReq >= minReqKeys.length / 2;

    const toggleMinReq = () => {
        const newMinReq = !isMinReqChecked;
        const newChanges = { ...policyChanges };
        minReqKeys.forEach((key) => updateValueInChanges(newChanges, key, newMinReq));
        setPolicyChanges(newChanges);
    };
    const getValue = (key: keyof IPasswordPolicy) => mergedPolicy[key];

    const { passwordStrength } = mergedPolicy;
    if (fetchError) {
        return <div>There was a problem loading your password policy. Please try again</div>;
    }
    return (
        <div className="password-policy">
            <div className="main-title">Configuration</div>
            <div className="policy-section">
                <div className="section-title">Password Policy</div>
                {renderPropertyCheckbox('passwordStrengthEnabled', <span className="setting-title">Minimum Password Strength</span>)}
                <div className="password-strength">
                    <Slider
                        disabled={!getValue('passwordStrengthEnabled')}
                        min={0}
                        max={4}
                        onChange={strengthChangeHandler}
                        value={passwordStrength}
                        tooltipVisible={false}
                    />
                    <span className="strength-display">
                        <span className="strength-value">{Number(passwordStrength) + 1}</span>
                        <span className="strength-text">{['Very Weak', 'Weak', 'Medium', 'Strong', 'Very Strong'][Number(passwordStrength)]}</span>
                    </span>
                </div>
                <Checkbox checked={isMinReqChecked} onChange={toggleMinReq}>
                    <span className="major">Minimum Requirements</span>
                </Checkbox>
                <div className="min-req">
                    {Object.entries(minReqTitles).map(([enableKey, title]) => {
                        const key: keyof IPasswordPolicy = enablingKeyMapping[enableKey as keyof IPasswordPolicy]!;
                        const disabled = !mergedPolicy[enableKey as keyof IPasswordPolicy];
                        return (
                            <div key={enableKey}>
                                {renderPropertyCheckbox(enableKey as keyof IPasswordPolicy, title)}
                                <MaskedInput
                                    className="text-input"
                                    disabled={disabled}
                                    mask={integerMask}
                                    value={getValue(key as keyof IPasswordPolicy) as number}
                                    onChange={getInputNumberUpdater(key)}
                                />
                            </div>
                        );
                    })}
                </div>
                {[
                    {
                        key: 'usernameBlockerEnabled',
                        title: `Don't allow user name as password`
                    },
                    {
                        key: 'previousPasswordsBlockerEnabled',
                        title: `Don't allow email string as password`
                    }
                ].map(({ key, title }) => (
                    <div key={key}>{renderPropertyCheckbox(key as keyof IPasswordPolicy, title)}</div>
                ))}
            </div>
            <div className="policy-section">
                <div className="section-title">Password Life Cycle</div>
                {[
                    {
                        key: 'numDaysToExpire',
                        title: 'Password Renewal',
                        before: 'Every',
                        after: 'days'
                    },
                    {
                        key: 'numFailedAttemptsToBlock',
                        title: 'Freeze Account',
                        before: 'After',
                        after: 'failed login attempts'
                    }
                ].map(({ key, title, before, after }) => (
                    <div key={key} className="lifecycle-input">
                        <div className="setting-title">{title}</div>
                        <div>
                            {before}
                            <MaskedInput
                                className="text-input"
                                mask={integerMask}
                                value={getValue(key as keyof IPasswordPolicy) as number}
                                onChange={getInputNumberUpdater(key as keyof IPasswordPolicy)}
                            />
                            {after}
                        </div>
                    </div>
                ))}
            </div>
            <div className="setting-title">Enforce Changes</div>
            <Radio.Group value={isHardReset} onChange={hardResetChanged}>
                {[
                    {
                        value: true,
                        title: 'During current session (after saving changes)',
                        sub: 'This will log out all users and force them to log in and create a new password'
                    },
                    {
                        value: false,
                        title: 'Take effect on next password renewal',
                        sub: 'Each user will have to update their password on the next password renewal'
                    }
                ].map(({ value, title, sub }) => (
                    <Radio className="hard-reset-radio" key={value.toString()} value={value}>
                        <span className="hard-reset-item">
                            <span>
                                <div className="hard-reset-title">{title}</div>
                                <div className="hard-reset-subtitle">{sub}</div>
                            </span>
                        </span>
                    </Radio>
                ))}
            </Radio.Group>
            <div className="settings-actions">
                <Button className="reset-btn" type="link" onClick={resetPolicy}>
                    Reset to Default
                </Button>
                <Button disabled={Object.keys(policyChanges).length === 0} type="primary" onClick={setPasswordPolicy}>
                    Save Changes
                </Button>
            </div>
        </div>
    );
};
