import * as React from 'react';

interface IProps {
    menus?: any;
    handleItemClick: () => void;
}

export class Menu extends React.PureComponent<IProps> {
    handleMenuClick = (event: React.MouseEvent) => {
        event.preventDefault();
        event.stopPropagation();
        this.props.handleItemClick();
    };

    render() {
        return (
            <div className="dropdown-menu" onClick={this.handleMenuClick}>
                {this.props.menus}
            </div>
        );
    }
}
