import * as React from 'react';
import classNames from 'classnames';
import Dropzone from 'react-dropzone';
import FileExcelIcon from '@ant-design/icons/FileExcelFilled';
import { Icon } from '@components/Icon';

import './styles.scss';
import { Tag } from 'antd';

interface IDropzone {
    children?: (params: { isDragActive: boolean }) => any;
    accept: string | undefined;
    maxSize: number;
    onDropHandler: (goodFile: File[], badFile: File[]) => void;
    file?: File;
    errors?: string[];
    id?: string;
    customLabel?: string;
    selectedFileIcon?: string;
}

export class OwnDropzone extends React.Component<IDropzone> {
    onDrop = (acceptedFiles: File[], rejectedFiles: File[]) => this.props.onDropHandler(acceptedFiles, rejectedFiles);

    clearFile = () => this.props.onDropHandler([], []);

    render() {
        const { errors, file, accept, maxSize, children, id, customLabel, selectedFileIcon } = this.props;

        const label = <label htmlFor={id}>{customLabel || 'Please upload your contract'}</label>;

        const errorsMessages = errors && Boolean(errors.length) && (
            <div className="field-messages-errors">
                {errors.map((err) => (
                    <span className="error" key={err}>
                        {err}
                    </span>
                ))}
                <span className="try-another-file">We detected an error. Please try to upload another file</span>
            </div>
        );

        return file && selectedFileIcon ? (
            <div className="dnd-selected-file">
                <Tag closable onClose={this.clearFile}>
                    <FileExcelIcon />
                    <span className="tag-content">{file.name}</span>
                </Tag>
            </div>
        ) : (
            <div className="field field-dnd">
                {label}
                <Dropzone onDrop={this.onDrop} accept={accept} maxSize={maxSize} multiple={false}>
                    {({ getRootProps, getInputProps, isDragActive }): JSX.Element => (
                        <div
                            {...getRootProps()}
                            className={classNames('dropzone', {
                                'is-active': isDragActive
                            })}
                        >
                            <input {...getInputProps()} id={id} />
                            {!isDragActive && <span className="placeholder">Drag and drop your file here</span>}
                            <Icon iconName="dropHere" />
                            <div className="placeholder">
                                {children ? (
                                    children({ isDragActive })
                                ) : isDragActive ? (
                                    <span>Release Here!</span>
                                ) : (
                                    <span className="bottom-span">
                                        or <span className="is-blue">browse</span> for your file
                                    </span>
                                )}
                            </div>
                        </div>
                    )}
                </Dropzone>
                {file && <div className="file-info">{<span>Selected file: {file.name}</span>}</div>}
                {errorsMessages}
            </div>
        );
    }
}
