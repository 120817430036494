import React, { ReactElement, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import uiActions from '@actions/ui';
import authActions, { SessionEvent } from '@actions/auth';
import { Dialog, Icon } from '@components';
import './sessionExpirationDialogStyles.scss';
import { getAuthToken, getSession } from '@selectors/auth';
import { IconLabel } from '@components/IconLabel/IconLabel';

const MINUTE_IN_MS = 60e3;

const getMinutesLeftUntil = (date: number): number => {
    return Math.round((date - Date.now()) / MINUTE_IN_MS);
};

export const SessionExpirationWarning = () => {
    const dispatch = useDispatch();
    const authToken = useSelector(getAuthToken);
    const session = useSelector(getSession);

    const [timeLeftInMins, setTimeLeft] = useState(getMinutesLeftUntil(session.expiresAt));

    useEffect(() => {
        setTimeLeft(getMinutesLeftUntil(session.expiresAt));
    }, [session.expiresAt]);

    useEffect(() => {
        const timer = window.setInterval(() => {
            setTimeLeft((time) => time - 1);
        }, MINUTE_IN_MS);
        return () => {
            window.clearInterval(timer);
        };
    });

    const oncloseClick = () => {
        dispatch(uiActions.hideSessionExpirationDialog());
    };

    const continueWorking = () => {
        dispatch(authActions.sessionCheckRequest());
        oncloseClick();
    };

    const logoutClick = () => {
        dispatch(authActions.logoutRequest(authToken));
        oncloseClick();
    };

    let dialogConsts: {
        title: string;
        message: string;
        buttons: ReactElement[];
    } = {
        title: '',
        message: '',
        buttons: []
    };

    switch (session.lastEvent) {
        case SessionEvent.Warning: {
            dialogConsts = {
                title: 'Inactivity',
                message: `You are about to be logged out of the platform within
                ${timeLeftInMins} minutes due to inactivity`,
                buttons: [
                    <button key="logout" className={'expiration-dialog-btn close-dialog'} onClick={logoutClick}>
                        Logout
                    </button>,
                    <button key="continue" className={'expiration-dialog-btn apply'} onClick={continueWorking}>
                        Continue
                    </button>
                ]
            };
            break;
        }
        case SessionEvent.Expired: {
            dialogConsts = {
                title: 'Session is over',
                message: 'Your session has expired. Please login again to continue working',
                buttons: [
                    <button key="login" className={'expiration-dialog-btn apply'} onClick={logoutClick}>
                        Login
                    </button>
                ]
            };
            break;
        }
        case SessionEvent.Replaced: {
            dialogConsts = {
                title: 'Session is over',
                message: 'This user is logged in another browser, this session will close now.',
                buttons: [
                    <button key="logout" className={'expiration-dialog-btn apply'} onClick={logoutClick}>
                        OK
                    </button>
                ]
            };
            break;
        }
        default:
            return <></>;
    }

    return (
        <Dialog shouldCloseOnClickOutside={false} isOpen={true} onClose={oncloseClick} classList="expiration-dialog" hideCloseButton>
            <div className={'expiration-dialog-header'}>
                <IconLabel icon={<Icon iconName={'warning'} />}>{dialogConsts.title}</IconLabel>
            </div>
            {dialogConsts.message}
            <div className={'dialog-button-row'}>{dialogConsts.buttons}</div>
        </Dialog>
    );
};
