import { createMuiTheme, Theme } from '@material-ui/core/styles';

// tslint:disable-next-line:interface-name
interface ExtendedThemeProps {
    palette: Theme['palette'] & {
        gradient: string;
        lighter: string;
        success: string;
    };
}

export type ExtendedTheme = Theme & ExtendedThemeProps;

const SPACING = 4;

const GRADIENT = `linear-gradient(270deg, #7C83FF 0%, #4A83FF 100%);`;

export enum FontWeight {
    Regular = 400,
    Semibold = 600,
    Bold = 700
}

export enum FontColor {
    B = '#000',
    B85 = '#000000d9',
    B65 = '#000000a6',
    B45 = '#00000073',
    W = '#fff',
    W85 = '#ffffffd9',
    W65 = '#ffffffa6',
    Clear = '#1890ff',
    Success = '#10CE8D',
    Warning = '#ff715b'
}

export const materialTheme = createMuiTheme(
    {
        spacing: SPACING,
        palette: {
            text: {
                primary: FontColor.W
            },
            primary: {
                main: FontColor.Clear,
                dark: '#4a83fa'
            },
            error: {
                main: '#feaf33',
                light: '#feaf33'
            }
        },
        breakpoints: {
            values: { xs: 0, sm: 600, md: 960, lg: 1366, xl: 1920 }
        },
        typography: {
            fontFamily: "'Open Sans', sans-serif",
            htmlFontSize: 18,
            h6: {
                fontSize: 20
            },
            subtitle1: {
                fontSize: 18,
                fontWeight: 700
            },
            subtitle2: {
                fontSize: 12
            },
            body1: {
                fontSize: 14
            },
            body2: {
                fontSize: 16,
                fontWeight: 'bold'
            },
            caption: {
                fontSize: 13
            }
        },
        shape: {
            borderRadius: 2
        },
        overrides: {
            MuiTextField: {
                root: {
                    margin: '24px 0px',
                    borderRadius: 4,
                    border: 'solid 1px #c5cee0'
                }
            },
            MuiInput: {
                root: {
                    padding: 12,
                    fontSize: 12,
                    color: `rgb(74, 92, 92)`,
                    lineHeight: 1.5
                }
            },
            MuiAppBar: {
                root: {
                    background: GRADIENT
                }
            },
            MuiToolbar: {
                regular: {
                    paddingLeft: SPACING * 8,
                    paddingRight: SPACING * 8
                }
            },
            MuiButtonGroup: {
                grouped: {
                    borderRadius: 4
                }
            },
            MuiButton: {
                root: {
                    borderRadius: 0
                }
            },
            MuiContainer: {
                maxWidthLg: {
                    paddingLeft: SPACING * 8,
                    paddingRight: SPACING * 8,
                    paddingTop: SPACING * 10,
                    paddingBottom: SPACING * 10
                }
            }
        },
        props: {
            MuiSvgIcon: {
                fontSize: 'inherit'
            },
            MuiTextField: {
                InputProps: {
                    disableUnderline: true
                }
            },
            MuiContainer: {
                disableGutters: false
            },
            MuiAppBar: {
                elevation: 0,
                square: true,
                position: 'static',
                color: 'primary'
            },
            MuiToolbar: {
                disableGutters: true
            },
            MuiButtonBase: {
                disableRipple: true
            },
            MuiLink: {
                underline: 'none'
            }
        }
    },
    // tslint:disable-next-line:no-object-literal-type-assertion
    {
        palette: {
            gradient: GRADIENT,
            lighter: '#5f5f5f',
            success: '#10ce8d'
        }
    } as ExtendedThemeProps
) as ExtendedTheme;
