import { createAction } from '@helpers/actionCreator';
export enum queryFiltersType {
    OPEN_FILTERS_PANEL = 'VOICE:OPEN:FILTER_PANEL',
    CLOSE_FILTERS_PANEL = 'VOICE:CLOSE:FILTER_PANEL',
    SET_FILTERS = 'VOICE:SET:FILTERS',
    CLEAR_ALL_FILTERS = 'VOICE:CLEAR:ALL_FILTERS',
    CLEAR_FILTER = 'VOICE:CLEAR:FILTER'
}

const queryFiltersActions = {
    openFilterPanel: createAction(queryFiltersType.OPEN_FILTERS_PANEL),
    closeFilterPanel: createAction(queryFiltersType.CLOSE_FILTERS_PANEL),
    clearAllFilters: createAction(queryFiltersType.CLEAR_ALL_FILTERS),
    setFilters: createAction(queryFiltersType.SET_FILTERS, {
        payload: (params: any) => params
    }),
    clearFilter: createAction(queryFiltersType.CLEAR_FILTER, {
        payload: (filterKey: string) => filterKey
    })
};

export default queryFiltersActions;
