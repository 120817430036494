import { getUrlQueryParams } from '../../../../utils/urlFunctions';
import queryString from 'query-string';
import { ViewBy } from '@actions/voice';

export function updateViewByInUrl(viewBy: ViewBy, location: Location, history: any) {
    const queryParams = getUrlQueryParams(location);
    queryParams.viewBy = viewBy;
    delete queryParams.page;
    delete queryParams.sorting;
    const stringifyParams = queryString.stringify(queryParams);
    history.push(`${location.pathname}?${stringifyParams}`);
}

export function getViewByFromUrl(location: Location): ViewBy | undefined {
    const queryParams = getUrlQueryParams(location);
    let viewBy;
    if (queryParams.viewBy) {
        viewBy = queryParams.viewBy;
    }
    return viewBy;
}
