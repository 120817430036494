import { createAction } from '@helpers/actionCreator';
import { IStateDispute } from '@reducers/voiceDisputeDrawer';

export enum voiceDisputeDrawerType {
    OPEN_DISPUTE_DRAWER = 'VOICE:OPEN:DISPUTE_DRAWER',
    CLOSE_DISPUTE_DRAWER = 'VOICE:CLOSE:DISPUTE_DRAWER'
}

const voiceDisputeDrawerActions = {
    closeDisputeDrawer: createAction(voiceDisputeDrawerType.CLOSE_DISPUTE_DRAWER),
    openDisputeDrawer: createAction(voiceDisputeDrawerType.OPEN_DISPUTE_DRAWER, {
        payload: (disputeId: IStateDispute['disputeId']) => disputeId
    })
};

export default voiceDisputeDrawerActions;
