import React, { ComponentState } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { bindActionCreators } from 'redux';
import moment from 'moment';

import { IRootState } from '@reducers/index';
import { getAccounts } from '@selectors/voice';
import voiceActions from '@actions/voice';

import { Drawer } from '@components';

import './styles.scss';
import { RadioButton } from '@components/Fields/Radio';
import { BaseInput } from '@components/Fields/BaseInput';

const years = [2017, 2018, 2019, 2020];

const monthes = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

interface IState {
    formattedStartDate: string | null;
    formattedEndDate: string | null;
    startYear: number | null;
    startMonth: number | null;
    accountId: string | null;
    partner: string | null;
    searchParams: string | null;
}

const initialState: IState = {
    formattedStartDate: null,
    formattedEndDate: null,
    startYear: null,
    startMonth: null,
    accountId: null,
    partner: null,
    searchParams: null
};

interface IProps {
    isOpen: boolean;
    closeDrawer: () => void;
    accountPresentedName?: string;
    actions?: typeof voiceActions;
    accounts?: ReturnType<typeof getAccounts>;
}
@connect(
    createStructuredSelector<IRootState, any, any>({
        accounts: getAccounts
    }),
    (dispatch: any) => ({
        actions: bindActionCreators(
            {
                ...voiceActions
            } as any,
            dispatch
        )
    })
)
export class NewSettlement extends React.Component<IProps, IState> {
    state = { ...initialState };

    componentDidUpdate(prevProps: any, prevState: IState) {
        const { startYear, startMonth } = this.state;
        if (prevState.startYear !== startYear || prevState.startMonth !== startMonth) {
            const formattedStartDate = moment([startYear || '', startMonth || 0])
                .startOf('month')
                .format('MMM DD');
            const formattedEndDate = moment([startYear || '', startMonth || 0])
                .endOf('month')
                .format('MMM DD, YYYY');

            this.setState({ formattedStartDate, formattedEndDate });
        }
        if (this.props.accountPresentedName && (!prevProps.accountPresentedName || this.props.accountPresentedName !== this.state.partner)) {
            if (!this.state.partner) {
                this.handleChangePartner(this.props.accountPresentedName);
            }
        }
    }

    handleChangeYear = (year: number): ComponentState => this.setState({ startYear: Number(year) });

    handleChangeMonth = (month: number): ComponentState => {
        this.setState({ startMonth: Number(month) });
    };

    handleChangePartner = (partnerPresentedName: string) => {
        const accountId = this.props.accounts!.find((account: any) => account.presentedName === partnerPresentedName)!.id;
        this.setState({ partner: partnerPresentedName, accountId });
    };

    handleAccountSearch = (event: any) => {
        const value = event.target.value.toString().toLowerCase();
        this.setState({ searchParams: value });
    };

    submit = () => {
        const { startYear, startMonth, accountId } = this.state;

        if (startYear && startMonth !== null && accountId) {
            this.props.actions!.voiceCreateSettlementPeriod(
                accountId,
                moment([startYear, startMonth])
                    .startOf('month')
                    .unix(),
                moment([startYear, startMonth])
                    .endOf('month')
                    .unix()
            );
        }

        this.reInitOnDrawerClose();
    };

    reInitOnDrawerClose = () => {
        this.setState({ ...initialState });
        this.props.closeDrawer();
    };

    render() {
        const { startYear, startMonth, accountId, partner, searchParams, formattedStartDate, formattedEndDate } = this.state;
        const accountsList = this.props.accounts!.map((account: any) => account.presentedName);

        return !this.props.isOpen ? null : (
            <Drawer closeDrawer={this.reInitOnDrawerClose} classList="add-new-voice-drawer">
                <div className="add-new-voice-title">Add New Settlement</div>
                <div className="add-new-voice">
                    <div className="account">
                        <div className="add-new-voice--label">
                            <span>Choose Account</span>
                            {partner ? <span className="selected-label">{partner}</span> : null}
                        </div>
                        <BaseInput
                            input={{
                                onChange: this.handleAccountSearch,
                                name: 'partner'
                            }}
                            type="text"
                            placeholder="Search"
                        />
                        <div className="partners-list">
                            {searchParams
                                ? accountsList
                                      .filter((item: string) => item.toLowerCase().includes(searchParams, 0))
                                      .map((item: string, key: number) => (
                                          <RadioButton
                                              name="partner"
                                              value={item}
                                              label={item}
                                              checked={partner === item}
                                              onChange={this.handleChangePartner}
                                              key={key}
                                          />
                                      ))
                                : accountsList.map((item: string, key: number) => (
                                      <RadioButton
                                          name="partner"
                                          value={item}
                                          label={item}
                                          checked={partner === item}
                                          onChange={this.handleChangePartner}
                                          key={key}
                                      />
                                  ))}
                        </div>
                    </div>
                    <div className="add-new-voice--label">
                        <span>Date</span>
                        {startMonth !== null && startYear ? (
                            <span className="selected-label">
                                {formattedStartDate} - {formattedEndDate}
                            </span>
                        ) : null}
                    </div>
                    <div className="date">
                        <div className="monthes">
                            <div className="label">Month</div>
                            <div className="month-wrap">
                                {monthes.map((month, index) => (
                                    <RadioButton
                                        checked={startMonth === index}
                                        key={month}
                                        label={month}
                                        name="month"
                                        onChange={this.handleChangeMonth}
                                        value={index}
                                    />
                                ))}
                            </div>
                        </div>
                        <div className="years">
                            <div className="label">Year</div>
                            <div className="years-wrap">
                                {years.map((year, index) => (
                                    <RadioButton
                                        checked={startYear === year}
                                        key={index}
                                        label={year}
                                        name="month"
                                        onChange={this.handleChangeYear}
                                        value={year}
                                    />
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className="form-actions">
                        <button className="btn btn-secondary inline" onClick={this.reInitOnDrawerClose}>
                            Cancel
                        </button>
                        <button className="btn btn-primary" onClick={this.submit} disabled={!startYear || startMonth === null || !accountId}>
                            Create settlement
                        </button>
                    </div>
                </div>
            </Drawer>
        );
    }
}
