import { ClearTable, IColumn, IFetcher } from '@components/ClearTable/ClearTable';
import React, { useCallback, useMemo, useState } from 'react';
import { T } from '@components/T/T';
import { FontWeight } from '@components/theme';
import { ColumnTextFilter } from '@components/ClearTable/Filters/ColumnTextFilter';
import { Checkbox } from 'antd';
import { Box } from '@material-ui/core';
import orderBy from 'lodash/orderBy';
import { CheckboxChangeEvent } from 'antd/es/checkbox';

interface Props {
    isLoading: boolean;
    translationList: Array<{ standard: string; translation: string }>;
}

export const CountryMappingTranslationTable: React.FC<Props> = ({ translationList, isLoading }) => {
    const [shouldShowOnlyTranslationDifferences, setShouldShowOnlyTranslationDifferences] = useState(false);
    const [translationTextFilter, setTranslationTextFilter] = useState<string | undefined>(undefined);
    const [translationSortDirection, setTranslationSortDirection] = useState<'asc' | 'desc' | undefined>(undefined);

    const displayedTranslationList = useMemo(() => {
        let list = translationList;
        if (shouldShowOnlyTranslationDifferences) {
            list = list.filter((t) => t.standard !== t.translation);
        }
        if (translationTextFilter) {
            const filter = translationTextFilter.toLowerCase();
            list = list.filter((t) => t.translation.toLowerCase().startsWith(filter));
        }

        if (translationSortDirection) {
            list = orderBy(list, ['translation'], [translationSortDirection]);
        }

        return list;
    }, [translationList, translationSortDirection, translationTextFilter, shouldShowOnlyTranslationDifferences]);

    const toggleShouldShowOnlyTranslationDifferences = useCallback(
        (e: CheckboxChangeEvent) => setShouldShowOnlyTranslationDifferences(e.target.checked),
        []
    );

    const fetch: IFetcher<{ standard: string; translation: string }> = useCallback(({ query, sorting }) => {
        setTranslationSortDirection(sorting?.order);
        setTranslationTextFilter(query?.translation);
    }, []);

    const getColumns = useCallback(
        (): Array<IColumn<{ standard: string; translation: string }>> => [
            {
                title: 'Standard',
                dataIndex: 'standard'
            },
            {
                title: 'Your Translation',
                dataIndex: 'translation',
                render(translation, { standard }) {
                    const isIdenticalToStandard = translation?.toLowerCase() === standard.toLowerCase();
                    return (
                        <T display="inline" weight={isIdenticalToStandard ? FontWeight.Regular : FontWeight.Semibold}>
                            {translation}
                        </T>
                    );
                },
                sorter: true,
                filterDropdown: ColumnTextFilter
            }
        ],
        []
    );

    return (
        <Box>
            <Box mb={4}>
                <Checkbox value={shouldShowOnlyTranslationDifferences} onChange={toggleShouldShowOnlyTranslationDifferences}>
                    Show only Translations different from Standard
                </Checkbox>
            </Box>
            <ClearTable
                hideMenu
                emptyStateText="There are no translations to show"
                height={500}
                disablePagination={true}
                width={200}
                fetch={fetch}
                getColumns={getColumns}
                entityName="Translations"
                isLoading={isLoading}
                items={displayedTranslationList}
                total={displayedTranslationList.length}
            />
        </Box>
    );
};
