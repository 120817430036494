import { SafeLink } from '@components/SafeLink/SafeLink';
import React from 'react';
import { T } from '@components/T/T';
import { FontColor, FontWeight } from '@components/theme';

export const AboutUs: React.FC<any> = (props: any) => (
    <div {...props}>
        <T weight={FontWeight.Semibold} variant="caption" display="inline">
            We are
        </T>{' '}
        <SafeLink openTab href="http://www.clearx.io">
            <T weight={FontWeight.Semibold} variant="caption" color={FontColor.Clear}>
                Clear
            </T>
        </SafeLink>
        <T weight={FontWeight.Semibold} variant="caption">
            {', a blockchain based product, designed to'}
        </T>
        <br />
        <T weight={FontWeight.Semibold} variant="caption">
            {'reduce friction in settlement resolution workflows.'}
        </T>
    </div>
);
