import React from 'react';

export const ExpirePasswordIcon = () => (
    <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 28 28">
        <g>
            <path d="M6.5,10 C5.67090907,10 5,10.6709091 5,11.5 C5,12.3290909 5.67363636,13 6.5,13 C7.32909091,13 8,12.3290909 8,11.5 C8,10.6709091 7.32909091,10 6.5,10 L6.5,10 Z M10,11.5 C10,12.3290909 10.6736364,13 11.5,13 C12.3263637,13 13,12.3290909 13,11.5 C13,10.6709091 12.3263636,10 11.5,10 C10.6709091,10 10,10.6709091 10,11.5 Z M15,11.5 C15,12.3290909 15.6736364,13 16.5,13 C17.3263637,13 18,12.3290909 18,11.5 C18,10.6709091 17.3263636,10 16.5,10 C15.6709091,10 15,10.6709091 15,11.5 Z" />
            <path d="M12,0 C18.628125,0 24,5.371875 24,12 C24,12.8756065 23.9062519,13.7292893 23.7282441,14.5515601 C26.2385335,15.614746 28,18.1016667 28,21 C28,24.8659932 24.8659932,28 21,28 C18.1016667,28 15.614746,26.2385335 14.5513726,23.7277353 C13.7292893,23.9062519 12.8756065,24 12,24 C5.371875,24 0,18.628125 0,12 C0,5.371875 5.371875,0 12,0 Z M21,15 C17.6862915,15 15,17.6862915 15,21 C15,24.3137085 17.6862915,27 21,27 C24.3137085,27 27,24.3137085 27,21 C27,17.6862915 24.3137085,15 21,15 Z M12,1.6875 C10.6078125,1.6875 9.2578125,1.959375 7.98515625,2.4984375 C6.75703125,3.01640625 5.653125,3.76171875 4.70625,4.70859375 C3.759375,5.6578125 3.01640625,6.759375 2.49609375,7.9875 C1.959375,9.2578125 1.6875,10.6078125 1.6875,12 C1.6875,13.3921875 1.959375,14.7421875 2.4984375,16.0148438 C3.01640625,17.2429688 3.76171875,18.346875 4.70859375,19.29375 C5.6578125,20.240625 6.759375,20.9835938 7.9875,21.5039062 C9.2578125,22.040625 10.6078125,22.3125 12,22.3125 C12.7082754,22.3125 13.4056315,22.2421315 14.0874391,22.1020118 C14.0294774,21.7429846 14,21.3749347 14,21 C14,17.1340068 17.1340068,14 21,14 C21.3749347,14 21.7429846,14.0294774 22.1019585,14.0862411 C22.2420686,13.4062554 22.3125,12.7085922 22.3125,12 C22.3125,10.6078125 22.040625,9.2578125 21.5015625,7.98515625 C20.9835938,6.75703125 20.2382812,5.653125 19.2914063,4.70625 C18.3421875,3.759375 17.240625,3.01640625 16.0125,2.49609375 C14.7421875,1.959375 13.3921875,1.6875 12,1.6875 Z" />
            <path
                d="M.9375 5C.41953125 5 0 4.80066815 0 4.5545657L0 .445434298C0 .199331849.41953125 0 .9375 0 1.45546875 0 1.875.199331849 1.875.445434298L1.875 4.5545657C1.875 4.80066815 1.45546875 5 .9375 5zM0 7C0 7.55228475.472199678 8 1.0546875 8 1.63717532 8 2.109375 7.55228475 2.109375 7 2.109375 6.44771525 1.63717532 6 1.0546875 6 .472199678 6 0 6.44771525 0 7z"
                transform="translate(20 17)"
            />
        </g>
    </svg>
);
