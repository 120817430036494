import { createRequestAction } from '@helpers/actionCreator';

export enum balanceType {
    BALANCE_GET_REQUEST = 'BALANCE:GET::REQUEST',
    BALANCE_GET_REQUEST_SUCCESS = 'BALANCE:GET::REQUEST:SUCCESS',
    BALANCE_GET_REQUEST_FAIL = 'BALANCE:GET::REQUEST:FAIL',
    BALANCE_GET_REQUEST_FINISHED = 'BALANCE:GET::REQUEST:FINISHED',
    BALANCE_UPDATE_RECEIVED = 'BALANCE::UPDATE:RECEIVED'
}

export interface IStateBalance {
    eusdBalance: string;
    hasLoaded: boolean;
    eur: string;
    gbp: string;
    jpy: string;
    margin: string;
    percent: string;
    purchased: string;
    rmb: string;
    usdBalance: string;
    usd: string;
    withdrawal: string;
    withdrawn: string;
    accounts: string;
    updatedTime: string;
    totalEusdBalance: string;
    payableInvoicesTotal: string;
    receivableInvoicesTotal: string;
}

const balanceActions = {
    balanceGetRequest: createRequestAction(balanceType.BALANCE_GET_REQUEST, 'getMyEusdBalance')
};

export default balanceActions;
