import React, { useState, useEffect } from 'react';
import Helmet from 'react-helmet';
import get from 'lodash/get';
import { Sticky, SubHeader, Icon, FiltersPanel, Pagination, Table, Pulse } from '@components';
import { PageTitle, NewAgreement } from '@modules/Voice/components';
import ActiveFilters from '@components/FiltersPane/ActiveFilters';
import { IFilterItem, FilterItemType } from '@components/FiltersPane';
import { getAccounts, getAgreements, getSwapAgreementUpdateCount } from '@selectors/voice';
import { RouteChildrenProps } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { voicePaths, setPathId } from 'utils/routePaths';
import voiceActions from '@actions/voice';
import { getFilters } from '@selectors/queryFilters';

import './styles.scss';
import moment from 'moment';
import { periodDateFormat } from '@constants';
import { ISwapAgreement } from '@reducers/voice';
import { ITableColumnProps } from '@components/Table';

const tableColumns: ITableColumnProps[] = [
    { key: 'name', title: 'Title' },
    { key: 'startDate', title: 'Start Date' },
    { key: 'endDate', title: 'End Date' },
    { key: 'currency', title: 'Currency' },
    { key: 'status', title: 'Status' },
    { key: 'updatedDate', title: 'Updated At', defaultSort: true }
];
const defaultSortColumn = tableColumns.find(({ defaultSort }) => !!defaultSort) || tableColumns[0];
const defaultSortKey = defaultSortColumn.sortingKey || defaultSortColumn.key;
const statusMapping: Record<string, string> = {
    EFFECTIVE: 'Effective',
    NEW_DRAFT: 'New Draft'
};
const reverseStatusMapping: Record<string, string> = Object.entries(statusMapping).reduce((acc: Record<string, string>, [k, v]) => {
    acc[v] = k;
    return acc;
}, {});
const filterItems: IFilterItem[] = [
    {
        key: 'dateFilter',
        type: FilterItemType.Date,
        title: 'Date Period'
    },
    {
        key: 'currency',
        type: FilterItemType.Select,
        title: 'Currency',
        options: ['USD', 'EUR']
    },
    {
        key: 'status',
        type: FilterItemType.Select,
        title: 'Status',
        options: Object.values(statusMapping)
    },
    {
        key: 'draftId',
        type: FilterItemType.Select,
        title: 'Has Draft',
        options: ['Has Draft']
    }
];
const bind = (func: (arg: any) => void, arg: any) => () => func(arg);
export const AgreementsContainer = (props: RouteChildrenProps) => {
    const swapAgreementUpdateCount = useSelector(getSwapAgreementUpdateCount);
    const accounts = useSelector(getAccounts);
    const accountId = (props.match!.params as any).id;
    const account = accounts.find(({ id }) => id === accountId);
    const accountName = get(account, 'name', '');
    const [isNewOpen, setIsNewOpen] = useState(false);
    const { items, pagination } = useSelector(getAgreements);
    const [page, setPage] = useState(0);
    const filters = useSelector(getFilters);
    const [processedFilters, setProcessedFilters] = useState({});
    const [sorting, setSorting] = useState({
        by: defaultSortKey,
        order: 'descending'
    });
    const dispatch = useDispatch();

    const goBack = () => props.history.push(voicePaths.agreements);
    const getTableData = () =>
        items.map((item) => {
            const status = item.status.replace(/_/g, ' ');
            const editableDraft = !!(item.draftId && item.isEditable);
            return {
                ...item,
                ...(['startDate', 'endDate', 'updatedDate'] as Array<keyof ISwapAgreement>).reduce((acc: any, key) => {
                    acc[key] = moment.utc((item[key] as number) * 1000).format(periodDateFormat);
                    return acc;
                }, {}),
                // If has a draft but is uneditable => the draft is pending approval
                status: item.draftId ? (
                    <>
                        {status} <span className="draft-chip">+ {editableDraft ? 'Draft' : 'Pending'}</span>
                        {editableDraft && <Pulse className="draft-pulse" />}
                    </>
                ) : (
                    status
                )
            };
        });
    const rowClick = ({ id }: { id: string }) => props.history.push(setPathId(voicePaths.agreement, id));
    const onSort = ({ key, sortingKey }: ITableColumnProps, isAsc: boolean) => {
        setSorting({
            by: sortingKey || key,
            order: isAsc ? 'ascending' : 'descending'
        });
        setPage(0);
    };

    useEffect(() => {
        if (!accounts.length) {
            dispatch(voiceActions.voiceQueryAccounts());
        }
    }, []);
    useEffect(() => {
        const newFilters = { ...filters } as any;
        const { status, draftId } = filters as any;
        if (status) {
            newFilters.status = (status as string[]).map((s) => reverseStatusMapping[s]);
        }
        if (draftId) {
            // NOTE - gets translated into 'includes' filter in BE query
            newFilters.draftId = 'SWAP';
        }
        setProcessedFilters(newFilters);
        setPage(0);
    }, [filters]);
    useEffect(() => {
        dispatch(voiceActions.getAgreements(accountId, page, processedFilters, sorting));
    }, [page, sorting, processedFilters, swapAgreementUpdateCount]);
    return (
        <main className="voice-agreements">
            <Helmet title="Clear Voice - Swap Agreements" />
            <Sticky>
                <SubHeader title="">
                    <button className="circled-button" onClick={goBack}>
                        <Icon iconName="back" title="Go Back" />
                    </button>
                    <PageTitle title={`${accountName} Agreements`} />
                    <button className="btn btn-primary" onClick={bind(setIsNewOpen, true)}>
                        Add New
                    </button>
                </SubHeader>
            </Sticky>
            <FiltersPanel items={filterItems} currency="" />
            <div className="actions-bar">
                <ActiveFilters items={filterItems} />
                <span className="spacer" />
                <Pagination pagination={pagination} onSelectPage={setPage} />
            </div>
            <NewAgreement isOpen={isNewOpen} close={bind(setIsNewOpen, false)} accountId={accountId} />
            <Table
                handleRowWithValueClick={rowClick}
                classes="voice-settlements-list"
                cols={tableColumns}
                data={getTableData()}
                noHeader={true}
                notFrontendSortable={true}
                onSortingColumnSelect={onSort}
            />
        </main>
    );
};
